/**
 * Created by maitray on 9/3/16.
 */
angular.module('adelementAdminApp')
    .factory('AeAccount',function($http){
    return{
        getAccounts: function(){
           return $http.get('/api/user/accounts').then(function (response) {
                return response.data;
            });
        }
    }
});
