    /**
     * Created by mjhuria on 11/4/17.
     */

    angular.module('adelementAdminApp')
        .controller('RecoManagerController', function ($scope,$state,$stateParams,$interval,$timeout,$http,RecoService,GetAdvertiserIDName){
            $scope.advertiserId = $stateParams.advertiserId;
            $scope.feedProps = {};
            $scope.feedColumns = [];
            $scope.feedFeatureColumns = [];
            $scope.groupBy =[]
            $scope.features = [];
            $scope.allAdvertisersIdName = [];
            $scope.freco_freq = ["daily","weekly","monthly"];
            $scope.ureco_freq = ["daily","weekly","monthly"];
            $scope.feature_types=["relative","absolute","popularity"]
            $scope.feature_funs=["expression","equality_weight","price_weight","popularity_weight_log10","computedaysago","calculateDistanceScoreQatar","intersect_weight","discount_weight"]

            $scope.feedProps.selected=undefined
            $scope.frequency=undefined

            $scope.userprops = {};
            $scope.popularity = {};
            $scope.ma_weight_array =[];

            function initController(){
            }
            function initServices(selected){
                if($scope.advertiserId){
                   RecoService.getAllFeedProps($scope.advertiserId).then(function(feeddata){
                        $scope.feedProps.data = angular.copy(feeddata);
                        $scope.feedProps.names = feeddata.names
                        if(selected != undefined){
                            $scope.feedProps.selected= selected
                        }else{
                            $scope.feedProps.selected= feeddata.names[0]
                        }

                        if($scope.feedProps.selected){
                            $scope.frequency = $scope.feedProps.data[$scope.feedProps.selected].feed.frequency
                            $scope.groupBy=$scope.feedProps.data[$scope.feedProps.selected].feed.groupBy.split(',')
                            $scope.feedColumns=$scope.feedProps.data[$scope.feedProps.selected].feed.feedColumnsOutput.split(',')
                            $scope.features=$scope.feedProps.data[$scope.feedProps.selected].features
                            var alterObj=angular.fromJson($scope.feedProps.data[$scope.feedProps.selected].feed.feedAlterJson)

                            $scope.feedFeatureColumns=angular.copy($scope.feedColumns);


                            angular.forEach(alterObj, function(value, key) {
                              if(this.indexOf(value.col) == -1){
                                this.push(value.col)
                              }
                            }, $scope.feedFeatureColumns);

                            angular.forEach($scope.features, function(value, key) {
                                if(this.indexOf(value.attributeColumn) == -1){
                                    this.push(value.attributeColumn)
                                }
                            }, $scope.feedFeatureColumns);
                            $scope.feedFeatureColumns.push("popularity_weight")

                            $scope.userprops=$scope.feedProps.data[$scope.feedProps.selected].userprops
                            $scope.popularity=$scope.feedProps.data[$scope.feedProps.selected].popularity
                            if($scope.popularity && $scope.popularity.dailyWeightSeriesUnique && $scope.popularity.dailyWeightSeriesUnique.trim().length>0 ){
                                var wstr=$scope.popularity.dailyWeightSeriesUnique.trim().split(',')
                                $scope.ma_weight_array=wstr
                                $scope.popularity.lookBackDays=wstr.length
                            }
                        }
                    });
                }
            }

            $scope.selectFeed = function() {
                $scope.features=$scope.feedProps.data[$scope.feedProps.selected].features
                $scope.frequency = $scope.feedProps.data[$scope.feedProps.selected].feed.frequency
                $scope.feedColumns=$scope.feedProps.data[$scope.feedProps.selected].feed.feedColumnsOutput.split(',')
                var alterObj=angular.fromJson($scope.feedProps.data[$scope.feedProps.selected].feed.feedAlterJson)
                $scope.groupBy=$scope.feedProps.data[$scope.feedProps.selected].feed.groupBy.split(',')

                $scope.feedFeatureColumns=angular.copy($scope.feedColumns);


                angular.forEach(alterObj, function(value, key) {
                    if(this.indexOf(value.col) == -1){
                        this.push(value.col)
                    }
                }, $scope.feedFeatureColumns);

                angular.forEach($scope.features, function(value, key) {
                    if(this.indexOf(value.attributeColumn) == -1){
                        this.push(value.attributeColumn)
                    }
                }, $scope.feedFeatureColumns);
                $scope.feedFeatureColumns.push("popularity_weight")

                $scope.userprops=$scope.feedProps.data[$scope.feedProps.selected].userprops
                $scope.popularity=$scope.feedProps.data[$scope.feedProps.selected].popularity

                if($scope.popularity && $scope.popularity.dailyWeightSeriesUnique && $scope.popularity.dailyWeightSeriesUnique.trim().length>0 ){
                    var wstr=$scope.popularity.dailyWeightSeriesUnique.trim().split(',')
                    $scope.ma_weight_array=wstr
                    $scope.popularity.lookBackDays=wstr.length
                }

            };
            $scope.updateWeight = function(data,i) {
                $scope.ma_weight_array[i]=data
                //if (data !== 'awesome') {
                  //return "Username should be `awesome`";
                //}
              };

            $scope.movingAverage = function() {
                $scope.ma_weight_array =[];
                for(var i = 0; i < $scope.popularity.lookBackDays; i += 1){
                    if($scope.moving_average =='ema'){
                        var t=1/Math.pow(1+($scope.popularity.lookBackDays-i),$scope.popularity.alpha)
                        $scope.ma_weight_array.push(Math.round(t * 100) / 100)
                    }else{
                        $scope.ma_weight_array.push(Math.round(100/$scope.popularity.lookBackDays) / 100)
                    }
                }
            };

            $scope.addFeature=function() {
                var feedId=$scope.feedProps.data[$scope.feedProps.selected].feed.feedId
                $scope.features.push({feedId:feedId,attributeColumn:"",attributeFunction:$scope.feature_funs[0],attributeType:$scope.feature_types[0],attributeWeight:0})
            };
            $scope.removeFeature=function(index) {
                $scope.features.splice(index, 1);
            };

            $scope.saveReco = function(){
                $scope.popularity.dailyWeightSeriesUnique=$scope.ma_weight_array.join(',')
                $scope.popularity.dailyWeightSeriesVisit=$scope.ma_weight_array.join(',')
                var toedit={}
                toedit.advertiserId=$scope.advertiserId
                toedit.feedName=$scope.feedProps.data[$scope.feedProps.selected].feed.feedName
                toedit.containerHash=$scope.feedProps.data[$scope.feedProps.selected].feed.containerHash
                toedit.feedId=$scope.feedProps.data[$scope.feedProps.selected].feed.feedId
                toedit.groupBy=$scope.groupBy.join(',')
                toedit.features=$scope.features
                toedit.popularity=$scope.popularity
                toedit.userprops=$scope.userprops
                toedit.frequency=$scope.frequency

                //alert(JSON.stringify(toedit))

                RecoService.saveRecoProps($scope.advertiserId,toedit).then(function(data){
                    initServices(data.feedName);
                });
            }
            $scope.onSelected = function(val){
                $scope.groupBy=val
            }


            // added by Snehal
            // get all the advertisers
            var advertiserInfoPromise =  GetAdvertiserIDName.query();
            advertiserInfoPromise.$promise.then(function(response){
                 $scope.allAdvertisersIdName = response;
                 if($scope.stateDecide){
                    $scope.selectedAdvertiser = parseInt($stateParams.advertiserId);
                 }
            });

            $scope.getSelectedAdvertiser = function(selectedAdvertiser){
                $state.go("reco-gets",{advertiserId:selectedAdvertiser});
            }

            initController();
            initServices();

        });
