'use strict';

angular.module('adelementAdminApp')
    .controller('AeAdvertisersController', function ($scope, $state, AeAdvertisers, ParseLinks) {

        $scope.aeAdvertiserss = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            AeAdvertisers.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.aeAdvertiserss = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.aeAdvertisers = {
                companyName: null,
                legalEntityName: null,
                contactName: null,
                contactEmail: null,
                contactPhone: null,
                billingContactName: null,
                billingEmail: null,
                billingPhoneNo: null,
                address: null,
                city: null,
                country: null,
                websiteUrl: null,
                androidApp: null,
                iosApp: null,
                timezone: null,
                language: null,
                billingCurrency: null,
                numberFormat: null,
                businessCategory: null,
                businessType: null,
                thirdPartyAdServer: null,
                allowAdTagAccess: null,
                creditLimit: null,
                creationTimestamp: null,
                approvingUser: null,
                status: null,
                note: null,
                id: null
            };
        };
    });
