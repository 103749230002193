/**
 * Created by rahul on 9/6/16.
 */
angular.module('adelementAdminApp')
    .controller('AdvertisersMgtController', function ($scope,$compile,$timeout,DTOptionsBuilder,dataTableInstance,$http,DTColumnBuilder,SocialService,$state) {
        $scope.panelids = ['advertisers-mgt'];
        $scope.DemoRequests = [];
        $scope.dtInstance = {};
        $scope.timeSchedulingInfo={};

        function createdRow(row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        }
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('paging',true)
            .withOption('order',[])
            .withDisplayLength(50)
            .withOption('createdRow', createdRow)
            .withOption('ajax', {
                url: "api/getalladvertisers",
                type:"POST",
                headers: {"X-CSRF-TOKEN":SocialService.getCSRF()},
            })
            .withFixedHeader({
                bottom: true,
                headerOffset:49
            }).withDOM('frtipl')
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('full_numbers');

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('Id'),
            DTColumnBuilder.newColumn('advertiserName').withTitle('Advertiser Name').renderWith(function(data,type,row) {
                var resVal = '<a ui-sref="advertiser-edit({id:'+row.id+'})">'+data+'</a>';
                return resVal;
            }),
            DTColumnBuilder.newColumn('email').withTitle('Email'),
            DTColumnBuilder.newColumn('creditLimit').withTitle('Credit Limit'),
            DTColumnBuilder.newColumn('noOfUsers').withTitle('Users'),
        ];

        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }
        $scope.check = function (data) {
            var jsonData = JSON.parse(data);
            var template = "";
            for(var index in jsonData){
                template += jsonData[index].date.split("T")[0]+" "+jsonData[index].time;
            }
            return jsonData;
            console.log(data);
        };

        $scope.initRefresh = function () {
            $timeout(function(){$scope.$emit('panel-refresh', $scope.panelids);}, 50);
        };
        $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
            }
        });

        $scope.createAdvertiser = function(){
            $state.go("advertiser-add");
        }
    });
