/**
 * Created by adelement on 21/4/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .controller('ChangeHistoryController', function ($scope,DTOptionsBuilder,ChangeHistoryService,ObjectDiff,dataTableInstance) {
        var vm = this;
        $scope.changeHistory = [];
        $scope.changeArray = [];
        $scope.dtInstance = {};
        vm.loadAllHistory = loadAllHistory;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('iDisplayLength',50).withOption('paging',true).withOption('order',[[0,'desc']])
            .withFixedHeader({
                bottom: true,
                headerOffset:49
            }).withDOM('frtipl');

        vm.loadAllHistory();
        $scope.prevChange = {};
        $scope.userEntityCol = {};
        $scope.advertiserEntityCol = {};
        $scope.campaignEntityCol = {};
        $scope.adgroupEntityCol = {};

        function loadAllHistory(){
            ChangeHistoryService.findAllChangeHistory().then(function(data){
                $scope.changeHistory = data;
                for(var index in $scope.changeHistory){
                    $scope.changeArray = [];
                    if($scope.changeHistory[index].prevChange != null ){
                        var previousVersion = JSON.parse($scope.changeHistory[index].prevChange),
                            currentVersion = JSON.parse($scope.changeHistory[index].entityValue);
                        var diff = ObjectDiff.diffOwnProperties(previousVersion, currentVersion);
                        if(diff.changed == 'object change'){
                            for(var diffIndex in diff.value){
                                if(diff.value[diffIndex].hasOwnProperty('changed') && diff.value[diffIndex].changed != 'equal'){
                                    if(diffIndex == 'lastModifiedDate' || diffIndex == 'createdDate')
                                        continue;
                                    var changes = diffIndex + ' changed from <del class="diff diff-key"> ' +diff.value[diffIndex].removed + ' </del> to <ins class="diff diff-key"> '+diff.value[diffIndex].added +'</ins>';
                                    $scope.changeArray.push(changes);
                                }
                            }
                        }
                        $scope.prevChange[$scope.changeHistory[index].id] =$scope.changeArray.toString();

                    }else{
                        $scope.prevChange[$scope.changeHistory[index].id] = "";
                    }
                    var entityVal = JSON.parse($scope.changeHistory[index].entityValue);
                    if($scope.changeHistory[index].entityType == "com.adelement.domain.User"){
                        $scope.userEntityCol[$scope.changeHistory[index].id] = entityVal.email;
                    }else if($scope.changeHistory[index].entityType == "com.adelement.domain.ProspectiveUsers"){
                        $scope.userEntityCol[$scope.changeHistory[index].id] = "Prospective user :: "+entityVal.email;
                    }else if($scope.changeHistory[index].entityType == "com.adelement.domain.AeAdvertisers"){
                        $scope.advertiserEntityCol[$scope.changeHistory[index].id] = entityVal.id + "::"+entityVal.advertiserName;
                    }else if($scope.changeHistory[index].entityType == ""){
                    }else if($scope.changeHistory[index].entityType == ""){
                    }
                }
            });
        };
        var instance = dataTableInstance.getDataTable();
            if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }
        $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
            }
        })
    });
