/**=========================================================
 * Module: sparkline.js
 * SparkLines Mini Charts
 =========================================================*/

(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .directive('sparkline', sparkline);

    function sparkline () {
        var directive = {
            restrict: 'EA',
            scope: {
              'sparkline': '=',
                'settings':'='
            },
            link: function (scope, element, attrs) {
                // var options = scope.sparkline,
                //     data = element.data();
                // var settings = scope.settings?scope.settings:"html";
                //
                // if(!options) // if no scope options, try with data attributes
                //     options = data;
                // else
                // if(data) // data attributes overrides scope options
                //     options = angular.extend({}, options, data);
                //
                // options.type = options.type || 'bar'; // default chart is bar
                // options.disableHiddenCheck = true;
                //
                // element.sparkline(settings, options);
                //
                // // if(options.resize) {
                // //     $($window).resize(function(){
                // //         $element.sparkline(settings, options);
                // //         //alert("sfsdfsfsdsf");
                // //     });
                // // }

            },
            controller: Controller
        };
        return directive;

    }
    Controller.$inject = ['$scope', '$element', '$timeout', '$window'];
    function Controller($scope, $element, $timeout, $window) {
      var runSL = function(){
        initSparLine();
      };

      $timeout(runSL);

        $scope.vals = JSON.stringify($scope.$parent.vals);
        //$scope.$parent.$watch($scope.$parent.vals2,function(newv,oldv){
        //
        //           runSL();
        //
        //});
        $scope.$watch('settings',function(newv,oldv){
            if(oldv!=newv && newv!=undefined && oldv!=undefined){
                //console.log("changed",$element.context.id);
                $timeout(runSL);
            }

        });
        $scope.$on('overlaychange', function(event, args){
            if(args.id == $element.context.id) {


                console.log("change detected", args);

                var options = $scope.sparkline,
                    data = $element.data();

                if (!options) // if no scope options, try with data attributes
                    options = data;
                else if (data) // data attributes overrides scope options
                    options = angular.extend({}, options, data);

                options.type = options.type || 'bar'; // default chart is bar
                options.disableHiddenCheck = true;
                var dats = args.val;
                $element.sparkline(dats, options);
            }
        });

      function initSparLine() {
        var options = $scope.sparkline,
            data = $element.data();
						//alert(JSON.stringify(data));
						//console.log("CData: ", data)
          var settings = $scope.settings?$scope.settings:"html";

        if(!options) // if no scope options, try with data attributes
          options = data;
        else
          if(data) // data attributes overrides scope options
            options = angular.extend({}, options, data);

        options.type = options.type || 'bar'; // default chart is bar
        options.disableHiddenCheck = true;

        $element.sparkline(settings, options);

        if(options.resize) {
          $($window).resize(function(){
            $element.sparkline(settings, options);
              //alert("sfsdfsfsdsf");
          });
        }
      }

    }


})();
