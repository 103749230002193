'use strict';

angular.module('adelementAdminApp')
    .controller('RegisterController2', function ($scope, $translate, $timeout, Auth,USER_TITLE_CONST,WEBSITE_UNIQUE_VISITORS_CONST,APP_UNIQUE_USERS_CONST) {
        $scope.success = null;
        $scope.error = null;
        $scope.doNotMatch = null;
        $scope.errorUserExists = null;
        $scope.registerAccount = {};
        $scope.userTitleConst = USER_TITLE_CONST;
        $scope.websitUniqueVisitorsConst = WEBSITE_UNIQUE_VISITORS_CONST;
        $scope.appUniqueUsersConst = APP_UNIQUE_USERS_CONST;
        $scope.validFormError = false;
        $scope.data = {};
        $scope.userTimeSlots = [{prefferedDate:"",prefferedslot:{},myDatetimeRange:{
            "time": {
                "from": 420,
                "to": 1035,
                "dFrom": 0,
                "dTo": 1440,
                "step": 15,
                "minRange": 15,
                "hours24": false
            },
            "hasDatePickers": false,
            "hasTimeSliders": true
        }}];
        $scope.addTimeSlot = function (data) {
            if($scope.userTimeSlots.length < 3){
                $scope.userTimeSlots.push({prefferedslot:{},myDatetimeRange:{
                    "time": {
                        "from": 420,
                        "to": 1035,
                        "dFrom": 0,
                        "dTo": 1440,
                        "step": 15,
                        "minRange": 15,
                        "hours24": false
                    },
                    "hasDatePickers": false,
                    "hasTimeSliders": true
                }});
            }
        };

        $scope.removeTimeSlot = function (data) {
           if($scope.userTimeSlots.length > 0){
               $scope.userTimeSlots.pop();
           }
        };




        $timeout(function (){angular.element('[ng-model="registerAccount.login"]').focus();});

        $scope.myDatetimeRange = {
            "time": {
                "from": 420,
                "to": 1035,
                "dFrom": 0,
                "dTo": 1440,
                "step": 15,
                "minRange": 15,
                "hours24": false
            },
            "hasDatePickers": false,
            "hasTimeSliders": true
        };
        $scope.myDatetimeLabels = {
            date: {
                from: 'Start date',
                to: 'End date'
            }
        };
        var tz = moment();
        var getTimeZone = function (){
            var offset = new Date().getTimezoneOffset(),
                o = Math.abs(offset);
            return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
        }
        $scope.registerAccount.timeZone = "GMT "+getTimeZone();

        var timeConvertFunction = function (input) {
            var type=false;
            var
                hours = parseInt( input / 60, 10 ),
                minutes = (input - (hours * 60)) < 10 ? '0' + (input - (hours * 60)) : input - (hours * 60),
                meridian = type ? ':00' : ( hours >= 12 && hours !== 24 ? ' PM' : ' AM' );

            return (!type && hours > 12 ? (hours === 24 ? '00' : (hours - 12 < 10 ? '0': '' ) + (hours - 12) ) : (hours < 10 ? '0' : '') + hours) + ':' + minutes + meridian;
        };

        $scope.register2 = function () {
            if(this.reg.registerForm.$valid){
                var timeSlots = [];
                for(var timeslot in $scope.userTimeSlots){
                    var prefTimeSlot = {};
                    prefTimeSlot.time = timeConvertFunction($scope.userTimeSlots[timeslot].myDatetimeRange.time.from) + " to " + timeConvertFunction($scope.userTimeSlots[timeslot].myDatetimeRange.time.to);
                    prefTimeSlot.date = $scope.userTimeSlots[timeslot].prefferedDate;

                    timeSlots.push(prefTimeSlot);
                }
                $scope.registerAccount.timeSlot  = JSON.stringify(timeSlots);
                // $scope.registerAccount.timeSlot  = timeSlots;
                $scope.registerAccount.langKey = $translate.use();
                $scope.doNotMatch = null;
                $scope.error = null;
                $scope.errorUserExists = null;
                $scope.errorEmailExists = null;

                Auth.createAccount($scope.registerAccount).then(function () {
                    $scope.success = 'OK';
                    $scope.validFormError = false;
                }).catch(function (response) {
                    $scope.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        $scope.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        $scope.errorEmailExists = 'ERROR';
                    } else {
                        $scope.error = 'ERROR';
                    }
                });
            }else {
                $scope.validFormError = true;

            }
        };
    });

(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .directive('masked', masked);

    function masked () {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element) {
            var $elem = $(element);
            if($.fn.inputmask)
                $elem.inputmask();
        }
    }

})();
