(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('entity-audit', {
            parent: 'entity',
            url: '/entity-audits',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.pagetitles.entity-audit.title'
            },
            templateUrl: 'app/admin/entity-audit/entity-audits.html',
            controller: 'EntityAuditController',
            controllerAs: 'vm',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pagetitles');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
