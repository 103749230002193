'use strict';

angular.module('adelementAdminApp')
    .factory('CreativeManagerService', function ($http) {
       var url = "api/creative-manager/";
       return {
            getCreatives: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getCreativeByAdvId: function (advertiserId) {
               return $http.post(url+advertiserId).then(function (response) {
                   return response.data;
                });
            },
            getCreativesByConcept: function () {
                return $http.get(url+"concept").then(function (response) {
                    return response.data;
                });
            },
            getCreativeByAdvertiserId: function (advertiserId) {
               return $http.get(url+advertiserId).then(function (response) {
                   return response.data;
                });
            },

            getAdParamsById : function(){
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
       }
    })
    .factory('CreativeManagerAdtagService', function ($http) {
       var url = "api/creatives/adtags";
       return {
            getAdtagData: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getSubcategories: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getCountryLanguages: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getTechnicalAttributes: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getSensitiveAttributes: function () {
               return $http.get(url).then(function (response) {
                   return response.data;
                });
            },
            saveCreativeAdtagFormAdvId: function (advId, data) {
               return $http.post(url+"/"+advId, data).then(function (response) {
                  return response.data;
             });
            },
            saveNewCreativeAdtagSize: function (advId,creativeMappingId ,data) {
               return $http.post(url+"/"+advId+"/"+creativeMappingId, data).then(function (response) {
                  return response.data;
             });
            },
            updateCreativeAdtag: function (advId, creativeMappingId, data) {
               return $http.post(url+"/"+advId+"/"+creativeMappingId,data).then(function (response) {
                   return response.data;
               });
            },
           getCreativeAdtag: function (advId,creativeMappingId) {
               return $http.get(url+"/"+advId+"/"+creativeMappingId).then(function (response) {
                 return response.data;
             });
           },
           advertiserTimezone: function (advertiserId) {
              var url = "api/campaign-manager/getAdvertiser/"+advertiserId;
              return $http.get(url).then(function (response) {
                  return response.data;
              });
           }
       }
    })
    .factory('CreativeManagerInbannerAdtagService', function ($http) {
        var url = "api/creatives/inbanner-video";
        return {
            getInbannerAdtagData: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getSubcategories: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getCountryLanguages: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getTechnicalAttributes: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getSensitiveAttributes: function () {
               return $http.get(url).then(function (response) {
                   return response.data;
                });
            },
            saveCreativeInbannerAdtagFormAdvId: function (advId, data) {
               return $http.post(url+"/"+advId, data).then(function (response) {
                  return response.data;
             });
            },
            updateCreativeInbannerAdtag: function (advId, creativeMappingId, data) {
               return $http.post(url+"/"+advId+"/"+creativeMappingId, data).then(function (response) {
                   return response.data;
               });
            },
           getCreativeInbannerAdtag: function (advId,creativeMappingId) {
               return $http.get(url+"/"+advId+"/"+creativeMappingId).then(function (response) {
                 return response.data;
             });
           },
           saveNewCreativeInbannerAdtagSize: function (advId,creativeMappingId ,data) {
              return $http.post(url+"/"+advId+"/"+creativeMappingId, data).then(function (response) {
                 return response.data;
              });
           },
           advertiserTimezone: function (advertiserId) {
               var url = "api/campaign-manager/getAdvertiser/"+advertiserId;
               return $http.get(url).then(function (response) {
                   return response.data;
               });
           }
        }
    })
    .factory('GetAdvertiserIDName', function ($resource) {
        return $resource('api/aeadvertisersidname', {}, {
            'query': { method: 'GET', isArray: true},
        })
    })

    .factory('GetCretivesByAdvID', function ($resource) {
        return $resource('api/creative-manager/:advertiserId', {}, {
            'query': { method: 'POST', isArray: true},
            'get': {
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        })
    })

    .factory('CreativeManagerUploadAdsService', function ($http) {
       var url = "api/creatives/upload-ads";
       var config = {
           	   	transformRequest: angular.identity,
           	   	transformResponse: angular.identity,
       	   		headers : {
       	   			'Content-Type': undefined,
//       	   			'Accept': 'multipart/form-data, */*'
//       	   			'Accept': 'application/json, */*'
       	   	    }
              };
       return {
            getUploadAdsData: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getSubcategories: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getCountryLanguages: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getTechnicalAttributes: function () {
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getSensitiveAttributes: function () {
               return $http.get(url).then(function (response) {
                   return response.data;
                });
            },
            saveCreativeUploadAdFormAdvId: function (advId, data) {
               return $http.post(url+"/"+advId, data, config).then(function (response) {
                  return response.data;
             });
            },
            saveNewCreativeUploadAdSize: function (advId,creativeMappingId ,data) {
               return $http.post(url+"/"+advId+"/"+creativeMappingId, data, config).then(function (response) {
                  return response.data;
             });
            },
            updateCreativeUploadAd: function (advId, creativeMappingId, data) {
               return $http.post(url+"/"+advId+"/"+creativeMappingId,data, config).then(function (response) {
                   return response.data;
               });
            },
           getCreativeUploadAd: function (advId,creativeMappingId) {
               return $http.get(url+"/"+advId+"/"+creativeMappingId).then(function (response) {
                 return response.data;
             });
           },
       }
    });
