/**
 * Created by maitray on 27/7/17.
 */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('campaign-manager', {
                parent: 'entity',
                url: '/campaign-manager',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/campaign-manager/campaign-create.html',
                controller: 'CampaignManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$stateParams', function ($translate, $translatePartialLoader, $stateParams) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('campaign-manager.new-campaign', {
                url: '/new/campaign/{advertiserId}?editing',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/campaign-manager/campaign-create-campaign.html',
                controller: 'CampaignCreateEditController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$stateParams', function ($translate, $translatePartialLoader, $stateParams) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    campaign: ['$state','$stateParams','$http',function ($state,$stateParams,$http) {
                        if($stateParams.hasOwnProperty("editing")){
                            var editingCampaignId = $stateParams.editing;
                            if(editingCampaignId == undefined){
                                return undefined;
                            }
                            else{
                                var url = "api/campaign-manager/get/campaign/"+$stateParams.advertiserId+"/"+$stateParams.editing;
                                return $http.get(url).then(function (response) {
                                    return response.data;
                                });
                            }
                        }
                        return undefined;
                    }],

                    // added by Snehal
                    campaignObjective: [function () {
                        return undefined;
                    }],
                    advertiserFeatureRestrictions: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAeAdvertisersFeature/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    conversionTrackings: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getConversionTrackings/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    campaignConversionTrackings: [function () {
                        return undefined;
                    }],
                    cloneCampaign: [function () {
                        return undefined;
                    }]
                }
            })
            .state('campaign-manager.new-adgroup', {
                url: '/new/adgroup/{advertiserId}/{campaignId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/campaign-manager/campaign-create-adgroup.html',
                controller: 'AdgroupCreateEditController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$stateParams', function ($translate, $translatePartialLoader, $stateParams) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    campaign: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/get/campaign/"+$stateParams.advertiserId+"/"+$stateParams.campaignId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    adgroup: [function () {
                        return undefined;
                    }],
                    adgroupPlacementBidOptProps: [function () {
                        return undefined;
                    }],

                    // added by Snehal
                    advertiserFeatureRestrictions: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAeAdvertisersFeature/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    cloneAdgroup: [function () {
                        return undefined;
                    }],
                    appnexusMapping: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAppnexusMapping/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })
            .state('campaign-manager.edit-campaign', {
                url: '/edit/campaign/{advertiserId}/{campaignId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/campaign-manager/campaign-create-campaign.html',
                controller: 'CampaignCreateEditController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$stateParams', function ($translate, $translatePartialLoader, $stateParams) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    campaign: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/get/campaign/"+$stateParams.advertiserId+"/"+$stateParams.campaignId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    // added by Snehal
                    advertiserFeatureRestrictions: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAeAdvertisersFeature/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    conversionTrackings: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getConversionTrackings/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    campaignConversionTrackings: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getCampaignConversionTracker/"+$stateParams.advertiserId+"/"+$stateParams.campaignId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    cloneCampaign: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getCloneCampaign/"+$stateParams.advertiserId+"/"+$stateParams.campaignId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })
            .state('campaign-manager.edit-adgroup', {
                url: '/edit/adgroup/{advertiserId}/{campaignId}/{adgroupId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/campaign-manager/campaign-create-adgroup.html',
                controller: 'AdgroupCreateEditController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$stateParams', function ($translate, $translatePartialLoader, $stateParams) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    campaign: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/get/campaign/"+$stateParams.advertiserId+"/"+$stateParams.campaignId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    adgroup: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/get/adgroup/"+$stateParams.advertiserId+"/"+$stateParams.campaignId+"/"+$stateParams.adgroupId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    adgroupPlacementBidOptProps: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdgroupPlacementBidOptProp/"+$stateParams.advertiserId+"/"+$stateParams.campaignId+"/"+$stateParams.adgroupId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],

                    // added by Snehal
                    advertiserFeatureRestrictions: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAeAdvertisersFeature/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    cloneAdgroup: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getCloneAdgroup/"+$stateParams.advertiserId+"/"+$stateParams.adgroupId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    appnexusMapping: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAppnexusMapping/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })
            .state('campaign-manager.edit-assign-creatives', {
                url: '/edit/assign/{advertiserId}/{campaignId}/{adgroupId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/campaign-manager/campaign-create-assign-creatives.html',
                controller: 'AssignCreativeNewEditController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$stateParams', function ($translate, $translatePartialLoader, $stateParams) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    campaign: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/get/campaign/"+$stateParams.advertiserId+"/"+$stateParams.campaignId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }],
                    adgroup: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/get/adgroup/"+$stateParams.advertiserId+"/"+$stateParams.campaignId+"/"+$stateParams.adgroupId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

    });
