/**
 * Created by maitray on 27/7/17.
 */


angular.module('adelementAdminApp')
    .controller('CampaignManagerController', function ($scope,$timeout,$stateParams,$state) {
        $scope.advertiserId = "0";
        $scope.campaignId = "0";
        $scope.adgroupId = "0";
        $scope.adgroup = {};
        $scope.editModeCampaign = false; // false is create / true is edit
        $scope.editModeAdgroup = false; // false is create / true is edit
        $scope.editModeAssign = false; // false is create / true is edit
        $scope.targetingSettings = undefined;

        // Added by Snehal
        $scope.campaignObjective = undefined;
    });

angular.module('adelementAdminApp')
    .controller('CampaignCreateEditController', function ($scope,$timeout,$stateParams,advertiser,campaign,$state,CampaignManagerService,$filter, adgroupPlacementBidOptProperties,advertiserFeatureRestrictions,conversionTrackings,campaignConversionTrackings,$http,cloneCampaign) {
        $scope.advertiserId = $stateParams.advertiserId;
        $scope.$parent.advertiserId = $stateParams.advertiserId;
        $scope.advertiser = advertiser;
        $scope.editMode = false; // false is create / true is edit
        $scope.adgroupId = 5068;
        $scope.budgetType = ["Daily","Total"];
        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.startDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            minDate:moment()
        };
        $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.startDateOpened = false;
        $scope.endDateOpened = false;

        // added by Snehal
        $scope.showSecondaryConversionTracker = false;

        $scope.initDate = new Date('2019-10-20');
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        $scope.campaign = {};
        $scope.cloneCampaign = {};
        var fcapDurationMinutes = {HOUR:60,DAY:1440,WEEK:10080,MONTH:43200};
        var m = moment();
        var today = m.set({hour:0,minute:0,second:0,millisecond:0}).toDate();

        var currentUserTime;
        var offset = 0;
        if($scope.advertiser != undefined) {
            currentUserTime = $scope.advertiser.currentTime;
            offset = new Date().getTimezoneOffset();
        }

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
                else{
                    $scope.campaign.budgets[index].startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;
                }
                else{
                    $scope.campaign.budgets[index].endDateOpened = true;
                }
            }
        };

        // $scope.checkDateLimits = function(selectedDate,type,dateType,index) {
        //     var currentDate = new Date(selectedDate);
        //     var upperBounds;
        //     var lowerBounds;
        //     if(type == "budget") {
        //         if(dateType == "startDate") {
        //             if(index == 0) {
        //                 lowerBounds = new Date($scope.campaign.startDate);
        //                 upperBounds = new Date($scope.campaign.budgets[0].endDate?$scope.campaign.budgets[0].endDate:$scope.campaign.endDate);
        //             }
        //             else{
        //                 lowerBounds = new Date($scope.campaign.budgets[index-1].endDate);
        //                 upperBounds = new Date($scope.campaign.budgets[index].endDate);
        //             }
        //         }
        //         else {
        //             //endDate
        //             if(index == 0) {
        //                 lowerBounds = new Date($scope.campaign.budgets[0].startDate?$scope.campaign.budgets[0].startDate:$scope.campaign.startDate);
        //                 upperBounds = new Date($scope.campaign.endDate);
        //             }
        //             else {
        //                 lowerBounds = new Date($scope.campaign.budgets[index].startDate);
        //                 upperBounds = new Date($scope.campaign.endDate);
        //             }
        //         }
        //     }
        //     else {
        //         //campaign Dates
        //         if(dateType == "startDate") {
        //             lowerBounds = new Date("01/01/2001");
        //             upperBounds = new Date("09/09/9999");
        //         }
        //         else {
        //             //endDate
        //             lowerBounds = new Date($scope.campaign.startDate);
        //             upperBounds = new Date("09/09/9999");
        //         }
        //     }
        //     return currentDate < upperBounds && currentDate > lowerBounds;
        // };
        $scope.startLessThanToday = function(which,index) {
            if($scope.campaignId != undefined) {
                if (which == 0) {
                    var startD = new Date($scope.campaign.startDate);
                    return today > startD;
                } else {
                    var startD = new Date($scope.campaign.budgets[index].startDate);
                    return today > startD;
                }
            }
            else{
                return false;
            }
        };
        $scope.checkDateLimits = function(selectedDate){
            var currentDate = new Date(selectedDate);
            var upperBounds = new Date();
            var lowerBounds = new Date('01/01/1900');

            return currentDate < upperBounds && currentDate > lowerBounds;

        };
        $scope.checkDateLimitsCampaign = function(selectedDate,dateType) {
            var currentDate = new Date(selectedDate);
            var upperBounds;
            var lowerBounds;

            if(dateType == "startDate") {
                var lowestDate = campaign.budgets[0].startDate;
                for(var i=0; i<$scope.campaign.budgets.length; i++) {
                    var finalDate = new Date($scope.campaign.budgets[i].startDate)
                }
            }
            else {
                lowerBounds = new Date($scope.campaign.startDate);
                upperBounds = new Date("09/09/9999");
            }

            return currentDate < upperBounds && currentDate > lowerBounds;
        };

        $scope.checkStartMoreThanEndDate = function (startValue,endValue) {
            if(startValue && endValue){
                var startDate = new Date(startValue);
                var endDate = new Date(endValue);
                return startDate < endDate;
            }
            else if(!endValue) {
                return true;
            }
            else {
                return false;
            }
        };
        $scope.checkStartMoreThanEndDateBudget = function (value,startOrEnd,index) {
                if(startOrEnd == 0){
                    if(value) {
                        var startDate = new Date(value);
                        var data = angular.copy($scope.campaign.budgets[index].endDate);
                        var endDate = data == null?null:new Date(data);
                        if(endDate != null) {
                            // alert(endDate.toDateString())
                            return startDate < endDate;
                        }
                        else {
                            return true;
                        }
                    }
                    return false
                }
                else {
                    if(value != null) {
                        var endDate = new Date(value);
                        var data = angular.copy($scope.campaign.budgets[index].startDate);
                        var startDate = new Date(data);
                        return startDate < endDate;
                    }
                    return true;
                }
        };

        $scope.checkOverLappingDates = function (viewValue,index,type) {
            var currentDate = new Date(viewValue);
//            $scope.campaignForm.$setValidity();
            for(var i=0;i<$scope.campaign.budgets.length;i++) {
                if(index!=i || type == 0) {
                    var budget = $scope.campaign.budgets[i];
                    var startDate;
                    var endDate;
                    if (budget.startDate && budget.startDate != "") {
                        startDate = new Date(budget.startDate);
                    }
                    if (budget.endDate && budget.endDate != "") {
                        endDate = new Date(budget.endDate);
                    }
                    else if(budget.type=="daily"){
                        endDate = new Date("09/09/9999");
                    }
                    if (currentDate > startDate && currentDate < endDate) {
                        return false;
                    }
                    else {

                        if(type == 0) {
                            if($scope.campaignForm["budgetStart"+i.toString()]) {
                                    if($scope.campaignForm["budgetStart"+i.toString()].$error && $scope.campaignForm["budgetStart"+i.toString()].$error.overlapCampaign)
                                        $scope.campaignForm["budgetStart"+i.toString()].$validate();
                             }
                            if($scope.campaignForm["budgetEnd"+i.toString()]) {
                                    if($scope.campaignForm["budgetEnd"+i.toString()].$error && $scope.campaignForm["budgetEnd"+i.toString()].$error.overlapCampaign)
                                        $scope.campaignForm["budgetEnd"+i.toString()].$validate();
                             }
                        }
                    }
                }
            }

            return true;
        };

        $scope.checkOverLappingDatesForCampaign = function (viewValue,which) {
            var currentDate = new Date(viewValue);
            var campStartDate = new Date($scope.campaign.startDate);
            var campEndDate = new Date($scope.campaign.endDate);
            // added by Snehal
            var todaysDate = new Date($scope.advertiser.currentTime);

//            if($scope.campaignForm["startDate"]) {
//                    if($scope.campaignForm["startDate"].$error && $scope.campaignForm["startDate"].$error.overlapBudget)
//                        $scope.campaignForm["startDate"].$validate();
//            }
//            if($scope.campaignForm["endDate"]) {
//                    if($scope.campaignForm["endDate"].$error && $scope.campaignForm["endDate"].$error.overlapBudget)
//                        $scope.campaignForm["endDate"].$validate();
//            }
            if(campStartDate>=todaysDate){
                if(which == 1 && viewValue == null) {
                    return true;
                }
                if($scope.campaign.endDate == null) {
                    return currentDate >= campStartDate;
                }
                return currentDate >= campStartDate && currentDate <= campEndDate;
            }
            else{
                return true;
            }
        };

        function initCampaign() {
            $scope.campaign.campaignType = "video";

            $scope.campaign.startDate = $filter('date')(moment(currentUserTime).toDate(), 'yyyy-MM-dd HH:mm:ss');
            $scope.campaign.endDate = null;
            $scope.campaign.adRotation = "ROTATE";
            $scope.campaign.fcapDuration = "DAY";
            $scope.campaign.fcapDurationMinutes = 1440;
            $scope.campaign.budgets = [{startDate:dateFiltering(moment(new Date($scope.campaign.startDate)).toDate()),endDate:null,budgetType:"daily",spendLimit:null,clicksLimit:null,impressionsLimit:null,deliveryPace:"standard",startDateOpened:false,endDateOpened:false,startOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment().toDate()
            },endOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment().toDate()
            }}];
            calculateFcapNumber();

            // added ny Snehal
            $scope.campaign.trackers = [{clickConversionWindow: "",viewConversionWindow: "",conversionTracking:""}];
            $scope.campaign.tracker = {conversionTracking:[],
                                       clickConversionWindow: [],
                                       viewConversionWindow:[]};
        }
        function calculateFcapDurationMinutes() {
            var fcapDuration = fcapDurationMinutes[$scope.campaign.fcapDuration]*$scope.campaign.fcapDurationNumber;
            $scope.campaign.fcapDurationMinutes = fcapDuration;
        }
        function calculateFcapNumber() {
            $scope.campaign.fcapDurationNumber = $scope.campaign.fcapDurationMinutes / fcapDurationMinutes[$scope.campaign.fcapDuration];
        }

        function stateDecide(){
            $scope.campaignId = $stateParams.campaignId;

            if($scope.campaignId == undefined){
                // if($stateParams.hasOwnProperty("editing")){
                //     $scope.campaign = campaign;
                //     $scope.campaign.budgets = [{startDate:campaign.startDate,endDate:campaign.endDate,type:"daily",budget:(campaign.dailyBudgetCents/100).toString(),clicksLimit:campaign.clicksLimit.toString(),impressionsLimit:campaign.impressionsLimit.toString(),delivery:"standard",minDate:"",maxDate:"",startDateOpened:false,endDateOpened:false,startOption:{
                //         formatYear: 'yy',
                //         startingDay: 1,
                //         minDate : moment()
                //     },endOption:{
                //         formatYear: 'yy',
                //         startingDay: 1,
                //         minDate : moment()
                //     }}];
                // }
                // else{
                    initCampaign();
                // }
                $scope.$parent.editModeCampaign = false;
            }
            else{
                $scope.cloneCampaign = cloneCampaign;
                $scope.$parent.editModeCampaign = true;
                $scope.$parent.campaignId = $scope.campaignId;
                $scope.campaign = campaign;

                var totalBudgetCents = campaign.dailyBudgetCents;
                var impressionsLimit = campaign.impressionsLimit;
                var clicksLimit = campaign.clicksLimit;

                if(campaign.dailyBudgetCents == null || campaign.dailyBudgetCents == undefined){
                    totalBudgetCents = undefined;
                }
                else{
                    totalBudgetCents = (totalBudgetCents/100).toString();
                }
                if(campaign.impressionsLimit == null || campaign.impressionsLimit == undefined){
                    impressionsLimit = undefined;
                }
                if(campaign.clicksLimit == null || campaign.totalBudgetCents == undefined){
                    clicksLimit = undefined;
                }

                for(var i=0;i<$scope.campaign.budgets.length;i++) {
                    // $scope.campaign.budgets[i].startDate = dateFiltering(new Date($scope.campaign.budgets[i].startDate));
                    $scope.campaign.budgets[i].startDate = $filter('date')(moment($scope.campaign.budgets[i].startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                    if($scope.campaign.budgets[i].endDate.indexOf("9999") == -1) {
                        // $scope.campaign.budgets[i].endDate = dateFiltering(new Date($scope.campaign.budgets[i].endDate));
                        $scope.campaign.budgets[i].endDate = $filter('date')(moment($scope.campaign.budgets[i].endDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                    }
                    else {
                        $scope.campaign.budgets[i].endDate = null;
                    }
                    $scope.campaign.budgets[i].minDate = "";
                    $scope.campaign.budgets[i].maxDate = "";
                    $scope.campaign.budgets[i].startDateOpened = false;
                    $scope.campaign.budgets[i].endDateOpened = false;
                    $scope.campaign.budgets[i].startOption = {
                        formatYear: 'yy',
                        startingDay: 1,
                        minDate : moment()
                    };
                    $scope.campaign.budgets[i].endOption = {
                        formatYear: 'yy',
                        startingDay: 1,
                        minDate : moment()
                    };
                    $scope.campaign.budgets[i].spendLimit = $scope.campaign.budgets[i].spendLimit/100;
                }
                // $scope.campaign.budgets = [{startDate:campaign.startDate,endDate:campaign.endDate,type:"daily",budget:totalBudgetCents,clicksLimit:clicksLimit,impressionsLimit:impressionsLimit,delivery:"standard",minDate:"",maxDate:"",startDateOpened:false,endDateOpened:false,startOption:{
                //     formatYear: 'yy',
                //     startingDay: 1,
                //     minDate : moment()
                // },endOption:{
                //     formatYear: 'yy',
                //     startingDay: 1,
                //     minDate : moment()
                // }}];
                // $scope.campaign.startDate = dateFiltering(new Date($scope.campaign.startDate));
                $scope.campaign.startDate = $filter('date')(moment($scope.campaign.startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                // alert($scope.campaign.startDate);
                if($scope.campaign.endDate.indexOf("9999") == -1) {
                    // $scope.campaign.endDate = dateFiltering(new Date($scope.campaign.endDate));
                    $scope.campaign.endDate = $filter('date')(moment($scope.campaign.endDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                }
                else {
                    $scope.campaign.endDate = null;
                }
                calculateFcapNumber();

                // added by Snehal
                adgroupPlacementBidOptProp();

            }
            $scope.editMode = $scope.$parent.editModeCampaign;

            // added by Snehal
            $scope.conversionTrackingData = conversionTrackings;
        }

        function initController() {
            if($scope.stateDecide == 0){

            }
            else{

            }
        }
        function initServices() {

        }
        function dateFiltering(dateObj){
            return $filter('date')(dateObj, 'yyyy-MM-dd HH:mm:ss');
        }

        $scope.saveCampaign = function() {
            calculateFcapDurationMinutes();

            // added by Snehal
            campaignObjectiveSelection();

            if($scope.editMode) {
                var state = "campaign-manager.new-adgroup";
                var stateParameters = {};
                if($scope.$parent.adgroupId == 0) {
                    state = "campaign-manager.new-adgroup";
                    stateParameters = {advertiserId:$scope.advertiserId,campaignId:$scope.campaignId};
                }
                else {
                    if($scope.campaignId == $scope.$parent.adgroup.campaignId) {

                        state = "campaign-manager.edit-adgroup";
                        stateParameters = {
                            advertiserId: $scope.advertiserId,
                            campaignId: $scope.campaignId,
                            adgroupId: $scope.$parent.adgroupId
                        };
                    }
                    else {
                        state = "campaign-manager.new-adgroup";
                        stateParameters = {advertiserId:$scope.advertiserId,campaignId:$scope.campaignId};

                    }
                }
                CampaignManagerService.saveCampaign($scope.advertiserId,$scope.campaign).then(function (response) {
                    $state.go(state,stateParameters);
                });
            }
            else {
                CampaignManagerService.saveCampaign($scope.advertiserId,$scope.campaign).then(function (response) {
                    $scope.campaignId = response.campaignId;
                    $scope.$parent.campaignId = $scope.campaignId;
                    $scope.$parent.editModeCampaign = true;
                    $state.go('campaign-manager.new-adgroup',{advertiserId:$scope.advertiserId,campaignId:$scope.campaignId});
                });
            }
        };
        //add budget rows
        $scope.addBudget = function(){
            $scope.campaign.budgets.push({startDate:"",endDate:null,budgetType:"daily",spendLimit:null,clicksLimit:null,impressionsLimit:null,deliveryPace:"standard",minDate:"",maxDate:"",startDateOpened:false,endDateOpened:false,startOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            },endOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            }});
        };

        //delete budget from the list
        $scope.deleteBudget = function(index) {

            var cmpBudget = $scope.campaign.budgets[index];
            if(cmpBudget.hasOwnProperty("id")) {
                CampaignManagerService.deleteBudgetCmp($scope.advertiserId,$scope.campaign.campaignId,cmpBudget.id).then(function(response){
                    if(response.error) {
                        alert("not able to delete budget");
                    }
                    else {
                        $scope.campaign.budgets.splice(index,1);
                    }
                });

            }
            else {
                $scope.campaign.budgets.splice(index,1);
            }

        };

        $scope.dateFormat = function (data) {
            $scope.campaign[data] = $filter('date')($scope.campaign[data], 'yyyy-MM-dd HH:mm:ss');
        };
        $scope.dateFormatInBudget = function (data,index,parent) {
          $scope.campaign[parent][index][data] =  $filter('date')($scope.campaign[parent][index][data], 'yyyy-MM-dd HH:mm:ss');
        };

        stateDecide();
        initController();
        initServices();

        function campaignObjectiveSelection(){
            $scope.adgroupBidOpt ={}
            $scope.adgroupBidOpt.targetType =$scope.campaign.campaignObjective;
            $scope.$parent.campaignObjective = $scope.adgroupBidOpt.targetType;
        }

        // added by Snehal
        $scope.advertiserFeatureRestrictions = advertiserFeatureRestrictions;
        $scope.$watchCollection('adgroupBidOpt.targetType', function () {
            if($scope.adgroupBidOpt){
                $scope.campaignObjective = angular.copy($scope.adgroupBidOpt.targetType);
                $scope.$parent.campaignObjective =  angular.copy($scope.adgroupBidOpt.targetType);
                adgroupPlacementBidOptProperties.targetType =  angular.copy($scope.adgroupBidOpt.targetType);
            }
        });


        // added by Snehal
        function adgroupPlacementBidOptProp() {
            if($scope.campaign){
                $scope.adgroupBidOpt = {};
                $scope.campaign.tracker = {conversionTracking:[],
                                           clickConversionWindow: [],
                                           viewConversionWindow:[]};
                $scope.campaign.trackers = [];
                $scope.adgroupBidOpt.targetType = $scope.campaign.campaignObjective;
                var count = 0;
                $scope.campaignConversionTrackings = campaignConversionTrackings;
                if($scope.campaignConversionTrackings.length >0){
                    $scope.campaignConversionTrackings.forEach(function(data){
                       $scope.campaign.tracker.conversionTracking[count] = data.id.conversionId.toString();
                       $scope.campaign.tracker.clickConversionWindow[count] = data.conversionClickWindow;
                       $scope.campaign.tracker.viewConversionWindow[count] = data.conversionViewWindow;
                       $scope.campaign.trackers.push({
                           clickConversionWindow: "",
                           viewConversionWindow: ""
                       });
                       count+=1;
                    })
                }
                else{
                    $scope.campaign.trackers.push({
                        clickConversionWindow: "",
                        viewConversionWindow: ""
                    });
                }
            }
            else{
                $scope.campaign.trackers.push({
                    clickConversionWindow: "",
                    viewConversionWindow: ""

                });
            }
        }

        $scope.setConversionWindow = function(conversionTracking,index){
//            $scope.campaign.trackers = [];
            $scope.conversionTrackingData.forEach(function(data){
                if(data.conversionId == conversionTracking){
//                    $scope.campaign.tracker.conversionTracking[index] = conversionTracking;
                    $scope.campaign.tracker.clickConversionWindow[index] = data.conversionClickWindow;
                    $scope.campaign.tracker.viewConversionWindow[index] = data.conversionViewWindow;
                }
            });
        };

        $scope.setSecondaryConversionWindow = function(secondaryConversionTracking){
            $scope.conversionTrackingData.forEach(function(data){
                if(data.conversionId == secondaryConversionTracking){
                    $scope.campaign.clickSecondaryConversionWindow = data.conversionClickWindow;
                    $scope.campaign.viewSecondaryConversionWindow = data.conversionViewWindow;
                }
            });
        }

        $scope.secondaryConversionTracker = function(){
            $scope.showSecondaryConversionTracker = !$scope.showSecondaryConversionTracker;
        }

        // add Tracker
        $scope.addTracker = function(){
//            $scope.adgroup.tracker =[];
            $scope.campaign.trackers.push({
                clickConversionWindow: "",
                viewConversionWindow: "",

            });
        };

        //delete Tracker from the list
        $scope.deleteTracker = function(index) {
            $scope.campaign.trackers.splice(index,1);
            $scope.campaign.tracker.clickConversionWindow.splice(index,1);
            $scope.campaign.tracker.conversionTracking.splice(index,1);
            $scope.campaign.tracker.viewConversionWindow.splice(index,1);
        };

        $scope.campaignSearch = function(val){
            var advertiserId = $scope.advertiserId;
            return $http.get('/api/campaign-manager/campaigns/search/'+$scope.advertiserId+"/",{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.selectCampaignToClone = function(campaign) {
            if(campaign != undefined && campaign != null) {
                $scope.campaign = angular.copy(campaign);
                $scope.campaign.campaignName = $scope.campaign.campaignName+"_clone";
                CampaignManagerService.getCmapignConversionTrackers($scope.advertiserId, campaign.campaignId).then(function (response) {
                    // alert(response.campaignId);

                    $scope.campaign.tracker = {
                        conversionTracking: [],
                        clickConversionWindow: [],
                        viewConversionWindow: []
                    };
                    $scope.campaign.trackers = [];
                    for (var i = 0; i < response.length; i++) {
                        $scope.campaign.trackers.push({
                            clickConversionWindow: "",
                            viewConversionWindow: ""
                        });
                        $scope.campaign.tracker.conversionTracking.push(response[i].id.conversionId.toString());
                        $scope.campaign.tracker.clickConversionWindow.push(response[i].conversionClickWindow);
                        $scope.campaign.tracker.viewConversionWindow.push(response[i].conversionViewWindow);
                    }
                    if (response.length == 0) {
                        $scope.campaign.trackers = [{clickConversionWindow: "", viewConversionWindow: ""}];
                    }
                    campaignSync(campaign);
                    delete $scope.campaign.campaignId;
                });
            }
        };

        function campaignSync(campaign) {
            var totalBudgetCents = campaign.dailyBudgetCents;
            var impressionsLimit = campaign.impressionsLimit;
            var clicksLimit = campaign.clicksLimit;

            if(campaign.dailyBudgetCents == null || campaign.dailyBudgetCents == undefined){
                totalBudgetCents = undefined;
            }
            else{
                totalBudgetCents = (totalBudgetCents/100).toString();
            }
            if(campaign.impressionsLimit == null || campaign.impressionsLimit == undefined){
                impressionsLimit = undefined;
            }
            if(campaign.clicksLimit == null || campaign.totalBudgetCents == undefined){
                clicksLimit = undefined;
            }

            for(var i=0;i<$scope.campaign.budgets.length;i++) {

                // $scope.campaign.budgets[i].startDate = dateFiltering(new Date($scope.campaign.budgets[i].startDate));
                $scope.campaign.budgets[i].startDate = $filter('date')(moment($scope.campaign.budgets[i].startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                if($scope.campaign.budgets[i].endDate.indexOf("9999") == -1) {
                    // $scope.campaign.budgets[i].endDate = dateFiltering(new Date($scope.campaign.budgets[i].endDate));
                    $scope.campaign.budgets[i].endDate = $filter('date')(moment($scope.campaign.budgets[i].endDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                }
                else {
                    $scope.campaign.budgets[i].endDate = null;
                }

                $scope.campaign.budgets[i].minDate = "";
                $scope.campaign.budgets[i].maxDate = "";
                $scope.campaign.budgets[i].startDateOpened = false;
                $scope.campaign.budgets[i].endDateOpened = false;
                $scope.campaign.budgets[i].startOption = {
                    formatYear: 'yy',
                    startingDay: 1,
                    minDate : moment()
                };
                $scope.campaign.budgets[i].endOption = {
                    formatYear: 'yy',
                    startingDay: 1,
                    minDate : moment()
                };
                $scope.campaign.budgets[i].spendLimit = $scope.campaign.budgets[i].spendLimit/100;
                delete $scope.campaign.budgets[i].id;
                delete $scope.campaign.budgets[i].campaignId;
                delete $scope.campaign.budgets[i].createdBy;
                delete $scope.campaign.budgets[i].createdDate;
                delete $scope.campaign.budgets[i].lastModifiedBy;
                delete $scope.campaign.budgets[i].lastModifiedDate;
            }

            $scope.campaign.startDate = $filter('date')(moment($scope.campaign.startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
            if($scope.campaign.endDate.indexOf("9999") == -1) {
                $scope.campaign.endDate = $filter('date')(moment($scope.campaign.endDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
            }
            else {
                $scope.campaign.endDate = null;
            }
            calculateFcapNumber();
        }


    });


angular.module('adelementAdminApp')
    .controller('AdgroupCreateEditController', function ($scope,$timeout,$stateParams,advertiser,campaign,adgroup,adgroupPlacementBidOptProps,$state,$filter,CampaignManagerService,$http,ngDialog,adgroupPlacementBidOptProperties,advertiserFeatureRestrictions,cloneAdgroup,appnexusMapping) {
        $scope.targetingSettings = $scope.$parent.targetingSettings;
        $scope.advertiserId = $stateParams.advertiserId;
        $scope.campaignId = $stateParams.campaignId;
        $scope.$parent.campaignId = $stateParams.campaignId;
        $scope.$parent.advertiserId = $stateParams.advertiserId;
        $scope.editMode = false;
        $scope.adgroupId = undefined;
        $scope.advertiser = advertiser;
        $scope.campaign = campaign;
        $scope.adgroup = {};
        $scope.inventory = {};
        $scope.pretargetingAdgroups = [];
        $scope.adgroupOnSavingMode = false;


        // added by Snehal
        $scope.adgroupPlacementBidOptProps = adgroupPlacementBidOptProps;
        $scope.adgroupPlacementBidOptProperties = adgroupPlacementBidOptProperties;

        $scope.geo = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedGeo:undefined,geoType:"include",loadingLocations:false,include:[],exclude:[]};
        $scope.carrier = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedCarrier:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};

        $scope.adxAppCategory = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedAdxApp:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
        $scope.adxSiteCategory = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedAdxSite:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
        $scope.openrtbSiteCategory = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedOpenrtbSite:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
        $scope.mobileDevices = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedMobileDevice:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};

        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.startDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            minDate:moment()
        };
        $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.startDateOpened = false;
        $scope.endDateOpened = false;

        $scope.initDate = new Date('2019-10-20');
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.cloneAdgroup = {};
        $scope.loadingAdgroup = false;
        $scope.appnexusMapping = appnexusMapping;

        var m = moment();
        var today = m.set({hour:0,minute:0,second:0,millisecond:0}).toDate();

        var fcapDurationMinutes = {HOUR:60,DAY:1440,WEEK:10080,MONTH:43200};
        var blockListSegment = {};
        // added by Snehal
        adgroupPlacementBidOptProperties.targetType = campaign.campaignObjective;
        $scope.$parent.campaignObjective =  angular.copy(adgroupPlacementBidOptProperties.targetType);
        $scope.campaignObjective =  angular.copy(adgroupPlacementBidOptProperties.targetType);

        var currentUserTime;
        var offset = 0;
        var cloning = false;
        if($scope.advertiser != undefined) {
            currentUserTime = $scope.advertiser.currentTime;
            offset = new Date().getTimezoneOffset();
        }

        function dateFiltering(dateObj){
            return $filter('date')(dateObj, 'yyyy-MM-dd HH:mm:ss');
        }
        function stateDecide(){
            $scope.adgroupId = $stateParams.adgroupId;
            if($scope.adgroupId == undefined){
                $scope.$parent.editModeAdgroup = false;
                $scope.$parent.editModeCampaign = true;
                $scope.$parent.campaignId = $scope.campaignId;
                initAdgroup();
            }
            else{
                $scope.loadingAdgroup = true;
                $timeout(function(){$scope.loadingAdgroup = false;},5000);
                $scope.cloneAdgroup = cloneAdgroup;
                $scope.$parent.editModeAdgroup = true;
                $scope.adgroup = adgroup;
                $scope.$parent.editModeCampaign = true;
                $scope.$parent.campaignId = $scope.campaignId;
                $scope.adgroup.startDate = dateFiltering(moment(adgroup.startDate).add(offset,'MINUTES').toDate());
                $scope.adgroup.endDate = dateFiltering(moment(adgroup.endDate).add(offset,'MINUTES').toDate());
                $scope.$parent.adgroupId = $scope.adgroupId;
                $scope.$parent.adgroup = $scope.adgroup;

                var totalBudgetCents = adgroup.totalBudgetCents;
                var impressionsLimit = adgroup.impressionsLimit;
                var clicksLimit = adgroup.clicksLimit;

                if(adgroup.totalBudgetCents == null || adgroup.totalBudgetCents == undefined){
                    totalBudgetCents = undefined;
                }
                else{
                    totalBudgetCents = (totalBudgetCents/100).toString();
                }
                if(adgroup.impressionsLimit == null || adgroup.impressionsLimit == undefined){
                    impressionsLimit = undefined;
                }
                if(adgroup.clicksLimit == null || adgroup.totalBudgetCents == undefined){
                    clicksLimit = undefined;
                }
                 $scope.adgroup.maxBidDollar = $scope.adgroup.maxBidCents/100;
                 $scope.adgroup.goalPriceClickDollar = $scope.adgroup.goalPriceClick/100;
                 $scope.adgroup.goalPriceViewDollar = $scope.adgroup.goalPriceView/100;

                for(var i=0;i<$scope.adgroup.budgets.length;i++) {
                    $scope.adgroup.budgets[i].startDate = dateFiltering(moment($scope.adgroup.budgets[i].startDate).add(offset,'MINUTES').toDate());
                    if($scope.adgroup.budgets[i].endDate.indexOf("9999") == -1){
                        $scope.adgroup.budgets[i].endDate = dateFiltering(moment($scope.adgroup.budgets[i].endDate).add(offset,'MINUTES').toDate());
                    }
                    else {
                        $scope.adgroup.budgets[i].endDate = null;
                    }
                    $scope.adgroup.budgets[i].minDate = "";
                    $scope.adgroup.budgets[i].maxDate = "";
                    $scope.adgroup.budgets[i].startDateOpened = false;
                    $scope.adgroup.budgets[i].endDateOpened = false;
                    $scope.adgroup.budgets[i].startOption = {
                        formatYear: 'yy',
                        startingDay: 1,
                        minDate : moment()
                    };
                    $scope.adgroup.budgets[i].endOption = {
                        formatYear: 'yy',
                        startingDay: 1,
                        minDate : moment()
                    };
                    $scope.adgroup.budgets[i].spendLimit = $scope.adgroup.budgets[i].spendLimit/100;
                }

                $scope.adgroup.startDate = dateFiltering(new Date($scope.adgroup.startDate));
                if($scope.adgroup.endDate == "Invalid Date") {
                    $scope.adgroup.endDate = "9999-09-09";
                }
                if($scope.adgroup.startDate == "Invalid Date" || $scope.adgroup.startDate == null || $scope.adgroup.startDate == undefined) {

                    if(moment($scope.campaign.startDate).add(offset,'MINUTES').toDate() >= new Date($scope.advertiser.currentTime)) {
                        $scope.adgroup.startDate = $filter('date')(moment(campaign.startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                    }
                    else {
                        $scope.adgroup.startDate = $filter('date')(moment($scope.advertiser.currentTime).toDate(), 'yyyy-MM-dd HH:mm:ss');
                    }
                }
                if($scope.adgroup.endDate.indexOf("9999") == -1) {
                    $scope.adgroup.endDate = dateFiltering(new Date($scope.adgroup.endDate));
                }
                else {
                    $scope.adgroup.endDate = null;
                }

                CampaignManagerService.getAudiences($scope.advertiserId).then(function (audienceData) {
                    $scope.adgroup.audiences = audienceData;
                    initTargeting();
                });
                if($scope.adgroup.fcapImpressions == undefined || $scope.adgroup.fcapImpressions == null) {
                    $scope.adgroup.fcapImpressions = 1;
                }
                calculateFcapNumber();
            }
            $scope.editMode = $scope.$parent.editModeAdgroup;

            // added by Snehal
            adgroupPlacementBidOptPropInit(false);
        }

        function initController() {
            if($scope.stateDecide == 0){

            }
            else{

            }
        }

        function initServices() {

        }


        function initAdgroup() {

            // if(moment(campaign.startDate).add(offset,'MINUTES').toDate() > new Date(currentUserTime)) {
                console.log("inside start > currenttime");
                // console.log(new Date(campaign.startDate));
                console.log(moment(campaign.startDate));
                console.log(moment(campaign.startDate).toDate());

                if(moment($scope.campaign.startDate).add(offset,'MINUTES').toDate() >= new Date($scope.advertiser.currentTime)) {
                    $scope.adgroup.startDate = $filter('date')(moment(campaign.startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                }
                else {
                    $scope.adgroup.startDate = $filter('date')(moment($scope.advertiser.currentTime).toDate(), 'yyyy-MM-dd HH:mm:ss');
                }
                console.log($scope.adgroup.startDate);
            // }
            // else {
            //     console.log("inside else");
            //     console.log(currentUserTime);
            //     console.log(moment(currentUserTime));
            //     console.log(moment(currentUserTime).toDate());
            //     $scope.adgroup.startDate = $filter('date')(moment(currentUserTime).add(5,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
            //     console.log($scope.adgroup.startDate);
            // }
            if(new Date(campaign.endDate) > new Date()) {
                if(campaign.endDate.indexOf("9999") == -1) {
                    $scope.adgroup.endDate = $filter('date')(moment(new Date(campaign.endDate)).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                }
                else {
                    $scope.adgroup.endDate = null;
                }
            }
            $scope.adgroup.adRotation = "ROTATE";
            $scope.adgroup.fcapDuration = "DAY";
            $scope.adgroup.fcapDurationMinutes = 1440;
            $scope.adgroup.goalType = "NONE";
            $scope.adgroup.chinaOptout = 0;
            $scope.adgroup.autoBidOptimize = "1";
            $scope.adgroup.dealType = "CPM";
            $scope.adgroup.fcapImpressions = 1;
            if(advertiser.websiteUrl != null && advertiser.websiteUrl != "") {
                if(advertiser.websiteUrl.indexOf("http") == -1)
                    advertiser.websiteUrl = "http://"+advertiser.websiteUrl;
                $scope.adgroup.destinationUrl = advertiser.websiteUrl;
            }
            var endDateFoBud = dateFiltering(moment(new Date($scope.adgroup.endDate)).subtract(5,'MINUTES').toDate());
            if($scope.adgroup.endDate == null) {
                endDateFoBud = null;
            }
            $scope.adgroup.budgets = [];

            $scope.adgroup.targeting = {audience:{audienceId:[],depth:"",days:"",includeFlag:true},placements:{includeList:[],excludeList:[],deleteList:[],include:"",exclude:""},adnetworkSelected:[], pretargetingAdgroupMapping:{pretargetingAdgroupId:""},geos:{include:[],exclude:[],delete:[]},devices:{},environments:{},os:{},browsers:{},connectionTypes:{},videoSize:{},videoPlayerType:{},videoAdPosition:{},videoAdInventory:{},videoSkips:{},carriers:{include:[],exclude:[]},adxAppCategories:{include:[],exclude:[]},adxSiteCategories:{include:[],exclude:[]},openrtbSiteCategories:{include:[],exclude:[]},blockedPlacements:{all:[],data:""},mobileTargeting:{include:[],exclude:[]}};
            $scope.adgroup.audiences = [];
            CampaignManagerService.getAudiences($scope.advertiserId).then(function (audienceData) {
                $scope.adgroup.audiences = audienceData;
            });
            initTargetingSettings();
            calculateFcapNumber();

            // added by Snehal
            if(campaign.campaignObjective=='cpa'){
                $scope.adgroup.impressionThreshold = 5000;
                $scope.adgroup.lookBackDays = 60;
                $scope.adgroup.conversionThreshold = 3;

                // conversion weightage
//                $scope.adgroup.clickConversionPercent =
//                $scope.adgroup.clickCpa = 0;
//                $scope.adgroup.viewCpa = 0;
                $scope.adgroup.bidAdjConvrThresh = 7;
                $scope.adgroup.bidToAvgCpmThresh = 3;
                $scope.adgroup.clickConversionPercent = 100;
                $scope.adgroup.viewConversionPercent = 5;

                // CPA weightage
                $scope.adgroup.currentCpaBidPercent = 50;
                $scope.adgroup.targetCpaBidPercent = 50;

                $scope.adgroupPlacementBidOptProperties.impressionThreshold = $scope.adgroup.impressionThreshold;
                $scope.adgroupPlacementBidOptProperties.lookBackDays = $scope.adgroup.lookBackDays;
                $scope.adgroupPlacementBidOptProperties.conversionThreshold = $scope.adgroup.conversionThreshold;
                $scope.adgroupPlacementBidOptProperties.bidAdjConvrThresh = $scope.adgroup.bidAdjConvrThresh;
                $scope.adgroupPlacementBidOptProperties.bidToAvgCpmThresh = $scope.adgroup.bidToAvgCpmThresh;
                $scope.adgroupPlacementBidOptProperties.clickConversionPercent = $scope.adgroup.clickConversionPercent;
                $scope.adgroupPlacementBidOptProperties.viewConversionPercent = $scope.adgroup.viewConversionPercent;
                $scope.adgroupPlacementBidOptProperties.currentCpaBidPercent = $scope.adgroup.currentCpaBidPercent;
                $scope.adgroupPlacementBidOptProperties.targetCpaBidPercent = $scope.adgroup.targetCpaBidPercent;


            }
            else if(campaign.campaignObjective=='cpc' || campaign.campaignObjective=='cpm' || campaign.campaignObjective=='completion_rate'){
                $scope.adgroup.impressionThreshold = 1500;
                $scope.adgroup.lookBackDays = 30;
                $scope.adgroup.bidAdjConvrThresh = 7;
                $scope.adgroup.bidToAvgCpmThresh = 3;
                $scope.adgroup.clickConversionPercent = 0;
                $scope.adgroup.viewConversionPercent = 0;
                $scope.adgroup.maxBidMultiplier = 1;
                $scope.adgroup.targetCpaCents = 0;
                $scope.adgroup.targetCpaBidPercent = 50;
                $scope.adgroup.viewConversionPercent = 0;
                $scope.adgroup.conversionThreshold = 3;
                $scope.adgroup.currentCpaBidPercent = 50;
                $scope.adgroupPlacementBidOptProperties.impressionThreshold = $scope.adgroup.impressionThreshold;
                $scope.adgroupPlacementBidOptProperties.lookBackDays = $scope.adgroup.lookBackDays;
            }
            else if(campaign.campaignObjective=='margin_vast'){
                $scope.adgroup.targetIbvMargin = 50;
                $scope.adgroup.impressionThreshold = 75;
                $scope.adgroup.targetMinIbvLookback = 15;
                $scope.adgroup.targetMaxIbvLookback = 4320;
                $scope.adgroup.opportunityThresh = 0;
                $scope.adgroup.fillRateThresh = 0.01;
                $scope.adgroup.lookBackDays = 30;
                $scope.adgroup.bidResponseThresh = 1000;

                $scope.adgroupPlacementBidOptProperties.impressionThreshold = $scope.adgroup.impressionThreshold;
                $scope.adgroupPlacementBidOptProperties.lookBackDays = $scope.adgroup.lookBackDays;
                $scope.adgroupPlacementBidOptProperties.targetMinIbvLookback = $scope.adgroup.targetMinIbvLookback;
                $scope.adgroupPlacementBidOptProperties.targetMaxIbvLookback = $scope.adgroup.targetMaxIbvLookback;
                $scope.adgroupPlacementBidOptProperties.targetIbvMargin = $scope.adgroup.targetIbvMargin;
                $scope.adgroupPlacementBidOptProperties.opportunityThresh = $scope.adgroup.opportunityThresh;
                $scope.adgroupPlacementBidOptProperties.fillRateThresh = $scope.adgroup.fillRateThresh;
                $scope.adgroupPlacementBidOptProperties.bidResponseThresh = $scope.adgroup.bidResponseThresh;

            }
            else if(campaign.campaignObjective=='margin_inbanner'){
                $scope.adgroup.targetIbvMargin = 50;
                $scope.adgroup.impressionThreshold = 75;
                $scope.adgroup.targetMinIbvLookback = 15;
                $scope.adgroup.targetMaxIbvLookback = 4320;
                $scope.adgroup.lookBackDays = 3;
                $scope.adgroup.bidResponseThresh = 1000;
                $scope.adgroup.topPlacementsLimit = 100;
                $scope.adgroup.placementOptLastNHour = 24;
                $scope.adgroup.topPlacementLastNHour = 720;
                $scope.adgroup.topPlacementImpressionThresh = 15000;

                $scope.adgroupPlacementBidOptProperties.impressionThreshold = $scope.adgroup.impressionThreshold;
                $scope.adgroupPlacementBidOptProperties.lookBackDays = $scope.adgroup.lookBackDays;
                $scope.adgroupPlacementBidOptProperties.targetMinIbvLookback = $scope.adgroup.targetMinIbvLookback;
                $scope.adgroupPlacementBidOptProperties.targetMaxIbvLookback = $scope.adgroup.targetMaxIbvLookback;
                $scope.adgroupPlacementBidOptProperties.targetIbvMargin = $scope.adgroup.targetIbvMargin;
                $scope.adgroupPlacementBidOptProperties.bidResponseThresh = $scope.adgroup.bidResponseThresh;
                $scope.adgroupPlacementBidOptProperties.topPlacementsLimit = $scope.adgroup.topPlacementsLimit;
                $scope.adgroupPlacementBidOptProperties.placementOptLastNHour = $scope.adgroup.placementOptLastNHour;
                $scope.adgroupPlacementBidOptProperties.topPlacementLastNHour = $scope.adgroup.topPlacementLastNHour;
                $scope.adgroupPlacementBidOptProperties.topPlacementImpressionThresh = $scope.adgroup.topPlacementImpressionThresh;
            }
        }

        function calculateFcapDurationMinutes() {
            var fcapDuration = fcapDurationMinutes[$scope.adgroup.fcapDuration]*$scope.adgroup.fcapDurationNumber;
            $scope.adgroup.fcapDurationMinutes = fcapDuration;
        }
        function calculateFcapNumber() {
            $scope.adgroup.fcapDurationNumber = $scope.adgroup.fcapDurationMinutes / fcapDurationMinutes[$scope.adgroup.fcapDuration];
        }

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
                else{
                    $scope.adgroup.budgets[index].startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;
                }
                else{
                    $scope.adgroup.budgets[index].endDateOpened = true;
                }
            }
        };

        function addTargeting() {
            //placements targeting
            addPlacementsTargeting();
            //audience targeting
            addAudienceTargeting();
            //geo targeting
            addGeoTargeting();
            //carrier targeting
            addCarrierTargeting();

            addAdxSiteCategoryTargeting();
            addAdxAppCategoryTargeting();
            addOpenrtbSiteCategoryTargeting();
            addMobileDevicesTargeting();

            //add other targtings
            addAdvanceTargeting();
        }

        function addPlacementsTargeting() {
            var includePlacementString = $scope.adgroup.targeting.placements.include;
            var includePlacementList = [];
            var includeList = $scope.adgroup.targeting.placements.includeList;
            var excludeList = $scope.adgroup.targeting.placements.excludeList;

            $scope.adgroup.targeting.placements.includeList = [];
            var excludePlacementString = $scope.adgroup.targeting.placements.exclude;
            var excludePlacementList = [];
            $scope.adgroup.targeting.placements.excludeList = [];
            if(includePlacementString){
                if(includePlacementString.trim()!="") {
                    if(includePlacementString.indexOf(",") != -1) {
                        includePlacementList = includePlacementString.split(",");
                    }
                    else {
                        includePlacementList = includePlacementString.split("\n");
                    }
                    for(var i=0;i<includePlacementList.length;i++) {
                        if(includePlacementList[i].trim()!="") {
                            $scope.adgroup.targeting.placements.includeList.push(includePlacementList[i].trim())
                        }
                    }
                }
            }
            if(excludePlacementString){
                if(excludePlacementString.trim()!="") {
                    if(excludePlacementString.indexOf(",") != -1) {
                        excludePlacementList = excludePlacementString.split(",");
                    }
                    else {
                        excludePlacementList = excludePlacementString.split("\n");
                    }
                    for(var i=0;i<excludePlacementList.length;i++) {
                        if(excludePlacementList[i].trim()!="") {
                            $scope.adgroup.targeting.placements.excludeList.push(excludePlacementList[i].trim());
                        }
                    }
                }
            }

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.placements.includeList.concat($scope.adgroup.targeting.placements.excludeList);
            $scope.adgroup.targeting.placements.deleteList = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.placements.includeList.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.placements.excludeList.filter(function(x) { return excludeList.indexOf(x) < 0 });
            $scope.adgroup.targeting.placements.includeList = changedIncludeList;
            $scope.adgroup.targeting.placements.excludeList = changedExcludeList;
            console.log("deleteList",$scope.adgroup.targeting.placements.deleteList);
            var allblocked = $scope.adgroup.targeting.blockedPlacements.data;
            var blockPlacementList = allblocked.split("\n");
            $scope.adgroup.targeting.blockedPlacements.all = [];
            for(var i=0;i<blockPlacementList.length;i++) {
                if(blockPlacementList[i].trim()!="") {
                    $scope.adgroup.targeting.blockedPlacements.all.push(blockPlacementList[i].trim());
                }
            }

        }

        function addAudienceTargeting(){

        }

        function addGeoTargeting() {

            var includeList = $scope.geo.include;
            var excludeList = $scope.geo.exclude;

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.geos.include.concat($scope.adgroup.targeting.geos.exclude);
            $scope.adgroup.targeting.geos.delete = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.geos.include.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.geos.exclude.filter(function(x) { return excludeList.indexOf(x) < 0 });
            $scope.adgroup.targeting.geos.include = changedIncludeList;
            $scope.adgroup.targeting.geos.exclude = changedExcludeList;
            console.log("deleteList geo",$scope.adgroup.targeting.geos.delete);

        }
        function addCarrierTargeting() {

            var includeList = $scope.carrier.include;
            var excludeList = $scope.carrier.exclude;

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.carriers.include.concat($scope.adgroup.targeting.carriers.exclude);

            $scope.adgroup.targeting.carriers.delete = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.carriers.include.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.carriers.exclude.filter(function(x) { return excludeList.indexOf(x) < 0 });
            $scope.adgroup.targeting.carriers.include = changedIncludeList;
            $scope.adgroup.targeting.carriers.exclude = changedExcludeList;
            console.log("deleteList carrier",$scope.adgroup.targeting.carriers.delete);
        }

        function addAdxSiteCategoryTargeting() {

            var includeList = $scope.adxSiteCategory.include;
            var excludeList = $scope.adxSiteCategory.exclude;

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.adxSiteCategories.include.concat($scope.adgroup.targeting.adxSiteCategories.exclude);

            $scope.adgroup.targeting.adxSiteCategories.delete = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.adxSiteCategories.include.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.adxSiteCategories.exclude.filter(function(x) { return excludeList.indexOf(x) < 0 });
            $scope.adgroup.targeting.adxSiteCategories.include = changedIncludeList;
            $scope.adgroup.targeting.adxSiteCategories.exclude = changedExcludeList;
            console.log("deleteList adxSiteCategories",$scope.adgroup.targeting.adxSiteCategories.delete);
        }

        function addAdxAppCategoryTargeting() {

            var includeList = $scope.adxAppCategory.include;
            var excludeList = $scope.adxAppCategory.exclude;

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.adxAppCategories.include.concat($scope.adgroup.targeting.adxAppCategories.exclude);

            $scope.adgroup.targeting.adxAppCategories.delete = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.adxAppCategories.include.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.adxAppCategories.exclude.filter(function(x) { return excludeList.indexOf(x) < 0 });
            $scope.adgroup.targeting.adxAppCategories.include = changedIncludeList;
            $scope.adgroup.targeting.adxAppCategories.exclude = changedExcludeList;
            console.log("deleteList adxAppCategories",$scope.adgroup.targeting.adxAppCategories.delete);
        }

        function addOpenrtbSiteCategoryTargeting() {

            var includeList = $scope.openrtbSiteCategory.include;
            var excludeList = $scope.openrtbSiteCategory.exclude;

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.openrtbSiteCategories.include.concat($scope.adgroup.targeting.openrtbSiteCategories.exclude);

            $scope.adgroup.targeting.openrtbSiteCategories.delete = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.openrtbSiteCategories.include.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.openrtbSiteCategories.exclude.filter(function(x) { return excludeList.indexOf(x) < 0 });

            $scope.adgroup.targeting.openrtbSiteCategories.include = changedIncludeList;
            $scope.adgroup.targeting.openrtbSiteCategories.exclude = changedExcludeList;
            console.log("deleteList openrtbSiteCategories",$scope.adgroup.targeting.openrtbSiteCategories.delete);
        }

        function addMobileDevicesTargeting() {

            var includeList = $scope.mobileDevices.include;
            var excludeList = $scope.mobileDevices.exclude;

            var totalListBefore = includeList.concat(excludeList);
            var totalListAfter = $scope.adgroup.targeting.mobileTargeting.include.concat($scope.adgroup.targeting.mobileTargeting.exclude);

            $scope.adgroup.targeting.mobileTargeting.delete = totalListBefore.filter(function(x) { return totalListAfter.indexOf(x) < 0 });
            var changedIncludeList = $scope.adgroup.targeting.mobileTargeting.include.filter(function(x) { return includeList.indexOf(x) < 0 });
            var changedExcludeList = $scope.adgroup.targeting.mobileTargeting.exclude.filter(function(x) { return excludeList.indexOf(x) < 0 });
            $scope.adgroup.targeting.mobileTargeting.include = changedIncludeList;
            $scope.adgroup.targeting.mobileTargeting.exclude = changedExcludeList;
            console.log("deleteList mobiles",$scope.adgroup.targeting.mobileTargeting.delete);
        }

        function addAdvanceTargeting() {
            //add all key if the targeting settings are unaltered
            //1 if all the keys are selected then no need to add everything to table and redis(only all key)
            //2 if one of the selection is removed then remove the all key
            //3 all selections removal not allowed

        }

        function initTargeting() {
            $scope.targetingSettings = undefined;
            if($scope.targetingSettings == undefined) {
                initTargetingSettings();
            }
            $scope.adgroup.targeting = {audience:{audienceId:[],depth:"",days:"",includeFlag:true},placements:{includeList:[],excludeList:[],deleteList:[],include:"",exclude:""},adnetworkSelected:[],pretargetingAdgroupMapping:{pretargetingAdgroupId:""},geos:{include:[],exclude:[],delete:[]},devices:{},environments:{},os:{},browsers:{},connectionTypes:{},videoSize:{},videoPlayerType:{},videoAdPosition:{},videoAdInventory:{},videoSkips:{},carriers:{include:[],exclude:[],delete:[]},adxAppCategories:{include:[],exclude:[],delete:[]},adxSiteCategories:{include:[],exclude:[],delete:[]},openrtbSiteCategories:{include:[],exclude:[],delete:[]},blockedPlacements:{all:[],data:""},mobileTargeting:{include:[],exclude:[]}};
            initAdnetworks();
            initPlacements();
            initAudience();
            initAdgpMapping();
            initGeoTargeting();
            initCarrierTargeting();
            initAdxAppCategoryTargeting();
            initAdxSiteCategoryTargeting();
            initOpenrtbSiteCategoryTargeting();
            initMobileDeviceTargeting();
            initAdvanceTargeting();
            initBlockedUrls();
        }

        function initTargetingSettings() {
            if($scope.targetingSettings == undefined) {
                CampaignManagerService.getTargetingSettings().then(function (response) {

                    $scope.targetingSettings = response;
                    $scope.$parent.targetingSettings = response;
                        $scope.targetingSettings.device.forEach(function(device){
                            $scope.adgroup.targeting.devices[device.value] = true;
                        });
                        $scope.targetingSettings.environment.forEach(function(environment){
                            $scope.adgroup.targeting.environments[environment.value] = true;
                        });
                        $scope.targetingSettings.os.forEach(function (os) {
                            $scope.adgroup.targeting.os[os.value] = true;
                        });

                        $scope.targetingSettings.browsers.forEach(function (browser) {
                           $scope.adgroup.targeting.browsers[browser.value] = true;
                        });

                        $scope.targetingSettings.connectionType.forEach(function (connectionType) {
                            $scope.adgroup.targeting.connectionTypes[connectionType.value] = true;
                        });

                        $scope.targetingSettings.videoAdPosition.forEach(function (videoAdPosition) {
                            $scope.adgroup.targeting.videoAdPosition[videoAdPosition.value] = true;
                        });

                        $scope.targetingSettings.videoPlayerType.forEach(function (videoPlayerType) {
                            $scope.adgroup.targeting.videoPlayerType[videoPlayerType.value] = true;
                        });

                        $scope.targetingSettings.videoAdInventory.forEach(function (videoAdInventory) {
                            $scope.adgroup.targeting.videoAdInventory[videoAdInventory.value] = true;
                        });

                        $scope.targetingSettings.videoSkips.forEach(function (videoSkips) {
                            $scope.adgroup.targeting.videoSkips[videoSkips.value] = true;
                        });

                        $scope.targetingSettings.videoSize.forEach(function (videoSize) {
                            $scope.adgroup.targeting.videoSize[videoSize.value] = true;
                        });
                    $timeout(function(){$scope.loadingAdgroup = false;},1000);
                });

            }
            else {
                $scope.loadingAdgroup = false;
            }
        }

        function initAdnetworks() {
            for(var i=0;i<$scope.adgroup.adgroupsAdnetworks.length;i++) {
                var adnetworkId  = $scope.adgroup.adgroupsAdnetworks[i].adnetworks.id;
                $scope.inventory[adnetworkId] = true;
                $scope.adgroup.targeting.adnetworkSelected.push(adnetworkId);
            }
        }
        function initPlacements(){
            for(var i=0;i<$scope.adgroup.adgroupPlacementBids.length;i++) {
                var placementBid = $scope.adgroup.adgroupPlacementBids[i];
                if(placementBid.placementStatus && placementBid.placementStatus == "active") {
                    if ((placementBid.placementType == null || placementBid.placementType == "include") && placementBid.userBid != 0) {
                        $scope.adgroup.targeting.placements.includeList.push(placementBid.placementName);
                    }
                    else {
                        $scope.adgroup.targeting.placements.excludeList.push(placementBid.placementName);
                    }
                }
            }
            $scope.adgroup.targeting.placements.include = $scope.adgroup.targeting.placements.includeList.join("\n");
            $scope.adgroup.targeting.placements.exclude = $scope.adgroup.targeting.placements.excludeList.join("\n");
        }

        function initAudience() {
            if($scope.adgroup.audienceTargeting!=null) {
                if($scope.adgroup.audienceTargeting.length > 0) {
                    for(var i=0; i<$scope.adgroup.audienceTargeting.length; i++) {
                        $scope.adgroup.targeting.audience.audienceId.push($scope.adgroup.audienceTargeting[i].audienceId);
                    }
                }
            }
            if($scope.adgroup.audienceRecencyDepth != null) {
                $scope.adgroup.targeting.audience.depth = $scope.adgroup.audienceRecencyDepth.depth;
                $scope.adgroup.targeting.audience.days = $scope.adgroup.audienceRecencyDepth.recency;
                $scope.adgroup.targeting.audience.includeFlag = $scope.adgroup.audienceRecencyDepth.includeFlag;
            }
            blockOptionsSegment();

        }
        function initAdgpMapping() {
            if($scope.adgroup.pretargetingAdgroupMapping != null) {
                $scope.adgroup.targeting.pretargetingAdgroupMapping.pretargetingAdgroupId = $scope.adgroup.pretargetingAdgroupMapping.pretargetingAdgroupId;
            }
        }
        function initGeoTargeting() {
            for(var i=0;i<$scope.adgroup.geoTargetings.length;i++) {
                var geoTargeting = $scope.adgroup.geoTargetings[i];
                    if(cloning) {
                        geoTargeting.id = geoTargeting.geoId;
                    }
                    if (geoTargeting.geoType == null || geoTargeting.geoType == "include") {
                        // geoTargeting.geoName = geoTargeting.canonical+"("+geoTargeting.target_type+")";
                        $scope.adgroup.targeting.geos.include.push(geoTargeting);
                        $scope.geo.include.push(geoTargeting);
                    }
                    else {
                        // geoTargeting.geoName = geoTargeting.canonical+"("+geoTargeting.target_type+")";
                        $scope.adgroup.targeting.geos.exclude.push(geoTargeting);
                        $scope.geo.exclude.push(geoTargeting);
                    }
            }
        }
        function initCarrierTargeting() {
            for(var i=0;i<$scope.adgroup.carrierTargetings.length;i++) {
                var carrierTargeting = $scope.adgroup.carrierTargetings[i];
                if(cloning) {
                    carrierTargeting.id = carrierTargeting.carrierId;
                }
                if (carrierTargeting.carrierType == null || carrierTargeting.carrierType == "include") {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.carriers.include.push(carrierTargeting);
                    $scope.carrier.include.push(carrierTargeting);
                }
                else {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.carriers.exclude.push(carrierTargeting);
                    $scope.carrier.exclude.push(carrierTargeting);
                }
            }
        }
        function initAdxAppCategoryTargeting() {
            for(var i=0;i<$scope.adgroup.adxAppCategoryTargetings.length;i++) {
                var adxAppCategoryTargeting = $scope.adgroup.adxAppCategoryTargetings[i];
                if(cloning) {
                    adxAppCategoryTargeting.id = adxAppCategoryTargeting.appCatId;
                }
                if (adxAppCategoryTargeting.catType == null || adxAppCategoryTargeting.catType == "include") {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.adxAppCategories.include.push(adxAppCategoryTargeting);
                    $scope.adxAppCategory.include.push(adxAppCategoryTargeting);
                }
                else {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.adxAppCategories.exclude.push(adxAppCategoryTargeting);
                    $scope.adxAppCategory.exclude.push(adxAppCategoryTargeting);
                }
            }
        }
        function initAdxSiteCategoryTargeting() {
            for(var i=0;i<$scope.adgroup.adxSiteCategoryTargetings.length;i++) {
                var adxSiteCategoryTargeting = $scope.adgroup.adxSiteCategoryTargetings[i];
                if(cloning) {
                    adxSiteCategoryTargeting.id = adxSiteCategoryTargeting.siteCatId;
                }
                if (adxSiteCategoryTargeting.catType == null || adxSiteCategoryTargeting.catType == "include") {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.adxSiteCategories.include.push(adxSiteCategoryTargeting);
                    $scope.adxSiteCategory.include.push(adxSiteCategoryTargeting);
                }
                else {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.adxSiteCategories.exclude.push(adxSiteCategoryTargeting);
                    $scope.adxSiteCategory.exclude.push(adxSiteCategoryTargeting);
                }
            }
        }
        function initOpenrtbSiteCategoryTargeting() {
            for(var i=0;i<$scope.adgroup.openrtbSiteCategoryTargetings.length;i++) {
                var openrtbSiteCategoryTargeting = $scope.adgroup.openrtbSiteCategoryTargetings[i];
                if(cloning) {
                    openrtbSiteCategoryTargeting.id = openrtbSiteCategoryTargeting.siteCatId;
                }
                if (openrtbSiteCategoryTargeting.siteType == null || openrtbSiteCategoryTargeting.siteType == "include") {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.openrtbSiteCategories.include.push(openrtbSiteCategoryTargeting);
                    $scope.openrtbSiteCategory.include.push(openrtbSiteCategoryTargeting);
                }
                else {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.openrtbSiteCategories.exclude.push(openrtbSiteCategoryTargeting);
                    $scope.openrtbSiteCategory.exclude.push(openrtbSiteCategoryTargeting);
                }
            }
        }

        function initMobileDeviceTargeting() {
            for(var i=0;i<$scope.adgroup.adgroupMobileTargetings.length;i++) {
                var mobileTargeting = $scope.adgroup.adgroupMobileTargetings[i];
                if(cloning) {
                    mobileTargeting.id = mobileTargeting.mobileId;
                }
                mobileTargeting.name = mobileTargeting.mobileName;
                if (mobileTargeting.targetingType == null || mobileTargeting.targetingType == "include") {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.mobileTargeting.include.push(mobileTargeting);
                    $scope.mobileDevices.include.push(mobileTargeting);
                }
                else {
                    // carrierTargeting.geoName = carrierTargeting.canonical+"("+carrierTargeting.target_type+")";
                    $scope.adgroup.targeting.mobileTargeting.exclude.push(mobileTargeting);
                    $scope.mobileDevices.exclude.push(mobileTargeting);
                }
            }
        }

        function initAdvanceTargeting() {
            if($scope.adgroupId != undefined || $scope.adgroup.from.adgroupId != undefined) {
                var adgroupId = $scope.adgroupId?$scope.adgroupId:$scope.adgroup.from.adgroupId;
                CampaignManagerService.getAdvancedTargeting($scope.advertiserId,$scope.campaignId,adgroupId).then(function (response) {
                    var advanceTargetingData = response;
                    //technographic targeting
                    var deviceTargeting = advanceTargetingData.deviceTargeting;
                    var environmentTargeting = advanceTargetingData.environmentTargeting;
                    var osTargeting = advanceTargetingData.osTargeting;
                    var browserTargeting = advanceTargetingData.browserTargeting;
                    var connectionTypeTargeting = advanceTargetingData.connectionTypeTargeting;
                    // os:{},browsers:{},connectionTypes:{},videoSize:{},videoPlayerType:{},videoAdPosition:{},videoAdInventory:{},videoSkips:{}
                    deviceTargeting.forEach(function (deviceTarget) {
                        var targetType = false;
                        if (deviceTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.devices[deviceTarget.value] = targetType;
                    });
                    environmentTargeting.forEach(function (environmentTarget) {
                        var targetType = false;
                        if (environmentTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.environments[environmentTarget.value] = targetType;
                    });

                    osTargeting.forEach(function (osTarget) {
                        var targetType = false;
                        if (osTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.os[osTarget.value] = targetType;
                    });

                    browserTargeting.forEach(function (browserTarget) {
                        var targetType = false;
                        if (browserTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.browsers[browserTarget.value] = targetType;
                    });

                    connectionTypeTargeting.forEach(function (connectionTypeTarget) {
                        var targetType = false;
                        if (connectionTypeTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.connectionTypes[connectionTypeTarget.value] = targetType;
                    });
                    //video specific settings
                    var videoSizeTargeting = advanceTargetingData.videoSizeTargeting;
                    var videoPlayerTypeTargeting = advanceTargetingData.videoPlayerTypeTargeting;
                    var videoSkipsTargeting = advanceTargetingData.videoSkipsTargeting;
                    var videoInventoryTargeting = advanceTargetingData.videoInventoryTargeting;
                    var videoPositionTargeting = advanceTargetingData.videoPositionTargeting;

                    videoSizeTargeting.forEach(function (videoSizeTarget) {
                        var targetType = false;
                        if (videoSizeTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.videoSize[videoSizeTarget.value] = targetType;
                    });

                    videoPlayerTypeTargeting.forEach(function (videoPlayerTypeTarget) {
                        var targetType = false;
                        if (videoPlayerTypeTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.videoPlayerType[videoPlayerTypeTarget.value] = targetType;
                    });

                    videoSkipsTargeting.forEach(function (videoSkipsTarget) {
                        var targetType = false;
                        if (videoSkipsTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.videoSkips[videoSkipsTarget.value] = targetType;
                    });

                    videoInventoryTargeting.forEach(function (videoInventoryTarget) {
                        var targetType = false;
                        if (videoInventoryTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.videoAdInventory[videoInventoryTarget.value] = targetType;
                    });

                    videoPositionTargeting.forEach(function (videoPositionTarget) {
                        var targetType = false;
                        if (videoPositionTarget.targetingType == "include") {
                            targetType = true;
                        }
                        $scope.adgroup.targeting.videoAdPosition[videoPositionTarget.value] = targetType;
                    });
                });
            }
            else {

            }
        }

        function initBlockedUrls() {
            var adgId = $scope.adgroupId;
            if(adgId === undefined || adgId === null) {
                adgId = $scope.adgroup.from.adgroupId;
            }
            CampaignManagerService.getBlockedPlacements($scope.advertiserId,adgId).then(function (blockedUrls) {
                $scope.adgroup.targeting.blockedPlacements.data = blockedUrls.join("\n");
                $scope.adgroup.targeting.blockedPlacements.all = blockedUrls;
            });
        }

        $scope.dateFormat = function (data) {
            $scope.adgroup[data] = $filter('date')($scope.adgroup[data], 'yyyy-MM-dd HH:mm:ss');
        };
        $scope.dateFormatInBudget = function (data,index,parent) {
            $scope.adgroup[parent][index][data] =  $filter('date')($scope.adgroup[parent][index][data], 'yyyy-MM-dd HH:mm:ss');
        };

        $scope.addBudget = function(){
            var budlen = $scope.adgroup.budgets.length;
            // prevEndDate = $scope.adgroup.budgets[budlen-1].endDate;
            // if(prevEndDate != null) {
            if(budlen == 0) {

            var endDateFoBud = dateFiltering(moment(new Date($scope.adgroup.endDate)).subtract(5,'MINUTES').toDate());
                        if($scope.adgroup.endDate == null) {
                            endDateFoBud = null;
                        }
                $scope.adgroup.budgets.push({startDate:dateFiltering(moment(new Date($scope.adgroup.startDate)).toDate()),endDate:endDateFoBud,budgetType:"daily",spendLimit:"",clicksLimit:null,impressionsLimit:null,delivery:"standard",deliveryPace:"standard",startDateOpened:false,endDateOpened:false,startOption:{
                                                            formatYear: 'yy',
                                                            startingDay: 1,
                                                            minDate : moment().toDate()
                                                        },endOption:{
                                                            formatYear: 'yy',
                                                            startingDay: 1,
                                                            minDate : moment().toDate()
                                                        }});
            }
            else{
                $scope.adgroup.budgets.push({
                    startDate: "",
                    endDate: null,
                    budgetType: "daily",
                    spendLimit: null,
                    clicksLimit: null,
                    impressionsLimit: null,
                    deliveryPace: "standard",
                    minDate: "",
                    maxDate: "",
                    startDateOpened: false,
                    endDateOpened: false,
                    startOption: {
                        formatYear: 'yy',
                        startingDay: 1,
                        minDate: moment()
                    },
                    endOption: {
                        formatYear: 'yy',
                        startingDay: 1,
                        minDate: moment()
                    }
                });
             }
        };

        //delete budget from the list
        $scope.deleteBudget = function(index) {
            var adgBudget = $scope.adgroup.budgets[index];
            if(adgBudget.hasOwnProperty("id")) {
                CampaignManagerService.deleteBudgetAdg($scope.advertiserId,$scope.adgroup.adgroupId,adgBudget.id).then(function(response){
                    if(response.error) {
                        alert("not able to delete budget");
                    }
                    else {
                        $scope.adgroup.budgets.splice(index,1);
                    }
                });

            }
            else {
                $scope.adgroup.budgets.splice(index,1);
            }
        };


        $scope.saveAdgroup = function() {
            $scope.adgroupOnSavingMode = true;
            calculateFcapDurationMinutes();
            addTargeting();

            // added by Snehal
            adgroupPlacementBidOptProp();

            if($scope.editMode) {
                CampaignManagerService.saveAdgroup($scope.advertiserId,$scope.campaignId,$scope.adgroup).then(function (response) {
                    $scope.adgroupOnSavingMode = false;
                    $scope.adgroupId = response.adgroupId;
                    $scope.$parent.adgroupId = $scope.adgroupId;
                    $scope.$parent.editModeAdgroup = true;
                    $state.go('campaign-manager.edit-assign-creatives',{advertiserId:$scope.advertiserId,campaignId:$scope.campaignId,adgroupId:$scope.$parent.adgroupId});
                });
            }
            else {

                CampaignManagerService.saveAdgroup($scope.advertiserId,$scope.campaignId,$scope.adgroup).then(function (response) {
                    $scope.adgroupOnSavingMode = false;
                    $scope.adgroupId = response.adgroupId;
                    $scope.$parent.adgroupId = $scope.adgroupId;
                    $scope.$parent.editModeAdgroup = true;
                    $scope.$parent.adgroup = $scope.adgroup;
                    $state.go('campaign-manager.edit-assign-creatives',{advertiserId:$scope.advertiserId,campaignId:$scope.campaignId,adgroupId:$scope.adgroupId});
                });

            }
        };

        //Adgroup Overlapping validators
        $scope.checkOverLappingDatesForAdgroup = function (viewValue,which) {
            if(which == 1 && viewValue == null) {
                return true;
            }
            var currentDate = new Date(viewValue);
            var campStartDate = new Date($scope.adgroup.startDate);
            var campEndDate = new Date($scope.adgroup.endDate);

            if($scope.adgroup.endDate == null) {
                return currentDate >= campStartDate;
            }

            return currentDate >= campStartDate && currentDate <= campEndDate;
        };

        $scope.checkOverLappingDates = function (viewValue,index,type) {
            var currentDate = new Date(viewValue);

            for(var i=0;i<$scope.adgroup.budgets.length;i++) {
                if(index!=i || type == 0) {
                    var budget = $scope.adgroup.budgets[i];
                    var startDate;
                    var endDate;
                    if (budget.startDate && budget.startDate != "") {
                        startDate = new Date(budget.startDate);
                    }
                    if (budget.endDate && budget.endDate != "") {
                        endDate = new Date(budget.endDate);
                    }
                    else if(budget.type=="daily"){
                        endDate = new Date("09/09/9999");
                    }
                    if (currentDate > startDate && currentDate < endDate) {
                        return false;
                    }
                    else {
                        if(type == 0) {
                            if($scope.adgroupForm["budgetStart"+i.toString()]) {
                                    if($scope.adgroupForm["budgetStart"+i.toString()].$error && $scope.adgroupForm["budgetStart"+i.toString()].$error.overlapCampaign)
                                        $scope.adgroupForm["budgetStart"+i.toString()].$validate();
                             }
                            if($scope.adgroupForm["budgetEnd"+i.toString()]) {
                                    if($scope.adgroupForm["budgetEnd"+i.toString()].$error && $scope.adgroupForm["budgetEnd"+i.toString()].$error.overlapCampaign)
                                        $scope.adgroupForm["budgetEnd"+i.toString()].$validate();
                             }
                        }
                    }
                }
            }
            return true;
        };

        $scope.checkStartMoreThanEndDateBudget = function (value,startOrEnd,index) {
                if(startOrEnd == 0){
                    if(value) {
                        var startDate = new Date(value);
                        var data = angular.copy($scope.adgroup.budgets[index].endDate);
                        var endDate = data == null?null:new Date(data);
                        if(endDate != null) {
                            // alert(endDate.toDateString())
                            return startDate < endDate;
                        }
                        else {
                            return true;
                        }
                    }
                    return false;
                }
                else {
                    if(value != null) {
                        var endDate = new Date(value);
                        var data = angular.copy($scope.adgroup.budgets[index].startDate);
                        var startDate = new Date(data);
                        return startDate < endDate;
                    }
                    return true;
                }
        };

        $scope.checkStartMoreThanEndDate = function (startValue,endValue) {
            if(startValue && endValue){
                var startDate = new Date(startValue);
                var endDate = new Date(endValue);
                return startDate < endDate;
            }
            else if(!endValue) {
                return true;
            }
            else {
                return false;
            }
        };

        $scope.startLessThanToday = function(which,index) {
            if($scope.adgroupId != undefined) {
                if (which == 0) {
                    var startD = new Date($scope.adgroup.startDate);
                    return today > startD;
                } else {
                    var startD = new Date($scope.adgroup.budgets[index].startDate);
                    return today > startD;
                }
            }
            else {
                return false;
            }
        };
        $scope.checkForPlacements = function (value) {
            var placements = [];
            var bool = true;
            if(value != undefined && value!="") {
                if (value.indexOf(",") != -1) {
                    placements = value.split(",");
                }
                else {
                    placements = value.split("\n");
                }
                for (var i = 0; i < placements.length; i++) {
                    if (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(placements[i])) {
                        bool = bool & true;
                    }
                    else if(/^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]?$/g.test(placements[i])) {
                        bool = bool & true;
                    }
                    else if(/^\d+$/g.test(placements[i])) {
                        bool = bool & true;
                    }
                    else {
                        bool = bool & false;
                        return false;
                    }

                }
            }
            return bool;
        };
        $scope.inventoryChange = function(key) {
            if(key == '9') {
                //google
                if ($scope.inventory[key]) {
                    CampaignManagerService.getPretargetingAdgroups(key).then(function (response) {
                        $scope.pretargetingAdgroups = response;
                    })
                }
            }
            else {
                $scope.adgroup.targeting.pretargetingAdgroupMapping.pretargetingAdgroupId = key;
            }
        };

        $scope.geoSearch = function(val){
            return $http.get('/api/campaign-manager/geo/search/',{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.carrierSearch = function(val){
            return $http.get('/api/campaign-manager/carrier/search/',{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.adxAppSearch = function(val){
            return $http.get('/api/campaign-manager/adxAppCat/search/',{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.adxSiteSearch = function(val){
            return $http.get('/api/campaign-manager/adxSiteCat/search/',{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.openrtbSiteSearch = function(val){
            return $http.get('/api/campaign-manager/openrtbSiteCat/search/',{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.mobileDeviceSearch = function(val){
            return $http.get('/api/campaign-manager/mobiledevices/search/',{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.$watchCollection('inventory', function () {
            $scope.adgroup.targeting.adnetworkSelected = [];
            angular.forEach($scope.inventory, function (value, key) {
                if (value) {
                    $scope.adgroup.targeting.adnetworkSelected.push(key);
                    $scope.inventoryChange(key);
                }
            });
        });

        $scope.$watchCollection('adgroup.targeting.devices', function () {
            //don't allow to deselect everything;
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.devices ) {
                var excluded = 0;
                var included = 0;

                var len = Object.keys($scope.adgroup.targeting.devices).length;
                var allBool = $scope.adgroup.targeting.devices.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.devices, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.devices[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.devices.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.devices.all;
                }
            }
        });

        $scope.$watchCollection('adgroup.targeting.environments', function () {
            //don't allow to deselect everything;
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.environments ) {
                var excluded = 0;
                var len = Object.keys($scope.adgroup.targeting.environments).length;
                angular.forEach($scope.adgroup.targeting.environments, function (value, key) {
                    if (!value) {
                        excluded += 1;
                        if (excluded >= len) {
                            $scope.adgroup.targeting.environments[key] = true;
                        }
                    }
                });
            }
        });

        $scope.$watchCollection('adgroup.targeting.os', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.os ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.os).length;
                var allBool = $scope.adgroup.targeting.os.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.os, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.os[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.os.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.os.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.browsers', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.browsers ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.browsers).length;
                var allBool = $scope.adgroup.targeting.browsers.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.browsers, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.browsers[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }

                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.browsers.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.browsers.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.connectionTypes', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.connectionTypes ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.connectionTypes).length;
                var allBool = $scope.adgroup.targeting.connectionTypes.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.connectionTypes, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.connectionTypes[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.connectionTypes.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.connectionTypes.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.videoSize', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.videoSize ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.videoSize).length;
                var allBool = $scope.adgroup.targeting.videoSize.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.videoSize, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.videoSize[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.videoSize.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.videoSize.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.videoPlayerType', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.videoPlayerType ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.videoPlayerType).length;
                var allBool = $scope.adgroup.targeting.videoPlayerType.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.videoPlayerType, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.videoPlayerType[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.videoPlayerType.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.videoPlayerType.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.videoAdPosition', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.videoAdPosition ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.videoAdPosition).length;
                var allBool = $scope.adgroup.targeting.videoAdPosition.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.videoAdPosition, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.videoAdPosition[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.videoAdPosition.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.videoAdPosition.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.videoAdInventory', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.videoAdInventory ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.videoAdInventory).length;
                var allBool = $scope.adgroup.targeting.videoAdInventory.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.videoAdInventory, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.videoAdInventory[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.videoAdInventory.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.videoAdInventory.all;
                }
            }
        });
        $scope.$watchCollection('adgroup.targeting.videoSkips', function () {
            if($scope.adgroup.targeting !== undefined && $scope.adgroup.targeting.videoSkips ) {
                //don't allow to deselect everything;
                var excluded = 0;
                var included = 0;
                var len = Object.keys($scope.adgroup.targeting.videoSkips).length;
                var allBool = $scope.adgroup.targeting.videoSkips.hasOwnProperty("all");
                len = allBool ? len - 1 : len;
                angular.forEach($scope.adgroup.targeting.videoSkips, function (value, key) {
                    if (key != "all") {
                        if (!value) {
                            excluded += 1;
                            if (excluded >= len) {
                                $scope.adgroup.targeting.videoSkips[key] = true;
                            }
                        }
                        else {
                            included += 1;
                        }
                    }
                });
                if (included >= len) {
                    $scope.adgroup.targeting.videoSkips.all = true;
                }
                else {
                    delete $scope.adgroup.targeting.videoSkips.all;
                }
            }
        });

        $scope.selectGeo = function () {
            $scope.geo.selectedGeo.geoName = $scope.geo.selectedGeo.canonical+"("+$scope.geo.selectedGeo.target_type+")";
            if($scope.geo.geoType == 'exclude') {
                $scope.adgroup.targeting.geos.exclude.push($scope.geo.selectedGeo);
            }
            else {
                $scope.adgroup.targeting.geos.include.push($scope.geo.selectedGeo);
            }
            $scope.geo.geoType = "include";
            $scope.geo.selectedGeo = undefined;
        };

        $scope.selectCarrier = function () {
            $scope.carrier.selectedCarrier.carrierName = $scope.carrier.selectedCarrier.carrierName+'('+$scope.carrier.selectedCarrier.id+')'+'('+$scope.carrier.selectedCarrier.countryCode+')';
            if($scope.carrier.type == 'exclude') {
                $scope.adgroup.targeting.carriers.exclude.push($scope.carrier.selectedCarrier);
            }
            else {
                $scope.adgroup.targeting.carriers.include.push($scope.carrier.selectedCarrier);
            }
            $scope.carrier.type = "include";
            $scope.carrier.selectedCarrier = undefined;
        };

        $scope.selectAdxAppCategory = function () {
            $scope.adxAppCategory.selectedAdxApp.appCatName = $scope.adxAppCategory.selectedAdxApp.category;
            if($scope.adxAppCategory.type == 'exclude') {
                $scope.adgroup.targeting.adxAppCategories.exclude.push($scope.adxAppCategory.selectedAdxApp);
            }
            else {
                $scope.adgroup.targeting.adxAppCategories.include.push($scope.adxAppCategory.selectedAdxApp);
            }
            $scope.adxAppCategory.type = "include";
            $scope.adxAppCategory.selectedAdxApp = undefined;
        };

        $scope.selectAdxSiteCategory = function () {
            $scope.adxSiteCategory.selectedAdxSite.siteCatName = $scope.adxSiteCategory.selectedAdxSite.category;
            if($scope.adxSiteCategory.type == 'exclude') {
                $scope.adgroup.targeting.adxSiteCategories.exclude.push($scope.adxSiteCategory.selectedAdxSite);
            }
            else {
                $scope.adgroup.targeting.adxSiteCategories.include.push($scope.adxSiteCategory.selectedAdxSite);
            }
            $scope.adxSiteCategory.type = "include";
            $scope.adxSiteCategory.selectedAdxSite = undefined;
        };

        $scope.selectOpenrtbSiteCategory = function () {
            $scope.openrtbSiteCategory.selectedOpenrtbSite.siteCatName = $scope.openrtbSiteCategory.selectedOpenrtbSite.category;
            if($scope.openrtbSiteCategory.type == 'exclude') {
                $scope.adgroup.targeting.openrtbSiteCategories.exclude.push($scope.openrtbSiteCategory.selectedOpenrtbSite);
            }
            else {
                $scope.adgroup.targeting.openrtbSiteCategories.include.push($scope.openrtbSiteCategory.selectedOpenrtbSite);
            }
            $scope.openrtbSiteCategory.type = "include";
            $scope.openrtbSiteCategory.selectedOpenrtbSite = undefined;
        };

        $scope.selectMobileDevice = function () {
            // $scope.mobileDevices.selectedMobileDevice.name = $scope.carrier.selectedCarrier.carrierName+'('+$scope.carrier.selectedCarrier.id+')'+'('+$scope.carrier.selectedCarrier.countryCode+')';
            if($scope.mobileDevices.type == 'exclude') {
                $scope.adgroup.targeting.mobileTargeting.exclude.push($scope.mobileDevices.selectedMobileDevice);
            }
            else {
                $scope.adgroup.targeting.mobileTargeting.include.push($scope.mobileDevices.selectedMobileDevice);
            }
            $scope.mobileDevices.type = "include";
            $scope.mobileDevices.selectedMobileDevice = undefined;
        };

        $scope.removeGeo = function (key,index) {
            $scope.adgroup.targeting.geos[key].splice(index,1);
        };
        $scope.removeAllSelectedGeos = function (key) {
            $scope.adgroup.targeting.geos[key] = []
        };

        $scope.removeCarrier = function (key,index) {
            $scope.adgroup.targeting.carriers[key].splice(index,1);
        };
        $scope.removeAllSelectedCarriers = function (key) {
            $scope.adgroup.targeting.carriers[key] = []
        };

        $scope.removeAdxSiteCategory = function (key,index) {
            $scope.adgroup.targeting.adxSiteCategories[key].splice(index,1);
        };
        $scope.removeAllAdxSiteCategories = function (key) {
            $scope.adgroup.targeting.adxSiteCategories[key] = []
        };

        $scope.removeAdxAppCategory = function (key,index) {
            $scope.adgroup.targeting.adxAppCategories[key].splice(index,1);
        };
        $scope.removeAllAdxAppCategories = function (key) {
            $scope.adgroup.targeting.adxAppCategories[key] = [];
        };

        $scope.removeOpenrtbSiteCategory = function (key,index) {
            $scope.adgroup.targeting.openrtbSiteCategories[key].splice(index,1);
        };
        $scope.removeAllremoveOpenrtbSiteCategories = function (key) {
            $scope.adgroup.targeting.openrtbSiteCategories[key] = [];
        };
        $scope.removeMobile = function (key,index) {
            $scope.adgroup.targeting.mobileTargeting[key].splice(index,1);
        };

        $scope.audienceChanged = function() {

            console.log($scope.adgroup.targeting.audience.audienceId);
            blockListSegment = {};
            var min_depth = 100000;
            var max_days = -100000;
            for(var i=0;i<$scope.adgroup.targeting.audience.audienceId.length;i++) {
                var audienceId = $scope.adgroup.targeting.audience.audienceId[i];
                for(var j=0; j<$scope.adgroup.audiences.length; j++) {
                    if(audienceId == $scope.adgroup.audiences[j].id) {
                        console.log($scope.adgroup.audiences[j]);
                        blockListSegment[$scope.adgroup.audiences[j].bidSegmentId] = audienceId;
                        var rules = JSON.parse($scope.adgroup.audiences[j].rules);
                        console.log(rules);
                        if(rules) {
                            var depth = null;
                            var days = null;
                            rules.rules.map(function(k,v){
                                console.log(k);
                                if(k.field == "depth") {
                                    depth = parseInt(k.value);
                                }
                                else if(k.field == "days"){
                                    days = parseInt(k.value);
                                }
                            });
                            console.log(depth);
                            console.log(days);
                            if(depth != undefined) {
                                if(depth<min_depth) {
                                    min_depth = depth;
                                }
                            }
                            if(days != undefined) {
                                if(days>max_days) {
                                    max_days = days;
                                }
                            }
                        }
                    }
                }
            }
            blockOptionsSegment()
            // all_audiences.forEach(function (data) {
            //
            // });
            // console.log(all_audiences);
            if(min_depth != 100000) {
                $scope.adgroup.targeting.audience.depth = min_depth;
            }
            else {
                $scope.adgroup.targeting.audience.depth = "";
            }
            if(max_days != -100000) {
                $scope.adgroup.targeting.audience.days = max_days;
            }
            else {
                $scope.adgroup.targeting.audience.days = "";
            }
            console.log("min depth {}",min_depth);
            console.log("max days {}",max_days);

        };

        function blockOptionsSegment() {
            var all_audiences = $scope.adgroup.audiences.filter(function(x) { return ($scope.adgroup.targeting.audience.audienceId.indexOf(x.id) < 0 && blockListSegment.hasOwnProperty(x.bidSegmentId)) }).map(function(data) {
                return data.id
            });
            $scope.adgroup.audiences.map(function(data){
                if(all_audiences.indexOf(data.id) < 0) {
                    data.block = false;
                }
                else {
                    data.block = true;
                }
            });
        }


        // added by Snehal
        $scope.advertiserFeatureRestrictions = advertiserFeatureRestrictions;
        $scope.showModal = function () {
            timer = $timeout(function () {
                var dialog = ngDialog.open({
                    template: 'app/campaign-manager/adgroup-placement-bid-opt-prop.html',
                    controller: 'AdgroupPlacementBidOptPropsController',
                    className: 'ngdialog-theme-default custom-width-800',
                });
            }, 2000);
        };

        stateDecide();
        initController();
        initServices();

        // added by Snehal
        $scope.IsVisible = false;
        $scope.ShowHide = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisible = $scope.IsVisible ? false:true;
        }

        // added by Snehal
        function adgroupPlacementBidOptPropInit(copyMode) {
            if($scope.editMode || copyMode) {
                $scope.campaignObjective = $scope.campaign.campaignObjective;
                var agroup = $scope.adgroup;

                $scope.adgroup.targetType = $scope.adgroupPlacementBidOptProps.optTargetType;
                $scope.adgroup.target = $scope.adgroupPlacementBidOptProps.optTargetType;
                $scope.adgroup.optTargetRatePercent = $scope.adgroupPlacementBidOptProps.optTargetRatePct;
                if($scope.adgroup.target=="ctr"){
                    $scope.adgroup.ctrTarget = $scope.adgroupPlacementBidOptProps.optTargetRatePct;
                }else if($scope.adgroup.target=="cpc"){
                    $scope.adgroup.cpcTarget = $scope.adgroupPlacementBidOptProps.optTargetRatePct;
                }else if($scope.adgroup.target=="completion_rate"){
                    $scope.adgroup.completionRateTarget = $scope.adgroupPlacementBidOptProps.optTargetRatePct;
                }
                $scope.adgroup.impressionThreshold = $scope.adgroupPlacementBidOptProps.impressionThresh;
                $scope.adgroup.conversionThreshold = $scope.adgroupPlacementBidOptProps.convThresh;
                $scope.adgroup.lookBackDays = $scope.adgroupPlacementBidOptProps.lookBackDays;
                $scope.adgroup.viewConversionPercent = $scope.adgroupPlacementBidOptProps.viewConvPercent;
                $scope.adgroup.clickConversionPercent = $scope.adgroupPlacementBidOptProps.clickConvPercent;
                $scope.adgroup.maxBidMultiplier = $scope.adgroupPlacementBidOptProps.maxBidMult;
                $scope.adgroup.targetCpaCents = $scope.adgroupPlacementBidOptProps.targetCpaCents;
                $scope.adgroup.bidAdjConvrThresh = $scope.adgroupPlacementBidOptProps.bidAdjConvThresh;
                $scope.adgroup.bidToAvgCpmThresh = $scope.adgroupPlacementBidOptProps.bidToAvgCpmThresh;
                $scope.adgroup.currentCpaBidPercent = $scope.adgroupPlacementBidOptProps.currentCpaBidPercent;
                $scope.adgroup.targetCpaBidPercent = $scope.adgroupPlacementBidOptProps.targetCpaBidPercent;
                $scope.adgroup.targetMinIbvLookback = $scope.adgroupPlacementBidOptProps.targetMinIbvLookBack;
                $scope.adgroup.targetMaxIbvLookback = $scope.adgroupPlacementBidOptProps.targetMaxIbvLookBack;
                $scope.adgroup.targetIbvMargin = $scope.adgroupPlacementBidOptProps.targetIbvMargin;
                $scope.adgroup.topPlacementLastNHour = $scope.adgroupPlacementBidOptProps.topPlcLastNHour;
                $scope.adgroup.topPlacementImpressionThresh = $scope.adgroupPlacementBidOptProps.topPlcImpsThresh;
                $scope.adgroup.topPlacementsLimit = $scope.adgroupPlacementBidOptProps.topPlcLimit;
                $scope.adgroup.bidResponseThresh = $scope.adgroupPlacementBidOptProps.bidRespThresh;
                $scope.adgroup.placementOptLastNHour = $scope.adgroupPlacementBidOptProps.plcOptLastNHour;
                $scope.adgroup.fillRateThresh = $scope.adgroupPlacementBidOptProps.fillRateThresh;
                $scope.adgroup.opportunityThresh = $scope.adgroupPlacementBidOptProps.opportunityThresh;
            }
            else{
                $scope.campaignObjective = $scope.campaign.campaignObjective;
                var agroup = $scope.adgroup;
//                var placementBidOptProp = adgroupPlacementBidOptProps;
                var adgroupPlacementBidOptProps = adgroupPlacementBidOptProperties;
                $scope.adgroup.targetType = $scope.adgroupPlacementBidOptProperties.targetType;
                $scope.adgroup.target = $scope.adgroupPlacementBidOptProperties.targetType;
                $scope.adgroup.optTargetRatePercent = $scope.adgroupPlacementBidOptProperties.optTargetRatePercent;
                if($scope.adgroup.target=="ctr"){
                    $scope.adgroup.ctrTarget = $scope.adgroupPlacementBidOptProperties.optTargetRatePercent;
                }else if($scope.adgroup.target=="cpc"){
                    $scope.adgroup.cpcTarget = $scope.adgroupPlacementBidOptProperties.optTargetRatePercent;
                }else if($scope.adgroup.target=="completion_rate"){
                    $scope.adgroup.completionRateTarget = $scope.adgroupPlacementBidOptProperties.optTargetRatePercent;
                }
                $scope.adgroup.impressionThreshold = $scope.adgroupPlacementBidOptProperties.impressionThreshold;
                $scope.adgroup.conversionThreshold = $scope.adgroupPlacementBidOptProperties.conversionThreshold;
                $scope.adgroup.lookBackDays = $scope.adgroupPlacementBidOptProperties.lookBackDays;
                $scope.adgroup.viewConversionPercent = $scope.adgroupPlacementBidOptProperties.viewConversionPercent;
                $scope.adgroup.clickConversionPercent = $scope.adgroupPlacementBidOptProperties.clickConversionPercent;
                $scope.adgroup.maxBidMultiplier = $scope.adgroupPlacementBidOptProperties.maxBidMultiplier;
                $scope.adgroup.targetCpaCents = $scope.adgroupPlacementBidOptProperties.targetCpaCents;
                $scope.adgroup.bidAdjConvrThresh = $scope.adgroupPlacementBidOptProperties.bidAdjConvrThresh;
                $scope.adgroup.bidToAvgCpmThresh = $scope.adgroupPlacementBidOptProperties.bidToAvgCpmThresh;
                $scope.adgroup.currentCpaBidPercent = $scope.adgroupPlacementBidOptProperties.currentCpaBidPercent;
                $scope.adgroup.targetCpaBidPercent = $scope.adgroupPlacementBidOptProperties.targetCpaBidPercent;
                $scope.adgroup.targetMinIbvLookback = $scope.adgroupPlacementBidOptProperties.targetMinIbvLookback;
                $scope.adgroup.targetMaxIbvLookback = $scope.adgroupPlacementBidOptProperties.targetMaxIbvLookback;
                $scope.adgroup.targetIbvMargin = $scope.adgroupPlacementBidOptProperties.targetIbvMargin;
                $scope.adgroup.topPlacementLastNHour = $scope.adgroupPlacementBidOptProperties.topPlacementLastNHour;
                $scope.adgroup.topPlacementImpressionThresh = $scope.adgroupPlacementBidOptProperties.topPlacementImpressionThresh;
                $scope.adgroup.topPlacementsLimit = $scope.adgroupPlacementBidOptProperties.topPlacementsLimit;
                $scope.adgroup.bidResponseThresh = $scope.adgroupPlacementBidOptProperties.bidResponseThresh;
                $scope.adgroup.placementOptLastNHour = $scope.adgroupPlacementBidOptProperties.placementOptLastNHour;
                $scope.adgroup.fillRateThresh = $scope.adgroupPlacementBidOptProperties.fillRateThresh;
                $scope.adgroup.opportunityThresh = $scope.adgroupPlacementBidOptProperties.opportunityThresh;
            }
        }

        // added by Snehal
        function adgroupPlacementBidOptProp(){
            if($scope.adgroup.target){
                $scope.adgroup.targetType = $scope.adgroup.target;
            }
            else{
                $scope.adgroup.targetType = $scope.campaignObjective;
            }
            if(!$scope.adgroup.targetIbvMargin){
                $scope.adgroup.targetIbvMargin =  0;
            }
            if(!$scope.adgroup.impressionThreshold){
                $scope.adgroup.impressionThreshold =  0;
            }
            if(!$scope.adgroup.lookBackDays){
                $scope.adgroup.lookBackDays =  0;
            }
            if(!$scope.adgroup.targetMinIbvLookback){
                $scope.adgroup.targetMinIbvLookback =  0;
            }
            if(!$scope.adgroup.targetMaxIbvLookback){
                $scope.adgroup.targetMaxIbvLookback =  0;
            }
            if(!$scope.adgroup.bidResponseThresh){
                $scope.adgroup.bidResponseThresh =  0;
            }
            if(!$scope.adgroup.topPlacementImpressionThresh){
                $scope.adgroup.topPlacementImpressionThresh =  0;
            }
            if(!$scope.adgroup.topPlacementsLimit){
                $scope.adgroup.topPlacementsLimit =  0;
            }
            if(!$scope.adgroup.placementOptLastNHour){
                $scope.adgroup.placementOptLastNHour =  0;
            }
            if(!$scope.adgroup.topPlacementLastNHour){
                $scope.adgroup.topPlacementLastNHour =  0;
            }
            if(!$scope.adgroup.fillRateThresh){
                $scope.adgroup.fillRateThresh =  0;
            }
            if(!$scope.adgroup.opportunityThresh){
                $scope.adgroup.opportunityThresh =  0;
            }
            if(!$scope.adgroup.goalPriceClick){
                $scope.adgroup.goalPriceClick =  0;
            }
            if(!$scope.adgroup.goalPriceView){
                $scope.adgroup.goalPriceView =  0;
            }

        }

        if($scope.campaignObjective=='ctr'){
            $scope.$watchCollection('[adgroup.goalPriceClick, adgroup.goalPriceView, adgroup.goalType]', function () {
                if($scope.adgroup.goalType == 'CPC' || $scope.adgroup.goalType == 'CPA(1/click)' || $scope.adgroup.goalType == 'CPA(many/click)'){

                    $scope.adgroup.clickConversionPercent = 100;

                }
                else if($scope.adgroup.goalType == 'CPA(view)+CPA(many/click)') {

                    $scope.adgroup.clickConversionPercent = 100;
                    if($scope.adgroup.goalPriceClick && $scope.adgroup.goalPriceView){
                        $scope.adgroup.viewConversionPercent = Math.round(($scope.adgroup.goalPriceView / $scope.adgroup.goalPriceClick)*100);
                    }

                }
            });
        }

        $scope.setOptTargetRatePercent = function(target){
            if($scope.adgroup.ctrTarget){
                $scope.adgroup.optTargetRatePercent = $scope.adgroup.ctrTarget;
            }else if($scope.adgroup.cpcTarget){
                $scope.adgroup.optTargetRatePercent = $scope.adgroup.cpcTarget;
            }else if($scope.adgroup.completionRateTarget){
                $scope.adgroup.optTargetRatePercent = $scope.adgroup.completionRateTarget;
            }
        };

        $scope.adgroupSearch = function(val){
            var advertiserId = $scope.advertiserId;
            return $http.get('/api/campaign-manager/adgroups/search/'+$scope.advertiserId+"/",{params:{query:val}}).then(function(response){
                return response.data;
            });
        };

        $scope.selectAdgroupToClone = function(adgroup) {
            if(adgroup !== undefined && adgroup !== null) {
                cloning = true;
                $scope.adgroup = angular.copy(adgroup);
                $scope.adgroup.adgroupName += "_clone";
                CampaignManagerService.getAudiences($scope.advertiserId).then(function (audienceData) {
                    $scope.adgroup.audiences = audienceData;
                    initTargeting();
                    CampaignManagerService.getBlockedPlacements($scope.advertiserId,adgroup.adgroupId).then(function (blockedUrls) {
                        var adgId = adgroup.adgroupId;
                        var cmppId = adgroup.campaignId;
                        $scope.adgroup.targeting.blockedPlacements.data = blockedUrls.join("\n");
                        $scope.adgroup.targeting.blockedPlacements.all = blockedUrls;
                        var cleaner = ["campaignId","adgroupId","createdBy","createdDate","lastModifiedBy","lastModifiedDate"];
                        var skipKeys = ["from"];
                        $scope.cleanAdgroupObject($scope.adgroup,cleaner,skipKeys);
                        console.log("after clean {}",$scope.adgroup);
                        $scope.adgroup.startDate = dateFiltering(moment(adgroup.startDate).add(offset,'MINUTES').toDate());
                        $scope.adgroup.endDate = dateFiltering(moment(adgroup.endDate).add(offset,'MINUTES').toDate());
                        var totalBudgetCents = adgroup.totalBudgetCents;
                        var impressionsLimit = adgroup.impressionsLimit;
                        var clicksLimit = adgroup.clicksLimit;

                        if(adgroup.totalBudgetCents == null || adgroup.totalBudgetCents == undefined){
                            totalBudgetCents = undefined;
                        }
                        else{
                            totalBudgetCents = (totalBudgetCents/100).toString();
                        }
                        if(adgroup.impressionsLimit == null || adgroup.impressionsLimit == undefined){
                            impressionsLimit = undefined;
                        }
                        if(adgroup.clicksLimit == null || adgroup.totalBudgetCents == undefined){
                            clicksLimit = undefined;
                        }
                        // $scope.adgroup.maxBidCents = $scope.adgroup.maxBidCents/100;

                        for(var i=0;i<$scope.adgroup.budgets.length;i++) {
                            $scope.adgroup.budgets[i].startDate = dateFiltering(moment($scope.adgroup.budgets[i].startDate).add(offset,'MINUTES').toDate());
                            if($scope.adgroup.budgets[i].endDate.indexOf("9999") == -1){
                                $scope.adgroup.budgets[i].endDate = dateFiltering(moment($scope.adgroup.budgets[i].endDate).add(offset,'MINUTES').toDate());
                            }
                            else {
                                $scope.adgroup.budgets[i].endDate = null;
                            }
                            $scope.adgroup.budgets[i].minDate = "";
                            $scope.adgroup.budgets[i].maxDate = "";
                            $scope.adgroup.budgets[i].startDateOpened = false;
                            $scope.adgroup.budgets[i].endDateOpened = false;
                            $scope.adgroup.budgets[i].startOption = {
                                formatYear: 'yy',
                                startingDay: 1,
                                minDate : moment()
                            };
                            $scope.adgroup.budgets[i].endOption = {
                                formatYear: 'yy',
                                startingDay: 1,
                                minDate : moment()
                            };
                            $scope.adgroup.budgets[i].spendLimit = $scope.adgroup.budgets[i].spendLimit/100;
                            delete $scope.adgroup.budgets[i].id;
                            delete $scope.adgroup.budgets[i].adgroupId;
                            delete $scope.adgroup.budgets[i].campaignId;
                            delete $scope.adgroup.budgets[i].createdBy;
                            delete $scope.adgroup.budgets[i].createdDate;
                            delete $scope.adgroup.budgets[i].lastModifiedBy;
                            delete $scope.adgroup.budgets[i].lastModifiedDate;
                        }

                        $scope.adgroup.startDate = dateFiltering(new Date($scope.adgroup.startDate));
                        if($scope.adgroup.endDate == "Invalid Date") {
                            $scope.adgroup.endDate = "9999-09-09";
                        }
                        if($scope.adgroup.startDate == "Invalid Date" || $scope.adgroup.startDate == null || $scope.adgroup.startDate == undefined) {

                            if(moment($scope.campaign.startDate).add(offset,'MINUTES').toDate() >= new Date($scope.advertiser.currentTime)) {
                                $scope.adgroup.startDate = $filter('date')(moment(campaign.startDate).add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss');
                            }
                            else {
                                $scope.adgroup.startDate = $filter('date')(moment($scope.advertiser.currentTime).toDate(), 'yyyy-MM-dd HH:mm:ss');
                            }
                        }
                        if($scope.adgroup.endDate.indexOf("9999") == -1) {
                            $scope.adgroup.endDate = dateFiltering(new Date($scope.adgroup.endDate));
                        }
                        else {
                            $scope.adgroup.endDate = null;
                        }
                        $scope.geo = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedGeo:undefined,geoType:"include",loadingLocations:false,include:[],exclude:[]};
                        $scope.carrier = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedCarrier:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};

                        $scope.adxAppCategory = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedAdxApp:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
                        $scope.adxSiteCategory = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedAdxSite:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
                        $scope.openrtbSiteCategory = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedOpenrtbSite:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
                        $scope.mobileDevices = {incSelectAll:false,excSelectAll:false,noResults: "No Results Found",selectedMobileDevice:undefined,type:"include",loadingLocations:false,include:[],exclude:[]};
                        $scope.adgroup.targeting.placements.includeList = [];
                        $scope.adgroup.targeting.placements.excludeList = [];
                        CampaignManagerService.getAdgroupPlacementBidOptProp($scope.advertiserId,cmppId.toString(),adgId).then(function(placementBidProp){
                            $scope.adgroupPlacementBidOptProps = placementBidProp;
                            adgroupPlacementBidOptPropInit(true);
                        });
                    });
                });
                if($scope.adgroup.fcapImpressions == undefined || $scope.adgroup.fcapImpressions == null) {
                    $scope.adgroup.fcapImpressions = 1;
                }
                calculateFcapNumber();
            }
            else {
                cloning = false;
            }
        };
        $scope.cleanAdgroupObject = function(obj, keys, skips){
            var index;
            for (var prop in obj) {
                // important check that this is objects own property
                // not from prototype prop inherited
                if(obj.hasOwnProperty(prop)){
                    if(skips.indexOf(prop) === -1) {


                        switch (typeof(obj[prop])) {
                            case 'string':
                                index = keys.indexOf(prop);
                                if (index > -1) {
                                    delete obj[prop];
                                }
                                break;
                            case 'number':
                                index = keys.indexOf(prop);
                                if (index > -1) {
                                    delete obj[prop];
                                }
                                break;
                            case 'object':
                                index = keys.indexOf(prop);
                                if (index > -1) {

                                    delete obj[prop];
                                }

                                else {
                                    // console.log("prop {} {}",prop,obj);
                                    $scope.cleanAdgroupObject(obj[prop], keys,skips);
                                }
                                break;
                        }
                    }
                }
            }
        }

        $scope.dollarToCents = function(maxBidDollar){
            if(maxBidDollar){
                $scope.adgroup.maxBidCents = maxBidDollar*100;
            }
        }

        $scope.goalPriceClickDollarToCents = function(goalPriceClickDollar){
            if(goalPriceClickDollar){
                $scope.adgroup.goalPriceClick = goalPriceClickDollar*100;
            }
        }

        $scope.goalPriceViewDollarToCents = function(goalPriceViewDollar){
            if(goalPriceViewDollar){
                $scope.adgroup.goalPriceView = goalPriceViewDollar*100;
            }
        }

    });


angular.module('adelementAdminApp')
    .controller('AssignCreativeNewEditController', function ($scope,$timeout,$stateParams,advertiser,campaign,adgroup,$state,$filter,CampaignManagerService,dataTableInstance,DTOptionsBuilder,DTColumnBuilder,SocialService,$compile,ngDialog) {
        var getUrl;
        var getData;
        var sizes = [];

        $scope.savingInProcess = false;

        $scope.showDataTable = false;
        $scope.advertiserId = $stateParams.advertiserId;
        $scope.campaignId = $stateParams.campaignId;
        $scope.adgroupId = $stateParams.adgroupId;

        $scope.advertiser = advertiser;
        $scope.campaign = campaign;
        $scope.adgroup = adgroup;

        $scope.selected = {};
        $scope.selectAll = false;
        $scope.nested = {};
        $scope.nested.dtInstance = {};
        $scope.groupBy = "mappingId";
        getUrl = "api/campaign-manager/getCreatives/"+$scope.advertiserId+"/"+$scope.campaignId+"/"+$scope.adgroupId;
        getData = function(d){
            d.creativeFlag = true;
            d.groupBy = $scope.groupBy;
        };

        var createdRow = function(row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }

        function stateDecide() {
            $scope.$parent.editModeCampaign = true;
            $scope.$parent.editModeAdgroup = true;
            $scope.$parent.editModeAssign = true;
            $scope.$parent.campaignId = $scope.campaignId;
            $scope.$parent.advertiserId = $scope.advertiserId;
            $scope.$parent.adgroupId = $scope.adgroupId;
        }

        function initDatatables() {

            console.log(sizes,"adadada");
            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withOption('paging', true)
                .withOption('order', [])
                .withDisplayLength(50)
                .withOption('createdRow', createdRow)
                .withOption('ajax', {
                    url: getUrl,
                    data: getData,
                    type: "POST",
                    headers: {"X-CSRF-TOKEN": SocialService.getCSRF()}
                })
                .withOption('createdRow', function (row, data, dataIndex) {
                    // Recompiling so we can bind Angular directive to the DT
                    if(data.assigned){
                        row.style.backgroundColor = "#d4edda";
                        row.style.color = "#155724";
                        row.style.fontWeight = "900";
                    }

                    $compile(angular.element(row).contents())($scope);
                })
                .withDOM('frtipl')
                .withDataProp('data')
                .withOption('processing', true)
                .withOption('serverSide', true)
                .withLightColumnFilter({

                    '2': {
                        type: 'text', html: 'input'
                    },
                    '3': {
                        type: 'text', html: 'input'
                    },
                    '4': {
                        type: 'select', html: 'select', values: sizes

                    },
                    '5': {
                        type: 'text', html: 'input'
                    },
                    '6': {
                        type: 'text', html: 'input'
                    },
                    '7': {
                        type: 'text', html: 'input'
                    }
                })
                .withPaginationType('full_numbers')
                .withOption('initComplete', function(settings) {
                    // Recompiling so we can bind Angular directive to the DT
                    $compile(angular.element('#' + settings.sTableId).contents())($scope);
                });
            var titleHtml = '<input type="checkbox" ng-model="selectAll" ng-change="toggleAll()">';
            $scope.dtColumns = [
                DTColumnBuilder.newColumn(null).withTitle(titleHtml).renderWith(function(data, type, full, meta) {
                    $scope.selected[data.id] = data.assigned;
                    // alert(JSON.stringify(data));
                    var ifAdTag = data.type != "ad_tag" & data.type != "inbanner_video_adtag" & data.type != "creative_vast_tag"  & data.type != "image/flash";
                    ifAdTag = ifAdTag | data.assigned;
                    return '<input type="checkbox" ng-disabled="'+ifAdTag+'" ng-model="selected[' + data.id + ']">';
                }),
                DTColumnBuilder.newColumn(null).withTitle("preview").notSortable().renderWith(function(data, type, full, meta) {
                    var tdiv = '<div  ng-if ="selected[\'' + data.id + '\']" class="relative-pos adreports">' +
                        '<div>' + '<creative-preview creativeid="' +
                        data.id + '" scale="true"></creative-preview>' +
                        '</div>' + '</div>';
                    var tdd = "<div>  preview disabled </div>";

                    return tdd;
                }),
                DTColumnBuilder.newColumn('id').withTitle('ID'),
                DTColumnBuilder.newColumn('name').withTitle('Name'),
                DTColumnBuilder.newColumn('sizes').withTitle('sizes'),
                DTColumnBuilder.newColumn('type').withTitle('type').renderWith(function(data, type, full, meta) {
                    $scope.creativeType = data;
                    return data.replace(/_/g," ");
                }),
                DTColumnBuilder.newColumn('start_date').withTitle('Start Date'),
                DTColumnBuilder.newColumn('end_date').withTitle('End Date').renderWith(function(data, type, full, meta) {
                    if(data.indexOf("9999")!=-1) {
                        data = "-";
                    }
                    return data;
                }),
                DTColumnBuilder.newColumn('assignedTotal').withTitle('Assigned Ads').notSortable(),
                DTColumnBuilder.newColumn('activeTotal').withTitle('Active Ads').notSortable(),
                DTColumnBuilder.newColumn('pausedTotal').withTitle('Paused Ads').notSortable()
            ];
            $scope.showDataTable = true;
        }


        function initCreativeSelection(){

        }
        function initSizes() {
            CampaignManagerService.getAdSizes().then(function (response) {
                sizes = response;
                initDatatables();
            })
        }

        $scope.toggleAll = function(){
            Object.keys($scope.selected).map(function(key, index) {
                $scope.selected[key]=$scope.selectAll;
            });
        };
        $scope.groupByChange = function () {
            $scope.nested.dtInstance.reloadData();
        };
        $scope.isAnyCreativeSelected = function (dirty) {
            var selectedData = false;
            Object.keys($scope.selected).forEach(function (key) {
                if($scope.selected[key]){
                    selectedData = true;
                }
            });
            return selectedData || dirty;
        };

        $scope.assignCreatives = function () {
            $scope.savingInProcess = true;
            var listOfIds = [];
            var saveData = {}
            Object.keys($scope.selected).forEach(function (key) {
                if($scope.selected[key]){
                    listOfIds.push(key.toString());
                }
            });
            saveData.ids = listOfIds;
            saveData.groupBy = $scope.groupBy;
            CampaignManagerService.saveAssignCreatives($scope.advertiserId,$scope.campaignId,$scope.adgroupId,saveData).then(function (response) {
                console.log("adgroupAds", response);
                $scope.nested.dtInstance.reloadData();
                $scope.savingInProcess = false;
            });

        };
        $scope.$watch('nested.dtInstance',function( newValue, oldValue ) {
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.nested.dtInstance);
            }
        });

        $scope.createZoneForAdgroupId = function () {
          CampaignManagerService.createZoneForAdgroupId($scope.advertiserId,$scope.campaignId,$scope.adgroupId);
        };

        $scope.openAdPreview1 = function (creativeId) {
            var dialog = ngDialog.open({ template: 'app/creative-manager/creative-adspreview.html',
                controller: 'CreativeAdsPreviewController',
                className: 'ngdialog-theme-default custom-width-800',
                resolve : {
                    creativeId : function(){
                        return creativeId;
                    }
                }
            });
        };

        $scope.openReapproval = function () {

                ngDialog.open({
                    template: 'app/campaign-manager/third-party-approval.html',
                    className: 'ngdialog-theme-default custom-width-800',
                    plain: false,
                    controller: 'ApprovalController',
                    resolve: {
                        advertiserId: function () {
                            return $scope.advertiserId;
                        },
                        adgroupId: function () {
                            return $scope.adgroupId;
                        },
                        creativesData: function() {
                            return CampaignManagerService.getThirdPartyCreatives($scope.advertiserId,$scope.adgroupId).then(function (response) {
                                return response;
                            });
                        }
                    }
                }).closePromise.then(function (data) {
                });
        };

        stateDecide();
        initCreativeSelection();
        initSizes();
    });


angular.module('adelementAdminApp')
    .directive('mydatepickerPopup',[ '$filter',function ($filter){
        return {
            restrict: 'EAC',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(viewValue) {
                    // viewValue = new Date(viewValue);
                    return $filter('date')(viewValue, 'yyyy-MM-dd HH:mm:ss');
                });
            }
        }
    }]);

angular.module('adelementAdminApp')
    .directive('myLink', function() {
    return {
        restrict: 'A',
        scope: {
            enabled: '=myLink'
        },
        link: function(scope, element, attrs) {
            element.bind('click', function(event) {
                if(!scope.enabled) {
                    event.preventDefault();
                }
            });
        }
    };
});

// added by Snehal
angular.module('adelementAdminApp')
    .controller('AdgroupPlacementBidOptPropsPopupController', function ($scope, $modal,$timeout,$stateParams,advertiser,campaign,adgroup,$state,$filter,CampaignManagerService,adgroupPlacementBidOptProperties,ngDialog) {

        $scope.showModal = function () {
            timer = $timeout(function () {
                var dialog = ngDialog.open({
                    template: 'app/campaign-manager/adgroup-placement-bid-opt-prop.html',
                    controller: 'AdgroupPlacementBidOptPropsController',
                    className: 'ngdialog-theme-default custom-width-800',
                });
            }, 2000);
        };
    })

    .controller('AdgroupPlacementBidOptPropsController', function ($scope,$timeout,$stateParams,$state,$filter,CampaignManagerService, adgroupPlacementBidOptProperties,ngDialog) {
        $scope.adgroupBidOpt={};
        $scope.saveAdgroupBidOptProp = function(){
            adgroupPlacementBidOptProperties.targetType = $scope.adgroupBidOpt.targetType;
            adgroupPlacementBidOptProperties.optTargetRatePercent = $scope.adgroupBidOpt.optTargetRatePercent;
            adgroupPlacementBidOptProperties.impressionThreshold = $scope.adgroupBidOpt.impressionThreshold;
            adgroupPlacementBidOptProperties.conversionThreshold = $scope.adgroupBidOpt.conversionThreshold;
            adgroupPlacementBidOptProperties.lookBackDays = $scope.adgroupBidOpt.lookBackDays;
            adgroupPlacementBidOptProperties.viewConversionPercent = $scope.adgroupBidOpt.viewConversionPercent;
            adgroupPlacementBidOptProperties.clickConversionPercent = $scope.adgroupBidOpt.clickConversionPercent;
            adgroupPlacementBidOptProperties.maxBidMultiplier = $scope.adgroupBidOpt.maxBidMultiplier;
            adgroupPlacementBidOptProperties.targetCpaCents = $scope.adgroupBidOpt.targetCpaCents;
            adgroupPlacementBidOptProperties.bidAdjConvrThresh = $scope.adgroupBidOpt.bidAdjConvrThresh;
            adgroupPlacementBidOptProperties.bidToAvgCpmThresh = $scope.adgroupBidOpt.bidToAvgCpmThresh;
            adgroupPlacementBidOptProperties.currentCpaBidPercent = $scope.adgroupBidOpt.currentCpaBidPercent;
            adgroupPlacementBidOptProperties.targetCpaBidPercent = $scope.adgroupBidOpt.targetCpaBidPercent;
            adgroupPlacementBidOptProperties.targetMinIbvLookback = $scope.adgroupBidOpt.targetMinIbvLookback;
            adgroupPlacementBidOptProperties.targetMaxIbvLookback = $scope.adgroupBidOpt.targetMaxIbvLookback;
            adgroupPlacementBidOptProperties.targetIbvMargin = $scope.adgroupBidOpt.targetIbvMargin;
            adgroupPlacementBidOptProperties.topPlacementLastNHour = $scope.adgroupBidOpt.topPlacementLastNHour;
            adgroupPlacementBidOptProperties.topPlacementImpressionThresh = $scope.adgroupBidOpt.topPlacementImpressionThresh;
            adgroupPlacementBidOptProperties.topPlacementsLimit = $scope.adgroupBidOpt.topPlacementsLimit;
            adgroupPlacementBidOptProperties.bidResponseThresh = $scope.adgroupBidOpt.bidResponseThresh;
            adgroupPlacementBidOptProperties.placementOptLastNHour = $scope.adgroupBidOpt.placementOptLastNHour;
            var adgrp = adgroupPlacementBidOptProperties;
            ngDialog.close();
        }

        // added by Snehal
        function adgroupPlacementBidOptProp() {
            if(!$scope.editMode) {
                var agroupBidOpt = adgroupPlacementBidOptProperties;
                $scope.adgroup.targetType = adgroupPlacementBidOptProperties.targetType;
                $scope.adgroup.optTargetRatePercent = adgroupPlacementBidOptProperties.optTargetRatePercent;
                $scope.adgroup.impressionThreshold = adgroupPlacementBidOptProperties.impressionThreshold;
                $scope.adgroup.conversionThreshold = adgroupPlacementBidOptProperties.conversionThreshold;
                $scope.adgroup.lookBackDays = adgroupPlacementBidOptProperties.lookBackDays;
                $scope.adgroup.viewConversionPercent = adgroupPlacementBidOptProperties.viewConversionPercent;
                $scope.adgroup.clickConversionPercent = adgroupPlacementBidOptProperties.clickConversionPercent;
                $scope.adgroup.maxBidMultiplier = adgroupPlacementBidOptProperties.maxBidMultiplier;
                $scope.adgroup.targetCpaCents = adgroupPlacementBidOptProperties.targetCpaCents;
                $scope.adgroup.bidAdjConvrThresh = adgroupPlacementBidOptProperties.bidAdjConvrThresh;
                $scope.adgroup.bidToAvgCpmThresh = adgroupPlacementBidOptProperties.bidToAvgCpmThresh;
                $scope.adgroup.currentCpaBidPercent = adgroupPlacementBidOptProperties.currentCpaBidPercent;
                $scope.adgroup.targetCpaBidPercent = adgroupPlacementBidOptProperties.targetCpaBidPercent;
                $scope.adgroup.targetMinIbvLookback = adgroupPlacementBidOptProperties.targetMinIbvLookback;
                $scope.adgroup.targetMaxIbvLookback = adgroupPlacementBidOptProperties.targetMaxIbvLookback;
                $scope.adgroup.targetIbvMargin = adgroupPlacementBidOptProperties.targetIbvMargin;
                $scope.adgroup.topPlacementLastNHour = adgroupPlacementBidOptProperties.topPlacementLastNHour;
                $scope.adgroup.topPlacementImpressionThresh = adgroupPlacementBidOptProperties.topPlacementImpressionThresh;
                $scope.adgroup.topPlacementsLimit = adgroupPlacementBidOptProperties.topPlacementsLimit;
                $scope.adgroup.bidResponseThresh = adgroupPlacementBidOptProperties.bidResponseThresh;
                $scope.adgroup.placementOptLastNHour = adgroupPlacementBidOptProperties.placementOptLastNHour;
            }else{
                var placementBidOptProp = $scope.adgroupPlacementBidOptProps;
                $scope.adgroup.targetType = adgroupPlacementBidOptProperties.targetType;
                $scope.adgroup.optTargetRatePercent = adgroupPlacementBidOptProperties.optTargetRatePercent;
                $scope.adgroup.impressionThreshold = adgroupPlacementBidOptProperties.impressionThreshold;
                $scope.adgroup.conversionThreshold = adgroupPlacementBidOptProperties.conversionThreshold;
                $scope.adgroup.lookBackDays = adgroupPlacementBidOptProperties.lookBackDays;
                $scope.adgroup.viewConversionPercent = adgroupPlacementBidOptProperties.viewConversionPercent;
                $scope.adgroup.clickConversionPercent = adgroupPlacementBidOptProperties.clickConversionPercent;
                $scope.adgroup.maxBidMultiplier = adgroupPlacementBidOptProperties.maxBidMultiplier;
                $scope.adgroup.targetCpaCents = adgroupPlacementBidOptProperties.targetCpaCents;
                $scope.adgroup.bidAdjConvrThresh = adgroupPlacementBidOptProperties.bidAdjConvrThresh;
                $scope.adgroup.bidToAvgCpmThresh = adgroupPlacementBidOptProperties.bidToAvgCpmThresh;
                $scope.adgroup.currentCpaBidPercent = adgroupPlacementBidOptProperties.currentCpaBidPercent;
                $scope.adgroup.targetCpaBidPercent = adgroupPlacementBidOptProperties.targetCpaBidPercent;
                $scope.adgroup.targetMinIbvLookback = adgroupPlacementBidOptProperties.targetMinIbvLookback;
                $scope.adgroup.targetMaxIbvLookback = adgroupPlacementBidOptProperties.targetMaxIbvLookback;
                $scope.adgroup.targetIbvMargin = adgroupPlacementBidOptProperties.targetIbvMargin;
                $scope.adgroup.topPlacementLastNHour = adgroupPlacementBidOptProperties.topPlacementLastNHour;
                $scope.adgroup.topPlacementImpressionThresh = adgroupPlacementBidOptProperties.topPlacementImpressionThresh;
                $scope.adgroup.topPlacementsLimit = adgroupPlacementBidOptProperties.topPlacementsLimit;
                $scope.adgroup.bidResponseThresh = adgroupPlacementBidOptProperties.bidResponseThresh;
                $scope.adgroup.placementOptLastNHour = adgroupPlacementBidOptProperties.placementOptLastNHour;

            }
        }
     });

angular.module('adelementAdminApp').filter('timezonestr', function() {

    // Create the return function
    // set the required parameter name to **number**
    return function(number) {

        // Ensure that the passed in data is a number
        if(isNaN(number)) {

            // If the data is not a number or is less than one (thus not having a cardinal value) return it unmodified.
            return number;

        } else {
            var input = Math.abs(number);
            var hours = parseInt( input / 60, 10 );
            var minutes = (input - (hours * 60)) < 10 ? '0' + (input - (hours * 60)) : input - (hours * 60);

            if (number.toString().charAt(0) == '-'){
                return ' [ GMT -'+hours+':'+minutes+' ]';
            }else{
                return ' [ GMT +'+hours+':'+minutes + ' ]';
            }
        }
    }
});

angular
    .module('adelementAdminApp')
    .controller('ApprovalController',['$scope','CampaignManagerService','advertiserId','adgroupId','creativesData', function($scope,CampaignManagerService,advertiserId,adgroupId,creativesData) {
        function initControllerVariables() {
            $scope.advertiserId = advertiserId;
            $scope.adgroupId = adgroupId;
            $scope.creativesData = creativesData;
        }
        $scope.closeIt = function() {
            var dataReapproval = {ads:[]};
            angular.forEach($scope.creativesData,function(ad){
                if(ad.check){
                    dataReapproval.ads.push(ad);
                }
            });
            if(dataReapproval.ads.length>0) {
                CampaignManagerService.resendForApproval(advertiserId,adgroupId,dataReapproval).then(function(response){
                   $scope.closeThisDialog();
                });
            }
            else {
                $scope.closeThisDialog();
            }
        };
        initControllerVariables();
    }]);

