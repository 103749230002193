/**
 * Created by maitray on 26/8/16.
 */
angular
    .module('adelementAdminApp')
    .controller('BudgetController',['$scope','$rootScope','$state','$stateParams','$filter','Reports','advertiserId','campaignId',function($scope,$rootScope,$state,$stateParams,$filter,Reports,advertiserId,campaignId){
        var advertiserId = advertiserId;
        var campaignId = campaignId;
        var todays = new Date();
        $scope.campaign = {}
        $scope.infiniteDate = new Date("9999-09-09 00:00:00");
        $scope.opened = {};
        $scope.options = {
            minDate: new Date()
        };
        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        // $scope.tableActiveForm = {}

        var m = moment();
        var today = m.set({hour:0,minute:0,second:0,millisecond:0}).toDate();
        $scope.showFirstTime= false;

        $scope.openCalendar = function($event, elementOpened) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened[elementOpened] = !$scope.opened[elementOpened];
        };
        $scope.switchBudgets = function(show){
            $scope.activeBudget = show;
        };
        function initVariables() {
            todays = new Date();
            $scope.dailyIsLive = false;
            $scope.isCollapsed = true;
            $scope.budgets = [];
            $scope.dailyBudgets = [];
            $scope.scheduledBudgets = [];
            $scope.paces = [
                {value: "accelerated", text: 'Accelerated'},
                {value: "standard", text: 'Standard'}
            ];
            $scope.budgetType = [
                {value: "daily", text: 'Daily'},
                {value: "total", text: 'Total'}
            ];
        }

        function initServices(firstTime){
            Reports.getCampaign(advertiserId,campaignId).then(function(response){
                $scope.campaign = response
                $scope.budgets = angular.copy(response.budgets);
                $scope.scheduledBudgets = [];
                checkActiveBudget();
                if(firstTime) {
                    for(var i=0;i<$scope.campaign.budgets.length;i++) {
                        $scope.campaign.budgets[i].startDate = dateFiltering(new Date($scope.campaign.budgets[i].startDate));
                        if($scope.campaign.budgets[i].endDate.indexOf("9999") == -1) {
                            $scope.campaign.budgets[i].endDate = dateFiltering(new Date($scope.campaign.budgets[i].endDate));
                        }
                        else {
                            $scope.campaign.budgets[i].endDate = null;
                        }
                        $scope.campaign.budgets[i].minDate = "";
                        $scope.campaign.budgets[i].maxDate = "";
                        $scope.campaign.budgets[i].startDateOpened = false;
                        $scope.campaign.budgets[i].endDateOpened = false;
                        $scope.campaign.budgets[i].startOption = {
                            formatYear: 'yy',
                            startingDay: 1,
                            minDate : moment()
                        };
                        $scope.campaign.budgets[i].endOption = {
                            formatYear: 'yy',
                            startingDay: 1,
                            minDate : moment()
                        };
                        $scope.campaign.budgets[i].spendLimit = $scope.campaign.budgets[i].spendLimit/100;
                    }
                }
            });
        }
        function checkActiveBudget(){
            var now = new Date();
            for(var i=0;i<$scope.budgets.length;i++){
                $scope.budgets[i].dailyBudgetCents = $scope.budgets[i].dailyBudgetCents/100;
                $scope.budgets[i].totalBudgetCents = $scope.budgets[i].totalBudgetCents/100;

                var startDate = new Date($scope.budgets[i].startDate);
                var endDate = new Date($scope.budgets[i].endDate);

                if(endDate == $scope.infiniteDate){
                    if(now > startDate){
                        $scope.activeBudget = false;
                        $scope.budgets[i].active = true;
                    }
                }

                if(now >= startDate && now <= endDate){
                    $scope.activeBudget = true;
                    $scope.budgets[i].active = true;
                }
                else{
                    $scope.budgets[i].active = false;
                }
                $scope.budgets[i].budgetCents = $scope.budgets[i].budgetType == "total"?$scope.budgets[i].totalBudgetCents:$scope.budgets[i].dailyBudgetCents;
                $scope.budgets[i].startDate = startDate;
                $scope.budgets[i].endDate = endDate;
                $scope.scheduledBudgets.push($scope.budgets[i]);
            }
        }

        $scope.addNewBudgetSchedule = function(){
            $scope.inserted = {startDate:dateFiltering(new Date()),endDate:dateFiltering(new Date()),dailyBudgetCents:0,deliveryPace:"standard",budgetType:"total",spendLimit:100,clicksLimit:0,impressionsLimit:0,minDate:"",maxDate:"",startDateOpened:false,endDateOpened:false,startOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            },endOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            },editing:true};
            // var inserted = {startDate:dateFiltering(new Date()),endDate:dateFiltering(new Date()),budgetType:"total",spendLimit:100,clicksLimit:0,impressionsLimit:0,deliveryPace:"standard",minDate:"",maxDate:"",startDateOpened:false,endDateOpened:false,startOption:{
            //     formatYear: 'yy',
            //     startingDay: 1,
            //     minDate : moment()
            // },endOption:{
            //     formatYear: 'yy',
            //     startingDay: 1,
            //     minDate : moment()
            // }};
            $scope.campaign.budgets.push($scope.inserted);
        };

        $scope.addNewDailyBudget = function(){
            var inserted = {startDate:dateFiltering(new Date()),endDate:dateFiltering(new Date()),budgetType:"total",spendLimit:100,clicksLimit:0,impressionsLimit:0,deliveryPace:"standard",minDate:"",maxDate:"",startDateOpened:false,endDateOpened:false,startOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            },endOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            }};
            $scope.dailyBudgets.push(inserted);
            $scope.dailyIsLive = true;
        };

        $scope.showBudget = function(budget) {
            var selected = [];
            if(budget.budgetType) {
                selected = $filter('filter')($scope.budgetType, {value: budget.budgetType});
            }
            return selected.length ? selected[0].text : 'Not set';
        };

        $scope.saveBudget = function(data,budget){
            // $scope.$apply();
            var bud = {}
            var id = $scope.scheduledBudgets.indexOf(budget);
            bud.startDate  = moment(budget.startDate).format('YYYY-MM-DD HH:mm:ss');

            bud.spendLimit = (data.spendLimit == undefined || data.spendLimit == 0 || data.spendLimit == null)?null:data.spendLimit;
            bud.clicksLimit = (data.clicksLimit == undefined || data.clicksLimit == 0 || data.clicksLimit == null)?null:data.clicksLimit;
            bud.impressionsLimit = (data.impressionsLimit == undefined || data.impressionsLimit == 0 || data.impressionsLimit == null)?null:data.impressionsLimit;
            bud.deliveryPace = data.deliveryPace;
            bud.budgetType = data.budgetType;

            if(budget.budgetType == "daily" && (budget.endDate == undefined || budget.endDate == null || budget.endDate == "" )) {
                bud.endDate  = null;
            }
            else {
                bud.endDate  = moment(budget.endDate).format('YYYY-MM-DD HH:mm:ss');
            }
            if(budget.hasOwnProperty("id")) {
                bud.id = budget.id
            }

            if((bud.impressionsLimit == null || bud.impressionsLimit == undefined || bud.impressionsLimit == 0) && (bud.clicksLimit == null || bud.clicksLimit == undefined || bud.clicksLimit == 0)  && (bud.spendLimit == null || bud.spendLimit == undefined || bud.spendLimit == 0) )
            {
                this.rowform.$setError("spendLimit","you need to ");
                this.rowform.$setError("clicksLimit","set atleast one of");
                this.rowform.$setError("impressionsLimit","these parameters");

                return "you need to set atleast one of the three limit parameters";

            }
            Reports.saveBudget(bud,advertiserId,campaignId).then(function(data){
                if(data.status!=="success"){
                    $scope.error = data.error;
                    $scope.errorState = data.errorState;
                    alert($scope.error);
                    $scope.scheduledBudgets.splice(id,1);
                    return "error";
                }
            });
            console.log("budget data",bud);
        };

        $scope.checkForEmptyBudget = function(data){
            // if(data == undefined || data == null){
            //     return "budget cannot be empty";
            // }
            if(data<0){
                return "budget cannot be negative";
            }
        };
        $scope.saveDailyBudget  = function(data,budget){
            console.log("$data",data);
            console.log("budget",budget);
            budget.budgetCents = data;
            budget.startDate  = moment(budget.startDate).format('YYYY-MM-DD HH:mm:ss');
            budget.endDate  = moment($scope.infiniteDate).format('YYYY-MM-DD HH:mm:ss');
            budget.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
            Reports.saveBudget(budget,advertiserId,campaignId).then(function(data){
                if(data.status!=="success"){
                    $scope.error = data.error;
                    alert($scope.error);
                }
            });
        };
        $scope.checkBudget = function(){
          console.log("THIS IS DATA");
        };
        $scope.deleteBudget = function(budget){
            $scope.showFirstTime = false;
            var index = $scope.campaign.budgets.indexOf(budget);
            if(budget.hasOwnProperty("id")){
                var id = budget.id;
                Reports.deleteBudget(id,advertiserId,campaignId).then(function(data){
                    if(data.status == "success"){
                        $scope.campaign.budgets.splice(index,1);
                    }
                });
            }
            else{
                $scope.campaign.budgets.splice(index,1);
            }
        };

        $scope.checkForDelete = function(budget){
          if(budget.startDate > todays){
              return false;
          }
            return true;
        };

        $scope.checkForUpdate = function(budget){
            if(budget.budgetType != "daily"){
                if(budget.startDate < todays && budget.endDate < todays){
                    return true;
                }
            }
            return false;
        };

        $scope.checkStartDate = function(budget){
            if(budget.budgetType == "daily"){
                return true;
            }
            else{
                if(budget.startDate < todays){
                    return true;
                }
            }
            return false;
        };

        $scope.checkEndDate = function(budget){
            if(budget.budgetType == "daily"){
                return true;
            }
            else{
                if(budget.endDate < todays){
                    return true;
                }
            }
            return false;
        };

        //Date Time Picker Functions
        $scope.startLessThanToday = function(which,index,editing) {
            if(editing == undefined ) {
                if (which == 0) {
                    var startD = new Date($scope.campaign.startDate);
                    return today > startD;
                } else {
                    var startD = new Date($scope.campaign.budgets[index].startDate);
                    return today > startD;
                }
            }
            else {
                return false
            }
        };
        function dateFiltering(dateObj){
            return $filter('date')(dateObj, 'yyyy-MM-dd HH:mm:ss');
        }
        $scope.dateFormatInBudget = function (data,index,parent) {
            $scope.campaign[parent][index][data] =  $filter('date')($scope.campaign[parent][index][data], 'yyyy-MM-dd HH:mm:ss');
        };
        $scope.checkOverLappingDatesForCampaign = function (viewValue,which) {
            var currentDate = new Date(viewValue);
            var campStartDate = new Date($scope.campaign.startDate);
            var campEndDate = new Date($scope.campaign.endDate);
            // added by Snehal
            var todaysDate = new Date();
            if(campStartDate>=todaysDate){
                if(which == 1 && viewValue == null) {
                    return true;
                }
                if($scope.campaign.endDate == null) {
                    return currentDate > campStartDate;
                }
                return currentDate > campStartDate && currentDate < campEndDate;
            }
            else{
                return true;
            }
        };
        $scope.checkOverLappingDates = function (viewValue,index,type) {
            var currentDate = new Date(viewValue);

            for(var i=0;i<$scope.campaign.budgets.length;i++) {
                if(index!=i || type == 0) {
                    var budget = $scope.campaign.budgets[i];
                    var startDate;
                    var endDate;
                    if (budget.startDate && budget.startDate != "") {
                        startDate = new Date(budget.startDate);
                    }
                    if (budget.endDate && budget.endDate != "") {
                        endDate = new Date(budget.endDate);
                    }
                    else if(budget.type=="daily"){
                        endDate = new Date("09/09/9999");
                    }
                    if (currentDate > startDate && currentDate < endDate) {
                        return false;
                    }
                }
            }
            return true;
        };
        $scope.checkStartMoreThanEndDateBudget = function (value,startOrEnd,index) {
            if(startOrEnd == 0){
                if(value) {
                    var startDate = new Date(value);
                    var data = angular.copy($scope.campaign.budgets[index].endDate);
                    var endDate = data == null?null:new Date(data);
                    if(endDate != null) {
                        // alert(endDate.toDateString())
                        return startDate < endDate;
                    }
                    else {
                        return true;
                    }
                }
                return false
            }
            else {
                if(value != null) {
                    var endDate = new Date(value);
                    var data = angular.copy($scope.campaign.budgets[index].startDate);
                    var startDate = new Date(data);
                    return startDate < endDate;
                }
                return true;
            }
        };

        $scope.addBudget = function() {
            var inserted = {startDate:dateFiltering(new Date()),endDate:dateFiltering(new Date()),budgetType:"total",spendLimit:100,clicksLimit:0,impressionsLimit:0,deliveryPace:"standard",minDate:"",maxDate:"",startDateOpened:true,endDateOpened:true,startOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            },endOption:{
                formatYear: 'yy',
                startingDay: 1,
                minDate : moment()
            }}
            $scope.campaign.budgets.push(inserted);
            // $scope.tableActiveForm.$show();
            $scope.showFirstTime = true;
        };

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
                else{
                    $scope.campaign.budgets[index].startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;
                }
                else{
                    $scope.campaign.budgets[index].endDateOpened = true;
                }
            }
        };
        $scope.closeIt = function (msg) {
          $scope.closeThisDialog(msg);
        };
        initVariables();
        initServices(true);

    }]);
