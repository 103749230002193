/**
 * Created by adelement on 21/4/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('user-mgt', {
                parent: 'entity',
                url: '/usermanagement',
                data: {
                    authorities: ['ROLE_ADMIN','SUPER_EXPERT_WRITE','SUPER_EXPERT_ADMIN'],
                    pageTitle:'adelementAdminApp.pagetitles.user-mgt.title'
                },
                templateUrl: 'app/admin/user-mgt/user-mgt.html',
                controller: 'UserMgtController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    });
