/**
 * Created by maitray on 28/3/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .factory('UserRolesAssign', function ($resource) {
        return $resource('api/assignroles', {}, {
        });
    });
