'use strict';

angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('aeAdvertisers', {
                parent: 'entity',
                url: '/aeAdvertiserss',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'adelementAdminApp.aeAdvertisers.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/aeAdvertisers/aeAdvertiserss.html',
                        controller: 'AeAdvertisersController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aeAdvertisers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('aeAdvertisers.detail', {
                parent: 'entity',
                url: '/aeAdvertisers/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'adelementAdminApp.aeAdvertisers.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/aeAdvertisers/aeAdvertisers-detail.html',
                        controller: 'AeAdvertisersDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aeAdvertisers');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AeAdvertisers', function($stateParams, AeAdvertisers) {
                        return AeAdvertisers.get({id : $stateParams.id});
                    }]
                }
            })
            .state('aeAdvertisers.new', {
                parent: 'aeAdvertisers',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/aeAdvertisers/aeAdvertisers-dialog.html',
                        controller: 'AeAdvertisersDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    companyName: null,
                                    legalEntityName: null,
                                    contactName: null,
                                    contactEmail: null,
                                    contactPhone: null,
                                    billingContactName: null,
                                    billingEmail: null,
                                    billingPhoneNo: null,
                                    address: null,
                                    city: null,
                                    country: null,
                                    websiteUrl: null,
                                    androidApp: null,
                                    iosApp: null,
                                    timezone: null,
                                    language: null,
                                    billingCurrency: null,
                                    numberFormat: null,
                                    businessCategory: null,
                                    businessType: null,
                                    thirdPartyAdServer: null,
                                    allowAdTagAccess: null,
                                    creditLimit: null,
                                    creationTimestamp: null,
                                    approvingUser: null,
                                    status: null,
                                    note: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('aeAdvertisers', null, { reload: true });
                    }, function() {
                        $state.go('aeAdvertisers');
                    })
                }]
            })
            .state('aeAdvertisers.edit', {
                parent: 'aeAdvertisers',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/aeAdvertisers/aeAdvertisers-dialog.html',
                        controller: 'AeAdvertisersDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['AeAdvertisers', function(AeAdvertisers) {
                                return AeAdvertisers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('aeAdvertisers', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('aeAdvertisers.delete', {
                parent: 'aeAdvertisers',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/aeAdvertisers/aeAdvertisers-delete-dialog.html',
                        controller: 'AeAdvertisersDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['AeAdvertisers', function(AeAdvertisers) {
                                return AeAdvertisers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('aeAdvertisers', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('aeAdvertisers.approveuser', {
                parent: 'aeAdvertisers',
                url: '/approveuser/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/aeAdvertisers/aeAdvertisers-dialog.html',
                        controller: 'AeAdvertisersDialogProspectiveController',
                        size: 'lg',
                        resolve: {
                            prospectiveUser: ['ProspectiveUsers', function(ProspectiveUsers) {
                                return ProspectiveUsers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                            $state.go('aeAdvertisers', null, { reload: true });
                        }, function() {
                            $state.go('aeAdvertisers');
                        })
                }]
            });


    });
