/**
 * Created by rahul on 16/3/16.
 */
'use strict';

angular.module('adelementAdminApp').controller('AeAdvertisersDialogProspectiveController',
    ['$scope', '$stateParams', '$uibModalInstance', 'AeAdvertisers','prospectiveUser',
        function($scope, $stateParams, $uibModalInstance, AeAdvertisers,prospectiveUser) {

            //$scope.aeAdvertisers = prospectiveUser;
            function initVariables(){
                $scope.aeAdvertisers = {};
            }

            function initServices(){
                prospectiveUser["$promise"].then(function(data){
                    $scope.aeAdvertisers = {
                        "contactName": data.firstName + " " + data.lastName,
                        "companyName": data.companyName,
                        "legalEntityName" : data.companyName,
                        "contactEmail" : data.email,
                        "contactPhone" : data.phoneNo,
                        "billingContactName": data.firstName,
                        "billingEmail" : data.email,
                        "billingPhoneNo" : data.phoneNo,
                        "city" : data.city,
                        "country" : data.country,
                        "websiteUrl" : data.websiteUrl,
                        "androidApp" : data.androidApp,
                        "iosApp" : data.iosApp,



                    };
                });
            }

            $scope.load = function(id) {
                AeAdvertisers.get({id : id}, function(result) {
                    $scope.aeAdvertisers = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('adelementAdminApp:aeAdvertisersUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.aeAdvertisers.id != null) {
                    AeAdvertisers.update($scope.aeAdvertisers, onSaveSuccess, onSaveError);
                } else {
                    AeAdvertisers.save($scope.aeAdvertisers, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function() {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.datePickerForTimezone = {};

            $scope.datePickerForTimezone.status = {
                opened: false
            };

            $scope.datePickerForTimezoneOpen = function($event) {
                $scope.datePickerForTimezone.status.opened = true;
            };
            $scope.datePickerForCreationTimestamp = {};

            $scope.datePickerForCreationTimestamp.status = {
                opened: false
            };

            $scope.datePickerForCreationTimestampOpen = function($event) {
                $scope.datePickerForCreationTimestamp.status.opened = true;
            };
            initVariables();
            initServices();

        }]);
