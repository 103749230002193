'use strict';

angular.module('adelementAdminApp').controller('ProspectiveUsersDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProspectiveUsers',
        function($scope, $stateParams, $uibModalInstance, entity, ProspectiveUsers) {

        $scope.prospectiveUsers = entity;
        $scope.load = function(id) {
            ProspectiveUsers.get({id : id}, function(result) {
                $scope.prospectiveUsers = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('adelementAdminApp:prospectiveUsersUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.prospectiveUsers.id != null) {
                ProspectiveUsers.update($scope.prospectiveUsers, onSaveSuccess, onSaveError);
            } else {
                ProspectiveUsers.save($scope.prospectiveUsers, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);
