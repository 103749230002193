'use strict';

angular.module('adelementAdminApp')
    .controller('AeAdvertisersDetailController', function ($scope, $rootScope, $stateParams, entity, AeAdvertisers) {
        $scope.aeAdvertisers = entity;
        $scope.load = function (id) {
            AeAdvertisers.get({id: id}, function(result) {
                $scope.aeAdvertisers = result;
            });
        };
        var unsubscribe = $rootScope.$on('adelementAdminApp:aeAdvertisersUpdate', function(event, result) {
            $scope.aeAdvertisers = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
