(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .factory('ChangeHistoryService', ChangeHistoryService);

    ChangeHistoryService.$inject = ['$http'];

    function ChangeHistoryService ($http) {

        var service = {
            findAllChangeHistory: findAllChangeHistory,
        };

        return service;

        function findAllChangeHistory() {
            return $http.get('api/changehistory').then(function (response) {
                return response.data;
            });
        }
    }
})();
