'use strict';
angular.module('adelementAdminApp')
    .directive('adTagPreview', function() {
      return {
        restrict: 'E',
        priority: 1001,
        scope: {
            source:'=source',
            index:'=index',
        },
        link: function(scope, element, attrs) {
           scope.$watch('[source,index]',function(oldv,newv){
              if(oldv[0]){
                var iframe;
                var isIframe = document.getElementById("ifrm"+oldv[1])
                if(isIframe==null){
                    iframe = document.createElement('iframe');
                    iframe.setAttribute("id", "ifrm"+oldv[1]);
                    element[0].appendChild(iframe);
                }
                else{
                    isIframe.parentNode.removeChild(isIframe);
                    iframe = document.createElement('iframe');
                    iframe.setAttribute("id", "ifrm"+oldv[1]);
                    element[0].appendChild(iframe);
                }
                var doc = iframe.contentWindow.document;
                doc.open();
                doc.write(oldv[0]);
                doc.close();
              }
           });
        }
      };
    })

 .controller('CreativeManagerController', function ($scope,CreativeManagerService,$stateParams,$http,DTOptionsBuilder,$compile,dataTableInstance,DTColumnBuilder,DTColumnDefBuilder,SocialService,$timeout,$state,GetCretivesByAdvID,GetAdvertiserIDName,$sessionStorage,ngDialog,$location,$filter,$templateCache,$rootScope){
    $scope.creativeManagerData = [];
    $scope.panelids = ['creative-manager'];
    $scope.dtInstance = {};
    $scope.selectedAdvertiser= null;
    $scope.allAdvertisersIdName = [];
    $rootScope.preferencesData = {};
    if(!$sessionStorage.hasOwnProperty("firstTimeCreatives")){
        $sessionStorage.firstTimeCreatives = true;
    }
    $scope.breadCrum = [];
    $scope.startDate = new Date();
    $scope.endDate = new Date();
    $scope.dateRange = {};
    $scope.segmentTitle = {"concept": "Ad Id", "size": "Size"};
    $scope.creativePreferences={}
    $scope.modalDates = {
         startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
         endDate: moment().format('YYYY-MM-DD HH:mm:ss')
     };
    $scope.creativeData={
        adSummaryFilter:'concept'
    };
    $scope.selected = {};
    $scope.selectAll = false;
    $scope.toggleAll = toggleAll;
    $scope.toggleOne = toggleOne;
    $scope.isCreativeAdtagPreview = false;
    var advertiserId;
    var summaryFilter;
    var summaryFilterId;
    var FilteredData= false;
    var getUrl;
    var getData;
    $scope.addCreativeOptions={};
    $scope.data = {};
    $scope.data.getLevels = function() {
      return [ {'name': 'Video', 'id': 1},
               {'name': 'Static', 'id': 2},
               {'name': 'HTML5', 'id': 3}]
    };

    // get all the advertisers
    var advertiserInfoPromise =  GetAdvertiserIDName.query();
    advertiserInfoPromise.$promise.then(function(response){
         $scope.allAdvertisersIdName = response;
         if($scope.stateDecide){
            $scope.selectedAdvertiser = parseInt($stateParams.advertiserId);
         }
    });

    //caption util function for date formatting
    function getCaptionDate(dtStr){
        var months = ["January", "February", "March", "April",
                                    "May", "June", "July", "August",
                                    "September", "October", "November", "December"]
        var dt = new Date(dtStr);
        var fdt = dt.getDate() + " " + months[Number(dt.getMonth())] + ", " + dt.getFullYear();
        return fdt;
    };

    // select Advertiser for breadcrum
    $scope.selectAdvertiser = function (selectedAdvertiserId){
        var allAdvertisersIdName = $scope.allAdvertisersIdName;
        for (var key in allAdvertisersIdName) {
            var obj = allAdvertisersIdName[key];
            if (obj.id === selectedAdvertiserId) {
                return (obj.name);
            }
        }
    }

    $scope.dateChanged = function(label){
        $sessionStorage.dateLabel = label;
        if(label === "custom range"){
            $sessionStorage.dateRange=$scope.modalDates;
        }
        $scope.dtInstance.reloadData();
    };

    function init(){
        $scope.adSummaryFilter="concept";
        $scope.creativeData.adSummaryFilter= $stateParams.summaryFilter;
        $sessionStorage.summaryFilter= $stateParams.summaryFilter;
        if ($stateParams.summaryFilter=="concept"){
            $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
        }
        else
        {
            $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
        }
    //       $sessionStorage.creativeData.adSummaryFilter = $scope.creativeData.adSummaryFilter;
        $scope.dtInstance.reloadData;
    }

    // get the records according to the adSummary filter
    $scope.adSummaryFilterDecider = function(summaryFilterOpt){
        var url = $location.absUrl();   // http://127.0.0.1:8080/#/creative-manager/concept
        var urlPath = $location.path();  //: /creative-manager/concept
        if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter") && $stateParams.hasOwnProperty("summaryFilterId")){
           $scope.stateDecide = 4;
           if (summaryFilterOpt =="adsize"){
               $stateParams.summaryFilter=summaryFilterOpt;
               $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
           }
           else{
               $stateParams.summaryFilter=summaryFilterOpt;
               $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
           }
           $scope.creativeData.adSummaryFilter = $sessionStorage.summaryFilter
           $scope.advertiserId = $stateParams.advertiserId;
           $scope.summaryFilter = $stateParams.summaryFilter;
           $scope.summaryFilterId = $stateParams.summaryFilterId;
           getUrl = "/api/creative-manager/byAdvertiser/"+advertiserId+"/"+$scope.summaryFilter+"/"+$scope.summaryFilterId;
           $state.go('creative-manager-adv-summaryFilter-summaryFilterId',{advertiserId: $scope.advertiserId, summaryFilter: $scope.summaryFilter, summaryFilterId:$scope.summaryFilterId});
       }
       else if($stateParams.hasOwnProperty("summaryFilter") && $stateParams.hasOwnProperty("summaryFilterId") && !$stateParams.hasOwnProperty("advertiserId")){
            $scope.stateDecide = 3;
            if (summaryFilterOpt =="adsize"){
                $stateParams.summaryFilter=summaryFilterOpt;
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
            }
            else{
                $stateParams.summaryFilter=summaryFilterOpt;
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
            }
            $scope.creativeData.adSummaryFilter = $sessionStorage.summaryFilter
            $scope.summaryFilter = $stateParams.summaryFilter;
            $scope.summaryFilterId = $stateParams.summaryFilterId;
            getUrl = "/api/creative-manager/"+$scope.summaryFilter+"/"+$scope.summaryFilterId;
            $state.go('creative-manager-summaryFilter-summaryFilterId',{summaryFilter:$scope.summaryFilter,summaryFilterId:$scope.summaryFilterId});
       }
       else if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter") && !$stateParams.hasOwnProperty("summaryFilterId")){
            $scope.stateDecide = 2;
            if (summaryFilterOpt =="adsize"){
                $stateParams.summaryFilter=summaryFilterOpt;
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
            }
            else{
                $stateParams.summaryFilter=summaryFilterOpt;
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
            }
            $scope.creativeData.adSummaryFilter = $sessionStorage.summaryFilter
            $scope.advertiserId = $stateParams.advertiserId;
            $scope.summaryFilter = $stateParams.summaryFilter;
            getUrl = "/api/creative-manager/byAdvertiser/"+$scope.advertiserId+"/"+$scope.summaryFilter;
            $state.go('creative-manager-adv-summaryFilter',{advertiserId:$scope.advertiserId,summaryFilter:$scope.summaryFilter});
       }
       else {
            $scope.stateDecide = 1;
            if (summaryFilterOpt =="adsize"){
                $stateParams.summaryFilter=summaryFilterOpt;
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
            }
            else{
                $stateParams.summaryFilter=summaryFilterOpt;
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
            }
            $scope.summaryFilter = $stateParams.summaryFilter;
            getUrl = "/api/creative-manager/"+$scope.summaryFilter;
            $state.go('creative-manager-summaryFilter',{summaryFilter:$scope.summaryFilter});
        }
    }

    // get all the advetiser list for dropdown
    $scope.getAdvCreatives = function(){
        advertiserId=$scope.selectedAdvertiser;
        $scope.summaryFilter = $stateParams.summaryFilter;
        $state.go('creative-manager-adv-summaryFilter', {advertiserId: $scope.selectedAdvertiser,summaryFilter: $scope.summaryFilter});
    };

    // create the vast tag form
    $scope.addCreativeVast = function(){
        if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter")){
            $state.go('creativeForm-create-advId', {advertiserId: $scope.advertiserId});
        }
         else{
            $state.go('creativeForm-create', {});
         }
    }

    // create the creative adtag form
    $scope.addCreativeAdTag = function(){
        if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter")){
            $state.go('creative-manager-create-adtags-creative-advId', {advertiserId: $scope.advertiserId});
        }
         else{
            $state.go('creative-manager-create-adtags-creative', {});
         }
    }

    // create the creative inbanner adtag form
    $scope.addCreativeInbannerAdTag = function(){
        if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter")){
            $state.go('creative-manager-create-inbanner-video-creative-advId', {advertiserId: $scope.advertiserId});
         }
         else{
            $state.go('creative-manager-create-inbanner-creative', {});
         }
    }

    // create the creative inbanner adtag form
    $scope.addCreativeUpdateAd = function(){
        if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter")){
            $state.go('creative-manager-create-upload-ads-creative-advId', {advertiserId: $scope.advertiserId});
         }
         else{
            $state.go('creative-manager-create-upload-ads-creative', {});
         }
    }

    $scope.openAdPreview = function (creativeId) {
        var dialog = ngDialog.open({ template: 'app/creative-manager/creative-adspreview.html',
            controller: 'CreativeAdsPreviewController',
            className: 'ngdialog-theme-default custom-width-800',
            resolve : {
                creativeId : function(){
                    return creativeId;
                }
            }
        });
    };


    function getDataService(){
        var service = $scope.services[$state.current.name];
        service.method(service.params(service.val)).then(function(response){
            $scope.dashboard = angular.copy(response);
            if($scope.dashboard.hasOwnProperty("currency")){
                $scope.currency = $scope.dashboard.currency;
            }
            if($scope.dashboard.hasOwnProperty("tickerPeriod")){
                $scope.tickerPeriod = $scope.dashboard.tickerPeriod;
            }
            $scope.strimpressions="";
            $scope.str="";
            $scope.data = {};
            var cardkeys = [];
            if($scope.dashboard.tables.length>0){
                angular.forEach($scope.dashboard[type][0],function(value,key){
                    var mapping = $scope.reportParamsKeys[key];
                    if(!$scope.data.hasOwnProperty(key)){
                        $scope.data[key] = [];
                        cardkeys.push(key);
                    }
                });
            }
            if($scope.dashboard.hasOwnProperty("advertiserTimezone") && $scope.dashboard.advertiserTimezone !=null){
                formatTimezone($scope.dashboard.advertiserTimezone);
            }
        });
    }

    // state decider
    function stateDecider(){
       if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter") && $stateParams.hasOwnProperty("summaryFilterId")  ){
           $scope.stateDecide = 4;
           $scope.advertiserId = $stateParams.advertiserId;
           $scope.summaryFilter = $stateParams.summaryFilter;
           $scope.summaryFilterId = $stateParams.summaryFilterId;
           if ($stateParams.summaryFilter =="adsize"){
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
                $scope.breadCrum = [{name: "Creative Manager",sref:"creative-manager-summaryFilter({summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"},{name:"Advertiser",sref:"creative-manager-adv-summaryFilter({advertiserId:" + $stateParams.advertiserId + ",summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"},{name:"Id",sref:"creative-manager-adv-summaryFilter-summaryFilterId({advertiserId:" + $stateParams.advertiserId + ",summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" +",summaryFilterId:"+$stateParams.summaryFilterId +"})"}];
           }
           else{
               $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
               $scope.breadCrum = [{name: "Creative Manager",sref:"creative-manager-summaryFilter({summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"},{name:"Advertiser",sref:"creative-manager-adv-summaryFilter({advertiserId:" + $stateParams.advertiserId + ",summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"},{name:"Id",sref:"creative-manager-adv-summaryFilter-summaryFilterId({advertiserId:" + $stateParams.advertiserId + ",summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" +",summaryFilterId:"+$stateParams.summaryFilterId +"})"}];
           }
           getData = function(d){
               d.dateStart = $scope.modalDates.startDate;
               d.dateEnd = $scope.modalDates.endDate;
               d.tickerPeriod = $scope.tickerPeriod;
           };
           getUrl = "/api/creative-manager/byAdvertiser/"+$scope.advertiserId+"/"+$scope.summaryFilter+"/"+$scope.summaryFilterId;
           $state.go('creative-manager-adv-summaryFilter-summaryFilterId',{advertiserId:$scope.advertiserId, summaryFilter:$scope.summaryFilter, summaryFilterId:$scope.summaryFilterId});
       }
       else if($stateParams.hasOwnProperty("summaryFilter") && $stateParams.hasOwnProperty("summaryFilterId") && !$stateParams.hasOwnProperty("advertiserId")){
            $scope.stateDecide = 3;
               if ($stateParams.summaryFilter =="adsize"){
                   $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
               }
               else{
                   $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
               }
            $scope.summaryFilter = $stateParams.summaryFilter;
            $scope.summaryFilterId = $stateParams.summaryFilterId;
            getData = function(d){
                d.dateStart = $scope.modalDates.startDate;
                d.dateEnd = $scope.modalDates.endDate;
                d.tickerPeriod = $scope.tickerPeriod;
            };
            getUrl = "/api/creative-manager/"+$scope.summaryFilter+"/"+$scope.summaryFilterId;
            $state.go('creative-manager-summaryFilter-summaryFilterId',{summaryFilter:$scope.summaryFilter,summaryFilterId:$scope.summaryFilterId});
       }
       else if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter") && !$stateParams.hasOwnProperty("summaryFilterId")){
            $scope.stateDecide = 2;
            $scope.advertiserId = $stateParams.advertiserId;
            $scope.summaryFilter = $stateParams.summaryFilter;
            if ($stateParams.summaryFilter =="adsize"){
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
                $scope.breadCrum = [{name: "Creative Manager",sref:"creative-manager-summaryFilter({summaryFilter:"+ "\'" + $scope.summaryFilter+ "\'" + "})"},{name:"Advertiser",sref:"creative-manager-adv-summaryFilter({advertiserId:" + $stateParams.advertiserId + ",summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"}];
           }
           else{
                $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
                $scope.breadCrum = [{name: "Creative Manager",sref:"creative-manager-summaryFilter({summaryFilter:"+ "\'" + $scope.summaryFilter+ "\'" + "})"},{name:"Advertiser",sref:"creative-manager-adv-summaryFilter({advertiserId:" + $stateParams.advertiserId + ",summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"}];
           }
           getData = function(d){
               d.dateStart = $scope.modalDates.startDate;
               d.dateEnd = $scope.modalDates.endDate;
               d.tickerPeriod = $scope.tickerPeriod;
           };
           getUrl = "/api/creative-manager/byAdvertiser/"+$scope.advertiserId+"/"+$scope.summaryFilter;
           $state.go('creative-manager-adv-summaryFilter',{advertiserId:$scope.advertiserId,summaryFilter:$scope.summaryFilter});
       }
       else{
           $scope.stateDecide = 1;
           $scope.summaryFilter = $stateParams.summaryFilter;
           if ($stateParams.summaryFilter =="adsize"){
               $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
               $scope.breadCrum = [{name:"Creative Manager",sref:"creative-manager-summaryFilter({summaryFilter:"+ "\'" + $stateParams.summaryFilter+ "\'" + "})"}];
           }
           else{
               $scope.creativeData.adSummaryFilter = $stateParams.summaryFilter;
               $scope.breadCrum = [{name:"Creative Manager",sref:"creative-manager-summaryFilter({summaryFilter:" + "\'"+$stateParams.summaryFilter+"\'" + "})"}];
           }
           getData = function(d){
               d.dateStart = $scope.modalDates.startDate;
               d.dateEnd = $scope.modalDates.endDate;
               d.tickerPeriod = $scope.tickerPeriod;
           };
           getUrl = "/api/creative-manager/"+$scope.summaryFilter;
           $state.go('creative-manager-summaryFilter',{summaryFilter:$scope.summaryFilter});
       }
    }
    stateDecider();
//    getDataService();
    setTickerPeriod();
    init();

    // date ranges
    $scope.ranges  = {
        'Today': [moment().format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
        'Yesterday': [moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')],
        'Last 7 Days': [moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
        'Last 30 Days': [moment().subtract(29, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
        'This Month': [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')],
        'Last Month': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')]
    };

    // set ticker period
    function setTickerPeriod(){
            if($sessionStorage.firstTimeCreatives &&
                            ($state.current.name === "creative-manager-adv-summaryFilter" ||
                            $state.current.name === "creative-manager-summaryFilter" ||
                            $state.current.name === "creative-manager-summaryFilter-summaryFilterId" ||
                            $state.current.name === "creative-manager-adv-summaryFilter-summaryFilterId")){
            $scope.tickerPeriod = $rootScope.preferencesData.hasOwnProperty("tickerPeriod")?$rootScope.preferencesData.tickerPeriod:"1";
            $sessionStorage.tickerPeriod = $scope.tickerPeriod;
        }
        else{
            if($sessionStorage.firstTimeCreatives){
                $scope.tickerPeriod = "1";
            }
            else{
                $scope.tickerPeriod = $sessionStorage.tickerPeriod;
            }
        }
    }

    //callback on table complete
    var $INITCOMPLETE = function(settings, respJson) {
        console.log("init complete: Settings: " + settings + "Json: " + respJson);
        if($state.current.name === "creative-manager-summaryFilter" ||
        $state.current.name === "creative-manager-adv-summaryFilter" ||
        $state.current.name === "creative-manager-summaryFilter-summaryFilterId" ||
        $state.current.name === "creative-manager-adv-summaryFilter-summaryFilterId"){
            var prevStartDate = respJson.tickerInfo.prevStartDate;
            var prevEndDate = respJson.tickerInfo.prevEndDate;
            var captionData = "Ticker comparison date range: ";
            var fprevStartDate = getCaptionDate(prevStartDate);
            var fprevEndDate = getCaptionDate(prevEndDate);
            captionData = captionData + fprevStartDate + " to " + fprevEndDate + ".";
            $('<caption/>').html(captionData).appendTo('#tables');
            //$compile(angular.element('#caption').contents())($scope);
        }
    };

    // initialize the date preferences
    function initializeDatePreferences() {
        if($sessionStorage.summaryFilter && $state.current.name === "creative-manager-summaryFilter") {
            if ($rootScope.preferencesData.hasOwnProperty("dateLabel")) {
                if ($rootScope.preferencesData.dateLabel === "custom range") {
                    if ($rootScope.preferencesData.hasOwnProperty("dateRange")) {
                        $scope.modalDates = $rootScope.preferencesData.dateRange;
                        $sessionStorage.dateRange = $rootScope.preferencesData.dateRange;
                    }
                    else {
                        $scope.modalDates = {
                            startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment().format('YYYY-MM-DD HH:mm:ss')
                        };
                    }
                }
                else {
                    $scope.modalDates = {
                        startDate: $scope.ranges[$rootScope.preferencesData.dateLabel][0],
                        endDate: $scope.ranges[$rootScope.preferencesData.dateLabel][1]
                    };
                }
                $sessionStorage.dateLabel = $rootScope.preferencesData.dateLabel;
            }
            else {
                $scope.modalDates = {
                    startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment().format('YYYY-MM-DD HH:mm:ss')
                };
            }
        }
        else{
            if ($sessionStorage.hasOwnProperty("dateLabel")) {
                if ($sessionStorage.dateLabel === "custom range") {
                    if ($sessionStorage.hasOwnProperty("dateRange")) {
                        $scope.modalDates = $sessionStorage.dateRange;
                    }
                    else {
                        $scope.modalDates = {
                            startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment().format('YYYY-MM-DD HH:mm:ss')
                        };
                    }
                }
                else {
                    $scope.modalDates = {
                        startDate: $scope.ranges[$sessionStorage.dateLabel][0],
                        endDate: $scope.ranges[$sessionStorage.dateLabel][1]
                    };
                }
            }
            else {
                $scope.modalDates = {
                    startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment().format('YYYY-MM-DD HH:mm:ss')
                };
            }
        }
        $scope.dateShow = true;
    }
//  initializeDatePreferences();

    function createdRow(row, data, dataIndex) {
        // Recompiling so we can bind Angular directive to the DT
        $compile(angular.element(row).contents())($scope);
    }

    var titleHtml = '<input type="checkbox" ng-model="selectAll" ng-click="toggleAll(selectAll, selected)">';

    // Initializing the datatable
    function initializeDataTable(){
        $scope.dtInstance = {};
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('paging',true)
            .withOption('order',[])
            .withDisplayLength(50)
            .withOption('createdRow', createdRow)
            .withOption('ajax', {
                url: getUrl,
                data: getData,
                type:"POST",
                headers: {"X-CSRF-TOKEN":SocialService.getCSRF()},
            })
            .withOption('createdRow', createdRow)
//            .withOption('createdRow', function(row, data, dataIndex) {
//            // Recompiling so we can bind Angular directive to the DT
//                $compile(angular.element(row).contents())($scope);
//            })
            .withOption('headerCallback', function(header) {
                if (!$scope.headerCompiled) {
                    // Use this headerCompiled field to only compile header once
                    $scope.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withFixedHeader({
                bottom: true,
                headerOffset:49
            }).withDOM('frtipl')
            .withDataProp('data')
            .withOption('processing', true)
//            .withOption('footerCallback',footerCallback)
            .withOption('serverSide', true)
            .withPaginationType('full_numbers');

        $scope.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function(data, type, full, meta) {
                $scope.selected[full.creativeId] = false;
                return '<input type="checkbox" ng-model="selected[' + data.creativeId + ']" ng-click="toggleOne(selected)">';
            }),
            DTColumnBuilder.newColumn('creative').withTitle('Creative').withOption('defaultContent', '-default value-').renderWith(function(data,type,row) {
            var creativeAdtags = [];
            var creativeVastTags = [];
            var isCreativeAdtag = false;
            var isCreativeVastTag = false;
            creativeAdtags = row.creativeAdTag;
            creativeVastTags = row.creativeVastTag;
            var creativeId;

            if(creativeAdtags){
                isCreativeAdtag = true;
            }

            if(!isCreativeAdtag && creativeVastTags){
//                if(typeof creativeVastTags === "object"){
                isCreativeVastTag = true;
            }
            else{
                creativeId = row.creativeId;
            }

            if($scope.isCreativeAdtagPreview){
                var tdiv = '<div class="relative-pos adreports">' +
                       '<div>' + '<creative-preview creativeid="' +
                       creativeId + '" scale="true"></creative-preview>' +
                '</div>' + '<div ng-click="openAdPreview(' +
                creativeId + ')" class="overlay-ads"></div>' + '</div>'
            }
            else{
                var tdiv = "no preview available"
            }
//            var tdiv = "no preview available"
            return tdiv;

            }),
            DTColumnBuilder.newColumn('creativeIdNameStatus').withTitle('Id, Name & Status').renderWith(function(data,type,row) {
                var summaryFilterOption = $sessionStorage.summaryFilter;
                var mapping = "none";
                var tspanClass = "";
                if(summaryFilterOption == "concept") {
                    mapping = "mappingId";
                }
                else {
                    mapping = "size";
                }
                var creativeTagName= row.creativeIdNameStatus.split("\n");
                var creativeType = row.creativeSizeType.split("\n");
                var status = creativeTagName[2];
                var id = creativeTagName[0];
                if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter") && !$stateParams.hasOwnProperty("summaryFilterId")) {
                   advertiserId = $stateParams.advertiserId;
                    var tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+id+'" call-back="reloadDataTables()" type="creative" mapping="true" mapping-type="'+mapping+'" state="'+status+'"></toggle-state>';
                       var resVal = '<div>'+tspan+'<br/>'+'<a ui-sref="creative-manager-adv-summaryFilter-summaryFilterId({ advertiserId:'+row.advertiserId+", summaryFilter:'"+summaryFilterOption+"', summaryFilterId:"+ row.creativeMappingId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                }
                else if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("summaryFilter") && $stateParams.hasOwnProperty("summaryFilterId")){
                    mapping = "none";
                    var tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+id+'" call-back="reloadDataTables()" type="creative" mapping="false" mapping-type="'+mapping+'" state="'+status+'"></toggle-state>';
                   if(creativeType[1]=="inbanner_video_adtag"){
                      var resVal = '<div>'+tspan+'<br/>'+'<a ui-sref="creative-manager-edit-inbanner-creative-advId({ advertiserId:'+row.advertiserId+", creativeMappingId:"+ row.creativeMappingId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                   }
                   else if(creativeType[1]=="ad_tag"){
                      var resVal = '<div>'+tspan+'<br/>'+'<a ui-sref="creative-manager-edit-adtags-creative-advId({ advertiserId:'+row.advertiserId+", creativeMappingId:"+ row.creativeMappingId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                   }
                   else if(creativeType[1]=="creative_vast_tag"){
                       var resVal = '<div>'+tspan+'<br/>'+'<a ui-sref="creativeForm-edit({ advertiserId:'+row.advertiserId+", creativeId:"+ row.creativeId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                    }
                    else if(creativeType[1]=="image/flash"){
                        var resVal = '<div>'+tspan+'<br/>'+'<a ui-sref="creative-manager-edit-upload-ads-creative-advId({ advertiserId:'+row.advertiserId+", creativeMappingId:"+ row.creativeMappingId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                    }
                }
                else{
                    var tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+id+'" call-back="reloadDataTables()" type="creative" mapping="true" mapping-type="'+mapping+'" state="'+status+'"></toggle-state>';
                    var resVal = '<div>'+tspan+'<br/>'+'<a ui-sref="creative-manager-adv-summaryFilter-summaryFilterId({ advertiserId:'+row.advertiserId+", summaryFilter:'"+summaryFilterOption+"', summaryFilterId:"+ row.creativeMappingId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                    //var resVal = '<div>'+creativeTagName[0]+'<br/>'+'<a ui-sref="creative-manager-summaryFilter-summaryFilterId({ summaryFilter:'+"\'"+summaryFilterOption+"\'"+', summaryFilterId:'+ row.creativeMappingId+'})">'+creativeTagName[1]+'</a>'+'<br/>'+creativeTagName[2]+'<br/></div>';
                }
                return resVal;
            }),
            DTColumnBuilder.newColumn('creativeSizeType').withTitle('Sizes & Type').renderWith(function(data,type,row) {
                var adSizes = [];
                var creativeSize;
                adSizes = row.adsizes;
                adSizes.forEach(function(element) {
                    if(element.value==row.adUnitId){
                        creativeSize= element.label;
                    }
                });
                var creativeSizeType= data.split("\n");
                var resVal = '<div>'+creativeSize+'<br/>'+creativeSizeType[1]+'<br/></div>';
                return resVal;
            }),
            DTColumnBuilder.newColumn('creativeStartDate').withTitle('Start Date').renderWith(function(data,type,row) {
                var startDate = row.creativeStartDate;
                startDate = startDate.split('T')[0];
                return startDate;
            }),
//            DTColumnBuilder.newColumn('creativeStartDate').withTitle('Start Date').withOption('defaultContent', '0000-00-00'),
//            DTColumnBuilder.newColumn('creativeEndDate').withTitle('End Date').withOption('defaultContent', '0000-00-00'),
            DTColumnBuilder.newColumn('creativeEndDate').withTitle('End Date').renderWith(function(data,type,row) {
                var endDate = row.creativeEndDate;
                endDate = endDate.split('T')[0];
                if(endDate=="9999-09-09"){
                    endDate="";
                }
                return endDate;
            }),
            DTColumnBuilder.newColumn('campaignsCount').withTitle('Campaigns').withOption('defaultContent', '0'),
            DTColumnBuilder.newColumn('adgroupsCount').withTitle('Adgroups').withOption('defaultContent', '0'),
            DTColumnBuilder.newColumn('labels').withTitle('Labels')
//            DTColumnBuilder.newColumn('date').withTitle('Ad Date').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('sumRequests').withTitle('Requests').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('sumOpportunities').withTitle('Opportunities').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('sumImpressions').withTitle('Impressions').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('sumSpends').withTitle('Spend').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('sumClicks').withTitle('Clicks').withOption('defaultContent', '0')
//            DTColumnBuilder.newColumn('requests').withTitle('Requests').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('opportunities').withTitle('Opportunities').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('impressions').withTitle('Impressions').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('spend').withTitle('Spend').withOption('defaultContent', '0'),
//            DTColumnBuilder.newColumn('clicks').withTitle('Clicks').withOption('defaultContent', '0')
        ];
    }

    $scope.reloadDataTables = function(){
        $scope.dtInstance.reloadData();
    };

    $scope.previewChange = function(){
        $scope.isCreativeAdtagPreview = !$scope.isCreativeAdtagPreview;
        $scope.reloadDataTables();
    }

    // datatable footer handling
    var $footerHandler = function(tfoot, data){
        console.log("inside footerhandler");
//        var spanVal = $scope.stateDecide === 1 || ($scope.stateDecide == 3)?2:3;
//        var startIndex = $scope.stateDecide === 1 || ($scope.stateDecide == 3)?6:3;
        var startIndex = 6;
        var table = $scope.dtInstance.dataTable;
        var api = $scope.dtInstance.DataTable;
        //TOTAL LABEL
        var col = api.column(startIndex-1);
        var foot = api.column(5).footer();
        foot.innerHTML = "Total";
        /*MAINTAIN COLUMN ORDER*/
        api.column(6).footer().innerHTML = $filter('number')(data[0].sumCampaigns);             //sumCampaigns
        api.column(7).footer().innerHTML = $filter('number')(data[0].sumAdgroups);              //sumAdgroups
        api.column(8).footer().innerHTML = $filter('number')(data[0].sumRequests);              //sumRequests
        api.column(9).footer().innerHTML = $filter('number')(data[0].sumOpportunities);         //sumOpportunities
        api.column(10).footer().innerHTML = $filter('number')(data[0].sumImpressions)           //impressions
        api.column(11).footer().innerHTML = $filter('number')(data[0].sumSpends);//spend
        api.column(12).footer().innerHTML = $filter('number')(data[0].sumClicks);//clicks
    }

    // footer callback
    var $FOOTERCALLBACK = function(tfoot, data, start, end, display) {
        //console.log("FOOTERCALLBACK: " + tfoot);
        var $delay = 50;
        if($scope.stateDecide === 3){
            $delay = 200;
        }
        $timeout($footerHandler(tfoot, data), $delay);
    }

    var $API_URL, $API_DATA;
    var footerCallback= function (tfoot, data) {
        if (data.length > 0) {
            // Need to call $apply in order to call the next digest
            $scope.$apply(function () {
                $scope.footerData={}
                for (var i = 0, len = data.length; i < len; i++) {
                    if (i>0){
                        $scope.footerData.sumImpressions = data[i].sumImpressions;
                        $scope.footerData.sumClicks = data[i].sumClicks;
                        $scope.footerData.sumSpends = data[i].sumSpends;
                        $scope.footerData.sumOpportunities = data[i].sumOpportunities;
                        $scope.footerData.sumCampaigns = data[i].sumCampaigns;
                        $scope.footerData.sumAdgroups = data[i].sumAdgroups;
                        $scope.footerData.sumRequests = data[i].sumRequests;
                    }
                }
            });
        }
    }
    initializeDataTable();

    function toggleAll (selectAll, selectedItems) {
        for (var id in selectedItems) {
            if (selectedItems.hasOwnProperty(id)) {
                selectedItems[id] = selectAll;
            }
        }
    }

    function toggleOne (selectedItems) {
        for (var id in selectedItems) {
            if (selectedItems.hasOwnProperty(id)) {
                if(!selectedItems[id]) {
                    $scope.selectAll = false;
                    return;
                }
            }
        }
        $scope.selectAll = true;
    }

    $scope.selectedRowsCount = function() {
        var count = 0;
        angular.forEach($scope.selected, function(selectedCheckbox){
            count += (selectedCheckbox==true) ? 1 : 0;
        });
        return (count);
    }

    var instance = dataTableInstance.getDataTable();
    if(!angular.equals({},instance) && instance){
        instance.DataTable.destroy();
    }

    $scope.initRefresh = function () {
        $timeout(function(){$scope.$emit('panel-refresh', $scope.panelids);}, 50);
    };

    $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
        if(newValue && newValue.hasOwnProperty("id")){
            dataTableInstance.setDataTable($scope.dtInstance);
        }
    });
})

// create creative adtag controller
    .controller('CreativeManagerCreateAdtagCreativeController', function ($scope,$stateParams,$state,$window,$http,$compile,CreativeManagerService,$filter,$rootScope,GetAdvertiserIDName,$timeout,$sanitize, $sce,ngDialog,CreativeManagerAdtagService,advertiser) {
        $scope.categories = [];
        $scope.categoriesAdtag = [];
        $scope.sensitiveAttributes = [];
        $scope.technicalAttributes = [];
        $scope.subCategories = [];
        $scope.countryLanguages = [];
        $scope.advertiser = advertiser;
        $scope.countryLanguagesAdtag = [];
        $scope.categoryNames = [];
        $scope.destinationUrl="";
        $scope.advertiserDomain="";
        $scope.adSizeCount=1;
        $scope.creativeFormAdtagData=[];
        $scope.creativeNameAdtag = [];
        $scope.callbacks=false;
        $scope.allAdvertisersIdName = [];
        $scope.selectedCountryLanguagesAdtag = [];
        $scope.sensitiveAttributeNames = [];
        $scope.technicalAttributeNames = [];
        $scope.selectedTechnicalAttribute=[];
        $scope.selectedSentitiveAttribute=[];
        $scope.selectedTechnicalAttributeAdtag = []
        $scope.selectedSensitiveAttributeAdtag = [];
        $scope.destinationUrlAdtag = [];
        $scope.advertiserDomainAdtag = [];
        $scope.impressionTrackingPixelAdtag = [];
        $scope.ibvAdStartCallbackAdtag = [];
        $scope.ibvAdCompleteCallbackAdtag = [];
        $scope.ibvAdClicktrackCallbackAdtag = [];
        $scope.selectedAdSizesList=[];
        $scope.subCategoryNames = [];
        $scope.selectedSubCategories = [];
        $scope.selectedCategoryAdtag = [];
        $scope.selectedSubCategoriesAdtag = [];
        $scope.text1 = [];
        $scope.subCategoriesdataAdtag = [];
        $scope.selectedAdSize=[];
        $scope.selectedAdtagAdSizes=[];
        $scope.subCategoriesAdtag = [];
        $scope.previewText=[];
        $scope.selectedCreativeAdSize=[];
        $scope.selectedCreativeSize=[];
        $scope.expandAdtagSize = [];
        var formAdtagData = {};
        $scope.isAdvertiserId = false;
        var creativeId;
        var advertiserId;
        $scope.selectedCreativeAdSizeExist = [];
        $scope.selectedSize= false;
        $scope.showSubCategories = false
        $scope.subCategoryAdtagTouched = [];
        $scope.adSizeSelectionDisable = true;
        $scope.categoryAdtagTouched = [];
        $scope.countryLanguagesAdtagTouched = [];
        $scope.destinationUrlAdtagTouched = false;
        $scope.advertiserDomainAdtagTouched = false;
        $scope.technicalAttributeAdtagTouched = false;
        $scope.sensitiveAttributeAdtagTouched = false;
        $scope.technicalAttributeTouched = false;
        $scope.sensitiveAttributeTouched = false;
        $scope.impressionTrackingPixel = false;
        $scope.submitButtonDisable = false;
        $scope.invalidTag = [];
        $scope.thirdPartyMacrosEnable = [];
        $scope.clickUrl = [];
        $scope.cacheBusterBtn = [];
        $scope.disableStartDate = {};
        var formAdtagsData = [];
        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.startDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            minDate:moment()
        };
        $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.adSizes=[];
        $scope.creativeId=[];
        $scope.adSizesCounter=0;
        $scope.startDateOpened = false;
        $scope.endDateOpened = false;
        $scope.sliceCount = 0
        $scope.currentIndex = 0
        $scope.startDateTouched = false;
        $scope.endDateTouched = false;
        $scope.startDateAdtagOpened = [];
        $scope.endDateAdtagOpened = [];
        var creativeMappingId;
        $scope.platformSelection = false;
        $scope.formAdtagData = {};
        $scope.searchWords = [];
        $scope.seletedtechnicalAttribute = {
            technicalAttributes:[]
        }
        $scope.seletedsensitiveAttribute = {
            sensitiveAttributes:[]
        }
        $scope.seletedtechnicalAttribute = {
            technicalAttributesAdtag:[]
        }
        $scope.seletedsensitiveAttribute = {
            sensitiveAttributesAdtag:[]
        }
        $scope.initDate = new Date();

        $scope.currentUserTime = "";
        var offset = 0;
        if($scope.advertiser != undefined) {
            $scope.currentUserTime = $scope.advertiser.currentTime;
            offset = new Date().getTimezoneOffset();
        }

        $scope.adtag = {};
//        $scope.adtag = {
//            startDate : moment().format('YYYY-MM-DD HH:mm:ss'),
//            startDateAdtag : moment().format('YYYY-MM-DD HH:mm:ss'),
//            endDate : moment().format('YYYY-MM-DD HH:mm:ss'),
//            endDateAdtag : moment().format('YYYY-MM-DD HH:mm:ss')
//        }
        $scope.startDateAdtagSize = []
        $scope.endDateAdtagSize = []
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.copyText = function(text1,index){
//            $scope.text1[index]= text1;
            $scope.previewText[index] =  $scope.text1[index];

            var validPattern = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            var invalidPattern = /http:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            var text1 = text1.trim();
            var firstChar = text1.charAt(0);
            var lastChar = text1.charAt(text1.length-1);
            if(firstChar!='"' && lastChar!='"'){
                if(validPattern.test(text1)){
                    $scope.invalidTag[index] = false;
                    $scope.submitButtonDisable = false;
                }
                else if(invalidPattern.test(text1)){
                   $scope.invalidTag[index] = true;
                   alert($scope.creativeNameAdtag[index]+" tag is invalid");
                   $scope.submitButtonDisable = true;
                }
            }

            String.prototype.replaceAll = function(searchStr, replaceStr) {
                var str = this;

                // no match exists in string?
                if(str.indexOf(searchStr) === -1) {
                    // return string
                    return str;
                }

                // replace and remove first match, and do another recursirve search/replace
                return (str.replace(searchStr, replaceStr)).replaceAll(searchStr, replaceStr);
            }

            angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                if(val.thirdPartyPlaceholder){
                    if(text1.search(/val.thirdPartyPlaceholder/i)){
                       $scope.searchWords.push(val.thirdPartyPlaceholder)
                       text1 = text1.replaceAll(val.thirdPartyPlaceholder, val.macro);
                    }
                }
            })
            $scope.text1[index] = angular.copy(text1);
        }

        $scope.platform = {
           desktopWeb: false,
           mobileWeb: false,
           app: false
        };

        $scope.macrotype = {
            adserverMacros: false,
            rtbMacros: false
        };

        $scope.$watchCollection('platform', function () {
          $scope.selectedPlatform = [];
          $scope.selectedMacrosTypeList = [];
          $scope.platformCount = 0;
          angular.forEach($scope.platform, function (value, key) {
            if (value) {
              $scope.selectedPlatform.push(key);
              $scope.platformSelection = true;
              $scope.platformCount+=1;
            }
          });
            if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
                angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                  angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                        if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                          }
                        }
                        else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                    else if($scope.selectedPlatform.length>1){
                        if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                  });
                });
             } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
               angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                 angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                       if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                         if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                         }
                       }
                       else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                    else if($scope.selectedPlatform.length>1){
                       if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                 });
               });
             }
             else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
                  angular.forEach($scope.selectedPlatform, function (data) {
     //                console.log(data)
                    angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                       if($scope.selectedPlatform.length==1){
                          if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                            if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                            }
                          }
                          else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                       else if($scope.selectedPlatform.length>1){
                          if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                    });
                 });
             }
        });



        $scope.isStartDateOpen = false;
        $scope.isEndDateOpen = false;
        $scope.isStartDateAdtagOpen = [];
        $scope.isEndDateAdtagOpen = [];

        $scope.openCalendar = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index == -1){
                    $scope.isStartDateOpen = true;
                }
            }
            else{
                if(index == -1){
                    $scope.isEndDateOpen = true;
                }
            }
        };

        $scope.openCalendarAdtagSize = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index > -1){
                    $scope.isStartDateAdtagOpen[index] = true;
                }
            }
            else{
                if(index > -1){
                    $scope.isEndDateAdtagOpen[index] = true;
                }
            }
        };



        $scope.getMacros = function(){
            $scope.selectedMacrosTypeList=[];
            $scope.macros = true;
          if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
              angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
            angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
              if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
            });
          });
       } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
         angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
           angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
             if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
               if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
               }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
           });
         });
       }
       else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
            angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
              angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                  if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                  }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
              });
            });
          }
          else{
            $scope.macros = false;
          }
        }


        // get all the advertisers
        var advertiserInfoPromise =  GetAdvertiserIDName.query();
        advertiserInfoPromise.$promise.then(function(response){
             $scope.allAdvertisersIdName = response;
             if($scope.stateDecide){
                $scope.selectedAdvertiser = parseInt($stateParams.advertiserId);
             }
        });

        // set creativename()
        $scope.setCreativeName = function(creativeName){
            $scope.adSizeSelectionDisable = false;
//            $scope.creativeNameAdtagLength = $scope.creativeNameAdtag.length;
            if($scope.creativeNameAdtag.length>0){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    // filter last8char
                    var list8char = $scope.creativeNameAdtag[i].substr($scope.creativeNameAdtag[i].length - 8);
                    // remove last8char
                    $scope.creativeNameAdtag[i] = creativeName+list8char

                }
            }
        }

        // set creative name to respective creativename of adtag size
        $scope.setCreativeNameAdtag = function(creativeNameAdtag,index){
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                if($scope.selectedAdSize[i] == $scope.selectedAdSize[index]){
                    $scope.creativeNameAdtag[i] = angular.copy(creativeNameAdtag[index]);
                }
            }
        }

        // setDestinationUrl copies the destination url to all the selected adsizes
        $scope.setDestinationUrl = function(destinationUrl){
            if(!$scope.destinationUrlAdtagTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.destinationUrlAdtag[i]= angular.copy($scope.destinationUrl);
                }
            }
        }

        // set the destination url of adtag size
        $scope.setDestinationUrlAdtag = function(destinationUrlAdtag,index){
            $scope.destinationUrlAdtagTouched = true;
            $scope.destinationUrlAdtag[index] = angular.copy(destinationUrlAdtag[index]);
        }

        // setAdvertiserDomain copies the destination url to all the selected adsizes
        $scope.setAdvertiserDomain = function(advertiserDomain){
            if(!$scope.advertiserDomainAdtagTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.advertiserDomainAdtag[i]= angular.copy($scope.advertiserDomain);
                }
            }
        }

        // set the setAdvertiserDomain of adtag size
        $scope.setAdvertiserDomainAdtag = function(advertiserDomainAdtag,index){
            $scope.advertiserDomainAdtagTouched = true;
            $scope.advertiserDomainAdtag[index] = angular.copy(advertiserDomainAdtag[index]);
        }

        // getBinder binds the text from textarea as html of preview
        $scope.getBinder = function(){
            $scope.previewText = $sce.trustAsHtml($scope.text1);
        }

        function initAdtag() {
            $scope.adtag.startDate = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.adtag.endDate = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.startDateAdtagSize = moment($scope.currentUserTime).add(10+offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.endDateAdtagSize = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
        }

//        init();

        // initialize the services
        function initServices() {
            CreativeManagerAdtagService.getAdtagData().then(function(categoriesdata){
                $scope.categories = angular.copy(categoriesdata.contentCategories);
                $scope.categoriesAdtag = angular.copy(categoriesdata.contentCategories);
                if(!$scope.subCategories.length){
                    $scope.subCategories = angular.copy(categoriesdata.contentSubcategories);
                }
//                $scope.subCategoriesAdtag = angular.copy(categoriesdata.contentSubcategories);
                $scope.technicalAttributes = angular.copy(categoriesdata.creativeTechnicalAttributes);
                $scope.sensitiveAttributes = angular.copy(categoriesdata.creativeSensitiveAttributes);
                $scope.countryLanguages = angular.copy(categoriesdata.countryLanguages);
                $scope.thirdPartyNamesList = angular.copy(categoriesdata.thirdPartyNames);
                $scope.thirdPartyMacrosList = angular.copy(categoriesdata.thirdPartyMacros);
                $scope.countryLanguagesAdtag = angular.copy(categoriesdata.countryLanguages);
                var adSizes = angular.copy(categoriesdata.adSizes);
//                $scope.adSizes[$scope.adSizeCount-1] = angular.copy(adSizes.slice(1));

                if(!$scope.adSizes.length && $scope.sliceCount==0){
                    $scope.adSizes = angular.copy(categoriesdata.adSizes.slice(1));
                    $scope.sliceCount +=1;
                }
//                $scope.totalAdSizes = angular.copy(adSizes.slice(1));
                $scope.subCategoriesdata = angular.copy($scope.subCategories);
            });
        }

        // init
        function init(){
           if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("creativeMappingId") && $stateParams.creativeMappingId != undefined){
               $scope.isAdvertiserId = false;
               $scope.isEditCreative = true;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               creativeMappingId = $stateParams.creativeMappingId;
               $scope.creativeMappingId = creativeMappingId;
               $scope.endDateTouched = true;
           }
           else if($stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeMappingId") && $stateParams.advertiserId != undefined){
               $scope.isAdvertiserId = false;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               $scope.isEditCreative = false;
           }
           else if(!$stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeMappingId") && $stateParams.advertiserId == undefined){
               $scope.isAdvertiserId = true;
               $scope.isEditCreative = false;
           }
           else{
              $scope.isAdvertiserId = false;
              $scope.isEditCreative = false;
           }
           // creative in adtag edit mode
           if($scope.isEditCreative){
               $scope.isAdvertiserId = false;
               $scope.disableAdsize={};
               $scope.callbacks=true;
               CreativeManagerAdtagService.getCreativeAdtag(advertiserId,creativeMappingId).then(function(creativeAdtagData){

                   for(var index=0;index<Object.keys(creativeAdtagData).length;index++){
                       $scope.creativeAllDetails = angular.copy(creativeAdtagData[index]);
                       $scope.showSubCategories = true
                       $scope.creativeFormAdtagData = $scope.creativeAllDetails;
                       //  $scope.adSizeCount = $scope.creativeFormAdtagData.adSizeCount;
                       $scope.tabListCounter = Object.keys(creativeAdtagData).length;
//                       $scope.subCategoriesAdtag[index] = $scope.creativeFormAdtagData.contentSubcategories;
                       $scope.disableAdsize[index]=true;
                       $scope.creativeId[index] = $scope.creativeFormAdtagData.creativeId;


                       if(index==0){
                           $scope.impressionTrackingPixel = $scope.creativeFormAdtagData.impressionTrackingPixel;
                           $scope.thirdPartyMacrosEnable[index] = !$scope.impressionTrackingPixel;
                           if(!$scope.thirdPartyMacrosEnable[index]){
                              $scope.clickUrl[index] = true;
                              $scope.cacheBusterBtn[index] = true;
                           }
                           $scope.countryLanguages = $scope.creativeFormAdtagData.countryLanguages;
                           $scope.countryLanguagesAdtag = $scope.creativeFormAdtagData.countryLanguages;
                           $scope.categories = $scope.creativeFormAdtagData.contentCategories;
                           $scope.categoriesAdtag = $scope.creativeFormAdtagData.contentCategories;
                           $scope.adtagSizes = angular.copy($scope.creativeFormAdtagData.adSizes.slice(1));
//                          $scope.subCategories = $scope.creativeFormAdtagData.contentSubcategories;
                            $scope.subCategoriesAdtagData = $scope.creativeFormAdtagData.contentSubcategories;
                            $scope.ibvAdStartCallback = $scope.creativeFormAdtagData.ibvAdStartCallback;
                            $scope.ibvAdCompleteCallback = $scope.creativeFormAdtagData.ibvAdCompleteCallback;
                            $scope.ibvAdClicktrackCallback = $scope.creativeFormAdtagData.ibvAdClickTrackCallback;
                            if(!$scope.adSizes.length){
                                $scope.adSizes = angular.copy($scope.creativeFormAdtagData.adSizes.slice(1));
                            }
                       }

                       var creativeName=$scope.creativeFormAdtagData.creativeNameAdtag.split('-');
                       var creativeAdTagName=$scope.creativeFormAdtagData.creativeNameAdtag.slice(0,-7);
                       var creativeTagName = $scope.creativeFormAdtagData.creativeNameAdtag.replace(creativeName.slice(-1),"");
                       creativeTagName = creativeTagName.replace(/-$/, '');
                       $scope.creativeName= creativeTagName;
                       $scope.creativeAdSize= creativeName[1].trim();
                       if($scope.creativeFormAdtagData.platform){
                           var platformArray = $scope.creativeFormAdtagData.platform.split(',');
                           $scope.selectedPlatform.push(platformArray);
                           angular.forEach($scope.platform, function (value, key) {
                               angular.forEach(platformArray, function (data) {
                                  if (key == data) {
                                     $scope.platform[key] = true;
                                  }
                               })
                           });
                       }
                       $scope.expandAdtagSize[index] = true;
                       $scope.creativeNameAdtag[index] = $scope.creativeFormAdtagData.creativeNameAdtag;

                      $scope.startDateAdtagSize[index] = moment($scope.creativeFormAdtagData.creativesStartDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                      // alert($scope.startDateAdtagSize[index]);
                      if($scope.creativeFormAdtagData.creativesEndDate.indexOf("9999") == -1) {
                          // $scope.campaign.endDate = dateFiltering(new Date($scope.campaign.endDate));
                          $scope.endDateAdtagSize[index] = moment($scope.creativeFormAdtagData.creativesEndDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                      }
                      else {
                          $scope.endDateAdtagSize[index] = null;
                      }
//
//                       $scope.startDateAdtagSize[index] = new Date($scope.creativeFormAdtagData.creativesStartDate);
//                       if($scope.creativeFormAdtagData.creativesEndDate=="9999-09-09 09:09:09"){
//                            $scope.endDateAdtagSize[index]= "";
//                       }
//                       else{
//                            $scope.endDateAdtagSize[index] = new Date($scope.creativeFormAdtagData.creativesEndDate);
//                       }
                       var curDate = new Date().toISOString().slice(0,10);
                       var formattedStartDate = new Date($scope.startDateAdtagSize[index]).toISOString().slice(0,10);
                       if(formattedStartDate < curDate){
                          $scope.disableStartDate[index] = true;
                       }else{
                          $scope.disableStartDate[index] = false;
                       }
//                     $scope.destinationUrlAdtag[index] = $scope.creativeFormAdtagData.destinationUrlAdtag;
                       $scope.advertiserDomainAdtag[index] = $scope.creativeFormAdtagData.advertiserDomainAdtag;
                       $scope.impressionTrackingPixelAdtag[index] = $scope.creativeFormAdtagData.impressionTrackingPixel;
                       $scope.ibvAdStartCallbackAdtag[index] = $scope.creativeFormAdtagData.ibvAdStartCallback;
                       $scope.ibvAdCompleteCallbackAdtag[index] = $scope.creativeFormAdtagData.ibvAdCompleteCallback;
                       $scope.ibvAdClicktrackCallbackAdtag[index] = $scope.creativeFormAdtagData.ibvAdClickTrackCallback;
                       $scope.text1[index] = $scope.creativeFormAdtagData.adtagCode;
                       var sensitiveAttributesArray = $scope.creativeFormAdtagData.sensitiveAttributes.split('::');
                       $scope.selectedSensitiveAttributeAdtag[index] = sensitiveAttributesArray;
                       var technicalAttributesArray = $scope.creativeFormAdtagData.technicalAttributes.split('::');
                       $scope.selectedTechnicalAttributeAdtag[index] = technicalAttributesArray;
//                     $scope.adSizes[index].forEach( function (adSize)
                       $scope.adSizes.forEach( function (adSize)
                       {
                          if(adSize.value==$scope.creativeFormAdtagData.adtagSize){
                             $scope.selectedAdSize[index] = adSize;
                          }
                       });
                       $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
                       {
                          if(countryLanguage.language==$scope.creativeFormAdtagData.language){
                            $scope.selectedCountryLanguagesAdtag[index] = countryLanguage.languageCode;
                            if(index==0){
                                $scope.selectedCountryLanguages = $scope.selectedCountryLanguagesAdtag[index];
                            }
                          }
                       });
                       $scope.categoriesAdtag.forEach( function (categoriesAdtag)
                       {
                          if(categoriesAdtag.contentCategoryName==$scope.creativeFormAdtagData.adtagCategory){
                             $scope.selectedCategoryAdtag[index] = categoriesAdtag.contentCategoryId;
//                             if(index==0){
////                                 $scope.selectedCategory = $scope.selectedCategoryAdtag[index];
//                                  $scope.subCategory = angular.copy($scope.subCategoriesAdtag[index]);
//                             }
                             var selectedCategoryId = categoriesAdtag.contentCategoryId;
                             $scope.selectedSubCategory = [];
                             if($scope.subCategoriesAdtagData){
                                 $scope.subCategoriesAdtagData.forEach( function (arrayItem)
                                 {
                                    var subCategoryId = arrayItem.contentSubcategoryId;
                                    if(selectedCategoryId==arrayItem.contentCategoryId){
                                        $scope.showSubCategories = true;
                                        $scope.selectedSubCategory.push(arrayItem.contentSubcategoryName);
                                    }
                                 });
//                                 $scope.subCategoriesAdtag = [];
                                 $scope.subCategoriesAdtag[index] = angular.copy($scope.selectedSubCategory);
                                 $scope.selectedSubCategoriesAdtag[index] = $scope.creativeFormAdtagData.adtagSubCategory;
                              if(index==0){
                                  $scope.subCategories = [];
                                  $scope.selectedCategory = $scope.selectedCategoryAdtag[index];
                                  $scope.subCategories = $scope.subCategoriesAdtag[index];
                                  $scope.selectedSubCategories = $scope.selectedSubCategoriesAdtag[index];
                              }
                             }
                          }
                       });
                       if(index==0){
                           $scope.adtag.startDate = angular.copy($scope.startDateAdtagSize[index]);
                           $scope.adtag.endDate = angular.copy($scope.endDateAdtagSize[index]);
                           $scope.disableStartDateCal = angular.copy($scope.disableStartDate[index]);
//                           $scope.destinationUrl = angular.copy($scope.destinationUrlAdtag[index]);
                           $scope.advertiserDomain = angular.copy($scope.advertiserDomainAdtag[index]);
                           $scope.seletedsensitiveAttribute.sensitiveAttributes = angular.copy($scope.selectedSensitiveAttributeAdtag[index]);
                           $scope.seletedtechnicalAttribute.technicalAttributes = angular.copy($scope.selectedTechnicalAttributeAdtag[index]);
                       }
                       $scope.advertiserId = advertiserId;
                   }
               });
             }else{
                 initAdtag();
             }

        };
        initServices();
        init();

        // checkErr is used to generate error if the start date is greater the end date and start date is leass than current date
        $scope.checkErr = function(startDate,endDate) {
            $scope.errMessage = '';
            var curDate = new Date().toISOString().slice(0,10);;
            var formattedStartDate = new Date(startDate).toISOString().slice(0,10);
            if(endDate){
                var formattedEndDate = new Date(endDate).toISOString().slice(0,10);
                if(new Date(startDate) > new Date(endDate)){
                  $scope.errMessage = 'End Date should be greater than start date';
                  return false;
                }
                if(formattedStartDate < curDate && !$scope.disableStartDate[0]){
                   $scope.errMessage = 'Start date should not be before today.';
                   return false;
                }
                if(formattedEndDate < curDate){
                   $scope.errMessage = 'End date should not be before today.';
                   return false;
                }
            }
        };

        // checkErrAdtag is used to generate error if the start date is greater the end date and start date is leass than current date
        $scope.checkErrAdtag = function(startDate,endDate,index) {
            $scope.errMessageAdtag = '';
            var curDate = new Date().toISOString().slice(0,10);;
            var formattedStartDate = new Date(startDate).toISOString().slice(0,10);
            if(new Date(startDate) > new Date(endDate)){
              $scope.errMessageAdtag = 'End Date should be greater than start date';
              return false;
            }
            if(formattedStartDate < curDate && !$scope.disableStartDate[index]){
               $scope.errMessageAdtag = 'Start date should not be before today.';
               return false;
            }
        };


        // copyData() is used to copy the data to the new added adsize
        $scope.copyData=function(adtagCount){
            if($scope.isEditCreative){
                $scope.disableAdsize[adtagCount]=false;
            }
            $scope.currentIndex = adtagCount;
            if($scope.impressionTrackingPixel){
                $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                $scope.clickUrl[$scope.currentIndex] = true;
                $scope.cacheBusterBtn[$scope.currentIndex] = true;
            }
            else{
                $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                $scope.clickUrl[$scope.currentIndex] = false;
                $scope.cacheBusterBtn[$scope.currentIndex] = false;
                $scope.clickUrlMacro = "data-dcm-click-tracker='https:{clickurl}'";
                $scope.cacheBusterMacro = '{random}';
            }
            var adtagCount = adtagCount;
//            $scope.adSizes= $scope.totalAdSizes;
            $scope.selectedCountryLanguagesAdtag[adtagCount]= angular.copy($scope.selectedCountryLanguages);
            if(typeof $scope.selectedCategory !== 'undefined' && $scope.selectedCategory !== null && $scope.selectedCategory.length!==0){
                $scope.selectedCategoryAdtag[adtagCount]= angular.copy($scope.selectedCategory);
            }
            if(typeof $scope.selectedSubCategories !== 'undefined' && $scope.selectedSubCategories !== null && $scope.selectedSubCategories.length!==0 ){
                $scope.selectedSubCategoriesAdtag[adtagCount]= angular.copy($scope.selectedSubCategories);
            }
            $scope.subCategoriesAdtag[adtagCount] = angular.copy($scope.subCategories);
            $scope.selectedTechnicalAttributeAdtag[adtagCount]= angular.copy($scope.selectedTechnicalAttribute);
            $scope.selectedSensitiveAttributeAdtag[adtagCount]= angular.copy($scope.selectedSentitiveAttribute);
            $scope.startDateAdtagSize[adtagCount] = angular.copy($scope.adtag.startDate);
            $scope.endDateAdtagSize[adtagCount] =  angular.copy($scope.adtag.endDate);
//            $scope.destinationUrlAdtag[adtagCount] = angular.copy($scope.destinationUrl);
            $scope.advertiserDomainAdtag[adtagCount] = angular.copy($scope.advertiserDomain);
        }

        $scope.deleteAdsize = function(index){
            if(index>0){
                $scope.tabListCounter-=1;
            }
        }

//        $scope.checkStartMoreThanEndDate = function (startValue,endValue) {
//            if(startValue && endValue){
//                var startDate = new Date(startValue);
//                var endDate = new Date(endValue);
//                return startDate < endDate;
//            }
//            else {
//                return false;
//            }
//        };

        // setStartDate() is used to copy the start date to adtagsize start date
        $scope.setStartDate = function(startDate){
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                $scope.startDateAdtagSize[i] =  startDate;
            }
        }

        // setEndDate() is used to copy the end date to adtagsize end date
        $scope.setEndDate = function(endDate){
            $scope.endDateTouched = true;
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                $scope.endDateAdtagSize[i] = endDate;
            }
        }

        // getCategories() binds the selected category to adtag selected category and load the respective subcategories
        $scope.getCategories=function(selectedCategory){
            if(typeof selectedCategory != 'undefined'){
//                $scope.selectedCategory = selectedCategory;
                $scope.subCategories = angular.copy($scope.subCategoriesdata)
                $scope.subCategoriesList = [];
                $scope.subCategories.forEach( function (arrayItem)
                {
                    var subCategoryId = arrayItem.contentSubcategoryId;
                    if(selectedCategory==arrayItem.contentCategoryId){
                        $scope.showSubCategories = true;
                        $scope.subCategoriesList.push(arrayItem.contentSubcategoryName);
                    }
                });
                $scope.subCategories = angular.copy($scope.subCategoriesList);
                if(!$scope.categoryAdtagTouched[$scope.adSizesCounter] && !$scope.subCategoryAdtagTouched[$scope.adSizesCounter]){
                    $scope.categoriesAdtag = angular.copy($scope.categories);
                    var categoryAssignment = function(){
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            $scope.selectedCategoryAdtag[i] = $scope.selectedCategory;
                            $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategoriesList);
                        }
                    }
                    $timeout( categoryAssignment, 1000);
                }
            }
        }

        // getSubCategory() binds the selected subcategory to adtag selected subcategory
        $scope.getSubCategory = function(selectedSubCategories){
            if(typeof selectedSubCategories != 'undefined'){
//                $scope.selectedSubCategories = selectedSubCategories;
                if(!$scope.subCategoryAdtagTouched[$scope.adSizesCounter])
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedSubCategoriesAdtag[i]= angular.copy($scope.selectedSubCategories)
                        $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategories);
                    }
                }
            }
        }

        // getCategoriesAdtag() takes the selected category and load the respective subcategories
        $scope.getCategoriesAdtag=function(selectedCategoryAdtag, index){
            if(typeof selectedCategoryAdtag != 'undefined'){
//                $scope.selectedCategoryAdtag[index] = selectedCategoryAdtag;
//                $scope.selectedSubCategoriesAdtag[index]=[];
                $scope.subCategoriesdataAdtag=[];
                var selectedCategoryId = $scope.selectedCategoryAdtag[index];
                $scope.subCategoriesAdtag[index] = angular.copy($scope.subCategoriesdata)
                $scope.subCategoriesAdtag[index].forEach( function (arrayItem)
                {
                    var subCategoryId = arrayItem.contentSubcategoryId;
                    if(selectedCategoryId==arrayItem.contentCategoryId){
                        $scope.subCategoriesdataAdtag.push(arrayItem.contentSubcategoryName);
//                        var subCategoriesdataAdtag = $scope.subCategoriesdataAdtag;
//                        $scope.selectedSubCategoriesAdtag[index]= subCategoriesdataAdtag;
                    }
                });
                $scope.categoryAdtagTouched = true;
                $scope.subCategoriesAdtag[index] = angular.copy($scope.subCategoriesdataAdtag);
            }
        }

        $scope.getSubCategoryAdtag = function(selectedSubCategoriesAdtag, index){
            if(typeof selectedSubCategoriesAdtag != 'undefined'){
//                $scope.selectedSubCategoriesAdtag[index] = selectedSubCategoriesAdtag;
                $scope.subCategoryAdtagTouched[index] = true;
            }
        }

        // getTechnicalAttributes() binds the selected technical attribute to all technical attribute of adtag size
        $scope.getTechnicalAttributes = function(selectedTechnicalAttribute){
            $scope.technicalAttributeTouched = true;
            if(typeof selectedTechnicalAttribute != 'undefined'){
                var selectedTechnicalAttribute=selectedTechnicalAttribute.filter(function(val){
                           return Boolean(val);
                 });
                $scope.selectedTechnicalAttribute = selectedTechnicalAttribute;
                if(!$scope.technicalAttributeAdtagTouched)
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedTechnicalAttributeAdtag[i]= angular.copy($scope.selectedTechnicalAttribute);
                    }
                }
            }
        }

        // getSensitiveAttributes() binds the selected sensitive attribute to all adtag size sensitive attribute
        $scope.getSensitiveAttributes=function(selectedSentitiveAttribute){
            $scope.sensitiveAttributeTouched = true;
            if(typeof selectedSentitiveAttribute != 'undefined'){
                var selectedSentitiveAttribute=selectedSentitiveAttribute.filter(function(val){
                     return Boolean(val);
                });
                $scope.selectedSentitiveAttribute = selectedSentitiveAttribute;
                if(!$scope.sensitiveAttributeAdtagTouched)
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedSensitiveAttributeAdtag[i]= angular.copy($scope.selectedSentitiveAttribute);
                    }
                }
            }
        }

        $scope.getTechnicalAttributesAdtag = function(selectedTechnicalAttributeAdtag,technicalAttribute,outerIndex){
            var technicalAttributeAdtagTabCount = $scope.tabCount;
            $scope.technicalAttributeAdtagTouched = true;
//            $scope.selectedTechnicalAttributeAdtag[outerIndex] = technicalAttribute;
        }

        $scope.getSensitiveAttributesAdtag = function(selectedSentitiveAttributeAdtag,sensitiveAttribute,outerIndex){
            var sensitiveAttributeAdtagTabCount = $scope.tabCount;
             $scope.sensitiveAttributeAdtagTouched = true;
        }

        // getCountryLanguages() binds the selected country language to all adsize country language
        $scope.getCountryLanguages=function(selectedCountryLanguages){
            if(typeof selectedCountryLanguages != 'undefined'){
                if(!$scope.countryLanguagesAdtagTouched[$scope.adSizeCount-1]){
                    $scope.countryLanguagesAdtag = angular.copy($scope.countryLanguages);
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedCountryLanguagesAdtag[i]= selectedCountryLanguages;
                    }
                }
            }
        }

        $scope.getCountryLanguagesAdtag=function(selectedCountryLanguagesAdtag,index){
            if(typeof selectedCountryLanguagesAdtag != 'undefined'){
                $scope.countryLanguagesAdtagTouched[index] = true;
//                $scope.selectedCountryLanguagesAdtag[index]= selectedCountryLanguagesAdtag[index];
            }
        }

        // getAdSizes() take the selected AdSize and joins it with creative name for adtag Size creative name
        $scope.getAdSizes = function(selectedAdSize,index){
            $scope.adSizeCall = true;
            if($scope.creativeName){
                if (typeof selectedAdSize != "undefined"){
    //                $scope.selectedInbannerAdsizes[index]=selectedAdSize;
                    $scope.currentIndex = index;
                    if(!$scope.isEditCreative){
                        if($scope.selectedAdSizesList.length==0){
                            $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                        }
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                            }
                            if(index>i){
                                if(selectedAdSize.label === $scope.selectedAdSizesList[i])
                                {
                                    $scope.selectedCreativeAdSizeExist[index] = true;
                                    $scope.expandAdtagSize[index] = false;
                                    break;
                                }
                                else{
                                    $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                                    $scope.selectedCreativeAdSizeExist[index] = false;
                                    $scope.expandAdtagSize[index] = true;
                                }
                            }
                            else{
                                $scope.expandAdtagSize[index] = true;
                            }
                             if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                             }
                        }
                         if($scope.impressionTrackingPixel){
                             $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                             $scope.clickUrl[$scope.currentIndex] = true;
                             $scope.cacheBusterBtn[$scope.currentIndex] = true;
                         }
                         else{
                             $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                             $scope.clickUrl[$scope.currentIndex] = false;
                             $scope.cacheBusterBtn[$scope.currentIndex] = false;
                             $scope.clickUrlMacro = "data-dcm-click-tracker='https:{clickurl}'";
                             $scope.cacheBusterMacro = '{random}';
                         }
                        $scope.startDateAdtagSize[index] = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//                        $scope.endDateAdtagSize[index] = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
                    }
                    else{
                        if($scope.selectedAdSizesList.length==0){
                            $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                        }
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                            }
                            if(index>i){
                                if(selectedAdSize.label === $scope.selectedAdSize[i].label)
                                {
                                    $scope.selectedCreativeAdSizeExist[index] = true;
                                    $scope.expandAdtagSize[index] = false;
                                    break;
                                }
                                else{
                                    $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                                    $scope.selectedCreativeAdSizeExist[index] = false;
                                    $scope.expandAdtagSize[index] = true;
                                }
                            }
                            else{
                                $scope.expandAdtagSize[index] = true;
                            }
                             if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                             }
                        }
                    }
                }
            }
        }

        $scope.isDisabled = function(selectedAdSize,index){
            if($scope.selectedCreativeAdSize.length>0 && index>0){
                for(var i=0;i<$scope.adSizeCount-1;i++)
                {
                    if($scope.selectedAdSize == $scope.selectedAdSizesList[i++]){
                        $scope.expandAdtagSize[index] = false;
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            }
        }


        $scope.setImpressionTrackingPixel = function(impressionTrackingPixel){
            $scope.displayThirdPartyAdtagNames = true;
            if(!impressionTrackingPixel){
                for(var i=0;i<$scope.adSizeCount;i++)
                {
                    $scope.thirdPartyMacrosEnable[i] = true;
                    $scope.clickUrl[i] = false;
                    $scope.cacheBusterBtn[i] = false;
                    $scope.clickUrlMacro = "data-dcm-click-tracker='https:{clickurl}'";
                    $scope.cacheBusterMacro = '{random}';
                    $scope.impressionTrackingPixelAdtag[i] = impressionTrackingPixel;
                }
            }
            else{
                for(var i=0;i<$scope.adSizeCount;i++)
                {
                    $scope.thirdPartyMacrosEnable[i] = false;
                    $scope.clickUrl[i] = true;
                    $scope.cacheBusterBtn[i] = true;
                    $scope.impressionTrackingPixelAdtag[i] = impressionTrackingPixel;
                }
            }
        }

        // applyToAll() copies the
        $scope.applyToAll = function(applyToAllAdtags){
            for(var i=0;i<$scope.adSizeCount;i++)
            {
                $scope.selectedCountryLanguagesAdtag[i]= angular.copy($scope.selectedCountryLanguages);
                $scope.selectedCategoryAdtag[i]= angular.copy($scope.selectedCategory);
                $scope.selectedSubCategoriesAdtag[i]= angular.copy($scope.selectedSubCategories)
                $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategories);
                $scope.selectedTechnicalAttributeAdtag[i]= angular.copy($scope.selectedTechnicalAttribute);
                $scope.selectedSensitiveAttributeAdtag[i]= angular.copy($scope.selectedSentitiveAttribute);
            }
        }

        function loadHtml(index) {
            var previewDoc = document.getElementById('adTagPreview');
            var line = document.createElement("div");
            line.innerHTML = $scope.text1[index]
            previewDoc.appendChild(line);
        }

        $scope.checkOverLappingDatesForAdtags = function (viewValue) {
            var currentDate = new Date(viewValue);
            var adtagStartDate = new Date($scope.adtag.startDate);
            var adtagEndDate = new Date($scope.adtag.endDate);
            return currentDate > adtagStartDate && currentDate < adtagEndDate;
        };

        $scope.range = function(count){
           $scope.adSizeCount = count;
           return new Array(+count);
        };

        function dateFiltering(dateObj){
            return $filter('date')(dateObj, 'yyyy-MM-dd HH:mm:ss');
        }

        $scope.user = angular.copy($scope.leader);
//        $scope.adtag = {budgets:[{startDate:dateFiltering(moment().add(364, 'DAYS').toDate()),endDate:"",type:"daily",budget:"",clicksLimit:"",impressionsLimit:"",delivery:"standard",startDateOpened:false,endDateOpened:false,startOption:{
//            formatYear: 'yy',
//            startingDay: 1,
//            minDate : moment().toDate()
//        },endOption:{
//            formatYear: 'yy',
//            startingDay: 1,
//            minDate : moment().toDate()
//        }}]};

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;
                }
            }
        };

        $scope.openStartEndDateAdtagSize = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index > -1){
                    $scope.startDateAdtagOpened[index] = true;
                }
            }
            else{
                if(index > -1){
                    $scope.endDateAdtagOpened[index] = true;
                }
            }
        };

        $scope.IsVisible = false;
        $scope.ShowHide = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisible = $scope.IsVisible ? false:true;
        }
        $scope.IsVisibleAdtag = [];
        $scope.ShowHideAdtag = function(index){
            $scope.IsVisibleAdtag[index] = $scope.IsVisibleAdtag[index] ? false:true;
        }
        $scope.dateFormat = function (data) {
            $scope.adtag[data] = $filter('date')($scope.adtag[data], 'yyyy-MM-dd HH:mm:ss');
        };
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.lastFocused;
        angular.element("#input1").focus(function() {
            $scope.lastFocused = document.activeElement;
        });

        function insertAtCursor(textArea, macro, index) {
            //IE support
            if (document.selection) {
                textArea.focus();
                sel = document.selection.createRange();
                sel.text = macro;
            }
            // Microsoft Edge
            else if(window.navigator.userAgent.indexOf("Edge") > -1) {
              var startPos = textArea.selectionStart;
              var endPos = textArea.selectionEnd;
              textArea.value = textArea.value.substring(0, startPos)+ macro + textArea.value.substring(endPos, textArea.value.length);
              var pos = startPos + macro.length;
              textArea.focus();
              textArea.setSelectionRange(pos, pos);
            }
            //MOZILLA and others
            else if (textArea.selectionStart || textArea.selectionStart == '0') {
                var startPos = textArea.selectionStart;
                var endPos = textArea.selectionEnd;
                textArea.value = textArea.value.substring(0, startPos)
                    + macro
                    + textArea.value.substring(endPos, textArea.value.length);
                    textArea.focus();
                    return textArea.value;
            } else {
                textArea.value += macro;
                textArea.focus();
                return textArea.value
            }
        }


        $scope.getThirdPartyAdtag = function(thirdPartyAdtagName,index) {
            $scope.selectedThirdPartyMacrosList = [];
            angular.forEach($scope.thirdPartyMacrosList, function (value, key) {
                if(value.third_party_id==thirdPartyAdtagName){
                    $scope.selectedThirdPartyMacrosList.push(value);
//                    if(value.description=="clickURL"){
//                        $scope.clickUrlMacro = value.macro;
//                    }
                }
            })
        };

        // insertText() inserts the macro on the cursor position
        $scope.insertText = function(text,index){
            var insertedText = insertAtCursor(document.getElementsByTagName('textarea')[index], text,index);
            $scope.text1[index]=angular.copy(insertedText);
        }

        $scope.reloadRoute = function() {
            $state.reload();
        };

        $scope.saveCreativesAdtagForm = function(){
           if($stateParams.hasOwnProperty("advertiserId")){
              if($scope.isEditCreative){
                 CreativeManagerAdtagService.saveNewCreativeAdtagSize(advertiserId,$scope.creativeMappingId,formAdtagsData).then(function(data){
                      $scope.submitButtonDisable = true;
                      $timeout(function () {
                         $state.go('creative-manager-edit-adtags-creative-advId', {advertiserId: advertiserId, creativeMappingId: $scope.creativeMappingId})
                     }, 2000);
                  //console.log(data);
                  $scope.reloadRoute
                  });
              }
              else{
                  CreativeManagerAdtagService.saveCreativeAdtagFormAdvId(advertiserId,formAdtagsData).then(function(data){
                     $scope.submitButtonDisable = true;
                     $timeout(function () {
                         $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
                      }, 2000);
                   //console.log(data);
                   $scope.reloadRoute
                  });
              }
           }
           else{
               CreativeManagerAdtagService.saveCreativeAdtagFormAdvId(advertiserId,formAdtagsData).then(function(data){
                   $scope.submitButtonDisable = true;
                   $timeout(function () {
                      $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
                   }, 2000);
                  //console.log(data);
                  $scope.reloadRoute
               });
           }
        };

        $scope.getAdvertiserTimezone = function(advertiserId){
          CreativeManagerAdtagService.advertiserTimezone(advertiserId).then(function (data) {
             console.log(data);
             $scope.currentUserTime = "";
             var offset = 0;
             $scope.advertiser = data;
             if($scope.advertiser != undefined) {
                 $scope.currentUserTime = $scope.advertiser.currentTime;
                 offset = new Date().getTimezoneOffset();
                $scope.adtag.startDate = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
             }
          });
        }

        var timer;

        $scope.openAdtagPopup = function (creativeAdtag, adsize) {
           timer = $timeout(function () {
                var dialog = ngDialog.open({ template: 'app/creative-manager/creative-adtag-popup.html',
                    controller: 'CreativeAdtagPopupController',
                    className: 'ngdialog-theme-default custom-width-800',
                    resolve : {
                        creativeAdtag : function(){
                            return creativeAdtag;
                        },
                        adsize : function(){
                            return adsize;
                        }
                    }
                });
            }, 1000);
        };


        $scope.hideIt = function () {
            $timeout.cancel(timer);
            $scope.hovering = false;
        };

        $scope.updateCreativeAdSize = function(index){
              $scope.formAdtagsData = [];
//              var formAdtagsData = [];
              $scope.formAdtagData = {};
              if($stateParams.hasOwnProperty("advertiserId")){
                  advertiserId = $stateParams.advertiserId;
              }
              else{
                  advertiserId = $scope.advertiserId;
              }
              $scope.formAdtagData.advertiserId = advertiserId;
              // if isEditCreative then assign the creativeId to the formAdtagData
              if($scope.isEditCreative){
                  $scope.formAdtagsData = [];
                  $scope.formAdtagData.creativeMappingId=creativeMappingId;
                  $scope.formAdtagData.creativeId = $scope.creativeId[index];
                  $scope.formAdtagData.creativeNameAdtag = $scope.creativeNameAdtag[index];
                  $scope.formAdtagData.platform = $scope.selectedPlatform.toString();
                  $scope.formAdtagData.selectedCreativeAdSize = $scope.selectedAdSize[index].value;
                  $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
                  {
                       if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                            $scope.formAdtagData.selectedCountryLanguagesAdtag = countryLanguage.language;
                       }
                  });
                  $scope.categoriesAdtag.forEach( function (categoriesAdtag)
                  {
                     if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                         $scope.formAdtagData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                     }
                  });
                  $scope.formAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
                  if($scope.technicalAttributeTouched){
                     $scope.formAdtagData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
                  }
                  if($scope.sensitiveAttributeTouched){
                      $scope.formAdtagData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
                  }
                  $scope.formAdtagData.creativeAdTags = $scope.text1[index];
      //            $scope.formAdtagData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
                  $scope.formAdtagData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
                  $scope.formAdtagData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
                  $scope.formAdtagData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                  if($scope.endDateTouched){
                    if($scope.endDateAdtagSize[index]== undefined || $scope.endDateAdtagSize[index]== "Invalid date" || $scope.endDateAdtagSize[index]== null || $scope.endDateAdtagSize[index]==""){
                          $scope.formAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
                     }
                     else{
                         $scope.formAdtagData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                     }
                  }
                  else{
                     $scope.formAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
                  }
                  formAdtagData = angular.copy($scope.formAdtagData);
                  formAdtagsData.push(formAdtagData);
//                  if(formAdtagsData.length<=$scope.adSizeCount){
//                     formAdtagsData.push(formAdtagData);
//                  }
                 CreativeManagerAdtagService.updateCreativeAdtag(advertiserId,creativeMappingId, formAdtagsData).then(function (data) {
//                         console.log(data);
                         $scope.reloadRoute();
                  });
              }
        }


        $scope.saveNewCreativeAdSize= function(index){
              $scope.formAdtagsData = [];
//              var formAdtagsData = [];
              $scope.formAdtagData = {};
              if($stateParams.hasOwnProperty("advertiserId")){
                  advertiserId = $stateParams.advertiserId;
              }
              else{
                  advertiserId = $scope.advertiserId;
              }
              $scope.formAdtagData.advertiserId = advertiserId;
              // if isEditCreative then assign the creativeId to the formAdtagData
              if($scope.isEditCreative){
                  $scope.formAdtagData.creativeMappingId=creativeMappingId;
              }
             $scope.formAdtagData.creativeId = $scope.creativeId[index];
             $scope.formAdtagData.creativeNameAdtag = $scope.creativeNameAdtag[index];
             $scope.formAdtagData.platform = $scope.selectedPlatform.toString();
             $scope.formAdtagData.selectedCreativeAdSize = $scope.selectedAdSize[index].value;
             $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
             {
                  if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                      $scope.formAdtagData.selectedCountryLanguagesAdtag = countryLanguage.language;
                  }
             });
             $scope.categoriesAdtag.forEach( function (categoriesAdtag)
             {
                if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                    $scope.formAdtagData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                }
             });
             if(typeof $scope.selectedSubCategoriesAdtag[index] !== 'undefined' && $scope.selectedSubCategoriesAdtag[index] !== null && $scope.selectedSubCategoriesAdtag[index].length!==0){
                 $scope.formAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
             }
             else{
                 $scope.formAdtagData.selectedSubCategoriesAdtag = "";
             }
//             $scope.formAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
             if($scope.technicalAttributeTouched){
                $scope.formAdtagData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
             }
             if($scope.sensitiveAttributeTouched){
                 $scope.formAdtagData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
             }
             $scope.formAdtagData.creativeAdTags = $scope.text1[index];
    //             $scope.formAdtagData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
             $scope.formAdtagData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
             $scope.formAdtagData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
             $scope.formAdtagData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
             if($scope.endDateTouched){
                if($scope.endDateAdtagSize[index]== undefined || $scope.endDateAdtagSize[index]== "Invalid date" || $scope.endDateAdtagSize[index]== null || $scope.endDateAdtagSize[index]==""){
                     $scope.formAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
                }
                else{
                     $scope.formAdtagData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                }
             }
             else{
                $scope.formAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
             }
             formAdtagData = angular.copy($scope.formAdtagData);
             if(formAdtagsData.length<=$scope.adSizeCount){
                formAdtagsData.push(formAdtagData);
             }
            console.log('Saving New Creative');
            $scope.saveCreativesAdtagForm();
            $scope.reloadRoute();
        }


        $scope.saveCreativeAdtagSize = function(index){
             if($stateParams.hasOwnProperty("advertiserId")){
                advertiserId = $stateParams.advertiserId;
                if($scope.creativeId[index]){
                    $scope.updateCreativeAdSize(index);
                }
                else{
                    $scope.saveNewCreativeAdSize(index);
                }
             }
        }

          // submitCreativesForm function will be called whenever submit button is pressed
        $scope.submitCreativeAdTags= function(){
          $scope.formAdtagsData = [];
//          var formAdtagsData = [];
          if($stateParams.hasOwnProperty("advertiserId")){
              advertiserId = $stateParams.advertiserId;
          }
          else{
              advertiserId = $scope.advertiserId;
          }
          $scope.formAdtagData.advertiserId = advertiserId;
          $scope.formAdtagData.platform = $scope.selectedPlatform.toString();
          // if isEditCreative then assign the creativeId to the formAdtagData
          if($scope.isEditCreative){
              $scope.formAdtagData.creativeMappingId=creativeMappingId;
          }
          for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
             $scope.formAdtagData.creativeId = $scope.creativeId[i];
             $scope.formAdtagData.creativeNameAdtag = $scope.creativeNameAdtag[i];
             $scope.formAdtagData.selectedCreativeAdSize = $scope.selectedAdSize[i].value;
             $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
             {
                  if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[i]){
                      $scope.formAdtagData.selectedCountryLanguagesAdtag = countryLanguage.language;
                  }
             });
             $scope.categoriesAdtag.forEach( function (categoriesAdtag)
             {
                if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[i]){
                    $scope.formAdtagData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                }
             });
             if(typeof $scope.selectedSubCategoriesAdtag[i] !== 'undefined' && $scope.selectedSubCategoriesAdtag[i] !== null && $scope.selectedSubCategoriesAdtag[i].length!==0){
                 $scope.formAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[i];
             }
             else{
                 $scope.formAdtagData.selectedSubCategoriesAdtag = "";
             }
             if($scope.technicalAttributeTouched){
                $scope.formAdtagData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[i].join('::');
             }
             if($scope.sensitiveAttributeTouched){
                 $scope.formAdtagData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[i].join('::');
             }
             $scope.formAdtagData.creativeAdTags = $scope.text1[i];
//             $scope.formAdtagData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
             $scope.formAdtagData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[i];
             $scope.formAdtagData.startDateAdtagSize  = moment($scope.startDateAdtagSize[i]).format('YYYY-MM-DD HH:mm:ss');
             if($scope.endDateAdtagSize[i]){
                $scope.formAdtagData.endDateAdtagSize  = moment($scope.endDateAdtagSize[i]).format('YYYY-MM-DD HH:mm:ss');
             }
             else{
                $scope.formAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
             }
             $scope.formAdtagData.impressionTrackingPixel = $scope.impressionTrackingPixel;
             formAdtagData = angular.copy($scope.formAdtagData);
             if(formAdtagsData.length<=$scope.adSizeCount){
                formAdtagsData.push(formAdtagData);
             }
          }
       // if creativeId is not present, saveCreativesForm() will be called, else updateCreative() will call
             $scope.reloadRoute = function() {
                  $state.reload();
             };
            if (creativeMappingId === undefined || creativeMappingId === null) {
                console.log('Saving New Creative');
                $scope.saveCreativesAdtagForm();
            }
//            else {
//                CreativeManagerAdtagService.updateCreativeAdtag(advertiserId,creativeMappingId, formAdtagsData).then(function (data) {
//                   console.log(data);
//                   $scope.reloadRoute();
//                });
//            };
            $scope.submitButtonDisable = true
        };

       document.addEventListener("DOMContentLoaded", function() {
          document.getElementById("process").addEventListener("click", function () {
             var $iframe = document.getElementById("iframe-render"),iframe, iframeDoc;
             if ( !$iframe ) {
               iframe = document.createElement("iframe");
               iframe.id = "iframe-render";
               document.getElementById("adTagPreview").appendChild( iframe );
             } else {
               iframe = $iframe;
             }
             iframeDoc = iframe.contentWindow ?
                          iframe.contentWindow :
                          iframe.contentDocument.document ?
                          iframe.contentDocument.document :
                          iframe.contentDocument;
             iframeDoc.document.open();
             iframeDoc.document.write(
                document.getElementById("$index").value
             );
             iframeDoc.document.close();
          }, false);
       }, false);

     })
     .filter('hasIntersection', function() {
        return function(item, array) {
           return array.indexOf(item) >= 0;
        };
     })
     .filter('sanitizer',function($sce) {
         return function(content) {
              return $sce.trustAsHtml(content);
         };
     })



    .controller('CreativeManagerCreateInbannerVideoCreativeController', function ($scope,$stateParams,$state,$window,$http,CreativeManagerService,$compile,$filter,$rootScope,GetAdvertiserIDName,$timeout,$sanitize, $sce, ngDialog, CreativeManagerAdtagService, CreativeManagerInbannerAdtagService,advertiser) {
        $scope.categories = [];
        $scope.allAdvertisersIdName = [];
        $scope.categoriesAdtag = [];
        $scope.sensitiveAttributes = [];
        $scope.technicalAttributes = [];
        $scope.subCategories = [];
        $scope.countryLanguages = [];
        $scope.countryLanguagesAdtag = [];
        $scope.categoryNames = [];
        $scope.destinationUrl="";
        $scope.adSizeCount=1;
        $scope.advertiser = advertiser;
        $scope.creativeFormInbannerAdtagData=[];
        $scope.creativeNameAdtag = [];
        $scope.selectedCountryLanguagesAdtag = [];
        $scope.sensitiveAttributeNames = [];
        $scope.technicalAttributeNames = [];
        $scope.selectedTechnicalAttribute=[];
        $scope.selectedAdSizesList=[];
        $scope.selectedSentitiveAttribute=[];
        $scope.selectedTechnicalAttributeAdtag = [];
        $scope.selectedSensitiveAttributeAdtag = [];
        $scope.destinationUrlAdtag = [];
        $scope.advertiserDomainAdtag = [];
        $scope.subCategoryNames = [];
        $scope.selectedSubCategories = [];
        $scope.selectedCategoryAdtag = [];
        $scope.selectedSubCategoriesAdtag = [];
        $scope.text1 = [];
        $scope.subCategoriesdataAdtag = [];
        $scope.selectedAdSize=[];
        $scope.selectedAdtagAdSizes=[];
        $scope.subCategoriesAdtag = [];
        $scope.impressionTrackingPixel = true;
        var formAdtagsData = [];
        $scope.previewText=[];
        $scope.selectedCreativeAdSize=[];
        $scope.selectedCreativeSize=[];
        $scope.expandAdtagSize = [];
        var formInbannerAdtagData = {};
        $scope.callbacks=false;
        $scope.isAdvertiserId = false;
        var creativeId;
        var advertiserId;
        $scope.selectedCreativeAdSizeExist = [];
        $scope.selectedSize= false;
        $scope.showSubCategories = false
        $scope.subCategoryInbannerAdtagTouched = [];
        $scope.categoryAdtagTouched = [];
        $scope.countryLanguagesAdtagTouched = [];
        $scope.destinationUrlAdtagTouched = false;
        $scope.technicalAttributeAdtagTouched = false;
        $scope.sensitiveAttributeAdtagTouched = false;
        $scope.sensitiveAttributeInbannerTouched = false;
        $scope.technicalAttributeInbannerTouched = false;
        $scope.displayThirdPartyAdtagNames = false;
        $scope.impressionTrackingPixelAdtag = [];
        $scope.ibvAdStartCallbackAdtag = [];
        $scope.ibvAdCompleteCallbackAdtag = [];
        $scope.ibvAdClicktrackCallbackAdtag = [];
        $scope.clickUrlBtn = false;
        $scope.cacheBusterBtn = false;
        $scope.submitButtonDisable = false;
        $scope.invalidTag = [];
        $scope.disableStartDate = {};
        $scope.sliceCount = 0;
        var formInbannerAdtagsData = [];
        $scope.searchWords = [];
        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.startDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            minDate:moment()
        };

        $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.adSizes=[];
        $scope.creativeId=[];
        $scope.adSizesCounter=0;
        $scope.selectedInbannerAdsizes=[];
        $scope.startDateOpened = false;
        $scope.endDateOpened = false;
        $scope.startDateTouched = false;
        $scope.endDateTouched = false;
        $scope.thirdPartyMacrosEnable = [];
        $scope.clickUrl = [];
        $scope.cacheBusterBtn = [];
        $scope.startDateAdtagOpened = [];
        $scope.endDateAdtagOpened = [];
        var creativeMappingId;
        $scope.platformSelection = false;
        $scope.formInbannerAdtagData = {};
        $scope.seletedtechnicalAttribute = {
            technicalAttributes:[]
        }

        $scope.seletedsensitiveAttribute = {
            sensitiveAttributes:[]
        }

        $scope.seletedtechnicalAttribute = {
            technicalAttributesAdtag:[]
        }

        $scope.seletedsensitiveAttribute = {
            sensitiveAttributesAdtag:[]
        }

        $scope.initDate = new Date();
        $scope.adtag = {};
//        $scope.adtag = {
//            startDate : moment().format('YYYY-MM-DD HH:mm:ss'),
//            startDateAdtag : moment().format('YYYY-MM-DD HH:mm:ss')
//        }
//        $scope.adtag = {
//            endDate : moment().format('YYYY-MM-DD HH:mm:ss'),
//            endDateAdtag : moment().format('YYYY-MM-DD HH:mm:ss')
//        }

        $scope.currentUserTime = "";
        var offset = 0;
        if($scope.advertiser != undefined) {
            $scope.currentUserTime = $scope.advertiser.currentTime;
            offset = new Date().getTimezoneOffset();
        }

        $scope.startDateAdtagSize = [];
        $scope.endDateAdtagSize = [];
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];


        $scope.copyText = function(text1,index){
//            $scope.text1[index]= text1;
            $scope.previewText[index] =  text1;

            var validPattern = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            var invalidPattern = /http:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            var text1 = text1.trim();
            var firstChar = text1.charAt(0);
            var lastChar = text1.charAt(text1.length-1);
            if(firstChar!='"' && lastChar!='"'){
                if(validPattern.test(text1)){
                    $scope.invalidTag[index] = false;
                    $scope.submitButtonDisable = false;
                }
                else if(invalidPattern.test(text1)){
                   $scope.invalidTag[index] = true;
                   alert($scope.creativeNameAdtag[index]+" tag is invalid");
                   $scope.submitButtonDisable = true;
                }
            }
            else{
               $scope.invalidTag[index] = true;
               alert($scope.creativeNameAdtag[index]+" tag is invalid");
               $scope.submitButtonDisable = true;
            }

            String.prototype.replaceAll = function(searchStr, replaceStr) {
                var str = this;

                // no match exists in string?
                if(str.indexOf(searchStr) === -1) {
                    // return string
                    return str;
                }

                // replace and remove first match, and do another recursirve search/replace
                return (str.replace(searchStr, replaceStr)).replaceAll(searchStr, replaceStr);
            }

            angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                if(val.thirdPartyPlaceholder){
                    if(text1.search(/val.thirdPartyPlaceholder/i)){
                       $scope.searchWords.push(val.thirdPartyPlaceholder)
                       text1 = text1.replaceAll(val.thirdPartyPlaceholder, val.macro);
                    }
                }
            })

            if($scope.platform.app){
                if(text1.search(/~appbundle~\"/i)){
                    text1 = text1.replace("~appbundle~\"","~appbundle~&c1=~RID~&c2=~AEID~&c3=\"")
                }
            }

            $scope.text1[index] = angular.copy(text1);
//            alert($scope.searchWords)
        }

         $scope.platform = {
            desktopWeb: false,
            mobileWeb: false,
            app: false
         };

         $scope.macrotype = {
            adserverMacros: false,
            rtbMacros: false
         };

        $scope.$watchCollection('platform', function () {
          $scope.selectedPlatform = [];
          $scope.selectedMacrosTypeList = [];
          angular.forEach($scope.platform, function (value, key) {
            if (value) {
              $scope.selectedPlatform.push(key);
              $scope.platformSelection = true;
            }
          });
            if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
                angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                  angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                        if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                          }
                        }
                        else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                    else if($scope.selectedPlatform.length>1){
                        if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                  });
                });
             } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
               angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                 angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                       if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                         if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                         }
                       }
                       else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                    else if($scope.selectedPlatform.length>1){
                       if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                 });
               });
             }
             else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
                  angular.forEach($scope.selectedPlatform, function (data) {
     //                console.log(data)
                    angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                       if($scope.selectedPlatform.length==1){
                          if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                            if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                            }
                          }
                          else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                       else if($scope.selectedPlatform.length>1){
                          if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                    });
                 });
             }
        });

        $scope.isStartDateOpen = false;
        $scope.isEndDateOpen = false;
        $scope.isStartDateAdtagOpen = [];
        $scope.isEndDateAdtagOpen = [];

        $scope.openCalendar = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index == -1){
                    $scope.isStartDateOpen = true;
                }
            }
            else{
                if(index == -1){
                    $scope.isEndDateOpen = true;
                }
            }
        };

        $scope.openCalendarAdtagSize = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index > -1){
                    $scope.isStartDateAdtagOpen[index] = true;
                }
            }
            else{
                if(index > -1){
                    $scope.isEndDateAdtagOpen[index] = true;
                }
            }
        };

        $scope.getMacros = function(){
            $scope.selectedMacrosTypeList=[];
            $scope.macros = true;
          if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
              angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
            angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
              if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
            });
          });
       } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
         angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
           angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
             if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
               if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
               }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
           });
         });
       }
       else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
            angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
              angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                  if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                  }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
              });
            });
          }
          else{
            $scope.macros = false;
          }
        }

        $scope.deleteAdsize = function(index){
            if(index>0){
                $scope.tabListCounter-=1;
            }
        }

        var timer;

        $scope.openAdtagPopup = function (creativeAdtag, adsize) {
            timer = $timeout(function () {
                var dialog = ngDialog.open({ template: 'app/creative-manager/creative-adtag-popup.html',
                    controller: 'CreativeAdtagPopupController',
                    className: 'ngdialog-theme-default custom-width-800',
                    resolve : {
                        creativeAdtag : function(){
                            return creativeAdtag;
                        },
                        adsize : function(){
                            return adsize;
                        }
                    }
                });
            }, 2000);
        };

         $scope.hideIt = function () {
            $timeout.cancel(timer);
            $scope.hovering = false;
         };

        // get all the advertisers
        var advertiserInfoPromise =  GetAdvertiserIDName.query();
        advertiserInfoPromise.$promise.then(function(response){
             $scope.allAdvertisersIdName = response;
             if($scope.stateDecide){
                $scope.selectedAdvertiser = parseInt($stateParams.advertiserId);
             }
        });

        $scope.setCreativeName = function(creativeName){
            $scope.adSizeSelectionDisable = false;
//            $scope.creativeName = creativeName;
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                if($scope.selectedAdSize.length==0){
//                    $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName);
//                    $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+" - "+$scope.selectedAdSize);
                }
            }
        }


        $scope.setCreativeNameAdtag = function(creativeNameAdtag,index){
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                if($scope.selectedAdSize[i] == $scope.selectedAdSize[index]){
                    $scope.creativeNameAdtag[i] = angular.copy(creativeNameAdtag[index]);
                }
            }
        }

        $scope.setDestinationUrl = function(destinationUrl){
//            $scope.destinationUrl = destinationUrl;
            if(!$scope.destinationUrlAdtagTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.destinationUrlAdtag[i]= angular.copy($scope.destinationUrl)
                }
            }
        }

        $scope.setDestinationUrlAdtag = function(destinationUrlAdtag,index){
            $scope.destinationUrlAdtagTouched = true;
            $scope.destinationUrlAdtag[index] = angular.copy(destinationUrlAdtag[index]);
        }

        // setAdvertiserDomain copies the destination url to all the selected adsizes
        $scope.setAdvertiserDomain = function(advertiserDomain){
            if(!$scope.advertiserDomainAdtagTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.advertiserDomainAdtag[i]= angular.copy($scope.advertiserDomain);
                }
            }
        }

        $scope.setDestinationUrlAdtag = function(advertiserDomainAdtag,index){
            $scope.advertiserDomainAdtagTouched = true;
            $scope.advertiserDomainAdtag[index] = angular.copy(advertiserDomainAdtag[index]);
        }

        $scope.getBinder = function(){
            $scope.previewText = $sce.trustAsHtml($scope.text1);
        }

        function initAdtag() {
//            $scope.adtag.startDate = moment($scope.currentUserTime).add(10+offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
            $scope.adtag.startDate = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.adtag.endDate = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.startDateAdtagSize = moment($scope.currentUserTime).add(10+offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.endDateAdtagSize = moment($scope.currentUserTime).add(364, 'DAYS').add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
        }
//        initAdtag();
//        init();

        $scope.showPopover = function() {
          $scope.popoverIsVisible = true;
        };

        $scope.hidePopover = function () {
          $scope.popoverIsVisible = false;
        };

        function initServices() {
            CreativeManagerInbannerAdtagService.getInbannerAdtagData().then(function(categoriesdata){
                $scope.categories = angular.copy(categoriesdata.contentCategories);
                $scope.categoriesAdtag = angular.copy(categoriesdata.contentCategories);
                if(!$scope.subCategories.length){
                    $scope.subCategories = angular.copy(categoriesdata.contentSubcategories);
                }
//                $scope.subCategoriesAdtag = angular.copy(categoriesdata.contentSubcategories);
                $scope.technicalAttributes = angular.copy(categoriesdata.creativeTechnicalAttributes);
                $scope.sensitiveAttributes = angular.copy(categoriesdata.creativeSensitiveAttributes);
                $scope.countryLanguages = angular.copy(categoriesdata.countryLanguages);
                $scope.countryLanguagesAdtag = angular.copy(categoriesdata.countryLanguages);
                $scope.thirdPartyNamesList = angular.copy(categoriesdata.thirdPartyNames);
                $scope.thirdPartyMacrosList = angular.copy(categoriesdata.thirdPartyMacros);
                var adSizes = angular.copy(categoriesdata.adSizes);
                if(!$scope.adSizes.length && $scope.sliceCount==0){
                    $scope.adSizes = angular.copy(categoriesdata.adSizes.slice(1));
                    $scope.sliceCount +=1;
                }
                $scope.totalAdSizes = angular.copy(adSizes.slice(1));;
                $scope.subCategoriesdata = angular.copy($scope.subCategories);
            });
        }


        function init(){
            if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("creativeMappingId") && $stateParams.creativeMappingId != undefined){
               $scope.isAdvertiserId = false;
               $scope.isEditCreative = true;
               $scope.isNewCreative = false;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               creativeMappingId = $stateParams.creativeMappingId;
               $scope.creativeMappingId = creativeMappingId;
               $scope.endDateTouched = true;
            }
            else if($stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeMappingId") && $stateParams.advertiserId != undefined){
               $scope.isAdvertiserId = false;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               $scope.isNewCreative = true;
               $scope.isEditCreative = false;
            }
            else if(!$stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeMappingId") && $stateParams.advertiserId == undefined){
                $scope.isAdvertiserId = true;
                $scope.isEditCreative = false;
           }
            else{
              $scope.isAdvertiserId = false;
              $scope.isEditCreative = false;
            }
            if($scope.isEditCreative){
                $scope.isAdvertiserId = false;
                $scope.disableAdsize={};
                $scope.callbacks=true;
               CreativeManagerInbannerAdtagService.getCreativeInbannerAdtag(advertiserId,creativeMappingId).then(function(creativeAdtagData){
                   for(var index=0;index<Object.keys(creativeAdtagData).length;index++){
                       $scope.creativeAllDetails = angular.copy(creativeAdtagData[index]);
                       $scope.showSubCategories = true;
                       var adSizes;
                       $scope.creativeFormInbannerAdtagData = $scope.creativeAllDetails;
//                       $scope.adSizeCount = $scope.creativeFormInbannerAdtagData.adSizeCount;
                       $scope.tabListCounter = Object.keys(creativeAdtagData).length;
                       $scope.disableAdsize[index]=true;
//                       $scope.allSubCategoriesAdtag = $scope.creativeFormInbannerAdtagData.contentSubcategories;
                       $scope.creativeId[index] = $scope.creativeFormInbannerAdtagData.creativeId;
                       if(index==0){
                           $scope.countryLanguages = $scope.creativeFormInbannerAdtagData.countryLanguages;
                           $scope.countryLanguagesAdtag = $scope.creativeFormInbannerAdtagData.countryLanguages;
                           $scope.categories = $scope.creativeFormInbannerAdtagData.contentCategories;
                           $scope.categoriesAdtag = $scope.creativeFormInbannerAdtagData.contentCategories;
                           $scope.adtagSizes = angular.copy($scope.creativeFormInbannerAdtagData.adSizes.slice(1));
                           if(!$scope.adSizes.length){
                               $scope.adSizes = angular.copy($scope.creativeFormInbannerAdtagData.adSizes.slice(1));
                           }
//                          $scope.subCategories = $scope.creativeFormInbannerAdtagData.contentSubcategories;
                            $scope.subCategoriesAdtagData = $scope.creativeFormInbannerAdtagData.contentSubcategories;
                          $scope.ibvAdStartCallback = $scope.creativeFormInbannerAdtagData.ibvAdStartCallback;
                          $scope.ibvAdCompleteCallback = $scope.creativeFormInbannerAdtagData.ibvAdCompleteCallback;
                          $scope.ibvAdClicktrackCallback = $scope.creativeFormInbannerAdtagData.ibvAdClickTrackCallback;
                          $scope.impressionTrackingPixel = $scope.creativeFormInbannerAdtagData.impressionTrackingPixel;
                          $scope.thirdPartyMacrosEnable[index] = !$scope.impressionTrackingPixel;
                          if(!$scope.thirdPartyMacrosEnable[index]){
                             $scope.clickUrl[index] = true;
                             $scope.cacheBusterBtn[index] = true;
                          }
                       }

//                       $scope.adSizes[index] = adSizes
                       var creativeName=$scope.creativeFormInbannerAdtagData.creativeNameAdtag.split('-');
                       var creativeTagsName=$scope.creativeFormInbannerAdtagData.creativeNameAdtag.slice(0,-7);
                       var creativeTagName = $scope.creativeFormInbannerAdtagData.creativeNameAdtag.replace(creativeName.slice(-1),"");
                       creativeTagName = creativeTagName.replace(/-$/, '');
                       $scope.creativeName= creativeTagName;
//                       $scope.creativeName= creativeName[0].trim();
                       $scope.creativeAdSize= creativeName[1].trim();
                       $scope.expandAdtagSize[index] = true;
                       $scope.creativeNameAdtag[index] = $scope.creativeFormInbannerAdtagData.creativeNameAdtag;
                       if($scope.creativeFormInbannerAdtagData.platform){
                           var platformArray = $scope.creativeFormInbannerAdtagData.platform.split(',');
                           $scope.selectedPlatform.push(platformArray);
                           angular.forEach($scope.platform, function (value, key) {
                               angular.forEach(platformArray, function (data) {
                                  if (key == data) {
                                     $scope.platform[key] = true;
                                  }
                               })
                           });
                       }

                       $scope.startDateAdtagSize[index] = moment($scope.creativeFormInbannerAdtagData.creativesStartDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                       // alert($scope.startDateAdtagSize[index]);
                       if($scope.creativeFormInbannerAdtagData.creativesEndDate.indexOf("9999") == -1) {
                           // $scope.campaign.endDate = dateFiltering(new Date($scope.campaign.endDate));
                           $scope.endDateAdtagSize[index] = moment($scope.creativeFormInbannerAdtagData.creativesEndDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                       }
                       else {
                           $scope.endDateAdtagSize[index] = null;
                       }

//                       $scope.startDateAdtagSize[index] = new Date($scope.creativeFormInbannerAdtagData.creativesStartDate);
//                       if($scope.creativeFormInbannerAdtagData.creativesEndDate=="9999-09-09 09:09:09"){
//                            $scope.endDateAdtagSize[index]= "";
//                       }
//                       else{
//                           $scope.endDateAdtagSize[index] = new Date($scope.creativeFormInbannerAdtagData.creativesEndDate);
//                       }
                       var curDate = new Date().toISOString().slice(0,10);

                       var formattedStartDate = new Date($scope.startDateAdtagSize[index]).toISOString().slice(0,10);
                       if(formattedStartDate < curDate){
                          $scope.disableStartDate[index] = true;
                       }else{
                          $scope.disableStartDate[index] = false;
                       }
//                       $scope.destinationUrlAdtag[index] = $scope.creativeFormInbannerAdtagData.destinationUrlAdtag;
                       $scope.advertiserDomainAdtag[index] = $scope.creativeFormInbannerAdtagData.advertiserDomainAdtag;
                       $scope.impressionTrackingPixelAdtag[index] = $scope.creativeFormInbannerAdtagData.impressionTrackingPixel;
                       $scope.ibvAdStartCallbackAdtag[index] = $scope.creativeFormInbannerAdtagData.ibvAdStartCallback;
                       $scope.ibvAdCompleteCallbackAdtag[index] = $scope.creativeFormInbannerAdtagData.ibvAdCompleteCallback;
                       $scope.ibvAdClicktrackCallbackAdtag[index] = $scope.creativeFormInbannerAdtagData.ibvAdClickTrackCallback;
                       $scope.text1[index] = $scope.creativeFormInbannerAdtagData.adtagCode;
                       var sensitiveAttributesArray = $scope.creativeFormInbannerAdtagData.sensitiveAttributes.split('::');
                       $scope.selectedSensitiveAttributeAdtag[index] = sensitiveAttributesArray;
                       var technicalAttributesArray = $scope.creativeFormInbannerAdtagData.technicalAttributes.split('::');
                       $scope.selectedTechnicalAttributeAdtag[index] = technicalAttributesArray;
                       $scope.adSizes.forEach( function (adSize)
                       {
                          if(adSize.value==$scope.creativeFormInbannerAdtagData.adtagSize){
                             $scope.selectedAdSize[index] = adSize;
                          }
                       });


                       $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
                       {
                          if(countryLanguage.language==$scope.creativeFormInbannerAdtagData.language){
                             $scope.selectedCountryLanguagesAdtag[index] = countryLanguage.languageCode;
                             if(index==0){
                                $scope.selectedCountryLanguages = $scope.selectedCountryLanguagesAdtag[index];
                             }
                          }
                       });


                       $scope.categoriesAdtag.forEach( function (categoriesAdtag)
                       {
                          if(categoriesAdtag.contentCategoryName==$scope.creativeFormInbannerAdtagData.adtagCategory){
                             $scope.selectedCategoryAdtag[index] = categoriesAdtag.contentCategoryId;
                             var selectedCategoryId = categoriesAdtag.contentCategoryId;
                             $scope.selectedSubCategory = [];
                             if($scope.subCategoriesAdtagData){
                                 $scope.subCategoriesAdtagData.forEach( function (arrayItem)
                                 {
                                    var subCategoryId = arrayItem.contentSubcategoryId;
                                    if(selectedCategoryId==arrayItem.contentCategoryId){
                                        $scope.showSubCategories = true;
                                        $scope.selectedSubCategory.push(arrayItem.contentSubcategoryName);
                                    }
                                 });
//                                 $scope.subCategoriesAdtag = [];
                                 $scope.subCategoriesAdtag[index] = angular.copy($scope.selectedSubCategory);
                                 $scope.selectedSubCategoriesAdtag[index] = $scope.creativeFormInbannerAdtagData.adtagSubCategory;
                              if(index==0){
                                  $scope.subCategories = [];
                                  $scope.selectedCategory = $scope.selectedCategoryAdtag[index];
                                  $scope.subCategories = $scope.subCategoriesAdtag[index];
                                  $scope.selectedSubCategories = $scope.selectedSubCategoriesAdtag[index];
                              }
                             }
                          }
                       });

                       if(index==0){
                           $scope.adtag.startDate = angular.copy($scope.startDateAdtagSize[index]);
                           $scope.adtag.endDate = angular.copy($scope.endDateAdtagSize[index]);
                           $scope.disableStartDateCal = angular.copy($scope.disableStartDate[index]);
//                           $scope.destinationUrl = angular.copy($scope.destinationUrlAdtag[index]);
                           $scope.advertiserDomain = angular.copy($scope.advertiserDomainAdtag[index]);
                           $scope.seletedsensitiveAttribute.sensitiveAttributes = angular.copy($scope.selectedSensitiveAttributeAdtag[index]);
                           $scope.seletedtechnicalAttribute.technicalAttributes = angular.copy($scope.selectedTechnicalAttributeAdtag[index]);
                       }
                       $scope.advertiserId = advertiserId;
                   }
               });
             }
             else{
                initAdtag();
             }

             if($scope.impressionTrackingPixel){
                 $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                 $scope.clickUrl[$scope.currentIndex] = true;
                 $scope.cacheBusterBtn[$scope.currentIndex] = true;
             }
             else{
                 $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                 $scope.clickUrl[$scope.currentIndex] = false;
                 $scope.cacheBusterBtn[$scope.currentIndex] = false;
                 $scope.clickUrlMacro = '{clickurl}';
                 $scope.cacheBusterMacro = '{random}';
             }
           };
        initServices();
        init();
//        initAdtag();

        $scope.copyData=function(adtagCount){
            $scope.newSize=true;
            if($scope.isEditCreative){
                $scope.disableAdsize[adtagCount]=false;
            }
            $scope.currentIndex = adtagCount;
            if($scope.impressionTrackingPixel){
                $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                $scope.clickUrl[$scope.currentIndex] = true;
                $scope.cacheBusterBtn[$scope.currentIndex] = true;
            }
            else{
                $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                $scope.clickUrl[$scope.currentIndex] = false;
                $scope.cacheBusterBtn[$scope.currentIndex] = false;
                $scope.clickUrlMacro = '{clickurl}';
                $scope.cacheBusterMacro = '{random}';
            }
            var adtagCount = adtagCount;

//            $scope.adSizes= $scope.totalAdSizes;
            $scope.selectedCountryLanguagesAdtag[adtagCount]= angular.copy($scope.selectedCountryLanguages);
            $scope.selectedCategoryAdtag[adtagCount]= angular.copy($scope.selectedCategory);
            $scope.selectedSubCategoriesAdtag[adtagCount]= angular.copy($scope.selectedSubCategories)
            $scope.subCategoriesAdtag[adtagCount] = angular.copy($scope.subCategories);
            $scope.selectedTechnicalAttributeAdtag[adtagCount]= angular.copy($scope.selectedTechnicalAttribute);
            $scope.selectedSensitiveAttributeAdtag[adtagCount]= angular.copy($scope.selectedSentitiveAttribute);
            $scope.startDateAdtagSize[adtagCount] = angular.copy($scope.adtag.startDate);
            $scope.endDateAdtagSize[adtagCount] =  angular.copy($scope.adtag.endDate);
//            $scope.destinationUrlAdtag[adtagCount] = angular.copy($scope.destinationUrl);
            $scope.advertiserDomainAdtag[adtagCount] = angular.copy($scope.advertiserDomain);
        }

        // checkErr is used to generate error if the start date is greater the end date and start date is leass than current date
        $scope.checkErr = function(startDate,endDate) {
            $scope.errMessage = '';
            var curDate = new Date().toISOString().slice(0,10);
            var formattedStartDate = new Date(startDate).toISOString().slice(0,10);
            if(endDate){
                var formattedEndDate = new Date(endDate).toISOString().slice(0,10);

                if(new Date(startDate) > new Date(endDate)){
                  $scope.errMessage = 'End Date should be greater than start date';
                  return false;
                }
                if(formattedStartDate < curDate && !$scope.disableStartDate[0]){
                   $scope.errMessage = 'Start date should not be before today.';
    //               $scope.disableStartDate = true;
                   return false;
                }
                if(formattedEndDate < curDate){
                   $scope.errMessage = 'End date should not be before today.';
                   return false;
                }
            }
        };

        // checkErrAdtag is used to generate error if the start date is greater the end date and start date is leass than current date
        $scope.checkErrAdtag = function(startDate,endDate,index) {
            $scope.errMessageAdtag = '';
            var curDate = new Date().toISOString().slice(0,10);;
            var formattedStartDate = new Date(startDate).toISOString().slice(0,10);
            if(new Date(startDate) > new Date(endDate)){
              $scope.errMessageAdtag = 'End Date should be greater than start date';
              return false;
            }
            if(formattedStartDate < curDate && !$scope.disableStartDate[index]){
               $scope.errMessageAdtag = 'Start date should not be before today.';
               return false;
            }
        };

        // set the setAdvertiserDomain of inbanner adtag size
        $scope.setAdvertiserDomainAdtag = function(advertiserDomainAdtag,index){
            $scope.advertiserDomainAdtagTouched = true;
            $scope.advertiserDomainAdtag[index] = angular.copy(advertiserDomainAdtag[index]);
        }

        $scope.setImpressionTrackingPixel = function(impressionTrackingPixel){
            $scope.displayThirdPartyAdtagNames = true;
            if(!impressionTrackingPixel){
                for(var i=0;i<$scope.adSizeCount;i++)
                {
                    $scope.thirdPartyMacrosEnable[i] = true;
                    $scope.clickUrl[i] = false;
                    $scope.cacheBusterBtn[i] = false;
                    $scope.clickUrlMacro = '{clickurl}';
                    $scope.cacheBusterMacro = '{random}';
                    $scope.impressionTrackingPixelAdtag[i] = impressionTrackingPixel;
                }
            }
            else{
                for(var i=0;i<$scope.adSizeCount;i++)
                {
                    $scope.thirdPartyMacrosEnable[i] = false;
                    $scope.clickUrl[i] = true;
                    $scope.cacheBusterBtn[i] = true;
                    $scope.impressionTrackingPixelAdtag[i] = impressionTrackingPixel;
                }
            }
        }

        $scope.getThirdPartyAdtag = function(thirdPartyAdtagName,index) {
            $scope.selectedThirdPartyMacrosList = [];
            angular.forEach($scope.thirdPartyMacrosList, function (value, key) {
                if(value.third_party_id==thirdPartyAdtagName){
                     $scope.selectedThirdPartyMacrosList.push(value);
                }
            })
        };

//        $scope.checkStartMoreThanEndDate = function (startValue,endValue) {
//            if(startValue && endValue){
//                var startDate = new Date(startValue);
//                var endDate = new Date(endValue);
//                return startDate < endDate;
//            }
//            else {
//                return false;
//            }
//        };

        // setStartDate() is used to copy the start date to adtagsize start date
        $scope.setStartDate = function(startDate){
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                $scope.startDateAdtagSize[i] =  startDate;
            }
        }

        // setEndDate() is used to copy the end date to adtagsize end date
        $scope.setEndDate = function(endDate){
            $scope.endDateTouched = true;
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                $scope.endDateAdtagSize[i] = endDate;
            }
        }

        // getCategoriesInbanner() binds the selected category to adtag selected category and load the respective subcategories
        $scope.getCategoriesInbanner=function(selectedCategory){
            if(typeof selectedCategory != 'undefined'){
//                $scope.selectedCategory = selectedCategory;
                $scope.subCategories = angular.copy($scope.subCategoriesdata)
                $scope.selectedSubCategoriesList = [];
                $scope.subCategories.forEach( function (arrayItem)
                {
                    var subCategoryId = arrayItem.contentSubcategoryId;
                    if(selectedCategory==arrayItem.contentCategoryId){
                        $scope.showSubCategories = true;
                        $scope.selectedSubCategoriesList.push(arrayItem.contentSubcategoryName);
                    }
                });
                $scope.subCategories = angular.copy($scope.selectedSubCategoriesList);
                if(!$scope.categoryAdtagTouched[$scope.adSizesCounter] && !$scope.subCategoryInbannerAdtagTouched[$scope.adSizesCounter]){
                    $scope.categoriesAdtag = angular.copy($scope.categories);
                    var categoryAssignment = function(){
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            $scope.selectedCategoryAdtag[i] = $scope.selectedCategory;
                            $scope.subCategoriesAdtag[i] = angular.copy($scope.selectedSubCategoriesList);
                        }
                    }
                    $timeout( categoryAssignment, 1000);
                }
            }
        }

        // getSubCategoryInbanner() binds the selected subcategory to adtag selected subcategory
        $scope.getSubCategoryInbanner = function(selectedSubCategories){
            if(typeof selectedSubCategories != 'undefined'){
                $scope.selectedSubCategories = selectedSubCategories;
                if(!$scope.subCategoryInbannerAdtagTouched[$scope.adSizesCounter])
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedSubCategoriesAdtag[i]= angular.copy($scope.selectedSubCategories)
                        $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategories);
                    }
                }
            }
        }

        // getCategoriesAdtag() takes the selected categoryAdtag and loads the respective subcategory
        $scope.getCategoriesAdtag=function(selectedCategoryAdtag, index){
            if(typeof selectedCategoryAdtag != 'undefined'){
//                $scope.selectedCategoryAdtag[index] = selectedCategoryAdtag;
//                $scope.selectedSubCategoriesAdtag[index]=[];
                $scope.subCategoriesdataAdtag=[];
                var selectedCategoryId = $scope.selectedCategoryAdtag[index];
                $scope.subCategoriesAdtag[index] = angular.copy($scope.subCategoriesdata)
                $scope.subCategoriesAdtag[index].forEach( function (arrayItem)
                {
                    var subCategoryId = arrayItem.contentSubcategoryId;
                    if(selectedCategoryId==arrayItem.contentCategoryId){
                        $scope.subCategoriesdataAdtag.push(arrayItem.contentSubcategoryName);
//                        var subCategoriesdataAdtag = $scope.subCategoriesdataAdtag;
//                        $scope.selectedSubCategoriesAdtag[index]= subCategoriesdataAdtag;
                    }
                });
                $scope.categoryAdtagTouched = true;
                $scope.subCategoriesAdtag[index] = angular.copy($scope.subCategoriesdataAdtag);
            }
        }

        $scope.getSubCategoryInbannerAdtag = function(selectedSubCategoriesAdtag, index){
            if(typeof selectedSubCategoriesAdtag != 'undefined'){
                $scope.selectedSubCategoriesAdtag[index] = selectedSubCategoriesAdtag;
                $scope.subCategoryInbannerAdtagTouched[index] = true;
            }
        }

        // getTechnicalAttributes() binds the selected technical attribute to all adtag size technical attribute
        $scope.getTechnicalAttributes = function(selectedTechnicalAttribute){
            $scope.technicalAttributeInbannerTouched = true;
            if(typeof selectedTechnicalAttribute != 'undefined'){
                var selectedTechnicalAttribute=selectedTechnicalAttribute.filter(function(val){
                    return Boolean(val);
                });
                $scope.selectedTechnicalAttribute = selectedTechnicalAttribute;
                if(!$scope.technicalAttributeAdtagTouched)
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedTechnicalAttributeAdtag[i]= angular.copy($scope.selectedTechnicalAttribute);
                    }
                }
            }
        }

        // getSensitiveAttributes() binds the selected sensitive attribute to all adtag size sensitive attribute
        $scope.getSensitiveAttributes=function(selectedSentitiveAttribute){
            $scope.sensitiveAttributeInbannerTouched = true;
            if(typeof selectedSentitiveAttribute != 'undefined'){
                var selectedSentitiveAttribute=selectedSentitiveAttribute.filter(function(val){
                    return Boolean(val);
                });
                $scope.selectedSentitiveAttribute = selectedSentitiveAttribute;
                if(!$scope.sensitiveAttributeAdtagTouched)
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedSensitiveAttributeAdtag[i]= angular.copy($scope.selectedSentitiveAttribute);
                    }
                }
            }
        }

        $scope.getTechnicalAttributesAdtag = function(selectedTechnicalAttributeAdtag,technicalAttribute,outerIndex){
            var technicalAttributeAdtagTabCount = $scope.tabCount;
            $scope.technicalAttributeAdtagTouched = true;
        }

        $scope.getSensitiveAttributesAdtag = function(selectedSentitiveAttributeAdtag,sensitiveAttribute,outerIndex){
            var sensitiveAttributeAdtagTabCount = $scope.tabCount;
             $scope.sensitiveAttributeAdtagTouched = true;
        }

        // getCountryLanguages() binds the selected country language to all adsize country language
        $scope.getCountryLanguages=function(selectedCountryLanguages){
            if(typeof selectedCountryLanguages != 'undefined'){
                if(!$scope.countryLanguagesAdtagTouched[$scope.adSizeCount-1]){
                    $scope.countryLanguagesAdtag = angular.copy($scope.countryLanguages);
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedCountryLanguagesAdtag[i]= selectedCountryLanguages;
                    }
                }
            }
        }

        $scope.getCountryLanguagesAdtag=function(selectedCountryLanguagesAdtag,index){
            if(typeof selectedCountryLanguagesAdtag != 'undefined'){
                $scope.countryLanguagesAdtagTouched[index] = true;
            }
        }

        // getInbannerAdSizes() take the selected AdSize and joins it with creative name for adtag Size creative name
        $scope.getInbannerAdSizes = function(selectedAdSize,index){
            $scope.adSizeCall = true;
            if($scope.creativeName){
                if (typeof selectedAdSize != "undefined"){
                    $scope.currentIndex = index;
                    if(!$scope.isEditCreative){
                        if($scope.selectedAdSizesList.length==0){
                            $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                        }
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                            }
                            if(index>i){
                                if(selectedAdSize.label === $scope.selectedAdSizesList[i])
                                {
                                    $scope.selectedCreativeAdSizeExist[index] = true;
                                    $scope.expandAdtagSize[index] = false;
                                    break;
                                }
                                else{
                                    $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                                    $scope.selectedCreativeAdSizeExist[index] = false;
                                    $scope.expandAdtagSize[index] = true;
                                }
                            }
                            else{
                                $scope.expandAdtagSize[index] = true;
                            }
                             if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                             }
                        }
                        if($scope.impressionTrackingPixel){
                            $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                            $scope.clickUrl[$scope.currentIndex] = true;
                            $scope.cacheBusterBtn[$scope.currentIndex] = true;
                        }
                        else{
                            $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                            $scope.clickUrl[$scope.currentIndex] = false;
                            $scope.cacheBusterBtn[$scope.currentIndex] = false;
                            $scope.clickUrlMacro = '{clickurl}';
                            $scope.cacheBusterMacro = '{random}';
                        }
                        $scope.startDateAdtagSize[index] = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//                        $scope.endDateAdtagSize[index] = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
//                        $scope.endDateAdtagSize[index] = moment($scope.currentUserTime).add(364, 'DAYS').add(offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
                    }
                    else{
                        if($scope.selectedAdSizesList.length==0){
                            $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                        }
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                            }
                            if(index>i){
                                if(selectedAdSize.label === $scope.selectedAdSize[i].label)
                                {
                                    $scope.selectedCreativeAdSizeExist[index] = true;
                                    $scope.expandAdtagSize[index] = false;
                                    break;
                                }
                                else{
                                    $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                                    $scope.selectedCreativeAdSizeExist[index] = false;
                                    $scope.expandAdtagSize[index] = true;
                                }
                            }
                            else{
                                $scope.expandAdtagSize[index] = true;
                            }
                             if(i == index){
                                $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                             }
                        }
                    }
                }
            }
        }

        $scope.isDisabled = function(selectedAdSize,index){
            if($scope.selectedCreativeAdSize.length>0 && index>0){
                for(var i=0;i<$scope.adSizeCount-1;i++)
                {
                    if($scope.selectedAdSize[i] == $scope.selectedAdSize[index]){
                        $scope.expandAdtagSize[index] = false;
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            }
        }

        $scope.applyToAll = function(applyToAllAdtags){
            for(var i=0;i<$scope.adSizeCount;i++)
            {
                $scope.selectedCountryLanguagesAdtag[i]= angular.copy($scope.selectedCountryLanguages);
                $scope.selectedCategoryAdtag[i]= angular.copy($scope.selectedCategory);
                $scope.selectedSubCategoriesAdtag[i]= angular.copy($scope.selectedSubCategories)
                $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategories);
                $scope.selectedTechnicalAttributeAdtag[i]= angular.copy($scope.selectedTechnicalAttribute);
                $scope.selectedSensitiveAttributeAdtag[i]= angular.copy($scope.selectedSentitiveAttribute);
            }
        }

        function loadHtml(index) {
            var previewDoc = document.getElementById('adTagPreview');
            var line = document.createElement("div");
            line.innerHTML = $scope.text1[index]
            previewDoc.appendChild(line);
        }

        $scope.checkOverLappingDatesForAdtags = function (viewValue) {
            var currentDate = new Date(viewValue);
            var adtagStartDate = new Date($scope.adtag.startDate);
            var adtagEndDate = new Date($scope.adtag.endDate);
            return currentDate > adtagStartDate && currentDate < adtagEndDate;
        };

        $scope.range = function(count){
          $scope.adSizeCount = count;
          return new Array(+count);
        };

        function dateFiltering(dateObj){
            return $filter('date')(dateObj, 'yyyy-MM-dd HH:mm:ss');
        }

        $scope.user = angular.copy($scope.leader);

//        $scope.adtag = {budgets:[{startDate:dateFiltering(moment().add(364, 'DAYS').toDate()),endDate:"",type:"daily",budget:"",clicksLimit:"",impressionsLimit:"",delivery:"standard",startDateOpened:false,endDateOpened:false,startOption:{
//            formatYear: 'yy',
//            startingDay: 1,
//            minDate : moment().toDate()
//        },endOption:{
//            formatYear: 'yy',
//            startingDay: 1,
//            minDate : moment().toDate()
//        }}]};

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;
                }
            }
        };

        $scope.openStartEndDateAdtagSize = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index > -1){
                    $scope.startDateAdtagOpened[index] = true;
                }
            }
            else{
                if(index > -1){
                    $scope.endDateAdtagOpened[index] = true;
                }
            }
        };

    $scope.IsVisible = false;
    $scope.ShowHide = function () {
        //If DIV is visible it will be hidden and vice versa.
        $scope.IsVisible = $scope.IsVisible ? false:true;
    }

    $scope.IsVisibleAdtag = [];
    $scope.ShowHideAdtag = function(index){
        $scope.IsVisibleAdtag[index] = $scope.IsVisibleAdtag[index] ? false:true;
    }

    $scope.dateFormat = function (data) {
        $scope.adtag[data] = $filter('date')($scope.adtag[data], 'yyyy-MM-dd HH:mm:ss');
    };

    $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.lastFocused;
    angular.element("#input1").focus(function() {
        $scope.lastFocused = document.activeElement;
    });

    function insertAtCursor(textArea, macro, index) {
        //IE support
        if (document.selection) {
            textArea.focus();
            sel = document.selection.createRange();
            sel.text = macro;
        }
        // Microsoft Edge
        else if(window.navigator.userAgent.indexOf("Edge") > -1) {
          var startPos = textArea.selectionStart;
          var endPos = textArea.selectionEnd;
          textArea.value = textArea.value.substring(0, startPos)+ macro + textArea.value.substring(endPos, textArea.value.length);
          var pos = startPos + macro.length;
          textArea.focus();
          textArea.setSelectionRange(pos, pos);
        }
        //MOZILLA and others
        else if (textArea.selectionStart || textArea.selectionStart == '0') {
            var startPos = textArea.selectionStart;
            var endPos = textArea.selectionEnd;
            textArea.value = textArea.value.substring(0, startPos)
                + macro
                + textArea.value.substring(endPos, textArea.value.length);
                textArea.focus();
                return textArea.value;
        } else {
            textArea.value += macro;
            textArea.focus();
            return textArea.value
        }
    }

    // insertText() inserts the macro on the cursor position
    $scope.insertText = function(text,index){
        var insertedText = insertAtCursor(document.getElementsByTagName('textarea')[index], text,index);
        $scope.text1[index]=angular.copy(insertedText);
    }

     $scope.reloadRoute = function() {
          $state.reload();
     };

    // saveCreativesInbannerAdtagForm() gets called when the submit button is pressed to save the form and it changes the state for form update
    $scope.saveCreativesInbannerAdtagForm = function(){
       if($stateParams.hasOwnProperty("advertiserId")){
           if($scope.isEditCreative){
              CreativeManagerInbannerAdtagService.saveNewCreativeInbannerAdtagSize(advertiserId,$scope.creativeMappingId,formInbannerAdtagsData).then(function(data){
                   $scope.submitButtonDisable = true;
                   $timeout(function () {
                     $state.go('creative-manager-edit-inbanner-creative-advId', {advertiserId: advertiserId, creativeMappingId: $scope.creativeMappingId})
                  }, 2000);
//               console.log(data);
               $scope.reloadRoute;
               });
           }
           else{
               CreativeManagerInbannerAdtagService.saveCreativeInbannerAdtagFormAdvId(advertiserId,formInbannerAdtagsData).then(function(data){
                  $scope.submitButtonDisable = true;
                  $timeout(function () {
                      $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
                   }, 2000);
//                console.log(data);
                $scope.reloadRoute;
               });
           }
       }
       else{
           CreativeManagerInbannerAdtagService.saveCreativeInbannerAdtagFormAdvId(advertiserId,formInbannerAdtagsData).then(function(data){
              $scope.submitButtonDisable = true;
              $timeout(function () {
                 $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
              }, 2000);
//                console.log(data);
           $scope.reloadRoute
           });
       }

    };

    $scope.updateCreativeAdSize = function(index){
          $scope.formInbannerAdtagDataList = [];
          $scope.formInbannerAdtagData = {};
          if($stateParams.hasOwnProperty("advertiserId")){
              advertiserId = $stateParams.advertiserId;
          }
          else{
              advertiserId = $scope.advertiserId;
          }
          $scope.formInbannerAdtagData.advertiserId = advertiserId;
          // if isEditCreative then assign the creativeId to the formInbannerAdtagData
          if($scope.isEditCreative){
              $scope.formInbannerAdtagDataList = [];
              $scope.formInbannerAdtagData.creativeMappingId=creativeMappingId;
              $scope.formInbannerAdtagData.creativeId = $scope.creativeId[index];
              $scope.formInbannerAdtagData.creativeNameAdtag = $scope.creativeNameAdtag[index];
              $scope.formInbannerAdtagData.platform = $scope.selectedPlatform.toString();
              $scope.formInbannerAdtagData.selectedCreativeAdSize = $scope.selectedAdSize[index].value;
              $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
              {
                   if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                        $scope.formInbannerAdtagData.selectedCountryLanguagesAdtag = countryLanguage.language;
                   }
              });
              $scope.categoriesAdtag.forEach( function (categoriesAdtag)
              {
                 if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                     $scope.formInbannerAdtagData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                 }
              });
              $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
              if($scope.technicalAttributeTouched){
                 $scope.formInbannerAdtagData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
              }
              if($scope.sensitiveAttributeTouched){
                  $scope.formInbannerAdtagData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
              }
              $scope.formInbannerAdtagData.creativeAdTags = $scope.text1[index];
//              $scope.formInbannerAdtagData.creativeAdTags = $scope.items;
  //            $scope.formInbannerAdtagData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
              $scope.formInbannerAdtagData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
              $scope.formInbannerAdtagData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
              $scope.formInbannerAdtagData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
              if($scope.endDateTouched){
                if($scope.endDateAdtagSize[index]== undefined || $scope.endDateAdtagSize[index]== "Invalid date" || $scope.endDateAdtagSize[index]== null || $scope.endDateAdtagSize[index]==""){
                   $scope.formInbannerAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
                 }
                 else{
                   $scope.formInbannerAdtagData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                 }
              }
              else{
                 $scope.formInbannerAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
              }
              formInbannerAdtagData = angular.copy($scope.formInbannerAdtagData);
              $scope.formInbannerAdtagDataList.push(formInbannerAdtagData);
              CreativeManagerInbannerAdtagService.updateCreativeInbannerAdtag(advertiserId,creativeMappingId, $scope.formInbannerAdtagDataList).then(function (data) {
//                     console.log(data);
                     $scope.reloadRoute();
              });
          }
    }


    $scope.saveNewCreativeAdSize= function(index){
          $scope.formInbannerAdtagsData = [];
//          var formInbannerAdtagsData=[];
          $scope.formInbannerAdtagData = {};
          if($stateParams.hasOwnProperty("advertiserId")){
              advertiserId = $stateParams.advertiserId;
          }
          else{
              advertiserId = $scope.advertiserId;
          }
          $scope.formInbannerAdtagData.advertiserId = advertiserId;
          // if isEditCreative then assign the creativeId to the formInbannerAdtagData
          if($scope.isEditCreative){
              $scope.formInbannerAdtagData.creativeMappingId=creativeMappingId;
          }
         $scope.formInbannerAdtagData.creativeId = $scope.creativeId[index];
         $scope.formInbannerAdtagData.creativeNameAdtag = $scope.creativeNameAdtag[index];
         $scope.formInbannerAdtagData.platform = $scope.selectedPlatform.toString();
         $scope.formInbannerAdtagData.selectedCreativeAdSize = $scope.selectedAdSize[index].value;
         $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
         {
              if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                  $scope.formInbannerAdtagData.selectedCountryLanguagesAdtag = countryLanguage.language;
              }
         });
         $scope.categoriesAdtag.forEach( function (categoriesAdtag)
         {
            if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                $scope.formInbannerAdtagData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
            }
         });
         if(typeof $scope.selectedSubCategoriesAdtag[i] !== 'undefined' && $scope.selectedSubCategoriesAdtag[i] !== null && $scope.selectedSubCategoriesAdtag[i].length!==0){
             $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
         }
         else{
             $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = "";
         }
//         $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
         if($scope.technicalAttributeTouched){
            $scope.formInbannerAdtagData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
         }
         if($scope.sensitiveAttributeTouched){
             $scope.formInbannerAdtagData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
         }
         $scope.formInbannerAdtagData.creativeAdTags = $scope.text1[index];
//             $scope.formInbannerAdtagData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
         $scope.formInbannerAdtagData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
         $scope.formInbannerAdtagData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
         $scope.formInbannerAdtagData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
         if($scope.endDateTouched){
            $scope.formInbannerAdtagData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
         }
         else{
            $scope.formInbannerAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
         }
         formInbannerAdtagData = angular.copy($scope.formInbannerAdtagData);
         if(formInbannerAdtagsData.length<=$scope.adSizeCount){
            formInbannerAdtagsData.push(formInbannerAdtagData);
         }
        console.log('Saving New Creative');
        $scope.saveCreativesInbannerAdtagForm();
        $scope.reloadRoute();
    }


    $scope.saveCreativeAdtagSize = function(index){
         if($stateParams.hasOwnProperty("advertiserId")){
            advertiserId = $stateParams.advertiserId;
            if($scope.creativeId[index]){
                $scope.updateCreativeAdSize(index);
            }
            else{
                $scope.saveNewCreativeAdSize(index);
            }
         }
    }


    // submitCreativesForm function submit the inbanner form when the submit button is pressed
    $scope.submitCreativeInbannerAdTags= function(){
//       var formInbannerAdtagsData = [];
       if($stateParams.hasOwnProperty("advertiserId")){
          advertiserId = $stateParams.advertiserId;
       }
       else{
          advertiserId = $scope.advertiserId;
       }
       $scope.formInbannerAdtagData.advertiserId = advertiserId;
       // if isEditCreative then assign the creativeId to the formInbannerAdtagData
       if($scope.isEditCreative){
          $scope.formInbannerAdtagData.creativeMappingId=creativeMappingId;
       }
       for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
          $scope.formInbannerAdtagData.creativeId = $scope.creativeId[i];
          $scope.formInbannerAdtagData.creativeNameAdtag = $scope.creativeNameAdtag[i];
          $scope.formInbannerAdtagData.selectedCreativeAdSize = $scope.selectedAdSize[i].value;
          $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
          {
             if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[i]){
                $scope.formInbannerAdtagData.selectedCountryLanguagesAdtag = countryLanguage.language;
             }
          });
          $scope.categoriesAdtag.forEach( function (categoriesAdtag)
          {
             if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[i]){
                $scope.formInbannerAdtagData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
             }
          });
          if(typeof $scope.selectedSubCategoriesAdtag[i] !== 'undefined' && $scope.selectedSubCategoriesAdtag[i] !== null && $scope.selectedSubCategoriesAdtag[i].length!==0){
               $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[i];
           }
           else{
               $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = "";
           }
//          $scope.formInbannerAdtagData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[i];
          if($scope.technicalAttributeInbannerTouched){
              $scope.formInbannerAdtagData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[i].join('::');
          }
          if($scope.sensitiveAttributeInbannerTouched){
              $scope.formInbannerAdtagData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[i].join('::');
          }
          $scope.formInbannerAdtagData.creativeAdTags = $scope.text1[i];
//          $scope.formInbannerAdtagData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
          $scope.formInbannerAdtagData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[i];
          $scope.formInbannerAdtagData.startDateAdtagSize  = moment($scope.startDateAdtagSize[i]).format('YYYY-MM-DD HH:mm:ss');
          if($scope.endDateAdtagSize[i]){
              $scope.formInbannerAdtagData.endDateAdtagSize  = moment($scope.endDateAdtagSize[i]).format('YYYY-MM-DD HH:mm:ss');
          }
          else{
            $scope.formInbannerAdtagData.endDateAdtagSize  = "9999-09-09 09:09:09";
          }
          $scope.formInbannerAdtagData.impressionTrackingPixel = $scope.impressionTrackingPixel;
          $scope.formInbannerAdtagData.platform = $scope.selectedPlatform.toString();
          formInbannerAdtagData = angular.copy($scope.formInbannerAdtagData);
          if(formInbannerAdtagsData.length<=$scope.adSizeCount){
             formInbannerAdtagsData.push(formInbannerAdtagData);
          }
       }
       $scope.reloadRoute = function() {
           $state.reload();
       };
       if (creativeMappingId === undefined || creativeMappingId === null) {
          console.log('Saving New Creative');
          $scope.saveCreativesInbannerAdtagForm();
       }
//       else {
//          CreativeManagerInbannerAdtagService.updateCreativeInbannerAdtag(advertiserId,creativeMappingId, formAdtagsData).then(function (data) {
//             console.log(data);
//             $scope.reloadRoute();
//             //  init();
//          });
//       };
        $scope.submitButtonDisable = true
    };

    $scope.getAdvertiserTimezone = function(advertiserId){
      CreativeManagerInbannerAdtagService.advertiserTimezone(advertiserId).then(function (data) {
         console.log(data);
         $scope.currentUserTime = "";
         var offset = 0;
         $scope.advertiser = data;
         if($scope.advertiser != undefined) {
             $scope.currentUserTime = $scope.advertiser.currentTime;
             offset = new Date().getTimezoneOffset();
            $scope.adtag.startDate = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
         }
      });
    }

    document.addEventListener("DOMContentLoaded", function() {
       document.getElementById("process").addEventListener("click", function () {
          var $iframe = document.getElementById("iframe-render"),
              iframe, iframeDoc;
          if (!$iframe ) {
            iframe = document.createElement("iframe");
            iframe.id = "iframe-render";
            document.getElementById("adTagPreview").appendChild( iframe );
          } else {
            iframe = $iframe;
          }
          iframeDoc = iframe.contentWindow ?
                      iframe.contentWindow :
                      iframe.contentDocument.document ?
                        iframe.contentDocument.document :
                        iframe.contentDocument;
          iframeDoc.document.open();
          iframeDoc.document.write(
             document.getElementById("$index").value
          );
          iframeDoc.document.close();
       }, false);
    }, false);
  })
  .filter('hasIntersection', function() {
     return function(item, array) {
        return array.indexOf(item) >= 0;
     };
  })
  .filter('sanitizer',function($sce) {
     return function(content) {
        return $sce.trustAsHtml(content);
     };
  })

angular
    .module('adelementAdminApp')
    .controller('CreativeAdsPreviewController',['$scope','$rootScope','$state','$stateParams','creativeId',function($scope,$rootScope,$state,$stateParams,creativeId){
        function initControllerVariables(){
            $scope.creativeId = creativeId;
        }
        initControllerVariables();
    }]);

  angular
     .module('adelementAdminApp')
     .controller('CreativeAdtagPopupController',['$scope','$rootScope','$state','$stateParams','creativeAdtag','adsize',function($scope,$rootScope,$state,$stateParams,creativeAdtag,adsize){
         function initControllerVariables(){
             $scope.creativeAdtag = creativeAdtag;
             $scope.adsize = adsize;
         }
         initControllerVariables();
     }]);

  angular
      .module('adelementAdminApp')
      .directive('validateDatePicker', function () {
          function checkIfEmpty(dateValue) {
              return dateValue ? true : false;
          }
          function checkIfIsValidDate(adtag) {
              return adtag.startDate < adtag.endDate;
          }
          return {
              require: 'ngModel',
              link: function (scope, elem, attrs, ctrl) {
                  scope.$watch(attrs.ngModel, function () {
                      ctrl.$valid = true;
                      var isValid = checkIfEmpty(scope.adtag.startDate);
                      ctrl.$setValidity("required", isValid);
                      if (isValid) {
                          ctrl.$setValidity("shouldBeLessThanEndDate", checkIfIsValidDate(scope.searchParameters));
                      }
                  });
              }
          }
      })

  angular
      .module('adelementAdminApp')
      .directive('creativePreview',['CreativeManagerService', function (CreativeManagerService) {
          return {
              restrict: 'EA',
              scope: {
                  creativeid: '=creativeid',
                  scale: '=scale'
              },
              template:'<div ng-class="class"></div>',
              link: function (scope, element, attrs) {
                  scope.type = 0;
                  scope.type_vast = "VAST";
                  scope.type_adtag = "adtag";
                  scope.type_inbanner = "inbanner";
                  scope.haveBanner = false;
                  scope.checkAdType = function(type){
                      if(scope.data){
                          return scope.data.adType === type;
                      }
                      return false;
                  };
                  scope.$watch('creativeid',function(oldv,newv){
                     if(newv){
                        CreativeManagerService.getAdParamsById().then(function(response){
                            scope.adtagData = response.adtagData;
                            scope.vastTagData = response.vastTagData;
                            scope.adSizes = response.adSizes;
                            scope.data = response;
                            scope.adtagData.forEach(function(element) {
                                if(element.creativeId==newv){
                                    scope.creativeAdTag = element.creativeAdTag;
                                    scope.adtag = element;
                                }
                            });
                            scope.vastTagData.forEach(function(element) {
                                if(element.creativeId==newv){
                                    scope.creativeVastTag = element.creativeVastTag;
                                    scope.vastTag = element;
                                }
                            });

                            var scalefactor = 1;
                            var elm = element.children(0);
                            var iframetag = '<iframe scrolling="NO" FRAMEBORDER="0"></iframe>';
                            if(scope.creativeAdTag){
                                scope.iframesrc = 'data:text/html;base64, '+window.btoa(scope.creativeAdTag);
                                scope.adSizes.forEach(function(element) {
//                                    if(element[0]==scope.uploadAds.adUnitId ){
                                    if(element[0]==scope.adtag.adUnitId ){
                                        scope.data.width = element[1];
                                        scope.data.height = element[2];
                                    }
                                });
                            }
                            else if(scope.creativeVastTag){
                               scope.iframesrc = 'data:text/html;base64, '+window.btoa(scope.creativeVastTag);
                               scope.adSizes.forEach(function(element) {
                                   if(element[0]==scope.vastTag.adUnitId){
                                       scope.data.width = element[1];
                                       scope.data.height = element[2];
                                   }
                               });
                            }

                            if(scope.scale){
                                   scalefactor = Math.min(120/scope.data.width,100/scope.data.height);
                                   elm.css("width",120);
                                   elm.css("height",100);
                            }
                            scope.height = scope.data.height;
                            scope.width  = scope.data.width;
                            scope.class = "htmladthumb"+scope.width+"_"+scope.height;
                            elm.append(iframetag);
                            elm.css("overflow","hidden");
                            elm.children(0).css("width",scope.width);
                            elm.children(0).css("height",scope.height);
                            elm.children(0).css("transform","scale("+scalefactor+")");
                            elm.children(0).css("transform-origin","0 0");
                            elm.children(0).attr("src",scope.iframesrc);
                            elm.children(0).attr("class",scope.class);
                        })
                     }
                  })
              }
          }
      }])
      .directive('adtagPopup',['CreativeManagerInbannerAdtagService','CreativeManagerAdtagService', function (CreativeManagerInbannerAdtagService,CreativeManagerAdtagService) {
         return {
             restrict: 'EA',
             scope: {
                 creativeadtag: '=creativeadtag',
                 adsize: '=adsize',
                 scale: '=scale'
             },
             template:'<div ng-class="class"></div>',
             link: function (scope, element, attrs) {
                 scope.type = 0;
                 scope.haveBanner = false;
                 scope.checkAdType = function(type){
                     if(scope.data){
                         return scope.data.adType === type;
                     }
                     return false;
                 };
                 scope.$watch('creativeadtag',function(oldv,newv){
                   var scalefactor = 1;
                   scope.creativeAdTag = oldv;

//                   CreativeManagerInbannerAdtagService.getInbannerAdtagData().then(function(response){
//                      var adSizes = response.adSizes;
//                   });

                   scope.adsize = scope.adsize.label;
                   if (scope.adsize != undefined){
                       scope.adsize = scope.adsize.split('x');
                       scope.width = scope.adsize[0];
                       scope.height = scope.adsize[1];
                   }
                   else{
                       scope.adSizes.forEach(function(element) {
                           if(element.label==scope.adsize){
                               scope.width = element[1];
                               scope.height = element[2];
                           }
                       });
                   }

//                   adSizes.forEach(function(element) {
//                       if(element.label==scope.adtag.adUnitId ){
//                           scope.data.width = element[1];
//                           scope.data.height = element[2];
//                       }
//                   });

                   var elm = element.children(0);
                   var iframetag = '<iframe scrolling="NO" FRAMEBORDER="0"></iframe>';
                   scope.iframesrc = 'data:text/html;base64, '+window.btoa(scope.creativeAdTag);
                   if(scope.scale){
                      scalefactor = Math.min(120/scope.width,100/scope.height);
                      elm.css("width",120);
                      elm.css("height",100);
                   }
                   scope.class = "htmladthumb"+scope.width+"_"+scope.height;
                   elm.append(iframetag);
                   elm.css("overflow","hidden");
                   elm.children(0).css("width",scope.width);
                   elm.children(0).css("height",scope.height);
                   elm.children(0).css("transform","scale("+scalefactor+")");
                   elm.children(0).css("transform-origin","0 0");
                   elm.children(0).attr("src",scope.iframesrc);
                   elm.children(0).attr("class",scope.class);
                 })
             }
         }
      }])


// create creative upload ads controller
angular
    .module('adelementAdminApp')
    .directive('adImagePreview', function() {
      return {
        restrict: 'A',
        priority: 1001,
        scope: {
            source:'=source',
            index:'=index',
        },
        template: '<img ng-src="{{source}}"/>',
        link: function(scope, element, attrs) {
           scope.$watch('[source,index]',function(oldv,newv){
              if(oldv[0]){
                var iframe;
                var isIframe = document.getElementById("ifrm"+oldv[1])
                if(isIframe==null){
                    iframe = document.createElement('iframe');
                    iframe.setAttribute("id", "ifrm"+oldv[1]);
                    element[0].appendChild(iframe);
                }
                else{
                    isIframe.parentNode.removeChild(isIframe);
                    iframe = document.createElement('iframe');
                    iframe.setAttribute("id", "ifrm"+oldv[1]);
                    element[0].appendChild(iframe);
                }
                var doc = iframe.contentWindow.document;
                doc.open();
                doc.write(oldv[0]);
                doc.close();
              }
           });
        }
      };
    })
    .directive('ngFileModel', ['$parse','$rootScope','CreativeManagerUploadAdsService','$compile', function ($parse,$rootScope,CreativeManagerUploadAdsService,$compile) {
        return {
            restrict: 'A',
            link: function ($scope, element, attrs) {
                var model = $parse(attrs.ngFileModel);
                var isMultiple = attrs.multiple;
                var modelSetter = model.assign;
                $scope.isImageUpload = false;
                element.bind('change', function () {
                    var values = [];
                    $scope.correctImages = [];
                    $scope.faultyImages = [];
                    $scope.imagesInfo = [];
                    $scope.validatedImages = [];
                    $scope.verifiedImage = [];
//                    $scope.imagesSrc=[];
                    $scope.extensions=[".jpeg",".png",".jpg",".gif"];
                    var index = 0;
                    var adsCounter = 1;
                    $scope.adSizeCount = 1;
                    $scope.tabListCounter = 1;
//                    $scope.expandAdtagSize[index] = false;
//                    $scope.selectedAdSize[index] = null;
//                    $scope.imagesSrc[index] = null;
                    $scope.clickUrlDirectiveCall = false;
                    $scope.advertiserDomainDirectiveCall = false;
                    $scope.thirdPartyimpressionTrackingDirectiveCall = false;
//                    $scope.thirdPartyimpressionTracking= [];
                    $scope.fileLength = element[0].files.length;
                    CreativeManagerUploadAdsService.getUploadAdsData().then(function(categoriesdata){
                        $scope.isImageUpload = true;
                        $scope.adUnitSizes = angular.copy(categoriesdata.adSizes);
                        validateImages();
                    })
                    function validateImages(){
                        angular.forEach(element[0].files, function (item) {
                            var value = {
                               // File Name
                                name: item.name.replace(/ /g, "_"),
                                //File Size
                                size: Math.round(item.size/1000)+"KB",
                                //File URL to view
                                url: URL.createObjectURL(item),
                                // File Input Value
                                _file: item
                            };
                            var uploadImageData = item;
                            var filename = item.name.replace(/ /g, "_");
                            var indexFile = filename.lastIndexOf(".");

                            var image = new Image();
                            image.src = URL.createObjectURL(item);
                            var width;
                            var height;

                            image.onload = function() {
                                width = this.width;
                                height = this.height;
                                value.width = width;
                                value.height = height;
                                var imageValidation = false

                                var strsubstring = filename.substring(indexFile, filename.length);
                                var imageWidthHeight = value.width+"x"+value.height;
                                value.extension = strsubstring;
                                // size and extension validation
                                if(1000000>item.size){
                                    if(strsubstring == '.png' || strsubstring == '.jpeg' || strsubstring == '.jpg' || strsubstring == '.gif'){
                                        values.push(value);
                                        $scope.correctImages = values
                                        console.log(image.naturalWidth, image.naturalHeight);

                                        // dimension validation
                                        angular.forEach($scope.adUnitSizes, function (data) {
                                           if(data.label!="All"){
                                               $scope.adsize = data.label;
                                               $scope.adsize = $scope.adsize.split('x');
                                               $scope.width = $scope.adsize[0];
                                               $scope.height = $scope.adsize[1];
                                               if(value.width == $scope.width && $scope.height==value.height){
                                                   imageValidation = true;
                                                   value.adUnitSize = data;
                                                   value.adsize = imageWidthHeight;
                                                   value.status = "Verified";
                                                   value.sizeStatus = true;
                                                   value.dimensionStatus = true;
                                                   value.formatStatus = true;
                                                   $scope.imagesSrc.push(value.url);
                                                   $scope.imageFilename.push(value.name);
                                                   $scope.imagesInfo.push(value);
                                                   uploadValidatedImages(value);
                                                   $scope.uploadFile.push(uploadImageData);
                                               }
                                           }
                                        });
                                        if(!imageValidation){

                                           var found = $scope.adUnitSizes.some(function (el) {
                                             return el.label === imageWidthHeight;
                                           });

                                           if(!found){
                                               value.status = "Dimension Verification Failed"
                                               value.adsize = imageWidthHeight;
                                               value.statusReason = imageWidthHeight;                                                     value.sizeStatus = true;
                                               value.dimensionStatus = false;
                                               value.formatStatus = true;
                                               $scope.faultyImages.push(value);
                                           }
                                        }
                                        $scope.correctImages = $scope.imagesInfo;

                                    }
                                    else{
                                       var found = $scope.adUnitSizes.some(function (el) {
                                         return el.label === imageWidthHeight;
                                       });

                                       if(!found && $scope.extensions.indexOf(strsubstring)==-1){
                                           value.status = "Extension and Dimension Verification Failed";
                                           value.statusReason = "Extension-"+strsubstring+", Dimension-"+imageWidthHeight;
                                           value.sizeStatus = true;
                                           value.dimensionStatus = false;
                                           value.formatStatus = false;
                                       }
                                       else{
                                           value.status = "Extension Verification Failed";
                                           value.statusReason = strsubstring;
                                           value.sizeStatus = true;
                                           value.dimensionStatus = true;
                                           value.formatStatus = false;
                                        }
                                        value.adsize = imageWidthHeight;
                                        $scope.faultyImages.push(value);
                                    }
                                }
                                else{
                                   var found = $scope.adUnitSizes.some(function (el) {
                                     return el.label === imageWidthHeight;
                                   });

                                    if(1000000<item.size && !$scope.extensions.indexOf(strsubstring) && !found){
                                        value.status = "Size,Dimension and Extension Verification Failed";
                                        value.statusReason = "Size-"+Math.round(item.size/1000)+"KB, Extension-"+strsubstring+", Dimension-"+imageWidthHeight;
                                           value.sizeStatus = false;
                                           value.dimensionStatus = false;
                                           value.formatStatus = false;
                                    }
                                    else if(1000000<item.size && !found){
                                         value.status = "Size and Dimension Verification Failed";
                                         value.statusReason = "Size-"+Math.round(item.size/1000)+"KB, Dimension-"+imageWidthHeight;
                                           value.sizeStatus = false;
                                           value.dimensionStatus = false;
                                           value.formatStatus = true;
                                     }
                                    else if(1000000<item.size && !$scope.extensions.indexOf(strsubstring)){
                                         value.status = "Size and Extension Verification Failed";
                                         value.statusReason = "Size-"+Math.round(item.size/1000)+"KB, Extension-"+strsubstring;
                                           value.sizeStatus = false;
                                           value.dimensionStatus = true;
                                           value.formatStatus = false;
                                         }
                                    else{
                                        value.status = "Size Verification Failed";
                                        value.statusReason = Math.round(item.size/1000)+"KB";
                                        value.sizeStatus = false;
                                        value.dimensionStatus = true;
                                        value.formatStatus = true;
                                    }
                                    value.adsize = imageWidthHeight;
                                    $scope.faultyImages.push(value);
                                }
                            };
                        });
                    }

                    function uploadValidatedImages(imageInfo){
                        $scope.verifiedImage.push(imageInfo)
                        var imagesInfo = $scope.imagesInfo;
                        var faultyImages = $scope.faultyImages;
                        $scope.tabListCounter = adsCounter;
                        $scope.selectedAdSize[index] = imageInfo.adUnitSize.value;
                        $scope.getAdSizes(imageInfo.adUnitSize,index);
                        index+=1;
                        adsCounter+=1;
                        $scope.adSizeCount = adsCounter;
                    }

                    // getAdSizes() take the selected AdSize and joins it with creative name for adtag Size creative name
                    $scope.getAdSizes = function(selectedAdSize,index){
                        $scope.adSizeCall = true;
                        if($scope.creativeName){
                            if (typeof selectedAdSize != "undefined"){
                //                $scope.selectedInbannerAdsizes[index]=selectedAdSize;
                                $scope.currentIndex = index;
                                if(!$scope.isEditCreative){
                                    $scope.expandAdtagSize[index] = true;
                                    $scope.creativeNameUploadAd[index] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                                    if($scope.advertiserDomain){
                                        $scope.advertiserDomainDirectiveCall = true;
                                        $scope.setAdvertiserDomain($scope.advertiserDomain,index);
                                    }
                                    if($scope.clickUrlUploadAd){
                                        $scope.clickUrlDirectiveCall = true;
                                        $scope.setClickUrl($scope.clickUrlUploadAd,index);
                                    }
                                    if($scope.uploadAds.startDate){
                                        $scope.setStartDate($scope.uploadAds.startDate);
                                    }
                                    if($scope.uploadAds.endDate){
                                        $scope.setEndDate($scope.uploadAds.endDate);
                                    }
//                                    if($scope.thirdPartyimpressionTracking.length>0){
                                      if($scope.impressionTrackingPixel){
                                        $scope.thirdPartyimpressionTrackingDirectiveCall = true;
                                        $scope.setImpressionTrackingPixel($scope.thirdPartyimpressionTracking, index);
                                    }
                                }
                            }
                        }
                    }

                    if($scope.imagesInfo.length>0 && $scope.faultyImages.length==0){
                        $scope.uploadBtn = true;
                    }
                    $scope.$apply(function () {
                        if (isMultiple) {
                            modelSetter($scope, values);
                        } else {
                            modelSetter($scope, values[0]);
                        }
                    });
                });

                $scope.clickHere = function(){
                    $scope.imagesInfoShow = true;
                };
            }
        };
    }])
   .directive('fileModel', ['$parse','$rootScope','CreativeManagerUploadAdsService','$compile', function ($parse,$rootScope,CreativeManagerUploadAdsService,$compile) {
        return {
            restrict: 'A',
            link: function($scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function(){

                    var values = [];
                    $scope.correctImages = [];
                    $scope.faultyImages = [];
                    $scope.imagesInfo = [];
                    $scope.validatedImages = [];
                    $scope.verifiedImage = [];
                    $scope.extensions=[".jpeg",".png",".jpg",".gif"];
                    var index = 0;
                    var adsCounter = 1;
                    $scope.adSizeCount = 1;
                    $scope.tabListCounter = 1;

                    $scope.clickUrlDirectiveCall = false;
                    $scope.advertiserDomainDirectiveCall = false;
                    $scope.thirdPartyimpressionTrackingDirectiveCall = false;

                    $scope.fileLength = element[0].files.length;
                    CreativeManagerUploadAdsService.getUploadAdsData().then(function(categoriesdata){
                        $scope.isImageUpload = true;
                        $scope.adUnitSizes = angular.copy(categoriesdata.adSizes);
                        validateImages();
                    })
                    function validateImages(){
                        angular.forEach(element[0].files, function (item) {
                            var value = {
                               // File Name
                                name: item.name.replace(/ /g, "_"),
                                //File Size
                                size: Math.round(item.size/1000)+"KB",
                                //File URL to view
                                url: URL.createObjectURL(item),
                                // File Input Value
                                _file: item
                            };
                            var uploadImageData = item;
                            var filename = item.name.replace(/ /g, "_");
                            var indexFile = filename.lastIndexOf(".");

                            var image = new Image();
                            image.src = URL.createObjectURL(item);
                            var width;
                            var height;

                            image.onload = function() {
                                width = this.width;
                                height = this.height;
                                value.width = width;
                                value.height = height;
                                var imageValidation = false

                                var strsubstring = filename.substring(indexFile, filename.length);
                                var imageWidthHeight = value.width+"x"+value.height;
                                value.extension = strsubstring;
                                // size and extension validation
                                if(1000000>item.size){
                                    if(strsubstring == '.png' || strsubstring == '.jpeg' || strsubstring == '.jpg' || strsubstring == '.gif'){
                                        values.push(value);
                                        $scope.correctImages = values
                                        console.log(image.naturalWidth, image.naturalHeight);

                                        // dimension validation
                                        angular.forEach($scope.adUnitSizes, function (data) {
                                           if(data.label!="All"){
                                               $scope.adsize = data.label;
                                               $scope.adsize = $scope.adsize.split('x');
                                               $scope.width = $scope.adsize[0];
                                               $scope.height = $scope.adsize[1];
                                               if(value.width == $scope.width && $scope.height==value.height){
                                                   imageValidation = true;
                                                   value.adUnitSize = data;
                                                   value.adsize = imageWidthHeight;
                                                   value.status = "Verified";
                                                   value.sizeStatus = true;
                                                   value.dimensionStatus = true;
                                                   value.formatStatus = true;
//                                                   $scope.imagesSrc.push(value.url);
                                                   $scope.replacedImageSrc = value.url;
                                                   $scope.imageFilename.push(value.name);
                                                   $scope.imagesInfo.push(value);
                                                   $scope.uploadUpdatedFile.push(uploadImageData)
                                                   uploadValidatedImage(value,value.url);
//                                                   $scope.uploadFile.push(uploadImageData);

                                               }
                                           }
                                        });
                                        if(!imageValidation){

                                           var found = $scope.adUnitSizes.some(function (el) {
                                             return el.label === imageWidthHeight;
                                           });

                                           if(!found){
                                               value.status = "Dimension Verification Failed"
                                               value.adsize = imageWidthHeight;
                                               value.statusReason = imageWidthHeight;                                                             value.sizeStatus = true;
                                               value.dimensionStatus = false;
                                               value.formatStatus = true;
                                               $scope.faultyImages.push(value);
                                               alert("Dimension Verification Failed. image width height-"+imageWidthHeight)
                                           }
                                        }
                                        $scope.correctImages = $scope.imagesInfo;

                                    }
                                    else{
                                       var found = $scope.adUnitSizes.some(function (el) {
                                         return el.label === imageWidthHeight;
                                       });

                                       if(!found && $scope.extensions.indexOf(strsubstring)==-1){
                                           value.status = "Extension and Dimension Verification Failed";
                                           value.statusReason = "Extension-"+strsubstring+", Dimension-"+imageWidthHeight;
                                           alert("Extension and Dimension Verification Failed. Extension-"+strsubstring+", Dimension-"+imageWidthHeight);
                                           value.sizeStatus = true;
                                           value.dimensionStatus = false;
                                           value.formatStatus = false;
                                       }
                                       else{
                                           value.status = "Extension Verification Failed";
                                           alert("Extension Verification Failed. Extension-"+strsubstring);
                                           value.statusReason = strsubstring;
                                           value.sizeStatus = true;
                                           value.dimensionStatus = true;
                                           value.formatStatus = false;
                                        }
                                        value.adsize = imageWidthHeight;
                                        $scope.faultyImages.push(value);
                                    }
                                }
                                else{
                                   var found = $scope.adUnitSizes.some(function (el) {
                                     return el.label === imageWidthHeight;
                                   });

                                    if(1000000<item.size && !$scope.extensions.indexOf(strsubstring) && !found){
                                        value.status = "Size,Dimension and Extension Verification Failed";
                                        value.statusReason = "Size-"+Math.round(item.size/1000)+"KB, Extension-"+strsubstring+", Dimension-"+imageWidthHeight;
                                        alert("Size,Dimension and Extension Verification Failed. Size-"+Math.round(item.size/1000)+"KB, Extension-"+strsubstring+", Dimension-"+imageWidthHeight);
                                        value.sizeStatus = false;
                                        value.dimensionStatus = false;
                                        value.formatStatus = false;
                                    }
                                    else if(1000000<item.size && !found){
                                         value.status = "Size and Dimension Verification Failed";
                                         value.statusReason = "Size-"+Math.round(item.size/1000)+"KB, Dimension-"+imageWidthHeight;
                                         alert("Size and Dimension Verification Failed. Size-"+Math.round(item.size/1000)+"KB, Dimension-"+imageWidthHeight);
                                           value.sizeStatus = false;
                                           value.dimensionStatus = false;
                                           value.formatStatus = true;
                                     }
                                    else if(1000000<item.size && !$scope.extensions.indexOf(strsubstring)){
                                         value.status = "Size and Extension Verification Failed";
                                         value.statusReason = "Size-"+Math.round(item.size/1000)+"KB, Extension-"+strsubstring;
                                         alert("Size and Extension Verification Failed. Size - "+Math.round(item.size/1000)+"KB, Extension-"+strsubstring);
                                           value.sizeStatus = false;
                                           value.dimensionStatus = true;
                                           value.formatStatus = false;
                                         }
                                    else{
                                        value.status = "Size Verification Failed";
                                        value.statusReason = Math.round(item.size/1000)+"KB";
                                        alert("Size Verification Failed. Size - " + value.statusReason);
                                        value.sizeStatus = false;
                                        value.dimensionStatus = true;
                                        value.formatStatus = true;
                                    }
                                    value.adsize = imageWidthHeight;
                                    $scope.faultyImages.push(value);
                                }
                            };
                        });
                    }

                    function uploadValidatedImage(imageInfo, imageUrl){
//                        $scope.verifiedImage.push(imageInfo)
                        var imagesInfo = $scope.imagesInfo;
                        var faultyImages = $scope.faultyImages;
//                        $scope.tabListCounter = adsCounter;
                        $scope.changeImage(imageInfo, imageUrl);
//                        $scope.selectedAdSize[index] = imageInfo.adUnitSize.value;
//                        $scope.getAdSizes(imageInfo.adUnitSize,index);
//                        index+=1;
//                        adsCounter+=1;
//                        $scope.adSizeCount = adsCounter;
                    }

                    // getAdSizes() take the selected AdSize and joins it with creative name for adtag Size creative name
                    $scope.getAdSizes = function(selectedAdSize,index){
                        $scope.adSizeCall = true;
                        if($scope.creativeName){
                            if (typeof selectedAdSize != "undefined"){
                                $scope.currentIndex = index;
                                if(!$scope.isEditCreative){
                                    $scope.expandAdtagSize[index] = true;
                                    $scope.creativeNameUploadAd[index] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                                    if($scope.advertiserDomain){
                                        $scope.advertiserDomainDirectiveCall = true;
                                        $scope.setAdvertiserDomain($scope.advertiserDomain,index);
                                    }
                                    if($scope.clickUrlUploadAd){
                                        $scope.clickUrlDirectiveCall = true;
                                        $scope.setClickUrl($scope.clickUrlUploadAd,index);
                                    }
                                    if($scope.uploadAds.startDate){
                                        $scope.setStartDate($scope.uploadAds.startDate);
                                    }
                                    if($scope.uploadAds.endDate){
                                        $scope.setEndDate($scope.uploadAds.endDate);
                                    }
                                    if($scope.impressionTrackingPixel){
                                        $scope.thirdPartyimpressionTrackingDirectiveCall = true;
                                        $scope.setImpressionTrackingPixel($scope.thirdPartyimpressionTracking, index);
                                    }
                                }
                            }
                        }
                    }

                    $scope.$apply(function(){
                        modelSetter($scope, element[0].files[0]);
                    });
                });
            }
        };
   }])

  .directive('adImagePopup',['CreativeManagerUploadAdsService','$timeout', function (CreativeManagerUploadAdsService,$timeout) {
     return {
         restrict: 'EA',
         scope: {
             imageurl: '=imageurl',
             adsize: '=adsize',
             scale: '=scale'
         },
         template:'<div ng-class="class"></div>',
         link: function ($scope, element, attrs) {
             $scope.type = 0;
             $scope.haveBanner = false;
             $scope.checkAdType = function(type){
                 if($scope.data){
                     return $scope.data.adType === type;
                 }
                 return false;
             };
             $scope.$watch('imageurl',function(oldv,newv){
                var scalefactor = 1;
                $scope.imageurl = oldv;

                var adSizes = [];

                var img = new Image();
                img.src = $scope.imageurl;
                img.onload = function() {
                   $scope.width = this.width;
                   $scope.height = this.height;
                }

                var elm = element.children(0);
                var iframetag = '<iframe scrolling="NO" FRAMEBORDER="0"></iframe>';
//                $scope.iframesrc = 'data:text/html;base64, '+window.btoa($scope.imageurl);
                $scope.iframesrc = $scope.imageurl;
                if($scope.scale){
                   scalefactor = Math.min(120/$scope.width,100/$scope.height);
                   elm.css("width",120);
                   elm.css("height",100);
                }
                $scope.class = "htmladthumb"+$scope.width+"_"+$scope.height;
                elm.append(iframetag);
                elm.css("overflow","hidden");
                elm.children(0).css("width",$scope.width);
                elm.children(0).css("height",$scope.height);
                elm.children(0).css("transform","scale("+scalefactor+")");
                elm.children(0).css("transform-origin","0 0");
                elm.children(0).attr("src",$scope.iframesrc);
                elm.children(0).attr("class",$scope.class);
             })
         }
     }
  }])
   .controller('CreativeAdImagePopupController',['$scope','$rootScope','$state','$stateParams','imageUrl','adsize',function($scope,$rootScope,$state,$stateParams,imageUrl,adsize){
           function initControllerVariables(){
               $scope.imageUrl = imageUrl;
               $scope.adsize = adsize;
           }
           initControllerVariables();
       }])
    .controller('CreativeManagerUploadAdsCreativeController', function ($scope,$stateParams,$state,$window,$http,$compile,CreativeManagerService,$filter,$rootScope,GetAdvertiserIDName,$timeout,$sanitize, $sce,ngDialog,CreativeManagerAdtagService,CreativeManagerUploadAdsService,advertiser) {
        $scope.uploadAds={};
        $scope.categories = [];
        $scope.categoriesAdtag = [];
        $scope.sensitiveAttributes = [];
        $scope.technicalAttributes = [];
        $scope.subCategories = [];
        $scope.countryLanguages = [];
        $scope.countryLanguagesAdtag = [];
        $scope.categoryNames = [];
        $scope.destinationUrl="";
        $scope.advertiser = advertiser;
        $scope.advertiserDomain="";
        $scope.adSizeCount=1;
        $scope.creativeNameUploadAd = [];
        $scope.callbacks=false;
        $scope.allAdvertisersIdName = [];
        $scope.selectedCountryLanguagesAdtag = [];
        $scope.sensitiveAttributeNames = [];
        $scope.technicalAttributeNames = [];
        $scope.selectedTechnicalAttribute=[];
        $scope.selectedSentitiveAttribute=[];
        $scope.selectedTechnicalAttributeAdtag = []
        $scope.selectedSensitiveAttributeAdtag = [];
        $scope.destinationUrlAdtag = [];
        $scope.advertiserDomainAdtag = [];
        $scope.impressionTrackingPixelAdtag = [];
        $scope.ibvAdStartCallbackAdtag = [];
        $scope.ibvAdCompleteCallbackAdtag = [];
        $scope.ibvAdClicktrackCallbackAdtag = [];
        $scope.selectedAdSizesList=[];
        $scope.subCategoryNames = [];
        $scope.selectedSubCategories = [];
        $scope.selectedCategoryAdtag = [];
        $scope.selectedSubCategoriesAdtag = [];
        $scope.text1 = [];
        $scope.subCategoriesdataAdtag = [];
        $scope.selectedAdSize=[];
        $scope.selectedAdtagAdSizes=[];
        $scope.subCategoriesAdtag = [];
        $scope.previewText=[];
        $scope.selectedCreativeAdSize=[];
        $scope.selectedCreativeSize=[];
        $scope.expandAdtagSize = [];
        var formAdtagData = {};
        $scope.isAdvertiserId = false;
        var creativeId;
        var advertiserId;
        $scope.selectedCreativeAdSizeExist = [];
        $scope.selectedSize= false;
        $scope.showSubCategories = false
        $scope.subCategoryAdtagTouched = [];
        $scope.adSizeSelectionDisable = true;
        $scope.categoryAdtagTouched = [];
        $scope.countryLanguagesAdtagTouched = [];
        $scope.destinationUrlAdtagTouched = false;
        $scope.clickUrlUploadAdsTouched = false;
        $scope.advertiserDomainAdtagTouched = false;
        $scope.technicalAttributeAdtagTouched = false;
        $scope.sensitiveAttributeAdtagTouched = false;
        $scope.technicalAttributeTouched = false;
        $scope.sensitiveAttributeTouched = false;
        $scope.impressionTrackingPixel = false;
        $scope.submitButtonDisable = false;
        $scope.thirdPartyimpressionTracking = [];
        $scope.thirdPartyimpressionTrackingAdTouched = false;
        $scope.thirdPartyimpressionTrackingDirectiveCall = false;
        $scope.imageBroswerTouched = false;
        $scope.imageFilename = [];
        $scope.thirdPartyMacrosEnable = [];
        $scope.clickUrl = [];
        $scope.cacheBusterBtn = [];
        $scope.disableStartDate = {};
        $scope.formDataUploadAds = new FormData();
        var formUploadAdsData = [];

        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.startDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            minDate:moment()
        };
        $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.adSizes=[];
        $scope.creativeId=[];
        $scope.thirdPartyimpressionTrackingAd = [];
        $scope.adSizesCounter=0;
        $scope.startDateOpened = false;
        $scope.endDateOpened = false;
        $scope.sliceCount = 0
        $scope.currentIndex = 0
        $scope.startDateTouched = false;
        $scope.endDateTouched = false;
        $scope.startDateAdtagOpened = [];
        $scope.endDateAdtagOpened = [];
        var creativeMappingId;
        $scope.platformSelection = false;
        $scope.uploadBtn = false;
        $scope.formAdtagData = {};
        $scope.clickUrlUploadAd = "";
        $scope.clickUrlUploadAds = [];
        $scope.uploadFile = [];
        $scope.uploadUpdatedFile = [];
        $scope.thirdPartyTrackingCount = 1;
        $scope.thirdPartyimpressionTrackingPixel = [];
        $scope.seletedtechnicalAttribute = {
            technicalAttributes:[]
        }
        $scope.seletedsensitiveAttribute = {
            sensitiveAttributes:[]
        }
        $scope.seletedtechnicalAttribute = {
            technicalAttributesAdtag:[]
        }
        $scope.seletedsensitiveAttribute = {
            sensitiveAttributesAdtag:[]
        }
        $scope.initDate = new Date();

        $scope.currentUserTime ="";
        var offset = 0;
        if($scope.advertiser != undefined) {
            $scope.currentUserTime = $scope.advertiser.currentTime;
            offset = new Date().getTimezoneOffset();
        }

        $scope.uploadAds = {};

        $scope.startDateAdtagSize = [];
        $scope.endDateAdtagSize = [];
        $scope.imagesSrc = [];
        $scope.replacedImageSrc = "";
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.copyText = function(text1,index){
            $scope.text1[index]= text1;
            $scope.previewText[index] =  $scope.text1[index];
        }

        $scope.platform = {
           desktopWeb: false,
           mobileWeb: false,
           app: false
        };

        $scope.macrotype = {
            adserverMacros: false,
            rtbMacros: false
        };


        $scope.$watchCollection('[imagesInfo,faultyImages]', function () {
            if($scope.imagesInfo){
                $scope.correctImages = $scope.imagesInfo;
            }
            if($scope.imagesInfo && $scope.faultyImages){
                if($scope.imagesInfo.length>0 && $scope.faultyImages.length==0){
                    $scope.uploadBtn = true;
                }
            }
        });

        $scope.$watchCollection('platform', function () {
          $scope.selectedPlatform = [];
          $scope.selectedMacrosTypeList = [];
          $scope.platformCount = 0;
          angular.forEach($scope.platform, function (value, key) {
            if (value) {
              $scope.selectedPlatform.push(key);
              $scope.platformSelection = true;
              $scope.platformCount+=1;
            }
          });
            if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
                angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                  angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                        if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                          }
                        }
                        else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                    else if($scope.selectedPlatform.length>1){
                        if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                  });
                });
             } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
               angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                 angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                       if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                         if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                         }
                       }
                       else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                    else if($scope.selectedPlatform.length>1){
                       if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                 });
               });
             }
             else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
                  angular.forEach($scope.selectedPlatform, function (data) {
     //                console.log(data)
                    angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                       if($scope.selectedPlatform.length==1){
                          if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                            if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                            }
                          }
                          else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                       else if($scope.selectedPlatform.length>1){
                          if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                    });
                 });
             }
        });


        $scope.isStartDateOpen = false;
        $scope.isEndDateOpen = false;
        $scope.isStartDateAdtagOpen = [];
        $scope.isEndDateAdtagOpen = [];

        $scope.openCalendar = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index == -1){
                    $scope.isStartDateOpen = true;
                }
            }
            else{
                if(index == -1){
                    $scope.isEndDateOpen = true;
                }
            }
        };

        $scope.openCalendarAdtagSize = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index > -1){
                    $scope.isStartDateAdtagOpen[index] = true;
                }
            }
            else{
                if(index > -1){
                    $scope.isEndDateAdtagOpen[index] = true;
                }
            }
        };

        $scope.getMacros = function(){
            $scope.selectedMacrosTypeList=[];
            $scope.macros = true;
          if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
              angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
            angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
              if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
            });
          });
       } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
         angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
           angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
             if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
               if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
               }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
           });
         });
       }
       else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
            angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
              angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                  if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                  }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
              });
            });
          }
          else{
            $scope.macros = false;
          }
        }


        // get all the advertisers
        var advertiserInfoPromise =  GetAdvertiserIDName.query();
        advertiserInfoPromise.$promise.then(function(response){
             $scope.allAdvertisersIdName = response;
             if($scope.stateDecide){
                $scope.selectedAdvertiser = parseInt($stateParams.advertiserId);
             }
        });

        // set creativename()
        $scope.setCreativeName = function(creativeName){
            $scope.adSizeSelectionDisable = false;
            //   $scope.creativeName = creativeName;
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                if($scope.selectedAdSize.length==0){
                // $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName);
                // $scope.creativeNameAdtag[i] = angular.copy($scope.creativeName+" - "+$scope.selectedAdSize);
                }
            }
        }

        // set creative name to respective creativename of uploadAds size
        $scope.setCreativeNameAdtag = function(creativeNameUploadAd,index){
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                if($scope.selectedAdSize[i] == $scope.selectedAdSize[index]){
                    $scope.creativeNameUploadAd[i] = angular.copy(creativeNameUploadAd[index]);
                }
            }
        }

        // setDestinationUrl copies the destination url to all the selected adsizes
        $scope.setDestinationUrl = function(destinationUrl){
            if(!$scope.destinationUrlAdtagTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.destinationUrlAdtag[i]= angular.copy($scope.destinationUrl);
                }
            }
        }

        // set the destination url of uploadAds size
        $scope.setDestinationUrlAdtag = function(destinationUrlAdtag,index){
            $scope.destinationUrlAdtagTouched = true;
            $scope.destinationUrlAdtag[index] = angular.copy(destinationUrlAdtag[index]);
        }

        // setClickUrl copies the click url to all the selected adsizes
        $scope.setClickUrl = function(clickUrl,index){
            if($scope.clickUrlDirectiveCall){
                if(!$scope.clickUrlUploadAdsTouched){
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.clickUrlUploadAds[i]= angular.copy($scope.clickUrlUploadAd);
                    }
                }
            }
            if($scope.imageBroswerTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.clickUrlUploadAds[i]= angular.copy($scope.clickUrlUploadAd);
                }
            }

        }

        // set the destination url of uploadAds size
        $scope.setClickUrlAdtag = function(clickUrlUploadAds,index){
            $scope.clickUrlUploadAdsTouched = true;
            $scope.clickUrlUploadAds[index] = angular.copy(clickUrlUploadAds[index]);
        }

        // setAdvertiserDomain copies the destination url to all the selected adsizes
        $scope.setAdvertiserDomain = function(advertiserDomain, index){
            if($scope.advertiserDomainDirectiveCall){
                if(!$scope.advertiserDomainAdtagTouched){
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.advertiserDomainAdtag[i]= angular.copy($scope.advertiserDomain);
                    }
                }
            }
            if($scope.imageBroswerTouched){
                for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                    $scope.advertiserDomainAdtag[i]= angular.copy($scope.advertiserDomain);
                }
            }
        }

        // set the setAdvertiserDomain of uploadAds size
        $scope.setAdvertiserDomainAdtag = function(advertiserDomainAdtag,index){
            $scope.advertiserDomainAdtagTouched = true;
            $scope.advertiserDomainAdtag[index] = angular.copy(advertiserDomainAdtag[index]);
        }

        // getBinder binds the text from textarea as html of preview
        $scope.getBinder = function(){
            $scope.previewText = $sce.trustAsHtml($scope.text1);
        }

        function initUploadAd() {
//            $scope.uploadAds.startDate = $filter('date')(moment().toDate(), 'yyyy-MM-dd HH:mm:ss');
//            $scope.uploadAds.endDate = $filter('date')(moment().add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss');
//            $scope.uploadAds.startDate = moment($scope.currentUserTime).add(10+offset,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
            $scope.uploadAds.startDate = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.uploadAds.endDate = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
            $scope.uploadAds.startDateAdtag = moment($scope.currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.uploadAds.endDateAdtag = moment($scope.currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
        }
//        initAdtag();
//        init();

        // initialize the services
        function initServices() {
            CreativeManagerUploadAdsService.getUploadAdsData().then(function(categoriesdata){
                $scope.categories = angular.copy(categoriesdata.contentCategories);
                $scope.categoriesAdtag = angular.copy(categoriesdata.contentCategories);
                if(!$scope.subCategories.length){
                    $scope.subCategories = angular.copy(categoriesdata.contentSubcategories);
                }
//                $scope.subCategoriesAdtag = angular.copy(categoriesdata.contentSubcategories);
                $scope.technicalAttributes = angular.copy(categoriesdata.creativeTechnicalAttributes);
                $scope.sensitiveAttributes = angular.copy(categoriesdata.creativeSensitiveAttributes);
                $scope.countryLanguages = angular.copy(categoriesdata.countryLanguages);
                $scope.thirdPartyNamesList = angular.copy(categoriesdata.thirdPartyNames);
                $scope.thirdPartyMacrosList = angular.copy(categoriesdata.thirdPartyMacros);
                $scope.countryLanguagesAdtag = angular.copy(categoriesdata.countryLanguages);
                var adSizes = angular.copy(categoriesdata.adSizes);
//                $scope.adSizes[$scope.adSizeCount-1] = angular.copy(adSizes.slice(1));

                if(!$scope.adSizes.length && $scope.sliceCount==0){
                    $scope.adSizes = angular.copy(categoriesdata.adSizes.slice(1));
                    $scope.sliceCount +=1;
                }
//                $scope.totalAdSizes = angular.copy(adSizes.slice(1));
                $scope.subCategoriesdata = angular.copy($scope.subCategories);
            });
        }

        // init
        function init(){
            $scope.thirdPartyimpressionTrackingPixel.push({thirdPartyimpressionTrackingPixel : " "});
           if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("creativeMappingId") && $stateParams.creativeMappingId != undefined){
               $scope.isAdvertiserId = false;
               $scope.isEditCreative = true;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               creativeMappingId = $stateParams.creativeMappingId;
               $scope.creativeMappingId = creativeMappingId;
//               $scope.endDateTouched = true;
           }
           else if($stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeMappingId") && $stateParams.advertiserId != undefined){
               $scope.isAdvertiserId = false;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               $scope.isEditCreative = false;
           }
           else if(!$stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeMappingId") && $stateParams.advertiserId == undefined){
               $scope.isAdvertiserId = true;
               $scope.isEditCreative = false;
           }
           else{
              $scope.isAdvertiserId = false;
              $scope.isEditCreative = false;
           }
           // creative in uploadAds edit mode
           if($scope.isEditCreative){
               $scope.isAdvertiserId = false;
               $scope.disableAdsize={};
               $scope.callbacks=true;
               CreativeManagerUploadAdsService.getCreativeUploadAd(advertiserId,creativeMappingId).then(function(creativeUploadAdData){

                   for(var index=0;index<Object.keys(creativeUploadAdData).length;index++){
                       $scope.creativeAllDetails = angular.copy(creativeUploadAdData[index]);
                       $scope.showSubCategories = true
                       $scope.creativeFormUploadAdData = $scope.creativeAllDetails;
                       //  $scope.adSizeCount = $scope.creativeFormUploadAdData.adSizeCount;
                       $scope.tabListCounter = Object.keys(creativeUploadAdData).length;
//                       $scope.subCategoriesAdtag[index] = $scope.creativeFormUploadAdData.contentSubcategories;
                       $scope.disableAdsize[index]=true;
                       $scope.creativeId[index] = $scope.creativeFormUploadAdData.creativeId;


                       if(index==0){
                           if($scope.creativeFormUploadAdData.thirdPartyTrackers.length>0){
                               for (var i = 0, len = $scope.creativeFormUploadAdData.thirdPartyTrackers.length; i < len; i++) {
                                   if($scope.thirdPartyimpressionTrackingPixel.length<$scope.creativeFormUploadAdData.thirdPartyTrackers.length){
                                       $scope.thirdPartyimpressionTrackingPixel.push({thirdPartyimpressionTrackingPixel : " "});
                                   }
                                   if(!$scope.thirdPartyimpressionTrackingAd[index]){
                                       $scope.thirdPartyimpressionTrackingAd[index] = new Array();
                                   }
                                   $scope.thirdPartyimpressionTracking[i] = $scope.creativeFormUploadAdData.thirdPartyTrackers[i].thirdPartyTracker;
    //                           $scope.thirdPartyimpressionTrackingAd[index] = $scope.creativeFormUploadAdData.thirdPartyTrackers;
                               }
                           }
//                           $scope.thirdPartyimpressionTracking = $scope.creativeFormUploadAdData.thirdPartyTrackers;

                           $scope.clickUrlUploadAd = $scope.creativeFormUploadAdData.clickUrlUploadAds;
//                           $scope.thirdPartyMacrosEnable[index] = !$scope.impressionTrackingPixel;
//                           if(!$scope.thirdPartyMacrosEnable[index]){
//                              $scope.clickUrl[index] = true;
//                              $scope.cacheBusterBtn[index] = true;
//                           }
                           $scope.countryLanguages = $scope.creativeFormUploadAdData.countryLanguages;
                           $scope.countryLanguagesAdtag = $scope.creativeFormUploadAdData.countryLanguages;
                           $scope.categories = $scope.creativeFormUploadAdData.contentCategories;
                           $scope.categoriesAdtag = $scope.creativeFormUploadAdData.contentCategories;
//                           $scope.adtagSizes = angular.copy($scope.creativeFormUploadAdData.adSizes.slice(1));
                           $scope.adSizes = angular.copy($scope.creativeFormUploadAdData.adSizes.slice(1));
//                          $scope.subCategories = $scope.creativeFormUploadAdData.contentSubcategories;
                            $scope.subCategoriesAdtagData = $scope.creativeFormUploadAdData.contentSubcategories;
                            $scope.ibvAdStartCallback = $scope.creativeFormUploadAdData.ibvAdStartCallback;
                            $scope.ibvAdCompleteCallback = $scope.creativeFormUploadAdData.ibvAdCompleteCallback;
                            $scope.ibvAdClicktrackCallback = $scope.creativeFormUploadAdData.ibvAdClickTrackCallback;
                            if(!$scope.adSizes.length){
                                $scope.adSizes = angular.copy($scope.creativeFormUploadAdData.adSizes.slice(1));
                            }
                       }

                       var creativeName=$scope.creativeFormUploadAdData.creativeNameUploadAd.split('-');
                       var creativeAdTagName=$scope.creativeFormUploadAdData.creativeNameUploadAd.slice(0,-7);
                       var creativeTagName = $scope.creativeFormUploadAdData.creativeNameUploadAd.replace(creativeName.slice(-1),"");
                       creativeTagName = creativeTagName.replace(/-$/, '');
                       $scope.creativeName= creativeTagName;
                       $scope.creativeAdSize= creativeName[1].trim();
                       if($scope.creativeFormUploadAdData.platform){
                           var platformArray = $scope.creativeFormUploadAdData.platform.split(',');
                           $scope.selectedPlatform.push(platformArray);
                           angular.forEach($scope.platform, function (value, key) {
                               angular.forEach(platformArray, function (data) {
                                  if (key == data) {
                                     $scope.platform[key] = true;
                                  }
                               })
                           });
                       }
                       $scope.expandAdtagSize[index] = true;
                       $scope.creativeNameUploadAd[index] = $scope.creativeFormUploadAdData.creativeNameUploadAd;
                       if($scope.creativeFormUploadAdData.thirdPartyTrackers.length>0){
                           for (var i = 0, len = $scope.creativeFormUploadAdData.thirdPartyTrackers.length; i < len; i++) {
                               if($scope.thirdPartyimpressionTrackingPixel.length<$scope.creativeFormUploadAdData.thirdPartyTrackers.length){
                                   $scope.thirdPartyimpressionTrackingPixel.push({thirdPartyimpressionTrackingPixel : " "});
                               }
                               if(!$scope.thirdPartyimpressionTrackingAd[index]){
                                   $scope.thirdPartyimpressionTrackingAd[index] = new Array();
                               }
                               $scope.thirdPartyimpressionTrackingAd[index][i] = $scope.creativeFormUploadAdData.thirdPartyTrackers[i].thirdPartyTracker;
//                           $scope.thirdPartyimpressionTrackingAd[index] = $scope.creativeFormUploadAdData.thirdPartyTrackers;
                           }
                       }
                       $scope.clickUrlUploadAds[index] = $scope.creativeFormUploadAdData.clickUrlUploadAds;
                       $scope.imagesSrc[index] = $scope.creativeFormUploadAdData.imageSrc;

                       $scope.startDateAdtagSize[index] = moment($scope.creativeFormUploadAdData.creativesStartDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                       // alert($scope.startDateAdtagSize[index]);
                       if($scope.creativeFormUploadAdData.creativesEndDate.indexOf("9999") == -1) {
                           // $scope.campaign.endDate = dateFiltering(new Date($scope.campaign.endDate));
                           $scope.endDateAdtagSize[index] = moment($scope.creativeFormUploadAdData.creativesEndDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                       }
                       else {
                           $scope.endDateAdtagSize[index] = null;
                       }

//                       $scope.startDateAdtagSize[index] = new Date($scope.creativeFormUploadAdData.creativesStartDate);
//                       if($scope.creativeFormUploadAdData.creativesEndDate=="9999-09-09 09:09:09.0"){
//                            $scope.endDateAdtagSize[index]= "";
//                       }
//                       else{
//                            $scope.endDateAdtagSize[index] = new Date($scope.creativeFormUploadAdData.creativesEndDate);
//                       }
                       var curDate = new Date().toISOString().slice(0,19);
                       var formattedStartDate = new Date($scope.startDateAdtagSize[index]).toISOString().slice(0,19);
                       if(formattedStartDate < curDate){
                          $scope.disableStartDate[index] = true;
                       }else{
                          $scope.disableStartDate[index] = false;
                       }
//                     $scope.destinationUrlAdtag[index] = $scope.creativeFormUploadAdData.destinationUrlAdtag;
                       $scope.advertiserDomainAdtag[index] = $scope.creativeFormUploadAdData.advertiserDomainAdtag;
                       $scope.impressionTrackingPixelAdtag[index] = $scope.creativeFormUploadAdData.impressionTrackingPixel;
                       $scope.ibvAdStartCallbackAdtag[index] = $scope.creativeFormUploadAdData.ibvAdStartCallback;
                       $scope.ibvAdCompleteCallbackAdtag[index] = $scope.creativeFormUploadAdData.ibvAdCompleteCallback;
                       $scope.ibvAdClicktrackCallbackAdtag[index] = $scope.creativeFormUploadAdData.ibvAdClickTrackCallback;
//                       $scope.text1[index] = $scope.creativeFormUploadAdData.adtagCode;
                       var sensitiveAttributesArray = $scope.creativeFormUploadAdData.sensitiveAttributes.split('::');
                       $scope.selectedSensitiveAttributeAdtag[index] = sensitiveAttributesArray;
                       var technicalAttributesArray = $scope.creativeFormUploadAdData.technicalAttributes.split('::');
                       $scope.selectedTechnicalAttributeAdtag[index] = technicalAttributesArray;
//                     $scope.adSizes[index].forEach( function (adSize)
                       $scope.adSizes.forEach( function (adSize)
                       {
                          if(adSize.value==$scope.creativeFormUploadAdData.adtagSize){
                             $scope.selectedAdSize[index] = adSize.value;
                          }
                       });
                       $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
                       {
                          if(countryLanguage.language==$scope.creativeFormUploadAdData.language){
                            $scope.selectedCountryLanguagesAdtag[index] = countryLanguage.languageCode;
                            if(index==0){
                                $scope.selectedCountryLanguages = $scope.selectedCountryLanguagesAdtag[index];
                            }
                          }
                       });
                       $scope.categoriesAdtag.forEach( function (categoriesAdtag)
                       {
                          if(categoriesAdtag.contentCategoryName==$scope.creativeFormUploadAdData.adtagCategory){
                             $scope.selectedCategoryAdtag[index] = categoriesAdtag.contentCategoryId;
//                             if(index==0){
////                                 $scope.selectedCategory = $scope.selectedCategoryAdtag[index];
//                                  $scope.subCategory = angular.copy($scope.subCategoriesAdtag[index]);
//                             }
                             var selectedCategoryId = categoriesAdtag.contentCategoryId;
                             $scope.selectedSubCategory = [];
                             if($scope.subCategoriesAdtagData){
                                 $scope.subCategoriesAdtagData.forEach( function (arrayItem)
                                 {
                                    var subCategoryId = arrayItem.contentSubcategoryId;
                                    if(selectedCategoryId==arrayItem.contentCategoryId){
                                        $scope.showSubCategories = true;
                                        $scope.selectedSubCategory.push(arrayItem.contentSubcategoryName);
                                    }
                                 });
//                                 $scope.subCategoriesAdtag = [];
                                 $scope.subCategoriesAdtag[index] = angular.copy($scope.selectedSubCategory);
                                 $scope.selectedSubCategoriesAdtag[index] = $scope.creativeFormUploadAdData.adtagSubCategory;
                              if(index==0){
                                  $scope.subCategories = [];
                                  $scope.selectedCategory = $scope.selectedCategoryAdtag[index];
                                  $scope.subCategories = $scope.subCategoriesAdtag[index];
                                  $scope.selectedSubCategories = $scope.selectedSubCategoriesAdtag[index];
                              }
                             }
                          }
                       });
                       if(index==0){
                           $scope.uploadAds.startDate = angular.copy($scope.startDateAdtagSize[index]);
                           $scope.uploadAds.endDate = angular.copy($scope.endDateAdtagSize[index]);
                           $scope.disableStartDateCal = angular.copy($scope.disableStartDate[index]);
//                           $scope.destinationUrl = angular.copy($scope.destinationUrlAdtag[index]);
                           $scope.advertiserDomain = angular.copy($scope.advertiserDomainAdtag[index]);
                           $scope.seletedsensitiveAttribute.sensitiveAttributes = angular.copy($scope.selectedSensitiveAttributeAdtag[index]);
                           $scope.seletedtechnicalAttribute.technicalAttributes = angular.copy($scope.selectedTechnicalAttributeAdtag[index]);
                       }
                       $scope.advertiserId = advertiserId;
                   }
               });
             }
             else{
                initUploadAd();
             }

        };
        initServices();
        init();
//        initUploadAd();

        // checkErr is used to generate error if the start date is greater the end date and start date is leass than current date
        $scope.checkErr = function(startDate,endDate) {
            $scope.errMessage = '';
            var curDate = new Date().toISOString().slice(0,10);;
            var formattedStartDate = new Date(startDate).toISOString().slice(0,10);
            if(endDate){
                var formattedEndDate = new Date(endDate).toISOString().slice(0,10);
                if(new Date(startDate) > new Date(endDate)){
                  $scope.errMessage = 'End Date should be greater than start date';
                  return false;
                }
                if(formattedStartDate < curDate && !$scope.disableStartDate[0]){
                   $scope.errMessage = 'Start date should not be before today.';
                   return false;
                }
                if(formattedEndDate < curDate){
                   $scope.errMessage = 'End date should not be before today.';
                   return false;
                }
            }
        };

        // checkErrAdtag is used to generate error if the start date is greater the end date and start date is leass than current date
        $scope.checkErrAdtag = function(startDate,endDate,index) {
            $scope.errMessageAdtag = '';
            var curDate = new Date().toISOString().slice(0,10);;
            var formattedStartDate = new Date(startDate).toISOString().slice(0,10);
            if(new Date(startDate) > new Date(endDate)){
              $scope.errMessageAdtag = 'End Date should be greater than start date';
              return false;
            }
            if(formattedStartDate < curDate && !$scope.disableStartDate[index]){
               $scope.errMessageAdtag = 'Start date should not be before today.';
               return false;
            }
        };


        // copyData() is used to copy the data to the new added adsize
        $scope.copyData=function(adtagCount){
            if($scope.isEditCreative){
                $scope.disableAdsize[adtagCount]=false;
            }
            $scope.currentIndex = adtagCount;
            if($scope.impressionTrackingPixel){
                $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                $scope.clickUrl[$scope.currentIndex] = true;
                $scope.cacheBusterBtn[$scope.currentIndex] = true;
            }
            else{
                $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                $scope.clickUrl[$scope.currentIndex] = false;
                $scope.cacheBusterBtn[$scope.currentIndex] = false;
                $scope.clickUrlMacro = "data-dcm-click-tracker='https:{clickurl}'";
                $scope.cacheBusterMacro = '{random}';
            }
            var adtagCount = adtagCount;
            $scope.selectedCountryLanguagesAdtag[adtagCount]= angular.copy($scope.selectedCountryLanguages);
            if(typeof $scope.selectedCategory !== 'undefined' && $scope.selectedCategory !== null && $scope.selectedCategory.length!==0){
                $scope.selectedCategoryAdtag[adtagCount]= angular.copy($scope.selectedCategory);
            }
            if(typeof $scope.selectedSubCategories !== 'undefined' && $scope.selectedSubCategories !== null && $scope.selectedSubCategories.length!==0 ){
                $scope.selectedSubCategoriesAdtag[adtagCount]= angular.copy($scope.selectedSubCategories);
            }
            $scope.subCategoriesAdtag[adtagCount] = angular.copy($scope.subCategories);
            $scope.selectedTechnicalAttributeAdtag[adtagCount]= angular.copy($scope.selectedTechnicalAttribute);
            $scope.selectedSensitiveAttributeAdtag[adtagCount]= angular.copy($scope.selectedSentitiveAttribute);
            $scope.startDateAdtagSize[adtagCount] = angular.copy($scope.uploadAds.startDate);
            $scope.endDateAdtagSize[adtagCount] =  angular.copy($scope.uploadAds.endDate);
//            $scope.destinationUrlAdtag[adtagCount] = angular.copy($scope.destinationUrl);
            $scope.advertiserDomainAdtag[adtagCount] = angular.copy($scope.advertiserDomain);
        }

        $scope.deleteAdsize = function(index){
            if(index>0){
                $scope.tabListCounter-=1;
            }
        }


        // setStartDate() is used to copy the start date to adtagsize start date
        $scope.setStartDate = function(startDate){
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                $scope.startDateAdtagSize[i] =  startDate;
            }
        }

        // setEndDate() is used to copy the end date to adtagsize end date
        $scope.setEndDate = function(endDate){
            $scope.endDateTouched = true;
            for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                $scope.endDateAdtagSize[i] = endDate;
            }
        }

        // getCategories() binds the selected category to uploadAds selected category and load the respective subcategories
        $scope.getCategories=function(selectedCategory){
            if(typeof selectedCategory != 'undefined'){
//                $scope.selectedCategory = selectedCategory;
                $scope.subCategories = angular.copy($scope.subCategoriesdata)
                $scope.subCategoriesList = [];
                $scope.subCategories.forEach( function (arrayItem)
                {
                    var subCategoryId = arrayItem.contentSubcategoryId;
                    if(selectedCategory==arrayItem.contentCategoryId){
                        $scope.showSubCategories = true;
                        $scope.subCategoriesList.push(arrayItem.contentSubcategoryName);
                    }
                });
                $scope.subCategories = angular.copy($scope.subCategoriesList);
                if(!$scope.categoryAdtagTouched[$scope.adSizesCounter] && !$scope.subCategoryAdtagTouched[$scope.adSizesCounter]){
                    $scope.categoriesAdtag = angular.copy($scope.categories);
                    var categoryAssignment = function(){
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            $scope.selectedCategoryAdtag[i] = $scope.selectedCategory;
                            $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategoriesList);
                        }
                    }
                    $timeout( categoryAssignment, 1000);
                }
            }
        }

        // getSubCategory() binds the selected subcategory to uploadAds selected subcategory
        $scope.getSubCategory = function(selectedSubCategories){
            if(typeof selectedSubCategories != 'undefined'){
//                $scope.selectedSubCategories = selectedSubCategories;
                if(!$scope.subCategoryAdtagTouched[$scope.adSizesCounter])
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedSubCategoriesAdtag[i]= angular.copy($scope.selectedSubCategories)
                        $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategories);
                    }
                }
            }
        }

        // getCategoriesAdtag() takes the selected category and load the respective subcategories
        $scope.getCategoriesAdtag=function(selectedCategoryAdtag, index){
            if(typeof selectedCategoryAdtag != 'undefined'){
//                $scope.selectedCategoryAdtag[index] = selectedCategoryAdtag;
//                $scope.selectedSubCategoriesAdtag[index]=[];
                $scope.subCategoriesdataAdtag=[];
                var selectedCategoryId = $scope.selectedCategoryAdtag[index];
                $scope.subCategoriesAdtag[index] = angular.copy($scope.subCategoriesdata)
                $scope.subCategoriesAdtag[index].forEach( function (arrayItem)
                {
                    var subCategoryId = arrayItem.contentSubcategoryId;
                    if(selectedCategoryId==arrayItem.contentCategoryId){
                        $scope.subCategoriesdataAdtag.push(arrayItem.contentSubcategoryName);
//                        var subCategoriesdataAdtag = $scope.subCategoriesdataAdtag;
//                        $scope.selectedSubCategoriesAdtag[index]= subCategoriesdataAdtag;
                    }
                });
                $scope.categoryAdtagTouched = true;
                $scope.subCategoriesAdtag[index] = angular.copy($scope.subCategoriesdataAdtag);
            }
        }

        $scope.getSubCategoryAdtag = function(selectedSubCategoriesAdtag, index){
            if(typeof selectedSubCategoriesAdtag != 'undefined'){
//                $scope.selectedSubCategoriesAdtag[index] = selectedSubCategoriesAdtag;
                $scope.subCategoryAdtagTouched[index] = true;
            }
        }

        // getTechnicalAttributes() binds the selected technical attribute to all technical attribute of uploadAds size
        $scope.getTechnicalAttributes = function(selectedTechnicalAttribute){
            $scope.technicalAttributeTouched = true;
            if(typeof selectedTechnicalAttribute != 'undefined'){
                var selectedTechnicalAttribute=selectedTechnicalAttribute.filter(function(val){
                           return Boolean(val);
                 });
                $scope.selectedTechnicalAttribute = selectedTechnicalAttribute;
                if(!$scope.technicalAttributeAdtagTouched)
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedTechnicalAttributeAdtag[i]= angular.copy($scope.selectedTechnicalAttribute);
                    }
                }
            }
        }

        // getSensitiveAttributes() binds the selected sensitive attribute to all uploadAds size sensitive attribute
        $scope.getSensitiveAttributes=function(selectedSentitiveAttribute){
            $scope.sensitiveAttributeTouched = true;
            if(typeof selectedSentitiveAttribute != 'undefined'){
                var selectedSentitiveAttribute=selectedSentitiveAttribute.filter(function(val){
                     return Boolean(val);
                });
                $scope.selectedSentitiveAttribute = selectedSentitiveAttribute;
                if(!$scope.sensitiveAttributeAdtagTouched)
                {
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedSensitiveAttributeAdtag[i]= angular.copy($scope.selectedSentitiveAttribute);
                    }
                }
            }
        }

        $scope.getTechnicalAttributesAdtag = function(selectedTechnicalAttributeAdtag,technicalAttribute,outerIndex){
            var technicalAttributeAdtagTabCount = $scope.tabCount;
            $scope.technicalAttributeAdtagTouched = true;
//            $scope.selectedTechnicalAttributeAdtag[outerIndex] = technicalAttribute;
        }

        $scope.getSensitiveAttributesAdtag = function(selectedSentitiveAttributeAdtag,sensitiveAttribute,outerIndex){
            var sensitiveAttributeAdtagTabCount = $scope.tabCount;
             $scope.sensitiveAttributeAdtagTouched = true;
        }

        // getCountryLanguages() binds the selected country language to all adsize country language
        $scope.getCountryLanguages=function(selectedCountryLanguages){
            if(typeof selectedCountryLanguages != 'undefined'){
                if(!$scope.countryLanguagesAdtagTouched[$scope.adSizeCount-1]){
                    $scope.countryLanguagesAdtag = angular.copy($scope.countryLanguages);
                    for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                        $scope.selectedCountryLanguagesAdtag[i]= selectedCountryLanguages;
                    }
                }
            }
        }

        $scope.getCountryLanguagesAdtag=function(selectedCountryLanguagesAdtag,index){
            if(typeof selectedCountryLanguagesAdtag != 'undefined'){
                $scope.countryLanguagesAdtagTouched[index] = true;
//                $scope.selectedCountryLanguagesAdtag[index]= selectedCountryLanguagesAdtag[index];
            }
        }

        // getAdSizes() take the selected AdSize and joins it with creative name for uploadAds Size creative name
        $scope.getAdSizes = function(selectedAdSize,index){
            $scope.adSizeCall = true;
            if($scope.creativeName){
                if (typeof selectedAdSize != "undefined"){
    //                $scope.selectedInbannerAdsizes[index]=selectedAdSize;
                    $scope.currentIndex = index;
                    if(!$scope.isEditCreative){
                        if($scope.selectedAdSizesList.length==0){
                            $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                        }
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            if(i == index){
                                $scope.creativeNameUploadAd[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                                if($scope.advertiserDomain){
                                    $scope.setAdvertiserDomain($scope.advertiserDomain);
                                }
//                                if($scope.clickUrlUploadAd){
//                                    $scope.setClickUrl($scope.clickUrlUploadAd);
//                                }
                            }
                            if(index>i){
                                if(selectedAdSize.label === $scope.selectedAdSizesList[i])
                                {
                                    $scope.selectedCreativeAdSizeExist[index] = true;
                                    $scope.expandAdtagSize[index] = false;
                                    break;
                                }
                                else{
                                    $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                                    $scope.selectedCreativeAdSizeExist[index] = false;
                                    $scope.expandAdtagSize[index] = true;
                                }
                            }
                            else{
                                $scope.expandAdtagSize[index] = true;
                            }
                             if(i == index){
                                $scope.creativeNameUploadAd[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                             }
                        }
                         if($scope.impressionTrackingPixel){
                             $scope.thirdPartyMacrosEnable[$scope.currentIndex] = false;
                             $scope.clickUrl[$scope.currentIndex] = true;
                             $scope.cacheBusterBtn[$scope.currentIndex] = true;
                         }
                         else{
                             $scope.thirdPartyMacrosEnable[$scope.currentIndex] = true;
                             $scope.clickUrl[$scope.currentIndex] = false;
                             $scope.cacheBusterBtn[$scope.currentIndex] = false;
                             $scope.clickUrlMacro = "data-dcm-click-tracker='https:{clickurl}'";
                             $scope.cacheBusterMacro = '{random}';
                         }
                    }
                    else{
                        if($scope.selectedAdSizesList.length==0){
                            $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                        }
                        for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
                            if(i == index){
                                $scope.creativeNameUploadAd[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                            }
                            if(index>i){
                                if(selectedAdSize.label === $scope.selectedAdSize[i].label)
                                {
                                    $scope.selectedCreativeAdSizeExist[index] = true;
                                    $scope.expandAdtagSize[index] = false;
                                    break;
                                }
                                else{
                                    $scope.selectedAdSizesList[index] = angular.copy(selectedAdSize.label);
                                    $scope.selectedCreativeAdSizeExist[index] = false;
                                    $scope.expandAdtagSize[index] = true;
                                }
                            }
                            else{
                                $scope.expandAdtagSize[index] = true;
                            }
                             if(i == index){
                                $scope.creativeNameUploadAd[i] = angular.copy($scope.creativeName+"-"+selectedAdSize.label);
                             }
                        }
                    }
                }
            }
        }

        $scope.isDisabled = function(selectedAdSize,index){
            if($scope.selectedCreativeAdSize.length>0 && index>0){
                for(var i=0;i<$scope.adSizeCount-1;i++)
                {
                    if($scope.selectedAdSize == $scope.selectedAdSizesList[i++]){
                        $scope.expandAdtagSize[index] = false;
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            }
        }

        $scope.setImpressionTrackingPixel = function(impressionTrackingPixel, index){
//            $scope. = true;
//            $scope.displayThirdPartyAdtagNames = true;
//            $scope.thirdPartyimpressionTrackingAd = [];
            if($scope.thirdPartyimpressionTrackingDirectiveCall){
                if(!$scope.thirdPartyimpressionTrackingAdTouched){
                    for(var tpCount=0; tpCount<$scope.thirdPartyTrackingCount; tpCount++){
                        if(!$scope.thirdPartyimpressionTrackingAd[index]){
                            $scope.thirdPartyimpressionTrackingAd[index] = new Array();
                        }
                        $scope.thirdPartyimpressionTrackingAd[index][tpCount] = impressionTrackingPixel[tpCount];
                    }
                }
            }else if($scope.imageBroswerTouched){
                for(var i=0;i<$scope.adSizeCount;i++)
                {
                    if(!$scope.thirdPartyimpressionTrackingAd[i]){
                        $scope.thirdPartyimpressionTrackingAd[i] = new Array();
                    }
                    $scope.thirdPartyimpressionTrackingAd[i][index] = angular.copy(impressionTrackingPixel);
//                    for(var tpCount=0; tpCount<$scope.thirdPartyTrackingCount; tpCount++){
//                        $scope.thirdPartyimpressionTrackingAd[tpCount] = angular.copy(impressionTrackingPixel);
//                    }
                }
            }
        }

//        $scope.setImpressionTrackingPixel = function(impressionTrackingPixel){
//            $scope.impressionTrackingPixel = true;
////            $scope.displayThirdPartyAdtagNames = true;
//            $scope.thirdPartyimpressionTrackingAd = [];
//            if($scope.thirdPartyimpressionTrackingDirectiveCall){
//                if(!$scope.thirdPartyimpressionTrackingAdTouched){
//                    for(var i=0;i<$scope.adSizeCount;i++)
//                    {
//        //                $scope.thirdPartyMacrosEnable[i] = true;
//        //                $scope.clickUrl[i] = false;
//        //                $scope.cacheBusterBtn[i] = false;
//                        $scope.thirdPartyimpressionTrackingAd[i] = impressionTrackingPixel;
////                        for(var j=0;j<impressionTrackingPixel.length;j++){
////                            $scope.thirdPartyimpressionTrackingAd[i][j] = impressionTrackingPixel[j];
////                        }
//                    }
//                }
//            }
//            if($scope.imageBroswerTouched){
//                for(var i=0;i<$scope.adSizeCount;i++)
//                {
//                    $scope.thirdPartyimpressionTrackingAd[i] = impressionTrackingPixel;
//                }
//            }
//        }


        $scope.setImpressionTrackingPixelAd = function(thirdPartyimpressionTracking,index, parentIndex){
            $scope.thirdPartyimpressionTrackingAdTouched = true;
            if(!$scope.thirdPartyimpressionTrackingAd[parentIndex]){
                $scope.thirdPartyimpressionTrackingAd[parentIndex] = new Array();
            }
            $scope.thirdPartyimpressionTrackingAd[parentIndex][index] = thirdPartyimpressionTracking;
        }

        // applyToAll() copies the
        $scope.applyToAll = function(applyToAllAdtags){
            for(var i=0;i<$scope.adSizeCount;i++)
            {
                $scope.selectedCountryLanguagesAdtag[i]= angular.copy($scope.selectedCountryLanguages);
                $scope.selectedCategoryAdtag[i]= angular.copy($scope.selectedCategory);
                $scope.selectedSubCategoriesAdtag[i]= angular.copy($scope.selectedSubCategories)
                $scope.subCategoriesAdtag[i] = angular.copy($scope.subCategories);
                $scope.selectedTechnicalAttributeAdtag[i]= angular.copy($scope.selectedTechnicalAttribute);
                $scope.selectedSensitiveAttributeAdtag[i]= angular.copy($scope.selectedSentitiveAttribute);
            }
        }

        function loadHtml(index) {
            var previewDoc = document.getElementById('adTagPreview');
            var line = document.createElement("div");
            line.innerHTML = $scope.text1[index]
            previewDoc.appendChild(line);
        }

        $scope.checkOverLappingDatesForAdtags = function (viewValue) {
            var currentDate = new Date(viewValue);
            var adtagStartDate = new Date($scope.uploadAds.startDate);
            var adtagEndDate = new Date($scope.uploadAds.endDate);
            return currentDate > adtagStartDate && currentDate < adtagEndDate;
        };

        $scope.range = function(count){
           $scope.adSizeCount = count;
           return new Array(+count);
        };

        function dateFiltering(dateObj){
            return $filter('date')(dateObj, 'yyyy-MM-dd HH:mm:ss');
        }

        $scope.user = angular.copy($scope.leader);
//        $scope.uploadAds = {budgets:[{startDate:dateFiltering(moment().add(364, 'DAYS').toDate()),endDate:"",type:"daily",budget:"",clicksLimit:"",impressionsLimit:"",delivery:"standard",startDateOpened:false,endDateOpened:false,startOption:{
//            formatYear: 'yy',
//            startingDay: 1,
//            minDate : moment().toDate()
//        },endOption:{
//            formatYear: 'yy',
//            startingDay: 1,
//            minDate : moment().toDate()
//        }}]};

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;
                }
            }
        };

        $scope.openStartEndDateAdtagSize = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index > -1){
                    $scope.startDateAdtagOpened[index] = true;
                }
            }
            else{
                if(index > -1){
                    $scope.endDateAdtagOpened[index] = true;
                }
            }
        };

        $scope.IsVisible = false;
        $scope.ShowHide = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisible = $scope.IsVisible ? false:true;
        }
        $scope.IsVisibleAdtag = [];
        $scope.ShowHideAdtag = function(index){
            $scope.IsVisibleAdtag[index] = $scope.IsVisibleAdtag[index] ? false:true;
        }
        $scope.dateFormat = function (data) {
            $scope.uploadAds[data] = $filter('date')($scope.uploadAds[data], 'yyyy-MM-dd HH:mm:ss');
        };
        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.lastFocused;
        angular.element("#input1").focus(function() {
            $scope.lastFocused = document.activeElement;
        });

        function insertAtCursor(textArea, macro, index) {
            //IE support
            if (document.selection) {
                textArea.focus();
                sel = document.selection.createRange();
                sel.text = macro;
            }
            // Microsoft Edge
            else if(window.navigator.userAgent.indexOf("Edge") > -1) {
              var startPos = textArea.selectionStart;
              var endPos = textArea.selectionEnd;
              textArea.value = textArea.value.substring(0, startPos)+ macro + textArea.value.substring(endPos, textArea.value.length);
              var pos = startPos + macro.length;
              textArea.focus();
              textArea.setSelectionRange(pos, pos);
            }
            //MOZILLA and others
            else if (textArea.selectionStart || textArea.selectionStart == '0') {
                var startPos = textArea.selectionStart;
                var endPos = textArea.selectionEnd;
                textArea.value = textArea.value.substring(0, startPos)
                    + macro
                    + textArea.value.substring(endPos, textArea.value.length);
                    textArea.focus();
                    return textArea.value;
            } else {
                textArea.value += macro;
                textArea.focus();
                return textArea.value
            }
        }


        $scope.getThirdPartyAdtag = function(thirdPartyAdtagName,index) {
            $scope.selectedThirdPartyMacrosList = [];
            angular.forEach($scope.thirdPartyMacrosList, function (value, key) {
                if(value.third_party_id==thirdPartyAdtagName){
                    $scope.selectedThirdPartyMacrosList.push(value);
//                    if(value.description=="clickURL"){
//                        $scope.clickUrlMacro = value.macro;
//                    }

                }
            })
        };

        // insertText() inserts the macro on the cursor position
        $scope.insertText = function(text,index){
            var insertedText = insertAtCursor(document.getElementsByTagName('textarea')[index], text,index);
            $scope.text1[index]=angular.copy(insertedText);
        }

        $scope.reloadRoute = function() {
            $state.reload();
        };

        $scope.saveCreativesUploadAdForm = function(){
           if($stateParams.hasOwnProperty("advertiserId")){
              if($scope.isEditCreative){
//                 CreativeManagerUploadAdsService.saveNewCreativeUploadAdSize(advertiserId,$scope.creativeMappingId,formUploadAdsData).then(function(data){
                 CreativeManagerUploadAdsService.saveNewCreativeUploadAdSize(advertiserId,$scope.creativeMappingId,$scope.formDataUploadAds).then(function(data){
                      $scope.submitButtonDisable = true;
                      $timeout(function () {
                         $state.go('creative-manager-edit-upload-ads-creative-advId', {advertiserId: advertiserId, creativeMappingId: $scope.creativeMappingId})
                     }, 2000);
                  //console.log(data);
                  $scope.reloadRoute
                  });
              }
              else{
//                  CreativeManagerUploadAdsService.saveCreativeUploadAdFormAdvId(advertiserId,formUploadAdsData).then(function(data){
                  CreativeManagerUploadAdsService.saveCreativeUploadAdFormAdvId(advertiserId,$scope.formDataUploadAds).then(function(data){
                     $scope.submitButtonDisable = true;
                     $timeout(function () {
                         $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
                      }, 2000);
                   //console.log(data);
                   $scope.reloadRoute
                  });
              }
           }
           else{
//               CreativeManagerUploadAdsService.saveCreativeUploadAdFormAdvId(advertiserId,formUploadAdsData).then(function(data){
               CreativeManagerUploadAdsService.saveCreativeUploadAdFormAdvId(advertiserId,$scope.formDataUploadAds).then(function(data){
                   $scope.submitButtonDisable = true;
                   $timeout(function () {
                      $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
                   }, 2000);
                  //console.log(data);
                  $scope.reloadRoute
               });
           }
        };


        var timer;

        $scope.openAdImagePopup = function (imageUrl, adsize) {
           timer = $timeout(function () {
                var dialog = ngDialog.open({ template: 'app/creative-manager/creative-ad-image-popup.html',
                    controller: 'CreativeAdImagePopupController',
                    className: 'ngdialog-theme-default custom-width-800',
                    resolve : {
                        imageUrl : function(){
                            return imageUrl;
                        },
                        adsize : function(){
                            return adsize;
                        }
                    }
                });
            }, 1000);
        };


        $scope.hideIt = function () {
            $timeout.cancel(timer);
            $scope.hovering = false;
        };

        $scope.updateCreativeAdSize = function(index){
              $scope.formUploadAdsData = [];
              $scope.formUploadAdData = {};
              if($stateParams.hasOwnProperty("advertiserId")){
                  advertiserId = $stateParams.advertiserId;
              }
              else{
                  advertiserId = $scope.advertiserId;
              }
              $scope.formUploadAdData.advertiserId = advertiserId;
              // if isEditCreative then assign the creativeId to the formUploadAdData
              if($scope.isEditCreative){
                  $scope.formUploadAdData.creativeMappingId=creativeMappingId;
                  $scope.formUploadAdData.creativeId = $scope.creativeId[index];
                  $scope.formUploadAdData.creativeNameUploadAd = $scope.creativeNameUploadAd[index];
                  $scope.formUploadAdData.platform = $scope.selectedPlatform.toString();
                  $scope.formUploadAdData.selectedCreativeAdSize = $scope.selectedAdSize[index];
                  $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
                  {
                       if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                            $scope.formUploadAdData.selectedCountryLanguagesAdtag = countryLanguage.language;
                       }
                  });
                  $scope.categoriesAdtag.forEach( function (categoriesAdtag)
                  {
                     if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                         $scope.formUploadAdData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                     }
                  });
                  $scope.formUploadAdData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
                  if($scope.technicalAttributeTouched){
                     $scope.formUploadAdData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
                  }
                  if($scope.sensitiveAttributeTouched){
                      $scope.formUploadAdData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
                  }
                  $scope.formUploadAdData.creativeAdTags = $scope.text1[index];
      //            $scope.formUploadAdData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
                  $scope.formUploadAdData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
                  $scope.formUploadAdData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
                  $scope.formUploadAdData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                  if($scope.endDateTouched){
                    if($scope.endDateAdtagSize[index]== undefined || $scope.endDateAdtagSize[index]== "Invalid date" || $scope.endDateAdtagSize[index]== null || $scope.endDateAdtagSize[index]==""){
                          $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
                     }
                     else{
                         $scope.formUploadAdData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                     }
                  }
                  else{
                     $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
                  }
                  var formUploadAdData = angular.copy($scope.formUploadAdData);
                  if(formUploadAdsData.length<=$scope.adSizeCount){
//                     formUploadAdsData.append('formUploadAdData',formUploadAdData);
                     formUploadAdsData.push(formUploadAdData);
                     $scope.formDataUploadAds.append('formUploadAdData',angular.toJson(formUploadAdData));
                     $scope.formDataUploadAds.append('uploadImage',$scope.uploadFile[index]);
                  }
                 CreativeManagerUploadAdsService.updateCreativeUploadAd(advertiserId,creativeMappingId, formUploadAdsData).then(function (data) {
//                         console.log(data);
                         $scope.reloadRoute();
                  });
              }
        }


        $scope.saveNewCreativeAdSize= function(index){
              $scope.formUploadAdsData = [];
              $scope.formUploadAdData = {};
              if($stateParams.hasOwnProperty("advertiserId")){
                  advertiserId = $stateParams.advertiserId;
              }
              else{
                  advertiserId = $scope.advertiserId;
              }
              $scope.formUploadAdData.advertiserId = advertiserId;
              // if isEditCreative then assign the creativeId to the formUploadAdData
              if($scope.isEditCreative){
                  $scope.formUploadAdData.creativeMappingId=creativeMappingId;
              }
             $scope.formUploadAdData.creativeId = $scope.creativeId[index];
             $scope.formUploadAdData.creativeNameUploadAd = $scope.creativeNameUploadAd[index];
             $scope.formUploadAdData.platform = $scope.selectedPlatform.toString();
             $scope.formUploadAdData.selectedCreativeAdSize = $scope.selectedAdSize[index];
             $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
             {
                  if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                      $scope.formUploadAdData.selectedCountryLanguagesAdtag = countryLanguage.language;
                  }
             });
             $scope.categoriesAdtag.forEach( function (categoriesAdtag)
             {
                if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                    $scope.formUploadAdData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                }
             });
             if(typeof $scope.selectedSubCategoriesAdtag[i] !== 'undefined' && $scope.selectedSubCategoriesAdtag[i] !== null && $scope.selectedSubCategoriesAdtag[i].length!==0){
                 $scope.formUploadAdData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
             }
             else{
                 $scope.formUploadAdData.selectedSubCategoriesAdtag = "";
             }
//             $scope.formUploadAdData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
             if($scope.technicalAttributeTouched){
                $scope.formUploadAdData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
             }
             if($scope.sensitiveAttributeTouched){
                 $scope.formUploadAdData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
             }
             $scope.formUploadAdData.creativeAdTags = $scope.text1[index];
    //             $scope.formUploadAdData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
             $scope.formUploadAdData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
//             $scope.formUploadAdData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
             $scope.formUploadAdData.thirdPartyimpressionTrackingAd = $scope.thirdPartyimpressionTrackingAd[index];
             $scope.formUploadAdData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
             if($scope.endDateTouched){
                if($scope.endDateAdtagSize[index]== undefined || $scope.endDateAdtagSize[index]== "Invalid date" || $scope.endDateAdtagSize[index]== null || $scope.endDateAdtagSize[index]==""){
                     $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
                }
                else{
                     $scope.formUploadAdData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                }
             }
             else{
                $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
             }
             $scope.formUploadAdData.imagesSrc = $scope.imagesSrc[index];
             formUploadAdData = angular.copy($scope.formUploadAdData);
             if(formUploadAdsData.length<=$scope.adSizeCount){
//                 formUploadAdsData.append('formUploadAdData',formUploadAdData);
                formUploadAdsData.push(formUploadAdData);
                $scope.formDataUploadAds.append('formUploadAdData',angular.toJson(formUploadAdData));
                $scope.formDataUploadAds.append('uploadImage',$scope.uploadFile[i]);
             }
            console.log('Saving New Creative');
            $scope.saveCreativesUploadAdForm();
            $scope.reloadRoute();
        }

        $scope.updateCreativeUploadAdSize = function(index){
              $scope.formAdtagsData = [];
//              var formAdtagsData = [];
              $scope.formUploadAdData = {};
              $scope.formUploadAdData.thirdPartyimpressionTrackingAd = [];
              if($stateParams.hasOwnProperty("advertiserId")){
                  advertiserId = $stateParams.advertiserId;
              }
              else{
                  advertiserId = $scope.advertiserId;
              }
              $scope.formUploadAdData.advertiserId = advertiserId;
              $scope.imagesSrc[index] = $scope.replacedImageSrc;
              // if isEditCreative then assign the creativeId to the formUploadAdData
              if($scope.isEditCreative){
                  $scope.formAdtagsData = [];
                  $scope.formUploadAdData.creativeMappingId=creativeMappingId;
                  $scope.formUploadAdData.creativeId = $scope.creativeId[index];
                  $scope.formUploadAdData.creativeNameUploadAd = $scope.creativeNameUploadAd[index];
                  $scope.formUploadAdData.platform = $scope.selectedPlatform.toString();
                  $scope.formUploadAdData.selectedCreativeAdSize = $scope.selectedAdSize[index].value;
                  $scope.formUploadAdData.clickUrlUploadAds = $scope.clickUrlUploadAds[index];
                  if($scope.thirdPartyimpressionTrackingAd.length>0){
                      for(var j=0;j< $scope.thirdPartyTrackingCount; j++){
                         $scope.formUploadAdData.thirdPartyimpressionTrackingAd.push($scope.thirdPartyimpressionTrackingAd[index][j]);
                      }
                  }
                  $scope.formUploadAdData.imagesSrc = $scope.imagesSrc[index];
                  $scope.formUploadAdData.impressionTrackingPixel = $scope.impressionTrackingPixel;
                  $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
                  {
                       if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[index]){
                            $scope.formUploadAdData.selectedCountryLanguagesAdtag = countryLanguage.language;
                       }
                  });
                  $scope.categoriesAdtag.forEach( function (categoriesAdtag)
                  {
                     if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[index]){
                         $scope.formUploadAdData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                     }
                  });
                  $scope.formUploadAdData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[index];
                  if($scope.technicalAttributeTouched){
                     $scope.formUploadAdData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[index].join('::');
                  }
                  if($scope.sensitiveAttributeTouched){
                      $scope.formUploadAdData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[index].join('::');
                  }
      //            $scope.formUploadAdData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
                  $scope.formUploadAdData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[index];
//                  $scope.formUploadAdData.impressionTrackingPixel = $scope.impressionTrackingPixelAdtag[index];
                  $scope.formUploadAdData.startDateAdtagSize  = moment($scope.startDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                  if($scope.endDateTouched){
                    if($scope.endDateAdtagSize[index]== undefined || $scope.endDateAdtagSize[index]== "Invalid date" || $scope.endDateAdtagSize[index]== null || $scope.endDateAdtagSize[index]==""){
                          $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
                     }
                     else{
                         $scope.formUploadAdData.endDateAdtagSize  = moment($scope.endDateAdtagSize[index]).format('YYYY-MM-DD HH:mm:ss');
                     }
                  }
                  else{
                     $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
                  }
//                  formUploadAdData = angular.copy($scope.formUploadAdData);
                  formUploadAdsData.push($scope.formUploadAdData);
                  $scope.formDataUploadAds.append('formUploadAdData',angular.toJson($scope.formUploadAdData));
                  $scope.formDataUploadAds.append('uploadImage',$scope.uploadUpdatedFile[0]);
//                  if(formAdtagsData.length<=$scope.adSizeCount){
//                     formAdtagsData.push(formAdtagData);
//                  }
                 CreativeManagerUploadAdsService.updateCreativeUploadAd(advertiserId,creativeMappingId, $scope.formDataUploadAds).then(function (data) {
//                         console.log(data);
                         $scope.reloadRoute();
                  });
              }
        }


        $scope.saveCreativeUploadAdSize = function(index){
             if($stateParams.hasOwnProperty("advertiserId")){
                advertiserId = $stateParams.advertiserId;
                if($scope.creativeId[index]){
                    $scope.updateCreativeUploadAdSize(index);
                }
                else{
                    $scope.saveNewCreativeAdSize(index);
                }
             }
        }

          // submitCreativesForm function will be called whenever submit button is pressed
        $scope.submitCreativeUploadAds= function(){
//          $scope.formUploadAdsData = new FormData();
//                 data.append('uploadfile', file);

          $scope.formUploadAdData = {};
          if($stateParams.hasOwnProperty("advertiserId")){
              advertiserId = $stateParams.advertiserId;
          }
          else{
              advertiserId = $scope.advertiserId;
          }
          $scope.formUploadAdData.advertiserId = advertiserId;
          $scope.formUploadAdData.platform = $scope.selectedPlatform.toString();
          // if isEditCreative then assign the creativeId to the formUploadAdData
          if($scope.isEditCreative){
              $scope.formUploadAdData.creativeMappingId=creativeMappingId;
          }
          $scope.formUploadAdData.thirdPartyimpressionTrackingAd = [];
          for (var i = 0, len = $scope.adSizeCount; i < len; i++) {
             $scope.formUploadAdData.creativeId = $scope.creativeId[i];
             $scope.formUploadAdData.creativeNameUploadAd = $scope.creativeNameUploadAd[i];
             $scope.formUploadAdData.selectedCreativeAdSize = $scope.selectedAdSize[i];
             $scope.formUploadAdData.clickUrlUploadAds = $scope.clickUrlUploadAds[i];
             if($scope.thirdPartyimpressionTrackingAd.length>0){
                 for(var j=0;j< $scope.thirdPartyTrackingCount; j++){
                    $scope.formUploadAdData.thirdPartyimpressionTrackingAd.push($scope.thirdPartyimpressionTrackingAd[i][j]);
                 }
             }
             $scope.formUploadAdData.imagesSrc = $scope.imagesSrc[i];
             $scope.formUploadAdData.impressionTrackingPixel = $scope.impressionTrackingPixel;
//             $scope.formUploadAdData.uploadImage = $scope.uploadFile[i];
             $scope.countryLanguagesAdtag.forEach( function (countryLanguage)
             {
                  if(countryLanguage.languageCode == $scope.selectedCountryLanguagesAdtag[i]){
                      $scope.formUploadAdData.selectedCountryLanguagesAdtag = countryLanguage.language;
                  }
             });
             $scope.categoriesAdtag.forEach( function (categoriesAdtag)
             {
                if(categoriesAdtag.contentCategoryId==$scope.selectedCategoryAdtag[i]){
                    $scope.formUploadAdData.selectedCategoryAdtag = categoriesAdtag.contentCategoryName;
                }
             });
             if(typeof $scope.selectedSubCategoriesAdtag[i] !== 'undefined' && $scope.selectedSubCategoriesAdtag[i] !== null && $scope.selectedSubCategoriesAdtag[i].length!==0){
                 $scope.formUploadAdData.selectedSubCategoriesAdtag = $scope.selectedSubCategoriesAdtag[i];
             }
             else{
                 $scope.formUploadAdData.selectedSubCategoriesAdtag = "";
             }
             if($scope.technicalAttributeTouched){
                $scope.formUploadAdData.selectedTechnicalAttributeAdtag = $scope.selectedTechnicalAttributeAdtag[i].join('::');
             }
             if($scope.sensitiveAttributeTouched){
                 $scope.formUploadAdData.selectedSensitiveAttributeAdtag = $scope.selectedSensitiveAttributeAdtag[i].join('::');
             }
//             $scope.formUploadAdData.destinationUrlAdtag = $scope.destinationUrlAdtag[i];
             $scope.formUploadAdData.advertiserDomainAdtag = $scope.advertiserDomainAdtag[i];
             $scope.formUploadAdData.startDateAdtagSize  = moment($scope.startDateAdtagSize[i]).format('YYYY-MM-DD HH:mm:ss');
             if($scope.endDateTouched){
                $scope.formUploadAdData.endDateAdtagSize  = moment($scope.endDateAdtagSize[i]).format('YYYY-MM-DD HH:mm:ss');
             }
             else{
                $scope.formUploadAdData.endDateAdtagSize  = "9999-09-09 09:09:09";
             }
             $scope.formUploadAdData.impressionTrackingPixel = $scope.impressionTrackingPixel;
             var formUploadAdData = angular.copy($scope.formUploadAdData);
//             if(formUploadAdsData.length<=$scope.adSizeCount){
             if(formUploadAdsData.length<=$scope.adSizeCount){
                $scope.formDataUploadAds.append('formUploadAdData',angular.toJson(formUploadAdData));
                $scope.formDataUploadAds.append('uploadImage',$scope.uploadFile[i]);
//                 formUploadAdsData.append('formUploadAdData',formUploadAdData);
                formUploadAdsData.push(formUploadAdData);
             }
          }
       // if creativeId is not present, saveCreativesForm() will be called, else updateCreative() will call
             $scope.reloadRoute = function() {
                  $state.reload();
             };
            if (creativeMappingId === undefined || creativeMappingId === null) {
                console.log('Saving New Creative');
                $scope.saveCreativesUploadAdForm();
//                var urlUploadAd = "api/creatives/upload-ads"+"/"+advertiserId ;
//                var req = {
//                    url: urlUploadAd,
//                    method: 'POST',
//                    headers: {'Content-Type': undefined},
//                    data: $scope.formDataUploadAds,
//                    transformRequest: function (data, headersGetterFunction) {
//                      return data;
//                    }
//                };
            }

            $scope.submitButtonDisable = true
        };

       document.addEventListener("DOMContentLoaded", function() {
          document.getElementById("process").addEventListener("click", function () {
             var $iframe = document.getElementById("iframe-render"),iframe, iframeDoc;
             if ( !$iframe ) {
               iframe = document.createElement("iframe");
               iframe.id = "iframe-render";
               document.getElementById("adTagPreview").appendChild( iframe );
             } else {
               iframe = $iframe;
             }
             iframeDoc = iframe.contentWindow ?
                          iframe.contentWindow :
                          iframe.contentDocument.document ?
                          iframe.contentDocument.document :
                          iframe.contentDocument;
             iframeDoc.document.open();
             iframeDoc.document.write(
                document.getElementById("$index").value
             );
             iframeDoc.document.close();
          }, false);
       }, false);

       $scope.$on('counterVal', function (event, args) {
            $scope.count = args.countVal;
            if ($scope.count === 0) {
//           	  alert('BOOM');
           }
        });


       $scope.addThirdPartyTracking = function(){
            $scope.thirdPartyimpressionTrackingPixel.push({thirdPartyimpressionTrackingPixel : " "});
            $scope.thirdPartyTrackingCount = $scope.thirdPartyimpressionTrackingPixel.length;
       }

       $scope.deleteThirdPartyTracking = function(index){
            $scope.thirdPartyimpressionTrackingPixel.splice(index,1);
            $scope.thirdPartyTrackingCount = $scope.thirdPartyimpressionTrackingPixel.length;
       }

       $scope.imageBroswer = function(){
            $scope.imageBroswerTouched = true;
       }

       $scope.changeImage = function(imageInfo, imageUrl){
            var images = $scope.imagesSrc;
            var uploadFIle = $scope.uploadUpdatedFile;
            $scope.replacedImageSrc = imageUrl;
       }

     })
