(function () {
    "use strict";
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular.module("adelementAdminApp")

.constant("ENV", "prod")

.constant("VERSION", "0.0.1-SNAPSHOT")

.constant("APP_MEDIAQUERY", {
	"desktopLG": 1200,
	"desktop": 992,
	"tablet": 768,
	"mobile": 480
})

.constant("NUMBER_FORMAT_CONST", [
	"USD",
	"GBP",
	"INR",
	"EUR"
])

.constant("LANGUAGE_CONST", {
	"en": {
		"id": "en",
		"name": "English"
	}
})

.constant("THIRD_PARTY_AD_SERVER_CONST", [
	"Doubleclick",
	"Trueffect",
	"Sumoaudience"
])

.constant("USER_TITLE_CONST", [
	"Owner / Partner/ Founder / President",
	"CMO / Director / VP Marketing",
	"Marketing Sales / Manager",
	"Media Buyer / Planner",
	"Marketing Consultant"
])

.constant("WEBSITE_UNIQUE_VISITORS_CONST", [
	"<= 1K",
	"10K - 50K",
	"50K - 100K",
	"100K - 500K",
	"500K - 2M",
	"2M +"
])

.constant("APP_UNIQUE_USERS_CONST", [
	"<= 1K",
	"10K - 50K",
	"50K - 100K",
	"100K - 500K",
	"500K - 2M",
	"2M +"
])

.constant("ACL_PERMISSIONS_CONST", [
	"READ",
	"WRITE"
])

.constant("BUSINESS_TYPE_CONST", [
	"Local",
	"Regional",
	"National",
	"International"
])

.constant("RETARGETING_TAGS_TYPE", [
	"Simple Retargeting Tag",
	"Dynamic Retargeting Tag"
])

;
})();
