/**
 * Created by RahulD on 3/5/16.
 */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('demo-requests', {
                parent: 'entity',
                url: '/demorequests',
                data: {
                    authorities: ['ROLE_ADMIN','SUPER_EXPERT_WRITE'],
                    pageTitle: 'adelementAdminApp.pagetitles.demo-requests.title'
                },
                templateUrl: 'app/admin/demo-requests/demo-requests.html',
                controller: 'DemoRequestsController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    });

