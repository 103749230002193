/**
 * Created by maitray on 11/3/16.
 */
(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .service('Colors', Colors);

    Colors.$inject = ['APP_COLORS'];
    function Colors(APP_COLORS) {
        this.byName = byName;

        ////////////////

        function byName(name) {
            return (APP_COLORS[name] || '#fff');
        }
    }

})();
