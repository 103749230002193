/**
 * Created by adelement on 5/5/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .controller('AdvertiserAddController', function ($rootScope,$scope,$state,CreateAdvertisers,GetSelectionInfo,NUMBER_FORMAT_CONST,LANGUAGE_CONST,BUSINESS_TYPE_CONST,THIRD_PARTY_AD_SERVER_CONST,$stateParams,AeAdvertisers,AssignedUsers,GetAllUsers,AssignUsersToAdvertiser) {
        $scope.numberFormatConst=NUMBER_FORMAT_CONST;
        $scope.languageConst = LANGUAGE_CONST;
        $scope.businessTyprConst=BUSINESS_TYPE_CONST;
        $scope.thirdPartyAdServer=THIRD_PARTY_AD_SERVER_CONST;
        $scope.notFilledRequiredFields = false;
        $scope.contollerErrorMsg = "";
        $scope.advertiserEdit = false;
        $scope.aeAdvertisersProsp = {
            id:"",
            contactName: "",
            companyName: "",
            legalEntityName: "",
            contactEmail: "",
            contactPhone: "",
            billingContactName: "",
            billingEmail: "",
            billingPhoneNo: "",
            city : "",
            country : "",
            websiteUrl : "",
            androidApp : "",
            iosApp : "",
            timezone:"",
            language:"",
            billingCurrency:"",
            numberFormat:"",
            businessCategory:"",
            businessType:"",
            thirdPartyAdServer:"",
            allowAdTagAccess:"",
            creditLimit:"",
            note:"",
            createdUserId:"",
            permission:"",
        };
        $scope.prevState = $rootScope.fromState.name;
        if($scope.prevState == undefined || $scope.prevState == ""){
            $scope.prevState = "dashboard.agency";
        }
        $scope.step_1=true;
        $scope.step_2=false;
        $scope.assignedUsers=[];
        $scope.allUsers = [];
        $scope.clickableStep1 = false;
        $scope.clickableStep2 = false;

        $scope.assignUserData = {
            advertiserId:"",
            assignUsersId:[],
            removeUsersId:[]
        };
        var allusers = GetAllUsers.get();
        allusers.$promise.then(function(response){
            $scope.allUsers = response.users;
            for(var ind in  $scope.allUsers){
                for(var index in $scope.assignedUsers){
                    if($scope.allUsers[ind].id == $scope.assignedUsers[index].id){
                        $scope.allUsers.splice(ind,1);
                    }
                }
            }
        });

        $scope.contactNameCheck = false;
        $scope.aeCompanyNameCheck = false;
        $scope.contactEmailCheck = false;
        $scope.contactPhoneCheck=false;
        $scope.legalEntityCheck = false;
        $scope.billingContactNameCheck = false;
        $scope.billingEmailCheck = false;
        $scope.billingPhoneCheck=false;
        $scope.creditLimitCheck=false;
        $scope.addressCheck = false;
        $scope.aeCityCheck=false;
        $scope.aeWebsiteUrlCheck = false;
        $scope.androidAppUrlCheck = false;
        $scope.iOsAppurlCheck = false;
        $scope.timezoneCheck=false;
        $scope.billingCurrencyCheck=false;

        var onAuthFailure = function(response){
            $state.go('accessdenied');
        };

        if($stateParams.id != null && $stateParams.id != undefined && $stateParams.id !=''){
            $scope.clickableStep1 = true;
            $scope.clickableStep2 = true;
            var aeAdvertiser = AeAdvertisers.get({id:$stateParams.id});
            aeAdvertiser.$promise.then(function(response){
                $scope.aeAdvertisersProsp = response;
                $scope.advertiserEdit = true;

                if($scope.aeAdvertisersProsp.contactName != "" && $scope.aeAdvertisersProsp.contactName!=undefined){
                    $scope.contactNameCheck = true;
                }
                if($scope.aeAdvertisersProsp.companyName != "" && $scope.aeAdvertisersProsp.companyName!= undefined){
                    $scope.aeCompanyNameCheck = true;
                }
                if($scope.aeAdvertisersProsp.contactEmail != "" && $scope.aeAdvertisersProsp.contactEmail!=undefined){
                    $scope.contactEmailCheck=true;
                }
                if($scope.aeAdvertisersProsp.contactPhone != "" && $scope.aeAdvertisersProsp.contactPhone!=null){
                    $scope.contactPhoneCheck=true;
                }
                if($scope.aeAdvertisersProsp.legalEntityName != "" && $scope.aeAdvertisersProsp.legalEntityName!=undefined){
                    $scope.legalEntityCheck = true;
                }
                if($scope.aeAdvertisersProsp.billingContactName != "" && $scope.aeAdvertisersProsp.billingContactName!=undefined){
                    $scope.billingContactNameCheck=true;
                }
                if($scope.aeAdvertisersProsp.billingEmail != "" && $scope.aeAdvertisersProsp.billingEmail!=undefined){
                    $scope.billingEmailCheck = true;
                }
                if($scope.aeAdvertisersProsp.billingPhoneNo != "" && $scope.aeAdvertisersProsp.billingPhoneNo!=null){
                    $scope.billingPhoneCheck = true;
                }
                if($scope.aeAdvertisersProsp.creditLimit != "" && $scope.aeAdvertisersProsp.creditLimit!=null){
                    $scope.creditLimitCheck=true;
                }
                if($scope.aeAdvertisersProsp.address != "" && $scope.aeAdvertisersProsp.address!= undefined){
                    $scope.addressCheck=true;
                }
                if($scope.aeAdvertisersProsp.city != "" && $scope.aeAdvertisersProsp.city!=undefined){
                    $scope.aeCityCheck=true;
                }
                if($scope.aeAdvertisersProsp.websiteUrl != "" && $scope.aeAdvertisersProsp.websiteUrl != undefined){
                    $scope.aeWebsiteUrlCheck=true;
                }
                if($scope.aeAdvertisersProsp.androidApp != "" && $scope.aeAdvertisersProsp.androidApp!=undefined){
                    $scope.androidAppUrlCheck=true;
                }
                if($scope.aeAdvertisersProsp.iosApp != "" && $scope.aeAdvertisersProsp.iosApp!=undefined){
                    $scope.iOsAppurlCheck = true;
                }
                if($scope.aeAdvertisersProsp.timezone !="" && $scope.aeAdvertisersProsp.timezone !=null){
                    $scope.timezoneCheck=true;
                }
                if($scope.aeAdvertisersProsp.billingCurrency !="" && $scope.aeAdvertisersProsp.billingCurrency!=undefined){
                    $scope.billingCurrencyCheck = true;
                }

            },onAuthFailure);
            var users = AssignedUsers.get({id:$stateParams.id});
            users.$promise.then(function(response){
                $scope.assignedUsers = response.assignedUsers;
                for(var ind in $scope.allUsers){
                    for(var index in $scope.assignedUsers){
                        if($scope.allUsers[ind].id == $scope.assignedUsers[index].id){
                            $scope.allUsers.splice(ind,1);
                        }
                    }
                }

            });

            $scope.assignUserData.advertiserId = $stateParams.id;
        }
        $scope.getSelectionInfo = GetSelectionInfo.getSelectionInfo().then(function (data) {
            $scope.currency =  data.currenceyInfo;
            $scope.iabCategorization = data.iabCategorization;
            $scope.countryCodes = data.countryCodes;
            $scope.timeZoneInfo = [];
            for(var timezone in data.timezoneInfo){
                var obj = {};
                var obj2 = data.timezoneInfo[timezone];
                obj.id = obj2.id;
                obj.name = obj2.name;
                var input = Math.abs(obj2.additionalNumber);
                var hours = parseInt( input / 60, 10 );
                var minutes = (input - (hours * 60)) < 10 ? '0' + (input - (hours * 60)) : input - (hours * 60);
                if (obj2.additionalNumber.toString().charAt(0) == '-'){
                    obj.additionalNumber = '-'+hours+":"+minutes;
                }else{
                    obj.additionalNumber = '+'+hours+":"+minutes;
                }
                $scope.timeZoneInfo.push(obj);
            }
        });
        $scope.createAdvertiser = function () {
            if ($scope.aeAdvertisersProsp["contactName"] == undefined || $scope.aeAdvertisersProsp["contactName"] == "" || !$scope.aeAdvertisersProsp["contactName"].trim()) {
                $scope.contollerErrorMsg = "Contact Name is required.";
                $scope.notFilledRequiredFields = true;
                return;
            } else if ($scope.aeAdvertisersProsp["companyName"] == undefined || $scope.aeAdvertisersProsp["companyName"] == "" || !$scope.aeAdvertisersProsp["companyName"].trim()) {
                $scope.contollerErrorMsg = "Company Name is required.";
                $scope.notFilledRequiredFields = true;
                return;

            } else if ($scope.aeAdvertisersProsp["contactEmail"] == undefined || $scope.aeAdvertisersProsp["contactEmail"] == "" || !$scope.aeAdvertisersProsp["contactEmail"].trim()) {
                $scope.contollerErrorMsg = "Contact Email is required.";
                $scope.notFilledRequiredFields = true;
                return;

            } else if ($scope.aeAdvertisersProsp["billingCurrency"] == undefined || $scope.aeAdvertisersProsp["billingCurrency"] == "" || !$scope.aeAdvertisersProsp["billingCurrency"].trim()) {
                $scope.contollerErrorMsg = "Billing Currency is required.";
                $scope.notFilledRequiredFields = true;
                return;
            }else if($scope.aeAdvertisersProsp["country"] == undefined || $scope.aeAdvertisersProsp["country"] == ""){
                $scope.contollerErrorMsg = "Country is required.";
                $scope.notFilledRequiredFields = true;
                return
            } else if ($scope.aeAdvertisersProsp.timezone == undefined || $scope.aeAdvertisersProsp.timezone == "") {
                $scope.contollerErrorMsg = "Timezone is required.";
                $scope.notFilledRequiredFields = true;
                return;

            } else if ($scope.aeAdvertisersProsp.legalEntityName == undefined || $scope.aeAdvertisersProsp.legalEntityName == "" || !$scope.aeAdvertisersProsp.legalEntityName.trim()) {
                $scope.contollerErrorMsg = "Legal Entity Name is required.";
                $scope.notFilledRequiredFields = true;
                return;

            } else if (($scope.aeAdvertisersProsp.websiteUrl == "" || $scope.aeAdvertisersProsp.websiteUrl == undefined || !$scope.aeAdvertisersProsp.websiteUrl.trim()) && ($scope.aeAdvertisersProsp.androidApp == "" || $scope.aeAdvertisersProsp.androidApp == undefined || !$scope.aeAdvertisersProsp.androidApp.trim()) && ($scope.aeAdvertisersProsp.iosApp == "" || $scope.aeAdvertisersProsp.iosApp == undefined || !$scope.aeAdvertisersProsp.iosApp.trim())) {
                $scope.contollerErrorMsg = "Website Url or Android App Url or iOs app url is required.";
                $scope.notFilledRequiredFields = true;
                return;
            }
            var onSuccessAdvertiserCreate = function (advertiser) {
                $scope.aeAdvertisersProsp.id=advertiser.id;
                $scope.assignUserData.advertiserId = advertiser.id;
                $scope.notFilledRequiredFields = false;
                $scope.step(2);
            };
            var onErrorAdvertiserCreate = function () {
                $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                $scope.notFilledRequiredFields = true;
            };
            $scope.aeAdvertisersProsp['flag'] = "addadvertiser";
            CreateAdvertisers.save($scope.aeAdvertisersProsp, onSuccessAdvertiserCreate, onErrorAdvertiserCreate);
        };
        $scope.step = function(step){
            if(step==1){
                $scope.step_1=true;
                $scope.step_2=false;
            }else if(step==2){
                $scope.step_1=false;
                $scope.step_2=true;
            }
        };

        var assignUser = [];
        var removeUser = [];
        var onSuccessUserAssign = function(respone){
            $scope.contollerErrorMsg ="";
            $scope.notFilledRequiredFields = false;
            if($scope.advertiserEdit){
                $scope.assignUserData.assignUsersId.pop();
                $scope.assignUserData.removeUsersId.pop();
            }else{
                $state.go( $scope.prevState);
            }
        };
        var onFailureUserAssign = function(response){
            $scope.contollerErrorMsg = response.data.error + "  User: "+ response.data.user;
            $scope.notFilledRequiredFields = true;
            if($scope.advertiserEdit){
                $scope.assignUserData.assignUsersId.pop();
                $scope.assignUserData.removeUsersId.pop();
                var user = $scope.assignedUsers.pop();
                if($scope.allUsers.indexOf(user) == -1){
                    $scope.allUsers.push(user);
                }
            }
        };
        $scope.assignUserFunc = function(user){
            if(user == undefined){
                $scope.contollerErrorMsg = "Please select user to assign";
                $scope.notFilledRequiredFields = true;
                return;
            }
            $scope.contollerErrorMsg = "";
            $scope.notFilledRequiredFields = false;
            $scope.assignedUsers.push(user);
            assignUser.push(user.id);
            removeUser.splice(removeUser.indexOf(removeUser,1));
            $scope.allUsers.splice($scope.allUsers.indexOf(user),1);
            $scope.assignUserData.assignUsersId.push(user.id);
            $scope.assignUserData.removeUsersId.splice($scope.assignUserData.removeUsersId.indexOf(user.id),1);
            if($scope.advertiserEdit){
                AssignUsersToAdvertiser.save($scope.assignUserData,onSuccessUserAssign,onFailureUserAssign);
            }
        };

        $scope.removeUserFunc = function(user){
            $scope.assignedUsers.splice($scope.assignedUsers.indexOf(user),1);
            removeUser.push(user.id);
            assignUser.splice(assignUser.indexOf(user.id),1);
            if($scope.allUsers.indexOf(user) == -1){
                $scope.allUsers.push(user);
            }
            $scope.assignUserData.assignUsersId.splice($scope.assignUserData.assignUsersId.indexOf(user.id),1);
            $scope.assignUserData.removeUsersId.push(user.id);

            if($scope.advertiserEdit){
                AssignUsersToAdvertiser.save($scope.assignUserData,onSuccessUserAssign,onFailureUserAssign);
            }
        };

        $scope.assignUsers = function(){
            if($scope.advertiserEdit){
                $state.go( $scope.prevState);
                return;
            }
            $scope.assignUserData.removeUsersId=[];
            AssignUsersToAdvertiser.save($scope.assignUserData,onSuccessUserAssign,onFailureUserAssign);
        };
    });
