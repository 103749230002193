'use strict';

angular.module('adelementAdminApp')
    .factory('Notifications', function ($resource, DateUtils) {
        return $resource('api/notificationss/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timestamp = DateUtils.convertDateTimeFromServer(data.timestamp);
                    return data;
                }
            },
            'getforuser':{
                url: 'api/notificationss/forusers',
                method : 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
//                    for(var i=0;i<data.length;i++){
//                        data[i].timestamp = DateUtils.convertDateTimeFromServer(data[i].timestamp);
//                    }
                    // data.timestamp = DateUtils.convertDateTimeFromServer(data.timestamp);
                    return data;
                }
            },
            'getfortopbar':{
                url: 'api/notificationss/fortopbar',
                method : 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'markAllAsRead' : {method:'PUT',url:'api/notificationss/markasread'}
        });
    })
    .factory('MarkAsReadOne', function ($resource) {
        return $resource('api/notificationss/markasreadone', {}, {
            'update': { method:'PUT'},
        });
    });
