/**
 * Created by rahul on 6/9/16.
 */
angular.module('adelementAdminApp')
    .factory('GetAudiences', function ($resource) {
        return $resource('api/audiences/get/:advertiserId', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        })
    })
    .factory('GetAdvertiserIDName', function ($resource) {
            return $resource('api/aeadvertisersidname', {}, {
                'query': { method: 'GET', isArray: true},
            })
    })
    .factory('UpdateAudStatus', function ($resource) {
        return $resource('api/audiences/updatestatus', {}, {
            'update': { method:'PUT' }
        });
    });
