angular
    .module('adelementAdminApp')
    .controller('DashboardController',['$scope','$rootScope','$state','$stateParams','AeAccount','Notifications','Auth','Principal','currentUserX','Reports',function($scope,$rootScope,$state,$stateParams,AeAccount,Notifications,Auth,Principal,currentUserX,Reports){

        function initControllerVariables(){
            $scope.data = {};
            $scope.state = $state;
            $scope.accountid = {};
            $scope.accounts = [];
            $scope.notificationlist = {};
            $scope.account = {};
            $scope.accountsLoaded = false;
            $scope.campNames = {};//hashmap of campid, new campName; maintained for campaign rename logic
            $scope.adgpNames = {};//hashmap of adgpid, new adgroupName; maintained for adgroup rename logic
        }

        function initServices(){
            getUserPreferences();
        }
        function getUserPreferences(){
            if($scope.account !== null && $scope.account !== undefined) {
                Reports.getUserPreferences($scope.account.id, "report").then(function (response) {
                    $rootScope.preferencesData = eval("(" + response.data + ")");
                    getNotifications();
                });
            }
            else{
                getNotifications();
            }
        }
        function getNotifications(){
            Notifications.getfortopbar(function(result,headers){
                $scope.notificationlist = result;
            });
        }

        $scope.getAccountsData = function(){
            $scope.accountsLoaded = true;
            AeAccount.getAccounts().then(function(data){
                $scope.accounts = [];
                for(var i=0;i<data.length;i++){
                    if(data[i].status==1){
                        data[i].image = "https://secure.gravatar.com/avatar/eb8a835bf49342bcf9536589b6f6d9ef?s=250&d=mm&r=g";
                        $scope.accounts.push(data[i]);
                    }
                }
                $scope.accountsLoaded = false;
            });

        };

        $scope.selectAccount = function(index,data){
            $state.go('dashboard.account',{accid:index.id});
        };

        $scope.logout = function() {
            Auth.logout();
            $state.go('home');
        };

        $scope.markAllAsRead = function(){
            Notifications.markAllAsRead(function(data){
                //code here
                getNotifications();
            });
        };

        function getAccount() {
                // $scope.authorize = authorize;
                $scope.account = currentUserX;
                $scope.isAuthenticated = Principal.isAuthenticated;
                initServices();

        }
        initControllerVariables();
        getAccount();
    }]);
angular
    .module('adelementAdminApp')
    .controller('AgencyController',['$scope','$rootScope','$state','$stateParams','Reports','$timeout','DTOptionsBuilder','DTColumnBuilder','$templateCache','$compile','DTColumnDefBuilder','$localStorage','dataTableInstance','$sessionStorage','ngDialog','SocialService','$filter','CampaignRename','AdgroupRename','$interval','CampaignManagerService','Dashboard','Currency',function($scope,$rootScope,$state,$stateParams,Reports,$timeout,DTOptionsBuilder,DTColumnBuilder,$templateCache,$compile,DTColumnDefBuilder,$localStorage,dataTableInstance,$sessionStorage,ngDialog,SocialService,$filter,CampaignRename,AdgroupRename,$interval,CampaignManagerService,Dashboard,Currency){
        $scope.reportParamsKeys = $rootScope.reportKeys;
        $scope.reportParamsKeysForPlacementReports = {};
        $scope.dateShow = false;
        if(!$sessionStorage.hasOwnProperty("firstTimeDashboard")){
            $sessionStorage.firstTimeDashboard = true;
        }
        $scope.breadCrum = [];
        $scope.stateParams = $stateParams;
        $scope.reportParamsKeysChartType = $rootScope.reportKeysChartTypes;
        $scope.reportKeysUnits = $rootScope.reportKeysUnits;
        $scope.filterKeysReport = $rootScope.filterKeysReport;
        $scope.startDate = new Date();
        $scope.endDate = new Date();
        $scope.dateRange = {};
        $scope.stateDecide = null;
        var stateType = null;
        $scope.colvis = [];
				$scope.tickerFlagDict = {};
        $scope.conceptorsize = true;
        $scope.segment = "concept";
				$scope.segmentTitle = {"concept": "Ad Id"/*"Ad Concept"*/, "ad": "Ad Id", "size": "Size"};
        $scope.metrics = "all";
        $scope.adFilteringOn = true;
        $scope.tableVisible = false;
        // $scope.modalDates = {
        //     startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD'),
        //     endDate: moment().format('YYYY-MM-DD')
        // };

        var stateProperties = {0:{id:"advertiserId",name:"advertiserName"},1:{id:"campaignId",name:"campaignName"},2:{id:"adgroupId",name:"adgroupName"},3:{id:"adId",name:"adName"},4:{id:"placementId",name:"placementName"}};
        $scope.alphaschart = {"smoothedLine":0.0,"column":0.9};
        $scope.panelids = ["spend","ctr","convrate","roa","chart","table"];

        function getUserPreferences(){
            var id = $scope.account.id;
            Reports.getUserPreferences(id,"report").then(function (response) {
                if(response.data !== null && response.data !== undefined){
                    $rootScope.preferencesData = eval("(" + response.data + ")");
                }
                else{
                    $rootScope.preferencesData = {};
                }
                setDayWeekAndColvisPref();
				$scope.tickerFlagDict = setTickerData();//hot fix, handle this neatly
				setTickerPeriod();
				setShowTickerDataFlag();//flag to show ticker comparison data
                initializeDatePreferences();
                initDecideState();
                initControllerVariables();
                initColvisPref();
                initServices();
            });
        }
        function setDayWeekAndColvisPref(){
            if($sessionStorage.firstTimeDashboard && $state.current.name === "dashboard.agency"){
                $scope.dayorweekbool = $rootScope.preferencesData.hasOwnProperty("dayorweek")?$rootScope.preferencesData.dayorweek:true;
                $sessionStorage.dayorweek = $scope.dayorweekbool;

                $scope.colvis = $rootScope.preferencesData.hasOwnProperty("colvis")?$rootScope.preferencesData.colvis:[];
                $sessionStorage.colvis = $scope.colvis;
            }
            else{
                if($sessionStorage.firstTimeDashboard){
                    $scope.dayorweekbool = true;
                    $scope.colvis = [];
                }
                else{
                    $scope.dayorweekbool = $sessionStorage.dayorweek;
                    $scope.colvis = $sessionStorage.colvis;
                }
            }
        }

				//set default ticker configuration -- hot fix
				function setTickerData(){
            var dicttoreturn = {};
						var len = 0;
						if($rootScope.hasOwnProperty("preferencesData") && $rootScope.preferencesData != null){
							if($rootScope.preferencesData.hasOwnProperty("tickerParamsSelected")){
								len = Object.keys($rootScope.preferencesData.tickerParamsSelected).length;
							}
						}

						if(len > 0) {
                var dicttoreturn = $rootScope.preferencesData.tickerParamsSelected;
                return dicttoreturn;
            }
           	else {//first time
							angular.forEach($scope.paramsdict,function(val,key){
            		dicttoreturn[key] = false;
            	});
							//default settings
							dicttoreturn['sumImpressions'] = true;
							dicttoreturn['ctr'] = true;
							dicttoreturn['sumSpendCents'] = true;
							if($rootScope.preferencesData == null || $rootScope.preferencesData == undefined) {
                                $rootScope.preferencesData = {}
                            }
							$rootScope.preferencesData.tickerParamsSelected = angular.copy(dicttoreturn);
							return dicttoreturn;
					}
				};

        function setTickerPeriod(){
					if($sessionStorage.firstTimeDashboard &&
									($state.current.name === "dashboard.agency" ||
									$state.current.name === "dashboard.account" ||
									$state.current.name === "dashboard.account.campaign" ||
									$state.current.name === "dashboard.account.campaign.adgroup")){
                $scope.tickerPeriod = $rootScope.preferencesData.hasOwnProperty("tickerPeriod")?$rootScope.preferencesData.tickerPeriod:"1";
                $sessionStorage.tickerPeriod = $scope.tickerPeriod;
            }
            else{
                if($sessionStorage.firstTimeDashboard){
                    $scope.tickerPeriod = "1";
                }
                else{
                    $scope.tickerPeriod = $sessionStorage.tickerPeriod;
                }
            }
        }

        //set show ticker comparison data flag
        function setShowTickerDataFlag(){
            if($sessionStorage.firstTimeDashboard &&
									($state.current.name === "dashboard.agency" ||
									$state.current.name === "dashboard.account" ||
									$state.current.name === "dashboard.account.campaign",
									$state.current.name === "dashboard.account.campaign.adgroup")){
                $scope.showTickerData = $rootScope.preferencesData.hasOwnProperty("showTickerData")?$rootScope.preferencesData.showTickerData:"false";
                $sessionStorage.showTickerData = $scope.showTickerData;
            }
            else{
							if($sessionStorage.firstTimeDashboard || $sessionStorage.showTickerData == undefined){
								$scope.showTickerData = false;
							}
							else{
								$scope.showTickerData = $sessionStorage.showTickerData;
							}
           }
        }

        if($state.current.name === "dashboard.agency"){
            $scope.columndefaultsort = 7;
        }
        else{
            $scope.columndefaultsort = 9;
        }
        function createdRow(row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
            // alert(JSON.stringify);
        }


	//function to get object lenth
	var calcObjectLength = function(object){
					var count = 0;
					for(var i in object){
									count++;
					}
					return count;
	};

	//function to create span array of ad review status
	var getAdSpanArray = function(obj){
					var spanArray = "";
					for(var k in obj){
									var v = obj[k];
									var currSpan = "";
									if(k !== 'count'){
													currSpan = '<span>' + v + ' ';
													if(k === 'approved'){
																	currSpan += '<span uib-popover="approved" popover-trigger="mouseenter">';
																	currSpan += '<em class="fa fa-check"></em>';
																	currSpan += '</span>';
													}
													else if(k === 'under_review'){
																	currSpan += '<span uib-popover="under review" popover-trigger="mouseenter">';
																	currSpan += '<em class="fa fa-eye"></em>';
																	currSpan += '</span>';
													}
													else if(k === 'disapproved'){
																	currSpan += '<span uib-popover="disapproved" popover-trigger="mouseenter">';
																	currSpan += '<em class="circle circle-danger"></em>';
																	currSpan += '</span>';
													}
													currSpan += '</span>';
									}
									spanArray += currSpan;
					}
					return spanArray;
	};

	//TICKER UTILITY FUNCTIONS

	//calculate value change percentage
	function calculateDelta(orgVal, oldVal){
		var increaseP = 0;
		if(oldVal != 0){
			var increase = orgVal - oldVal;
			increaseP = (increase/oldVal)*100;
		}
		else {
			increaseP = orgVal;
		}

		return increaseP;
	};

	//create span based on value change percentage
	function getDeltaSpan(increaseP, toggleColor, showTicker){
		var redColors = ['#ffa8a8', '#ff0000', '#ad0d0d', '#6f0d0d'];
		var greenColors = ['#b2ffb2', '#00ff00', '#009900', '#003300'];
		var incSpan = "";
		var incval = $filter('number')(increaseP,2);
		var val = Math.abs(incval);
		var useColor = "";
		var symbolClass = "";

		//DEFINE COLOR INTENSITY LEVEL
		colorLevel = 0;
		if(val <= 5){
			colorLevel = 0;
		}else if(val > 5 && val <= 15){
			colorLevel = 1;
		}else if(val > 15 && val <=30){
			colorLevel = 2;
		}else {
			colorLevel = 3;
		}

		//DEFINE SYMBOL CLASS AND COLOR BASED ON INCREASE
		if(increaseP < 0){
			//decrease in value
			symbolClass = "fa fa-caret-down";
			useColor = redColors[colorLevel];
			if(toggleColor == true){
				useColor = greenColors[colorLevel];
			}
		}
		else {
			//increase in value
			symbolClass = "fa fa-caret-up";
			useColor = greenColors[colorLevel];
			if(toggleColor == true){
				useColor = redColors[colorLevel];
			}
		}

		var hide = false;
		if(showTicker == false){
			hide = true;
		}

		incSpan = '<span title="' +  incval + '%"' +
							'ng-hide="' + hide + '"' +
							'class="'  + symbolClass + '"' +
							' style="font-size:12px;display:inline-block;margin-left:4px;' +
							'color:' + useColor + '">' +
							'</span>';

		return incSpan;
	};

	//caption util function for date formatting
	function getCaptionDate(dtStr){
		var months = ["January", "February", "March", "April",
									"May", "June", "July", "August",
									"September", "October", "November", "December"];

		var dt = new Date(dtStr);
		var fdt = dt.getDate() + " " + months[Number(dt.getMonth())] + ", " + dt.getFullYear();
		return fdt;
	};

	//wrapper function to create delta span
	function handleDeltaWrapper(sArray, toggleColors, showTicker){
		var orgVal = sArray[0];
		var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
		var increaseP = calculateDelta(orgVal, oldVal);
		//console.log(increaseP + ":" + orgVal + "|" + oldVal);
		var incSpan = getDeltaSpan(increaseP, toggleColors, showTicker);
		return incSpan;
	}

	//wrapper to show ticker data along with ticker
	function showTickerDataDiv(oldVal){
		var hide = false;
		if($scope.showTickerData == false){
			hide = true;
		}
		return '<div class="tickerCompData" ng-hide=' + hide + '>' + oldVal + '</div>'
	}

	function showTickerDataDivWithTicker(oldVal, showTicker){
		var hide = false;
		if($scope.showTickerData == false){
			hide = true;
		}
		//return '<div class="tickerCompData" ng-hide=' + hide + '>' + oldVal + '</div>'
		spanHide = false;
		if(showTicker == false){
			spanHide = true;
		}
		symbolClass = "fa fa-caret-up";
		useColor = "transparent";
		incSpan = '<span ' +
							'ng-hide="' + spanHide + '"' +
							'class="'  + symbolClass + '"' +
							' style="font-size:12px;display:inline-block;margin-left:4px;' +
							'color:' + useColor + '">' +
							'</span>';
		oldValSeperator = '<p ng-hide=true>' + "|" + '</p>';
		return '<div class="tickerCompData" ng-hide=' + hide + '>' + oldValSeperator + oldVal +  incSpan + '</div>'
	}

	//~TICKER UTILITY FUNCTIONS


	//get data table options based on current state
	function getDataTableOptions($stateName){
		var $API_URL, $API_DATA;

		//DATATABLE CALLBACKS
		var $footerHandler = function(){
			console.log("inside footerhandler");
            var spanVal = 2;
            var startIndex = 2;
            if($scope.stateDecide === 0) {
                spanVal = 2;
                startIndex = 2;
            }

            else if($scope.stateDecide === 3) {
                spanVal = 3;
                startIndex = 3;
            }
            else {
                spanVal = 4;
                startIndex = 4;
            }

			$table = $scope.dtInstance.dataTable;
			$Api = $scope.dtInstance.DataTable;

			//TOTAL LABEL
			$col = $Api.column(startIndex-1);
			$foot = $Api.column(startIndex-1).footer();
			$foot.innerHTML = "Total";

			$curr = $scope.currencySymbols[$scope.currency];

			/*MAINTAIN COLUMN ORDER*/
			//$Api.column(startIndex++).footer().innerHTML = $scope.dashboard.cards.sumImpressions;//impressions
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumImpressions)//impressions
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumClicks);//clicks
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.ctr,2) + "%";//ctr
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpc,2);//cpc
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpm,2);//cpm
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.sumSpendCents,2);//spend
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.tpSpendCentsAdvCurr,2);//mediacost
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.margin,1) + "%";//margin
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.total_conversions);//all conv
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumAttributedConversions);//attr conv
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumClickConversions);//click conv
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumViewConversions);//view conv
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumOptimizationConversions);//Opt conv
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.conv_total,2) + "%";//all conv rate
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.conv_attributed,2) + "%";//attr conv rate
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.conv_click,2) + "%";//click conv rate
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.conv_view,2) + "%";//view conv rate
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.conv_optimization,2);//Opt conv rate
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpa_total,2);//all cpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpa_attributed,2);//attr cpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpa_click,2);//click cpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpa_view,2);//view cpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.cpa_optimization,2);//Opt cpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.total_revenue,2);//all revenue
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.sumAttributedRevenue,2);//attr revenue
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.sumClickRevenue,2);//click revenue
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.sumViewRevenue,2);//view revenue
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.sumOptimizationRevenue,2);//Opt revenue
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.rpa_total,2);//all rpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.rpa_attributed,2);//attr rpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.rpa_click,2);//click rpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.rpa_view,2);//view rpa
			$Api.column(startIndex++).footer().innerHTML = $curr + $filter('number')($scope.dashboard.cards.rpa_optimization,2);//Opt rpa
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.roas_total,2) + "%";//all roas
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.roas_attributed,2) + "%";//attr roas
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.roas_click,2) + "%";//click roas
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.roas_view,2) + "%";//view roas
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.roas_optimization,2) + "%";//Opt. roas
			//VIDEO STATS
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEComplete);//complete
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEFirstQuartile);//firstQ
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEFullscreen);//fullscreen
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEMidpoint);//midpoint
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEMute);//mute
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEPause);//pause
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumESkip);//skip
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEReplay);//replay
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEResume);//resume
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEStart);//start
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEStop);//stop
			$Api.column(startIndex++).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEThirdQuartile);//ThirdQ
			$Api.column(startIndex).footer().innerHTML = $filter('number')($scope.dashboard.cards.sumEUnmute);//unmute

			//PUT FIELDS HERE
			//$col = $Api.column(startIndex);
			//$foot = $Api.column(startIndex).footer();

			//$foot.hidden = false;//Test
			//$table = $scope.dtInstance.dataTable;
			//$Api = $scope.dtInstance.DataTable;
			//$col = $Api.column(4);
			//$foot = $Api.column(4).footer();
			//$foot.hidden = false;//Test
		};

		var $FOOTERCALLBACK = function(tfoot, data, start, end, display) {
			//console.log("FOOTERCALLBACK: " + tfoot);
			var $delay = 0;
			if($scope.stateDecide === 3){
				$delay = 200;
			}
			$timeout($footerHandler, $delay);
		};

		var $FOOTERCALLBACKOLD = function(tfoot, data, start, end, display) {
			console.log("FOOTERCALLBACK: " + tfoot);
			// alert("footbak call back");
            var spanVal = 2;
            var startIndex = 2;
            if($scope.stateDecide === 0) {
                spanVal = 2;
                startIndex = 2;
            }
            else if($scope.stateDecide === 3) {
                spanVal = 3;
                startIndex = 3;
            }
            else {
                spanVal = 4;
                startIndex = 4;
            }

			$footers = '<th colspan = ' + spanVal + ' align="center">Total</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumImpressions | number }}</th>';
			$footers +=	'<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumClicks | number }}</th>';
			$footers +=	'<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.ctr | number:2}}%</th>';
			$footers +=	'<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpc | number:2}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpm | number:2}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.sumSpendCents | number: 2}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.tpSpendCentsAdvCurr | number: 2 }}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.margin | number:1 }}%</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.total_conversions | number}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumAttributedConversions | number }}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumClickConversions | number}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumViewConversions | number}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumOptimizationConversions | number}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.conv_total | number:2}}%</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.conv_attributed | number:2}}%</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.conv_click | number:2}}%</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.conv_view | number:2}}%</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.conv_optimization | number:2}}%</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpa_total | number:2}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpa_attributed | number:2}}</th>';
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpa_click | number:2 }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpa_view | number:2 }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.cpa_optimization | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.total_revenue | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.sumAttributedRevenue | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.sumClickRevenue | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.sumViewRevenue | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.sumOptimizationRevenue | shortNumber:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.rpa_total | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.rpa_attributed | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.rpa_click | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.rpa_view | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{currencySymbols[currency]}}{{ dashboard.cards.rpa_optimization | number:2}}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.roas_total | number:2}}%</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.roas_attributed | number:2}}%</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.roas_click | number:2}}%</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.roas_view | number:2}}%</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.roas_optimization | number:2}}% </th>' ;
			$footers += '<!-- DATA for VIDEO ADS -->' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEComplete | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEFirstQuartile | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEFullscreen | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEMidpoint | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEMute | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEPause | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumESkip | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEReplay | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEResume | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEStart | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEStop | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEThirdQuartile | number }}</th>' ;
			$footers += '<th data-column-index="' + (startIndex++) + '">{{ dashboard.cards.sumEUnmute | number }}</th>';

			tfoot.innerHTML = $footers;
			$compile(angular.element(tfoot).contents())($scope);

			//PUT FIELDS HERE
			//$col = $Api.column(startIndex);
			//$foot = $Api.column(startIndex).footer();

			//$foot.hidden = false;//Test

			/*tfoot.innerHTML = '<td colspan = ' + spanVal + ' align="center">Total</td>' +
                    '<td>{{ dashboard.cards.sumImpressions | number }}</td>' +
                    '<td>{{ dashboard.cards.sumClicks | number }}</td>' +
                    '<td>{{ dashboard.cards.ctr | number:2}}%</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpc | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpm | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.sumSpendCents | number: 2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.tpSpendCentsAdvCurr | number: 2 }}</td>' +
                    '<td>{{ dashboard.cards.margin | number:1 }}%</td>' +
                    '<td>{{ dashboard.cards.total_conversions | number}}</td>' +
                    '<td>{{ dashboard.cards.sumAttributedConversions | number }}</td>' +
                    '<td>{{ dashboard.cards.sumClickConversions | number}}</td>' +
                    '<td>{{ dashboard.cards.sumViewConversions | number}}</td>' +
                    '<td>{{ dashboard.cards.sumOptimizationConversions | number}}</td>' +
                    '<td>{{ dashboard.cards.conv_total | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.conv_attributed | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.conv_click | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.conv_view | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.conv_optimization | number:2}}%</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpa_total | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpa_attributed | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpa_click | number:2 }}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpa_view | number:2 }}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.cpa_optimization | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.total_revenue | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.sumAttributedRevenue | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.sumClickRevenue | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.sumViewRevenue | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.sumOptimizationRevenue | shortNumber:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.rpa_total | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.rpa_attributed | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.rpa_click | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.rpa_view | number:2}}</td>' +
                    '<td>{{currencySymbols[currency]}}{{ dashboard.cards.rpa_optimization | number:2}}</td>' +
                    '<td>{{ dashboard.cards.roas_total | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.roas_attributed | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.roas_click | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.roas_view | number:2}}%</td>' +
                    '<td>{{ dashboard.cards.roas_optimization | number:2}}% </td>' +
                    '<!-- DATA for VIDEO ADS -->' +
                    '<td>{{ dashboard.cards.sumEComplete | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEFirstQuartile | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEFullscreen | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEMidpoint | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEMute | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEPause | number }}</td>' +
                    '<td>{{ dashboard.cards.sumESkip | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEReplay | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEResume | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEStart | number }}</td>' +
                    '<td>{{ dashboard.cards.sumEStop | number }}</td>' +
										'<td>{{ dashboard.cards.sumEThirdQuartile | number }}</td>' +
										'<td>{{ dashboard.cards.sumEUnmute | number }}</td>';
										$compile(angular.element(tfoot).contents())($scope);*/

			//$table = $scope.dtInstance.dataTable;
			//$Api = $scope.dtInstance.DataTable;
			//$col = $Api.column(4);
			//$foot = $Api.column(4).footer();
			//$foot.hidden = false;//Test

		};
		var $CREATEDROWCALLBACK = function(row, data, index){
			$compile(angular.element(row).contents())($scope);
		};
		var $HEADERCALLBACK = function( thead, data, start, end, display ) {
			$compile(angular.element(thead).contents())($scope);
		};

		//callback on table complete
		var $INITCOMPLETE = function(settings, respJson) {
			console.log("init complete: Settings: " + settings + "Json: " + respJson);
			if($state.current.name === "dashboard.agency" ||
					$state.current.name === "dashboard.account" ||
					$state.current.name === "dashboard.account.campaign" ||
					$state.current.name === "dashboard.account.campaign.adgroup"){
				var prevStartDate = respJson.tickerInfo.prevStartDate;
				var prevEndDate = respJson.tickerInfo.prevEndDate;

				var captionData = "Ticker comparison date range: ";
				var fprevStartDate = getCaptionDate(prevStartDate);
				var fprevEndDate = getCaptionDate(prevEndDate);

				//$scope.tickerRange['Start'] = fprevStartDate;
				//$scope.tickerRange['End'] = fprevEndDate;
				//captionData = '<span ng-model=tickerRange>' + captionData + "{{tickerRange[Start]}}" + " to " + "{{tickerRange[End]}}" + '</span>';
			 	captionData = captionData + fprevStartDate + " to " + fprevEndDate + ".";

				$('<caption/>').html(captionData).appendTo('#tables');
				//$compile(angular.element('#caption').contents())($scope);
			}
		};


		//COMMON REPORT COLUMN DEFINITIONS
		var $DATA_PROP = 'data'
		var $DATA_COLUMNS = [
			DTColumnBuilder.newColumn('sumImpressions').withTitle('Impressions').renderWith(function(data,type,row) {
					//var dataSpan = '<span>' + resval + '</span>';
					//return '<div>' + dataSpan + '<br>' + incSpan + '</div>';
					//return '<div>' + dataSpan + '&nbsp;' + incSpan + '</div>';

					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumImpressions']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumImpressions']);

						return '<div class="nowrap">' + resval + incSpan + divCompare +  '</div>';
					}
					return resval;

					}),
			DTColumnBuilder.newColumn('sumClicks').withTitle('Clicks').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumClicks']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumClicks']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('ctr').withTitle('CTR').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['ctr']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['ctr']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpc').withTitle('CPC').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpc']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpc']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpm').withTitle('CPM').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpm']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpm']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumSpendCents').withTitle('Spend').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumSpendCents']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumSpendCents']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('tpSpendCentsAdvCurr').withTitle('Media Cost').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['tpSpendCentsAdvCurr']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['tpSpendCentsAdvCurr']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('margin').withTitle('Margin').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['margin']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['margin']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('total_conversions').withTitle('All Conv.').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['total_conversions']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['total_conversions']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumAttributedConversions').withTitle('Attr. Conv.').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumAttributedConversions']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumAttributedConversions']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumClickConversions').withTitle('Click Conv.').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumClickConversions']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumClickConversions']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumViewConversions').withTitle('View Conv.').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumViewConversions']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumViewConversions']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumOptimizationConversions').withTitle('Opt. Conv.').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumOptimizationConversions']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumOptimizationConversions']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('conv_total').withTitle('All Conv. Rate').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['conv_total']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['conv_total']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('conv_attributed').withTitle('Attr Conv. Rate').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['conv_attributed']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['conv_attributed']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('conv_click').withTitle('Click Conv. Rate').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['conv_click']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['conv_click']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('conv_view').withTitle('View Conv. Rate').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['conv_view']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['conv_view']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('conv_optimization').withTitle('Opt Conv. Rate').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['conv_optimization']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['conv_optimization']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpa_total').withTitle('All CPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpa_total']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpa_total']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpa_attributed').withTitle('Attr. CPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpa_attributed']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpa_attributed']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpa_click').withTitle('Click CPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpa_click']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpa_click']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpa_view').withTitle('View CPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpa_view']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpa_view']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('cpa_optimization').withTitle('Opt. CPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, true, $scope.tickerFlagDict['cpa_optimization']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['cpa_optimization']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('total_revenue').withTitle('All Revenue').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['total_revenue']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['total_revenue']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumAttributedRevenue').withTitle('Attr. Revenue').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumAttributedRevenue']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumAttributedRevenue']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumClickRevenue').withTitle('Click Revenue').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumClickRevenue']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumClickRevenue']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumViewRevenue').withTitle('View Revenue').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumViewRevenue']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumViewRevenue']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumOptimizationRevenue').withTitle('Optimization Revenue').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumOptimizationRevenue']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumOptimizationRevenue']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('rpa_total').withTitle('All RPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['rpa_total']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['rpa_total']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('rpa_attributed').withTitle('Attr. RPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['rpa_attributed']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['rpa_attributed']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('rpa_click').withTitle('Click RPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['rpa_click']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['rpa_click']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('rpa_view').withTitle('View RPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['rpa_view']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = $scope.currencySymbols[$scope.currency]+""+compareval;
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['rpa_view']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('rpa_optimization').withTitle('Opt. RPA').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = $scope.currencySymbols[$scope.currency]+""+resval;
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['rpa_optimization']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['rpa_optimization']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('roas_total').withTitle('All ROAS').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['roas_total']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['roas_total']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('roas_attributed').withTitle('Attr. ROAS').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['roas_attributed']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['roas_attributed']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('roas_click').withTitle('Click ROAS').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['roas_click']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['roas_click']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('roas_view').withTitle('View ROAS').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['roas_view']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['roas_view']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('roas_optimization').withTitle('Opt. ROAS').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0],2);
					resval = resval+"%";
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['roas_optimization']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal,2);//if exists
						compareval = compareval+"%";
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['roas_optimization']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEComplete').withTitle('Complete').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEComplete']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEComplete']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEFirstQuartile').withTitle('FirstQuartile').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEFirstQuartile']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEFirstQuartile']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEFullscreen').withTitle('Fullscreen').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEFullscreen']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEFullscreen']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEMidpoint').withTitle('Midpoint').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEMidpoint']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEMidpoint']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEMute').withTitle('Mute').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEMute']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEMute']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEPause').withTitle('Pause').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEPause']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEPause']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumESkip').withTitle('Skip').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumESkip']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumESkip']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEReplay').withTitle('Replay').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEReplay']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEReplay']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEResume').withTitle('Resume').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEResume']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEResume']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEStart').withTitle('Start').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEStart']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEStart']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEStop').withTitle('Stop').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEStop']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEStop']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEThirdQuartile').withTitle('ThirdQuartile').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEThirdQuartile']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEThirdQuartile']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),
			DTColumnBuilder.newColumn('sumEUnmute').withTitle('UnMute').renderWith(function(data,type,row) {
					data = data + ''//convert to string for split to work
					var sArray = data.split("|");
					var resval = $filter('number')(sArray[0]);
					if($state.current.name === "dashboard.agency" || $state.current.name === "dashboard.account" || $state.current.name === "dashboard.account.campaign" || $state.current.name === "dashboard.account.campaign.adgroup"){
						var incSpan = handleDeltaWrapper(sArray, false, $scope.tickerFlagDict['sumEUnmute']);

						var oldVal = ( sArray[1] != undefined && !isNaN(parseInt(sArray[1])) ) ?sArray[1]:0;
						var compareval = $filter('number')(oldVal);//if exists
						var divCompare = showTickerDataDivWithTicker(compareval, $scope.tickerFlagDict['sumEUnmute']);

						return '<div class="nowrap">' + resval + incSpan + divCompare + '</div>';
					}
					return resval;
					}),

		];


		var $DT_DOM = 'RCBfr<"datatable-scroll"t>ipl';//div scroll enabled

		//AGENCY DATATABLE OPTIONS
		var $DASHBOARD_AGENCY_API_URL = "api/getaccountreports";
		var $DASHBOARD_AGENCY_API_DATA = function(d){
			d.dateStart = $scope.modalDates.startDate;
			d.dateEnd = $scope.modalDates.endDate;
			d.daysorweek = $scope.dayorweekbool?"DAY":"WEEK";
			d.currency = $scope.currency;
			d.tickerPeriod = $scope.tickerPeriod;
			d.getCharts = "0";
			/*if($scope.getAdvCharts === true){
			 *$scope.getAdvCharts = false;
			 *}*/
		};

		var $DASHBOARD_AGENCY_FOOTERCALLBACK = $FOOTERCALLBACK;
		/*var $DASHBOARD_AGENCY_FOOTERCALLBACK = function (tfoot, data) {
			//alert("dfdfdf");
			$scope.$apply(function () {

					//$scope.sums = computeSums(data);
					var footer = $templateCache.get('tableFooter'),
					$tfoot = angular.element(tfoot),
					content = $compile(footer)($scope),
					visibleData = _this._('tr', { 'filter':'applied' });
					//$tfoot.find('tr').html("sdfsdfsdfsdfsssssssssssssssssssssssss");
					$tfoot.html("sdfsdfsdfsdfsssssssssssssssssssssssss");
					});
		};*/

		var $DASHBOARD_AGENCY_DT_COLUMNS_PREFIX = [
			DTColumnBuilder.newColumn('advertiserId').withTitle('Adv. Id'),
			DTColumnBuilder.newColumn('advertiserName').withTitle('Name').renderWith(function(data,type,row){
					advertiserId = row['advertiserId'];
					// sref = "stateChangeAdv('dashboard.account',{accid:" + advertiserId + "})";
                    sref = "dashboard.account({accid:" + advertiserId + "})";
					return '<a ui-sref="' + sref  + '">' + data + '</a>';
					})
		];

		var $DASHBOARD_AGENCY_CREATEDROWCALLBACK = function(row, rowData, dispayIndex, dispayIndexFull){
			$('td:eq(0)', row).css("text-align", "left");
			$('td:eq(1)', row).attr('class', 'text-left ng-scope');
            $('td:eq(1)', row).attr('memory-directive', '');
			//$('td:eq(2)', row).attr('class', 'white-space:nowrap ng-scope');
			$compile(angular.element(row).contents())($scope);
		};

		//CAMPAIGN LEVEL
		var $DASHBOARD_ACCOUNT_API_URL = "api/getaccountcampaignreports";
		var $DASHBOARD_ACCOUNT_API_DATA = function(d){
			d.dateStart = $scope.modalDates.startDate;
			d.dateEnd = $scope.modalDates.endDate;
			d.daysorweek = $scope.dayorweekbool?"DAY":"WEEK";
			d.currency = $scope.currency;
			d.advertiserId = $stateParams.accid;
			d.tickerPeriod = $scope.tickerPeriod;
			d.getCharts = "0";
			/*if($scope.getAdvCharts === true){
			 *$scope.getAdvCharts = false;
			 *}
			 */
		};
		$scope.selectAllElements = false;
        var titleHtml = '<input type="checkbox" ng-model="selectAllElements" ng-change="toggleAll()" style="text-align: left !important;">';
        $scope.selected = {};
        $scope.explicitSelection = {};
        $scope.toggleAll = function(){
            // console.log($scope.dashboard);
            var key = "";
            if($scope.stateDecide == 1) {
                key = "campaignId";
            }
            else if($scope.stateDecide == 2) {
                key = "adgroupId";
            }
            else if($scope.stateDecide == 3) {
                key = "adId";
            }
            for (obj in $scope.dashboard.tables) {
                $scope.selected[$scope.dashboard.tables[obj][key]] = $scope.selectAllElements;
            }
            // Object.keys($scope.selected).map(function(key, index) {
            //     $scope.selected[key]=$scope.selectAllElements;
            // });
            $scope.explicitSelection = {};
        };
        $scope.explicitSelect = function (id) {
            $scope.explicitSelection[id] = true;
        };
		var $DASHBOARD_ACCOUNT_DT_COLUMNS_PREFIX = [
        DTColumnBuilder.newColumn(null).withClass("left-align").withTitle(titleHtml).notSortable().renderWith(function(data, type, row) {
            // alert(JSON.stringify(data));
            if(!$scope.explicitSelection[data.campaignId]) {
                $scope.selected[data.campaignId] = $scope.selectAllElements;
            }
            return '<input type="checkbox" ng-model="selected[' + data.campaignId + ']" ng-change="explicitSelect('+data.campaignId+')">';
        }),
			DTColumnBuilder.newColumn('campaignId').withTitle('Camp. Id').renderWith(function(data, type, row){
				var ngClass = "";
				var status = row["status"];
				if (status === 'paused') {
					ngClass = 'circle circle-danger circle-md text-left';
				}
				else {
					ngClass = 'circle circle-success circle-md text-left';
				}
				return '<toggle-state cls="dt-lefthalf ' + ngClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="campaign" mapping="false" state="'+status+'" mapping-type="none"></toggle-state>';
			}),
			DTColumnBuilder.newColumn('campaignName').withTitle('Camp Name').renderWith(function(data,type,row){
					/*
					   <td ng-if="stateDecide === 1" class="text-left" ng-init="parTd = this" ng-mouseover="showEditIcon(tabledata.campaignId)" ng-mouseleave="hideEditIcon()">
					   <div class="alink">
					   <a class="dt-lefthalf" ui-sref="dashboard.account.campaign({ accid:tabledata.advertiserId,id: tabledata.campaignId })" editable-text="tabledata.campaignName" e-form="{{'eform'+tabledata.campaignId}}" onaftersave="renameCampaign(tabledata.campaignName,tabledata.campaignId)">
					   {{tabledata.campaignName}}
					   </a>
					   <div class="alink_edit" ng-show="ehover">
					   <a ng-click="showEform(parTd,tabledata.campaignId)">
					   <em class="fa fa-pencil"></em>
					   </a>
					   </div>
					   </div>
					   </td>

					 */

				advertiserId = row['advertiserId'];
				campaignId = row['campaignId'];
				campaignName = data;

				//set campaign name in campNames hashmap for using as editable text
				$scope.campNames[campaignId] = campaignName;

				//ngEv1 = 'ng-init="parDiv = this" ng-mouseover="showEditIcon2(' + campaignId + ',' + "this" + ')" ng-mouseleave="hideEditIcon()"';
				//ngEv1 = 'ng-init="parDiv = this" ng-mouseover="showEditIcon(' + campaignId + ',' + "tdref" + ')" ng-mouseleave="hideEditIcon()"';
				//ngEv1 = 'ng-init="parDiv = this" ng-mouseover="showEditIcon2(' + campaignId + ')"' +
				//'ng-mouseleave="hideEditIcon2(' + campaignId + ')"';

				ngEv1 = "";//set div attributes here
				ngEv2 = 'ng-model = "' + 'campNames[' + campaignId + ']"';//set anchor tag attributes here

				alinkDivName = "alink";
				alinkDivId = "alink" + campaignId;
                // var changestate = "stateChangeAdv('dashboard.account.campaign',{ accid:" + advertiserId + ",id:"+campaignId+"})";
				tdiv = '<div ng-cloak id = "' +  alinkDivId + '"' + 'class="' + alinkDivName + '"' + ngEv1 + '>' +
									'<a ' + ngEv2 + ' class="dt-lefthalf" href="#/campaigns/' + advertiserId +
                    '/' + campaignId + '"' +
									'editable-text=' + 'campNames[' + campaignId +']' + ' ' +
									'e-form=' + "eform" + campaignId + ' ' +
									'onaftersave=' +
									'"renameCampaign(' + 'campNames[' + campaignId +']' + ',' + campaignId + ')"' + '>' +
										'{{campNames[' + campaignId +']' + '}} ' +
									'</a>' +
									'<div ' + 'id="' + alinkDivId + '_edit" ' +
										'class="' + alinkDivName + '_edit" ' +
										'ng-show="ehover' + campaignId + '">' +
                                        '<a ui-sref="campaign-manager.edit-campaign({ advertiserId:' + advertiserId + ',campaignId:' + campaignId +'})"' + '>' +
											'<em class="fa fa-pencil"></em>' +
										'</a>' +
									'</div>' +
								'</div>';

				return tdiv;
			}),
			DTColumnBuilder.newColumn('dailyBudgetCents').withTitle('Budget').renderWith(function(data,type,row){
				var advertiserId = row['advertiserId'];
				var campaignId = row['campaignId'];
				var budgetType = row['budgetType'];
				var spendAsOfNow = $filter('number')((row['spendAsOfNow']/100),2);
				var spendAsOfNowstr = spendAsOfNow.replace(",","").replace(".00","");
				var dailyBudget = $filter('number')((data/100),2);
				var ngEv = 'ng-click="openBudgetSchedule(' + advertiserId + ',' + campaignId + ')"';
				var bdata = $scope.currencySymbols[$scope.currency] + "" + dailyBudget;
				var budget = dailyBudget.replace(",","").replace(".00","");
				var percentage = Math.floor((parseInt(spendAsOfNowstr)/parseInt(budget))*100);
                var type="danger";
                var budgetStr = budgetType+" spend reached: "+spendAsOfNow+"\n remaining budget: "+$filter('number')((parseInt(budget)-parseInt(spendAsOfNowstr)).toString(),2);
                if(spendAsOfNowstr === "0")
                    budgetStr = "budget not active";
				if (percentage < 25) {
                    type = 'danger';
                } else if (percentage < 50) {
                    type = 'warning';
                } else if (percentage < 75) {
                    type = 'info';
                } else {
                    type = 'success';
                }
				var tdiv = '<div uib-tooltip="'+budgetStr+'" tooltip-append-to-body="true" tooltip-class="customClassDesc" '  + ngEv + '><uib-progressbar class="progress-striped active mb0 spend-bar" value="'+spendAsOfNowstr+'" max="'+budget+'" type="'+type+'"><i>'+bdata+'</i></uib-progressbar></div>';
				return tdiv;
			})
		];


		var $DASHBOARD_ACCOUNT_FOOTERCALLBACK = $DASHBOARD_AGENCY_FOOTERCALLBACK;//using common footer callback

		var $DASHBOARD_ACCOUNT_CREATEDROWCALLBACK = function(row, rowData, dispayIndex, dispayIndexFull){
			var str = "Row: " + row + "Data: " + rowData + "dI: " + dispayIndex + "dIF: " + dispayIndexFull;
			//console.log(str);
			/*
			   <td ng-if="stateDecide === 1" class="text-left" ng-init="parTd = this" ng-mouseover="showEditIcon(tabledata.campaignId)" ng-mouseleave="hideEditIcon()">
			   <div class="alink">
			   <a class="dt-lefthalf" ui-sref="dashboard.account.campaign({ accid:tabledata.advertiserId,id: tabledata.campaignId })" editable-text="tabledata.campaignName" e-form="{{'eform'+tabledata.campaignId}}" onaftersave="renameCampaign(tabledata.campaignName,tabledata.campaignId)">
			   {{tabledata.campaignName}}
			   </a>
			   <div class="alink_edit" ng-show="ehover">
			   <a ng-click="showEform(parTd,tabledata.campaignId)">
			   <em class="fa fa-pencil"></em>
			   </a>
			   </div>
			   </div>
			   </td>

			 */


			var advertiserId = rowData['advertiserId'];
			var campaignId = rowData['campaignId'];
			var campaignName = rowData['campaignName'];

			//campaignID <td> tag
			$('td:eq(1)', row).css("text-align", "left");

			//campaignName <td> tag
			//$('td:eq(1)', row).css("text-align", "left");
			$('td:eq(2)', row).unbind('init');
			$('td:eq(2)', row).bind('init', "parTd = this");
            $('td:eq(2)', row).attr('memory-directive', '');
			$('td:eq(2)', row).attr('class', 'text-left ng-scope');
			$('td:eq(2)', row).attr('ng-init', "parTd = this");
			$('td:eq(2)', row).attr('ng-mouseover', 'showEditIcon2(' + campaignId + ')');
			$('td:eq(2)', row).attr('ng-mouseleave', 'hideEditIcon2(' + campaignId + ')');


			$compile(angular.element(row).contents())($scope);

		};

		//ADGROUP LEVEL
		var $DASHBOARD_ACCOUNT_CAMPAIGN_API_URL = "api/getaccountcampaignadgroupreports";
		var $DASHBOARD_ACCOUNT_CAMPAIGN_API_DATA = function(d){
			d.dateStart = $scope.modalDates.startDate;
			d.dateEnd = $scope.modalDates.endDate;
			d.daysorweek = $scope.dayorweekbool?"DAY":"WEEK";
			d.currency = $scope.currency;
			d.advertiserId = $stateParams.accid;
			d.campaignId = $stateParams.id;
			d.tickerPeriod = $scope.tickerPeriod;
			d.getCharts = "0";
			/*if($scope.getAdvCharts === true){
			 *$scope.getAdvCharts = false;
			 *}
			 */
		};

		var $DASHBOARD_ACCOUNT_CAMPAIGN_DT_COLUMNS_PREFIX = [
            DTColumnBuilder.newColumn(null).withClass("left-align").withTitle(titleHtml).notSortable().renderWith(function(data, type, row) {
                // alert(JSON.stringify(data));
                if(!$scope.explicitSelection[data.adgroupId]) {
                    $scope.selected[data.adgroupId] = $scope.selectAllElements;
                }
                return '<input type="checkbox" ng-model="selected[' + data.adgroupId + ']" ng-change="explicitSelect('+data.adgroupId+')">';
            }),
			DTColumnBuilder.newColumn('adgroupId').withTitle('Adgroup. Id').renderWith(function(data, type, row){
			var ngClass;
            var status = row["status"];
			if (status === 'paused') {
				ngClass = 'circle circle-danger circle-md text-left';
			}
			else {
				ngClass = 'circle circle-success circle-md text-left';
			}
			// return '<span class="dt-lefthalf ' + ngClass + '"></span>' + data;
                return '<toggle-state cls="dt-lefthalf ' + ngClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="adgroup" mapping="false" mapping-type="none" state="'+status+'"></toggle-state>';
			}),
			DTColumnBuilder.newColumn('adgroupName').withTitle('Adgroup Name').renderWith(function(data,type,row){
      	/*<td ng-if="stateDecide === 2" class="text-left"
					ng-init="parTd = this" ng-mouseover="showEditIcon(tabledata.adgroupId)" ng-mouseleave="hideEditIcon()">
					<div class="alink">
						<a class="dt-lefthalf" ui-sref="dashboard.account.campaign.adgroup({ accid:tabledata.advertiserId,campid: tabledata.campaignId,id:tabledata.adgroupId})" editable-text="tabledata.adgroupName" e-form="{{'eform'+tabledata.adgroupId}}" onaftersave="renameAdgroup(tabledata.adgroupName,tabledata.adgroupId)">
							{{tabledata.adgroupName}}
						</a>
						<div class="alink_edit" ng-show="ehover">
							<a ng-click="showEform(parTd,tabledata.adgroupId)">
								<em class="fa fa-pencil"></em>
							</a>
						</div>
        	</div>
       	</td>
				*/

				var advertiserId = row['advertiserId'];
				var campaignId = row['campaignId'];
				var adgroupId = row['adgroupId'];
				var adgroupName = data;
				$scope.adgpNames[adgroupId] = adgroupName;

				//ngEv1 = 'ng-init="parDiv = this" ng-mouseover="showEditIcon2(' + campaignId + ',' + "this" + ')" ng-mouseleave="hideEditIcon()"';
				//ngEv1 = 'ng-init="parDiv = this" ng-mouseover="showEditIcon(' + campaignId + ',' + "tdref" + ')" ng-mouseleave="hideEditIcon()"';
				//ngEv1 = 'ng-init="parDiv = this" ng-mouseover="showEditIcon2(' + campaignId + ')"' +
				//'ng-mouseleave="hideEditIcon2(' + campaignId + ')"';

				ngEv1 = "";//set div attributes here
				//ngEv2 = "";//set anchor tag attributes here
				ngEv2 = 'ng-model = "' + 'adgpNames[' + adgroupId + ']"';//set anchor tag attributes here

				alinkDivName = "alink";
				alinkDivId = "alink" + adgroupId;
				adgpVarName = "adgpName" + adgroupId;
                // var changestate = "stateChangeAdv('dashboard.account.campaign.adgroup',{ accid:" + advertiserId + ",campid:"+campaignId+",id:"+adgroupId+"})";
				tdiv = '<div id = "' +  alinkDivId + '"' + 'class="' + alinkDivName  + '"' + ngEv1 + '>' +
									'<a ' + ngEv2 + ' class="dt-lefthalf" href="#/adgroup/' + advertiserId +
                    '/' + campaignId +
                    '/' + adgroupId + '"' +
										'editable-text=' + 'adgpNames[' + adgroupId +']' + ' ' +
										'e-form=' + "eform" + adgroupId + ' ' +
										'onaftersave=' +
										'"renameAdgroup(' + 'adgpNames[' + adgroupId +']' + ',' + adgroupId + ')"' + '>' +
										'{{adgpNames[' + adgroupId +']' + '}} ' +
									'</a>' +
									'<div ' + 'id="' + alinkDivId + '_edit" ' +
										'class="' + alinkDivName + '_edit" ' +
										'ng-show="ehover' + adgroupId + '">' +
										'<a ui-sref="campaign-manager.edit-adgroup({ advertiserId:' + advertiserId + ',campaignId:' + campaignId + ',adgroupId:' + adgroupId +'})"' + '>' +
										'<em class="fa fa-pencil"></em>' +
										'</a>' +
									'</div>' +
								'</div>';


					return tdiv;
			}),
			DTColumnBuilder.newColumn('maxBidCents').withTitle('Max Bid').renderWith(function(data, type, row){
				var maxBidVal = data/100;
				var maxBid = $filter('number')(maxBidVal,2);
				var dealType = row['dealType'];
				// tdiv = '<div id = "' +  alinkDivId + '"' + 'class="' + alinkDivName + '"' + ngEv1 + '>' +
                	// 								'<a ' + ngEv2 + ' class="dt-lefthalf" href="#/campaigns/' + advertiserId +
                 //                    '/' + campaignId + '"' +
                	// 								'editable-text=' + 'campNames[' + campaignId +']' + ' ' +
                	// 								'e-form=' + "eform" + campaignId + ' ' +
                	// 								'onaftersave=' +
                	// 								'"renameCampaign(' + 'campNames[' + campaignId +']' + ',' + campaignId + ')"' + '>' +
                	// 									'{{campNames[' + campaignId +']' + '}} ' +
                	// 								'</a>' +
                	// 								'<div ' + 'id="' + alinkDivId + '_edit" ' +
                	// 									'class="' + alinkDivName + '_edit" ' +
                	// 									'ng-show="ehover' + campaignId + '">' +
                 //                                        '<a ui-sref="campaign-manager.edit-campaign({ advertiserId:' + advertiserId + ',campaignId:' + campaignId +'})"' + '>' +
                	// 										'<em class="fa fa-pencil"></em>' +
                	// 									'</a>' +
                	// 								'</div>' +
                	// 							'</div>';
                //
                	// 			return tdiv;

				return $scope.currencySymbols[$scope.currency]+""+ maxBid + "(" +  dealType + ")";
			})
		];

		var $DASHBOARD_ACCOUNT_CAMPAIGN_FOOTERCALLBACK = $DASHBOARD_AGENCY_FOOTERCALLBACK;

		var $DASHBOARD_ACCOUNT_CAMPAIGN_CREATEDROWCALLBACK = function(row, rowData, dispayIndex, dispayIndexFull){
			//var str = "Row: " + row + "Data: " + rowData + "dI: " + dispayIndex + "dIF: " + dispayIndexFull;
			//console.log(str);
			/*
			   <td ng-if="stateDecide === 1" class="text-left" ng-init="parTd = this" ng-mouseover="showEditIcon(tabledata.campaignId)" ng-mouseleave="hideEditIcon()">
			   <div class="alink">
			   <a class="dt-lefthalf" ui-sref="dashboard.account.campaign({ accid:tabledata.advertiserId,id: tabledata.campaignId })" editable-text="tabledata.campaignName" e-form="{{'eform'+tabledata.campaignId}}" onaftersave="renameCampaign(tabledata.campaignName,tabledata.campaignId)">
			   {{tabledata.campaignName}}
			   </a>
			   <div class="alink_edit" ng-show="ehover">
			   <a ng-click="showEform(parTd,tabledata.campaignId)">
			   <em class="fa fa-pencil"></em>
			   </a>
			   </div>
			   </div>
			   </td>

			 */

			var advertiserId = rowData['advertiserId'];
			var adgroupId = rowData['adgroupId'];
			var adgroupName = rowData['adgroupName'];

			//adgroupID <td> tag
			$('td:eq(1)', row).css("text-align", "left");

			//adgroupName <td> tag
			//$('td:eq(1)', row).css("text-align", "left");
			$('td:eq(2)', row).unbind('init');
			$('td:eq(2)', row).bind('init', "parTd = this");

			$('td:eq(2)', row).attr('class', 'text-left ng-scope');
			$('td:eq(2)', row).attr('memory-directive', '');
			$('td:eq(2)', row).attr('ng-init', "parTd = this");
			$('td:eq(2)', row).attr('ng-mouseover', 'showEditIcon2(' + adgroupId + ')');
			$('td:eq(2)', row).attr('ng-mouseleave', 'hideEditIcon2(' + adgroupId + ')');

			$compile(angular.element(row).contents())($scope);
		};

		//Ad LEVEL
		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_API_URL = "api/getads";
		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_API_DATA = function(d){
			d.dateStart = $scope.modalDates.startDate;
			d.dateEnd = $scope.modalDates.endDate;
			d.daysorweek = $scope.dayorweekbool?"DAY":"WEEK";
			d.currency = $scope.currency;
			d.tickerPeriod = $scope.tickerPeriod;
			d.advertiserId = $stateParams.accid;
			d.campaignId = $stateParams.campid;
			d.adgroupId = $stateParams.id;
			d.segment = $scope.segment;
			d.metric = $scope.metrics;
			d.tickerPeriod = $scope.tickerPeriod;
			d.getCharts = "0";
			/*if($scope.getAdvCharts === true){
				*$scope.getAdvCharts = false;
				*}
			*/
		};

		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_DT_COLUMNS_PREFIX = [
            DTColumnBuilder.newColumn(null).withClass("left-align").withTitle(titleHtml).notSortable().renderWith(function(data, type, row) {
                // alert(JSON.stringify(data));
                if(!$scope.explicitSelection[data.adId]) {
                    $scope.selected[data.adId] = $scope.selectAllElements;
                }
                return '<input type="checkbox" ng-model="selected[' + data.adId + ']" ng-change="explicitSelect('+data.adId+')">';
            }),
			DTColumnBuilder.newColumn('adId').withTitle('{{segmentTitle[segment]}}').renderWith(function(data, type, row){
				var status = row['status'];
				var reviewStatus = row['reviewStatus'];
				var adId= row['adId'];
				var width = row['width'];
				var height = row['height'];
				var statusCounts = row['statusCounts'] == null?{}:row['statusCounts'];
				var tspan = "";
				var tspanClass = "";
				if($scope.segment == 'ad'){
					//set span status
					if(status == 'active'){
						tspanClass = '"circle circle-success circle-lg"';
                        // tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="false" mapping-type="none" state="'+status+'"></toggle-state>';
					}
					else if(status === 'paused'){
						tspanClass = '"fa fa-pause"';
                        // tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="false" mapping-type="none" state="'+status+'"></toggle-state>';
					}
					else if(status === 'deleted'){
						tspanClass = '"fa fa-times"';
                        // tspan = '<span uib-popover="deleted" popover-trigger="mouseenter" class=' + tspanClass + '></span>';
					}
                    tspan = '<span uib-popover="active" popover-trigger="mouseenter" class=' + tspanClass + '></span>';
					//set span review status
					var emClass = "";
					if(reviewStatus === 'approved'){
						emClass = '<em class="fa fa-check"></em>';
					}
					else if(reviewStatus == 'under_review'){
						emClass = '<em class="fa fa-eye"></em>';
					}
					else if(reviewStatus == 'disapproved'){
						emClass = '<em class="circle circle-danger"></em>';
					}

					tspan += '<span uib-popover=' + '"' + reviewStatus + '"' + ' popover-trigger="mouseenter"> ' + emClass + '</span>';

					//put ad Id as column data if segment is not size
					tspan += '<span><b>' + adId + '</b></span>';
					tspan += '<div>' +  '(' + width + 'x' + height +  ')' + '</div>';
				}
				else if($scope.segment == 'size'){
					//PUT W X H AS COLUMN DATA IF SEGMENT IS SIZE
                    // tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="true" mapping-type="size" state="'+status+'"></toggle-state>';
					tspan += '<span><b>' + width + 'x' + height + '</b></span>';
					if($scope.metrics == "all") {
                        tspan += '<div>'+'<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="true" mapping-type="size" state="'+status+'"></toggle-state>'+'</div>';
                    }
                    else {
                        tspan += '<div>'+'<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="false" mapping-type="none" state="'+status+'"></toggle-state>'+'</div>';
                    }
				}
				else { // for $scope.segment == ad concept
					//put ad Id as column data if segment is not size
                    if($scope.metrics == "all") {
                        tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="true" mapping-type="mappingId" state="'+status+'"></toggle-state>';
                    }
                    else {
                        tspan= '<toggle-state cls="dt-lefthalf ' + tspanClass + '" advertiser-id="'+row['advertiserId']+'" id="'+data+'" call-back="reloadDataTables()" type="ads" mapping="false" mapping-type="none" state="'+status+'"></toggle-state>';
                    }
                    // tspan += '<span><b>' + adId + '</b></span>';
					//tspan += '<div>' +  '(' + width + 'x' + height +  ')' + '</div>';

					//SET ACTIVE, PAUSED AND DELETED DIVS
					var activeCount = statusCounts.hasOwnProperty('active')?statusCounts.active.count:0;
					var pausedCount = statusCounts.hasOwnProperty('paused')?statusCounts.paused.count:0;
					var deletedCount = statusCounts.hasOwnProperty('deleted')?statusCounts.deleted.count:0;

					var szDivActive = "<div></div>", szDivPaused = "<div></div>", szDivDeleted = "<div></div>";

					//ACTIVE DIV
					if(activeCount){
						szDivActive = '<div>';
						szDivActive += '<span uib-popover="active" popover-trigger="mouseenter" class="circle circle-success"></span>';
						szDivActive += '<span>' + activeCount + '</span>';
						var activeObjCount = calcObjectLength(statusCounts.active);
						if(activeObjCount > 1){
							//szDivActive += '<span ng-if="(' + statusCounts.active + ' | objLength) > 1">' +
							var spanArray = getAdSpanArray(statusCounts.active);
							szDivActive += '(' + spanArray + ')';
						}
						szDivActive += '</div>';
					}

					//PAUSED DIV
					if(pausedCount){
						szDivPaused = '<div>';
						szDivPaused += '<em uib-popover="paused" popover-trigger="mouseenter" class="fa fa-pause"></em>';
						szDivPaused += '<span>' + pausedCount + '</span>';
						var pausedObjCount = calcObjectLength(statusCounts.paused);
						if(pausedObjCount > 1){
							//szDivPaused += '<span ng-if="(' + statusCounts.paused + '| objLength) > 1">' +
							var spanArray = getAdSpanArray(statusCounts.paused);
							szDivPaused += '(' + spanArray + ')';
						}
						szDivPaused += '</div>';
					}

					//DELETED DIV
					if(deletedCount){
						szDivDeleted = '<div>';
						szDivDeleted += '<em uib-popover="deleted" popover-trigger="mouseenter" class="fa fa-times"></em>';
						szDivDeleted += '<span>' + deletedCount + '</span>';
						var deletedObjCount = calcObjectLength(statusCounts.deleted);
						if(deletedObjCount > 1){
							//szDivDeleted += '<span ng-if="(' + statusCounts.deleted + '| objLength)>1">' +
							var spanArray = getAdSpanArray(statusCounts.deleted);
							szDivDeleted += '(' + spanArray + ')';
						}
						szDivDeleted += '</div>';
					}

					tspan += szDivActive + szDivPaused + szDivDeleted;
				}

				return '<div><span>' + tspan +'</span></div>'
			}),
			DTColumnBuilder.newColumn('dummy').withTitle('Ad').renderWith(function(data,type,row){
				var adId = row['adId'];

				var tdiv = '<div class="relative-pos adreports">' +
										'<div>' +
											'<ads adid="' + adId + '" scale="true"></ads>' +
                    '</div>' +
											'<div ng-click="openAdPreview(' + adId + ')" class="overlay-ads"></div>' +
                  '</div>'

				return tdiv;
			})
		];

		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_FOOTERCALLBACK = $DASHBOARD_AGENCY_FOOTERCALLBACK;

		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_CREATEDROWCALLBACK = function(row, rowData, dispayIndex, dispayIndexFull){
			//adID <td> tag
			$('td:eq(1)', row).attr('class', 'text-left white-space adpreview');
			$compile(angular.element(row).contents())($scope);
		};


		//PLACEMENT LEVEL
		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_PLACEMENT_API_URL = "api/getplacementreports";

		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_PLACEMENT_API_DATA = function(d){
			d.dateStart = $scope.modalDates.startDate;
			d.dateEnd = $scope.modalDates.endDate;
			d.advertiserId = $stateParams.accid;
			d.campaignId = $stateParams.campid;
			d.adgroupId = $stateParams.id;
		};

		var $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_PLACEMENT_DT_COLUMNS_PREFIX = [
			DTColumnBuilder.newColumn('placementId').withTitle('Id'),
			DTColumnBuilder.newColumn('placementName').withTitle('Placement Name'),
			DTColumnBuilder.newColumn('placementStatus').withTitle('Placement Status')
		];


		//INIT AJAX INNOVATION SETTINGS
		var $AJAX_CALL = 1;
		if($stateName === "dashboard.agency"){
			$API_URL = $DASHBOARD_AGENCY_API_URL;
			$API_DATA = $DASHBOARD_AGENCY_API_DATA;
			$DATA_PROP = 'tickerData';//show tickers
			$FOOTERCALLBACK = $DASHBOARD_AGENCY_FOOTERCALLBACK;
			$DT_COLUMNS_PREFIX = $DASHBOARD_AGENCY_DT_COLUMNS_PREFIX;
			$CREATEDROWCALLBACK = $DASHBOARD_AGENCY_CREATEDROWCALLBACK;
		}
		else if($stateName === "dashboard.account"){
			$API_URL = $DASHBOARD_ACCOUNT_API_URL;
			$API_DATA = $DASHBOARD_ACCOUNT_API_DATA;
			$DATA_PROP = 'tickerData';//show tickers
			$FOOTERCALLBACK = $DASHBOARD_ACCOUNT_FOOTERCALLBACK;
			$DT_COLUMNS_PREFIX = $DASHBOARD_ACCOUNT_DT_COLUMNS_PREFIX;
			$CREATEDROWCALLBACK = $DASHBOARD_ACCOUNT_CREATEDROWCALLBACK;
		}
		else if($stateName === "dashboard.account.campaign"){
			$API_URL = $DASHBOARD_ACCOUNT_CAMPAIGN_API_URL;
			$API_DATA = $DASHBOARD_ACCOUNT_CAMPAIGN_API_DATA;
			$DATA_PROP = 'tickerData';//show tickers
			$FOOTERCALLBACK = $DASHBOARD_ACCOUNT_CAMPAIGN_FOOTERCALLBACK;
			$DT_COLUMNS_PREFIX = $DASHBOARD_ACCOUNT_CAMPAIGN_DT_COLUMNS_PREFIX;
			$CREATEDROWCALLBACK = $DASHBOARD_ACCOUNT_CAMPAIGN_CREATEDROWCALLBACK;
		}
		else if($stateName === "dashboard.account.campaign.adgroup"){//TODO: FIX SOME ISSUES
			$API_URL = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_API_URL;
			$API_DATA = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_API_DATA;
			$DATA_PROP = 'tickerData';//show tickers
			$FOOTERCALLBACK = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_FOOTERCALLBACK;
			$DT_COLUMNS_PREFIX = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_DT_COLUMNS_PREFIX;
			$CREATEDROWCALLBACK = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_CREATEDROWCALLBACK;
		}
		else if($stateName === "dashboard.account.campaign.adgroup.placement2"){//TODO: NOT IN USE TO BE TESTED/REVIEWED
			$API_URL = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_PLACEMENT_API_URL;
			$API_DATA = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_PLACEMENT_API_DATA;
			$FOOTERCALLBACK = $DASHBOARD_AGENCY_FOOTERCALLBACK;
			$DT_COLUMNS_PREFIX = $DASHBOARD_ACCOUNT_CAMPAIGN_ADGROUP_PLACEMENT_DT_COLUMNS_PREFIX;
			$DT_DOM = 'CBfrtipl';//div scroll not needed
		}
		else {
			$AJAX_CALL = 0;//non ajax table
		}

		if($AJAX_CALL === 1){
			//SET DT OPTIONS
			$scope.dtOptions = DTOptionsBuilder.newOptions()
				.withOption('paging',true)
				//.withOption('order',[])
				.withOption('order', [[$scope.columndefaultsort, 'desc']])
				//.withDisplayLength(50)
				.withOption('iDisplayLength', 50)
				.withOption('lengthChange', true)
				.withOption("bSortCellsTop", true)
				//.withOption('scrollX', true)
				//.withOption('scrollY', true)
				//.withOption('scrollXInner', "800%")
				.withOption('scrollCollapse', true)
				.withOption('createdRow', $CREATEDROWCALLBACK)
				.withOption('ajax', {
					url: $API_URL,
					type:"POST",
					data: $API_DATA,
					caption: "caption",
					headers: {"X-CSRF-TOKEN":SocialService.getCSRF()}
				})
				.withFixedHeader({
					bottom: true,
					//footer: true,
					headerOffset:49
				})
				.withDOM($DT_DOM)
				.withOption('headerCallback', $HEADERCALLBACK)
				.withOption('footerCallback',$FOOTERCALLBACK)
				.withOption('initComplete', $INITCOMPLETE)
                .withOption('fnDrawCallback',function(settings){
                    var aData = settings.oAjaxData;
                    if(aData.hasOwnProperty("search")) {
                        if(aData.search.hasOwnProperty("value")) {
                            // if(aData.search.value.length>0) {
                                $scope.dashboard.cards = this.api().ajax.json().cards;
                            // }
                        }
                    }
                })
				//.withOption('drawCallback', $INITCOMPLETE)
				//.withOption('rowCallback', $ROWCALLBACK)
				//.withOption('footerCallback',$FOOTERCALLBACK)
				/*.withOption('footerCallback',
					function (tfoot, data, start, end, display) {
						console.log("footer2 here!!!");
					}
				)
				.withOption('dataTableLoaded',
					function (tfoot, data, start, end, display) {
					console.log("footer2 here!!!");
					}
				)*/
				.withDataProp($DATA_PROP)
				.withOption('processing', true)
				.withOption('serverSide', true)
				.withButtons([
					{extend: 'csvHtml5', text: 'Total Summary',
						//exportOptions: {columns: ':visible'},
						exportData: {decodeEntities:true},
						customize:function(data,config){
							var csvArr = $.csv.toArrays(data);
							var newCSVArr = [];
							//process csv row
							for(var i = 0; i < csvArr.length; i++){
								dataArr = csvArr[i];
								var newDataArr = [];
								for(var j = 0; j < dataArr.length; j++){
									//remove value seperator | if exists for each field
									splitArr = dataArr[j].split('|');
									newDataArr[j] = splitArr[0];
								}
								newCSVArr[i] = newDataArr;
							}
							//form csv using new array
							var newData = $.csv.fromArrays(newCSVArr);
							//console.log(data);
							//console.log(newData);
							return newData;
						}
					},
					'print'
				])
				.withColVis()
				.withColVisStateChange(stateChange)
				.withPaginationType('full_numbers');

				//SET DT COLUMNS
				$scope.dtColumns = [];
					//append prefix colums
					angular.extend($scope.dtColumns, $DT_COLUMNS_PREFIX);
					//append data columns
					for (var i=0; i<$DATA_COLUMNS.length; i++) {
					    $scope.dtColumns.push($DATA_COLUMNS[i]);
					}
		}
        else {
                //NON AJAX CALL
                $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withOption('order', [[$scope.columndefaultsort, 'desc']])
                    .withPaginationType('full_numbers')
                    .withOption('iDisplayLength', 50)
                    .withOption('lengthChange', true)
                    .withOption("bSortCellsTop", true)
                    .withOption('scrollX', true)
                    .withOption('scrollCollapse', true)
                    .withButtons([
                        {extend: 'csvHtml5', text: 'Total Summary'},
                        'print',
                        'copy'])
                    .withFixedHeader({
                        bottom: true,
                        headerOffset: 49
                    })
                    .withColVis()
                    .withColVisStateChange(stateChange)
                    .withDOM('RCBfrtipl');
        }
    }

	function initializeDataTable(){
            $scope.dtInstance = {};
            if($state.current.name === "dashboard.account.campaign.adgroup.placement"){
                $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withOption('paging',true)
                    .withOption('order',[])
                    .withDisplayLength(25)
                    .withOption('lengthChange', true)
                    .withOption("bSortCellsTop", true)
                    .withOption('scrollX', true)
                    .withOption('scrollCollapse', true)
                    .withOption('createdRow', createdRow)
                    .withOption('ajax', {
                        url: "api/getplacementreports",
                        type:"POST",
                        data: function(d){
                            d.dateStart = $scope.modalDates.startDate;
                            d.dateEnd = $scope.modalDates.endDate;
                            d.advertiserId = $stateParams.accid;
                            d.campaignId = $stateParams.campid;
                            d.adgroupId = $stateParams.id;
                        },
                        headers: {"X-CSRF-TOKEN":SocialService.getCSRF()}
                    })
                    .withOption('footerCallback',footerCallback)
                    .withDataProp('data')
                    .withOption('processing', true)
                    .withOption('serverSide', true)
                    .withButtons([
                        { extend: 'csvHtml5', text: 'Total Summary' },
                        'print',
                        'copy'
                    ])
                    .withColVis()
                    .withColVisStateChange(stateChange)
                    .withPaginationType('full_numbers')
                    .withDOM('CBfrtipl');

                $scope.dtColumns = [
                    DTColumnBuilder.newColumn('placementId').withTitle('Id'),
                    DTColumnBuilder.newColumn('placementName').withTitle('Placement Name'),
										DTColumnBuilder.newColumn('placementStatus').withTitle('Placement Status'),
                    DTColumnBuilder.newColumn('sumImpressions').withTitle('Impressions').renderWith(function(data,type,row) {
												var resval = $filter('number')(data);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('sumClicks').withTitle('Clicks').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('ctr').withTitle('CTR').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('cpc').withTitle('CPC').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('cpm').withTitle('CPM').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('sumSpendCents').withTitle('Spend').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('tpSpendCentsAdvCurr').withTitle('Media Cost').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('margin').withTitle('Margin').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('total_conversions').withTitle('All Conv.').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('sumAttributedConversions').withTitle('Attr. Conv.').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('sumClickConversions').withTitle('Click Conv.').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('sumViewConversions').withTitle('View Conv.').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('sumOptimizationConversions').withTitle('Opt. Conv.').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval;
                    }),
                    DTColumnBuilder.newColumn('conv_total').withTitle('All Conv. Rate').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('conv_attributed').withTitle('Attr Conv. Rate').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('conv_click').withTitle('Click Conv. Rate').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('conv_view').withTitle('View Conv. Rate').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('conv_optimization').withTitle('Opt Conv. Rate').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('cpa_total').withTitle('All CPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('cpa_attributed').withTitle('Attr. CPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('cpa_click').withTitle('Click CPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('cpa_view').withTitle('View CPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('cpa_optimization').withTitle('Opt. CPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('total_revenue').withTitle('All Revenue').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('sumAttributedRevenue').withTitle('Attr. Revenue').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('sumClickRevenue').withTitle('Click Revenue').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('sumViewRevenue').withTitle('View Revenue').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('sumOptimizationRevenue').withTitle('Optimization Revenue').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('rpa_total').withTitle('All RPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('rpa_attributed').withTitle('Attr. RPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('rpa_click').withTitle('Click RPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('rpa_view').withTitle('View RPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('rpa_optimization').withTitle('Opt. RPA').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return $scope.currencySymbols[$scope.currency]+""+resval;
                    }),
                    DTColumnBuilder.newColumn('roas_total').withTitle('All ROAS').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('roas_attributed').withTitle('Attr. ROAS').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('roas_click').withTitle('Click ROAS').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('roas_view').withTitle('View ROAS').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    }),
                    DTColumnBuilder.newColumn('roas_optimization').withTitle('Opt. ROAS').renderWith(function(data,type,row) {
                        var resval = $filter('number')(data,2);
                        return resval+"%";
                    })

                ];
                var footerCallback = function (tfoot, data) {
                    //alert("inside footercallback");
										var _this = this;
                    $scope.$apply(function () {

                        //$scope.sums = computeSums(data);
                        var footer = $templateCache.get('tableFooter'),
                            $tfoot = angular.element(tfoot),
                            content = $compile(footer)($scope),
                            visibleData = _this._('tr', { 'filter':'applied' });
                        //$tfoot.find('tr').html("sdfsdfsdfsdfsssssssssssssssssssssssss");
                        $tfoot.html("sdfsdfsdfsdfsssssssssssssssssssssssss");
                    });
                }
            }
						else{
								getDataTableOptions($state.current.name);
						}
				}

				initializeDataTable();//Mukul: by default called in controller

				//angular.element(document.querySelector('datatable-scroll')).on('scroll', syncFixedHeaderScrollbar);

				//jQuery(
					//			function($)
						//		{
							//					$('.datatable-scroll').bind('scroll', syncFixedHeaderScrollbar)
							//	}
			//	);

				// function syncFixedHeaderScrollbar(event) {
				//	console.log("sync scrollbar event");
				// }

        function initColvisPref(){
            $scope.DTColumnDefs = [];
            if($scope.colvis.length > 0){

                for(var i=0;i<$scope.colvis.length;i++){
                    var column = $scope.colvis[i].column;
                    if($state.current.name !== $scope.colvis[i].state){
                        if($state.current.name === "dashboard.agency" ){
                            if($scope.colvis[i].state === "dashboard.account.campaign.adgroup") {
                                column = $scope.colvis[i].column - 1;
                            }
                            else {
                                column = $scope.colvis[i].column - 2;
                            }
                        }
                        else if($state.current.name === "dashboard.account.campaign.adgroup") {
                            if($scope.colvis[i].state === "dashboard.agency") {
                                column = $scope.colvis[i].column + 1;
                            }
                            else {
                                column = $scope.colvis[i].column - 1;
                            }
                        }
                        else if($scope.colvis[i].state === "dashboard.agency"){
                            column = $scope.colvis[i].column + 2;
                        }
                        else if($scope.colvis[i].state === "dashboard.account.campaign.adgroup") {
                            column = $scope.colvis[i].column + 1;
                        }
                    }
                    $scope.DTColumnDefs.push(
														DTColumnDefBuilder.newColumnDef(column)
														.withOption("type",$scope.colvis[i].type)
														.withOption("visible",$scope.colvis[i].status)
														.withOption("cname", $scope.colvis[i].name)//Mukul: added for comparing during state save
										);
                }
            }
            else{
                //maitray reaches here
                //Mukul: Never Reaches here ???
                $scope.DTColumnDefs = [
                    DTColumnDefBuilder.newColumnDef(5).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(6).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(7).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(8).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(20).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(21).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(22).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(23).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(24).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(25).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(26).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(27).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(28).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(29).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(30).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(31).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(32).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(33).withOption("type","currency"),
                    DTColumnDefBuilder.newColumnDef(34).withOption("type","currency")
                ];
            }
            $scope.tableVisible = true;
        }


        function stateChange(column, state) {
            var flag = true;
						//var fnS = $scope.dtInstance.dataTable.fnSettings();
						//var columnData = $scope.dtInstance.dataTable.fnSettings().aoColumns[column];
						//console.log("Column:", $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sTitle);
            for (var i = 0; i < $sessionStorage.colvis.length; i++) {
								//console.log(i, ": colvis: ", $sessionStorage.colvis[i].name);
                //if ($sessionStorage.colvis[i].name === $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sTitle ||
								if ($sessionStorage.colvis[i].name === $scope.dtInstance.dataTable.fnSettings().aoColumns[column].cname) {
                    if ($sessionStorage.colvis[i].status === state) {
                        flag = false;
                        break;
                    }
                    else {
                        flag = false;
                        $sessionStorage.colvis[i].status = state;
                        break;
                    }

                }
            }

						//show or hide footer
						//$scope.dtInstance.DataTable.column(column).footer().hidden = flag;

						if (flag){
                $sessionStorage.colvis.push({
                    column: column,
                    status: state,
                    name: $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sTitle,
                    type: $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sType,
                    state: $state.current.name
                });
						}
        }
        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.ngDestroy();
//            alert("destroy");
        }

        $scope.currencyList = ["INR","USD","GBP","EUR","AUD"];
        $scope.segments = [{name:"Ad Concept",value:"concept"},{name:"Size",value:"size"},{name:"Each Ad",value:"ad"}];
        $scope.metricsList = [{name:"All",value:"all"}];
        $scope.currencySymbols = {"INR":"₹","USD":"$","EUR":"€","GBP":"£","AUD":"$"};
        $scope.card1 = [];
        $scope.card2 = [];
        $scope.card3 = [];
        $scope.card4 = [];
        $scope.ranges  = {
                'Today': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                'Yesterday': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
                'Last 7 Days': [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                'Last 30 Days': [moment().subtract(29, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                'This Month': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
                'Last Month': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')]
        };

        function initializeDatePreferences() {
            if($sessionStorage.firstTimeDashboard && $state.current.name === "dashboard.agency") {
                if ($rootScope.preferencesData.hasOwnProperty("dateLabel")) {
                    if ($rootScope.preferencesData.dateLabel === "custom range") {
                        if ($rootScope.preferencesData.hasOwnProperty("dateRange")) {
                            $scope.modalDates = $rootScope.preferencesData.dateRange;
                            $sessionStorage.dateRange = $rootScope.preferencesData.dateRange;
                        }
                        else {
                            $scope.modalDates = {
                                startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD'),
                                endDate: moment().format('YYYY-MM-DD')
                            };
                        }
                    }
                    else {
                        $scope.modalDates = {
                            startDate: $scope.ranges[$rootScope.preferencesData.dateLabel][0],
                            endDate: $scope.ranges[$rootScope.preferencesData.dateLabel][1]
                        };
                    }
                    $sessionStorage.dateLabel = $rootScope.preferencesData.dateLabel;
                }
                else {
                    $scope.modalDates = {
                        startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    };
                }
            }
            else{
                if ($sessionStorage.hasOwnProperty("dateLabel")) {
                    if ($sessionStorage.dateLabel === "custom range") {
                        if ($sessionStorage.hasOwnProperty("dateRange")) {
                            $scope.modalDates = $sessionStorage.dateRange;
                        }
                        else {
                            $scope.modalDates = {
                                startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD'),
                                endDate: moment().format('YYYY-MM-DD')
                            };
                        }
                    }
                    else {
                        $scope.modalDates = {
                            startDate: $scope.ranges[$sessionStorage.dateLabel][0],
                            endDate: $scope.ranges[$sessionStorage.dateLabel][1]
                        };
                    }
                }
                else {
                    $scope.modalDates = {
                        startDate: moment().subtract(6, 'DAYS').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    };
                }
            }
            $scope.dateShow = true;
        }



        function initControllerVariables(){
            // $('.fixedHeader-floating').remove();
            $scope.services = {"dashboard.agency":{"method":Reports.getAccountLevelReport,"params":$scope.getUrl,"val":1},"dashboard.account":{"method":Reports.getCampaignLevelReport,"params":$scope.getUrl,"val":2},"dashboard.account.campaign":{"method":Reports.getAdgroupLevelReport,"params":$scope.getUrl,"val":3},"dashboard.account.campaign.adgroup":{"method":Reports.getAdLevelReport,"params":$scope.getUrl,"val":4},"dashboard.account.campaign.adgroup.placement":{"method":Reports.getPlacementLevelReport,"params":$scope.getUrl,"val":5}};
            $scope.currencyChange = false;
            if($sessionStorage.firstTimeDashboard && $state.current.name === "dashboard.agency"){
                $scope.currency = $rootScope.preferencesData.hasOwnProperty("currency")?$rootScope.preferencesData.currency:"INR";
                $sessionStorage.currency = $scope.currency;
                $sessionStorage.firstTimeDashboard = false;
								//initialize tickerPeriod
								$scope.tickerPeriod = $rootScope.preferencesData.hasOwnProperty("tickerPeriod")?$rootScope.preferencesData.tickerPeriod:"1";
            }
            else{
                $scope.currency = $sessionStorage.currency;
                if($scope.currency == undefined || $scope.currency == null) {
                    if(Currency != undefined) {
                        $scope.currency = Currency.currency;
                    }

                }
								//initialize tickerPeriod
								$scope.tickerPeriod = $sessionStorage.tickerPeriod;
            }
            $scope.units = {percentage:"%",currency:$scope.currencySymbols[$scope.currency],na:""};
            $scope.unitspos = {percentage:"post",currency:"pre",na:"pre"};
            $scope.user = {
                name: 'awesome user'
            };
            $scope.data = {impr:"1,4,5,4,8,7,10",cpm:"2,5,3,7,4,5",spend:"100,200,50,10,500"};
            $scope.dashboard = {};
            $scope.vals = [];
            $scope.vals2 = JSON.stringify($scope.data.spend);
            $scope.convslides = [{conv:{type:"conversions",value:500},cpa:"$17",convrate:"0.5%"},{conv:{type:"unique click conversions",value:100},cpa:"$2.2",convrate:"0.1%"},{conv:{type:"total click conversions",value:200},cpa:"$1.27",convrate:"0.14%"},{conv:{type:"view conversions",value:500},cpa:"$0.1",convrate:"0.01%"}];

            $scope.chartsAxisOptions1 = [];
            $scope.chartsAxisOptionsForPlacement = [];
            $scope.chartsAxisOptions2 = ["none"];
            $scope.advertiserOptions = ["all"];
            $scope.advertiserDictionary = {"all":"all"};
            $scope.advertiser = "all";
            $scope.axis1 = "sumSpendCents";
            $scope.axis2 = "ctr";
            $scope.axisForPie = "spendCents";
            $scope.chartSubject = "dailytrend";
            $scope.advertiserselectlock = false;
            //$scope.dataSource={ "chart": { "caption": "Actual Revenues, Targeted Revenues & Profits", "subcaption": "Last year", "xaxisname": "Month", "yaxisname": "Amount (In USD)", "numberprefix": "$", "theme": "fint" }, "categories": [ { "category": [ { "label": "Jan" }, { "label": "Feb" }, { "label": "Mar" }, { "label": "Apr" }, { "label": "May" }, { "label": "Jun" }, { "label": "Jul" }, { "label": "Aug" }, { "label": "Sep" }, { "label": "Oct" }, { "label": "Nov" }, { "label": "Dec" } ] } ], "dataset": [ { "seriesname": "Actual Revenue", "data": [ { "value": "16000" }, { "value": "20000" }, { "value": "18000" }, { "value": "19000" }, { "value": "15000" }, { "value": "21000" }, { "value": "16000" }, { "value": "20000" }, { "value": "17000" }, { "value": "25000" }, { "value": "19000" }, { "value": "23000" } ] }, { "seriesname": "Projected Revenue", "renderas": "line", "showvalues": "0", "data": [ { "value": "15000" }, { "value": "16000" }, { "value": "17000" }, { "value": "18000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" }, { "value": "20000" }, { "value": "21000" }, { "value": "22000" }, { "value": "23000" } ] }, { "seriesname": "Profit", "renderas": "area", "showvalues": "0", "data": [ { "value": "4000" }, { "value": "5000" }, { "value": "3000" }, { "value": "4000" }, { "value": "1000" }, { "value": "7000" }, { "value": "1000" }, { "value": "4000" }, { "value": "1000" }, { "value": "8000" }, { "value": "2000" }, { "value": "7000" } ] } ] };
            $scope.chartData = [{ "country": "USA", "visits": 4252 }, { "country": "China", "visits": 1882 }, { "country": "Japan", "visits": 1809 }, { "country": "Germany", "visits": 1322 }, { "country": "UK", "visits": 1122 }, { "country": "France", "visits": 1114 }, { "country": "India", "visits": 984 }, { "country": "Spain", "visits": 711 }, { "country": "Netherlands", "visits": 665 }, { "country": "Russia", "visits": 580 }, { "country": "South Korea", "visits": 443 }, { "country": "Canada", "visits": 441 }, { "country": "Brazil", "visits": 395 }, { "country": "Italy", "visits": 386 }, { "country": "Australia", "visits": 384 }, { "country": "Taiwan", "visits": 338 }, { "country": "Poland", "visits": 328 }];
            if($scope.stateDecide === 4){
                $scope.options = {
                    "type": "pie",
                    "startDuration": 0,
                    "theme": "light",
                    "addClassNames": true,
                    "autoResize": true,
                    "fontFamily": "gotham-book",
                    "defs": {
                        "filter": [{
                            "id": "shadow",
                            "width": "200%",
                            "height": "200%",
                            "feOffset": {
                                "result": "offOut",
                                "in": "SourceAlpha",
                                "dx": 0,
                                "dy": 0
                            },
                            "feGaussianBlur": {
                                "result": "blurOut",
                                "in": "offOut",
                                "stdDeviation": 5
                            },
                            "feBlend": {
                                "in": "SourceGraphic",
                                "in2": "blurOut",
                                "mode": "normal"
                            }
                        }]
                    },
                    "colors": [
                        "#FAAA5A",
                        "#02133A",
                        "#dc3912",
                        "#109618",
                        "#990099",
                        "#0131cc",
                        "#d00d72",
                        "#FAAA5A",
                        "#ca8be4",
                        "#b1d508",
                        "#0099e5",
                        "#57d5a7",
                        "#999999",
                        "#FA615A",
                        "#a3de6f"],
                    "legend": {
                        "divId": "legenddiv",
                        "equalWidths": true,
                        "useGraphSettings": false,
                        "valueAlign": "top",
                        "valueWidth": 100,
                        "position": "bottom",
                        "markerType": "circle",
                        "markerSize": 10,
                        "fontFamily": "gotham-book"
                    },
                    "dataProvider": [],
                    "titleField": "title",
                    "valueField": "value",
                    "labelRadius": 5,
                    "radius": "42%",
                    "innerRadius":"50%",
                    "labelText": "[[title]]",
                    "export": {
                        "enabled": true
                    }
                }
            }
            else {
                $scope.options = {
                    "type": "serial",
                    "theme": "light",
                    "autoResize": true,
                    "fontFamily": "gotham-book",
                    "colors": [
                        "#02133A",
                        "#C63256",
                        "#FAAA5A",
                        "#0099e5",
                        "#ff9900",
                        "#dc3912",
                        "#109618",
                        "#990099",
                        "#0131cc",
                        "#d00d72",
                        "#333333",
                        "#ca8be4",
                        "#b1d508",
                        "#6f4ad4",
                        "#57d5a7",
                        "#999999"
                    ],
                    "legend": {
                        "divId": "legenddiv",
                        "equalWidths": true,
                        "useGraphSettings": false,
                        "valueAlign": "top",
                        "valueWidth": 100,
                        "position": "bottom",
                        "markerType": "circle",
                        "markerSize": 10
                    },
                    "dataProvider": [],
                    "valueAxes": [
                        {
                            "id": $scope.axis1,
                            "axisAlpha": 1,
                            "gridAlpha": 0,
                            "position": "left",
                            "axisColor": "#555555",
                            "axisThickness": 1,
                            "title": $scope.reportParamsKeys[$scope.axis1]
                        },
                        {
                            "id": $scope.axis2,
                            "axisAlpha": 1,
                            "gridAlpha": 0.1,
                            "inside": false,
                            "position": "right",
                            "title": $scope.reportParamsKeys[$scope.axis2],
                            "axisColor": "#555555",
                            "axisThickness": 1
                        }
                    ],
                    "graphs": [
                        {
                            "alphaField": "alpha",
                            "balloonText": $scope.reportKeysUnits[$scope.axis1] === "currency" ? $scope.units[$scope.reportKeysUnits[$scope.axis1]] + " [[value]]" : "[[value]] " + $scope.units[$scope.reportKeysUnits[$scope.axis1]],
                            "dashLengthField": "dashLength",
                            "fillAlphas": 0.9,
                            "legendValueText": $scope.reportKeysUnits[$scope.axis1] === "currency" ? $scope.units[$scope.reportKeysUnits[$scope.axis1]] + " [[value]]" : "[[value]] " + $scope.units[$scope.reportKeysUnits[$scope.axis1]],
                            "title": $scope.reportParamsKeys[$scope.axis1],
                            "type": "column",
                            "valueField": $scope.axis1,
                            "valueAxis": $scope.axis1,
                            "lineThickness": 2
                        },
                        {

                            "bulletBorderAlpha": 1,
                            "bulletBorderThickness": 1,
                            "dashLengthField": "dashLength",
                            "legendValueText": $scope.reportKeysUnits[$scope.axis2] === "currency" ? $scope.units[$scope.reportKeysUnits[$scope.axis2]] + " [[value]]" : "[[value]] " + $scope.units[$scope.reportKeysUnits[$scope.axis2]],
                            "title": $scope.reportParamsKeys[$scope.axis2],
                            "type": "smoothedLine",
                            // "bullet": "round",
                            // "bulletSize": 5,
                            // "fillAlphas": 0.2,
                            "valueField": $scope.axis2,
                            "valueAxis": $scope.axis2,
                            "balloonText": $scope.reportKeysUnits[$scope.axis2] === "currency" ? $scope.units[$scope.reportKeysUnits[$scope.axis2]] + " [[value]]" : "[[value]] " + $scope.units[$scope.reportKeysUnits[$scope.axis2]],
                            "animationPlayed": true,
                            "lineThickness": 2
                        }
                    ],
                    "chartCursor": {
                        "categoryBalloonDateFormat": "YYYY-MM-DD",
                        "cursorAlpha": 0.1,
                        "cursorColor": "#000000",
                        "fullWidth": true,
                        "valueBalloonsEnabled": true,
                        "zoomable": true
                    },
                    "dataDateFormat": "YYYY-MM-DD",
                    "categoryField": "date",
                    "categoryAxis": {
                        "dateFormats": [
                            {
                                "period": "DD",
                                "format": "YYYY-MM-DD"
                            },
                            {
                                "period": "MM",
                                "format": "YYYY-MM-DD"
                            },
                            {
                                "period": "YYYY",
                                "format": "YYYY-MM-DD"
                            }
                        ],
                        "equalSpacing": true,
                        "parseDates": true,
                        "autoGridCount": true,
                        "axisColor": "#555555",
                        "gridAlpha": 0.8,
                        "gridColor": "#DDDDDD"
                    },
                    "export": {
                        "enabled": true
                    },
                    "numberFormatter": {
                        "precision": 2,
                        "decimalSeparator": ".",
                        "thousandsSeparator": ",",
                        "usePrefixes": true,
                        "prefixesOfBigNumbers": [{"number": 1000, "prefix": "k"}, {
                            "number": 1000000,
                            "prefix": "M"
                        }, {"number": 1000000000, "prefix": "G"}, {
                            "number": 1000000000000,
                            "prefix": "T"
                        }, {"number": 1000000000000000, "prefix": "P"}, {
                            "number": 1000000000000000000,
                            "prefix": "E"
                        }, {"number": 1e+21, "prefix": "Z"}, {"number": 1e+24, "prefix": "Y"}],

                    },
                    "responsive": {
                        "enabled": true
                    }
                };
            }

        }
        function initDecideState(){
            if($state.current.name === "dashboard.agency"){
                $scope.stateDecide = 0;
                // $rootScope.$broadcast('dashboardAtHomeState',$stateParams.accid);
                // registerTableEvent();
                $scope.breadCrum = [{name:"All",sref:"dashboard.agency"}];
                stateType = "account";
            }
            else if($state.current.name === "dashboard.account"){
                // $rootScope.$broadcast('dashboardStateChange',$stateParams.accid);
                $scope.stateDecide = 1;
                stateType = "campaign";
                $scope.breadCrum = [{name:"All",sref:"dashboard.agency"},{name:"Campaign",sref:"dashboard.account({accid:" + $stateParams.accid + "})"}];
            }
            else if($state.current.name === "dashboard.account.campaign"){
                // $rootScope.$broadcast('dashboardStateChangeCampaign',$stateParams.accid,{accid:$stateParams.accid,campid:$stateParams.id});
                $scope.stateDecide = 2;
                stateType = "adgroup";
                $scope.breadCrum = [{name:"All",sref:"dashboard.agency"},{name:"Campaign",sref:"dashboard.account({accid:" + $stateParams.accid + "})"},{name:"Adgroup",sref:"dashboard.account.campaign({accid:" + $stateParams.accid + ",id:" + $stateParams.id + "})"}];
            }
            else if($state.current.name === "dashboard.account.campaign.adgroup"){
                $scope.stateDecide = 3;
                stateType = "ads";
                $scope.breadCrum = [{name:"All",sref:"dashboard.agency"},{name:"Campaign",sref:"dashboard.account({accid:" + $stateParams.accid + "})"},{name:"Adgroup",sref:"dashboard.account.campaign({accid:" + $stateParams.accid + ",id:" + $stateParams.campid + "})"},{name:"Ads",sref:"dashboard.account.campaign.adgroup({accid:" + $stateParams.accid + ",campid:" + $stateParams.campid + ",id:" + $stateParams.id + "})"}];
            }
            else if($state.current.name === "dashboard.account.campaign.adgroup.placement"){
                stateType = "placement";
                $scope.stateDecide = 4;
                $scope.advertiserId = $stateParams.accid;
                $scope.campaignId = $stateParams.campid;
                $scope.adgroupId = $stateParams.adgroupid;
                $scope.breadCrum = [{name:"All",sref:"dashboard.agency"},{name:"Campaign",sref:"dashboard.account({accid:" + $stateParams.accid + "})"},{name:"Adgroup",sref:"dashboard.account.campaign({accid:" + $stateParams.accid + ",id:" + $stateParams.campid + "})"},{name:"Placement",sref:"dashboard.account.campaign.adgroup.placement({accid:" + $stateParams.accid + ",campid:" + $stateParams.campid + ",id:" + $stateParams.id + "})"}];
            }
        }

        //function to format timezone
        $scope.advertiserTimezone = "";
        var formatTimezone = function(timezoneObj){
            var input = Math.abs(timezoneObj.offset);
            var hours = parseInt( input / 60, 10 );
            var minutes = (input - (hours * 60)) < 10 ? '0' + (input - (hours * 60)) : input - (hours * 60);

            if (timezoneObj.offset.toString().charAt(0) == '-'){
                $scope.advertiserTimezone = timezoneObj.name+' [ GMT -'+hours+':'+minutes+' ]';
            }else{
                $scope.advertiserTimezone = timezoneObj.name +' [ GMT +'+hours+':'+minutes + ' ]';
            }
        };


        $scope.renameAdgroup = function(adgpName,adgpId){
            var dataToSend = {};
            dataToSend["name"] = adgpName;
            dataToSend["id"] = adgpId;
            dataToSend["flag"] = "rename";
            var onsuccessAdgroupRename = function(response){
            	if($scope.stateDecide === 2){
                $scope.dtInstance.reloadData();
            	}
            };
            var onfailureAdgroupRename = function(response){
            };
            AdgroupRename.update(dataToSend,onsuccessAdgroupRename,onfailureAdgroupRename);
        };
        $scope.showEform = function(obj,id){
            obj["eform"+id].$show();
            this.ehover = false;
        };
        $scope.showEditIcon = function(id){
            var eformname = "eform"+id;
            data = this;
            if(data.hasOwnProperty(eformname) && ! data[eformname].$visible){
                this.ehover = true;
            }
        };

        $scope.hideEditIcon = function(){
            this.ehover = false;
        };

        $scope.showEform2 = function(obj,id){
						console.log("showEform2");
						var eformname = "eform"+id;
						var ehoverVarName = "ehover"+id;
            obj[eformname].$show();
            obj[ehoverVarName] = false;
        };
				$scope.showEditIcon2 = function(id, obj){
            var eformname = "eform"+id;
						var ehoverVarName = "ehover"+id;
						data = this;
            if(data.hasOwnProperty(eformname) && ! data[eformname].$visible){
							$scope[ehoverVarName] = true;
							/*if ($scope[ehoverVarName] === undefined) {
								$scope[ehoverVarName] = true;
							} else {
								$scope[ehoverVarName] = !$scope[ehoverVarName];
							}*/
						}
        };

        $scope.hideEditIcon2 = function(id){
						var ehoverVarName = "ehover"+id;
						$scope[ehoverVarName] = false;
        };

        $scope.renameCampaign = function (campName,campId) {
            var dataToSend = {};
            dataToSend["name"] = campName;
            dataToSend["id"] = campId;
            dataToSend["flag"] = "rename";
            var onsuccessCampaignRename = function(response){
            };
            var onfailureCampaignRename = function(response){
            };
            CampaignRename.update(dataToSend,onsuccessCampaignRename,onfailureCampaignRename);
        };

        $scope.getUrl = function(val){
            var dayorweek = $scope.dayorweekbool?"DAY":"WEEK";
            if(val===1){
                return "api/report/account/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/"+$scope.currency+"/"+dayorweek+"/";
            }
            else if(val===2){
                var advertiserId = $stateParams.accid;
                return "api/report/account/"+advertiserId+"/campaign/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/"+dayorweek+"/";
            }
            else if(val === 3){
                var advertiserId = $stateParams.accid;
                var campaignId = $stateParams.id;
                return "api/report/account/"+advertiserId+"/campaign/"+campaignId+"/adgroup/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/"+dayorweek+"/";
            }
            else if(val === 4){
                var advertiserId = $stateParams.accid;
                var campaignId = $stateParams.campid;
                var adgroupId = $stateParams.id;
                $scope.adgpId = $stateParams.id;
                return "api/report/account/"+advertiserId+"/campaign/"+campaignId+"/adgroup/"+adgroupId+"/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/"+dayorweek+"/"+$scope.segment;
            }
            else if(val === 5){
                var advertiserId = $stateParams.accid;
                var campaignId = $stateParams.campid;
                var adgroupId = $stateParams.id;
                $scope.adgpId = $stateParams.id;
                return "api/report/account/"+advertiserId+"/campaign/"+campaignId+"/adgroup/"+adgroupId+"/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/"+dayorweek+"/placement/";
            }
        };
        function getDataService(type){
            // $scope.advertiserOptions = ["all"];
            $scope.chartSubject = type;
            if($scope.stateDecide === 4){
                $scope.reportParamsKeysForPlacementReports = $rootScope.reportKeysForPlacement;
            }
            // $scope.advertiserDictionary = {"all":"all"};
            $scope.units.currency = $scope.currencySymbols[$scope.currency];
            $scope.options.dataProvider=[];
            var dayorweek = $scope.dayorweekbool?"DAY":"WEEK";
            var service = $scope.services[$state.current.name];
            service.method(service.params(service.val)).then(function(response){
                // console.log(response)
                $scope.dashboard = angular.copy(response);
                if($scope.dashboard.hasOwnProperty("currency")){
                    $scope.currency = $scope.dashboard.currency;
                }
                if($scope.dashboard.hasOwnProperty("tickerPeriod")){
                    $scope.tickerPeriod = $scope.dashboard.tickerPeriod;
                }
                $scope.strimpressions="";
                $scope.str="";
                $scope.data = {};
                var cardkeys = [];
                if($scope.dashboard.tables.length>0){
                    angular.forEach($scope.dashboard[type][0],function(value,key){
                        var mapping = $scope.reportParamsKeys[key];

                        if(!$scope.data.hasOwnProperty(key)){
                            $scope.data[key] = [];
                            cardkeys.push(key);
                        }

                    });
                }

                var dataobj = [];
                $scope.chartsAxisOptions1 = Object.keys($scope.filterKeysReport);
                $scope.chartsAxisOptions2 = Object.keys($scope.filterKeysReport);
                $scope.chartsAxisOptionsForPlacement = Object.keys($rootScope.filterKeysReportForPlacement);
                $scope.chartsAxisOptions2.push("none");
                if($scope.stateDecide === 4){
                    for(var i=0;i<$scope.dashboard.piechart.length;i++){
                        var obj = {};
                        obj.title = $scope.dashboard.piechart[i].placementName;
                        obj.value = $scope.dashboard.piechart[i].sumMetric;
                        $scope.options.dataProvider.push(obj);
                    }

                }
                else{
                    if(dayorweek === "WEEK"){
                        $scope.options.categoryAxis.dateFormats = [{
                            "period": "DD",
                            "format": "YYYY-MM-DD"
                        }, {
                            "period": "WW",
                            "format": "YYYY-MM-DD"
                        }, {
                            "period": "MM",
                            "format": "YYYY-MM-DD"
                        }, {
                            "period": "YYYY",
                            "format": "YYYY-MM-DD"
                        }];
                        // $scope.options.categoryAxis.minPeriod = "7DD";
                        $scope.options.categoryAxis.boldPeriodBeginning = false;

                    }
                    else{
                        $scope.options.categoryAxis.dateFormats = [
                            {
                                "period": "DD",
                                "format": "YYYY-MM-DD"
                            },
                            {
                                "period": "MM",
                                "format": "YYYY-MM-DD"
                            },
                            {
                                "period": "YYYY",
                                "format": "YYYY-MM-DD"
                            }
                        ];
                        if($scope.options.categoryAxis.hasOwnProperty("boldPeriodBeginning")){
                            delete $scope.options.categoryAxis.boldPeriodBeginning;
                        }
                    }
                    for(var i=0;i<$scope.dashboard[type].length;i++){
                        var obj = {};
                        obj.date = $scope.dashboard[type][i].date;
                        obj[$scope.axis1] = $scope.dashboard[type][i][$scope.axis1];
                        obj[$scope.axis2] = $scope.dashboard[type][i][$scope.axis2];
                        $scope.options.dataProvider.push(obj);
                    }
                    for(var i=0;i<$scope.dashboard.charts.length;i++){
                        var id = $scope.dashboard.charts[i][stateProperties[$scope.stateDecide].id];
                        var name = $scope.dashboard.charts[i][stateProperties[$scope.stateDecide].name];
                        if(!$scope.advertiserDictionary.hasOwnProperty(name)){
                            $scope.advertiserDictionary[name] = id;
                            $scope.advertiserOptions.push(name);
                        }

                    }
                    $scope.options.graphs[0].balloonText = $scope.reportKeysUnits[$scope.axis1]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis1]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis1]];
                    $scope.options.graphs[1].balloonText = $scope.reportKeysUnits[$scope.axis2]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis2]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis2]];

                    $scope.options.graphs[0].legendValueText = $scope.reportKeysUnits[$scope.axis1]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis1]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis1]];
                    $scope.options.graphs[1].legendValueText = $scope.reportKeysUnits[$scope.axis2]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis2]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis2]];

                }
                for(var i=0;i<$scope.dashboard["dailytrend"].length;i++) {
                    for (var j = 0; j < cardkeys.length; j++) {
                        var mapping = $scope.reportParamsKeys[cardkeys[j]];
                        $scope.data[cardkeys[j]].push($scope.dashboard["dailytrend"][i][cardkeys[j]]);
                    }
                }

                if($scope.chart){
                    $scope.chart.clear();
                }
                $scope.chart = AmCharts.makeChart("chartdiv", $scope.options);
                $scope.chart.invalidateSize();
                $scope.card1 = $scope.data.sumSpendCents;
                $scope.card2 = $scope.data.ctr;
                $scope.card3 = $scope.data.conv_total;
                $scope.card4 = $scope.data.roas_total;

                if($scope.stateDecide === 3){
                    addToMetricList();
                }
                $timeout(function(){$scope.$emit('removeSpinner',$scope.panelids);}, 50);

                $scope.$broadcast('reoloadcards',{"id":"card1"});
                if($scope.advertiser!="all" && !$scope.currencyChange){
                    $scope.changeAdvertiser();
                }
                if($scope.axis2 == "none"){
                    $scope.changeAxis();
                }
                $scope.$broadcast('reloadReportDataEvent');
                $scope.currencyChange = false;

                if($scope.dashboard.hasOwnProperty("advertiserTimezone") && $scope.dashboard.advertiserTimezone !=null){
                    formatTimezone($scope.dashboard.advertiserTimezone);
                }

            });
        }
        function addToMetricList(){
            var keymetric = "";
            if($scope.segment !== "ad"){
                $scope.adFilteringOn = true;
                if($scope.segment === "concept"){
                    keymetric = "adMappingId";
                }
                else{
                    keymetric = "adUnitId";
                }
                if($scope.metricsList.length>1){
                    $scope.metricsList = $scope.metricsList = [{name:"All",value:"all"}];
                }
                for(var i=0;i<$scope.dashboard.tables.length;i++){
                    if($scope.segment === "concept") {
                        $scope.metricsList.push({
                            name: $scope.dashboard.tables[i][keymetric],
                            value: $scope.dashboard.tables[i][keymetric]
                        });
                    }
                    else{
                        $scope.metricsList.push({
                            name: $scope.dashboard.tables[i].width +"x"+$scope.dashboard.tables[i].height,
                            value: $scope.dashboard.tables[i][keymetric]
                        });
                    }
                }
            }
            else{
                $scope.adFilteringOn = false;
            }
        }
        function rearrangeCardData(){
            $scope.cards = [];
            var obj = {};
            obj.color = "info";
            obj.id = "spend";
            obj.single = true;
            obj.details = [];
            var objdetails = {};
            objdetails.name="impressions";
            objdetails.value = $scope.dashboard.cards.sumImpressions;
            objdetails.data = $scope.dashboard;
            obj.details.push(objdetails);
            $scope.cards.push(obj);

        }
        function handleInit(){
            chart.legend.addListener("rollOverItem", handleRollOver);
        }

        function handleRollOver(e){
            var wedge = e.dataItem.wedge.node;
            wedge.parentNode.appendChild(wedge);
        }
        function initServices(){
            getDataService("dailytrend");
        }

        $scope.initRefresh = function () {
            $timeout(function(){$scope.$emit('panel-refresh', $scope.panelids);}, 50);
        };

        $scope.changeAxis = function(){
            $scope.chart.clear();
            if($scope.axis2 === "none"){

                $scope.chart.clear();
                $scope.chart = null;

                $scope.advertiserselectlock = true;
                $scope.$emit('panel-refresh', ["chart"]);
                $scope.chartSubject = "charts";
                $scope.options.dataProvider = [];
                $scope.options.chartCursor.oneBalloonOnly= true;
                var previous_options = angular.copy($scope.options);
                $scope.options.valueAxes = $scope.reportParamsKeysChartType[$scope.axis1] === "smoothedLine"?[{
                    "id": $scope.axis1,
                    "axisAlpha": 0,
                    "gridAlpha": 0,
                    "position": "left",
                    "title": ""
                }]:[{
                    "stackType": "regular",
                    "axisAlpha": 0.3,
                    "gridAlpha": 0
                }];
                $scope.options.graphs = [];
                var datesobj = {};
                var advs = {};
                for(var i=0;i<$scope.dashboard[$scope.chartSubject].length;i++) {
                    var obj = {};
                    obj.date = $scope.dashboard[$scope.chartSubject][i].date;
                    var advid = $scope.dashboard[$scope.chartSubject][i][stateProperties[$scope.stateDecide].name];
                    if(!datesobj.hasOwnProperty(obj.date)){
                        $scope.options.dataProvider.push(obj);
                        datesobj[obj.date] = '1';
                    }
                    if(!advs.hasOwnProperty(advid)){
                        advs[advid] = '1';
                        var graphschema = {};
                        var valueaxisschema = {};
                        if($scope.reportParamsKeysChartType[$scope.axis1] === "smoothedLine"){
                            graphschema = {
                                "balloonText": "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
                                "bulletBorderAlpha": 1,
                                "bulletBorderThickness": 1,
                                "dashLengthField": "dashLength",
                                "legendValueText": "[[value]]",
                                "title": advid,
                                "type": "smoothedLine",
                                // "bullet": "round",
                                // "bulletSize": 0.5,
                                // "fillAlphas": 0.2,
                                "valueField": advid,
                                "valueAxis": $scope.axis1,
                                "animationPlayed": true
                            };
                            valueaxisschema =  {
                                "id": $scope.axis1,
                                "axisAlpha": 0,
                                "gridAlpha": 0,
                                "position": "left",
                                "title": ""
                            };
                        }
                        else{
                            graphschema = {
                                "balloonText": "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
                                "fillAlphas": 0.8,
                                "labelText": "[[value]]",
                                "lineAlpha": 0.3,
                                "title": advid,
                                "type": "column",
                                "valueField": advid
                            }
                        }
                        $scope.options.graphs.push(graphschema);
                    }
                }
                for(var i=0;i<$scope.dashboard[$scope.chartSubject].length;i++){
                    var obj = {};
                    obj.date = $scope.dashboard[$scope.chartSubject][i].date;
                    var find = false;
                    for(var j=0; j<$scope.options.dataProvider.length;j++){
                        if(obj.date == $scope.options.dataProvider[j].date){
                            $scope.options.dataProvider[j][$scope.dashboard[$scope.chartSubject][i][stateProperties[$scope.stateDecide].name]] = $scope.dashboard[$scope.chartSubject][i][$scope.axis1];
                            find = true;
                        }
                    }
                    if(!find){

                    }
                }

                if($scope.dayorweekbool) {
                    for (var j = 0; j < $scope.options.dataProvider.length; j++) {
                        var chartobj = $scope.options.dataProvider[j];
                        angular.forEach(advs, function (value, key) {
                            if (!chartobj.hasOwnProperty(key)) {
                                $scope.options.dataProvider[j][key] = 0;
                            }
                        });
                    }
                }
                $scope.chartSubject = "charts";
                $scope.chart = AmCharts.makeChart("chartdiv", $scope.options);
                $scope.chart.invalidateSize();
                $scope.$emit('removeSpinner',["chart"]);
                $scope.options = previous_options;
                $scope.options.chartCursor.oneBalloonOnly= false;
            }
            else {
                $scope.advertiserselectlock = false;
                if($scope.advertiser!=="all"){
                    $scope.chartSubject = "charts";
                    $scope.options.dataProvider = [];
                    for(var i=0;i<$scope.dashboard[$scope.chartSubject].length;i++){
                        if($scope.advertiserDictionary.hasOwnProperty($scope.advertiser)){
                            if($scope.advertiserDictionary[$scope.advertiser] == $scope.dashboard[$scope.chartSubject][i][stateProperties[$scope.stateDecide].id]) {
                                var obj = {};
                                obj.date = $scope.dashboard[$scope.chartSubject][i].date;
                                obj[$scope.axis1] = $scope.dashboard[$scope.chartSubject][i][$scope.axis1];
                                obj[$scope.axis2] = $scope.dashboard[$scope.chartSubject][i][$scope.axis2];
                                $scope.options.dataProvider.push(obj);
                            }
                        }

                    }
                }
                else{
                    $scope.chartSubject = "dailytrend";
                    $scope.options.dataProvider = [];
                    for (var i = 0; i < $scope.dashboard[$scope.chartSubject].length; i++) {
                        var obj = {};
                        obj.date = $scope.dashboard[$scope.chartSubject][i].date;
                        obj[$scope.axis1] = $scope.dashboard[$scope.chartSubject][i][$scope.axis1];
                        obj[$scope.axis2] = $scope.dashboard[$scope.chartSubject][i][$scope.axis2];
                        $scope.options.dataProvider.push(obj);
                    }
                }
                $scope.options.valueAxes[0].id = $scope.axis1;
                $scope.options.valueAxes[1].id = $scope.axis2;
                $scope.options.valueAxes[1].title = $scope.reportParamsKeys[$scope.axis2];
                $scope.options.valueAxes[0].title = $scope.reportParamsKeys[$scope.axis1];

                $scope.options.graphs[0].valueField = $scope.axis1;
                $scope.options.graphs[1].valueField = $scope.axis2;

                $scope.options.graphs[0].balloonText = $scope.reportKeysUnits[$scope.axis1]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis1]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis1]];
                $scope.options.graphs[1].balloonText = $scope.reportKeysUnits[$scope.axis2]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis2]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis2]];

                $scope.options.graphs[0].legendValueText = $scope.reportKeysUnits[$scope.axis1]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis1]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis1]];
                $scope.options.graphs[1].legendValueText = $scope.reportKeysUnits[$scope.axis2]==="currency"?$scope.units[$scope.reportKeysUnits[$scope.axis2]]+" [[value]]":"[[value]] "+$scope.units[$scope.reportKeysUnits[$scope.axis2]];

                $scope.options.graphs[0].valueAxis = $scope.axis1;
                $scope.options.graphs[1].valueAxis = $scope.axis2;

                $scope.options.graphs[0].title = $scope.reportParamsKeys[$scope.axis1];
                $scope.options.graphs[1].title = $scope.reportParamsKeys[$scope.axis2];

                $scope.options.graphs[0].type = $scope.reportParamsKeysChartType[$scope.axis1];
                $scope.options.graphs[1].type = $scope.reportParamsKeysChartType[$scope.axis2];

                $scope.options.graphs[0].fillAlphas = $scope.alphaschart[$scope.options.graphs[0].type];
                $scope.options.graphs[1].fillAlphas = $scope.alphaschart[$scope.options.graphs[1].type];

                $scope.chart = AmCharts.makeChart("chartdiv", $scope.options);
                $scope.chart.invalidateSize();
            }
        };
        $scope.changeAxisForPlacement = function(axis){
            var advertiserId = $stateParams.accid;
            var campaignId = $stateParams.campid;
            var adgroupId = $stateParams.id;
            $scope.adgpId = $stateParams.id;
            var url = "api/report/account/placement/"+advertiserId+"/campaign/"+campaignId+"/adgroup/"+adgroupId+"/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/"+axis+"/piedata";
            Reports.getPlacementPieChartData(url).then(function (data) {
                $scope.options.dataProvider = [];
                for(var i=0;i<data.length;i++){
                    var obj = {};
                    obj.title = data[i].placementName;
                    obj.value = data[i].sumMetric;
                    $scope.options.dataProvider.push(obj);
                }
                $scope.chart.clear();
                $scope.chart = AmCharts.makeChart("chartdiv", $scope.options);
                $scope.chart.invalidateSize();

            });
        };

        $scope.changeAdvertiser = function(){
            $scope.chart.clear();
            $scope.options.dataProvider = [];
            if($scope.advertiser=="all"){
                $scope.chartSubject = "dailytrend";
                for(var i=0;i<$scope.dashboard[$scope.chartSubject].length;i++){
                    var obj = {};
                    obj.date = $scope.dashboard[$scope.chartSubject][i].date;
                    obj[$scope.axis1] = $scope.dashboard[$scope.chartSubject][i][$scope.axis1];
                    obj[$scope.axis2] = $scope.dashboard[$scope.chartSubject][i][$scope.axis2];
                    $scope.options.dataProvider.push(obj);
                }
            }
            else{
                $scope.chartSubject = "charts";
                for(var i=0;i<$scope.dashboard[$scope.chartSubject].length;i++){
                    if($scope.advertiserDictionary.hasOwnProperty($scope.advertiser)){
                        if($scope.advertiserDictionary[$scope.advertiser] == $scope.dashboard[$scope.chartSubject][i][stateProperties[$scope.stateDecide].id]) {
                            var obj = {};
                            obj.date = $scope.dashboard[$scope.chartSubject][i].date;
                            obj[$scope.axis1] = $scope.dashboard[$scope.chartSubject][i][$scope.axis1];
                            obj[$scope.axis2] = $scope.dashboard[$scope.chartSubject][i][$scope.axis2];
                            $scope.options.dataProvider.push(obj);
                        }
                    }

                }
            }
            $scope.options.valueAxes[0].id = $scope.axis1;
            $scope.options.valueAxes[1].id = $scope.axis2;
            $scope.options.valueAxes[1].title = $scope.reportParamsKeys[$scope.axis2];

            $scope.options.graphs[0].valueField = $scope.axis1;
            $scope.options.graphs[1].valueField = $scope.axis2;

            $scope.options.graphs[0].valueAxis = $scope.axis1;
            $scope.options.graphs[1].valueAxis = $scope.axis2;

            $scope.options.graphs[0].title = $scope.reportParamsKeys[$scope.axis1];
            $scope.options.graphs[1].title = $scope.reportParamsKeys[$scope.axis2];

            $scope.chart = AmCharts.makeChart("chartdiv", $scope.options);
            $scope.chart.invalidateSize();

        };
        $scope.dateChanged = function(label){
            $sessionStorage.dateLabel = label;
            $scope.chartSubject = null;
            $scope.chartSubject = "dailytrend";
            if(label === "custom range"){
                $sessionStorage.dateRange=$scope.modalDates;
            }
            $scope.$emit('panel-refresh', $scope.panelids);
            getDataService("dailytrend");
						//if($scope.stateDecide !== 3){//Mukul: currently ad level report not supported with ajax
                $scope.dtInstance.reloadData();
            //}
        };
        $scope.currencyChanged = function () {
            $sessionStorage.currency = $scope.currency;
            $scope.advertiserOptions = ["all"];
            $scope.advertiserDictionary = {"all":"all"};
            $scope.currencyChange = true;
            $scope.advertiser="all";
            $scope.chartSubject = "dailytrend";
            $scope.$emit('panel-refresh', $scope.panelids);
            getDataService("dailytrend");
            if($scope.stateDecide === 0){//currency change on at agency level report
                $scope.dtInstance.reloadData();
            }
        };
        $scope.dayorweekchange = function(){
            $sessionStorage.dayorweek = $scope.dayorweekbool;
            $scope.$emit('panel-refresh', $scope.panelids);
            getDataService("dailytrend");
						//if($scope.stateDecide !== 3){//Mukul: currently ad level report not supported with ajax
            	$scope.dtInstance.reloadData();
						//}
        };
        $scope.tickerPeriodChanged = function(period){
        	$sessionStorage.tickerPeriod = period;
          //$scope.dtInstance.reloadData();
				};
				$scope.showTickerComparisonChanged = function(flag){
					$sessionStorage.showTickerData = $scope.showTickerData = flag;
					$scope.dtInstance.reloadData();
				};
        $scope.getHeaders = function(){
            var keys = Object.keys($scope.dashboard.charts[0]);
            var headers = [];
            for(var i=0;i<keys.length;i++){
                headers.push($scope.reportParamsKeys[keys[i]]?$scope.reportParamsKeys[keys[i]]:keys[i]);
            }
            return headers;
        };
        $scope.openPreferences1 = function(){
            var dialog = ngDialog.open({ template: 'app/dashboard/preferences.html',
                            className: 'ngdialog-theme-default',
                            controller: 'PreferencesCtrl',
                            resolve : {
                                currencyList : function(){
                                    return $scope.currencyList;
                                },
                                ranges : function () {
                                    return $scope.ranges;
                                }
                            }
                            });
            dialog.closePromise.then(function (data) {
                // initServices();
            });
        };
        $scope.openAdPreview = function (adId) {
            var dialog = ngDialog.open({ template: 'app/dashboard/adspreview.html',
                controller: 'AdsPreviewController',
                className: 'ngdialog-theme-default custom-width-800',
                resolve : {
                    adId : function(){
                        return adId;
                    }
                }
            });
        };
        $scope.segmentChanged = function(filterPrimary,filterSecondary){
            $scope.segment = filterPrimary;
            $scope.metrics = "all";
            var spinnertime = 50;
            if($scope.segment === "ad"){
                spinnertime = 100;
                // var pagesize = 10;
                // $scope.dtOptions.iDisplayLength = 10;
                // $scope.dtInstance.rerender;
            }
            else{
                // $scope.dtInstance.DataTable.draw();
            }

            $scope.$emit('panel-refresh', [$scope.panelids[5]]);
            var advertiserId = $stateParams.accid;
            var campaignId = $stateParams.campid;
            var adgroupId = $stateParams.id;
            var url = "api/report/account/"+advertiserId+"/campaign/"+campaignId+"/adgroup/"+adgroupId+"/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/DAY"+"/"+$scope.segment+"/table/"+$scope.metrics;
            Reports.getMoreAds(url).then(function(data){
                $scope.dashboard.tables = data.tables;
                if(data.hasOwnProperty("cards")){
                    $scope.dashboard.cards = data.cards;
                }
                if(data.hasOwnProperty("dailytrend")) {
                    $scope.dashboard.dailytrend = data.dailytrend;
                    var cardkeys = [];
                    if ($scope.dashboard.tables.length > 0) {
                        angular.forEach($scope.dashboard["tables"][0], function (value, key) {
                            var mapping = $scope.reportParamsKeys[key];

                            if (!$scope.data.hasOwnProperty(key)) {
                                $scope.data[key] = [];
                                cardkeys.push(key);
                            }

                        });
                    }
                    for (var i = 0; i < $scope.dashboard["dailytrend"].length; i++) {
                        for (var j = 0; j < cardkeys.length; j++) {
                            var mapping = $scope.reportParamsKeys[cardkeys[j]];
                            $scope.data[cardkeys[j]].push($scope.dashboard["dailytrend"][i][cardkeys[j]]);
                        }
                    }
                }
                $timeout(function(){$scope.$emit('removeSpinner',[$scope.panelids[5]]);}, spinnertime);
                addToMetricList();
            });

						//invoke ajaxcall
            if($scope.stateDecide === 3){
            	$scope.dtInstance.reloadData();
           	}
        };
        $scope.metricsChanged = function(filterPrimary,filterSecondary){
            if(filterSecondary!== null) {
                $scope.segment = filterPrimary;
                $scope.metrics = filterSecondary;
                var spinnertime = 50;
                if ($scope.segment === "ad") {
                    spinnertime = 100;
                    // var pagesize = 10;
                    // $scope.dtOptions.iDisplayLength = 10;
                    // $scope.dtInstance.rerender;
                }
                else {
                    // $scope.dtInstance.DataTable.draw();
                }

                $scope.$emit('panel-refresh', [$scope.panelids[5]]);
                var advertiserId = $stateParams.accid;
                var campaignId = $stateParams.campid;
                var adgroupId = $stateParams.id;
                var url = "api/report/account/" + advertiserId + "/campaign/" + campaignId + "/adgroup/" + adgroupId + "/" + $scope.modalDates.startDate + "/" + $scope.modalDates.endDate + "/DAY" + "/" + $scope.segment + "/table/" + $scope.metrics;
                Reports.getMoreAds(url).then(function (data) {
                    $scope.dashboard.tables = data.tables;
                    if(data.hasOwnProperty("cards")){
                        $scope.dashboard.cards = data.cards;
                    }
                    if(data.hasOwnProperty("dailytrend")) {
                        $scope.dashboard.dailytrend = data.dailytrend;
                        var cardkeys = [];
                        if ($scope.dashboard.tables.length > 0) {
                            angular.forEach($scope.dashboard["tables"][0], function (value, key) {
                                var mapping = $scope.reportParamsKeys[key];

                                if (!$scope.data.hasOwnProperty(key)) {
                                    $scope.data[key] = [];
                                    cardkeys.push(key);
                                }

                            });
                        }
                        for (var i = 0; i < $scope.dashboard["dailytrend"].length; i++) {
                            for (var j = 0; j < cardkeys.length; j++) {
                                var mapping = $scope.reportParamsKeys[cardkeys[j]];
                                $scope.data[cardkeys[j]].push($scope.dashboard["dailytrend"][i][cardkeys[j]]);
                            }
                        }
                    }
                    $timeout(function () {
                        $scope.$emit('removeSpinner', [$scope.panelids[5]]);
                    }, spinnertime);
                });
								//invoke ajaxcall
            		if($scope.stateDecide === 3){
            			$scope.dtInstance.reloadData();
           			}
            }
        };
        $scope.bringAdsData = function($index){
            // var datatopass = $scope.segment==="concept"?$scope.dashboard.tables[$index].adMappingId:$scope.dashboard.tables[$index].adUnitId;
            var advertiserId = $stateParams.accid;
            var campaignId = $stateParams.campid;
            var adgroupId = $stateParams.id;
            var url = "api/report/account/"+advertiserId+"/campaign/"+campaignId+"/adgroup/"+adgroupId+"/"+$scope.modalDates.startDate+"/"+$scope.modalDates.endDate+"/DAY"+"/"+$scope.segment+"/table";
            Reports.getMoreAds(url).then(function(data){
                for(var i=0;i<data.length;i++){
                    $scope.dashboard.tables.splice($index+i,0,data[i]);
                }
            });
        };
        if(!$rootScope.hasOwnProperty("preferencesData")){
            getUserPreferences();
        }
        else{
            setDayWeekAndColvisPref();
						$scope.tickerFlagDict = setTickerData();//hot fix, handle this neatly
						setTickerPeriod();
						setShowTickerDataFlag();//flag to show ticker comparison data
            initializeDatePreferences();
            initDecideState();

            // initializeDataTable();

            initControllerVariables();
            initColvisPref();
            initServices();
        }
        $scope.$watch('dtInstance',function( newValue, oldValue ) {
            //console.log( "datatable", newValue );
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
                if($state.current.name === "dashboard.agency"){
                    // registerTableEvent();
                }
            }
        });
        $scope.dtIntanceCallback = function (instance) {
            $scope.dtInstance = instance;
        };
        function registerTableEvent() {
            $scope.dtInstance.dataTable.on('column-visibility.dt', function (e, settings, column, state) {
                var flag = true;
                for (var i = 0; i < $sessionStorage.colvis.length; i++) {
                    if ($sessionStorage.colvis[i].name === $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sTitle) {
                        if ($sessionStorage.colvis[i].status === state) {
                            flag = false;
                            break;
                        }
                        else {
                            flag = false;
                            $sessionStorage.colvis[i].status = state;
                            break;
                        }

                    }
                }
                if (flag)
                    $sessionStorage.colvis.push({
                        column: column,
                        status: state,
                        name: $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sTitle,
                        type: $scope.dtInstance.dataTable.fnSettings().aoColumns[column].sType,
                        state: $state.current.name
                    });
                // vCols = [];
                // $.each($scope.dtInstance.dataTable.fnSettings().aoColumns, function(c) {
                //     if ($scope.dtInstance.dataTable.fnSettings().aoColumns[c].bVisible == true) {
                //         vCols = vCols.concat($scope.dtInstance.dataTable.fnSettings().aoColumns[c].sTitle);
                //         console.log(vCols);
                //     }
                // });
            });
        }

        $scope.openBudgetSchedule = function(advertiserId,campaignId){
            var dialog = ngDialog.open({ template: 'app/dashboard/budget/budget.html',
                className: 'ngdialog-theme-default custom-dialogbox',
                controller: 'BudgetController',
                resolve : {
                    advertiserId : function(){
                        return advertiserId;
                    },
                    campaignId : function(){
                        return campaignId;
                    }
                }
            });
            dialog.closePromise.then(function (data) {
                if(data.value !== "$closeButton") {
                    $scope.dtInstance.reloadData();
                }
                // initServices();
                // console.log('ngDialog closed' + (data.value === 1 ? ' using the button' : '') + ' and notified by promise: ' + data.id);
            });

        };

        $scope.reloadDataTables = function () {
            $scope.dtInstance.reloadData();
        };
        $scope.stateChangeAdv = function(state,data){
          $state.go(state,data,{reload:state});
        };



        var stop;
        $scope.fight = function() {
            // Don't start a new fight if we are already fighting
            if ( angular.isDefined(stop) ) return;
                stop = $interval(function() {
                    $scope.$emit('panel-refresh', $scope.panelids);
                    initServices();
                    // alert("dfdfdfd");
                }, 300000);
        };

        $scope.stopFight = function() {
            if (angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
            }
        };


        $scope.$on('$destroy', function() {
            // Make sure that the interval is destroyed too
            $scope.stopFight();
        });
        $scope.fight();


        $scope.openReapproval = function () {

            ngDialog.open({
                template: 'app/campaign-manager/third-party-approval.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain: false,
                controller: 'ApprovalController',
                resolve: {
                    advertiserId: function () {
                        return $stateParams.accid;
                    },
                    adgroupId: function () {
                        return $stateParams.id;
                    },
                    creativesData: function() {
                        return CampaignManagerService.getThirdPartyCreatives($stateParams.accid,$stateParams.id).then(function (response) {
                            return response;
                        });
                    }
                }
            }).closePromise.then(function (data) {
            });
        };

        $scope.toggleStateList = function(state){
            var data = {state:state, ids:[]};

            for(var key in $scope.selected) {
                if($scope.selected[key]) {
                    data.ids.push(key);
                }
            }

            if(stateType == "ads") {
                if($scope.metrics == "all" && $scope.segment != "ads") {
                    data["mapping"] = true;
                    if($scope.segment == "size") {
                        data["mappingType"] = "size";
                    }
                    else if($scope.segment == "concept") {
                        data["mappingType"] = "mappingId";
                    }
                }
                else {
                    data["mapping"] = false;
                    data["mappingType"] = "none";
                }
            }
            Dashboard.toggleStateList($stateParams.accid,stateType,data).then(function(response){
                if(response.error != "") {
                    error = response.error;
                    errTemplate='<div>\n' +
                        '    <h2 class="text-danger fs-16">{{::error}}</h2>\n' +
                        '</div>';
                    ngDialog.open({
                        template: errTemplate,
                        plain:true,
                        controller: 'StatusErrorController',
                        resolve :{
                            error :function(){
                                return error;
                            }
                        }
                    });
                }
                $scope.reloadDataTables();
            });
        }

    }]);
angular
    .module('adelementAdminApp')
    .controller('AccountController',['$scope','$rootScope','$state','$stateParams','Dashboard',function($scope,$rootScope,$state,$stateParams,Dashboard){

        function initControllerVariables(){
            $scope.dataaccount = $stateParams.accid;
            $scope.campaigns = [];
        }
        function initServices(){
            Dashboard.getCampaigns($scope.dataaccount).then(function(data){
                $scope.campaigns = angular.copy(data);
            });
        }

        initControllerVariables();
        initServices();
    }]);

angular
    .module('adelementAdminApp')
    .controller('CampaignController',['$scope','$rootScope','$state','$stateParams','Dashboard',function($scope,$rootScope,$state,$stateParams,Dashboard){

        function initControllerVariables(){
            $scope.data = {};
            $scope.accid = $stateParams.accid;
            $scope.campid = $stateParams.id;
            $scope.adgroups = []
        }
        function initServices(){
            Dashboard.getAdgroups($scope.accid,$scope.campid).then(function(data){
                $scope.adgroups = angular.copy(data);
            });
        }
        initControllerVariables();
        initServices();
    }]);
angular
    .module('adelementAdminApp')
    .controller('AdsController',['$scope','$rootScope','$state','$stateParams','Dashboard',function($scope,$rootScope,$state,$stateParams,Dashboard){

        function initControllerVariables(){
            // $scope.adbyadid = true;
            if($stateParams.hasOwnProperty("adid")){
                $scope.adbyadid = true;
                $scope.adid = $stateParams.adid;
            }
            else{
                $scope.adbyadid = false;
                $scope.adgroupid = $stateParams.adgroupid;
            }

        }
        initControllerVariables();
    }]);

angular
    .module('adelementAdminApp')
    .controller('AdsPreviewController',['$scope','$rootScope','$state','$stateParams','adId',function($scope,$rootScope,$state,$stateParams,adId){

        function initControllerVariables(){
            $scope.adId = adId;
        }
        initControllerVariables();
    }]);
angular
    .module('adelementAdminApp')
    .controller('PreferencesCtrl',['$scope','$rootScope','$state','$stateParams','Dashboard','$localStorage','$sessionStorage','Reports',function($scope,$rootScope,$state,$stateParams,Dashboard,$localStorage,$sessionStorage,Reports){
        $scope.preferencesData = {};
        if($rootScope.preferencesData === null || $rootScope.preferencesData === undefined){
            $rootScope.preferencesData = {};
        }
        function initService(){
            var id = $stateParams.id;
            Reports.getUserPreferences(id,"report").then(function(response){
                $rootScope.preferencesData = eval("(" + response.data + ")");
            });
        }
        function initVariables() {
            $scope.currencyList = $rootScope.currencyList;
            $scope.paramsdict = $rootScope.filterKeysReport;
            $scope.paramslist = Object.keys($rootScope.filterKeysReport);
            $scope.paramsdict2 = angular.copy($rootScope.filterKeysReport);
						$scope.tickerFlagDict = angular.copy($rootScope.filterKeysReport);
            $scope.defaultParams = {currency: "INR", dateLabel: "Last 7 Days", dayorweek: true, tickerPeriod: "1"};
            $scope.ranges = $rootScope.ranges;
            $scope.currency = $rootScope.preferencesData.hasOwnProperty("currency") ? $rootScope.preferencesData.currency : selectDefault("currency");
            $scope.datelabel = $rootScope.preferencesData.hasOwnProperty("dateLabel") ? $rootScope.preferencesData.dateLabel : selectDefault("dateLabel");
            $scope.dayorweekbool = $rootScope.preferencesData.hasOwnProperty("dayorweek") ? $rootScope.preferencesData.dayorweek : selectDefault("dayorweek");
            $scope.tickerPeriod = $rootScope.preferencesData.hasOwnProperty("tickerPeriod") ? $rootScope.preferencesData.tickerPeriod : selectDefault("tickerPeriod");
            $scope.showTickerData = $rootScope.preferencesData.hasOwnProperty("showTickerData") ? $rootScope.preferencesData.showTickerData : selectDefault("showTickerData");
            // $scope.colvis = $localStorage.hasOwnProperty("colvis")?$localStorage.colvis : [];
            $scope.paramsdict2 = $rootScope.preferencesData.hasOwnProperty("colvis") ? selectAllBut() : selectAll();
						//$scope.tickerFlagDict = $rootScope.preferencesData.hasOwnProperty("colvis") ? tickersSelectAllBut() : tickersSelectAll();
						$scope.tickerFlagDict = setTickerData();
        }

				//set default ticker configuration -- hot fix [dup func]
				function setTickerData(){
            var dicttoreturn = {};
						var len = 0;
						if($rootScope.hasOwnProperty("preferencesData")){
							if($rootScope.preferencesData.hasOwnProperty("tickerParamsSelected")){
								len = Object.keys($rootScope.preferencesData.tickerParamsSelected).length;
							}
						}

						if(len > 0) {
                var dicttoreturn = $rootScope.preferencesData.tickerParamsSelected;
                return dicttoreturn;
            }
           	else {//first time
							angular.forEach($scope.paramsdict,function(val,key){
            		dicttoreturn[key] = false;
            	});
							//default settings
							dicttoreturn['sumImpressions'] = true;
							dicttoreturn['ctr'] = true;
							dicttoreturn['sumSpendCents'] = true;
							$rootScope.preferencesData.tickerParamsSelected = angular.copy(dicttoreturn);
							return dicttoreturn;
					}
				};

			  function selectAll(){
            var dicttoreturn = {};
            angular.forEach($scope.paramsdict,function(val,key){
                dicttoreturn[key] = true;
            });
            for(var i=0;i<$scope.paramslist.length;i++){
                $rootScope.preferencesData.colvis.push(
                {
                    column: i+2,
                    status: true,
                    name: $scope.paramslist[i],
                    type: $rootScope.reportKeysUnits[$scope.paramslist[i]] == "na"?"num-fmt":$rootScope.reportKeysUnits[$scope.paramslist[i]],
                    state: "dashboard.agency"
                }
                );
            }
            $rootScope.preferencesData.paramsSelected = angular.copy(dicttoreturn);
            return dicttoreturn;
        }

        function selectAllBut(){
            if($rootScope.preferencesData.colvis.length > 0) {
                var dicttoreturn = {};
                angular.forEach($scope.paramsdict, function (val, key) {
                    dicttoreturn[key] = true;
                });
                for (var i = 0; i < $rootScope.preferencesData.colvis.length; i++) {
                    if (dicttoreturn.hasOwnProperty($rootScope.preferencesData.colvis[i].name)) {
                        dicttoreturn[$rootScope.preferencesData.colvis[i].name] = $rootScope.preferencesData.colvis[i].status;
                    }
                }
                return dicttoreturn;
            }
            else{
                return selectAll();
            }
        }

        function selectDefault(key){
            $scope.savedState = false;
            return $scope.defaultParams[key];
        }

        if(!$rootScope.preferencesData.hasOwnProperty("paramsSelected")){
            $rootScope.preferencesData.paramsSelected = {};
        }
        if(!$rootScope.preferencesData.hasOwnProperty("tickerParamsSelected")){
            $rootScope.preferencesData.tickerParamsSelected = {};
        }

        if(!$rootScope.preferencesData.hasOwnProperty("colvis")){
            $rootScope.preferencesData.colvis = [];
        }

        $scope.checkInput = function(){
            $rootScope.preferencesData.currency = $scope.currency;
            $rootScope.preferencesData.dateLabel = $scope.datelabel;
            $rootScope.preferencesData.dayorweek = $scope.dayorweekbool;
						$rootScope.preferencesData.tickerPeriod = $scope.tickerPeriod;
						$rootScope.preferencesData.showTickerData = $scope.showTickerData;
            var pref = {data:JSON.stringify($rootScope.preferencesData)};

            Reports.setPreferences($stateParams.id,"report",pref).then(function(res){
                initService();
                initVariables();
            });
            $sessionStorage.firstTimeDashboard = true;
        };
        $scope.inputChanged = function($index){
            var param = $scope.paramslist[$index];
            if($rootScope.preferencesData.paramsSelected.hasOwnProperty(param)){
                for(var i=0;i<$rootScope.preferencesData.colvis.length;i++){

                    if($rootScope.preferencesData.colvis[i].name === param){
                        $rootScope.preferencesData.colvis[i].status = $scope.paramsdict2[param];
                        $rootScope.preferencesData.paramsSelected[param] = angular.copy($scope.paramsdict2[param]);
                    }
                }
            }
            else{
                $rootScope.preferencesData.paramsSelected[param] = angular.copy($scope.paramsdict2[param]);
                $rootScope.preferencesData.colvis.push(
                    {
                        column: $index+2,
                        status: $scope.paramsdict2[param],
                        name: param,
                        type: "currency",
                        state: "dashboard.agency"
                    }
                );
            }
            var pref = {data:JSON.stringify($rootScope.preferencesData)};
            Reports.setPreferences($stateParams.id,"report",pref).then(function(res){
                initService();
                initVariables();
            });
            $sessionStorage.firstTimeDashboard = true;
        };

				$scope.trickerInputChanged = function($index){
          var param = $scope.paramslist[$index];
          $rootScope.preferencesData.tickerParamsSelected[param] = angular.copy($scope.tickerFlagDict[param]);
          var pref = {data:JSON.stringify($rootScope.preferencesData)};
          Reports.setPreferences($stateParams.id,"report",pref).then(function(res){
						initService();
						initVariables();
					});
					$sessionStorage.firstTimeDashboard = true;
				}

        function findWithAttr(array, attr, value) {
            for(var i = 0; i < array.length; i += 1) {
                if(array[i][attr] === value) {
                    return i;
                }
            }
            return -1;
        }
        initVariables();
    }]);
angular
    .module('adelementAdminApp')
    .directive('testDirective', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element
                .on('mouseenter',function() {
                    var className = element.context.className.trim();
                    var classes = element.context.className.split(" ");
                    var flag=true;
                    for(var i=0;i<classes.length;i++){
                        if(scope.data.hasOwnProperty(classes[i])){
                           scope.vals = scope.data[classes[i]];
                           scope.vals2 = JSON.stringify(scope.data[classes[i]]);
                            flag = element.context.parentElement.parentElement.children[1]?true:false;
                            var id = flag?element.context.parentElement.parentElement.children[1].children[0].id:element.context.parentElement.children[3].children[0].id;
                            scope.$broadcast('overlaychange',{"val":scope.vals,"id":id});
                            element.addClass('bd-top');
                            $(element).siblings('div').removeClass('bd-top');
                        }
                    }
                })
                .on('mouseleave',function() {
                    // element.removeClass('bd-top');
                });
        }
    };
});


angular.module('adelementAdminApp').directive("scroll2", ['$timeout','$rootScope',function ($timeout,$rootScope) {
    return function(scope, element, attrs) {
        scope.firstTime = false;
        var func = function(){

            //var e = element.context.children[0].children[4].children[0];
						var e = element.context.children[0].children[4];
            angular.element(e).bind("scroll",function(){
                // alert("adaddd");
                var headers = document.getElementsByClassName("fixedHeader-floating");
                if(headers.length>0){
                    var header = headers[0];
                    var x = e.scrollLeft;
                    var pos = $rootScope.app.layout.isCollapsed?90:213;
										//console.log("SCROLL-AJAX");
										//console.log("x:", x);
										//console.log("pos:", pos);
										//console.log("new pos:", -x + pos);
                    header.style.left = -x + pos + "px";
                }
            });
        };
        angular.element(element).bind("scroll", function() {
            scope[attrs.scroll] = true;
            // scope.$apply();
        });
        scope.$on('reloadReportDataEvent', function() {
            //write your listener here
            if(!scope.firstTime){
                $timeout(func,3000);
            }
        });

    };
}]);

angular.module('adelementAdminApp').directive("scroll", ['$timeout','$rootScope',function ($timeout,$rootScope) {
    return function(scope, element, attrs) {
        scope.firstTime = false;
        var func = function(){

            var e = element.context.children[0].children[3].children[1];
            angular.element(e).bind("scroll",function(){
                // alert("adaddd");
                var headers = document.getElementsByClassName("fixedHeader-floating");
                if(headers.length>0){
                    var header = headers[0];
                    var x = e.scrollLeft;
                    var pos = $rootScope.app.layout.isCollapsed?90:213;
										//console.log("SCROLL");
										//console.log("x:", x);
										//console.log("pos:", pos);
										//console.log("new pos:", -x + pos);
                    header.style.left = -x + pos + "px";
                }
            });
        };
        angular.element(element).bind("scroll", function() {
            scope[attrs.scroll] = true;
            // scope.$apply();
        });
        scope.$on('reloadReportDataEvent', function() {
            //write your listener here
            if(!scope.firstTime){
                $timeout(func,3000);
            }
        });

    };
}]);
angular.module( 'adelementAdminApp' ).filter( 'shortNumber', function() {
    return function( number ) {
        if ( number ) {
            abs = Math.abs( number );
            if ( abs >= Math.pow( 10, 12 ) ) {
// trillion
                number = ( number / Math.pow( 10, 12 ) ).toFixed( 1 ) + "T";
            } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
// billion
                number = ( number / Math.pow( 10, 9 ) ).toFixed( 1 ) + "B";
            } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
// million
                number = ( number / Math.pow( 10, 6 ) ).toFixed( 1 ) + "M";
            } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
// thousand
                number = ( number / Math.pow( 10, 3 ) ).toFixed( 1 ) + "K";
            }
            return number;
        }
    };
} );
angular.module( 'adelementAdminApp' ).
filter('timeago', function() {
    return function(input, p_allowFuture) {
        var substitute = function (stringOrFunction, number, strings) {
                var string = $.isFunction(stringOrFunction) ? stringOrFunction(number, dateDifference) : stringOrFunction;
                var value = (strings.numbers && strings.numbers[number]) || number;
                return string.replace(/%d/i, value);
            },
            nowTime = (new Date()).getTime(),
            date = (new Date(input)).getTime(),
        //refreshMillis= 6e4, //A minute
            allowFuture = p_allowFuture || false,
            strings= {
                prefixAgo: null,
                prefixFromNow: null,
                suffixAgo: "ago",
                suffixFromNow: "from now",
                seconds: "less than a minute",
                minute: "about a minute",
                minutes: "%d minutes",
                hour: "about an hour",
                hours: "about %d hours",
                day: "a day",
                days: "%d days",
                month: "about a month",
                months: "%d months",
                year: "about a year",
                years: "%d years"
            },
            dateDifference = nowTime - date,
            words,
            seconds = Math.abs(dateDifference) / 1000,
            minutes = seconds / 60,
            hours = minutes / 60,
            days = hours / 24,
            years = days / 365,
            separator = strings.wordSeparator === undefined ?  " " : strings.wordSeparator,

        // var strings = this.settings.strings;
            prefix = strings.prefixAgo,
            suffix = strings.suffixAgo;

        if (allowFuture) {
            if (dateDifference < 0) {
                prefix = strings.prefixFromNow;
                suffix = strings.suffixFromNow;
            }
        }

        words = seconds < 45 && substitute(strings.seconds, Math.round(seconds), strings) ||
            seconds < 90 && substitute(strings.minute, 1, strings) ||
            minutes < 45 && substitute(strings.minutes, Math.round(minutes), strings) ||
            minutes < 90 && substitute(strings.hour, 1, strings) ||
            hours < 24 && substitute(strings.hours, Math.round(hours), strings) ||
            hours < 42 && substitute(strings.day, 1, strings) ||
            days < 30 && substitute(strings.days, Math.round(days), strings) ||
            days < 45 && substitute(strings.month, 1, strings) ||
            days < 365 && substitute(strings.months, Math.round(days / 30), strings) ||
            years < 1.5 && substitute(strings.year, 1, strings) ||
            substitute(strings.years, Math.round(years), strings);

        return $.trim([prefix, words, suffix].join(separator));
        // conditional based on optional argument
        // if (somethingElse) {
        //     out = out.toUpperCase();
        // }
        // return out;
    }
});
// $('.dataTables_scrollBody').on('scroll', function() {
//     alert('');
// });
angular
    .module('adelementAdminApp')
    .directive('ads',['Dashboard', function (Dashboard) {
        return {
            restrict: 'EA',
            scope: {
                adid: '=adid',
                scale: '=scale'

            },
            template:'<div ng-class="class"></div>',
            link: function (scope, element, attrs) {
                scope.type = 0;
                scope.type_flash = "FLASH";
                scope.type_adtag = "adtag";
                scope.type_html = "HTML";
                scope.haveBanner = false;
                scope.checkAdType = function(type){
                    if(scope.data){
                        return scope.data.adType === type;
                    }
                    return false;

                };
                scope.$watch('adid',function(oldv,newv){
                   if(newv){
                       Dashboard.getAdParamsById(newv).then(function(response){
                           scope.data = response;
                           var scalefactor = 1;
                           var iframetag = '<iframe scrolling="NO" FRAMEBORDER="0"></iframe>';
                           var elm = element.children(0);
                           if((scope.data.adType === "HTML" || scope.data.adType === "adtag")){
                               if(scope.data.adType === "HTML"){
                                   scope.type = 0;
                                   if(scope.data.bannerId != null && scope.data.bannerId != undefined){
                                       scope.haveBanner = true;
                                       scope.iframesrc = "https://ads.adelement.com/www/images/html5layout_feed/renderhtmlad.php?clkurl="+"&headline=" + encodeURIComponent(scope.data.headline) + "||" + encodeURIComponent(scope.data.headlineFontColor) + "|"+ encodeURIComponent(scope.data.shadowColor)+
                                           "&subheadline=" + encodeURIComponent(scope.data.subHeadline) + "||" + encodeURIComponent(scope.data.subheadlineFontColor) + "|"+encodeURIComponent(scope.data.subheadlineShadowColor)+
                                           "&ot1=" + encodeURIComponent(scope.data.optionalText1) + "||" + encodeURIComponent(scope.data.optionalText1Color) + "|" + encodeURIComponent(scope.data.optionalText1ShadowColor) +
                                           "&ot2=" + encodeURIComponent(scope.data.optionalText2) + "||" + encodeURIComponent(scope.data.optionalText2Color) + "|" + encodeURIComponent(scope.data.optionalText2ShadowColor) +
                                           "&ot3=" + encodeURIComponent(scope.data.optionalText3) + "||" + encodeURIComponent(scope.data.optionalText3Color) + "|" + encodeURIComponent(scope.data.optionalText3ShadowColor) +
                                           "&ot4=" + encodeURIComponent(scope.data.optionalText4) + "||" + encodeURIComponent(scope.data.optionalText4Color) + "|" + encodeURIComponent(scope.data.optionalText4ShadowColor) +
                                           "&feedid=" + encodeURIComponent(scope.data.feedId) + "&img=" + "{" + scope.data.feedFields.split(",").indexOf(scope.data.imageFields) + "}" +","+scope.data.imageTags+
                                           "&c2a=" + encodeURIComponent(scope.data.text) + "||" + scope.data.c2astyleFontColor + "|" + scope.data.gradient1 + "|" + scope.data.gradient2 + "|" + scope.data.c2astylebordercolor+
                                           "&adid="+scope.data.bannerId+"&productid=";
                                   }
                                   else {
                                       scope.iframesrc = "https://ads.adelement.com/www/images/html5layout_feed/renderhtmlad.php?clkurl=" + "&bg=" + encodeURIComponent(scope.data.backgroundLocation) + "&headline=" + encodeURIComponent(scope.data.headline) + "||" + encodeURIComponent(scope.data.headlineFontColor) + "|" + encodeURIComponent(scope.data.shadowColor) +
                                           "&subheadline=" + encodeURIComponent(scope.data.subHeadline) + "||" + encodeURIComponent(scope.data.subheadlineFontColor) + "|" + encodeURIComponent(scope.data.subheadlineShadowColor) +
                                           "&c2actionimg=" + encodeURIComponent(scope.data.btnSrc) + "&logoimg=" + encodeURIComponent(scope.data.cdnLocation) +
                                           "&ot1=" + encodeURIComponent(scope.data.optionalText1) + "||" + encodeURIComponent(scope.data.optionalText1Color) + "|" + encodeURIComponent(scope.data.optionalText1ShadowColor) +
                                           "&ot2=" + encodeURIComponent(scope.data.optionalText2) + "||" + encodeURIComponent(scope.data.optionalText2Color) + "|" + encodeURIComponent(scope.data.optionalText2ShadowColor) +
                                           "&ot3=" + encodeURIComponent(scope.data.optionalText3) + "||" + encodeURIComponent(scope.data.optionalText3Color) + "|" + encodeURIComponent(scope.data.optionalText3ShadowColor) +
                                           "&ot4=" + encodeURIComponent(scope.data.optionalText4) + "||" + encodeURIComponent(scope.data.optionalText4Color) + "|" + encodeURIComponent(scope.data.optionalText4ShadowColor) +
                                           "&productarea=" + encodeURIComponent(scope.data.productareaBackgroundColor) + "|" + encodeURIComponent(scope.data.productareaBorderColor) +
                                           "&layout_tpl=" + encodeURIComponent(scope.data.layoutLocation) + "&feedid=" + encodeURIComponent(scope.data.feedId) + "&img=" + "{" + scope.data.feedFields.split(",").indexOf(scope.data.imageFields) + "}" + "," + scope.data.imageTags +
                                           "&c2a=" + encodeURIComponent(scope.data.text) + "||" + scope.data.c2astyleFontColor + "|" + scope.data.gradient1 + "|" + scope.data.gradient2 + "|" + scope.data.c2astylebordercolor;
                                   }
                               }
                               else{
                                   scope.iframesrc = 'data:text/html;base64, '+window.btoa(scope.data.adTags);
                                   scope.type = 1;
                               }


                               if(scope.scale){
                                   scalefactor = Math.min(120/scope.data.width,100/scope.data.height);
                                   elm.css("width",120);
                                   elm.css("height",100);
                               }

                               scope.height = scope.data.height;
                               scope.width  = scope.data.width;
                               scope.class = "htmladthumb"+scope.width+"_"+scope.height;


                               elm.append(iframetag);
                               elm.css("overflow","hidden");
                               elm.children(0).css("width",scope.width);
                               elm.children(0).css("height",scope.height);
                               elm.children(0).css("transform","scale("+scalefactor+")");
                               elm.children(0).css("transform-origin","0 0");
                               elm.children(0).attr("src",scope.iframesrc);
                               elm.children(0).attr("class",scope.class);

                           }
                           // else if(scope.data.adType === "adtag"){
                           //     scope.type = 1;
                           //     var iframetag = '<iframe src="data:text/html;base64, '+window.btoa(scope.data.adTags)+'"</iframe>';
                           //     element.children(0).append(iframetag);
                           //     // element.children(0).children(0).append(scope.data.adTags);
                           //
                           // }
                           else if(scope.data.adType === "FLASH"){
                               scope.type=2;
                               var imgtag = "<img/>";
                               element.children(0).append(imgtag);
                               element.children(0).children(0).attr("src",scope.data.cdnLocations);
                               if(scope.scale){
                                   if(scope.data.width > scope.data.height){
                                       element.children(0).children(0).attr("width",120);
                                   }
                                   else{
                                       element.children(0).children(0).attr("height",100);
                                   }
                               }
                           }
                       });
                   }
                });
            }
        };
    }]);
angular
    .module('adelementAdminApp')
    .directive('adsByAdgroup',['Dashboard', function (Dashboard) {
        return {
            restrict: 'EA',
            scope: {
                adgroupid: '=adgroupid'
            },
            template:'<div ng-class="class" class="col-lg-12 col-md-12 col-sm-12" ng-repeat="ad in data | limitTo:adslimit:start track by $index"><iframe src="{{srcforiframe($index)}}" id="{{ad.adId}}" height="{{height($index)}}" width="{{width($index)}}" ng-class="classapply()" scrolling="NO" FRAMEBORDER="0"> </iframe></div><a class="mb-sm btn btn-default" ng-if="start>0" ng-click="prevAds()">Previous</a><a class="mb-sm btn btn-info" ng-click="nextAds()">Next</a>',
            link: function (scope, element, attrs) {
                scope.type = 0;
                scope.adslimit = 10;
                scope.start = 0;
                scope.nextAds = function(){
                    scope.start = scope.start+10;
                };
                scope.prevAds = function(){
                    if(scope.start>=10){
                        scope.start = scope.start-10;
                    }

                };
                scope.$watch('adgroupid',function(oldv,newv){
                    if(newv){
                        Dashboard.getAdParamsByAdgId(newv).then(function(response){
                            scope.data = response;
                            scope.adscreate = function (index) {
                                var iframesrc = "https://ads.adelement.com/www/images/html5layout_feed/renderhtmlad.php?clkurl=" + "&bg=" + encodeURIComponent(scope.data[index].backgroundLocation) + "&headline=" + encodeURIComponent(scope.data[index].headline) + "||" + encodeURIComponent(scope.data[index].headlineFontColor) + "|" + encodeURIComponent(scope.data[index].shadowColor) +
                                    "&subheadline=" + encodeURIComponent(scope.data[index].subHeadline) + "||" + encodeURIComponent(scope.data[index].subheadlineFontColor) + "|" + encodeURIComponent(scope.data[index].subheadlineShadowColor) +
                                    "&c2actionimg=" + encodeURIComponent(scope.data[index].btnSrc) + "&logoimg=" + encodeURIComponent(scope.data[index].cdnLocation) +
                                    "&ot1=" + encodeURIComponent(scope.data[index].optionalText1) + "||" + encodeURIComponent(scope.data[index].optionalText1Color) + "|" + encodeURIComponent(scope.data[index].optionalText1ShadowColor) +
                                    "&ot2=" + encodeURIComponent(scope.data[index].optionalText2) + "||" + encodeURIComponent(scope.data[index].optionalText2Color) + "|" + encodeURIComponent(scope.data[index].optionalText2ShadowColor) +
                                    "&ot3=" + encodeURIComponent(scope.data[index].optionalText3) + "||" + encodeURIComponent(scope.data[index].optionalText3Color) + "|" + encodeURIComponent(scope.data[index].optionalText3ShadowColor) +
                                    "&ot4=" + encodeURIComponent(scope.data[index].optionalText4) + "||" + encodeURIComponent(scope.data[index].optionalText4Color) + "|" + encodeURIComponent(scope.data[index].optionalText4ShadowColor) +
                                    "&productarea=" + encodeURIComponent(scope.data[index].productareaBackgroundColor) + "|" + encodeURIComponent(scope.data[index].productareaBorderColor) +
                                    "&layout_tpl=" + encodeURIComponent(scope.data[index].layoutLocation) + "&feedid=" + encodeURIComponent(scope.data[index].feedId) + "&img=" + "{" + scope.data[index].feedFields.split(",").indexOf(scope.data[index].imageFields) + "}" + "," + scope.data[index].imageTags +
                                    "&c2a=" + encodeURIComponent(scope.data[index].text) + "||" + scope.data[index].c2astyleFontColor + "|" + scope.data[index].gradient1 + "|" + scope.data[index].gradient2 + "|" + scope.data[index].c2astylebordercolor;

                                var height = scope.data[index].height;
                                var width = scope.data[index].width;
                                var classes = "htmladthumb" + width + "_" + height;
                                // var iframetag = '<iframe scrolling="NO" FRAMEBORDER="0"></iframe>';
                                // element.children(0).append(iframetag);
                                // alert(classes);
                                element.children(0).children(index).css("width", width);
                                element.children(0).children(index).css("height", height);
                                element.children(0).children(index).attr("src", iframesrc);
                                element.children(0).children(index).attr("class",classes);
                                element.children(0).children(index).attr("id",scope.data[index].adId);
                            };
                            scope.srcforiframe = function(index){
                                var iframesrc = "https://ads.adelement.com/www/images/html5layout_feed/renderhtmlad.php?clkurl=" + "&bg=" + encodeURIComponent(scope.data[index].backgroundLocation) + "&headline=" + encodeURIComponent(scope.data[index].headline) + "||" + encodeURIComponent(scope.data[index].headlineFontColor) + "|" + encodeURIComponent(scope.data[index].shadowColor) +
                                    "&subheadline=" + encodeURIComponent(scope.data[index].subHeadline) + "||" + encodeURIComponent(scope.data[index].subheadlineFontColor) + "|" + encodeURIComponent(scope.data[index].subheadlineShadowColor) +
                                    "&c2actionimg=" + encodeURIComponent(scope.data[index].btnSrc) + "&logoimg=" + encodeURIComponent(scope.data[index].cdnLocation) +
                                    "&ot1=" + encodeURIComponent(scope.data[index].optionalText1) + "||" + encodeURIComponent(scope.data[index].optionalText1Color) + "|" + encodeURIComponent(scope.data[index].optionalText1ShadowColor) +
                                    "&ot2=" + encodeURIComponent(scope.data[index].optionalText2) + "||" + encodeURIComponent(scope.data[index].optionalText2Color) + "|" + encodeURIComponent(scope.data[index].optionalText2ShadowColor) +
                                    "&ot3=" + encodeURIComponent(scope.data[index].optionalText3) + "||" + encodeURIComponent(scope.data[index].optionalText3Color) + "|" + encodeURIComponent(scope.data[index].optionalText3ShadowColor) +
                                    "&ot4=" + encodeURIComponent(scope.data[index].optionalText4) + "||" + encodeURIComponent(scope.data[index].optionalText4Color) + "|" + encodeURIComponent(scope.data[index].optionalText4ShadowColor) +
                                    "&productarea=" + encodeURIComponent(scope.data[index].productareaBackgroundColor) + "|" + encodeURIComponent(scope.data[index].productareaBorderColor) +
                                    "&layout_tpl=" + encodeURIComponent(scope.data[index].layoutLocation) + "&feedid=" + encodeURIComponent(scope.data[index].feedId) + "&img=" + "{" + scope.data[index].feedFields.split(",").indexOf(scope.data[index].imageFields) + "}" + "," + scope.data[index].imageTags +
                                    "&c2a=" + encodeURIComponent(scope.data[index].text) + "||" + scope.data[index].c2astyleFontColor + "|" + scope.data[index].gradient1 + "|" + scope.data[index].gradient2 + "|" + scope.data[index].c2astylebordercolor;
                                return iframesrc;
                            };
                            scope.height = function (index) {
                                return scope.data[index].height;
                            };
                            scope.width  = function(index){
                                return scope.data[index].width;
                            };
                            scope.classapply = function(index){
                                return "htmladthumb" + width + "_" + height;
                            };
                        });
                    }
                });
            }
        };
    }]);
angular
    .module('adelementAdminApp')
    .filter('objLength', function() {
    return function(object) {
        var count = 0;

        count = Object.keys(object).length;
        return count;
    }
});
angular
    .module('adelementAdminApp')
    .directive('toggleState',['Dashboard','ngDialog', function (Dashboard,ngDialog) {
        return {
            restrict: 'EA',
            scope: {
                advertiserId: '@advertiserId',
                id: '=id',
                callBack: '&callBack',
                cls: '@cls',
                mapping: '=mapping',
                state: '@state',
                type:'@type',
                mappingType: '@mappingType'
            },
            template:'<span ng-mouseover="popoverIsOpen = true" ng-mouseleave="popoverIsOpen = false"><span ng-class="cls" uib-popover-template="\'myPopoverTemplate.html\'" uib-popover-title="\'Activate/Pause\'" popover-trigger="outsideClick" popover-is-open="popoverIsOpen" popover-placement="right"></span> {{id}}</span>'+
            '<script type="text/ng-template" id="myPopoverTemplate.html">\n' +
            '        <div class="form-group">\n' +
            '          <label class="col-md-7 control-label fs-12 pl0 pr0" ng-if="type!=\'ads\' && type!=\'creative\'">{{state==\'active\'?\'Pause\':\'Activate\'}} {{::type}}</label>\n'+
            '            <div class="col-md-5" ng-if="type!=\'ads\' && type!=\'creative\'">\n'                                                                       +
            '               <button ng-class="state==\'active\'?\'fa fa-pause fs-16 btn btn-danger\':\'fa fa-play fs-16 btn btn-primary\'" ng-click="playPause()"></button>\n' +
            '           </div>\n'                                                                       +
            '            <div class="col-md-12" ng-if="type==\'ads\' || type==\'creative\'">\n'                                                                       +
            '            <div class="col-md-6">'+
            '               <button class="fa fa-pause fs-16 btn btn-danger" ng-click="pauseElement()"></button>\n' +
            '            </div>'+
            '            <div class="col-md-6">'+
            '               <button class="fa fa-play fs-16 btn btn-primary" ng-click="activateElement()"></button>\n' +
            '            </div>'+
            '           </div>\n'                                                                       +
            '        </div>\n' +
            '    </script>',
            link: function (scope, element, attrs) {
                scope.datafor = {"id":1};
                scope.errTemplate='<div>\n' +
                '    <h2 class="text-danger fs-16">{{::error}}</h2>\n' +
                '</div>';
                scope.creativeTemplate = '<div class="dialog-header"><h3>These ads will be affected</h3></div>' +
                    '<div class="dialog_content">' +
                    '<table class="table table-condensed table-striped table-bordered">' +
                    '<thead><tr>' +
                    '<th><input type="checkbox" ng-model="checkAll"></th>' +
                    '<th>Ad Id</th>' +
                    '<th>Adgroup Id</th>' +
                    '<th>Adgroup Name</th>' +
                    '<th>Current Status</th>' +
                    '<th>New Status</th></tr>' +
                    '</thead>' +
                        '<tr ng-repeat="data in ads">' +
                            '<td><input type="checkbox" ng-model="data.check"></td>' +
                            '<td>{{data.adId}}</td>' +
                            '<td>{{data.adgroupId}}</td>' +
                            '<td>{{data.adgroupName}}</td>' +
                            '<td>{{data.status}}</td>' +
                            '<td>{{state}}</td>' +
                        '</tr>' +
                    '</table>' +
                    '</div>' +
                    '<div class="dialog-footer text-right"><button type="button" ng-click="closeIt()" class="btn btn-default">Ok</button></div>';
                function activatePauseElements(dataToSend){
                    Dashboard.toggleState(scope.advertiserId, scope.type, scope.id, dataToSend).then(function (response) {
                        console.log(response);
                        if(response.error != "") {
                            scope.error = response.error;
                            ngDialog.open({
                                template: scope.errTemplate,
                                plain:true,
                                controller: 'StatusErrorController',
                                resolve :{
                                    error :function(){
                                        return scope.error;
                                    }
                                }
                            });
                        }
                        // if(respons)
                        scope.callBack();
                    });
                }
                scope.playPause = function() {
                    var status = scope.state === 'active'?'paused':'active';
                    var dataToSend = {mapping:scope.mapping,state:status,mappingType:scope.mappingType};
                    activatePauseElements(dataToSend);
                };
                scope.activateElement = function() {
                    var status = "active";
                    var dataToSend = {mapping:scope.mapping,state:status,mappingType:scope.mappingType};
                    if(scope.type == "creative") {
                        ngDialog.open({
                            template: scope.creativeTemplate,
                            plain: true,
                            controller: 'CreativesController',
                            resolve: {
                                state: function () {
                                    return status;
                                },
                                ads: function() {
                                     return Dashboard.getAffectedAds(scope.advertiserId, scope.type, scope.id, dataToSend).then(function (response) {
                                        return response;
                                    });
                                }
                            }
                        }).closePromise.then(function (data) {
                            if(data.value !== "$closeButton") {
                                var exclusion = data.value;
                                dataToSend.exclusionIds = exclusion;
                                dataToSend.exclusionType = "ad";
                                activatePauseElements(dataToSend);
                            }
                        });
                    }
                    else {
                        activatePauseElements(dataToSend);
                    }
                };
                scope.pauseElement = function() {
                    var status = "paused";
                    var dataToSend = {mapping:scope.mapping,state:status,mappingType:scope.mappingType};
                    if(scope.type == "creative") {
                        ngDialog.open({
                            template: scope.creativeTemplate,
                            plain: true,
                            controller: 'CreativesController',
                            resolve: {
                                state: function () {
                                    return status;
                                },
                                ads: function() {
                                    return Dashboard.getAffectedAds(scope.advertiserId, scope.type, scope.id, dataToSend).then(function (response) {
                                        return response;
                                    });
                                }
                            }
                        }).closePromise.then(function (data) {
                            if(data.value !== "$closeButton") {
                                var exclusion = data.value;
                                dataToSend.exclusionIds = exclusion;
                                dataToSend.exclusionType = "ad";
                                activatePauseElements(dataToSend);
                            }
                        });
                    }
                    else {
                        activatePauseElements(dataToSend);
                    }
                };
            }
        };
    }]);
angular
    .module('adelementAdminApp')
    .controller('StatusErrorController',['$scope','error',function($scope,error){

        function initControllerVariables(){
            $scope.error = error;
        }
        initControllerVariables();
    }]);
angular
    .module('adelementAdminApp')
    .controller('CreativesController',['$scope','state','ads',function($scope,state,ads){

        function initControllerVariables(){
            $scope.state = state;
            $scope.ads = ads;
            $scope.exclusionAdId = [];
        }
        $scope.closeIt = function(){
            angular.forEach($scope.ads,function(ad){
               if(!ad.check){
                   $scope.exclusionAdId.push(ad.adId.toString());
               }
            });
            $scope.closeThisDialog($scope.exclusionAdId);
        };
        initControllerVariables();
    }]);
angular
    .module('adelementAdminApp').directive('memoryDirective', function(){
    return {
        restrict: 'A',
        link: function(scope, element) {
            scope.$on('$destroy', function() {
                element.remove();
            });
        }
    };
});
angular
    .module('adelementAdminApp')
    .filter('howManyTrue', function() {
        return function(object) {
            var count = 0;

            for(var i in object){
                if(object[i]){
                    count++;
                }
            }
            return count;
        }
    });
