/**
 * Created by maitray on 11/4/16.
 */
'use strict';
angular.module('adelementAdminApp').controller('NewUserCreateController',
    ['$scope', '$rootScope','$state','$stateParams','aeAdvertisers','UserCreate','UserRolesAssign','AssignAdvertisers','CreateAdvertisers','GetSelectionInfo',
        'NUMBER_FORMAT_CONST','LANGUAGE_CONST','BUSINESS_TYPE_CONST','THIRD_PARTY_AD_SERVER_CONST','USER_TITLE_CONST','WEBSITE_UNIQUE_VISITORS_CONST','APP_UNIQUE_USERS_CONST','ACL_PERMISSIONS_CONST','userExist','assignedAdvertiserAndRoles','PasswordResetInit','currentUserX','AddAgency','AssignAgency',
        function($scope,$rootScope, $state ,$stateParams,aeAdvertisers,UserCreate,UserRolesAssign,AssignAdvertisers,CreateAdvertisers,GetSelectionInfo,NUMBER_FORMAT_CONST,LANGUAGE_CONST,BUSINESS_TYPE_CONST,THIRD_PARTY_AD_SERVER_CONST,USER_TITLE_CONST,WEBSITE_UNIQUE_VISITORS_CONST,APP_UNIQUE_USERS_CONST,ACL_PERMISSIONS_CONST,userExist,assignedAdvertiserAndRoles,PasswordResetInit,currentUserX,AddAgency,AssignAgency) {
            function initVariables(){
                $scope.account = currentUserX;
                $scope.error_step1 = false;
                $scope.allAeAdvertisers = {};
                $scope.numberFormatConst=NUMBER_FORMAT_CONST;
                $scope.languageConst = LANGUAGE_CONST;
                $scope.businessTyprConst=BUSINESS_TYPE_CONST;
                $scope.thirdPartyAdServer=THIRD_PARTY_AD_SERVER_CONST;
                $scope.userTitleConst=USER_TITLE_CONST;
                $scope.contollerErrorMsg = "Please fill up the required fields !!";
                $scope.notFilledRequiredFields = false;
                $scope.websiteMonthlyVisitorsConst = WEBSITE_UNIQUE_VISITORS_CONST;
                $scope.appMonthlyUsers = APP_UNIQUE_USERS_CONST;
                $scope.aclPermissionConst = ACL_PERMISSIONS_CONST;
                $scope.selectedAdvRole = false;
                $scope.disableAdvertiserAssign = false;
                $scope.prevState = $rootScope.fromState.name;
                if($scope.prevState == undefined || $scope.prevState == ""){
                    $scope.prevState = "dashboard.agency";
                }

                $scope.prospectiveUsers = {
                    firstName:"",
                    lastName:"",
                    email:"",
                    phoneNo:"",
                    country:"",
                    city:"",
                    companyName:"",
                    userTitle:"",
                    websiteUrl:"",
                    monthlyWebsiteVisitors:"",
                    appUrl:"",
                    monthlyAppUsers:"",
                    prefferedTime:"",
                    timeSlot:"",
                    timezone:"",
                    message:"",
                    organizationName:"",
                    status:"",
                    createdUserId:"",
                };
                $scope.showAdvertiserCreateAssign = 1;

                $scope.aeAdvertisersProsp = {
                    id:"",
                    contactName: "",
                    companyName: "",
                    legalEntityName: "",
                    contactEmail: "",
                    contactPhone: "",
                    billingContactName: "",
                    billingEmail: "",
                    billingPhoneNo: "",
                    city : "",
                    country : "",
                    websiteUrl : "",
                    androidApp : "",
                    iosApp : "",
                    timezone:"",
                    language:"",
                    billingCurrency:"",
                    numberFormat:"",
                    businessCategory:"",
                    businessType:"",
                    thirdPartyAdServer:"",
                    allowAdTagAccess:"",
                    creditLimit:"",
                    note:"",
                    createdUserId:"",
                    permission:"",

                };
                $scope.roles={
                    userRole:"",
                    accessMode:"",
                    permission : "",
                };

                $scope.advertiserAssign = {
                    userAccessPermission :"",
                    assignAdvertiser : [],
                    createdUserId : "",
                    removeAdvertisers : [],
                };

                $scope.step_1=true;
                $scope.step_2=false;
                $scope.step_3=false;
                $scope.step_4=false;

                $scope.digitalAssetsShow = false;
                $scope.demoSchedulingInfoShow = false;
                $scope.otherInfoShow = false;


                $scope.prospectiveUserTitleShow=false;
                $scope.userTitleShow = false;

                $scope.userRoles = [];
                $scope.accessModes = [];

                $scope.getExistUser={};
                $scope.getExistUser = userExist;
                $scope.getAssignAdvertisersAndRoles = {
                    assignedAdvertisers:[],
                    assignedRole:[]
                }

                $scope.clickableStep1=false;
                $scope.clickableStep2=false;
                $scope.clickableStep3=false;
                $scope.createdUserEdit = false;

                $scope.advertiserAssignAdd = [];
                $scope.prospectiveCheck = false;

                $scope.userRoleCheck = true;
                $scope.accessModeCheck = true;
                $scope.advPermissionCheck = true;

                $scope.agency = {
                    id:"",
                    agencyName:"",
                    legalEntityName:"",
                    email:"",
                    websiteUrl:"",
                    phoneNo:"",
                    address:"",
                    city:"",
                    country:"",
                    defaultCurrency:"",
                    defaultTimezone:"",
                    margin:"",
                    createdUserId:"",
                    permission:""
                }

                $scope.assignAgency = {
                    createdUserId:"",
                    userRole:"",
                    permission:"",
                    agencyId:"",
                    removeAgency:""
                }

                $scope.showStepAgency = false;
                $scope.showCreateAgency = true;
                $scope.showAssignAgency = false;

                $scope.assignedAgency = [];
                $scope.allAgencies = [];
                $scope.agencyAssigned = [];
                $scope.assignAgencySuccess = false;
            }


            function initServices(){
                //third step check
                $scope.contactNameCheck = false;
                if($scope.aeAdvertisersProsp.contactName == "" || $scope.aeAdvertisersProsp.contactName==undefined){
                    $scope.contactNameCheck = true;
                }
                $scope.aeCompanyNameCheck = false;
                if($scope.aeAdvertisersProsp.companyName == "" || $scope.aeAdvertisersProsp.companyName== undefined){
                    $scope.aeCompanyNameCheck = true;
                }
                $scope.contactEmailCheck = false;
                if($scope.aeAdvertisersProsp.contactEmail == "" || $scope.aeAdvertisersProsp.contactEmail==undefined){
                    $scope.contactEmailCheck=true;
                }
                $scope.contactPhoneCheck=false;
                if($scope.aeAdvertisersProsp.contactPhone == "" || $scope.aeAdvertisersProsp.contactPhone==undefined){
                    $scope.contactPhoneCheck=true;
                }
                $scope.legalEntityCheck = false;
                if($scope.aeAdvertisersProsp.legalEntityName == "" || $scope.aeAdvertisersProsp.legalEntityName==undefined){
                    $scope.legalEntityCheck = true;
                }

                $scope.billingContactNameCheck = false;
                if($scope.aeAdvertisersProsp.billingContactName == "" || $scope.aeAdvertisersProsp.billingContactName==undefined){
                    $scope.billingContactNameCheck=true;
                }
                $scope.billingEmailCheck = false;
                if($scope.aeAdvertisersProsp.billingEmail == "" || $scope.aeAdvertisersProsp.billingEmail==undefined){
                    $scope.billingEmailCheck = true;
                }
                $scope.billingPhoneCheck=false;
                if($scope.aeAdvertisersProsp.billingPhoneNo == "" || $scope.aeAdvertisersProsp.billingPhoneNo==undefined){
                    $scope.billingPhoneCheck = true;
                }
                $scope.creditLimitCheck=false;
                if($scope.aeAdvertisersProsp.creditLimit == "" || $scope.aeAdvertisersProsp.creditLimit==undefined){
                    $scope.creditLimitCheck=true;
                }
                $scope.addressCheck = false;
                if($scope.aeAdvertisersProsp.address == "" || $scope.aeAdvertisersProsp.address== undefined){
                    $scope.addressCheck=true;
                }
                $scope.aeCityCheck=false;
                if($scope.aeAdvertisersProsp.city == "" || $scope.aeAdvertisersProsp.city==undefined){
                    $scope.aeCityCheck=true;
                }
                $scope.aeWebsiteUrlCheck = false;
                if($scope.aeAdvertisersProsp.websiteUrl == "" || $scope.aeAdvertisersProsp.websiteUrl == undefined){
                    $scope.aeWebsiteUrlCheck=true;
                }
                $scope.androidAppUrlCheck = false;
                if($scope.aeAdvertisersProsp.androidApp == "" || $scope.aeAdvertisersProsp.androidApp==undefined){
                    $scope.androidAppUrlCheck=true;
                }

                $scope.iOsAppurlCheck = false;
                if($scope.aeAdvertisersProsp.iosApp == "" || $scope.aeAdvertisersProsp.iosApp==undefined){
                    $scope.iOsAppurlCheck = true;
                }

                $scope.allAeAdvertisers = aeAdvertisers;

                $scope.showCreateAdvertisers = function () {
                    $scope.showAdvertiserCreateAssign = 1;
                    $scope.notFilledRequiredFields = false;
                };

                $scope.showAssignAdvertiser = function () {
                    $scope.showAdvertiserCreateAssign = 2;
                    $scope.notFilledRequiredFields = false;
                };

                var onCreateUserSuccess = function (result) {
                    $scope.result = result;
                    $scope.prospectiveUsers.createdUserId = result.id;
                    $scope.roles.createdUserId = result.id;
                    $scope.advertiserAssign.createdUserId = result.id;
                    $scope.aeAdvertisersProsp.createdUserId=result.id;
                    $scope.notFilledRequiredFields = false;
                    $scope.step(2);
                };
                var onCreateUserError = function (response) {
                    $scope.notFilledRequiredFields = true;
                    if (response.status === 400 && response.data.hasOwnProperty("emailExist")) {
                        $scope.contollerErrorMsg = "User already exists with this e-mail.";
                    }else{
                        $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                        $scope.notFilledRequiredFields = true;
                    }
                };
                $scope.createUser = function () {
                    if($scope.prospectiveUsers["firstName"] == undefined || $scope.prospectiveUsers["firstName"]=="" || !$scope.prospectiveUsers["firstName"].trim()){
                        $scope.contollerErrorMsg = "First Name is required.";
                        $scope.notFilledRequiredFields = true;
                        return false;
                    }else if($scope.prospectiveUsers["lastName"]==undefined || $scope.prospectiveUsers["lastName"]=="" || !$scope.prospectiveUsers["lastName"].trim()){
                        $scope.contollerErrorMsg = "Last Name is required.";
                        $scope.notFilledRequiredFields = true;
                        return false;
                    }else if($scope.prospectiveUsers["email"] == undefined || $scope.prospectiveUsers["email"] == "" || !$scope.prospectiveUsers["email"].trim()){
                        $scope.contollerErrorMsg = "Email is required.";
                        $scope.notFilledRequiredFields = true;
                        return false;
                    }else if($scope.prospectiveUsers.organizationName == undefined || $scope.prospectiveUsers.organizationName == "" || !$scope.prospectiveUsers.organizationName.trim()){
                        $scope.contollerErrorMsg = "Organization Name is required.";
                        $scope.notFilledRequiredFields = true;
                        return false;
                    }
                    UserCreate.save($scope.prospectiveUsers,onCreateUserSuccess,onCreateUserError);
                };

                var onSuccessRolesAssign = function (data) {
                    if($scope.createdUserEdit==false){
                        $scope.showCreateAgency = true;
                        $scope.showAssignAgency = false;
                    }else{
                        $scope.showCreateAgency = false;
                        $scope.showAssignAgency = true;
                    }
                    if($scope.roles.userRole == "AGENCY"){

                        for(var authority in $scope.account.authorities){
                            if($scope.account.authorities[authority] == "ROLE_ADMIN" || $scope.account.authorities[authority] == "SUPER_EXPERT_ADMIN"){
                                $scope.showStepAgency = true;
                            }
                        }
                    }
                    if($scope.showStepAgency && $scope.roles.userRole == "AGENCY"){
                        $scope.step(4);
                    }else{
                        $scope.step(3);
                        $scope.showStepAgency = false;
                    }
                    if( $scope.assignAgencySuccess){
                        $scope.showCreateAgency = false;
                        $scope.showAssignAgency = true;
                    }

                    $scope.notFilledRequiredFields = false;
                };
                var onErrorRolesAssign = function (data) {
                    $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                    $scope.notFilledRequiredFields = true;
                };
                $scope.assignRoles = function () {
                    $scope.roles.permission = $scope.aeAdvertisersProsp.permission;
                    $scope.advertiserAssign.userAccessPermission = $scope.aeAdvertisersProsp.permission;

                    if($scope.roles.userRole == "" || $scope.roles.userRole == undefined){
                        $scope.contollerErrorMsg = "User Type is required.";
                        $scope.notFilledRequiredFields = true;
                        return
                    }
                    if($scope.roles.accessMode == "" || $scope.roles.accessMode==undefined){
                        $scope.contollerErrorMsg = "Access Mode is required.";
                        $scope.notFilledRequiredFields = true;
                        return
                    }
                    if($scope.roles.permission == "" || $scope.roles.permission == undefined){
                        $scope.contollerErrorMsg = "Access Permission is required.";
                        $scope.notFilledRequiredFields = true;
                        return
                    }
                    UserRolesAssign.save($scope.roles,onSuccessRolesAssign,onErrorRolesAssign);
                };


                var onSuccessAdvertiserAssign = function (response) {
                    $scope.notFilledRequiredFields = false;
                    $state.go($scope.prevState);
//                    $scope.step(4);
                };
                var onErrorAdvertiserAssign = function (response) {
                    if(response.hasOwnProperty("data") && response.data != ""){
                        $scope.contollerErrorMsg = response.data;
                    }else{
                        $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                    }
                    $scope.notFilledRequiredFields = true;
                };

                var onSuccessAdvertiserCreate = function (advertiser) {
                    $scope.aeAdvertisersProsp.id=advertiser.id;
                    $scope.contollerErrorMsg = "";
                    $scope.notFilledRequiredFields = false;
                    $state.go($scope.prevState);
//                    $scope.step(4);

                };
                var onErrorAdvertiserCreate = function () {
                    $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                    $scope.notFilledRequiredFields = true;
                };

                $scope.createOrAssignAdvertiser = function () {
                    if($scope.createdUserEdit){
                        $state.go($scope.prevState);
                        return
                    }
                    if($scope.showAdvertiserCreateAssign==1){
                        if($scope.aeAdvertisersProsp["contactName"]==undefined || $scope.aeAdvertisersProsp["contactName"]=="" || !$scope.aeAdvertisersProsp["contactName"].trim()){
                            $scope.contollerErrorMsg = "Contact Name is required.";
                            $scope.notFilledRequiredFields = true;
                            return
                        }else if($scope.aeAdvertisersProsp["companyName"]==undefined || $scope.aeAdvertisersProsp["companyName"]=="" || !$scope.aeAdvertisersProsp["companyName"].trim()){
                            $scope.contollerErrorMsg = "Company Name is required.";
                            $scope.notFilledRequiredFields = true;
                            return

                        }else if($scope.aeAdvertisersProsp["contactEmail"]==undefined || $scope.aeAdvertisersProsp["contactEmail"]=="" || !$scope.aeAdvertisersProsp["contactEmail"].trim()){
                            $scope.contollerErrorMsg = "Contact Email is required.";
                            $scope.notFilledRequiredFields = true;
                            return

                        }else if($scope.aeAdvertisersProsp["billingCurrency"]==undefined || $scope.aeAdvertisersProsp["billingCurrency"]=="" || !$scope.aeAdvertisersProsp["billingCurrency"].trim()){
                            $scope.contollerErrorMsg = "Billing Currency is required.";
                            $scope.notFilledRequiredFields = true;
                            return

                        }else if($scope.aeAdvertisersProsp["country"] == undefined || $scope.aeAdvertisersProsp["country"] == ""){
                            $scope.contollerErrorMsg = "Country is required.";
                            $scope.notFilledRequiredFields = true;
                            return
                        }else if($scope.aeAdvertisersProsp.timezone == undefined || $scope.aeAdvertisersProsp.timezone==""){
                            $scope.contollerErrorMsg = "Timezone is required.";
                            $scope.notFilledRequiredFields = true;
                            return

                        }else if($scope.aeAdvertisersProsp.legalEntityName == undefined || $scope.aeAdvertisersProsp.legalEntityName== "" ||!$scope.aeAdvertisersProsp.legalEntityName.trim()){
                            $scope.contollerErrorMsg = "Legal Entity Name is required.";
                            $scope.notFilledRequiredFields = true;
                            return

                        }else if( ($scope.aeAdvertisersProsp.websiteUrl=="" || $scope.aeAdvertisersProsp.websiteUrl == undefined || !$scope.aeAdvertisersProsp.websiteUrl.trim()) && ($scope.aeAdvertisersProsp.androidApp=="" || $scope.aeAdvertisersProsp.androidApp == undefined || !$scope.aeAdvertisersProsp.androidApp.trim()) && ($scope.aeAdvertisersProsp.iosApp=="" || $scope.aeAdvertisersProsp.iosApp == undefined || !$scope.aeAdvertisersProsp.iosApp.trim())){
                            $scope.contollerErrorMsg = "Website Url or Android App Url or iOs app url is required.";
                            $scope.notFilledRequiredFields = true;
                            return
                        }
                        $scope.aeAdvertisersProsp['flag'] = "createadvertiser";
                        CreateAdvertisers.save($scope.aeAdvertisersProsp,onSuccessAdvertiserCreate,onErrorAdvertiserCreate);

                    }else if($scope.showAdvertiserCreateAssign==2){
                        var advertiser;
                        for(advertiser in $scope.advertiserAssignAdd){
                            if($scope.advertiserAssign.assignAdvertiser.indexOf($scope.advertiserAssignAdd[advertiser].id) == -1){
                                $scope.advertiserAssign.assignAdvertiser.push($scope.advertiserAssignAdd[advertiser]["id"]);
                            }
                        }

                        if($scope.advertiserAssign.assignAdvertiser.length == 0 && $scope.advertiserAssign.removeAdvertisers.length == 0){
                            $scope.contollerErrorMsg = "Please Select Advertisers to assign.";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }
                        AssignAdvertisers.save($scope.advertiserAssign,onSuccessAdvertiserAssign,onErrorAdvertiserAssign);
                    }
                };

                $scope.step = function (val) {
                    if(val == 1){
                        $scope.step_1=true;
                        $scope.step_2=false;
                        $scope.step_3=false;
                        $scope.step_4=false;
                    }else if(val == 2){
                        $scope.step_1=false;
                        $scope.step_2=true;
                        $scope.step_3=false;
                        $scope.step_4=false;
                    }else if(val == 3){
                        $scope.step_1=false;
                        $scope.step_2=false;
                        $scope.step_3=true;
                        $scope.step_4=false;
                    }else if(val==4){
                        $scope.step_1=false;
                        $scope.step_2=false;
                        $scope.step_3=false;
                        $scope.step_4=true;
                    }
                    $scope.contollerErrorMsg = "";
                    $scope.notFilledRequiredFields = false;
                };

                $scope.prevClear = function () {
                    $scope.notFilledRequiredFields = false;
                    $scope.contollerErrorMsg = "";
                };

                $scope.getSelectionInfo = GetSelectionInfo.getSelectionInfo().then(function (data) {
                    $scope.currency =  data.currenceyInfo;
                    $scope.iabCategorization = data.iabCategorization;
                    $scope.userRoles = data.userRoles;
                    $scope.accessModes = data.accessModes;
                    $scope.permissions = data.permissions;
                    $scope.timeZoneInfo = [];
                    $scope.countryCodes = data.countryCodes;
                    $scope.allAgencies = data.agencies;
                    for(var timezone in data.timezoneInfo){
                        var obj = {};
                        var obj2 = data.timezoneInfo[timezone];
                        obj.id = obj2.id;
                        obj.name = obj2.name;
                        var input = Math.abs(obj2.additionalNumber);
                        var hours = parseInt( input / 60, 10 );
                        var minutes = (input - (hours * 60)) < 10 ? '0' + (input - (hours * 60)) : input - (hours * 60);
                        if (obj2.additionalNumber.toString().charAt(0) == '-'){
                            obj.additionalNumber = '-'+hours+":"+minutes;
                        }else{
                            obj.additionalNumber = '+'+hours+":"+minutes;
                        }
                        $scope.timeZoneInfo.push(obj);
                    }

                });

                if(userExist != null) {
                    userExist["$promise"].then(function (data) {
                            $scope.prospectiveUsers.firstName = $scope.getExistUser.firstName;
                            $scope.prospectiveUsers.lastName = $scope.getExistUser.lastName;
                            $scope.prospectiveUsers.email = $scope.getExistUser.email;
                            $scope.prospectiveUsers.createdUserId = $scope.getExistUser.id;
                            $scope.prospectiveUsers.organizationName = $scope.getExistUser.organizationName;
                            $scope.showAdvertiserCreateAssign = 2;
                            $scope.roles.createdUserId = $scope.getExistUser.id;
                            $scope.agency.createdUserId = $scope.getExistUser.id;

                            $scope.clickableStep1 = true;
                            $scope.clickableStep2 = true;
                            $scope.createdUserEdit = true;


                            $scope.firstNameCheck = false;
                            if ($scope.prospectiveUsers.firstName == "" || $scope.prospectiveUsers.firstName == undefined) {
                                $scope.firstNameCheck = true;
                            }
                            $scope.lastNameCheck = false;
                            if ($scope.prospectiveUsers.lastName == "" || $scope.prospectiveUsers.lastName == undefined) {
                                $scope.lastNameCheck = true;
                            }
                            $scope.emailCheck = false;
                            if ($scope.prospectiveUsers.email == "" || $scope.prospectiveUsers.email == undefined) {
                                $scope.emailCheck = true;
                            }
                            $scope.organisationNameCheck = false;
                            if ($scope.prospectiveUsers.organizationName == "" || $scope.prospectiveUsers.organizationName == undefined) {
                                $scope.organisationNameCheck = true;
                            }


                    });

                  assignedAdvertiserAndRoles.$promise.then(function(data){
                        $scope.getAssignAdvertisersAndRoles = assignedAdvertiserAndRoles;
                        var assignedAdvertisers = $scope.getAssignAdvertisersAndRoles.assignedAdvertisers;
                        if ($scope.getAssignAdvertisersAndRoles.assignedRole.length > 0) {
                            var assignedRole = $scope.getAssignAdvertisersAndRoles.assignedRole[0].name;
                            var assignedRoles = assignedRole.split('_');
                            $scope.roles.userRole = assignedRoles[0];
                            $scope.roles.accessMode = assignedRoles[1];
                            $scope.aeAdvertisersProsp.permission = assignedRoles[2];
                            $scope.roles.permission =  assignedRoles[2];

                            $scope.advertiserAssign.createdUserId = $stateParams.id;
                            $scope.advertiserAssign.userAccessPermission = $scope.roles.permission;

                            $scope.clickableStep3 = true;

                            if($scope.roles.userRole == "ADVERTISER"){
                                $scope.selectedAdvRole = true;
                                $scope.disableAdvertiserAssign = true;
                            }
                        }
                        var advertisers;
                        var advertiser;
                        aeAdvertisers.$promise.then(function () {
                              for (advertisers in $scope.allAeAdvertisers) {
                                  for (advertiser in assignedAdvertisers) {
                                      if (assignedAdvertisers[advertiser].advertiserId == $scope.allAeAdvertisers[advertisers].id){
                                          $scope.advertiserAssignAdd.push($scope.allAeAdvertisers[advertisers]);
                                          $scope.allAeAdvertisers.splice(advertisers,1);
                                          break;
                                      }
                                  }
                              }
                        });

                      $scope.userRoleCheck = false;
                      if($scope.roles.userRole== "" || $scope.roles.userRole==undefined){
                          $scope.userRoleCheck = true;
                      }
                      $scope.accessModeCheck = false;
                      if($scope.roles.accessMode== "" || $scope.roles.accessMode==undefined){
                          $scope.accessModeCheck = true;
                      }
                      $scope.advPermissionCheck = false;
                      if($scope.aeAdvertisersProsp.permission== "" || $scope.aeAdvertisersProsp.permission==undefined){
                          $scope.advPermissionCheck = true;
                      }
                    if($scope.roles.userRole == "AGENCY"){
                        for(var authority in $scope.account.authorities){
                            if($scope.account.authorities[authority] == "ROLE_ADMIN" || $scope.account.authorities[authority] == "SUPER_EXPERT_ADMIN"){
                                $scope.showStepAgency = true;
                            }
                        }
                        $scope.showAssignAgency = true;
                        $scope.showCreateAgency = false;
                    }

                    $scope.assignedAgency = assignedAdvertiserAndRoles.assignedAgency;
                    $scope.allAgencies = data.agencies;

                    $scope.agencyAssigned = [];
                    for(var index in $scope.allAgencies){
                        for(var ind in $scope.assignedAgency){
                            if($scope.allAgencies[index].id ==  $scope.assignedAgency[ind].agencyId){
                                 $scope.agencyAssigned.push($scope.allAgencies[index]);
                            }
                        }

                    }
                     if($scope.agencyAssigned.length != 0){
                        $scope.agencyAssignDisable = true;
                     }

                });

                }

                $scope.advertiserAdd = function (advertiseradd) {
                    if ($scope.advertiserAssignAdd.indexOf(advertiseradd) == -1 && advertiseradd != undefined) {
                        if($scope.selectedAdvRole && ($scope.advertiserAssignAdd.length==1 || $scope.advertiserAssignAdd.length >1 )){
                            $scope.advAssignMultiFlag = true;
                            return false;
                        }
                        $scope.advertiserAssignAdd.push(advertiseradd);
                        if ($scope.advertiserAssign.removeAdvertisers.indexOf(advertiseradd.id) != -1) {
                            $scope.advertiserAssign.removeAdvertisers.splice($scope.advertiserAssign.removeAdvertisers.indexOf(advertiseradd.id), 1)
                        }
                        $scope.allAeAdvertisers.splice($scope.allAeAdvertisers.indexOf(advertiseradd), 1)
                        if($scope.roles.userRole == "ADVERTISER"){
                            $scope.selectedAdvRole = true;
                        }
                    }
                };

                $scope.removeAssignedAdvertiser= function (advertiser) {
                    var adv;
                    var ind;
                    for(adv in $scope.advertiserAssignAdd){
                        if($scope.advertiserAssignAdd[adv]["id"] == advertiser.id){
                            ind = $scope.advertiserAssignAdd.indexOf(advertiser);
                            $scope.advertiserAssignAdd.splice(ind,1);
                            $scope.advertiserAssign.assignAdvertiser.splice($scope.advertiserAssign.assignAdvertiser.indexOf(advertiser.id));
                            $scope.advertiserAssign.removeAdvertisers.push(advertiser.id);
                            $scope.allAeAdvertisers.push(advertiser);
                            if($scope.advertiserAssignAdd.length==0){
                                $scope.disableAdvertiserAssign = false;
                            }
                        }
                    }
                };

                $scope.passwordResetInfoFlag = false;
                var onResetPasswordSuccess = function (response) {
                    $scope.passwordResetInfoFlag = true;
                    $scope.passwordResetInfoMessage = "Password Reset Successfully please check your email !";
                };
                var onResetPasswordFailure = function (response) {
                    $scope.passwordResetInfoFlag = true;
                    $scope.passwordResetInfoMessage = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                };
                $scope.resetUserPassword = function () {
                    PasswordResetInit.save($scope.getExistUser.email,onResetPasswordSuccess,onResetPasswordFailure);
                };


                $scope.firstNameCheck = false;
                if($scope.prospectiveUsers.firstName == "" || $scope.prospectiveUsers.firstName==undefined){
                    $scope.firstNameCheck = true;
                }
                $scope.lastNameCheck = false;
                if($scope.prospectiveUsers.lastName == "" || $scope.prospectiveUsers.lastName==undefined){
                    $scope.lastNameCheck = true;
                }
                $scope.emailCheck = false;
                if($scope.prospectiveUsers.email == "" || $scope.prospectiveUsers.email == undefined){
                    $scope.emailCheck = true;
                }
                $scope.organisationNameCheck = false;
                if ($scope.prospectiveUsers.organizationName == "" || $scope.prospectiveUsers.organizationName == undefined) {
                    $scope.organisationNameCheck = true;
                }
                $scope.userTitleCheck = false;
                if($scope.prospectiveUsers.userTitle == "" || $scope.prospectiveUsers.userTitle == undefined){
                    $scope.userTitleCheck = true;
                }
                $scope.companyNameCheck = false;
                if($scope.prospectiveUsers.companyName == "" || $scope.prospectiveUsers.companyName == undefined){
                    $scope.companyNameCheck = true;
                }
                $scope.websiteUrlCheck = false;

                if($scope.prospectiveUsers.websiteUrl == "" || $scope.prospectiveUsers.websiteUrl == undefined){
                    $scope.websiteUrlCheck = true;
                }
                $scope.appUrlCheck = false;
                if($scope.prospectiveUsers.appUrl == "" || $scope.prospectiveUsers.appUrl == undefined){
                    $scope.appUrlCheck = true;
                }
                $scope.websiteVisitorsCheck = false;
                if($scope.prospectiveUsers.monthlyWebsiteVisitors == "" || $scope.prospectiveUsers.monthlyWebsiteVisitors == undefined){
                    $scope.websiteVisitorsCheck = true;
                }
                $scope.appUsersCheck = false;
                if($scope.prospectiveUsers.monthlyAppUsers == "" || $scope.prospectiveUsers.monthlyAppUsers == undefined){
                    $scope.appUsersCheck = true;
                }
                $scope.cityCheck = false;
                if($scope.prospectiveUsers.city == "" || $scope.prospectiveUsers.city == undefined){
                    $scope.cityCheck = true;
                }

                $scope.countryCheck = false;
                if($scope.prospectiveUsers.country == "" || $scope.prospectiveUsers.country == undefined){
                    $scope.countryCheck = true;
                }

                $scope.messageCheck = false;
                if($scope.prospectiveUsers.message == "" || $scope.prospectiveUsers.message == undefined){
                    $scope.messageCheck = true;
                }


                $scope.validateFunction = function (input) {
                    if(input == undefined || input == null || input===""){
                        return "This field is required.";
                    }
                };

                $scope.editableSaveStep1 = function (data) {
                    var successFunc = function(data){
                    };
                    var failureFunc = function(response){
                        $scope.notFilledRequiredFields = true;
                        if (response.status === 400 && response.data.hasOwnProperty("emailExist")) {
                            $scope.contollerErrorMsg = "User already exists with this e-mail.";
                        }else if(response.status === 400 && response.data.hasOwnProperty("securityError")){
                            $scope.contollerErrorMsg = "You can not update your login credentials!";
                        }else{
                            $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                            $scope.notFilledRequiredFields = true;
                        }
                    };
                    //edit user
                    UserCreate.save($scope.prospectiveUsers,successFunc,failureFunc);
                };
                $scope.editableSaveStep2 = function (data) {
                    //edit roles and permission
                    $scope.roles.permission = $scope.aeAdvertisersProsp.permission;
                    var onSuccess = function (response) {
                        if($scope.roles.userRole == "AGENCY"){
                            for(var authority in $scope.account.authorities){
                                if($scope.account.authorities[authority] == "ROLE_ADMIN" || $scope.account.authorities[authority] == "SUPER_EXPERT_ADMIN"){
                                    $scope.showStepAgency = true;
                                }
                            }
                        }else{
                            $scope.showStepAgency = false;
                        }
                        if($scope.showStepAgency==true){
                            $scope.showCreateAgency = false;
                            $scope.showAssignAgency = true;
                        }
                    };
                    var onFailure = function (response) {
                        $scope.contollerErrorMsg = response.data;
                        $scope.notFilledRequiredFields = true;
                    };
                    UserRolesAssign.save($scope.roles,onSuccess,onFailure);
                    if($scope.roles.userRole == "ADVERTISER"){
                        $scope.selectedAdvRole = true;
                        $scope.disableAdvertiserAssign = true;
                    }else{
                        $scope.selectedAdvRole = false;
                        $scope.disableAdvertiserAssign = false;
                    }
                };
                $scope.editableSaveStep3 = function (data) {
                    //assign or remove advertisers
                    console.log(data);
                };

                $scope.advertiserAddEdit = function (advertiseradd) {
                    if($scope.createdUserEdit && advertiseradd != undefined){
                        if($scope.selectedAdvRole && $scope.advertiserAssignAdd.length >1){
                            $scope.advAssignMultiFlag = true;
                            return false;
                        }
                        $scope.advertiserAssign.userAccessPermission = $scope.aeAdvertisersProsp.permission;
                        $scope.advertiserAssign.assignAdvertiser.push(advertiseradd.id);
                        var onSuccess = function (response) {
                            $scope.advertiserAssign.assignAdvertiser.pop();
                            // $scope.advertiserAssignAdd.push(advertiseradd);
                        };
                        var onFailure = function (response) {
                            if(response.hasOwnProperty("data") && response.data != ""){
                                $scope.contollerErrorMsg = response.data;
                            }else{
                                $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                            }
                            $scope.notFilledRequiredFields = true;
                        };
                        AssignAdvertisers.save($scope.advertiserAssign,onSuccess,onFailure);


                    }
                };

                $scope.removeAssignedAdvertiserEdit = function (advertiser) {
                    if($scope.createdUserEdit && advertiser != undefined){
                        $scope.advertiserAssign.userAccessPermission = $scope.aeAdvertisersProsp.permission;
                        var onSuccess = function (response) {
                            $scope.advertiserAssign.removeAdvertisers.pop();
                            if($scope.advertiserAssignAdd.length == 0){
                                $scope.disableAdvertiserAssign = false;
                            }
                        };
                        var onFailure = function (response) {
                            if(response.hasOwnProperty("data") && response.data != ""){
                                $scope.contollerErrorMsg = response.data;
                            }else{
                                $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                            }
                            $scope.notFilledRequiredFields = true;
                        };
                        AssignAdvertisers.save($scope.advertiserAssign,onSuccess,onFailure)


                    }
                };

                $scope.showAgencyCreate = function(){
                    $scope.showAssignAgency = false;
                    $scope.showCreateAgency = true;
                    $scope.contollerErrorMsg = "";
                    $scope.notFilledRequiredFields = false;
                };
                $scope.showAgencyAssign = function(){
                    $scope.showCreateAgency = false;
                    $scope.showAssignAgency = true;
                    $scope.contollerErrorMsg = "";
                    $scope.notFilledRequiredFields = false;
                };

                var onSuccessAgencyCreate = function(response){
                    $scope.agency.id = response.id;
                    $scope.step(3);
                    $scope.contollerErrorMsg = "";
                    $scope.notFilledRequiredFields = false;
                };
                var onFailureAgencyCreate = function(response){
                    $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                    $scope.notFilledRequiredFields = true;
                    return;
                };

                $scope.createAgency = function(agency){
                    $scope.agency.permission = $scope.aeAdvertisersProsp.permission;
                    $scope.agency.createdUserId = $scope.prospectiveUsers.createdUserId;
                    if($scope.showCreateAgency == true){
                        if($scope.agency.agencyName == ""){
                            $scope.contollerErrorMsg = "Agency Name is required !";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }else if($scope.agency.legalEntityName == ""){
                            $scope.contollerErrorMsg = "Legal Entity Name is required !";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }else if($scope.agency.email == ""){
                            $scope.contollerErrorMsg = "Email is required !";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }else if($scope.agency.websiteUrl == ""){
                            $scope.contollerErrorMsg = "Website Url is required !";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }else if($scope.agency.country == ""){
                            $scope.contollerErrorMsg = "Country is required !";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }else if($scope.agency.margin == ""){
                            $scope.contollerErrorMsg = "Margin is required !";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }
                        AddAgency.save($scope.agency,onSuccessAgencyCreate,onFailureAgencyCreate);
                    }else if($scope.showAssignAgency==true){
                        if($scope.assignAgency.agencyId == "" && !$scope.assignAgencySuccess){
                            $scope.contollerErrorMsg = "Please create or assign agency to the user!";
                            $scope.notFilledRequiredFields = true;
                            return;
                        }
                        var onSuccessAgencyAssign = function(response){
                            $scope.assignAgency.agencyId= "";
                            $scope.assignAgency.removeAgency ="";
                            $scope.assignAgencySuccess = true;
                            $scope.step(3);
                        };
                        var onFailureAgencyAssign = function(response){
                            if(response.data=""){
                                $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                            }else{
                                $scope.contollerErrorMsg=response.data;
                            }
                            $scope.notFilledRequiredFields = true;
                        };

                        $scope.assignAgency.createdUserId=$scope.prospectiveUsers.createdUserId;
                        $scope.assignAgency.userRole=$scope.roles.userRole;
                        $scope.assignAgency.permission=$scope.aeAdvertisersProsp.permission;
                        AssignAgency.save($scope.assignAgency,onSuccessAgencyAssign,onFailureAgencyAssign);
                    }
                };


                var onSuccessAgencyAssignEdit = function(response){
                    $scope.assignAgency.agencyId= "";
                    $scope.assignAgency.removeAgency ="";
                };
                var onFailureAgencyAssignEdit = function(response){
                    if(response.data=""){
                        $scope.contollerErrorMsg = "Sorry ! We are facing some problem, your changes are not being saved, please try again later";
                    }else{
                        $scope.contollerErrorMsg=response.data;
                    }
                    $scope.notFilledRequiredFields = true;
                };
                $scope.agencyAdd = function(agency){
                    if($scope.agencyAssigned.length == 0){
                        $scope.agencyAssigned.push(agency);
                        $scope.assignAgency.agencyId=agency.id;
                        $scope.agencyAssignDisable = true;
                    }else{
                        $scope.agencyAssignDisable = true;
                    }
                };
                $scope.agencyAddEdit = function(agency){
                    if($scope.createdUserEdit){
                        $scope.assignAgency.createdUserId=$scope.prospectiveUsers.createdUserId;
                        $scope.assignAgency.userRole=$scope.roles.userRole;
                        $scope.assignAgency.permission=$scope.aeAdvertisersProsp.permission;
                        AssignAgency.save($scope.assignAgency,onSuccessAgencyAssignEdit,onFailureAgencyAssignEdit);
                    }
                };
                $scope.removeAssignedAgency = function(agency){
                    $scope.agencyAssigned.splice($scope.agencyAssigned.indexOf(agency),1);
                    $scope.assignAgency.removeAgency = agency.id;
                    if($scope.agencyAssigned.length == 0){
                        $scope.agencyAssignDisable = false;
                    }
                };
                $scope.removeAssignedAgencyEdit = function(agency){
                    if($scope.createdUserEdit){
                        $scope.assignAgency.createdUserId=$scope.prospectiveUsers.createdUserId;
                        $scope.assignAgency.userRole=$scope.roles.userRole;
                        $scope.assignAgency.permission=$scope.aeAdvertisersProsp.permission;
                        AssignAgency.save($scope.assignAgency,onSuccessAgencyAssignEdit,onFailureAgencyAssignEdit);
                    }
                };

            }
            $scope.usrRoleChanged = function(){
                if($scope.roles.userRole == "ADVERTISER"){
                    $scope.selectedAdvRole = true;
                }
                else{
                    $scope.selectedAdvRole = false;
                    $scope.disableAdvertiserAssign = false;
                }
            };

            initVariables();
            initServices();

    }]);
