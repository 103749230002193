(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService','$rootScope'];

    function notificationInterceptor ($q, AlertService,$rootScope) {
        var service = {
            response: response
        };

        return service;

        function response (response) {
            var alertKey = response.headers('X-adelementAdminApp-alert');
            if (angular.isString(alertKey)) {
                AlertService.success(alertKey, { param : response.headers('X-adelementAdminApp-params')});
            }
            if(response.config!=undefined && response.config.url.indexOf("errors/")==-1 && response.config.url.indexOf("api/account") == -1 && (response.config.url.indexOf("api/campaign-manager")!=-1 || response.config.url.indexOf("api/creatives")!=-1) && (response.config.method=="POST" || response.config.method=="PUT"))
            {
                $rootScope.httpSuccessCatch("Saved Successfully!!");
            }

            return response;
        }
    }
})();
