/**
 * Created by maitray on 29/3/17.
 */

angular.module('adelementAdminApp')
    .factory('FeedService', function ($http) {
        var url = "api/feed/";
        return {
            saveFeed: function (advId, data) {
                return $http.post(url+advId, data).then(function (response) {
                    return response.data;
                });
            },
            updateFeed: function (advId, feedId, data) {
                return $http.post(url+advId+"/"+feedId, data).then(function (response) {
                    return response.data;
                });
            },
            checkFeedName: function (feedName) {
                return $http.get(url+feedName+"/checkFeedName").then(function (response) {
                    return response.data;
                });
            },
            getFeed: function (advId,feedId) {
                return $http.get(url+advId+"/"+feedId).then(function (response) {
                    return response.data;
                });
            },
            getFeedProcessingInfo: function (advId,feedId,processingType) {
                return $http.get(url+advId+"/"+feedId+"/"+processingType+"/feedProcessing").then(function (response) {
                    return response.data;
                });
            },
            getSampleData: function (advId,feedId,type) {
                return $http.get(url+advId+"/"+feedId+"/"+type+"/sample").then(function (response) {
                    return response.data;
                });
            },
            getMacrosDate: function (searchVal) {
                return $http.get(url+"macro/"+searchVal).then(function (response) {
                    return response.data;
                });
            },
            setCustomFields : function(advId,feedId,data){
                return $http.post(url+advId+"/"+feedId+"/feedCustom", data).then(function (response) {
                    return response.data;
                });
            },
            setFilterFields : function(advId,feedId,data){
                return $http.post(url+advId+"/"+feedId+"/feedFilter", data).then(function (response) {
                    return response.data;
                });
            },
            linkContainerHashToFeed : function(advId,feedId,data){
                return $http.post(url+advId+"/"+feedId+"/linkContainerHash", data).then(function (response) {
                    return response.data;
                });
            },
            saveFeedTagMapping : function(advId, feedId, data) {
                return $http.post(url+advId+"/"+feedId+"/feedTagMapping", data).then(function (response) {
                    return response.data;
                });
            },
            saveProductDefinition : function(advId, feedId, data) {
                return $http.post(url+advId+"/"+feedId+"/productDefinition", data).then(function (response) {
                    return response.data;
                });
            },
            saveHierarchy : function(advId, feedId, data) {
                return $http.post(url+advId+"/"+feedId+"/hierarchy", data).then(function (response) {
                    return response.data;
                });
            },
            finalizeFeed : function(advId, feedId) {
                return $http.post(url+advId+"/"+feedId+"/finalFeed").then(function (response) {
                    return response.data;
                });
            },
            getFeeds : function () {
                return $http.get(url+"list").then(function (response) {
                    return response.data;
                });
            },
            downloadFeedToAePath: function (advId,feedId) {
                return $http.get(url+"aeDownload/"+advId+"/"+feedId).then(function (response) {
                    return null;
                });
            },
            getTagFeed: function (advId) {
                return $http.get(url+advId).then(function (response) {
                    return response.data;
                });
            }
        }
    })
    .factory('GetAdvertiserIDName', function ($resource) {
        return $resource('api/aeadvertisersidname', {}, {
            'query': { method: 'GET', isArray: true},
        })
    });
