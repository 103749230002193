/**
 * Created by maitray on 4/8/17.
 */

angular.module('adelementAdminApp')
    .factory('CampaignManagerService', function ($http) {
        var url = "api/campaign-manager/";
        return {
            saveCampaign: function (advId, data) {
                return $http.post(url+"new/campaign/"+advId, data).then(function (response) {
                    return response.data;
                });
            },
            updateCampaign: function (advId, feedId, data) {
                return $http.post(url+advId+"/"+feedId, data).then(function (response) {
                    return response.data;
                });
            },
            saveAdgroup : function (advId, campaignId, data) {
                return $http.post(url+"new/adgroup/"+advId+"/"+campaignId, data).then(function (response) {
                    return response.data;
                });
            },
            getAdSizes: function () {
                return $http.get(url+"getSizes").then(function (response) {
                    return response.data;
                });
            },
            saveAssignCreatives: function(advId,campaignId,adgroupId,data) {
                return $http.post(url+"assign/creatives/"+advId+"/"+campaignId+"/"+adgroupId, data).then(function (response) {
                    return response.data;
                });
            },
            getPretargetingAdgroups: function (adnetwork) {
                return $http.get(url+"getPretargingAdgroups/"+adnetwork).then(function (response) {
                    return response.data;
                });
            },
            getAudiences: function(advertiserId) {
                return $http.get(url+"getAudiences/"+advertiserId).then(function (response) {
                    return response.data;
                });
            },
            getTargetingSettings: function () {
                return $http.get(url+"getTargetingSettings").then(function (response) {
                    return response.data;
                });
            },
            getAdvancedTargeting: function (advertiserId,campaignId,adgroupId) {
                return $http.get(url+"getAdvancedTargeting/"+advertiserId+"/"+campaignId+"/"+adgroupId).then(function (response) {
                    return response.data;
                });
            },
            createZoneForAdgroupId : function (advertiserId,campaignId,adgroupId) {
                return $http.get(url+"createZones/"+advertiserId+"/"+campaignId+"/"+adgroupId).then(function (response) {
                    return response.data;
                });
            },
            getBlockedPlacements: function (advertiserId,adgroupId) {
                return $http.get(url+"getBlockedPlacements/"+advertiserId+"/"+adgroupId).then(function (response) {
                    return response.data;
                });
            },
            deleteBudgetCmp: function(advertiserId,campaignId,budgetId) {
                return $http.delete(url+"deleteBudgetCmp/"+advertiserId+"/"+campaignId+"/"+budgetId).then(function (response) {
                                return response.data;
                });
            },
            deleteBudgetAdg: function(advertiserId,adgroupId,budgetId) {
                return $http.delete(url+"deleteBudgetAdg/"+advertiserId+"/"+adgroupId+"/"+budgetId).then(function (response) {
                                return response.data;
                });
            },
            getThirdPartyCreatives: function (advertiserId,adgroupId) {
                return $http.get(url+"creativesForApproval/"+advertiserId+"/"+adgroupId).then(function (response) {
                    return response.data;
                });
            },
            resendForApproval: function (advertiserId,adgroupId,data) {
                return $http.post(url+"creatives/resendForApproval/"+advertiserId+"/"+adgroupId,data).then(function (response) {
                    return response.data;
                });
            },
            getCmapignConversionTrackers: function (advertiserId,campaignId) {
                var urli = url+"getCampaignConversionTracker/"+advertiserId+"/"+campaignId;
                return $http.get(urli).then(function (response) {
                    return response.data;
                });
            },
            getCloneCampaign: function (advertiserId,campaignId) {
                var urli = url+"getCloneCampaign/"+advertiserId+"/"+campaignId;
                return $http.get(urli).then(function (response) {
                    return response.data;
                });
            },
            getAdgroupPlacementBidOptProp: function (advertiserId,campaignId,adgroupId) {
                var urli = url+"getAdgroupPlacementBidOptProp/"+advertiserId+"/"+campaignId+"/"+adgroupId;
                return $http.get(urli).then(function (response) {
                    return response.data;
                });
            },
            getCloneAdgroup: function (advertiserId,adgroupId) {
                var urli = url+"getCloneAdgroup/"+advertiserId+"/"+adgroupId;
                return $http.get(urli).then(function (response) {
                    return response.data;
                });
            }
        }
    })

    // added by Snehal
    .factory('adgroupPlacementBidOptProperties', function(){
        var adgroupBidOpt =  {
            targetType: '',
            optTargetRatePercent:0,
            impressionThreshold:75,
            conversionThreshold:3,
            lookBackDays:30,
            viewConversionPercent:0,
            clickConversionPercent:0,
            maxBidMultiplier:1,
            targetCpaCents:0,
            bidAdjConvrThresh:7,
            bidToAvgCpmThresh:3,
            currentCpaBidPercent:50,
            targetCpaBidPercent:50,
            targetMinIbvLookback:15,
            targetMaxIbvLookback:4320,
            targetIbvMargin:50,
            topPlacementLastNHour:0,
            topPlacementImpressionThresh:0,
            topPlacementsLimit:0,
            bidResponseThresh:1000,
            placementOptLastNHour:0,
            fillRateThresh:0.01,
            opportunityThresh:0,
            clickCpa : 0,
            viewCpa:0
        };
        return adgroupBidOpt;
    });

