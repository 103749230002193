'use strict';

angular.module('adelementAdminApp').controller('RoleMappingDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'RoleMapping',
        function($scope, $stateParams, $uibModalInstance, entity, RoleMapping) {

        $scope.roleMapping = entity;
        $scope.load = function(id) {
            RoleMapping.get({id : id}, function(result) {
                $scope.roleMapping = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('adelementAdminApp:roleMappingUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.roleMapping.id != null) {
                RoleMapping.update($scope.roleMapping, onSaveSuccess, onSaveError);
            } else {
                RoleMapping.save($scope.roleMapping, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);
