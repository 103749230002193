/**
 * Created by maitray on 29/3/17.
 */

angular.module('adelementAdminApp')
    .controller('FeedManagerController', function ($scope,$state,$stateParams,$interval,$timeout,$http,FeedService,$uibModal,GetAdvertiserIDName){
        Array.prototype.mergeDeDup = function(/* variable number of arrays */){
            for(var i = 0; i < arguments.length; i++){
                var array = arguments[i];
                for(var j = 0; j < array.length; j++){
                    if(this.indexOf(array[j]) === -1) {
                        this.push(array[j]);
                    }
                }
            }
            return this;
        };
        $scope.stateDecide = false;
        var feedId;
        var advertiserId;
        var feedJsonBool = false;
        $scope.feedData = {};
        $scope.feedData.delimeter = ',';
        $scope.feedFormats = ["xml","csv","xlsx","xls"];
        $scope.feedProtocols = ["http","https","sftp","ftp","s3"];
        $scope.feedSchedule = ["DAILY","WEEKLY","MONTHLY"];
        $scope.days = {weekly:range(1,7),monthly:range(1,30)};
        $scope.hours = range(1,23);
        $scope.compressions = ["none","gz","tar.gz","7z","zip","bz"];
        $scope.error = "";
        $scope.errorFlag = false;
        $scope.downloadProgressShow = false;
        $scope.customFeedProgress = false;
        $scope.feedAllDetails = {};
        $scope.feed = {};
        $scope.feedProcessing = {};
        $scope.csvDownloadSample = [];
        $scope.csvDownloadSampleDate = "";
        $scope.csvAlterSample = [];
        $scope.csvAlterSampleDate = "";
        $scope.selected = '';
        $scope.noResults = false;
        $scope.feedCustom = [{fun:"expression",col:"",cols:[],args:[""]}];
        $scope.feedCriteria = [{op:"expression",col:[],vals:[""]}];
        $scope.errFlags = {custom:[{err:false}],filter:[{err:false}],mapping:[{err:false}],product:[{err:false}],hierarchy:[{err:false}]};
        $scope.errMsg = {custom:[{msg:""}],filter:[{msg:""}],mapping:[{msg:""}],product:[{msg:""}],hierarchy:[{msg:""}]};
        $scope.customFunctionsType = ["in-built","custom"];
        $scope.feedFunctions = [];
        $scope.feedParsing = {};
        $scope.fields = [];
        $scope.fieldsCumulative = [];
        $scope.tabs = [];
        $scope.functionDefinitions = {CONCATENATE:[ { arg_no: "0", arg_type: "lit", name:"str1", plural: false }, { arg_no: "1", arg_type: "col", name:"str2",plural: false } ]};
        var stop = {};
        $scope.containersAvailable = [];
        $scope.containersParamsAvailable = {};
        $scope.mappings = [{feedColumn:"",tagColumn:""}];
        $scope.tagMappings = [{feedColumn:"",tagColumn:""}];
        $scope.errTagFlags = {tagmapping:[{err:false}]};
        $scope.errTagMsg = {tagmapping:[{msg:""}]};
        $scope.mappingsDict = {};
        $scope.mappingsDictReverse = {};
        $scope.productDefinition = [];
        $scope.productDefinitionList = [];
        $scope.hierarchy = [{selected:[]}];
        $scope.columnsLocked = {};
        $scope.filterCounts = [];
        $scope.total_in = 0;
        $scope.total_out = 0;
        $scope.duplicate = 0;
        $scope.filterFunctionCounts = {};
        $scope.containerData = [];
        $scope.isDisabled = false;
        $scope.feedColumnNameError = false;
        $scope.notNullTagColNames = [];
        function range(start, end) {
            var foo = [];
            for (var i = start; i <= end; i++) {
                foo.push(i.toString());
            }
            return foo;
        }

        function initController(){

        }

        function initServices(){
            if($scope.stateDecide){
                FeedService.getFeed(advertiserId,feedId).then(function(feeddata){
                    $scope.feedAllDetails = angular.copy(feeddata);
                    $scope.feedData = $scope.feedAllDetails.feedAccess;
                    if($scope.feedData.feedUrl.indexOf($scope.feedData.feedAccessProtocol) != -1) {
                        $scope.feedData.feedUrl = $scope.feedData.feedUrl.replace($scope.feedData.feedAccessProtocol+"://","");
                    }
                    $scope.feed = $scope.feedAllDetails.feed;
                    $scope.feedData.name = $scope.feed.feedName;
                    $scope.feedData.type = $scope.feed.feedType;
                    $scope.feedFunctions = $scope.feedAllDetails.feedFunctions;
                    $scope.feedProcessing = $scope.feedAllDetails.feedProcessing;
                    $scope.feedData.hour = $scope.feedData.hour.toString();
                    $scope.feedData.day = $scope.feedData.day.toString();
                    $scope.feedParsing = $scope.feedAllDetails.feedParsing;
                    $scope.containersAvailable = $scope.feedAllDetails.containers;
                    $scope.containersParamsAvailable = $scope.feedAllDetails.containersParams;
                    $scope.filterCounts = $scope.feedAllDetails.feedFilterStats;
                    if($scope.filterCounts!=null){
                        initFilterCounts()
                    }
                    // if($scope.feedParsing.containerHash!=undefined && $scope.feedParsing.containerHash!="" && $scope.feedParsing.containerHash!=null){
                    //
                    // }
                    if($scope.feedParsing != null){
                        initFeedParsing();
                    }

                    $scope.tabs = ["Mathematics","string","date","logical"];
                    $scope.functions = [{Mathematics:["+","-","*","/","(",")","=","<",">","[","]"]},{string:[]},{date:[]},{logical:[]}];

                    for(var i=0;i<$scope.feedFunctions.length;i++){
                        var func = $scope.feedFunctions[i];
                        var type = func.functionType;
                        var functionname = func.functionName;
                        var args = func.inputs;
                        for(var j=0;j<$scope.functions.length;j++){
                            if($scope.functions[j].hasOwnProperty(type)){
                                $scope.functions[j][type].push(functionname);
                            }
                            else{
                                $scope.functions[j][type] = [];
                                $scope.functions[j][type].push(functionname);
                            }
                            console.log(args);
                            $scope.functionDefinitions[functionname] = eval(args);
                            console.log($scope.functionDefinitions[functionname]);
                        }
                    }


                    for(var i=0;i<$scope.feedCustom.length;i++){
                        $scope.errFlags.custom.push({err:false});
                        $scope.errMsg.custom.push({msg:""});
                    }
                    for(var i=0;i<$scope.feedCriteria.length;i++){
                        $scope.errFlags.filter.push({err:false});
                        $scope.errMsg.filter.push({msg:""});
                    }

                    if($scope.feedProcessing.status!= "success" && $scope.feedProcessing.status != "failed") {
                        $scope.downloadProgressShow = true;
                        triggerPolling("download");
                        if(feedJsonBool)
                        {
                            triggerPolling("feedprocess");
                        }
                    }
                    else {
                        getSampleDownloadData("download");
                        if(feedJsonBool)
                        {
                            getSampleDownloadData("feedprocess");
                        }
                    }

                    if($scope.feedData.type=="LOG_TYPE"){
                        //added by Snehal
                        $scope.feedDataLogExtraction = $scope.feedAllDetails.feedLogExtraction;
                        $scope.feedData.feedUrlS3 = $scope.feedDataLogExtraction.feedOutputS3path;
                        $scope.feedData.days = $scope.feedDataLogExtraction.days;
                        $scope.feedData.uniqueId = $scope.feedDataLogExtraction.uniqueId;
                        $scope.feedColumns = $scope.feedDataLogExtraction.feedColumns.split(',');
                        var feedFieldsExists = $scope.feedColumns.indexOf("depth");
                        if(feedFieldsExists>=0){
                            $scope.feedColumns.splice(0, 2);
                        }
                        $scope.tagColumns = $scope.feedDataLogExtraction.tagColumns.split(',');
                        var tagFieldsExists = $scope.tagColumns.indexOf("depth");
                        if(tagFieldsExists>=0){
                            $scope.tagColumns.splice(0,2);
                        }
                        $scope.feedData.hash = $scope.feedDataLogExtraction.containerHash;
                        $scope.feedData.uniqueCols = $scope.feedDataLogExtraction.uniqueId;
                        var uniqueColumns = $scope.feedData.uniqueCols.split(',')

                        $scope.containerParams = $scope.containersParamsAvailable[$scope.feedDataLogExtraction.containerHash];

                        $scope.feedData.uniqueCols = []
                        var tagColList = $scope.tagColumnsList;

                        $scope.cookieFeedMapping = $scope.feedAllDetails.cookieFeedMapping;
                        var i=0
                        $scope.feedData.tagColumnName = [];
                        $scope.feedData.feedColumnMapping = [];
                        $scope.feedData.notnullColumn = []
                        $scope.tagMappings = [{feedColumn:"",tagColumn:""}];
                        for(var j=0;j<$scope.feedColumns.length-1;j++){
                            $scope.tagMappings.push({feedColumn:"",tagColumn:""});
                            $scope.errTagFlags.tagmapping.push({err:false});
                            $scope.errTagMsg.tagmapping.push({msg:""});
                        }
                        $scope.feedColumns.forEach(function(data){
                            $scope.feedData.tagColumnName[i] = $scope.tagColumns[i];
                            $scope.feedData.feedColumnMapping[i] = data;
                            if(uniqueColumns.length>0){
                                for(var l=0;l<uniqueColumns.length;l++){
                                    if($scope.tagColumns[i] == uniqueColumns[l])
                                        $scope.feedData.uniqueCols.push(uniqueColumns[l])
                                }
                            }
                            i+=1;
                        });
                        for(var k=0;k<$scope.cookieFeedMapping.length;k++){
                            var columnName = $scope.cookieFeedMapping[k].columnName;
                            var columnNotNull = $scope.cookieFeedMapping[k].notnull;
                            if($scope.feedData.tagColumnName.indexOf(columnName)>-1 && columnNotNull){
                                var colIndex = $scope.feedData.tagColumnName.indexOf(columnName);
                                $scope.feedData.notnullColumn[colIndex]=$scope.cookieFeedMapping[k].notnull;
                                $scope.notNullTagColNames.push(columnName);
                            }
                        }
                    }
                });
            }

            // added by Snehal
            FeedService.getTagFeed(advertiserId).then(function(response) {
                $scope.containerData = response['containers'];
                $scope.containerParamsData = response['containersParams'];
            });
            // get all the advertisers
            var advertiserInfoPromise =  GetAdvertiserIDName.query();
            advertiserInfoPromise.$promise.then(function(response){
                 $scope.allAdvertisersIdName = response;
                 $scope.allAdvertisersIdName.forEach(function(data){
                    if(data.id==advertiserId){
                        $scope.advertiserName = data.name;
                    }
                 });
            });
        }

        // added by Snehal
        $scope.getContainerParams = function(hash){
            var containerHash = hash;
            $scope.containerParams = $scope.containerParamsData[hash];
            $scope.feedData.tagColumnName = {};
            if($scope.containerParams){
                $scope.tagMappings = [];
                for(var i=0; i<$scope.containerParams.length; i++){
                    $scope.tagMappings.push({feedColumn:"",tagColumn:""});
                    $scope.feedData.tagColumnName[i] = $scope.containerParams[i];
                    $scope.errTagFlags.tagmapping.push({err:false});
                    $scope.errTagMsg.tagmapping.push({msg:""});
                }
            }
        }

        function initFilterCounts() {
            $scope.filterCounts.forEach(function(data){
                if(data.function == "total_in" || data.function == "total_out" || data.function == "duplicate"){
                    $scope[data.function] = data.count;
                }
                else if(data.function == "expression"){
                    $scope.filterFunctionCounts[data.functionIndex] = data.count;
                }
            });
        }

        function initFeedParsing() {
            checkProductDefinition();
            if($scope.feedParsing["feedAlterJson"]!="" && $scope.feedParsing["feedAlterJson"]!=null && $scope.feedParsing["feedAlterJson"]!=undefined){
                feedJsonBool = true;
                $scope.feedCustom = JSON.parse($scope.feedParsing["feedAlterJson"]);
            }
            if($scope.feedParsing["feedFilterJson"]!="" && $scope.feedParsing["feedFilterJson"]!=null && $scope.feedParsing["feedFilterJson"]!=undefined){
                feedJsonBool = true;
                $scope.feedCriteria = JSON.parse($scope.feedParsing["feedFilterJson"]);
            }
            $scope.fields = $scope.feedParsing["feedColumnsOutput"].split(",");
            $scope.fieldsCumulative =  $scope.feedParsing["feedColumnsOutput"].split(",").mergeDeDup($scope.feedParsing["feedColumnsInput"].split(","));
            checkFeedTagMappings();
        }

        function addOrRemoveMappingLock(columns) {
            if(columns != undefined) {
                $scope.columnsLocked = {};
                for (var i = 0; i < columns.length; i++) {
                    var column = columns[i];
                    $scope.columnsLocked[column] = true;
                }
            }
        }
        function initColumnsLock() {
            $scope.columnsLocked = {};
            $scope.productDefinition.forEach(function(data){
                $scope.columnsLocked[data] = true;
            });
            $scope.hierarchy.forEach(function(data){
                data.selected.forEach(function (col) {
                    $scope.columnsLocked[col] = true;
                });
            });
        }
        function checkProductDefinition() {
            if($scope.feedParsing["feedHierarchy"]!="" && $scope.feedParsing["feedHierarchy"]!=null && $scope.feedParsing["feedHierarchy"]!=undefined){
                $scope.productDefinition = $scope.feedParsing["feedHierarchy"].split(",")[0].trim().split(":");
                $scope.errFlags.hierarchy = [];
                $scope.errMsg.hierarchy = [];

                $scope.hierarchy = $scope.feedParsing["feedHierarchy"].split(",").map(function (columns) {
                    $scope.errFlags.hierarchy.push({err:false});
                    $scope.errMsg.hierarchy.push({msg:""});
                    return {selected:columns.split(":")};
                });
                initColumnsLock();
            }
            if($scope.feedParsing.containerHash!= undefined && $scope.feedParsing.containerHash!=null && $scope.feedParsing.containerHash!="") {
                if ($scope.productDefinition.length == 0) {
                    $scope.errFlags.product[0].err = true;
                    $scope.errMsg.product[0].msg = "product definition cannot be empty";
                }
                if (Object.keys($scope.mappingsDict).length == 0) {
                    $scope.errFlags.mapping[0].err = true;
                    $scope.errMsg.mapping[0].msg = "mapping required";
                }
            }
        }
        function checkFeedTagMappings() {
            if($scope.feedParsing.feedTagMapping != undefined && $scope.feedParsing.feedTagMapping != null && $scope.feedParsing.feedTagMapping != "") {
                var mappingsList = $scope.feedParsing.feedTagMapping.split(",");
                if(mappingsList.length>0) {
                    $scope.mappings = [];
                    $scope.errFlags.mapping = [];
                    $scope.errMsg.mapping = [];
                    for (var i = 0; i < mappingsList.length; i++) {
                        var splittedCols = mappingsList[i].split(":");
                        $scope.mappingsDict[splittedCols[0]] = splittedCols[1];
                        $scope.mappingsDictReverse[splittedCols[1]] = splittedCols[0];
                        $scope.mappings.push({tagColumn:splittedCols[0],feedColumn:splittedCols[1]});
                        $scope.errFlags.mapping.push({err:false});
                        $scope.errMsg.mapping.push({msg:""});
                    }
                }
                $scope.productDefinitionList = Object.values($scope.mappingsDict);
            }
        }

        function stateDecider(){
            if($stateParams.hasOwnProperty("feedId")){
                $scope.stateDecide = true;
                feedId = $stateParams.feedId;
                advertiserId = $stateParams.advertiserId;
            }
            else{
                $scope.stateDecide = false;
                advertiserId = $stateParams.advertiserId;
            }
        }

        $scope.checkValidFeedName = function () {
            FeedService.checkFeedName($scope.feedData.name).then(function (response) {
                $scope.errorFlag = response.error;
                $scope.error = "feed name already exists";
            });
        };

        $scope.setNotNullColumn = function(notnullCol,index,tagColName){
            var nncol = notnullCol;
            var i = index;
            var columnIndex = $scope.notNullTagColNames.indexOf(tagColName);
            if(columnIndex==-1 && notnullCol==true){
                $scope.notNullTagColNames.push(tagColName)
            }else if(columnIndex>-1 && notnullCol==false){
                $scope.notNullTagColNames.splice(columnIndex, 1);
            }
        }

        $scope.saveFeed = function(){
            if($scope.feedData.type=="LOG_TYPE"){
                if($scope.feedData.feedColumnMapping!=null){
                    // added by Snehal
                    $scope.containerData.forEach(function(data){
                       if(data.containerHash==$scope.feedData.hash){
                          var advHashName = data.name;
                          $scope.feedData.feedUrlS3 ="s3://ae.backup/"+$scope.feedData.name+"/";
                       }
                    });
                    $scope.feedTypeSelect($scope.feedData.type);
                    $scope.tagColumnNameError = false;
                    var feedColumns = $scope.feedData.feedColumnMapping;
                    var notnullCols = $scope.feedData.notnullColumn;
                    var feedColumnArr = [];
                    var tagColumnArr = [];

                    angular.forEach($scope.feedData.feedColumnMapping, function(value, key){
                        feedColumnArr.push(value);
                    });

                    angular.forEach($scope.feedData.tagColumnName, function(value, key){
                        tagColumnArr.push(value);
                    });

                    feedColumnArr.unshift("depth");
                    feedColumnArr.unshift("aert");

                    tagColumnArr.unshift("depth");
                    tagColumnArr.unshift("ae_rt");

                    $scope.feedData.feedColumnMapping = feedColumnArr;
                    $scope.feedData.tagColumnName = tagColumnArr;
                    $scope.feedData.notNullTagColNames = $scope.notNullTagColNames;
//                    if(!$scope.stateDecide) {
//                        var feedColumnArr = [];
//                        var tagColumnArr = [];
//
//                        angular.forEach($scope.feedData.feedColumnMapping, function(value, key){
//                            feedColumnArr.push(value);
//                        });
//
//                        angular.forEach($scope.feedData.tagColumnName, function(value, key){
//                            tagColumnArr.push(value);
//                        });
//
//                        feedColumnArr.unshift("depth");
//                        feedColumnArr.unshift("aert");
//
//                        tagColumnArr.unshift("depth");
//                        tagColumnArr.unshift("ae_rt");
//
//                        $scope.feedData.feedColumnMapping = feedColumnArr;
//                        $scope.feedData.tagColumnName = tagColumnArr;
//                    }
//                    else{
//                        $scope.feedData.feedColumnMapping.unshift("depth");
//                        $scope.feedData.feedColumnMapping.unshift("aert");
//
//                        $scope.feedData.tagColumnName.unshift("depth");
//                        $scope.feedData.tagColumnName.unshift("ae_rt");
//                    }
                }
                else{
                    $scope.tagColumnNameError = true;
                }
            }

            $scope.errorFlag = false;
            FeedService.checkFeedName($scope.feedData.name).then(function (response) {
                if(!$scope.stateDecide){
                    $scope.errorFlag = response.error;
                }
                if($scope.errorFlag && !$scope.stateDecide){
                    $scope.error = "feed name already exists";
                }
                else {
                    if($scope.feedData.type == "ADELEMENT_TYPE" || $scope.feedData.type == "LOG_TYPE"){
                        var feedData = angular.copy($scope.feedData);
                        if(feedData.name == undefined || feedData.name == ""){
                            $scope.errorFlag = true;
                            $scope.error = "Please provide name of feed";
                        }
                        else if(feedData.feedFormat == undefined || feedData.feedFormat == ""){
                            $scope.errorFlag = true;
                            $scope.error = "Please provide format of feed";
                        }
                        else if(feedData.feedAccessProtocol == undefined || feedData.feedAccessProtocol == ""){
                            $scope.errorFlag = true;
                            $scope.error = "Please provide protocol of feed";
                        }
                        else if(feedData.feedUrl == undefined || feedData.feedUrl == ""){
                            $scope.errorFlag = true;
                            $scope.error = "Please provide url of feed";
                        }
                        else if(feedData.feedSchedule == undefined || feedData.feedSchedule == ""){
                            $scope.errorFlag = true;
                            $scope.error = "Please provide schedule of feed";
                        }
                        else if(feedData.hour == undefined || feedData.hour == ""){
                            $scope.errorFlag = true;
                            $scope.error = "Please provide hour of feed";
                        }
                        else if(feedData.feedSchedule == "MONTHLY" || feedData.feedSchedule == "WEEKLY"){
                            if(feedData.day == undefined || feedData.day == ""){
                                $scope.errorFlag = true;
                                $scope.error = "Please provide day of feed";
                            }
                        }

                        if(feedData.feedAccessProtocol == "sftp"){
                            if(feedData.feedCredentialsUsername == undefined || feedData.feedCredentialsUsername == ""){
                                $scope.errorFlag = true;
                                $scope.error = "Username required for sftp";
                            }
                            else if(feedData.feedCredentialsPassword == undefined || feedData.feedCredentialsPassword == ""){
                                $scope.errorFlag = true;
                                $scope.error = "Password required for sftp";
                            }
                        }

                        if(!$scope.errorFlag){
                            if(feedData.compression == undefined || feedData.compression == ""){
                                feedData.compression = "none";
                            }
                            feedData.feedUrl = feedData.feedAccessProtocol +"://"+feedData.feedUrl;
                            if(!$scope.stateDecide) {
                                FeedService.saveFeed(advertiserId, feedData).then(function (data) {
                                    $timeout(function () {
                                        $state.go('feed-edit', {advertiserId: advertiserId, feedId: data})
                                    }, 2000);
                                });
                            }
                            else {
                                FeedService.updateFeed(advertiserId,feedId,feedData).then(function (data) {
                                    initController();
                                    initServices();
                                })
                            }
                        }
                    }
                }
            });


        };

        function getSampleDownloadData(type){
            FeedService.getSampleData(advertiserId,feedId,type).then(function(sampleResponse) {
                if(sampleResponse.csvdata != null) {
                    if (type == "download" && sampleResponse.csvdata != null) {
                        $scope.csvDownloadSample = sampleResponse.csvdata.sample;
                        $scope.csvDownloadSampleDate = sampleResponse.csvdata.date;
                    }
                    else if(type == "feedprocess" && sampleResponse.csvdata != null){
                        $scope.csvAlterSample = sampleResponse.csvdata.sample;
                        $scope.csvAlterSampleDate = sampleResponse.csvdata.date;
                    }
                    else{
                        //null case
                        if(type="download"){
                            $scope.csvDownloadSample = [];
                            $scope.csvDownloadSampleDate = "";
                        }
                        else{
                            $scope.csvAlterSample = [];
                            $scope.csvAlterSampleDate = "";
                        }
                    }
                }

            });
        }

        function bringDataForFeedProcessing(type){
            FeedService.getFeedProcessingInfo(advertiserId,feedId,type).then(function(feeddata){
                if(type == "download") {
                    $scope.feedProcessing = feeddata.feedProcessing;
                    $scope.feedParsing = feeddata.feedParsing;
                    if($scope.feedProcessing != null && $scope.feedProcessing !=undefined) {
                        if ($scope.feedProcessing.status == "success" || $scope.feedProcessing.status == "failed") {
                            initFeedParsing();
                            $scope.downloadProgressShow = false;
                            stopFight(type);
                            getSampleDownloadData(type);
                        }
                    }
                }
                else if(type == "feedprocess"){
                    if(feeddata == "100"){
                        $scope.customFeedProgress = false;
                        stopFight(type);
                        getSampleDownloadData(type);
                        FeedService.downloadFeedToAePath(advertiserId,feedId);
                    }
                }
            });
        }


        function triggerPolling(type) {
            if (angular.isDefined(stop[type])) return;
            stop[type] = $interval(function () {
                bringDataForFeedProcessing(type);
            }, 10000);
        }
        var stopFight = function(type) {
            if (angular.isDefined(stop[type])) {
                $interval.cancel(stop[type]);
                stop[type] = undefined;
            }
        };


        $scope.$on('$destroy', function () {
            // Make sure that the interval is destroyed too
            stopFight("download");
            stopFight("feedprocess");
        });

        $scope.getMacros = function(searchVal){
            // return ['data','data1'];
            if(searchVal.indexOf("{") !== -1) {
                if(searchVal.indexOf("{") === searchVal.length-1){
                    searchVal = "";
                }
                else{
                    searchVal = searchVal.split("{")[1];
                }
                var url = "api/feed/";
                return $http.get(url + "macro/" + searchVal).then(function (response) {
                    return response.data;
                });
            }
        };

        $scope.onSelect = function(item, model, label, viewValue){
            console.log(item);
            console.log(model);
            console.log(label);
            alert(viewValue);
        };

        $scope.addCustomFeedColumn = function(){
            $scope.feedCustom.push({fun:"expression",col:"",cols:[],args:[""]});
            $scope.errFlags.custom.push({err:false});
            $scope.errMsg.custom.push({msg:""});
        };
        $scope.removeCustomFeedColumn = function(index){
            $scope.feedCustom.splice(index,1);
            $scope.errFlags.custom.push({err:false});
            $scope.errMsg.custom.push({msg:""});
        };

        $scope.addFilter = function(){
            $scope.feedCriteria.push({op:"expression",col:[],vals:[""]});
            $scope.errFlags.filter.push({err:false});
            $scope.errMsg.filter.push({msg:""});
        };
        $scope.removeFilter = function(index){
            $scope.feedCriteria.splice(index,1);
            $scope.errFlags.filter.splice(index,1);
            $scope.errMsg.filter.splice(index,1);
        };
        $scope.customlist = [];

        function iterate(obj,index) {
            if(obj.hasOwnProperty("left") || obj.hasOwnProperty("right")){
                if(obj.hasOwnProperty("left")) {
                    var lefttree = obj.left;
                    if (lefttree.hasOwnProperty("type")) {
                        if (lefttree.type == "Identifier") {
                            if ($scope.feedCustom[index].cols.indexOf(lefttree.name) == -1) {
                                $scope.feedCustom[index].cols.push(lefttree.name);
                            }
                        }
                        else {
                            iterate(lefttree, index);
                        }
                    }
                }
                if(obj.hasOwnProperty("right")) {
                    var righttree = obj.right;
                    if (righttree.hasOwnProperty("type")) {
                        if (righttree.type == "Identifier") {
                            if($scope.feedCustom[index].cols.indexOf(righttree.name) == -1) {
                                $scope.feedCustom[index].cols.push(righttree.name);
                            }
                        }
                        else {
                            iterate(righttree,index);
                        }
                    }
                }
            }
            else if(obj.hasOwnProperty("CallExpression")){
                var functioncall = obj.CallExpression;
                for(var i=0;i<functioncall.arguments.length;i++){
                    iterate(functioncall.arguments[i],index);
                }
            }
            else{
                return;
            }
        }

        $scope.evaluateFormula = function(index,insertCol){
            try{
                // alert(index);
                if($scope.feedCustom[index].args[0]!=undefined && $scope.feedCustom[index].args[0]!="") {
                    $scope.errFlags.custom[index].err = false;
                    $scope.errMsg.custom[index].msg = "";
                    var jsp = jsep($scope.feedCustom[index].args[0]);
                    console.log(JSON.stringify(jsp));
                    $scope.feedCustom[index].cols = [];
                    iterate(jsp,index);
                    if($scope.feedCustom[index].cols.length == 0){
                        var tentativecols = $scope.feedCustom[index].args[0].match(/\w+/g);
                        for(var i=0;i<tentativecols.length;i++){
                            if($scope.fieldsCumulative.indexOf(tentativecols[i].trim()) != -1) {
                                $scope.feedCustom[index].cols.push(tentativecols[i].trim());
                            }
                        }
                    }
                    console.log($scope.feedCustom[index].cols);
                    $scope.errFlags.custom[index].err = false;
                    $scope.errMsg.custom[index].msg = "";
                    if(insertCol) {
                        $scope.fields.push($scope.feedCustom[index].col);
                        $scope.fieldsCumulative.push($scope.feedCustom[index].col);
                    }
                }
                else{
                    $scope.errFlags.custom[index].err = false;
                    $scope.errMsg.custom[index].msg = "";
                }
            }
            catch(err){
                $scope.errFlags.custom[index].err = true;
                $scope.errMsg.custom[index].msg = err.message;
            }
        };

        $scope.evaluateFilterFormula = function(index){
            try{
                // alert(index);
                if($scope.feedCriteria[index].vals[0]!=undefined && $scope.feedCriteria[index].vals[0]!="") {
                    $scope.errFlags.filter[index].err = false;
                    $scope.errMsg.filter[index].msg = "";
                    var jsp = jsep($scope.feedCriteria[index].vals[0]);
                    console.log(JSON.stringify(jsp));
                    $scope.feedCriteria[index].col = [];
                    //iterate(jsp,index);
                    if($scope.feedCriteria[index].col.length == 0){
                        var tentativecols = $scope.feedCriteria[index].vals[0].match(/\w+/g);
                        for(var i=0;i<tentativecols.length;i++){
                            if($scope.fieldsCumulative.indexOf(tentativecols[i].trim()) != -1) {
                                $scope.feedCriteria[index].col.push(tentativecols[i].trim());
                            }
                        }
                    }
                    console.log($scope.feedCriteria[index].col);
                    $scope.errFlags.filter[index].err = false;
                    $scope.errMsg.filter[index].msg = "";
                }
                else{
                    $scope.errFlags.filter[index].err = false;
                    $scope.errMsg.filter[index].msg = "";
                }
            }
            catch(err){
                $scope.errFlags.filter[index].err = true;
                $scope.errMsg.filter[index].msg = err.message;
            }
        };

        $scope.saveCustomFields = function(){
            var checkTriggerForCustom = false;
            var columns = [];
            for(var i=0;i<$scope.feedCustom.length;i++) {
                $scope.evaluateFormula(i,true);
                if($scope.feedCustom[i].col == undefined || $scope.feedCustom[i].col == ""){
                    $scope.errFlags.custom[i].err = true;
                    $scope.errMsg.custom[i].msg = "new column name cannot be empty or null";
                }
                if($scope.feedCustom[i].fun == undefined || $scope.feedCustom[i].fun == ""){
                    $scope.errFlags.custom[i].err = true;
                    $scope.errMsg.custom[i].msg = "function name cannot be empty or null";
                }
                if ($scope.errFlags.custom[i].err) {
                    checkTriggerForCustom = true;
                }
                columns.push($scope.feedCustom[i].col);
            }
            if(!checkTriggerForCustom) {
                console.log("++++++++++++++++++++++++++++++");
                var customFieldsData = {customFields:JSON.stringify($scope.feedCustom),newColumns:columns.join()};
                console.log(customFieldsData);
                FeedService.setCustomFields(advertiserId,feedId,customFieldsData).then(function(response) {
                    console.log("++++++++++++++++++++++++++++++");
                    console.log(response);
                    $scope.feedCustom = JSON.parse(response.customColumns.feedAlterJson);
                    $scope.customFeedProgress = true;
                    triggerPolling("feedprocess");
                });
            }
        };

        $scope.saveFeedFilter = function(){
            var checkTriggerForFilters = false;
            for(var i=0;i<$scope.feedCriteria.length;i++) {
                $scope.evaluateFilterFormula(i);
                // if($scope.feedCriteria[i].col == undefined || $scope.feedCriteria[i].col == ""){
                //     $scope.errFlags.filter[i].err = true;
                //     $scope.errMsg.filter[i].msg = "new column name cannot be empty or null";
                // }
                if($scope.feedCriteria[i].vals[0] == undefined || $scope.feedCriteria[i].vals[0] == ""){
                    $scope.errFlags.filter[i].err = true;
                    $scope.errMsg.filter[i].msg = "function name cannot be empty or null";
                }
                if ($scope.errFlags.filter[i].err) {
                    checkTriggerForFilters = true;
                }
            }
            if(!checkTriggerForFilters) {
                console.log("++++++++++++++++++++++++++++++");
                var filterData = {filterJson:JSON.stringify($scope.feedCriteria)};
                console.log(filterData);
                FeedService.setFilterFields(advertiserId,feedId,filterData).then(function(response) {
                    console.log("++++++++++++++++++++++++++++++");
                    console.log(response);
                    $scope.feedCriteria = JSON.parse(response.feedFilter.feedFilterJson);
                    $scope.customFeedProgress = true;
                    triggerPolling("feedprocess");
                });
            }
        };

        $scope.linkContainerHash = function() {
            var hash = $scope.feedParsing.containerHash;
            if(hash !=undefined && hash!=""){
                var hashObj = {containerHash:hash};
                FeedService.linkContainerHashToFeed(advertiserId,feedId,hashObj);
                if($scope.productDefinition.length==0) {
                    $scope.errFlags.product[0].err = true;
                    $scope.errMsg.product[0].msg = "product definition cannot be empty";
                }
                if(Object.keys($scope.mappingsDict).length == 0){
                    $scope.errFlags.mapping[0].err = true;
                    $scope.errMsg.mapping[0].msg = "mapping required";
                }
            }
        };

        $scope.addMappings = function () {
            var validMappings = validateMappings();

            if(validMappings)
            {
                //save data;
                $scope.mappings.push({feedColumn:"",tagColumn:""});
                $scope.errFlags.mapping.push({err:false});
                $scope.errMsg.mapping.push({msg:""});
            }
        };
        $scope.removeMappings = function(index) {
            var mappingToDel = $scope.mappings.splice(index,1);
            delete $scope.mappingsDict[mappingToDel[0].tagColumn];
            $scope.errFlags.mapping.splice(index,1);
            $scope.errMsg.mapping.splice(index,1);
            var validMappings = validateMappings();

            if(validMappings)
            {
                //save all mappings data
                saveAllMappings();
            }
        };

        $scope.saveMappings = function(index) {
            if ($scope.mappings[index].feedColumn != undefined && $scope.mappings[index].tagColumn != undefined && $scope.mappings[index].feedColumn != "" && $scope.mappings[index].tagColumn != "") {
                if($scope.mappingsDictReverse.hasOwnProperty($scope.mappings[index].feedColumn) && $scope.mappingsDictReverse[$scope.mappings[index].feedColumn]!=$scope.mappings[index].tagColumn) {
                    var deleteTagColumn = $scope.mappingsDictReverse[$scope.mappings[index].feedColumn];
                    delete $scope.mappingsDict[deleteTagColumn];
                }
                $scope.mappingsDict[$scope.mappings[index].tagColumn]=$scope.mappings[index].feedColumn;
                var mappingDataToSave = {feedTagMapping:Object.keys($scope.mappingsDict).map(function(key){ return key+":"+$scope.mappingsDict[key] }).join(",")};
                FeedService.saveFeedTagMapping(advertiserId,feedId,mappingDataToSave).then(function (response) {
                    console.log(mappingDataToSave);
                    $scope.feedParsing.feedTagMapping = response.feedTagMapping;
                    checkFeedTagMappings();
                });
             }
            else{
                $scope.errFlags.mapping[index].err = true;
                $scope.errMsg.mapping[index].msg = "mappings cannot be empty";
            }
        };

        function saveAllMappings() {
            for(var index=0;index<$scope.mappings.length;index++){
                if ($scope.mappings[index].feedColumn != undefined && $scope.mappings[index].tagColumn != undefined && $scope.mappings[index].feedColumn != "" && $scope.mappings[index].tagColumn != "") {
                    $scope.mappingsDict[$scope.mappings[index].tagColumn]=$scope.mappings[index].feedColumn;
                }
            }
            var mappingDataToSave = {feedTagMapping:Object.keys($scope.mappingsDict).map(function(key){ return key+":"+$scope.mappingsDict[key] }).join(",")};
            FeedService.saveFeedTagMapping(advertiserId,feedId,mappingDataToSave).then(function (response) {
                console.log(mappingDataToSave);
                $scope.feedParsing.feedTagMapping = response.feedTagMapping;
                checkFeedTagMappings();
            });

        }

        function validateMappings() {
            var validMappings = true;
            for(var i=0;i<$scope.mappings.length;i++) {
                var mappingObj = $scope.mappings[i];
                var feedColumn = mappingObj.feedColumn;
                var tagColumn = mappingObj.tagColumn;
                if(feedColumn==undefined || tagColumn==undefined || feedColumn=="" || tagColumn=="") {
                    validMappings = false;
                    $scope.errFlags.mapping[i].err = true;
                    $scope.errMsg.mapping[i].msg = "mappings cannot be empty";
                }
                else {
                    $scope.errFlags.mapping[i].err = false;
                    $scope.errMsg.mapping[i].msg = "";
                }
            }
            return validMappings;
        }
        $scope.saveProductDefinition = function (columns) {
            // alert();
            console.log(columns,"<<<<<<<<<<<<<<<<<");
            if(columns != undefined && columns!=null && columns!=""){
                $scope.errFlags.product[0].err = false;
                $scope.errMsg.product[0].msg = "";
                addOrRemoveMappingLock(columns);
                var prodObj = {product:columns.join(":"),mappingProduct:columns.map(function(key){ return $scope.mappingsDictReverse[key] }).join(":")};
                console.log(prodObj,"----------------------------");
                FeedService.saveProductDefinition(advertiserId,feedId,prodObj).then(function (response) {
                    $scope.feedParsing.feedHierarchy = response.feedHierarchy;
                    checkProductDefinition();
                });
            }
            else{
                $scope.errFlags.product[0].err = true;
                $scope.errMsg.product[0].msg = "product definition cannot be empty";
                //alert($scope.productDefinition.join());
            }
        };

        function validateHierarchySelection(){
            var columnValidCheck = true;
            // if(type == "remove"){
            //     var productid = $scope.productDefinition.join(":");
            //     if(productid != columns[0]) {
            //         $scope.errFlags.hierarchy[0].err = true;
            //         $scope.errMsg.hierarchy[0].msg = "cannot remove 1st column";
            //         columns.unshift(productid);
            //         return false;
            //     }
            // }
            for(var i=1;i<$scope.hierarchy.length;i++) {
                $scope.errFlags.hierarchy[i].err = false;
                $scope.errMsg.hierarchy[i].msg = "";
                var column = $scope.hierarchy[i];
                // alert(column.length+"--- index of --- "+i);
                if(column.selected.length == 0) {
                    console.log($scope.hierarchy);
                    columnValidCheck = false;
                    $scope.errFlags.hierarchy[i].err = true;
                    $scope.errMsg.hierarchy[i].msg = "fields cannot be empty";
                }
            }
            return columnValidCheck;
        }

        $scope.selectHierarchy = function (col,type,index) {
            var selection = validateHierarchySelection();
            if(selection){
                var hierarchyObj = {
                    hierarchy:$scope.hierarchy.map(function(columns){return columns.selected.join(":")}).join(","),
                    mappingHierarchy:$scope.hierarchy.map(function(columns){return columns.selected.map(function(column){
                        return $scope.mappingsDictReverse[column]
                    }).join(":")
                }).join(",")
                };
                console.log("data---------->hierarchy",hierarchyObj);
                addOrRemoveMappingLock(col);
                FeedService.saveHierarchy(advertiserId,feedId,hierarchyObj).then(function (response) {
                    $scope.feedParsing.feedHierarchy = response.feedHierarchy;
                    checkProductDefinition();
                });
            }
        };

        $scope.addHierarchy = function () {
            if(validateHierarchySelection()){
                $scope.hierarchy.push({selected:[]});
                $scope.errFlags.hierarchy.push({err:false});
                $scope.errMsg.hierarchy.push({msg:""});
            }
        };
        $scope.removeHierarchy = function (index) {
            $scope.hierarchy.splice(index,1);
            $scope.errFlags.hierarchy.splice(index,1);
            $scope.errMsg.hierarchy.splice(index,1);
            $scope.selectHierarchy();
            initColumnsLock();
            // validateHierarchySelection
        };

        $scope.saveAllFeedData = function() {
            var saveDataFlag = true;
            Object.keys($scope.errFlags).forEach(function (section) {
                var sectionErrData = $scope.errFlags[section];
                sectionErrData.forEach(function (errorObj) {
                    if(errorObj.err){
                        saveDataFlag = false;
                    }
                })
            });
            if(saveDataFlag){
                FeedService.finalizeFeed(advertiserId,feedId).then(function (response) {

                });
            }
        };

        $scope.showTopProducts = function () {
            $uibModal.open({
                templateUrl: 'app/feed-manager/top-products.html',
                controller: 'TopProductsController',
                size: 'lg-90',
                resolve: {
                    FeedData: function () {
                        return {
                            advertiserId:advertiserId,
                            feedId:feedId,
                            active:1
                        };
                    }
                }
            }).result.then(function(result) {
                //$state.go('roleMapping', null, { reload: true });
            }, function() {
                //$state.go('roleMapping');
            })
        };

        // added by Snehal
        function validateTagMappings() {
            var validMappings = true;
            for(var i=0;i<$scope.tagMappings.length;i++) {
                var mappingObj = $scope.tagMappings[i];
                if($scope.feedData.tagColumnName){
                    var tagColumn = $scope.feedData.tagColumnName[i];
                }
                if($scope.feedData.feedColumnMapping){
                    var feedColumn = $scope.feedData.feedColumnMapping[i];
                }

                if(feedColumn==undefined || tagColumn==undefined || feedColumn=="" || tagColumn=="") {
                    validMappings = false;
                    if($scope.errTagFlags.tagmapping[i]){
                        $scope.errTagFlags.tagmapping[i].err = true;
                    }
                    if($scope.errTagMsg.tagmapping[i]){
                        $scope.errTagMsg.tagmapping[i].msg = "mappings cannot be empty";
                    }

                }
                else {
                    if($scope.errTagFlags.tagmapping[i]){
                        $scope.errTagFlags.tagmapping[i].err = false;
                    }
                    if($scope.errTagMsg.tagmapping[i]){
                        $scope.errTagMsg.tagmapping[i].msg = "";
                    }
                }
            }
            return validMappings;
        }

        function saveAllTagMappings() {
            for(var index=0;index<$scope.tagMappings.length;index++){
                if ($scope.tagMappings[index].feedColumn != undefined && $scope.tagMappings[index].tagColumn != undefined && $scope.tagMappings[index].feedColumn != "" && $scope.tagMappings[index].tagColumn != "") {
                    $scope.mappingsDict[$scope.tagMappings[index].tagColumn]=$scope.tagMappings[index].feedColumn;
                }
            }
            var mappingDataToSave = {feedTagMapping:Object.keys($scope.mappingsDict).map(function(key){ return key+":"+$scope.mappingsDict[key] }).join(",")};
            FeedService.saveFeedTagMapping(advertiserId,feedId,mappingDataToSave).then(function (response) {
//                console.log(mappingDataToSave);
                $scope.feedParsing.feedTagMapping = response.feedTagMapping;
                checkFeedTagMappings();
            });

        }

        $scope.addTagMappings = function () {
            var validMappings = validateTagMappings();

            if(validMappings)
            {
                //save data;
                $scope.tagMappings.push({feedColumn:"",tagColumn:""});
                $scope.errFlags.mapping.push({err:false});
                $scope.errMsg.mapping.push({msg:""});
            }
        };
        $scope.removeTagMappings = function(index) {
            var mappingToDel = $scope.tagMappings.splice(index,1);
//            $scope.feedData.tagColumnName.splice(index, 1);
//            $scope.feedData.feedColumnMapping.splice(index, 1);


            if($scope.feedData.tagColumnName){
                var tagColumnDeleted = $scope.feedData.tagColumnName[index];
                delete $scope.feedData.tagColumnName[index];
//                $scope.feedData.tagColumnName[index] = index+1
                var tagColsName = $scope.feedData.tagColumnName;
                var keyCount = 0;
                $scope.feedData.tagColumnName = {};
                for (var key in tagColsName) {
                    if (tagColsName.hasOwnProperty(key)) {
//                        console.log(key + " -> " + tagColsName[key]);
                        $scope.feedData.tagColumnName[keyCount] =  tagColsName[key];
                        keyCount+=1;
                    }
                }
            }
            if($scope.feedData.feedColumnMapping){
                var feedColumnDeleted = $scope.feedData.feedColumnMapping[index];
                delete $scope.feedData.feedColumnMapping[index];
                var feedColsName = $scope.feedData.feedColumnMapping;
                var keyCount = 0;
                $scope.feedData.feedColumnMapping = {};
                for (var key in feedColsName) {
                    if (feedColsName.hasOwnProperty(key)) {
//                        console.log(key + " -> " + feedColsName[key]);
                        $scope.feedData.feedColumnMapping[keyCount] =  feedColsName[key];
                        keyCount+=1;
                    }
                }
            }
            if($scope.feedData.notnullColumn){
//                delete $scope.feedData.notnullColumn[index];
                $scope.feedData.notnullColumn.splice(index,1);
            }
            $scope.errTagFlags.tagmapping.splice(index,1);
            $scope.errTagMsg.tagmapping.splice(index,1);
//            var validMappings = validateTagMappings();
            tagColumnSizeLength = Object.keys($scope.feedData.tagColumnName).length

            var tagColumns = {};
            var feedColuns = {};
            for(var i=0; i<$scope.containerParams.length; i++){
                Object.keys($scope.feedData.tagColumnName).forEach(function(key) {
                    if($scope.containerParams[i]==$scope.feedData.tagColumnName[key]){
//                        console.log(key, $scope.feedData.tagColumnName[key]);
                        if($scope.containerParams[i]!=tagColumnDeleted){
                            tagColumns[i]=$scope.containerParams[i];
                        }
                    }
//                    if(feedColumnDeleted){
//                        feedColuns[i]= $scope.feedColumnMapping[key];
//                    }
                });
            }
//            console.log("tagColumns ="+tagColumns);
//            console.log("feedColuns ="+feedColuns);

        };

        $scope.feedTypeSelect = function(type){
            if(type=="LOG_TYPE" || $scope.feedData.typeSelect=="LOG_TYPE"){
                $scope.feedData.typeSelect = type;
                $scope.feedData.feedFormat = $scope.feedFormats[1];
                $scope.feedData.delimiter = ",";
                $scope.feedData.feedAccessProtocol = $scope.feedProtocols[4];
                if($scope.feedData.feedUrlS3){
//                    $scope.feedData.feedUrl = angular.copy($scope.feedData.feedUrlS3);
                    var feedUrl = angular.copy($scope.feedData.feedUrlS3.substr(5));
                    var todayDate = new Date().toISOString().slice(0,10);
                    $scope.feedData.feedUrl = feedUrl+$scope.feedData.name+"_{%Y-%m-%d}.csv.7z";
                }
                $scope.feedData.compression = $scope.compressions[3];
            }
            else{
                $scope.feedData.typeSelect = type;
            }
        }

//        $scope.$watch('feedData.tagColumnName',function ( newValue, oldValue ) {
//            if(newValue && newValue.hasOwnProperty("id")){
//                dataTableInstance.setDataTable($scope.dtInstance);
//            }
//        });

        $scope.$watchCollection('feedData.tagColumnName', function () {
          $scope.tagColumnsList = [];
          angular.forEach($scope.feedData.tagColumnName, function (value, key) {
            if (value) {
              $scope.tagColumnsList.push(value);
            }
          });
            if($scope.feedData.hash){
//              for(var i=0;i<$scope.feedData.tagColumnName.length;i++){
              var count = 0
//              for(var i=0;i<$scope.tagColumnsList.length;i++){
//              $scope.feedData.tagColumnName.forEach(function(data){
//
//                if($scope.feedData.tagColumnName[count]==null){
//                    $scope.feedColumnNameError=true;
//                }
//                count+=1;
//              });
//              if(!$scope.feedColumnNameError){
//                $scope.isDisabled = false;
//              }
            }
        });


        $scope.feedColumnsMapping = function(feedCol){
            var validMappings = validateTagMappings();
        }

        stateDecider();
        initController();
        initServices();

    }).controller('FeedManagerControllerTest', function ($scope,$state,$stateParams,$interval,$timeout,$http,FeedService){
    $scope.form={cities:["asdfsf","sdfsf","yuyuy"]};
//    $scope.form.cities = ["asdfsf","sdfsf","yuyuy"];

    }).controller('TopProductsController', function ($scope,$state,$stateParams,$interval,$timeout,$http,FeedService,FeedData){
    $scope.csvDownloadSample = {date:"",data:[]};
    $scope.csvDownloadSampleDefaults = {date:"",data:[]};
    $scope.csvDownloadMissing = {date:"",data:[]};
    $scope.active = FeedData.active;

    $scope.tabs = [
        { title:'Top Products', content:$scope.csvDownloadSample, type: "topproducts", variable: "csvDownloadSample"},
        { title:'Default Products', content:$scope.csvDownloadSampleDefaults, type: "defaults", variable: "csvDownloadSampleDefaults"},
        { title:'Missing Products', content:$scope.csvDownloadMissing, type: "missing", variable: "csvDownloadMissing"}
    ];

    $scope.getCsvData = function (type,variable) {
        if($scope[variable].data.length == 0) {
            FeedService.getSampleData(FeedData.advertiserId, FeedData.feedId, type).then(function (sampleResponse) {
                if (sampleResponse.csvdata != null) {
                    $scope[variable].data = sampleResponse.csvdata.sample;
                }
                if (sampleResponse.hasOwnProperty("date")) {
                    $scope[variable].date = sampleResponse.csvdata.date;
                }
            });
        }
    };
});

angular
    .module('adelementAdminApp')
    .directive('functionCalculator',['$timeout','$document', function ($timeout,$document) {
        return {
                require: 'ngModel',
                scope: {
                    fields: '=fields',
                    ngModel: '=',
                    tabs: '=tabs',
                    functions: '=functions',
                    defs :'=defs',
                    errorFlag:'=errorFlag',
                    errorMsg:'=errorMsg',
                    filterTab:'=filter'

                },
                restrict: 'EA',
                template:'<div class="custom-cal" ng-blur="dialogFocus=false">' +
                '<div> ' +
                '<input type="text" class="form-control functions syntax" ng-model="syntax">'+
                '<input type="text" class="functions form-control funcname" ng-focus="dialogFocus=true" ng-model="ngModel" ng-change="updateModel(ngModel)">'+
                // '<span class="tag label label-info">{{ngModel}}<span data-role="remove"></span></span> ' +
                // '<span class="tag label label-info">Sydney<span data-role="remove"></span></span> ' +
                '</div> ' +
                '<div class="row" ng-show="dialogFocus">' +
                    '<div class="col-sm-12 col-xs-12 alert-danger" ng-if="errorFlag">{{errorMsg}}'+
                    '</div>'+
                '<div class="col-md-12">' +
                ' <div class="cc-wrapper"> <div class="row">' +
                ' <div class="col-md-5 cc-fields-height"> <ul class="cc-tab-nav">' +
                ' <li>Field Name</li> </ul> <ul class="adv-list adv-list-compact"> ' +
                '<li ng-repeat="field in fields track by $index" ng-click="addField($index)">{{field}} <a href=""><span>+</span></a></li> ' +
                '</ul> </div> <div class="col-md-7"> ' +
                '<ul class="cc-tab-nav"> ' +
                '<li ng-class="selectedIndex == $index?\'cc-tab-nav-active\':\'\'" ng-repeat="tab in tabs | filter: filterTab" ng-click="tabSelect($index)">{{tab}}</li> ' +
                '</ul> ' +
                '<div class="cc-tab-content"> ' +
                '<ul class="cc-tab-btn"> ' +
                '<li ng-repeat="func in functions[selectedIndex][selectedTab] track by $index" ng-click="addFunctions(selectedIndex,selectedTab,$index)">{{func}}</li> ' +
                '</ul> ' +
                '</div> ' +
                '</div> ' +
                '</div> ' +
                '</div> ' +
                '</div>'+
                '</div> ' +
                '</div> ',
                link: function(scope, element, attrs, ngModel){
                    scope.syntax = "";
                    scope.selectedIndex = 0;
                    scope.selectedTab = "Mathematics";
                    scope.dialogFocus = false;
                    if(scope.filterTab) {
                        scope.selectedTab = angular.copy(scope.filterTab);
                        console.log("this is functions {} {}",scope.functions);
                    }
                    scope.updateModel = function(item)
                    {
                        // alert("hi");
                        scope.syntax = "";
                        ngModel.$setViewValue(item);
                    };

                    element.on('itemAdded itemRemoved', function(){
                               // check if view value is not empty and is a string
                               // and update the view from string to an array of tags
                               if(ngModel.$viewValue && ngModel.$viewValue.split) {
                                 ngModel.$setViewValue( ngModel.$viewValue.split(',') );
                                 ngModel.$render();
                               }
                    });
                    scope.tabSelect = function(index){
                        scope.selectedIndex = index;
                        scope.selectedTab = scope.tabs[index];
                    };
                    scope.addField = function (index) {
                        scope.ngModel = scope.ngModel + ""+ scope.fields[index];
                        scope.syntax = "";
                        ngModel.$setViewValue(scope.ngModel);
                    };
                    scope.addFunctions = function(selectedIndex, selectedTab, index) {
                        var func = scope.functions[selectedIndex][selectedTab][index];
                        if(scope.defs.hasOwnProperty(func)){
                            var argname = [];
                            var definition = scope.defs[func];
                            for(var i=0;i<definition.length;i++){
                                argname.push(definition[i].name);
                            }
                            if(!scope.ngModel) {
                                scope.ngModel = "";
                            }
                            scope.ngModel = scope.ngModel+""+func+"(";
                            ngModel.$setViewValue(scope.ngModel);
                            scope.syntax = scope.ngModel+argname.join()+")";
                        }
                        else{
                            if(!scope.ngModel) {
                                scope.ngModel = "";
                            }
                            scope.ngModel = scope.ngModel+""+func;
                            ngModel.$setViewValue(scope.ngModel);
                            scope.syntax = "";
                        }
                    };

                    element.bind('click', function(e) {
                        console.log("in click");
                        // this part keeps it from firing the click on the document.
                        e.stopPropagation();
                    });
                    $document.bind('click', function() {
                        // magic here.
                        console.log("click in document");
                        scope.$apply(attrs.closedrop);
                        /*var myElement= document.getElementsByClassName('btn btn-small dropdown-toggle');
                         angular.element(myElement).triggerHandler('click');*/
                    })
                }
            }
       }]);

angular
    .module('adelementAdminApp')
    .filter('split', function() {
        return function(input, delimiter) {
            delimiter = delimiter || ','

            return input.split(delimiter);
        }
    });

angular
    .module('adelementAdminApp')
    .controller('FeedManagerListController', function ($scope,$state,$stateParams,$interval,$timeout,$http,FeedService,$uibModal,dataTableInstance,DTOptionsBuilder,DTColumnDefBuilder){
        $scope.dtInstance = {};
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('iDisplayLength',50).withOption('paging',true)
            .withFixedHeader({
                bottom: true,
                headerOffset:49
            }).withDOM('frtipl');
        $scope.dtColumnDefs = [
            DTColumnDefBuilder.newColumnDef(0).withOption('type', 'num'),
            DTColumnDefBuilder.newColumnDef(3).withOption('type', 'date'),
            DTColumnDefBuilder.newColumnDef(4).withOption('type', 'num'),
            DTColumnDefBuilder.newColumnDef(5).withOption('type', 'num')
        ];
        function init(){
            $scope.feedData = [];
        }
        function initServices(){
            FeedService.getFeeds().then(function (response) {
                //alert(response.length);
                $scope.feedData = response;
            })
        }
        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }
        $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
            }
        });

        $scope.showTopProducts = function (advertiserId,feedId,tab) {
            $uibModal.open({
                templateUrl: 'app/feed-manager/top-products.html',
                controller: 'TopProductsController',
                size: 'lg-90',
                resolve: {
                    FeedData: function () {
                        return {
                            advertiserId:advertiserId,
                            feedId:feedId,
                            active:tab
                        };
                    }
                }
            }).result.then(function(result) {
                //$state.go('roleMapping', null, { reload: true });
            }, function() {
                //$state.go('roleMapping');
            })
        };

        init();
        initServices();
    });
