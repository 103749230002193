'use strict';

angular.module('adelementAdminApp')
	.controller('AeAdvertisersDeleteController', function($scope, $uibModalInstance, entity, AeAdvertisers) {

        $scope.aeAdvertisers = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            AeAdvertisers.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
