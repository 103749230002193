(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            templateUrl:'',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            },
            controller: function($scope, authorize){
                $scope.authorize = authorize;
            }
        });
    }
})();
