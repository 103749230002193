/**
 * Created by maitray on 11/3/16.
 */
angular.module('adelementAdminApp')
    .factory('Dashboard',function($http) {
        return {
            getCampaigns: function (advid) {
                return $http.get('/api/users/' + advid + '/campaign').then(function (response) {
                    return response.data;
                });
            },
            getAdgroups: function (advid, campid) {
                return $http.get('/api/users/' + advid + '/' + campid + '/adgroup').then(function (response) {
                    return response.data;
                });
            },
            getAdParamsById : function(adid){
                return $http.get('api/getAdById/' + adid).then(function (response) {
                    return response.data;
                });
            },
            getAdParamsByAdgId : function(adgroupid){
                return $http.get('api/getAdByAdgroupId/' + adgroupid).then(function (response) {
                    return response.data;
                });
            },
            toggleState : function(advertiserId,type,id,data){
                var url = "/api/dashboard/toggleState/"+advertiserId+"/"+type+"/"+id;
                return $http.post(url,data).then(function (response) {
                    return response.data;
                });
            },
            getAffectedAds : function(advertiserId,type,id,data){
                var url = "/api/dashboard/getAffectedAds/"+advertiserId+"/"+type+"/"+id;
                return $http.post(url,data).then(function (response) {
                    return response.data;
                });
            },
            toggleStateList : function(advertiserId,type,data){
                var url = "/api/dashboard/toggleState/"+advertiserId+"/"+type+"/list";
                return $http.post(url,data).then(function (response) {
                    return response.data;
                });
            }
        }
    })
    .factory('CampaignRename', function ($resource, DateUtils) {
        return $resource('api/campaign/rename', {}, {
            'update': { method:'PUT' }
        });
    })
    .factory('AdgroupRename', function ($resource, DateUtils) {
        return $resource('api/adgroup/rename', {}, {
            'update': { method:'PUT' }
        });
    });
