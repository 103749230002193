/**
 * Created by rahul on 6/9/16.
 */
angular.module('adelementAdminApp')
    .controller('AudienceMgtController', function ($scope,$state,$stateParams,dataTableInstance,DTOptionsBuilder,GetAudiences,UpdateAudStatus,GetAdvertiserIDName) {
        $scope.dtInstance = {};
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('iDisplayLength',50).withOption('paging',true)
            .withFixedHeader({
                bottom: true,
                headerOffset:49
            }).withDOM('frtipl');


        $scope.audienceCount = null;
        $scope.containerEvents = null;
        $scope.audienceTickerData = null;
        $scope.audSparkLineData = null;

        $scope.selectedAdvertiser= null;

        $scope.allAdvertisersIdName = []

        var advertiserInfoPromise =  GetAdvertiserIDName.query();
        advertiserInfoPromise.$promise.then(function(response){
             $scope.allAdvertisersIdName = response;
        });


        $scope.getAdvAudiences = function(){
            var getAudPromise = GetAudiences.get({"advertiserId":$scope.selectedAdvertiser});
            getAudPromise.$promise.then(function(response){
                $scope.audienceCount = response.audienceCountData;
                $scope.containerEvents = response.containerEvents.containerEvents;
                $scope.audienceTickerData= response.audTickerData;
                $scope.audSparkLineData = response.audTickerData.sparkLineData;
            });

        };



        $scope.audStatusChange = function(audObj){
            console.log(audObj);
            function onSuccessUpdate(response){

            }
            function onFailureUpdate(response){
                alert("Fail to update audience status!");
            }
            UpdateAudStatus.update(audObj);
        }

        $scope.getAudRule = function(audObj){
            var ruleString = "";
            if(audObj!=null && audObj.containerHash in $scope.containerEvents){
                var ruleObj = eval('('+ audObj.rules  +')');
                var depth = null;
                var days = null;
                var indRuleObj = ruleObj.rules;
                for(var rind in indRuleObj){
                    if(indRuleObj[rind]["field"]== "depth"){
                        depth = indRuleObj[rind]["value"];
                    }else if(indRuleObj[rind]["field"]== "days"){
                        days = indRuleObj[rind]["value"];
                    }
                }
                if(depth != null && $scope.containerEvents[audObj.containerHash][depth] != undefined){
                    ruleString +="Visit "+$scope.containerEvents[audObj.containerHash][depth] +" page within "+days;
                }

            }
            return ruleString;
        };

        $scope.audienceType = function(audType){
            $scope.selectedType = audType;
            if($scope.selectedAdvertiser){
                if(audType == 'app'){
                    $state.go("container-create",{advId:$scope.selectedAdvertiser,webApp:"app"});
                }else{
                    $state.go("container-create",{advId:$scope.selectedAdvertiser,webApp:"web"});
                }
            }
            else{
                if(audType == 'app'){
                    $state.go("container-create-woadv",{webApp:"app"});
                }else{
                    $state.go("container-create-woadv",{webApp:"web"});
                }
            }

        };
        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }
        $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
            }
        })
    });
