angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('creative-manager', {
                parent: 'entity',
                url: '/creative-manager',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creative-manager/creative-manager.html',
                controller: 'CreativeManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('creative-manager-update', {
                parent: 'entity',
                url: '/creative-manager/update/{advertiserId}/{conceptId}/{type}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creative-manager/creative-manager.html',
                controller: 'CreativeManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('pagetitles');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                                var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                                return $http.get(url).then(function (response) {
                                    return response.data;
                                });
                            }]
                        }
            })
            .state('creative-manager-adv-summaryFilter', {
                parent: 'entity',
                url: '/creative-manager/byAdvertiser/{advertiserId}/{summaryFilter}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creative-manager/creative-manager.html',
                controller: 'CreativeManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })
            .state('creative-manager-summaryFilter', {
                parent: 'entity',
                url: '/creative-manager/{summaryFilter}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creative-manager/creative-manager.html',
                controller: 'CreativeManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('creative-manager-summaryFilter-summaryFilterId', {
                parent: 'entity',
                url: '/creative-manager/{summaryFilter}/{summaryFilterId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creative-manager/creative-manager.html',
                controller: 'CreativeManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('creative-manager-adv-summaryFilter-summaryFilterId', {
                parent: 'entity',
                url: '/creative-manager/byAdvertiser/{advertiserId}/{summaryFilter}/{summaryFilterId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creative-manager/creative-manager.html',
                controller: 'CreativeManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('creative-manager-create-adtags-creative', {
                parent: 'entity',
                url: '/creatives/adtags',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-adtag-create.title'
                },
                templateUrl: 'app/creative-manager/create-adtag.html',
                controller: 'CreativeManagerCreateAdtagCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: [function () {
                        return undefined;
                    }]
                }
            })

            .state('creative-manager-create-adtags-creative-advId', {
                parent: 'entity',
                url: '/creatives/adtags/{advertiserId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-adtag-create.title'
                },
                templateUrl: 'app/creative-manager/create-adtag.html',
                controller: 'CreativeManagerCreateAdtagCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

            .state('creative-manager-edit-adtags-creative-advId', {
                parent: 'entity',
                url: '/creatives/adtags/{advertiserId}/{creativeMappingId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-adtag-edit.title'
                },
                templateUrl: 'app/creative-manager/create-adtag.html',
                controller: 'CreativeManagerCreateAdtagCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

            .state('creative-manager-create-inbanner-creative', {
                parent: 'entity',
                url: '/creatives/inbanner-video',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-inbanner-create.title'
                },
                templateUrl: 'app/creative-manager/create-inbanner-video.html',
                controller: 'CreativeManagerCreateInbannerVideoCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: [function () {
                        return undefined;
                    }]
                }
            })

            .state('creative-manager-create-inbanner-video-creative-advId', {
                parent: 'entity',
                url: '/creatives/inbanner-video/{advertiserId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-inbanner-create.title'
                },
                templateUrl: 'app/creative-manager/create-inbanner-video.html',
                controller: 'CreativeManagerCreateInbannerVideoCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

            .state('creative-manager-edit-inbanner-creative-advId', {
                parent: 'entity',
                url: '/creatives/inbanner-video/{advertiserId}/{creativeMappingId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-inbanner-edit.title'
                },
                templateUrl: 'app/creative-manager/create-inbanner-video.html',
                controller: 'CreativeManagerCreateInbannerVideoCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

            .state('creative-manager-adtags-list', {
                parent: 'entity',
                url: '/creatives/adtags/list',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-manager.title'
                },
                templateUrl: 'app/creatives/create-adtag.html',
                controller: 'CreativeManagerListAdtagCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('creative-manager-create-upload-ads-creative', {
                parent: 'entity',
                url: '/creatives/upload-ads',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-upload-ads.title'
                },
                templateUrl: 'app/creative-manager/upload-ads.html',
                controller: 'CreativeManagerUploadAdsCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: [function () {
                        return undefined;
                    }]
                }
            })

            .state('creative-manager-create-upload-ads-creative-advId', {
                parent: 'entity',
                url: '/creatives/upload-ads/{advertiserId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-upload-ads.title'
                },
                templateUrl: 'app/creative-manager/upload-ads.html',
                controller: 'CreativeManagerUploadAdsCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

            .state('creative-manager-edit-upload-ads-creative-advId', {
                parent: 'entity',
                url: '/creatives/upload-ads/{advertiserId}/{creativeMappingId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-upload-ads-edit.title'
                },
                templateUrl: 'app/creative-manager/upload-ads.html',
                controller: 'CreativeManagerUploadAdsCreativeController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })

    });
