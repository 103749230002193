'use strict';

angular.module('adelementAdminApp')
    .controller('ProspectiveUsersDetailController', function ($scope, $rootScope, $stateParams, entity, ProspectiveUsers) {
        $scope.prospectiveUsers = entity;
        $scope.load = function (id) {
            ProspectiveUsers.get({id: id}, function(result) {
                $scope.prospectiveUsers = result;
            });
        };
        var unsubscribe = $rootScope.$on('adelementAdminApp:prospectiveUsersUpdate', function(event, result) {
            $scope.prospectiveUsers = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
