/**
 * Created by snehal on 13/7/17.
 */
angular.module('adelementAdminApp')
    .factory('CreativeFormVastService', function ($http) {
        var url = "api/creatives/vast/";
        return {
           saveCreativeFormAdvId: function (advId, data) {
                 return $http.post(url+advId, data).then(function (response) {
                    return response.data;
             });
           },
           updateCreative: function (advId, creativeId, data) {
               return $http.post(url+advId+"/"+creativeId, data).then(function (response) {
                   return response.data;
               });
           },
           getCreative: function (advId,creativeId) {
               return $http.get(url+advId+"/"+creativeId).then(function (response) {
                   return response.data;
                });
           },
          saveCreativeForm: function (data) {
                return $http.post(url, data).then(function (response) {
                   return response.data;
            });
          },
          getVastData: function () {
              return $http.get(url).then(function (response) {
                  return response.data;
              });
          }
        }
    });
