/**
 * Created by adelement on 21/4/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('change-history', {
                parent: 'entity',
                url: '/changehistory',
                data: {
                    authorities: ['ROLE_ADMIN','SUPER_EXPERT_WRITE','SUPER_EXPERT_ADMIN'],
                    pageTitle:'adelementAdminApp.pagetitles.change-history.title'
                },
                templateUrl: 'app/admin/change-history/change-history.html',
                controller: 'ChangeHistoryController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        return $translate.refresh();
                    }]
                }
            });
    });
