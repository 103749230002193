/**
 * Created by rahul on 24/6/16.
 */
angular.module('adelementAdminApp')
    .controller('TagManagerController', function ($scope,$stateParams,TagManagerCtrlData){
        $scope.tagSettingStep = true;
        $scope.containerManageStep = false;
        $scope.recommendedAudienceStep = false;

        $scope.containerId = "";

        $scope.webAppType = $stateParams.webApp;

        var advertiserId = $stateParams.advId;

        if($stateParams.hasOwnProperty("containerId") && $stateParams.containerId != undefined){
            $scope.containerId = $stateParams.containerId;
            $scope.isEditContainer = true;
            TagManagerCtrlData.addCtrlVar("containerId",$scope.containerId);
        }

        $scope.tagManagersteps = function(step){
            if(step == 1){
                $scope.tagSettingStep = true;
                $scope.containerManageStep = false;
                $scope.recommendedAudienceStep = false;
            }else if(step==2 && TagManagerCtrlData.getCtrlVar("containerId") != null){
                $scope.tagSettingStep = false;
                $scope.containerManageStep = false;
                $scope.recommendedAudienceStep = true;
            }else if(step == 3 && TagManagerCtrlData.getCtrlVar("containerId") != null){
                $scope.tagSettingStep = false;
                $scope.containerManageStep = true;
                $scope.recommendedAudienceStep = false;
            }
        }

    })
    .controller('TagSettingsController', function ($scope,$stateParams,$state,$sce,GetDRTContainerTemplate,GetContainerTemplateData,CreateAdvertiserTags,CreateSrtContainer,TagManagerCtrlData,GetCreatedContainerData,UpdateEvtExampleValues,DraggableObjManupulate,DraggableObjDepthChange,GetTpSdkMacroMapping,GetAppTpSdk,SaveRecommendedAud,ngDialog,$window,GetVersionedContainerData,GetContainerVersions,RevertToContainerVersion,GetAdvertiserIDName) {
        $scope.containerTemplates = [];

        $scope.showContainerTemplates = false;
        $scope.showContainerEvents = false;

        $scope.draggableObjects = [];
        $scope.srtContainerTag = "";
        $scope.srtConvContainerTag = "";
        $scope.srtContainerId = "";
        $scope.srtConvContainerId = "";

        $scope.showAddVariables = false;
        $scope.addPageVariable = false;
        $scope.newPageVariable = "";
        $scope.advertiserName = "";
        $scope.createdTagId = "";

        $scope.audienceFrom = "";

        $scope.conversionType = ['Purchase','Signup','Lead','Other'];
        $scope.containerData = {
            "id":"",
            "advertiserId":$stateParams.advId,
            "containerName":"",
            "containerTemplateId":"",
            "containerEvents":$scope.draggableObjects,
            "duration":"",
            "containerType":null,
            "conversionType":null,
            "clickWindowHours":null,
            "viewWindowHours":null,
            "containerPlatform":$stateParams.webApp,
            "androidAppName":null,
            "iosAppName":null,
            "appTpSdkId":null,
            "convTagPiggybackFlag":false,
            "containerHash":"",
            "status":"",
            "description":"",
            "tpGenericUrl":null,
            "publishFlag":false,
            "containerVerHash":"",
            "containerVersion":null,
            "viewWindow":{},
            "clickWindow":{},
            "conversionTypeSelect":{},
            "selectedClickWindowFrequency":"",
            "selectedViewWindowFrequency":""
        };

        $scope.containerVersionsArray = [];

        $scope.conversionWindow = ["days","hours"];


        $scope.srtContainerData = {"id":null,"scriptTag":"","edit":false};
        $scope.srtConvContainerData = {"id":null,"scriptTag":"","edit":false};

        //icons mapping
        $scope.siteTypeIcons = {
            ecommerce:"fa-shopping-cart",
            hotels:"fa-building-o",
            flights:"fa-plane",
            education:"fa-graduation-cap",
            classified:"fa-list-alt",
            custom:"fa-code",
            travel:"",
            health:"fa-medkit"
        };
        $scope.hideGetPostback = false;
        $scope.appTpSdks=[];
        $scope.appTpMacroMapping = {};

        $scope.recommendedAudiences ={
            containerAudiences:[],
            activatedAudiences:[]
        };


        // added by Snehal
        $scope.allAdvertisersIdName = [];
        $scope.selectedAdvertiser = "";

        var advertiserInfoPromise =  GetAdvertiserIDName.query();
        advertiserInfoPromise.$promise.then(function(response){
             $scope.allAdvertisersIdName = response;
        });


        $scope.$watchCollection('allAdvertisersIdName', function () {
            if($stateParams.advId){
                $scope.showAdvertiser = false;
    //            $scope.selectedAdvertiser = $stateParams.advId;
                $scope.allAdvertisersIdName.forEach(function(obj) {

                  if(obj.id == $stateParams.advId){
                    $scope.selectedAdvertiser = obj.id;
//                    console.log(obj.x, obj.y);
                  }
                })
            }else{
                $scope.showAdvertiser = true;
            }
        });
        $scope.getSelectedAdvertiser = function(selectedAdvertiser){
            if($stateParams.webApp == "app"){
                $state.go("container-create",{advId:selectedAdvertiser,webApp:"app"});
            }else{
                $state.go("container-create",{advId:selectedAdvertiser,webApp:"web"});                      }
        }
        $scope.ShowHideWindow = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisible = $scope.IsVisible ? false:true;
        }




        if($stateParams.webApp == "app"){
            var appTpSdkPromise = GetAppTpSdk.query();
            appTpSdkPromise.$promise.then(function(response){
                $scope.appTpSdks = response;
            });
        }

        var copyDraggableObj = [];

        function getContainerVersionsArray(){
            var containerVersionsPromise = GetContainerVersions.get({"advertiserId":$scope.containerData["advertiserId"],"containerId":TagManagerCtrlData.getCtrlVar("containerId")});
            containerVersionsPromise.$promise.then(function(response){
                $scope.containerVersionsArray = response;
            });
        }

        $scope.getContainerTemplates = function(value){
            $scope.containerData.containerType = value;
            if($scope.containerData.containerType == "dynamicRt"){
                var forDrtSiteTypesPromise = GetDRTContainerTemplate.get({webApp:$scope.webAppType,advId:$stateParams.advId});
                forDrtSiteTypesPromise.$promise.then(function(response){
                    $scope.containerTemplates = response.containerTemplates;
                    $scope.advertiserName = response.advertiserName;
                    $scope.showContainerTemplates = true;
                });
            }

        };

        $scope.getContainerTemplatesData = function(containerTempId){
            $scope.containerData.containerTemplateId = containerTempId;
            $scope.showAddVariables = false;
            var forDRTTagsDataPromise = GetContainerTemplateData.query({containerTemplateId: containerTempId});
            forDRTTagsDataPromise.$promise.then(function(response){
                $scope.draggableObjects = response;
                copyDraggableObj = angular.copy($scope.draggableObjects);
                $scope.showContainerEvents = true;
            });
        };

        $scope.addScriptTagsToPage = function(){
            for(var ind in $scope.draggableObjects){

                var scriptTagStr = '<!-- AdElement Tag :AllVisitors–DynamicRT Description :-->\n';
                scriptTagStr += '<script type = "text/javascript">\n';
                scriptTagStr += 'var ae_parms_kv ={\n';

                for(var variable in $scope.draggableObjects[ind].containerEventParms){
                    if( $scope.draggableObjects[ind].containerEventParms[variable].hasOwnProperty("parmSourceType") && $scope.draggableObjects[ind].containerEventParms[variable].parmSourceType == 'custom')continue;
                    scriptTagStr += "    "+$scope.draggableObjects[ind].containerEventParms[variable].parmName ;
                    if( $scope.draggableObjects[ind].containerEventParms[variable].defaultValue !=null && $scope.draggableObjects[ind].containerEventParms[variable].defaultValue.indexOf("[") != -1 && $scope.draggableObjects[ind].containerEventParms[variable].defaultValue.indexOf("]") != -1){
                        scriptTagStr += " : " + $scope.draggableObjects[ind].containerEventParms[variable].defaultValue + "," ;               //"+$scope.draggableObjects[ind].containerEventParms[variable].description+" \n";
                        if($scope.draggableObjects[ind].containerEventParms[variable]["description"] !=null && $scope.draggableObjects[ind].containerEventParms[variable]["description"].replace( /\s/g, "") !=''){
                            scriptTagStr += "       // "+$scope.draggableObjects[ind].containerEventParms[variable].description+" \n";
                        }else{
                            scriptTagStr += "\n"
                        }
                    }else{
                        scriptTagStr += " : '" + $scope.draggableObjects[ind].containerEventParms[variable].defaultValue + "',";
                        if($scope.draggableObjects[ind].containerEventParms[variable]["description"] !=null && $scope.draggableObjects[ind].containerEventParms[variable]["description"].replace( /\s/g, "") !=''){
                            scriptTagStr += "       // "+$scope.draggableObjects[ind].containerEventParms[variable].description+"\n";
                        }else{
                            scriptTagStr += "\n";
                        }
                    }
                }
                scriptTagStr += '    depth : '+ $scope.draggableObjects[ind]["depth"] +'\n';
                scriptTagStr += "};\n";

                scriptTagStr += '(function () {var el_adv_id = "'+$scope.containerData.containerVerHash+'"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s =document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());';
                scriptTagStr += '</script>\n';
                scriptTagStr += '<!-- End of AdElement Retargeting Tag -->';
                $scope.draggableObjects[ind]["objScriptTag"] = scriptTagStr;
            }
        };

        if($stateParams.hasOwnProperty("containerId") && $stateParams.containerId != undefined){
            $scope.containerData.id = $stateParams.containerId;
            $scope.audienceFrom = 'fromNewTag';
            $scope.createExample = true;
            function onSuccessGetContainer(response){
                console.log("success");
            };
            function onFailureGetContainer(response){
                console.log("failure");
            };
            var getContainerData = GetCreatedContainerData.get({"advertiserId":$scope.containerData["advertiserId"],"containerId":$scope.containerData.id,"webApp":$stateParams.webApp},onSuccessGetContainer,onFailureGetContainer);

            $scope.getContainerDataFunc =  function (){
                getContainerData.$promise.then(function(response){
                    $scope.fromNewTag = true;
                    $scope.fromRuleOnExisting = false;
                    $scope.audienceCreateFromNewTag = true;
                    $scope.recommendedAudiences.containerAudiences = response["containerAudiences"];
                    if(response["container"]["containerType"] == "dynamicRt"){
                        $scope.containerData = response["container"];
                        $scope.draggableObjects = response["advertiserContainerData"];
                        $scope.containerData.containerEvents = $scope.draggableObjects;
                        copyDraggableObj = angular.copy($scope.draggableObjects);
                        $scope.showContainerEvents = true;
                        $scope.showAddVariables = true;
                        TagManagerCtrlData.addCtrlVar("containerEvents",$scope.draggableObjects);
                        TagManagerCtrlData.addCtrlVar("containerData",$scope.containerData);
                        $scope.getContainerTemplates("dynamicRt");
                        $scope.addScriptTagsToPage();
                        addAudienceName();
                    }else if(response["container"]["containerType"] == "simpleRt"){
                        $scope.containerData = response["container"];
                        $scope.srtContainerData.id =  $scope.containerData.id;
                        $scope.srtContainerData.edit = true;
                        if($scope.webAppType == "web"){
                            createSrtContainerScript($scope.containerData.containerName,$scope.containerData.containerType,$scope.containerData.containerHash);
                        }else if($scope.webAppType == "app"){
                            getGenericDefaultPostbacks($scope.containerData.appTpSdkId);
                        }
                        TagManagerCtrlData.addCtrlVar("containerData",$scope.containerData);

                    }else if(response["container"]["containerType"] == "simpleConv"){
                        $scope.containerData = response["container"];
                        $scope.srtConvContainerData.id =  $scope.containerData.id;
                        $scope.srtConvContainerData.edit = true;
                        createSrtContainerScript($scope.containerData.containerName,$scope.containerData.containerType,$scope.containerData.containerHash);
                        TagManagerCtrlData.addCtrlVar("containerData",$scope.containerData);
                    }
                });

            }
            $scope.getContainerDataFunc();
            getContainerVersionsArray();

        };


        $scope.getContainerVer = false;
        $scope.clearContainerVer = function(){
            $scope.select["containerVersion"]=null;
            $scope.getContainerVer = false;
            $scope.getContainerDataFunc();

        };


        $scope.getContainerVersion = function(containerVer){
            var getContainerVersionData = GetVersionedContainerData.get({"advertiserId":$scope.containerData["advertiserId"],"containerId":$scope.containerData.id,"containerVersion":containerVer});
            getContainerVersionData.$promise.then(function(response){
                $scope.getContainerVer = true;
                onSuccessCreateContainer(response);
            });
        };

        $scope.select={};
        $scope.select["containerVersion"]=null;
        function addAudienceName(){
            for(var indx in $scope.recommendedAudiences.containerAudiences){
                var audJsonStr;
                if(typeof $scope.recommendedAudiences.containerAudiences[indx] == "string"){
                    $scope.recommendedAudiences.containerAudiences[indx] = JSON.parse($scope.recommendedAudiences.containerAudiences[indx]);
                    audJsonStr =  eval('('+$scope.recommendedAudiences.containerAudiences[indx].rules+')');
                }else{
                    audJsonStr =  eval('('+$scope.recommendedAudiences.containerAudiences[indx].rules+')');
                }

                for(var ind in audJsonStr.rules){
                    if(audJsonStr.rules[ind]["field"] == "days"){
                        $scope.recommendedAudiences.containerAudiences[indx]["days"] = audJsonStr.rules[ind]["value"];
                    }else if(audJsonStr.rules[ind]["field"]=="depth"){
                        $scope.recommendedAudiences.containerAudiences[indx]["depth"] = audJsonStr.rules[ind]["value"];

                        for(var elemInd in $scope.containerData.containerEvents){
                            if(!$scope.recommendedAudiences.containerAudiences[indx].hasOwnProperty("eventName") && $scope.containerData.containerEvents[elemInd].depth == audJsonStr.rules[ind]["value"]){
                                $scope.recommendedAudiences.containerAudiences[indx]["eventName"] = $scope.containerData.containerEvents[elemInd]["eventName"];
                            }
                        }
                    }
                }
                if($scope.recommendedAudiences.containerAudiences[indx]["inclusionExclusionAudiences"] !=null && $scope.recommendedAudiences.containerAudiences[indx]["inclusionExclusionAudiences"] !=""){
                    var inclusionExclusionJson = eval('('+ $scope.recommendedAudiences.containerAudiences[indx]["inclusionExclusionAudiences"] +')');
                    var excludeAudIds =  inclusionExclusionJson["exclusion"];
                    $scope.recommendedAudiences.containerAudiences[indx]["excludeAudName"]="& are not ";
                    for(var exAudId in excludeAudIds){
                        for(var index in $scope.recommendedAudiences.containerAudiences){
                            if(excludeAudIds[exAudId] == $scope.recommendedAudiences.containerAudiences[index].id){
                                $scope.recommendedAudiences.containerAudiences[indx]["excludeAudId"] = excludeAudIds;
                                $scope.recommendedAudiences.containerAudiences[indx]["excludeAudName"] += $scope.recommendedAudiences.containerAudiences[index].audienceName +",";
                            }
                        }
                    }

                    $scope.recommendedAudiences.containerAudiences[indx]["excludeAudName"] = $scope.recommendedAudiences.containerAudiences[indx]["excludeAudName"].substring(0,$scope.recommendedAudiences.containerAudiences[indx]["excludeAudName"].length-1);

                }
            }

        };


        var getGenericDefaultPostbacks = function(appTpSdkId){
            for(var index in $scope.appTpSdks){
                if($scope.appTpSdks[index].id == appTpSdkId){
                    $scope.containerData.appTpSdkName = $scope.appTpSdks[index].appTpSdkName;
                    $scope.containerData.tpGenericUrl = $scope.appTpSdks[index].postbackDefaultUrl;
                    break;
                }
            }
        };

        $scope.generatePostbackUrl= function(appTpSdkId){

            getGenericDefaultPostbacks(appTpSdkId);
            for(var dragInd in $scope.draggableObjects){
                $scope.draggableObjects[dragInd]["androidPostbackUrl"] = null;
                $scope.draggableObjects[dragInd]["iosPostbackUrl"] = null;
            }
            var aePostbackUrl = "https://sc.adelement.com/appi_e?";
            var getTpMacromappingPromise = GetTpSdkMacroMapping.get({tpSdkId:appTpSdkId});
            getTpMacromappingPromise.$promise.then(function(response){
                console.log("promise");
                $scope.generatePostbackUrlsData = response;
                if($scope.generatePostbackUrlsData != null && $scope.generatePostbackUrlsData != undefined){
                    var postbacksObj = {};
                    if($scope.containerData["androidAppName"] == null && $scope.containerData["iosAppName"] == null){
                        alert("please provide android app name or ios app name");
                        return;
                    }
                    if($scope.containerData["androidAppName"] != null){
                        postbacksObj["androidPostbackUrl"] = $scope.containerData["androidAppName"];
                    }
                    if($scope.containerData["iosAppName"] != null){
                        postbacksObj["iosPostbackUrl"] = $scope.containerData["iosAppName"];
                    }
                    if(postbacksObj["androidPostbackUrl"] == postbacksObj["iosPostbackUrl"]){
                        delete postbacksObj["iosPostbackUrl"];
                    }
                    for(var appKey in postbacksObj){
                        for(var dind in $scope.draggableObjects){
                            var genericUrl = aePostbackUrl;
                            var parmJsonStr = "{";
                            for(var parmInd in $scope.draggableObjects[dind]["containerEventParms"]){
                                parmJsonStr+= '"'+$scope.draggableObjects[dind]["containerEventParms"][parmInd]["parmName"]+'" : "'+$scope.draggableObjects[dind]["containerEventParms"][parmInd]["defaultValue"]+'",';
                            }
                            if(parmJsonStr.length > 2)parmJsonStr = parmJsonStr.substr(0,parmJsonStr.length-1);
                            parmJsonStr+="}";

                            for(var key in $scope.generatePostbackUrlsData["genericUrl"]){
                                if($scope.generatePostbackUrlsData["genericUrl"][key].aeParam == "aeevt"){
                                    genericUrl += $scope.generatePostbackUrlsData["genericUrl"][key].aeParam+'='+ $scope.draggableObjects[dind]["eventName"] +'&'
                                }else if($scope.generatePostbackUrlsData["genericUrl"][key].aeParam == "aei"){
                                    genericUrl += $scope.generatePostbackUrlsData["genericUrl"][key].aeParam+'='+ postbacksObj[appKey] +'&'
                                }else if($scope.generatePostbackUrlsData["genericUrl"][key].aeParam == "aeevtval"){
                                    genericUrl += $scope.generatePostbackUrlsData["genericUrl"][key].aeParam+'='+ parmJsonStr +'&'
                                }else{
                                    genericUrl += $scope.generatePostbackUrlsData["genericUrl"][key].aeParam+'='+ $scope.generatePostbackUrlsData["genericUrl"][key].tpMacro +'&'
                                }
                            }
                            genericUrl = genericUrl.substring(0,genericUrl.length-1);
                            $scope.draggableObjects[dind][appKey] = genericUrl;
                        }
                    }
                }

            });
            $scope.hideGetPostback = true;
        };

        var createSrtContainerScript = function(containerName,containerType,containerHash){
            var scriptTagStr = '<!-- AdElement Tag :AllVisitors–'+containerName+' :-->\n';
            scriptTagStr += '<script type = "text/javascript">\n';
            scriptTagStr += 'var ae_parms_kv ={};\n';
            scriptTagStr += '(function () {var el_adv_id = "'+containerHash+'"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s =document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());';
            scriptTagStr += '</script>\n';
            scriptTagStr += '<!-- End of AdElement Tag -->';
            if(containerType == "simpleRt"){
                //$scope.srtContainerTag = scriptTagStr;
                $scope.srtContainerData.scriptTag = scriptTagStr;
            }else if(containerType == "simpleConv"){
                //$scope.srtConvContainerTag = scriptTagStr;
                $scope.srtConvContainerData.scriptTag = scriptTagStr;

            }
        };
        $scope.createSrtContainer = function(containerType){
            var onSuccessSrtContainerCreate = function(response){
                $scope.containerData["containerType"] = "simpleRt";
                if(response["container"]["containerType"] == "simpleRt"){
                    $scope.srtContainerData = response["container"];
                    $scope.containerData.id = $scope.srtContainerData.id;
                    TagManagerCtrlData.addCtrlVar("containerId",$scope.containerData.id);
                    $scope.recommendedAudiences.containerAudiences = response["containerAudiences"];
                }else if(response["container"]["containerType"] == "simpleConv"){
                    $scope.srtConvContainerData = response["container"];
                    //$scope.containerData.id = $scope.srtConvContainerData.id;
                    //TagManagerCtrlData.addCtrlVar("containerId",$scope.containerData.id);
                }
                if($scope.webAppType == "web"){
                    createSrtContainerScript(response["container"].containerName,response["container"].containerType,response["container"].containerHash);
                }else if($scope.webAppType == "app"){
                    $scope.srtContainerData.edit=true;
                    getGenericDefaultPostbacks(response["container"].appTpSdkId);
                }
                addAudienceName();
            };
            var onFailureSrtContainerCreate = function(response){
                console.log(response);
                console.log("failed to create container:::"+containerType)
            };
            $scope.containerData["containerType"] = containerType;
            CreateSrtContainer.save($scope.containerData,onSuccessSrtContainerCreate,onFailureSrtContainerCreate);
        };
        $scope.errMsg = "";
        $scope.isValidationError = false;


        var onSuccessCreateContainer = function(response){

            $scope.containerData = response["container"];
            if($scope.containerData.containerVersion > 0){
                $scope.containerData.containerVerHash = $scope.containerData.containerHash +"_v"+ $scope.containerData.containerVersion;
            }
            $scope.draggableObjects = response["advertiserContainerData"];

            $scope.containerData.containerEvents = $scope.draggableObjects;
            TagManagerCtrlData.addCtrlVar("containerId",$scope.containerData.id);
            TagManagerCtrlData.addCtrlVar("containerEvents",$scope.draggableObjects);
            $scope.recommendedAudiences.containerAudiences = response["containerAudiences"];
            $scope.containerData.publishFlag =false;
            if($scope.webAppType == "app"){
                $scope.generatePostbackUrl($scope.containerData.appTpSdkId);
            }else if($scope.webAppType == "web"){
                $scope.addScriptTagsToPage();
            }

            getContainerVersionsArray();
            addAudienceName();

            $state.go('container-edit',{"webApp":$scope.containerData.containerPlatform,"advId":$scope.containerData.advertiserId,"containerId":$scope.containerData.id});
        };
        var onFailureCreateContainer = function(response){
            ngDialog.open({
                template: '<p>Sorry ! we are facing some technical problem.</p>',
                plain:true,
                className: 'ngdialog-theme-default'
            });
            console.log(response);
        };
        $scope.createContainer = function(){
            $scope.errMsg = "";
            $scope.isValidationError = false;
            if($scope.containerData['duration'] == "" || $scope.containerData['containerName'] == ""){
                $scope.errMsg = "Container Name or retargeting duration should not be null !!";
                $scope.isValidationError = true;
                return;
            }
            if($scope.containerData.clickWindowHours == null || $scope.containerData.viewWindowHours == null){
                $scope.errMsg = "Click window hours and view window hours should not be null !!";
                $scope.isValidationError = true;
                return;
            }
            if($scope.containerData.conversionType == null){
                $scope.errMsg = "Please select conversion type !!";
                $scope.isValidationError = true;
                return;
            }
            if($scope.webAppType == "app"){
                if($scope.containerData.androidAppName == null && $scope.containerData.iosAppName == null){
                    $scope.errMsg = "Android app name or ios app name is required!";
                    $scope.isValidationError = true;
                    return;
                }
                if($scope.containerData.appTpSdkId == null){
                    $scope.errMsg = "Please select third party sdk !!";
                    $scope.isValidationError = true;
                    return;
                }
            }
            $scope.containerData.containerEvents = $scope.draggableObjects;
            CreateAdvertiserTags.save($scope.containerData,onSuccessCreateContainer,onFailureCreateContainer);
        };

        $scope.revertToContainerVersion = function(containerVersion){
            function onSuccessRevert(response){
                $scope.getContainerVer = false;
                $scope.select.containerVersion = null;
            }
            function onFailureRevert(response){

            }
            RevertToContainerVersion.update({"advertiserId":$scope.containerData.advertiserId,"containerId":$scope.containerData.id,"containerVersion":containerVersion},onSuccessRevert,onFailureRevert);
        };

        $scope.publishContainerModal = function(){
            ngDialog.openConfirm({
                template: '<div>Do you want to create new version of tag?</div><br>'+
                '<div>' +
                '<button type="button" class="btn btn-default" ng-click="closeThisDialog(0)">No' +
                '<button type="button" class="btn btn-primary" ng-click="confirm(1)">Yes' +
                '</button></div>',
                className: 'ngdialog-theme-default',
                plain:true,
                scope:$scope
            }).then(function () {
                console.log('Modal promise resolved. Value: ');
                $scope.containerData.publishFlag =true;
                $scope.createContainer();

            }, function () {
                console.log('Container Changes not published in the new version');
            });
        };

        $scope.onDropComplete = function (index, obj, evt) {
            DraggableObjManupulate.setDraggableObj($scope.draggableObjects,index,obj);
            $scope.draggableObjects = DraggableObjManupulate.getDraggableObj();
        };
        $scope.changeObj = function(){
            $scope.draggableObjects[index].name = data;
        };

        $scope.newAddedStageIndex = null;
        $scope.addStage = function(index,obj){
            index = parseInt(index);
            var draggableObjectsSize = $scope.draggableObjects.length;
            if(draggableObjectsSize-1!=index){
                var newStage = {eventName: '',depth:$scope.draggableObjects[index +1]["depth"],editStage : true,containerEventParms:[],conversionEnabled:false};
                $scope.draggableObjects.splice(index +1, 0, newStage);
                copyDraggableObj.splice(index + 1,0,angular.copy(newStage));
                $scope.newAddedStageIndex = index +1;
            }else{
                newStage = {eventName: "", depth: index, editStage: true, containerEventParms: [], conversionEnabled: false};
                $scope.draggableObjects.splice(index +1, 0, newStage);
                copyDraggableObj.splice(index + 1,0,angular.copy(newStage));
                $scope.newAddedStageIndex = index +1;
            }

        };

        $scope.turnOnEditable = function(obj){
          if($scope.newAddedStageIndex && obj.hasOwnProperty("form"+$scope.newAddedStageIndex)){
              obj["form"+$scope.newAddedStageIndex].$show();
              $scope.newAddedStageIndex = null;
          }
        };

        $scope.onShowEditFunc = function(index,parObj){
            $scope.draggableObjects[index].editStage = true;
            parObj["form"+index].$show();
        };

        $scope.showForm = function(index,obj){
            var formName = "form"+index;
            obj[formName].$show();
        };

        $scope.onAfterSaveFunc = function(index,obj){
            $scope.draggableObjects[index].editStage = false;
            if(obj.$data == ""){
                $scope.draggableObjects.splice(index,1);
            }
            for(var index in $scope.draggableObjects){
                $scope.draggableObjects[index].depth = $scope.draggableObjects[index].depth;
            }
        };

        $scope.onShowFunc = function (index) {
            $scope.draggableObjects[index].editStage = true;
        };
        $scope.onCancelFunc = function(index,obj){
            $scope.draggableObjects[index].editStage = false;
            if(obj.$data == ""){
                $scope.draggableObjects.splice(index,1);
            }
        };


        $scope.addVariables = function () {
            $scope.showAddVariables = true;
        };

        $scope.removeStage = function(index){
            $scope.draggableObjects.splice(index,1);
            copyDraggableObj.splice(index,1);
        };

        function checkJsCode(javascriptStr){
            try {
                $window.eval(javascriptStr);
            } catch (e) {
                if(e.message != "ae_parms_kv is not defined" && e.message != "dataLayer is not defined"){
                    $scope.errFlag = true;
                    $scope.errMsg = "Error in custom javascript code snippet !  "+e.message;
                }
            }
        };

        $scope.parmSourceType = ['pass','custom'];
        $scope.parmType = ['id','groupingId','price','other','depthIdentifier'];

        function containerParmsPopup(ObjIndex,flag){
            $scope.errMsg = "";
            $scope.errFlag = false;
            function closeEvtParmsAdd(){
                for(var indx = $scope.draggableObjects[ObjIndex]["containerEventParms"].length-1;indx>=0;indx--){
                    if($scope.draggableObjects[ObjIndex]["containerEventParms"][indx]["parmName"].trim() == "" || $scope.draggableObjects[ObjIndex]["containerEventParms"][indx]["parmSourceType"] == ""){
                        $scope.draggableObjects[ObjIndex]["containerEventParms"].splice(indx,1);
                    }
                    if($scope.draggableObjects[ObjIndex]["containerEventParms"][indx] !== undefined && $scope.draggableObjects[ObjIndex]["containerEventParms"][indx]["parmSourceType"] == 'custom' ){
                        checkJsCode($scope.draggableObjects[ObjIndex]["containerEventParms"][indx]["parmExtractSnippet"])
                        //if($scope.errFlag){
                        //    $scope.draggableObjects[ObjIndex]["containerEventParms"].splice(indx,1);
                        //}
                    }
                }
                $scope.errMsg = "";
                $scope.errFlag = false;
            }

            $scope.parmPopupIndex = ObjIndex;
            ngDialog.openConfirm({
                template: 'app/tag-manager/web/containerParmsPopup.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain:false,
                scope:$scope,
                preCloseCallback:closeEvtParmsAdd
            });
        }

        $scope.saveEvtVars = function(dObj,index){
            $scope.errMsg = "";
            $scope.errFlag = false;
            for(var ind in $scope.draggableObjects[index]["containerEventParms"]){
                if($scope.draggableObjects[index]["containerEventParms"][ind]["parmSourceType"] == "custom" && $scope.draggableObjects[index]["containerEventParms"][ind]["parmExtractSnippet"].trim()==""){
                    $scope.errMsg="Custom javascript code should not be null!";
                    $scope.errFlag = true;
                }
                if($scope.draggableObjects[index]["containerEventParms"][ind]["parmName"].trim() == "" || $scope.draggableObjects[index]["containerEventParms"][ind]["parmType"]=="" || $scope.draggableObjects[index]["containerEventParms"][ind]["parmSourceType"] == ""){
                    $scope.errMsg = "Parameter name,type and source should not be null!";
                    $scope.errFlag = true;
                }
                if($scope.draggableObjects[index]["containerEventParms"][ind]["parmSourceType"]=="custom" && $scope.draggableObjects[index]["containerEventParms"][ind]["parmExtractSnippet"].trim() !="")checkJsCode($scope.draggableObjects[index]["containerEventParms"][ind]["parmExtractSnippet"]);
            }

            if($scope.errFlag)return;

            dObj.closeThisDialog(0);
        };
        //for varibles display and manupulation
        var maintainPreviousDepth = {};
        $scope.showVariableForm = function(type,index,obj,parInd){

            if(type == 'pagevar'){
                //var formName = "variableForm"+index;
                //obj[formName].$show();
                //$scope.draggableObjects[index].editStage = true;

                containerParmsPopup(parInd,"varEdit");


            }else if(type == 'depth'){
                var formName = "objdepth"+index;
                obj[formName].$show();
                maintainPreviousDepth[index] = $scope.draggableObjects[index]["depth"];
                $scope.draggableObjects[index].editStage = true;
            }

        };
        $scope.removeVariable = function(index,parentIndex){
            if($scope.draggableObjects[parentIndex].containerEventParms[index]["parmType"] == "id")return;
            $scope.draggableObjects[parentIndex].containerEventParms.splice(index,1);
        };

        $scope.addVariable = function(parentIndex,flag){
            //parObj["newPageVariable"] = "";
            $scope.newPageVariable = "";
            //if(parObj.hasOwnProperty("addVariableForm")){
                //$scope.addPageVariable = true;
                //parObj["addVariableForm"].$show();
                var aeParmLength  = $scope.draggableObjects[parentIndex]["containerEventParms"].length;
                if(aeParmLength > 0){
                    $scope.draggableObjects[parentIndex]["containerEventParms"][aeParmLength-1]["addFlag"] = false;
                }
                var obj = {"parmName":"","parmType":"other","parmSourceType":"pass","addFlag":true,"defaultValue":"yourValue","parmExtractSnippet":""};
                $scope.draggableObjects[parentIndex]["containerEventParms"].push(obj);

                if(flag!="modaladd")containerParmsPopup(parentIndex,"varAdd");
            //}
        };

        $scope.insertVariable = function(parentIndex,varName){
            if(varName != "" && varName != undefined)
                $scope.draggableObjects[parentIndex].containerEventParms.push({parmName:varName,isVarEdit:false,parmType:"other",defaultValue:"yourValue"});
            $scope.addPageVariable = false;
            $scope.draggableObjects[parentIndex].editStage = false;
        };

        $scope.onShowVarEditFunc = function(type,parIndex,index){
            if(type == 'pagevar'){
                $scope.draggableObjects[parIndex].editStage = true;
                $scope.draggableObjects[parIndex].containerEventParms[index].isVarEdit = true;
            }

        };

        $scope.onCancelVarEditFunc = function(type,parIndex,index,obj){
            if(type == 'pagevar'){
                $scope.draggableObjects[parIndex].containerEventParms[index].isVarEdit = false;
            }
            $scope.draggableObjects[parIndex].editStage = false;

        };

        $scope.onAfterSaveVarEditFunc = function(type,parIndex,index,obj){
            if(type == 'pagevar'){
                $scope.draggableObjects[parIndex].editStage = false;
                $scope.draggableObjects[parIndex].containerEventParms[index].isVarEdit = false;
            }else if(type== 'depth'){
                DraggableObjDepthChange.setObjDepthChange($scope.draggableObjects,copyDraggableObj,maintainPreviousDepth,type,parIndex,index,obj);
                $scope.draggableObjects = DraggableObjDepthChange.getObjDepthChange();
            }

        };

        $scope.onShowVarAddFunc = function(parIndex){
            $scope.draggableObjects[parIndex].editStage = true;
        };

        $scope.onCancelVarAddFunc = function(parIndex,index,obj){
            $scope.draggableObjects[parIndex].editStage = false;
        };

        $scope.activateTab = function(tabNo){

        };
        $scope.exampleUrl = $sce.trustAsResourceUrl("");
        $scope.loadPage = function(exampleUrl){
            $scope.exampleUrl = $sce.trustAsResourceUrl(exampleUrl);
        };

        //code for generating pdf
        function toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.send();
        }

        var imgBase64Str = "";
        toDataUrl('assets/images/ae-logo.png', function(base64Img) {
            imgBase64Str = base64Img;
        });
        var image1 = "";
        toDataUrl('assets/images/docimage1.jpg', function(base64Img) {
            image1 = base64Img;
        });
        var image2 = "";
        toDataUrl('assets/images/docimage2.jpg', function(base64Img) {
            image2 = base64Img;
        });

        $scope.downloadPdf = function() {
            var pdfContent = new Array();
            var tab = 	{
                fillColor:'#0099e5',
                table: {
                    widths: [70,'*'],


                    body: [
                        [{image: 'image1',width:60,alignment:'center',margin:[0,0,-10,0]},{ text: 'Retargeting Integration Instructions '+$scope.advertiserName,fontSize: 18,alignment:'left',margin:[0,56,0,0],color:'white'}]
                    ]
                },
                layout: {
                    hLineWidth: function(i, node) {
                        return 2;
                    },
                    vLineWidth: function(i, node) {
                        return 2;
                    },
                    hLineColor: function(i, node) {
                        return '#0099e5';
                    },
                    vLineColor: function(i, node) {
                        return '#0099e5';
                    },
                }
            };
            pdfContent.push(tab);
            pdfContent.push("\n\n");
            var subheader = {
                text: 'INTRODUCTION\n',
                style:'header'
            };
            pdfContent.push(subheader);

            var content = {};
            var text1 = ['Please find below the detailed technical instructions for deploying AdElement retargeting tags on '+
            'your site. These are tailored specifically for your website based on our understanding of your '+
            'website structure and flow.\n\n','You need to implement a common tag across your platform, ensuring you pass correct variables at different pages as per instructions attached. These tags are placed along the shopping/browsing experience, hence on the following pages:\n\n'];
            //'Home Page, Search Page, Brand Page, Product Page, Compare Page, Booking Page and Thank You/Payment Confirmation Page\n\n'

            var pageTypeContentStr = "";
            for(var ind in $scope.draggableObjects){
                if($scope.draggableObjects[ind].hasOwnProperty("eventName")){
                    if($scope.draggableObjects[ind]["eventName"].toLowerCase().indexOf("page") == -1){
                        pageTypeContentStr += $scope.draggableObjects[ind]["eventName"] + " page, ";
                    }else{
                        pageTypeContentStr += $scope.draggableObjects[ind]["eventName"] + ", ";
                    }
                }
            }
            if(pageTypeContentStr != ""){
                pageTypeContentStr = pageTypeContentStr.slice(0, -2);
                pageTypeContentStr += "."
            }
            text1.push(pageTypeContentStr+"\n\n");
            content['text'] = text1;
            content['color'] = "#212121";
            pdfContent.push("\n");
            pdfContent.push(content);
            var colObj = 	{
                columns: [
                    {
                        text: [{text:'Asynchronous Implementation\n\n',bold:'true'},'It is important to place the AdElement retargeting tags within the <body> of the page, as close to the bottom of it as possible (i.e. just before the enclosing </body> element). This is to ensure that the tags load only after the webpage has finished loading completely to avoid any unnecessary delay in loading of page.\n\n',{text:'Unconditional Conversion Tags\n\n',bold:'true'},{text:'It is very important to not put any condition on the tags. Putting a condition on the ‘ThankYou’ page interferes with our algorithm’s capability to track user behaviour throughout the purchase cycle , and severely compromises our optimization process. Since our algorithm is centred on driving Conversions, and not just Clicks, we will not be able to optimize the campaign if we are not able to monitor the purchase cycle of a user to completion.'}],
                        margin:[0,-10,30,0]
                    },
                    {
                        image:'image2',width:220
                    }
                ]
            };
            pdfContent.push("\n");
            pdfContent.push({text:'IMPORTANT NOTES \n',style:'header'});
            //pdfContent.push({text:'Asynchronous Implementation\n\n\n',bold:'true'});
            pdfContent.push("\n");
            pdfContent.push(colObj);

            pdfContent.push({text:'INSTRUCTIONS FOR IMPLEMENTATION\n',style:'header'});
            pdfContent.push('\n');
            pdfContent.push([{text:'Details of variables to be passed on different pages:\n\n',style:'subheader'}]);

            var tableVars = [];
            var columnHeader = [{text:'Pages to be placed on',fillColor:'#0099e5',color:'white'}, {text:'Variable',fillColor:'#0099e5',color:'white'}, {text:'Value',fillColor:'#0099e5',color:'white'}, {text:'Optional',fillColor:'#0099e5',color:'white'}];
            tableVars.push(columnHeader);

            var depthVars = "";
            for(var ind in $scope.draggableObjects){
                if($scope.draggableObjects[ind].hasOwnProperty("depth") && $scope.draggableObjects[ind].hasOwnProperty("eventName")){
                    depthVars += $scope.draggableObjects[ind]["depth"]+' : '+ $scope.draggableObjects[ind]["eventName"] +'\n';
                }
            }
            tableVars.push([{ text: 'All pages',fillColor:'#f9fafc'},{text:'depth',fillColor:'#f9fafc'},{text:depthVars,fillColor:'#f9fafc'}, { text: 'N',fillColor:'#f9fafc'}]);

            var variableTrackObj = {};
            for(var ind in $scope.draggableObjects){
                for(var variable in $scope.draggableObjects[ind].containerEventParms){
                    if( $scope.draggableObjects[ind].containerEventParms[variable].hasOwnProperty("parmSourceType") && $scope.draggableObjects[ind].containerEventParms[variable].parmSourceType == 'custom')continue;

                    if($scope.draggableObjects[ind]["containerEventParms"][variable]["parmName"] in variableTrackObj){
                        variableTrackObj[$scope.draggableObjects[ind]["containerEventParms"][variable]["parmName"]]["page"].push($scope.draggableObjects[ind]["eventName"]);
                        variableTrackObj[$scope.draggableObjects[ind]["containerEventParms"][variable]["parmName"]]["description"].push($scope.draggableObjects[ind]["containerEventParms"][variable]["description"]);
                    }else{
                        variableTrackObj[$scope.draggableObjects[ind]["containerEventParms"][variable]["parmName"]] = {};
                        variableTrackObj[$scope.draggableObjects[ind]["containerEventParms"][variable]["parmName"]]["page"] = [$scope.draggableObjects[ind]["eventName"]];
                        variableTrackObj[$scope.draggableObjects[ind]["containerEventParms"][variable]["parmName"]]["description"] = [$scope.draggableObjects[ind]["containerEventParms"][variable]["description"]];
                    }
                }
            }

            for(var vObj in variableTrackObj){
                if(vObj == "depth")continue;
                tableVars.push([{ text: variableTrackObj[vObj]["page"].join(", "),fillColor:'#f9fafc'}, {text:vObj,fillColor:'#f9fafc'}, { text: variableTrackObj[vObj]["description"][0],fillColor:'#f9fafc' }, { text: 'N',fillColor:'#f9fafc'}]);
            }

            var tableContent = {

                table: {
                    widths: [115, 150, '*',45],
                    body:tableVars,
                    margin:[20,20,20,20]

                },
                layout: {
                    hLineWidth: function(i, node) {
                        return 0.1;
                    },
                    vLineWidth: function(i, node) {
                        return 0.1;
                    },
                    hLineColor: function(i, node) {
                        return '#d7d8d9';
                    },
                    vLineColor: function(i, node) {
                        return '#d7d8d9';
                    }
                }
            };



            pdfContent.push(tableContent);
            pdfContent.push('\n\n');
            pdfContent.push({text:'EXAMPLES:\n',style:'header'});
            pdfContent.push('\n');
            pdfContent.push({text:'Please note the examples below show possible values that get passed to our tag on certain page urls. You will have to insert appropriate macros/ variables in the tag so that the appropriate values get passed dynamically on all the pages.\n'});
            pdfContent.push('\n');
            for(var ind in $scope.draggableObjects){
                if($scope.draggableObjects[ind].hasOwnProperty("eventName") && $scope.draggableObjects[ind]["objScriptTag"] != undefined && $scope.draggableObjects[ind]["objScriptTag"] !=null){
                    var pageStrToPush = $scope.draggableObjects[ind].eventName;

                    pdfContent.push({text:'#'+(parseInt(ind)+1)+' Example of Retargeting tags on '+pageStrToPush,bold:'true'});
                    if($scope.draggableObjects[ind].hasOwnProperty("exampleUrl") && $scope.draggableObjects[ind]["exampleUrl"] != null ){
                        pdfContent.push('\n');
                        pdfContent.push({text:'Example URL: '+$scope.draggableObjects[ind].exampleUrl,fontSize:11});
                        pdfContent.push('\n');
                    }
                    var objScriptTab = 	{
                        fillColor:'#dddddd',
                        table: {
                            widths: ['*'],

                            body: [
                                [{text:$scope.draggableObjects[ind]["objScriptTag"],style:'code',margin:[15,20,15,15]}]
                            ]
                        },
                        layout: {
                            hLineWidth: function(i, node) {
                                return 1;
                            },
                            vLineWidth: function(i, node) {
                                return 1;
                            },
                            hLineColor: function(i, node) {
                                return '#f9fafc';
                            },
                            vLineColor: function(i, node) {
                                return '#f9fafc';
                            },
                        }
                    }
                    pdfContent.push(objScriptTab);
                    pdfContent.push('\n');
                }
            }
            //pdfContent.push({text:'FAQs\n\n',pageBreak: 'before', style:'header'});
            pdfContent.push({text:'FAQs\n', style:'header'});
            pdfContent.push('\n');
            pdfContent.push({text:'What happens after the pixels are implemented?\n',style:'subheader'});
            pdfContent.push('\n');
            pdfContent.push({text:'The pixel implementation is just the start of the retargeting process. After the implementation of the pixel, the following execution is expected:\n\n'});
            pdfContent.push({ol:['Sanity check by the AdElement technical and QA teams.','Sharing of the vector logo by you, and coding of creatives for dynamic, real-timerendering by AdElement.','Build-up of cookie pool data which will be utilised by the optimisation algorithm to retarget.','On-going campaign optimisation.']});
            pdfContent.push({text:'\nAs such, any hold-up at the pixel implementation stage becomes a serious bottleneck and delays the start of the campaign.\n\n'});
            pdfContent.push({text:'What all data is visible to AdElement using these pixels?\n\n',style:'subheader'});
            pdfContent.push({text:'The visit pixels capture site-data that would be available in the public domain anyway. This includes data regarding categories, products and pricing etc. It helps AdElement to generate dynamic creatives in real-time to be served to potential buyers.\n\n'});
            pdfContent.push({text:'In addition, the tags also help monitor cookie behaviour across the website, including categories browsed, number of pages visited, time spent on each product, products added to cart etc. These data points are used as an input to optimise the retargeting algorithm for identifying potential buyers, creation of behavioural segments, differential real-time bidding based on purchase potential etc.\n'});
            pdfContent.push({text:'These pixels also help us track when a conversion on the website has occurred. Other than that,it also records values of those variables that an advertiser passes on to us (product ID, product amount, transaction ID etc.). There are several reasons why it is important for us to track conversions:\n\n'});
            pdfContent.push({ol:['It allows the system to track the completion of a purchase cycle for that user. The learnings are then incorporated into the optimisation algorithm to better target those users who are likely to convert.','It helps us optimise on average order value.','It allows us to unpixel those buyers that have already converted, so that they are not served remarketing communication for a period of 30 days from the time of purchase (by default). This is to allow optimisation of new : repeat buyer ratio, but can be customised to decrease this time frame.\n\n']});
            pdfContent.push({text:'For any further technical questions, please do not hesitate to drop in a mail to support@adelement.com.'});
            var docDefinition = {
                header: function(){
                    return {
                        table: {
                            body: [
                                [
                                    {image: 'bot_head',width:94},
                                ]
                            ]
                        }, margin: [30,40],
                        layout: {
                            hLineWidth: function(i, node) {
                                return 1;
                            },
                            vLineWidth: function(i, node) {
                                return 1;
                            },
                            hLineColor: function(i, node) {
                                return 'white';
                            },
                            vLineColor: function(i, node) {
                                return 'white';
                            },
                        }

                    };
                },
                content: pdfContent,
                images:{
                    bot_head: imgBase64Str,
                    image1:image1,
                    image2:image2
                },
                pageSize: 'A4',
                pageMargins: [30,100,30,30],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true
                    },
                    subheader: {
                        fontSize: 14    ,
                        bold: true
                    },
                    code:{
                        fontSize:11,
                        font:'Courier'
                    }
                },
            };
            pdfMake.createPdf(docDefinition).download($scope.containerData.containerName.trim()+".pdf");
            $scope.containerData["containerEvents"] = $scope.draggableObjects;
            UpdateEvtExampleValues.update($scope.containerData);
        };
        $scope.downloadTextFile = function(){
            var textString = "";
            for(var ind in $scope.draggableObjects){
                if($scope.draggableObjects[ind].hasOwnProperty("eventName") && $scope.draggableObjects[ind]["objScriptTag"] != undefined && $scope.draggableObjects[ind]["objScriptTag"] !=null){
                    var pageStrToPush = $scope.draggableObjects[ind].eventName;
                    textString += '#'+(parseInt(ind)+1)+' Example of Retargeting tags on '+pageStrToPush;
                    textString += '\n\n';
                    if($scope.draggableObjects[ind].hasOwnProperty("exampleUrl") && $scope.draggableObjects[ind]["exampleUrl"] != null ){
                        textString+= 'Example URL: '+$scope.draggableObjects[ind].exampleUrl;
                        textString+= "\n\n";
                    }
                    textString += $scope.draggableObjects[ind]["objScriptTag"];
                    textString += "\n\n\n";
                }
            }
            var textData = new Blob([textString], {type: 'text/plain'});
            $scope.textFileHref = window.URL.createObjectURL(textData);
            $scope.textFileName = $scope.containerData.containerName.trim().replace(/[\s\.]/g,'_')+'.txt';
            console.log($scope.textFileHref);
        };


        function afterCloseCustomDepth(index){
            if($scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"] == null || $scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"].trim() =="") {
                $scope.containerData.containerEvents[index]["isCustomDepth"] =false;
                $scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"] = null;
            }else{
                try{
                    $window.eval($scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"]);
                }catch(e){
                    if(e.message != "ae_parms_kv is not defined" && e.message != "dataLayer is not defined"){
                        $scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"] = null;
                    }
                }
            }

            $scope.errFlag = false;
            $scope.errMsg = "";
        };
        $scope.afterSaveCustomDepth = function(index,dObj){
            if($scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"] != null && $scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"].trim() !=""){
                try{
                    $window.eval($scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"]);
                }catch(e){
                    if(e.message != "ae_parms_kv is not defined" && e.message != "dataLayer is not defined"){
                        $scope.errFlag = true;
                        $scope.errMsg = "Error in Javascript code snippet !  "+ e.message;
                        return false;
                    }
                }
                $scope.containerData.containerEvents[index]["isCustomDepth"] =true;
                dObj.closeThisDialog(0);

            }else{
                $scope.containerData.containerEvents[index]["isCustomDepth"] =false;
                $scope.containerData.containerEvents[index]["setDepthKvpairsSnippet"] = null;
                $scope.errFlag = true;
                $scope.errMsg = "Javascript code snippet should not be null !";
            }

        };
        $scope.addCustomDepth = function(objIndex){
            $scope.customDepthInd = objIndex;
            ngDialog.openConfirm({
                template: 'app/tag-manager/web/customDepthPopup.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain:false,
                scope:$scope,
                preCloseCallback:afterCloseCustomDepth(objIndex)
            });


        };

        $scope.audStatusChange = function(obj,flag){

            var onSuccessAudChange = function(response){
                $scope.recommendedAudiences.containerAudiences[$scope.recommendedAudiences.containerAudiences.indexOf(obj)]=response;
                addAudienceName();
            };
            var onFailureAudChange = function(response){
                console.log("failure")
            };
            var audStatus = {
                "advertiserId":$scope.containerData.advertiserId,
                "containerId":$scope.containerData.id
            };

            audStatus["depth"] = obj.depth;
            audStatus["excludeAudId"] = obj.excludeAudId;
            audStatus["audId"] = obj.id;
            audStatus["status"] = obj.status;
            audStatus["audienceName"] = obj.audienceName;
            audStatus["duration"] = obj.days;
            if("status" == flag){
                if($scope.recommendedAudiences.containerAudiences.indexOf(obj) > 0 && obj.status && !$scope.recommendedAudiences.containerAudiences[$scope.recommendedAudiences.containerAudiences.indexOf(obj) -1].status){
                    alert("activate previous audience");
                    obj.status=false;
                    return;
                }
            }else if("audName" == flag){
                addAudienceName();
            }
            SaveRecommendedAud.save(audStatus,onSuccessAudChange,onFailureAudChange);
        };


    })
    .controller('ContainerManageController', function ($scope,$stateParams,TagManagerCtrlData,ngDialog,ContainerTagPiggyBack,ContainerTpTagPiggyBack,GetTagData,FbEventVarsMapping,GetContainersTag,ContainerTpTagPiggyBackChangeStatus,$state,ContainerTpTagPiggyBackUpdateTag,GetContainerTagRulesData){
        $scope.cm = "ContainerManageController";
        $scope.showaddTag = false;
        $scope.tagStr = "";
        $scope.tagName="";
        $scope.conversionTagName="";
        $scope.conversionTag="";
        $scope.errFlag = false;
        $scope.errMsg = "";
        $scope.fbEvents= ['PageView','ViewContent','AddToCart','Purchase'];
        $scope.fbEventVars =  ['product_id','product_price','category_id','cart_amount','transaction_amount'];//{'fbparams':['value','currency','content_type','content_ids']}; //content type = product or product_group
        $scope.tagEditDisable = [];
        $scope.showCustomTagSave = [];
        $scope.eventMappingModel = {};
        $scope.varMappingModel = {};
        $scope.adNetworkChbx = {"otherthirdpartyTags":[]};
        $scope.appData ={"appUserListId":""};
        $scope.fbMappingObj = {"ViewContent":{"events":[],"parms":{"product_id":[],"product_price":""},
            "evtParm":[]},"AddToCart":{"events":[],"parms":{"product_id":[],"cart_amount":""},"evtParm":[]},
            "Purchase":{"events":[],"parms":{"product_id":[],"transaction_amount":""},"evtParm":[]}};
        $scope.saveCustomTagUpdate = [];

        //$scope.otherthirdpartyTags = [];
        var advertiserId = $stateParams.advId;
        var containerId = TagManagerCtrlData.getCtrlVar("containerId");
        //alert($scope.webAppType);
        $scope.adNetworkChbx["otherthirdparty"]=[];


        if($stateParams.hasOwnProperty("containerId") && $stateParams.containerId != undefined){
            var containerTagPromise = GetContainersTag.get({"advertiserId":advertiserId,"containerId":containerId});
            containerTagPromise.$promise.then(function(response){
                if("adx" in response["containerTags"]){
                    $scope.adNetworkChbx["adxchbx"]= true;
                }
                if("opxmarket" in response["containerTags"]){
                    $scope.adNetworkChbx["opxmarketchbx"] = true;
                }
                if("apnxmarket" in response["containerTags"]){
                    $scope.adNetworkChbx["apnxmarketchbx"] = true;
                }

                if("facebook" in response["containerTags"]){
                    $scope.adNetworkChbx["facebookchbx"] = true;
                }

                if("openrtb" in response["containerTags"]){
                    $scope.adNetworkChbx["openrtbchbx"]=true;
                }

//                var adNetworkSet = new Set();
                if("otherthirdparty" in response["containerTags"]){
                    $scope.containerEventsData = response["containerEventsList"];
                    $scope.containerTagRulesData = response["containerTagRulesList"];
                    $scope.containerTagRuleMappingsData = response["containerTagRuleMappingsList"];

                    $scope.containerEvents = [];
                    $scope.tagEventObject = {};
                    $scope.containerTagRuleMappingsData.forEach(function(containerTagRuleMappings) {
                        $scope.advId = containerTagRuleMappings.advertiserId;
                        $scope.containerID = containerTagRuleMappings.containerId;
                        $scope.containerTagId = containerTagRuleMappings.containerTagId;
                        $scope.containerTagRuleId = containerTagRuleMappings.containerTagRuleId;
                        $scope.containerTagRuleMappingsId = containerTagRuleMappings.id;
                        var containerTagRuleMappingObject = containerTagRuleMappings;
//                      if(containerTagRuleMappings.containerTagId==containerTagId){
                        $scope.tagEventObject[$scope.containerTagId]=[];
                        $scope.containerTagRuleMappingsData.forEach(function(containerTagRuleMappingData) {
                            $scope.containerTagRuleId1 = containerTagRuleMappingData.containerTagRuleId;
                            if($scope.containerTagId==containerTagRuleMappingData.containerTagId){
                                $scope.tagEventNames = [];
                                $scope.containerTagRulesData.forEach(function(containerTagRules) {
                                  if($scope.containerTagRuleId1==containerTagRules.id){
                                    var containerTagRulesObject = containerTagRules;
                                    var ruleName = containerTagRules.ruleName;
                                    $scope.depth = parseInt(ruleName.replace("depth","").replace("Filter",""));
                                    $scope.containerEventsData.forEach(function(containerEvent) {
                                        var containerEventData = containerEvent;
        //                                $scope.containerEvents.push(containerEvent.eventName)
                                        if($scope.depth==containerEvent.depth){
                                            $scope.eventName = containerEvent.eventName;
        //                                    $scope.tagEventNames.push($scope.eventName)
                                            $scope.tagEventObject[$scope.containerTagId].push($scope.eventName);
                                        }
                                    });
                                  }
                                });
                            }
                        });
                    });

                    if(response["containerTags"]["otherthirdparty"]){
                        response["containerTags"]["otherthirdparty"].forEach(function(obj) {
//                            if($scope.containerTagId==obj.id){

                            obj["tagEventNames"]=$scope.tagEventObject[obj.id];
//                                    adNetworkSet.add(obj);
                            $scope.adNetworkChbx["otherthirdparty"].push(obj);
//                            }
                        })
                    }
                    if(response["containerTags"]["adelement"]){
                        response["containerTags"]["adelement"].forEach(function(obj) {

                            obj["tagEventNames"]=$scope.tagEventObject[obj.id];
                            $scope.adNetworkChbx["otherthirdparty"].push(obj);
//                            }
                        })
                    }
                }
                else if ("adelement" in response["containerTags"]){
                    $scope.containerEventsData = response["containerEventsList"];
                    $scope.containerTagRulesData = response["containerTagRulesList"];
                    $scope.containerTagRuleMappingsData = response["containerTagRuleMappingsList"];

                    $scope.containerEvents = [];
                    $scope.tagEventObject = {};
                    $scope.containerTagRuleMappingsData.forEach(function(containerTagRuleMappings) {
                        $scope.advId = containerTagRuleMappings.advertiserId;
                        $scope.containerID = containerTagRuleMappings.containerId;
                        $scope.containerTagId = containerTagRuleMappings.containerTagId;
                        $scope.containerTagRuleId = containerTagRuleMappings.containerTagRuleId;
                        $scope.containerTagRuleMappingsId = containerTagRuleMappings.id;
                        var containerTagRuleMappingObject = containerTagRuleMappings;
//                      if(containerTagRuleMappings.containerTagId==containerTagId){
                        $scope.tagEventObject[$scope.containerTagId]=[];
                        $scope.containerTagRuleMappingsData.forEach(function(containerTagRuleMappingData) {
                            $scope.containerTagRuleId1 = containerTagRuleMappingData.containerTagRuleId;
                            if($scope.containerTagId==containerTagRuleMappingData.containerTagId){
                                $scope.tagEventNames = [];
                                $scope.containerTagRulesData.forEach(function(containerTagRules) {
                                  if($scope.containerTagRuleId1==containerTagRules.id){
                                    var containerTagRulesObject = containerTagRules;
                                    var ruleName = containerTagRules.ruleName;
                                    $scope.depth = parseInt(ruleName.replace("depth","").replace("Filter",""));
                                    $scope.containerEventsData.forEach(function(containerEvent) {
                                        var containerEventData = containerEvent;
        //                                $scope.containerEvents.push(containerEvent.eventName)
                                        if($scope.depth==containerEvent.depth){
                                            $scope.eventName = containerEvent.eventName;
        //                                    $scope.tagEventNames.push($scope.eventName)
                                            $scope.tagEventObject[$scope.containerTagId].push($scope.eventName);
                                        }
                                    });
                                  }
                                });
                            }
                        });
                    });

                    if(response["containerTags"]["otherthirdparty"]){
                        response["containerTags"]["otherthirdparty"].forEach(function(obj) {
//                            if($scope.containerTagId==obj.id){

                            obj["tagEventNames"]=$scope.tagEventObject[obj.id];
//                                    adNetworkSet.add(obj);
                            $scope.adNetworkChbx["otherthirdparty"].push(obj);
//                            }
                        })
                    }
                    if(response["containerTags"]["adelement"]){
                        response["containerTags"]["adelement"].forEach(function(obj) {

                            obj["tagEventNames"]=$scope.tagEventObject[obj.id];
                            $scope.adNetworkChbx["otherthirdparty"].push(obj);
//                            }
                        })
                    }

//                    $scope.adNetworkChbx["otherthirdparty"] = adNetworkSet;

//                    var containerTagRuleMappingObject = containerTagRuleMapping;
//                    if(response["containerTags"]["otherthirdparty"]){
//                        response["containerTags"]["otherthirdparty"].forEach(function(obj) {
//                            $scope.adNetworkChbx["otherthirdparty"].push(obj);
//                        })
//                    }
//                    if(response["containerTags"]["adelement"]){
//                        response["containerTags"]["adelement"].forEach(function(obj) {
//                            $scope.adNetworkChbx["otherthirdparty"].push(obj);
//                        })
//                    }
                }

                if("fbEvtMappingData" in response){
                    $scope.fbMappingObj = response["fbEvtMappingData"];
                }
            });



            // added by Snehal
            $scope.getContainerTagRulesData = function(){
                var containerTagRulesDataPromise = GetContainerTagRulesData.get({"advertiserId":advertiserId,"containerId":containerId});
                containerTagRulesDataPromise.$promise.then(function(response){
                    $scope.containerTagRulesData = response;
                    $scope.getContainerTagRuleWithMappingData();
                });
            }

            $scope.getContainerTagRuleWithMappingData = function(){
                $scope.containerTagRulesList = $scope.containerTagRulesData.containerTagRulesList;
                $scope.containerTagRuleMappingsList = $scope.containerTagRulesData.containerTagRuleMappingsList;
                $scope.containerEventsList = $scope.containerTagRulesData.containerEventsList;
            }

            $scope.getContainerTagRulesData();
            $scope.getTagEvents = function(){
                $scope.tagEventNames=[];
                $scope.containerEvents = [];
                $scope.containerTagRuleMappingsList.forEach(function(containerTagRuleMappings) {
                  if(containerTagRuleMappings.containerTagId==containerTagId){
                    $scope.containerTagRuleId = containerTagRuleMappings.containerTagRuleId;
                    $scope.containerTagRulesList.forEach(function(containerTagRules) {
                      if($scope.containerTagRuleId==containerTagRules.id){
                        var ruleName = containerTagRules.ruleName;
                        $scope.depth = parseInt(ruleName.replace("depth","").replace("Filter",""));
                        $scope.containerEventsList.forEach(function(containerEvent) {
                          $scope.containerEvents.push(containerEvent.eventName)
                          if($scope.depth==containerEvent.depth){
                            $scope.eventName = containerEvent.eventName;
                            $scope.tagEventNames.push($scope.eventName)
                          }
                        });
                      }
                    });
                  }
                });

                $scope.maintainContainerEvents.events=[];
                for(var l=0;l<$scope.tagEventNames.length;l++){
                    $scope.maintainContainerEvents.events.push($scope.tagEventNames[l])
                }
            }
//            $scope.getTagEvents();
        }

        $scope.piggybackCustomTag={
            "tagType":null,
            "advertiserId":advertiserId,
            "containerId":TagManagerCtrlData.getCtrlVar("containerId"),
            "tagName":"",
            "pgbackTag":"",
            "containerPlatform":$scope.webAppType,
            "depthOnPiggyback":[],
            "pgbackCustomTag": [],
            "tagId":null
        };

        $scope.maintainContainerEvents={};
        $scope.maintainContainerEvents["events"] = [];

        $scope.reloadRoute = function() {
            $state.reload();
        };

        $scope.piggybackTpTags= function(){
            $scope.piggybackCustomTag.tagType=null;
            $scope.errFlag = false;
            $scope.errMsg = "";
            $scope.piggybackCustomTag.tagName=null;
            $scope.piggybackCustomTag.pgbackTag=null;

            $scope.containerEvents = [];
            $scope.maintainContainerEvents["events"] = [];
            $scope.piggybackCustomTag.depthOnPiggyback = [];

            var getContainerEvents = TagManagerCtrlData.getCtrlVar("containerEvents");
            if(getContainerEvents != 'undefined' && getContainerEvents != null){
                for(var indx in getContainerEvents){
                    $scope.containerEvents.push(getContainerEvents[indx]["eventName"]);
                }
            }


            function onSuccessCustomTagPgbck(response){
                $scope.piggybackCustomTag.pgbackTag="";
                $scope.piggybackCustomTag.tagType=null;
                $scope.piggybackCustomTag.tagName="";
                $scope.adNetworkChbx["otherthirdparty"].push(response);
                console.log("success");
            };
            function onErrorCustomTagPgbck(){
                console.log("failure");
            };
            ngDialog.openConfirm({
                template: 'app/tag-manager/web/customTagPiggyBackPopup.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain:false,
                scope:$scope
            });
            $scope.saveCustomTag = function (dObj) {
                console.log('Modal promise resolved. Value: '+$scope.piggybackCustomTag.tagType);
                if($scope.piggybackCustomTag.tagType == null){
                    $scope.errFlag = true;
                    $scope.errMsg = "Plese Select Tag Type";
                    return false;
                }
                if($scope.piggybackCustomTag.pgbackTag == "" || $scope.piggybackCustomTag.tagName==""){
                    $scope.errFlag = true;
                    $scope.errMsg = "Tag Name or Tag can not be null.";
                    return false;
                }
                if($scope.piggybackCustomTag.pgbackTag.indexOf("img ") == -1 && $scope.piggybackCustomTag.pgbackTag.indexOf("script ") == -1){
                    $scope.errFlag = true;
                    $scope.errMsg = "Tag should be valid <img> or <script> tag.";
                    return false;
                }

                if($scope.maintainContainerEvents.events.length > 0 && getContainerEvents != 'undefined' && getContainerEvents != null){
                    for(var indx in getContainerEvents){
                        if($scope.maintainContainerEvents.events.indexOf(getContainerEvents[indx].eventName) != -1){
                            $scope.piggybackCustomTag.depthOnPiggyback.push(getContainerEvents[indx].depth);
                        }
                    }
                }

                ContainerTagPiggyBack.save($scope.piggybackCustomTag,onSuccessCustomTagPgbck,onErrorCustomTagPgbck);
                dObj.closeThisDialog(0);
                $scope.reloadRoute();

            };
            $scope.closeCustomPiggyBack = function(dObj) {
                console.log('Modal promise rejected. Reason: '+$scope.piggybackCustomTag.tagType);
                dObj.closeThisDialog(0);
            };

        };

        $scope.openConfirm = function (value,network,dispName) {
            var dispMsg="";
            var dataTosend = {
                "advertiserId":advertiserId,
                "containerId":TagManagerCtrlData.getCtrlVar("containerId"),
                "pgbackTag":network,
                "tagName":dispName,
                "pgOrRm":value,
                "appUserListId":$scope.appData.appUserListId,
                "tagType":"retargeting",
                "containerPlatform":$scope.webAppType

            };

            if(value){
                dispMsg = '<p>Are you sure you want to piggyback '+ dispName+' tag?</p>';
            }else{
                dispMsg = '<p>Are you sure you want to remove '+ dispName+' tag?</p>';
            }
            var textbox = "";
            if(network == "adx" && $scope.webAppType=="app" && value){
                textbox += '<br/><label>Enter Adx User List Id:</label> <input type="text" ng-model="appData.appUserListId" />';
            }

            ngDialog.openConfirm({
                template: dispMsg +textbox+
                '<div>' +
                '<button type="button" class="btn btn-default margin-lr10" ng-click="closeThisDialog(0)">No' +
                '<button type="button" class="btn btn-primary" ng-click="confirm(1)">Yes' +
                '</button></div>',
                className: 'ngdialog-theme-default',
                plain:true,
                scope:$scope
            }).then(function () {
                console.log('Modal promise resolved. Value: ');
                dataTosend["appUserListId"] = $scope.appData.appUserListId;
                piggyBackTpIntegratedTags(dataTosend);
            }, function () {
                console.log('Modal promise rejected. Reason: ');
            });
        };

        var fbPiggybackModal = function(){
            ngDialog.openConfirm({
                template:'app/tag-manager/web/fbPiggybackPopup.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain:false,
                scope:$scope
             });

            $scope.saveFbEvtParMapping = function(dObj){
                console.log($scope.fbMappingObj);
                console.log($scope.eventMappingModel);
                console.log($scope.varMappingModel);
                $scope.errFlag = false;
                $scope.errMsg = "";

                for(var evtIndx in $scope.fbMappingObj){
                    if($scope.fbMappingObj[evtIndx]["events"].length < 1 || $scope.fbMappingObj[evtIndx]["parms"]["product_id"].length < 0 ){
                        $scope.errFlag = true;
                        $scope.errMsg = "Please select events and variables.";
                        return false;
                    }
                }
                    var dataTosendFbMapping= {
                        "advertiserId":advertiserId,
                        "containerId":TagManagerCtrlData.getCtrlVar("containerId"),
                        "tagAdnetwork":"facebook",
                        "eventsParmMapping":$scope.fbMappingObj,
                        "containerPlatform":$scope.webAppType,
                        "pgbackTag":"facebook",
                        "piggybackOrRemoveFlag":$scope.adNetworkChbx.facebookchbx
                    };
                    function onSuccessFbMapping(response){
                        console.log(response);
                    };
                    function onErrorFbMapping(response){
                        console.log(response);
                    };
                FbEventVarsMapping.save(dataTosendFbMapping,onSuccessFbMapping,onErrorFbMapping);

                dObj.closeThisDialog(0);

            };
            $scope.closeFbMapping = function (dObj) {
                console.log("failure");
                dObj.closeThisDialog(0);
            };
        };

        $scope.onSelected = function(evt,evtArray){
            console.log(evt);

            $scope.fbMappingObj[evt]["evtParm"] = [];
            for(var evtInd in evtArray){
                $scope.fbMappingObj[evt]["evtParm"]=$scope.fbMappingObj[evt]["evtParm"].concat($scope.tagData["eventParmData"][evtArray[evtInd]]);
                $scope.fbMappingObj[evt]["evtParm"] = $scope.fbMappingObj[evt]["evtParm"].filter(function(item, pos) {
                    return $scope.fbMappingObj[evt]["evtParm"].indexOf(item) == pos;
                })
            }
        };

        $scope.fbPiggyBack = function(){
            var getDataPromise = GetTagData.get({advertiserId:advertiserId,containerId:TagManagerCtrlData.getCtrlVar("containerId"),vendor:"facebook"});
            getDataPromise.$promise.then(function(response){
                if(response['isFbpgb'] == false){

                    ngDialog.open({
                        template: '<p>Fb advertiser Mapping not found for the advertiser in interfaces.</p>',
                        plain:true,
                        className: 'ngdialog-theme-default'
                    });
                    return false;
                }else{
                    $scope.tagData = response;
                    fbPiggybackModal();
                }
                $scope.tagData = response;
                $scope.tagData["eventName"] = Object.keys(response["eventParmData"]);

                for(var evt in $scope.fbMappingObj){
                    $scope.fbMappingObj[evt]["evtParm"] = [];
                    for(var fbevt in $scope.fbMappingObj[evt]["events"]){
                        $scope.fbMappingObj[evt]["evtParm"]=$scope.fbMappingObj[evt]["evtParm"].concat($scope.tagData["eventParmData"][$scope.fbMappingObj[evt]["events"][fbevt]]);
                    }
                }


            });
        };

        var piggyBackTpIntegratedTags = function(dataTosend){
            var onSuccess = function(){
                console.log("tag piggybacked");
            };
            var onFailure = function(){
                console.log("error occured while piggybacking");
            };
            ContainerTpTagPiggyBack.save(dataTosend,onSuccess,onFailure);
        };

        // added by Snehal
        $scope.customTagEdit = function(index){
//            ContainerTpTagPiggyBackChangeStatus.update({"advertiserId":$scope.containerData.advertiserId,"containerId":$scope.containerData.id,"containerVersion":containerVersion},onSuccessRevert,onFailureRevert);
//            ContainerTpTagPiggyBackChangeStatus.update($scope.piggybackCustomTag,onSuccessCustomTagPgbck,onErrorCustomTagPgbck);
            $scope.tagEditDisable[index] = !$scope.tagEditDisable[index];
            $scope.showCustomTagSave[index] = true;
        }


        $scope.deleteCustomTag = function(index){
//            $scope.tagEditDisable[index] = !$scope.tagEditDisable[index];
//            $scope.piggybackCustomTag.pgbackCustomTag.splice(index, 1);
            var customTagName = $scope.adNetworkChbx.otherthirdparty[index].tagName;
            var customTagStatus = $scope.adNetworkChbx.otherthirdparty[index].status;
            ngDialog.openConfirm({
                template: 'app/tag-manager/web/customTagPiggybackDeletePopup.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain:false,
                scope:$scope,
                data: {
                    customTagName: customTagName,
                    customTagStatus: customTagStatus
                }
            });
            $scope.closeCustomPiggyBackDelete = function(dObj) {
//                $scope.adNetworkChbx.otherthirdparty[index].status = customTagStatus;
                dObj.closeThisDialog(0);
                $scope.reloadRoute();
            };

            $scope.confirmCustomPiggyBackDelete = function(dObj) {
                var onSuccess = function(){
                    console.log("piggyback tag status change");
                };
                var onFailure = function(){
                    console.log("error occured while changing status of piggyback tag");
                };
                $scope.piggybackCustomTagChangeStatus = $scope.adNetworkChbx.otherthirdparty[index];
                ContainerTpTagPiggyBackChangeStatus.update($scope.piggybackCustomTagChangeStatus,onSuccess,onFailure);
//                $scope.adNetworkChbx.otherthirdparty.splice(index, 1);
                dObj.closeThisDialog(0);
//                $scope.customTagStatus[index] = false;
                $scope.reloadRoute();
            };
        }

        $scope.saveCustomUpdatedTag = function(index){
            var onSuccess = function(){
                console.log("piggyback tag status change");
            };
            var onFailure = function(){
                console.log("error occured while changing status of piggyback tag");
            };

            $scope.piggybackCustomTagUpdate = $scope.adNetworkChbx.otherthirdparty[index];
            if($scope.piggybackCustomTag.pgbackCustomTag[index].includes("script")){
                $scope.piggybackCustomTagUpdate.script = $scope.piggybackCustomTag.pgbackCustomTag[index];
            }
            else{
                $scope.piggybackCustomTagUpdate.htmlContent = $scope.piggybackCustomTag.pgbackCustomTag[index];
            }
            ContainerTpTagPiggyBackUpdateTag.update($scope.piggybackCustomTagUpdate,onSuccess,onFailure);
        }



        $scope.piggybackTpTagsEdit= function(index){
            var piggybackCustomTagData = $scope.adNetworkChbx.otherthirdparty[index];
            var containerId = $scope.adNetworkChbx.otherthirdparty[index].containerId;
            var containerTagId = $scope.adNetworkChbx.otherthirdparty[index].id;
            var advertiserId = $scope.adNetworkChbx.otherthirdparty[index].advertiserId;

            $scope.tagEventNames=[];
            $scope.containerEvents = [];
            $scope.containerTagRuleMappingsList.forEach(function(containerTagRuleMappings) {
              if(containerTagRuleMappings.containerTagId==containerTagId){
                $scope.containerTagRuleId = containerTagRuleMappings.containerTagRuleId;
                $scope.containerTagRulesList.forEach(function(containerTagRules) {
                  if($scope.containerTagRuleId==containerTagRules.id){
                    var ruleName = containerTagRules.ruleName;
                    $scope.depth = parseInt(ruleName.replace("depth","").replace("Filter",""));
                    $scope.containerEventsList.forEach(function(containerEvent) {
                      $scope.containerEvents.push(containerEvent.eventName)
                      if($scope.depth==containerEvent.depth){
                        $scope.eventName = containerEvent.eventName;
                        $scope.tagEventNames.push($scope.eventName)
                      }
                    });
                  }
                });
              }
            });

            $scope.maintainContainerEvents.events=[];
            for(var l=0;l<$scope.tagEventNames.length;l++){
                $scope.maintainContainerEvents.events.push($scope.tagEventNames[l])
            }

            if(piggybackCustomTagData.tagType=="retargeting"){
                $scope.piggybackCustomTag.tagType="specific";
            }
            else if(piggybackCustomTagData.tagType=="conversion"){
                $scope.piggybackCustomTag.tagType="all";
            }
            else{
                $scope.piggybackCustomTag.tagType= piggybackCustomTagData.tagType;
            }

            $scope.piggybackCustomTag.tagName=piggybackCustomTagData.tagName;
            $scope.piggybackCustomTag.containerTagId=piggybackCustomTagData.id;

            if(piggybackCustomTagData.htmlContent){
                $scope.piggybackCustomTag.pgbackTag=piggybackCustomTagData.htmlContent;
            }else if(piggybackCustomTagData.script){
                $scope.piggybackCustomTag.pgbackTag=piggybackCustomTagData.script;
            }

            $scope.errFlag = false;
            $scope.errMsg = "";

            $scope.containerEvents = [];
            $scope.piggybackCustomTag.depthOnPiggyback = [];

            var getContainerEvents = TagManagerCtrlData.getCtrlVar("containerEvents");
            if(getContainerEvents != 'undefined' && getContainerEvents != null){
                for(var indx in getContainerEvents){
                    $scope.containerEvents.push(getContainerEvents[indx]["eventName"]);
                }
            }


            function onSuccessCustomTagPgbck(response){
                $scope.piggybackCustomTag.pgbackTag="";
                $scope.piggybackCustomTag.tagType=null;
                $scope.piggybackCustomTag.tagName="";
                $scope.adNetworkChbx["otherthirdparty"].push(response);
                console.log("success");
            };
            function onErrorCustomTagPgbck(){
                console.log("failure");
            };
            ngDialog.openConfirm({
                template: 'app/tag-manager/web/customTagPiggyBackPopup.html',
                className: 'ngdialog-theme-default custom-width-800',
                plain:false,
                scope:$scope,
                data: {
                    maintainContainerEvents: $scope.maintainContainerEvents
//                    customTagStatus: customTagStatus
                }
            });
            $scope.saveCustomTag = function (dObj) {
                console.log('Modal promise resolved. Value: '+$scope.piggybackCustomTag.tagType);
                if($scope.piggybackCustomTag.tagType == null){
                    $scope.errFlag = true;
                    $scope.errMsg = "Plese Select Tag Type";
                    return false;
                }
                if($scope.piggybackCustomTag.pgbackTag == "" || $scope.piggybackCustomTag.tagName==""){
                    $scope.errFlag = true;
                    $scope.errMsg = "Tag Name or Tag can not be null.";
                    return false;
                }
                if($scope.piggybackCustomTag.pgbackTag.indexOf("img ") == -1 && $scope.piggybackCustomTag.pgbackTag.indexOf("script ") == -1){
                    $scope.errFlag = true;
                    $scope.errMsg = "Tag should be valid <img> or <script> tag.";
                    return false;
                }

                if($scope.maintainContainerEvents.events.length > 0 && getContainerEvents != 'undefined' && getContainerEvents != null){
                    for(var indx in getContainerEvents){
                        if($scope.maintainContainerEvents.events.indexOf(getContainerEvents[indx].eventName) != -1){
                            $scope.piggybackCustomTag.depthOnPiggyback.push(getContainerEvents[indx].depth);
                        }
                    }
                }

                ContainerTagPiggyBack.save($scope.piggybackCustomTag,onSuccessCustomTagPgbck,onErrorCustomTagPgbck);
                dObj.closeThisDialog(0);
                $scope.reloadRoute();
            };
            $scope.closeCustomPiggyBack = function(dObj) {
                console.log('Modal promise rejected. Reason: '+$scope.piggybackCustomTag.tagType);
                dObj.closeThisDialog(0);
            };

        };
    });
