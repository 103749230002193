/**
 * Created by maitray on 18/5/16.
 */
angular.module('adelementAdminApp')
    .factory('dataTableInstance',function($http){
        var instance = {};
        var self = this;
        return{
            setDataTable: function (datatableinstance) {
                self.instance = datatableinstance;
            },
            getDataTable: function(){
                return self.instance;
            }
        }
    });
