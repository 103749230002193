/**
 * Created by rahul on 5/9/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .factory('GetUserManagement', function ($resource) {
        return $resource('api/getusermgt', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('UserDisable', function ($resource) {
        return $resource('api/userdisable/:id', {}, {
            'update': { method:'PUT'},
        });
    })
    .factory('PasswordResetInitById', function ($resource) {
        return $resource('api/resetpassword', {}, {
            'update': { method:'PUT'},
        });
    });
