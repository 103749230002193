(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('entity', {
            abstract: true,
            parent: 'app',
            views: {
                'content@': {
                    templateUrl: 'app/components/custom/app.html',
                    controller: 'DashboardController'
                }
            },
            resolve: {
                currentUserX: ['Principal', function(Principal) {
                    return Principal.identity();
                }]

            }
        });
    }
})();
