'use strict';

angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('register2', {
                parent: 'account',
                url: '/register2',
                data: {
                    authorities: [],
                    pageTitle: 'register2.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/register2/register2.html',
                        controller: 'RegisterController2'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register2');
                        return $translate.refresh();
                    }]
                }
            });
    });
