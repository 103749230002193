'use strict';

angular.module('adelementAdminApp')
    .controller('NotificationsDetailController', function ($scope, $rootScope, $stateParams, entity, Notifications) {
        $scope.notifications = entity;
        $scope.load = function (id) {
            Notifications.get({id: id}, function(result) {
                $scope.notifications = result;
            });
        };
        var unsubscribe = $rootScope.$on('adelementAdminApp:notificationsUpdate', function(event, result) {
            $scope.notifications = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
