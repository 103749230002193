/**
 * Created by rahul on 24/6/16.
 */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('container-create', {
                parent: 'entity',
                url: '/container/{webApp}/{advId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/tag-manager/web/tag-manager.html',
                controller: 'TagManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('container-edit', {
                parent: 'entity',
                url: '/container/{webApp}/{advId}/{containerId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/tag-manager/web/tag-manager.html',
                controller: 'TagManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            // added by Snehal
            .state('container-create-woadv', {
                parent: 'entity',
                url: '/container/{webApp}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.tag-manager-create.title'
                },
                templateUrl: 'app/tag-manager/web/tag-manager.html',
                controller: 'TagManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });

    });
