(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$sessionStorage', '$timeout', 'Auth'];

    function LoginController ($rootScope, $state, $sessionStorage, $timeout, Auth) {
        var vm = this;

        vm.authenticationError = false;
        // vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        // function cancel () {
        //     vm.credentials = {
        //         username: null,
        //         password: null,
        //         rememberMe: true
        //     };
        //     vm.authenticationError = false;
        //     $uibModalInstance.dismiss('cancel');
        // }

        function login (event) {
            event.preventDefault();
            if(vm.loginForm.$valid) {
                Auth.login({
                    username: vm.username,
                    password: vm.password,
                    rememberMe: vm.rememberMe
                }).then(function () {
                    vm.authenticationError = false;
                    // $uibModalInstance.close();
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('home');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is succesful, go to stored previousState and clear previousState
                    if ($sessionStorage.previousStateName) {
                        var previousStateName = $sessionStorage.previousStateName;
                        var previousStateParams = $sessionStorage.previousStateParams;
                        delete $sessionStorage.previousStateName;
                        delete $sessionStorage.previousStateParams;
                        $state.go(previousStateName, previousStateParams);
                    }
                    else {
                        $state.go('dashboard.agency');
                    }
                }).catch(function () {
                    vm.authenticationError = true;
                });
            }
            else{
                vm.loginForm.account_email.$dirty = true;
                vm.loginForm.account_password.$dirty = true;
            }
        }

        function register () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
