'use strict';

angular.module('adelementAdminApp').controller('NotificationsDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Notifications',
        function($scope, $stateParams, $uibModalInstance, entity, Notifications) {

        $scope.notifications = entity;
        $scope.load = function(id) {
            Notifications.get({id : id}, function(result) {
                $scope.notifications = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('adelementAdminApp:notificationsUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.notifications.id != null) {
                Notifications.update($scope.notifications, onSaveSuccess, onSaveError);
            } else {
                Notifications.save($scope.notifications, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForTimestamp = {};

        $scope.datePickerForTimestamp.status = {
            opened: false
        };

        $scope.datePickerForTimestampOpen = function($event) {
            $scope.datePickerForTimestamp.status.opened = true;
        };
}]);
