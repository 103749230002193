/**
 * Created by RahulD on 3/5/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .controller('DemoRequestsController', function ($scope,$compile,GetDemoRequests,$timeout,DTOptionsBuilder,dataTableInstance,$http,DTColumnBuilder,SocialService) {
        $scope.panelids = ['demo-requests'];
        $scope.DemoRequests = [];
        $scope.dtInstance = {};
        $scope.timeSchedulingInfo={};

        function createdRow(row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        }
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('paging',true)
            .withOption('order',[])
            .withDisplayLength(50)
            .withOption('createdRow', createdRow)
            .withOption('ajax', {
                url: "api/getdemorequests",
                type:"POST",
                headers: {"X-CSRF-TOKEN":SocialService.getCSRF()}
             })
            .withFixedHeader({
                 bottom: true,
                 headerOffset:49
             }).withDOM('frtipl')
             .withDataProp('data')
             .withOption('processing', true)
             .withOption('serverSide', true)
             .withPaginationType('full_numbers');

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('Id'),
            DTColumnBuilder.newColumn('userName').withTitle('User Name').renderWith(function(data,type,row) {
                                   var resVal;
                                   if(row.hasOwnProperty("createdUserId") && row.approveStatus == "approved" && row.createdUserId !=""){
                                       resVal = '<a ui-sref="userCreate.useredit({id:'+row.createdUserId+'})">'+data+'</a>';
                                   }else{
                                       resVal = '<a ui-sref="userCreate.prospective({id:'+row.id+'})">'+data+'</a>';
                                   }
                                   return resVal;
                            }),
            DTColumnBuilder.newColumn('companyName').withTitle('Company Name'),
            DTColumnBuilder.newColumn('webSiteName').withTitle('Website Url/App Info').renderWith(function(data,type,row) {
                                    var websiteUrl = data.startsWith('http')?data:'http://'+data;
                                    var appUsersSpan = "";
                                    if(row.appUrl !=null){
                                        appUsersSpan = row.appUrl;
                                        if(row.appUsers !=null){
                                            appUsersSpan += '<br/><span><i class="fa fa-users" aria-hidden="true"></i>'+row.appUsers+'</span><br/>';
                                        }
                                    }
                                    var visitorsSpan = "";
                                    if(row.websiteVisitors !=null){
                                        visitorsSpan = '<br/><span><i class="fa fa-users" aria-hidden="true"></i> '+row.websiteVisitors+'</span>'
                                    }

                                 var resVal = '<a href="'+websiteUrl+' " target="_blank">'+data+'</a>'+visitorsSpan+appUsersSpan;
                                 return resVal;
                            }),
            DTColumnBuilder.newColumn('email').withTitle('Email'),
            DTColumnBuilder.newColumn('demoSchedulingInfo').withTitle('Sugested Time').renderWith(function(data,type,row) {
                                     var resVal = "";
                                     if(data !=null){
                                        var toJson =  eval('(' + data + ')');
                                        for(var index in toJson){
                                         resVal = toJson[index].date.split("T")[0] + " " + toJson[index].time;
                                        }
                                     }
                                     return resVal;

                              }),
            DTColumnBuilder.newColumn('timezone').withTitle('Timezone'),
            DTColumnBuilder.newColumn('approveStatus').withTitle('Status').renderWith(function(data,type,row) {
                return data.toUpperCase();
            }),
        ];

        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }
        $scope.check = function (data) {
            var jsonData = JSON.parse(data);
            var template = "";
            for(var index in jsonData){
                template += jsonData[index].date.split("T")[0]+" "+jsonData[index].time;
            }
            return jsonData;
            console.log(data);
        };

        $scope.initRefresh = function () {
            $timeout(function(){$scope.$emit('panel-refresh', $scope.panelids);}, 50);
        };
        $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
            }
        });
});
