'use strict';

angular.module('adelementAdminApp')
    .controller('NotificationsController', function ($scope, $state, Notifications, ParseLinks,DateUtils,MarkAsReadOne) {

        $scope.notifications = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            Notifications.getforuser(function(result, headers) {
                $scope.notifications = result;
            });
        };


        $scope.markAsRead = function(notificationObj,parNotObj){
            var successStatusUpdate = function(response){
                if(notificationObj.status == "active"){
                    notificationObj.status = "read"
                    parNotObj.readCount++;
                    parNotObj.activeCount--;
                }
                notificationObj.commentShowHide=false;
            };
            var failureStatusUpdate = function(response){

            };
            MarkAsReadOne.update({id:notificationObj.id,comment:notificationObj.comment},successStatusUpdate,failureStatusUpdate);
        };

        function makeid(){
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for( var i=0; i < 5; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        }

        $scope.initRandomObj = function(){
            return makeid();
        }

        $scope.formatedSampleLog = function(jsonObj,parObj,ranstr){
            if(parObj.contNotf[ranstr+'_dflag'] == undefined){
                parObj.contNotf[ranstr+'_dflag'] = true;
            }else{
                parObj.contNotf[ranstr+'_dflag'] = !parObj.contNotf[ranstr+'_dflag'];
            }
            var jsonString = atob(jsonObj);
//            console.log(jsonString);
            var samplelogObject = eval('(' + jsonString + ')');
            console.log(samplelogObject);
            //parObj.contNotf[ranstr]=[];
            var stringDiv="<div class='sample-log-wrapper'> <div class='sample-log-inner row'>";
            var logUrl,intLogUrl,highlight;
            //parObj.contNotf[ranstr] = samplelogObject;
            for(var j=0;j<samplelogObject.length;j++){
                var obj = samplelogObject[j];
                if (obj.hasOwnProperty("highlightFlag")){
                      stringDiv+=("<div class='log-div col-md-4'> <div class='card_box missing-field-box'> <div class='sample-log-title'>Sample log "+(j+1)+"</div>");
                }
                else{
                      stringDiv+=("<div class='log-div col-md-4'> <div class='card_box'> <div class='sample-log-title'>Sample log "+(j+1)+"</div>");
                }
                         for (var prop in obj) {
                                if(prop == "sampleLogUrl"){
                                    intLogUrl=("<div class='sample-log-url'> <a href="+ obj[prop]+" target='_blank' class='log-url'> Page URL </a> </div>");
                                    //logUrl+=("<div class='log-url-add'>"+"<span>"+obj[prop]+"</span>"+"</div>");
                                    continue;
                                }
                                if(prop=="highlightFlag")
                                {
                                    highlight=("<div class='missing-field-box'></div>")
                                    stringDiv+=highlight
                                    continue
                                }
                                stringDiv+=("<span class='log-key-val'> <strong title='"+prop+"'>"+prop+" : </strong><div class='log-val'>"+decodeURIComponent(obj[prop])+"</div></span><div class='clr'></div>");
                         }
                       stringDiv+=(intLogUrl+"</div> </div>");
                       //console.log(stringDiv);
                       if(j==2 || j==4){
                            stringDiv+=("<div class='clr'></div>");
                      }
            }
            stringDiv+="</div></div>";
//            console.log(stringDiv);
            //console.log(logUrl);
            parObj.contNotf[ranstr]=stringDiv;
        }

        // $scope.loadPage = function(page) {
        //     $scope.page = page;
        //     $scope.loadAll();
        // };
        $scope.loadAll();


        // $scope.refresh = function () {
        //     $scope.loadAll();
        //     $scope.clear();
        // };

        // $scope.clear = function () {
            // $scope.notifications = {
            //     userId: null,
            //     role: null,
            //     intendedUsers: null,
            //     uiUrl: null,
            //     url: null,
            //     data: null,
            //     method: null,
            //     actionable: null,
            //     inlineActionable: null,
            //     description: null,
            //     timestamp: null,
            //     status: null,
            //     id: null
            // };
        // }
    })
    .filter('typeof', function() {
      return function(obj) {
        return typeof obj;
      };
    })
    .filter('to_trusted', ['$sce','$compile', function($sce,$compile){
            return function(text) {
//                return $sce.trustAsHtml(text);
                return $compile(angular.element(text).contents())($scope);
            };
        }])

    .directive('compileHtml', function($compile){
          return {
                    restrict: 'A',
                    link: function (scope, element, attrs) {
                        scope.$watch(function () {
                            return scope.$eval(attrs.compileHtml);
                        }, function (value) {
                            element.html(value);
                            $compile(element.contents())(scope);
                        });
                    }
                };


    });
