'use strict';

angular.module('adelementAdminApp')
    .controller('RoleMappingDetailController', function ($scope, $rootScope, $stateParams, entity, RoleMapping) {
        $scope.roleMapping = entity;
        $scope.load = function (id) {
            RoleMapping.get({id: id}, function(result) {
                $scope.roleMapping = result;
            });
        };
        var unsubscribe = $rootScope.$on('adelementAdminApp:roleMappingUpdate', function(event, result) {
            $scope.roleMapping = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
