/**
 * Created by maitray on 21/3/16.
 */
angular.module('adelementAdminApp')
    .factory('Reports',function($http){
        return{
            getAccountLevelReport: function(url){
                // var urldatequery = "api/report/account/"+dateStart+"/"+dateEnd+"/"+currency+"/"+dayorweek+"/";
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getCampaignLevelReport : function(url){
                // var urldatequery = "api/report/account/"+advertiserId+"/"+dateStart+"/"+dateEnd+"/"+dayorweeek+"/";
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getAdgroupLevelReport : function(url){
                // var urldatequery = "api/report/account/"+advertiserId+"/"+dateStart+"/"+dateEnd+"/"+dayorweeek+"/";
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getAdLevelReport : function(url){
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getPlacementLevelReport : function(url){
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getMoreAds : function(url){
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getPlacementPieChartData : function(url){
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getUserPreferences : function (userId,type) {
                var url = "/api/getUserPreferences/"+userId+"/"+type;
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            setPreferences : function(userId,type,data){
                var url = "/api/setUserPreferences/"+userId+"/"+type;
                return $http.post(url,data).then(function (response) {
                    return response.data;
                });
            },
            getBudget : function(advertiserId,campaignId){
                var url = "api/getBudget/"+advertiserId+"/"+campaignId;
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            saveBudget : function(data,advertiserId,campaignId){
                var url = "api/setBudget/"+advertiserId+"/"+campaignId;
                return $http.post(url,data).then(function (response) {
                    return response.data;
                });
            },
            deleteBudget : function(id,advertiserId,campaignId){
                var url = "api/deleteBudget/"+advertiserId+"/"+campaignId+"/"+id;
                return $http.delete(url).then(function (response) {
                    return response.data;
                });
            },
            getCampaign : function(advertiserId,campaignId){
                var url = "api/campaign-manager/get/campaign/"+advertiserId+"/"+campaignId;
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            }
        }
    });
