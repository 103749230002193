'use strict';

angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('prospectiveUsers', {
                parent: 'entity',
                url: '/prospectiveUserss',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'adelementAdminApp.prospectiveUsers.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/prospectiveUsers/prospectiveUserss.html',
                        controller: 'ProspectiveUsersController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prospectiveUsers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('prospectiveUsers.detail', {
                parent: 'entity',
                url: '/prospectiveUsers/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'adelementAdminApp.prospectiveUsers.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/prospectiveUsers/prospectiveUsers-detail.html',
                        controller: 'ProspectiveUsersDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prospectiveUsers');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProspectiveUsers', function($stateParams, ProspectiveUsers) {
                        return ProspectiveUsers.get({id : $stateParams.id});
                    }]
                }
            })
            .state('prospectiveUsers.new', {
                parent: 'prospectiveUsers',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospectiveUsers/prospectiveUsers-dialog.html',
                        controller: 'ProspectiveUsersDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    firstName: null,
                                    lastName: null,
                                    email: null,
                                    phoneNo: null,
                                    country: null,
                                    city: null,
                                    companyName: null,
                                    userTitle: null,
                                    websiteUrl: null,
                                    monthlyWebsiteVisitors: null,
                                    appUrl: null,
                                    monthlyAppUsers: null,
                                    prefferedTime: null,
                                    timeSlot: null,
                                    timezone: null,
                                    message: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('prospectiveUsers', null, { reload: true });
                    }, function() {
                        $state.go('prospectiveUsers');
                    })
                }]
            })
            .state('prospectiveUsers.edit', {
                parent: 'prospectiveUsers',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospectiveUsers/prospectiveUsers-dialog.html',
                        controller: 'ProspectiveUsersDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['ProspectiveUsers', function(ProspectiveUsers) {
                                return ProspectiveUsers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('prospectiveUsers', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('prospectiveUsers.delete', {
                parent: 'prospectiveUsers',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prospectiveUsers/prospectiveUsers-delete-dialog.html',
                        controller: 'ProspectiveUsersDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['ProspectiveUsers', function(ProspectiveUsers) {
                                return ProspectiveUsers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('prospectiveUsers', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
