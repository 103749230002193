/**
 * Created by adelement on 5/5/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('advertiser-add', {
                parent: 'entity',
                url: '/advertiseradd',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.advertiser-add.title'
                },
                templateUrl: 'app/admin/advertiser-add/advertiser-add.html',
                controller: 'AdvertiserAddController',
                resolve:{
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('advertiser-edit', {
                parent: 'entity',
                url: '/advertiseredit/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.advertiser-edit.title'
                },
                templateUrl: 'app/admin/advertiser-add/advertiser-add.html',
                controller: 'AdvertiserAddController',
                resolve:{
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    });

