/**
 * Created by rahul on 6/9/16.
 */
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('audience-mgt', {
                parent: 'entity',
                url: '/audiencemgt',
                data: {
                    authorities: [],
                    pageTitle:'adelementAdminApp.pagetitles.audience-mgt.title'
                },
                templateUrl: 'app/audience-management/audience-mgt.html',
                controller: 'AudienceMgtController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        return $translate.refresh();
                    }]
                }
            });
    });

