/**
 * Created by rahul on 24/6/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .factory('CreateAdvertiserTags', function ($resource) {
        return $resource('api/container/create', {}, {
        });
    })
    .factory('GetDRTContainerTemplate', function ($resource) {
        return $resource('api/container/getdrttemplates/:webApp/:advId', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('GetContainerTemplateData', function ($resource) {
        return $resource('api/container/gettemplatedata/:containerTemplateId', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('CreateSrtContainer', function ($resource) {
        return $resource('api/container/savesrtcontainer', {}, {
        });
    })
    .factory('TagManagerCtrlData', function () {
        var ctrlData = {};
        var addCtrlVar = function(varKey,varData){
            ctrlData[varKey] = varData;
        };
        var getCtrlVar = function(varKey){
            return ctrlData[varKey];
        };

        return{
            addCtrlVar : addCtrlVar,
            getCtrlVar : getCtrlVar
        }
    })
    .factory('ContainerTagPiggyBack', function ($resource) {
        return $resource('api/container/piggybacktag', {}, {
        });
    })
    .factory('ContainerTpTagPiggyBack', function ($resource) {
        return $resource('api/container/piggybacktptag', {}, {
        });
    })
    .factory('GetTagData', function ($resource) {
        return $resource('api/container/tagdata/:advertiserId/:containerId/:vendor', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('FbEventVarsMapping', function ($resource) {
        return $resource('api/container/fbeventvarsmapping', {}, {
        });
    })
    .factory('GetCreatedContainerData', function ($resource) {
        return $resource('api/container/getcreatedcontainer/:advertiserId/:containerId/:webApp', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('GetContainersTag', function ($resource) {
        return $resource('api/container/getcontainerstag/:advertiserId/:containerId', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('UpdateEvtExampleValues', function ($resource) {
        return $resource('api/container/updateevtexamplevalues', {}, {
            'update': { method:'PUT' }
        });
    })

    .service("DraggableObjManupulate",function(){
        var draggableObject = null;

        return{
            getDraggableObj : function(){
                return draggableObject;
            },
            setDraggableObj: function(dobjValue,index,obj){
                //a   c   d   e   b   f       b->e left shift ... left-right
                //0   1   2   3   4

                //a   b   c   d   e   f       input
                //0   1   2   3   4   5

                //a   e   b   c   d   f       e->b right shift ... right-left
                //0   1   2   3   4   5
                var otherObj = dobjValue[index];
                var otherIndex = dobjValue.indexOf(obj);

                var objTo = dobjValue[index];
                var objToMove =  dobjValue[otherIndex];

                var copiedObj = angular.copy(dobjValue);

                if(index > otherIndex){
                    for(var ind in dobjValue){
                        if(ind >= otherIndex) {
                            var incInd = parseInt(ind) + 1;
                            dobjValue[ind] = angular.copy(dobjValue[incInd]);
                            if(typeof dobjValue[ind] !== 'undefined' &&  typeof copiedObj[ind] !== 'undefined') {
                                dobjValue[ind]["depth"] = copiedObj[ind]["depth"];
                            }
                        }
                        if(index == ind){
                            objToMove["depth"] = copiedObj[ind]["depth"];
                            dobjValue[ind] = objToMove;
                            break;
                        }
                    }
                    //console.log("##############left shift##################");
                }else{
                    objToMove["depth"] = objTo["depth"];
                    for(var ind=(dobjValue.length-1);ind>=0; ind--){
                        if(ind <= otherIndex){
                            var decInd = parseInt(ind)-1;
                            dobjValue[ind] = angular.copy(dobjValue[decInd]);
                            if(typeof dobjValue[ind] !== 'undefined' &&  typeof copiedObj[ind] !== 'undefined') {
                                dobjValue[ind]["depth"] = copiedObj[ind]["depth"];
                            }
                        }
                        if(ind == index){
                            objToMove["depth"] = copiedObj[ind]["depth"];
                            dobjValue[ind] = objToMove;
                            break;
                        }
                    }
                    //console.log("##############right shift#####################");
                }
                draggableObject = dobjValue;
            }
        }

    })

    .service("DraggableObjDepthChange",function(){
        var draggableObject = null;
        return{
            getObjDepthChange : function(){
                return draggableObject;
            },
            setObjDepthChange : function(dobjValue,copyDraggableObj,maintainPreviousDepth,type,parIndex,index,obj){
                if(obj.$data === null){
                    alert("depth should not be null");
                    dobjValue[parIndex]["depth"] = angular.copy(copyDraggableObj[parIndex]["depth"]);
                }else{
                    var diffr = 0;
                    if(parIndex != 0 && dobjValue[parIndex]["depth"] < dobjValue[parIndex - 1]["depth"]){
                        alert("depth should be greater than previous depth");
                        dobjValue[parIndex]["depth"] = maintainPreviousDepth[parIndex];
                    }else if(parIndex != 0 && dobjValue[parIndex]["depth"] == dobjValue[parIndex - 1]["depth"]){
                        if(copyDraggableObj[parIndex]["depth"] > dobjValue[parIndex]["depth"]){
                            diffr = copyDraggableObj[parIndex]["depth"] - dobjValue[parIndex]["depth"];
                        }else{
                            diffr = dobjValue[parIndex]["depth"] - copyDraggableObj[parIndex]["depth"];
                        }
                        for(var indx in dobjValue){
                            if(indx > parIndex){
                                dobjValue[indx]["depth"] =  dobjValue[indx]["depth"] - diffr;
                                copyDraggableObj[indx]["depth"] = angular.copy(dobjValue[indx]["depth"]);
                            }
                        }
                        copyDraggableObj[parIndex]["depth"] = angular.copy(obj.$data);
                        //console.log(copyDraggableObj);
                    }else if(parIndex != 0 && dobjValue[parIndex]["depth"] > dobjValue[parIndex - 1]["depth"]){
                        if(copyDraggableObj[parIndex]["depth"] > dobjValue[parIndex]["depth"]){
                            diffr = copyDraggableObj[parIndex]["depth"] - dobjValue[parIndex]["depth"];
                            diffr = -(diffr);
                        }else{
                            diffr = dobjValue[parIndex]["depth"] - copyDraggableObj[parIndex]["depth"];
                        }
                        for(var indx in dobjValue){
                            if(indx > parIndex){
                                dobjValue[indx]["depth"] =  dobjValue[indx]["depth"] + diffr;
                                copyDraggableObj[indx]["depth"] = angular.copy(dobjValue[indx]["depth"]);
                            }
                        }
                        copyDraggableObj[parIndex]["depth"] = angular.copy(obj.$data);
                        //console.log(copyDraggableObj);
                    }else if(parIndex == 0){
                        if(dobjValue[parIndex]["depth"] < copyDraggableObj[parIndex]["depth"]){
                            if(copyDraggableObj[parIndex]["depth"] > dobjValue[parIndex]["depth"]){
                                diffr = copyDraggableObj[parIndex]["depth"] - dobjValue[parIndex]["depth"];
                            }else{
                                diffr = dobjValue[parIndex]["depth"] - copyDraggableObj[parIndex]["depth"];
                            }
                            for(var indx in dobjValue){
                                if(indx > parIndex){
                                    dobjValue[indx]["depth"] =  dobjValue[indx]["depth"] - diffr;
                                    copyDraggableObj[indx]["depth"] = angular.copy(dobjValue[indx]["depth"]);
                                }
                            }
                            copyDraggableObj[parIndex]["depth"] = angular.copy(obj.$data);
                            //console.log(copyDraggableObj);
                        }else if(dobjValue[parIndex]["depth"] > copyDraggableObj[parIndex]["depth"]){
                            if(copyDraggableObj[parIndex]["depth"] > dobjValue[parIndex]["depth"]){
                                diffr = copyDraggableObj[parIndex]["depth"] - dobjValue[parIndex]["depth"];
                            }else{
                                diffr = dobjValue[parIndex]["depth"] - copyDraggableObj[parIndex]["depth"];
                            }
                            for(var indx in dobjValue){
                                if(indx > parIndex){
                                    dobjValue[indx]["depth"] =  dobjValue[indx]["depth"] + diffr;
                                    copyDraggableObj[indx]["depth"] = angular.copy(dobjValue[indx]["depth"]);
                                }
                            }
                            copyDraggableObj[parIndex]["depth"] = angular.copy(obj.$data);
                            //console.log(copyDraggableObj);

                        }
                    }


                }
                dobjValue[parIndex].editStage = false;
                draggableObject  = dobjValue;
            }
        }
    })
    .factory('GetAppTpSdk', function ($resource) {
        return $resource('api/container/app/gettpsdks', {}, {
            'query': { method: 'GET', isArray: true}
        });
    })
    .factory('GetTpSdkMacroMapping', function ($resource) {
        return $resource('api/container/app/gettpsdkmacro/:tpSdkId', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('SaveRecommendedAud', function ($resource) {
        return $resource('api/audience/saverecoaud', {}, {
        });
    })
    .factory('GetVersionedContainerData', function ($resource) {
        return $resource('api/container/getcontainerversion/:advertiserId/:containerId/:containerVersion', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('GetContainerVersions', function ($resource) {
        return $resource('api/container/getcontainerversionarray/:advertiserId/:containerId', {}, {
            'get': {
                method: 'GET',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('RevertToContainerVersion', function ($resource) {
        return $resource('api/container/reverttoversion', {}, {
            'update': { method:'PUT' }
        });
    })
    // added by Snehal
    .factory('GetAdvertiserIDName', function ($resource) {
            return $resource('api/aeadvertisersidname', {}, {
                'query': { method: 'GET', isArray: true},
            })
    })
    .factory('ContainerTpTagPiggyBackChangeStatus', function ($resource) {
        return $resource('api/container/piggybacktptagchangestatus', {}, {
            'update': { method:'PUT' }
        });
    })
    .factory('ContainerTpTagPiggyBackUpdateTag', function ($resource) {
        return $resource('api/container/piggybacktptagupdate', {}, {
            'update': { method:'PUT' }
        });
    })
    .factory('GetContainerTagRulesData', function ($resource) {
        return $resource('api/container/getcontainerstagrule/:advertiserId/:containerId', {}, {
            'get': {
                method: 'GET',
//                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    });

