/**
 * Created by adelement on 21/4/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .controller('UserMgtController', function ($scope,$compile,GetUserManagement,UserDisable,PasswordResetInitById,$timeout,DTOptionsBuilder,dataTableInstance,DTColumnBuilder,SocialService,ngDialog) {
        $scope.users = [];
        $scope.dtInstance = {};
        $scope.showAllUsers = true;
        var instance = dataTableInstance.getDataTable();
        if(!angular.equals({},instance) && instance){
            instance.DataTable.destroy();
        }

        function createdRow(row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        }
        $scope.panelids = ['user-management'];

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('paging',true)
            .withOption('order',[])
            .withDisplayLength(50)
            .withOption('createdRow', createdRow)
            .withOption('ajax', {
                url: "api/getusermgt",
                type:"POST",
                headers: {"X-CSRF-TOKEN":SocialService.getCSRF()},
                data: function(d){
                    d.showAllUsers =  $scope.showAllUsers;
                },
            })
            .withFixedHeader({
                bottom: true,
                headerOffset:49
            }).withDOM('frtipl')
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('full_numbers');

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('User Id'),
            DTColumnBuilder.newColumn('userName').withTitle('User Name').renderWith(function(data,type,row) {
                var resVal = '<a ui-sref="userCreate.useredit({id:'+row.id+'})">'+data+'</a>';
                return resVal;
            }),
            DTColumnBuilder.newColumn('userEmail').withTitle('User Email'),
            DTColumnBuilder.newColumn('organisation').withTitle('Organisation'),
            DTColumnBuilder.newColumn('role').withTitle('Role').notSortable(),
            DTColumnBuilder.newColumn('assignrdAdvertisersCount').withTitle('Accounts').notSortable(),
            DTColumnBuilder.newColumn('resetPasswordFlag').withTitle('Reset Password').notSortable().renderWith(function(data,type,row){
                var strObj = JSON.stringify(row);
                var resVal = '<a href="" ng-click="passwordResetReqUId.indexOf('+ row.id +') != -1  || resetUserPassword('+ row.id +')">Reset Password</a>';
                return resVal;
            }),
            DTColumnBuilder.newColumn('activated').withTitle('User Status').notSortable().renderWith(function(data,type,row){
                var check = data == 1? true:false;
                var resVal = '<label class="switch switch-sm"><input name="userEnableDisable" type="checkbox" checked="checked"  ng-model="u'+ row["id"] +'" ng-checked="'+ check + '"  ng-click="disableUser('+ row["id"] +',this)" /><span></span></label>';
                return resVal;
            })
        ];

        $scope.showAllUsersFunc = function(){
            $scope.showAllUsers = ! $scope.showAllUsers;
            $scope.dtInstance.DataTable.ajax.reload();

        };
        $scope.userAcivationFlag = "";
        $scope.disableUser = function(userId,obj){
            $scope.userId = userId;
            UserDisable.update({id:$scope.userId,flag:obj["u"+userId]});
        };
        // $scope.$emit('panel-refresh', $scope.panelids);


        $scope.passwordResetReqUId = [];
        $scope.resetUserPassword = function (userId) {
            var onResetPasswordSuccess = function (response) {
                var dialog = ngDialog.open({
                    template: '<p>Password reset request sent to registered email.</p>',
                    plain: true,
                    closeByDocument: false,
                    closeByEscape: false
                });
                setTimeout(function () {
                    dialog.close();
                }, 1500);
                $scope.passwordResetReqUId.push(userId);
            };
            var onResetPasswordFailure = function (response) {

            };
            PasswordResetInitById.update(userId,onResetPasswordSuccess,onResetPasswordFailure);
            //row["resetPasswordFlag"] = '1';

        };
        $scope.initRefresh = function () {
            $timeout(function(){$scope.$emit('panel-refresh', $scope.panelids);}, 50);
            // $timeout(function(){dataTableInstance.setDataTable($scope.dtInstance);}, 200);
        };
        $scope.$watch('dtInstance',function tableavailable( newValue, oldValue ) {
            console.log( "datatable", newValue );
            if(newValue && newValue.hasOwnProperty("id")){
                dataTableInstance.setDataTable($scope.dtInstance);
            }
        });



    });
