/**
  * Created by mjhuria on 11/4/17.
  */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('reco-gets', {
                parent: 'entity',
                url: '/reco/{advertiserId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.reco-manager-edit.title'
                },
                templateUrl: 'app/reco-manager/reco-manager.html',
                controller: 'RecoManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('pagetitles');
                                    $translatePartialLoader.addPart('global');
                                    return $translate.refresh();
                    }]
                }
            })

            // added by Snehal
            .state('reco-gets-woadv', {
                parent: 'entity',
                url: '/reco',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.reco-manager-create.title'
                },
                templateUrl: 'app/reco-manager/reco-manager.html',
                controller: 'RecoManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    });
