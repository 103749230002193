/**
 * Created by maitray on 29/3/17.
 */
/**
 * Created by rahul on 24/6/16.
 */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('feed-create', {
                parent: 'entity',
                url: '/feed/{advertiserId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.feed-manager-create.title'
                },
                templateUrl: 'app/feed-manager/feed-manager.html',
                controller: 'FeedManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('feed-edit', {
                parent: 'entity',
                url: '/feed/{advertiserId}/{feedId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.feed-manager-edit.title'
                },
                templateUrl: 'app/feed-manager/feed-manager.html',
                controller: 'FeedManagerController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).state('feed-edit-test', {
                parent: 'entity',
                url: '/feedmanager/test',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.feed-manager-edit.title'
                },
                templateUrl: 'app/feed-manager/common-page-structure.html',
                controller: 'FeedManagerControllerTest',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).state('feed-list', {
            parent: 'entity',
            url: '/feed-manager',
            data: {
                authorities: [],
                pageTitle: 'adelementAdminApp.pagetitles.feed-manager-edit.title'
            },
            templateUrl: 'app/feed-manager/feed-manager-list.html',
            controller: 'FeedManagerListController',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pagetitles');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });

    });
