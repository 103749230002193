/**=========================================================
 * Module: sidebar-menu.js
 * Handle sidebar collapsible elements
 =========================================================*/

(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$scope', '$state', 'SidebarLoader', 'Utils','$stateParams','Dashboard'];
    function SidebarController($rootScope, $scope, $state, SidebarLoader,  Utils, $stateParams,Dashboard) {

        activate();

        ////////////////

        function activate() {
            var collapseList = [];

            // demo: when switch from collapse to hover, close all items
            $rootScope.$watch('app.layout.asideHover', function(oldVal, newVal){
                if ( newVal === false && oldVal === true) {
                    closeAllBut(-1);
                }
            });

            // $rootScope.$on('dashboardStateChangeCampaign',  function(event, stateparams,params) {
            //     console.log("to State ---- >>>> ",stateparams);
            //
            //     if($scope.menuItems){
            //         // $scope.menuItems[0].submenu = [];
            //         getDashboardData(stateparams,params,false);
            //     }
            //     else{
            //         SidebarLoader.getMenu(function(items){
            //             $scope.menuItems = items;
            //             $scope.menuItems[0].submenu = [];
            //             params.getAdg = true;
            //             getDashboardData(stateparams,params,true);
            //         });
            //     }
            // });

            // $rootScope.$on('dashboardStateChange',  function(event, stateparams) {
            //     console.log("to State ---- >>>> ",stateparams);
            //
            //     if($scope.menuItems){
            //         $scope.menuItems[0].submenu = [];
            //         getDashboardData(stateparams,{},true);
            //     }
            //     else{
            //         SidebarLoader.getMenu(function(items){
            //             $scope.menuItems = items;
            //             $scope.menuItems[0].submenu = [];
            //             getDashboardData(stateparams,{},true);
            //         });
            //     }
            // });

            // $rootScope.$on('dashboardAtHomeState',  function(event, stateparams) {
            //     if($scope.menuItems){
            //         $scope.menuItems[0].submenu = [];
            //         // getDashboardData(stateparams);
            //     }
            //     else{
            //         SidebarLoader.getMenu(function(items){
            //             $scope.menuItems = items;
            //             $scope.menuItems[0].submenu = [];
            //             // getDashboardData(stateparams);
            //         });
            //     }
            // });
            // Load menu from json file
            // -----------------------------------

            SidebarLoader.getMenu(sidebarReady);

            function sidebarReady(items) {
                $scope.menuItems = items;
            }

            function getDashboardData(stateparams, params,flag){
                if(flag) {
                    Dashboard.getCampaigns(stateparams).then(function (data) {
                        var objheading = {
                            "heading":"Campaigns",
                            "text": "camp",
                            "icon": "",
                            "alert": "",
                            "label": "label label-info",
                            "translate": "Campaigns",
                            "role": []
                        };
                        $scope.menuItems[0].submenu.push(objheading);
                        for (var i = 0; i < data.length; i++) {
                            var injectobject = {
                                "text": data[i].campname,
                                "sref": "dashboard.account.campaign({accid:" + stateparams + ",id:" + data[i].campid + "})",
                                "icon": "",
                                "alert": "",
                                "label": "label label-info",
                                "translate": data[i].campname,
                                "role": [], "id": data[i].id,submenu:[]
                            };
                            injectobject.text = data[i].campname;
                            injectobject.id = data[i].campid;
                            injectobject.translate = data[i].campname;
                            $scope.menuItems[0].submenu.push(injectobject);
                        }
                        if(params.getAdg){
                            getCampData(params);
                        }

                    });
                }
                else{
                    getCampData(params);
                }
            }

            function getCampData(params){
                Dashboard.getAdgroups(params.accid,params.campid).then(function (data) {
                    var campindex = null;
                    for(var i = 0; i < $scope.menuItems[0].submenu.length; i++){
                        if($scope.menuItems[0].submenu[i].id === params.campid){
                            campindex = i;
                            break
                        }
                    }

                    for (var i = 0; i < data.length; i++) {
                        var injectobject = {
                            "text": data[i].adgname,
                            "icon": "",
                            "alert": "",
                            "label": "label label-info",
                            "translate": data[i].adgname,
                            "role": [], "id": data[i].adgid
                        };
                        injectobject.text = data[i].adgname;
                        injectobject.id = data[i].adgid;
                        injectobject.translate = data[i].adgname;
                        $scope.menuItems[0].submenu[campindex].submenu.push(injectobject);
                    }
                });
            }


            // Handle sidebar and collapse items
            // ----------------------------------

            $scope.getMenuItemPropClasses = function(item) {
                return (item.heading ? 'nav-heading' : '') +
                    (isActive(item) ? ' active' : '') ;
            };

            $scope.addCollapse = function($index, item) {
                collapseList[$index] = $rootScope.app.layout.asideHover ? true : !isActive(item);
            };

            $scope.isCollapse = function($index) {
                return (collapseList[$index]);
            };

            $scope.toggleCollapse = function($index, isParentItem) {

                // collapsed sidebar doesn't toggle drodopwn
                if( Utils.isSidebarCollapsed() || $rootScope.app.layout.asideHover ) return true;

                // make sure the item index exists
                if( angular.isDefined( collapseList[$index] ) ) {
                    if ( ! $scope.lastEventFromChild ) {
                        if($index!==0)
                            collapseList[$index] = !collapseList[$index];
                        closeAllBut($index);
                    }
                }
                else if ( isParentItem ) {
                    closeAllBut(-1);
                }

                $scope.lastEventFromChild = isChild($index);

                return true;

            };

            // Controller helpers
            // -----------------------------------

            // Check item and children active state
            function isActive(item) {

                if(!item) return;

                if( !item.sref || item.sref === '#') {
                    var foundActive = false;
                    angular.forEach(item.submenu, function(value) {
                        if(isActive(value)) foundActive = true;
                    });
                    return foundActive;
                }
                else
                {
                    var stateincludes = false;
                    if(item.sref === "dashboard.agency" && $state.current.name === "dashboard.account"){
                       return true;
                    }
                    if(item.sref === "dashboard.agency" && $state.current.name === "dashboard.account.campaign"){
                        return true;
                    }
                    return $state.is(item.sref) || $state.includes(item.sref);
                }

            }

            function closeAllBut(index) {
                index += '';
                for(var i in collapseList) {
                    if(index < 0 || index.indexOf(i) < 0)
                        collapseList[i] = true;
                }
            }

            function isChild($index) {
                /*jshint -W018*/
                return (typeof $index === 'string') && !($index.indexOf('-') < 0);
            }

        } // activate
    }

})();

(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .controller('UserBlockController', UserBlockController);

    UserBlockController.$inject = ['$rootScope', '$scope'];
    function UserBlockController($rootScope, $scope) {

        activate();

        ////////////////

        function activate() {
            $rootScope.user = {
                name:     'John',
                job:      'ng-developer',
                picture:  'app/img/user/02.jpg'
            };

            // Hides/show user avatar on sidebar
            $rootScope.toggleUserBlock = function(){
                $rootScope.$broadcast('toggleUserBlock');
            };

            $rootScope.userBlockVisible = true;

            var detach = $rootScope.$on('toggleUserBlock', function(/*event, args*/) {

                $rootScope.userBlockVisible = ! $rootScope.userBlockVisible;

            });

            $scope.$on('$destroy', detach);
        }
    }
})();
