/**
 * Created by maitray on 4/3/16.
 */
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('dashboard', {
                parent:'entity'
            // views: {
            //     'content': {
            //         templateUrl: 'app/components/custom/app.html',
            //         controller: 'DashboardController'
            //     }
            // },
            //     resolve: {
            //         currentUserX: ['Principal', function(Principal) {
            //             return Principal.identity();
            //         }],
            //         aeAdvertisers: ['AeAccount',function(AeAccount){
            //             return AeAccount.getAccounts();
            //         }]
            //
            //     }
        })
        .state('dashboard.agency', {
            parent:'entity',
            url: '/dashboard',
            data: {
                // authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.pagetitles.dashboard-agency.title'
            },
            title: 'Blank Template',
            templateUrl: 'app/dashboard/agency.html',
            controller: 'AgencyController',
            pageTitle: 'template',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pagetitles');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                Currency: [function () {
                    return undefined;
                }]
            }
        })
        .state('dashboard.account', {
            parent:'entity',
            url: '/dashboard/:accid',
            data: {
                // authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.dashboard.account'
            },
            title: 'Blank Template',
            templateUrl: 'app/dashboard/agency.html',
            controller: 'AgencyController',
            pageTitle: 'template',
            resolve: {
                Currency: ['$stateParams', '$http', function ($stateParams, $http) {
                    var url = "api/dashboard/getAdvertiserCurrency/"+$stateParams.accid;
                    return $http.get(url).then(function (response) {
                        return response.data;
                    });
                }]
            }
        })
        .state('dashboard.account.campaign', {
            parent:'entity',
            url: '/campaigns/:accid/:id',
            data: {
                // authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.dashboard.account.campaign'
            },
            title: 'Blank',
            templateUrl: 'app/dashboard/agency.html',
            controller: 'AgencyController',
            pageTitle: 'campaign',
            resolve: {
                Currency: ['$stateParams', '$http', function ($stateParams, $http) {
                    var url = "api/dashboard/getAdvertiserCurrency/"+$stateParams.accid;
                    return $http.get(url).then(function (response) {
                        return response.data;
                    });
                }]
            }
        })
        .state('dashboard.account.campaign.adgroup', {
            parent:'entity',
            url: '/adgroup/:accid/:campid/:id',
            data: {
                // authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.dashboard.account.campaign'
            },
            title: 'Blank',
            templateUrl: 'app/dashboard/agency.html',
            controller: 'AgencyController',
            pageTitle: 'campaign',
            resolve: {
                Currency: ['$stateParams', '$http', function ($stateParams, $http) {
                    var url = "api/dashboard/getAdvertiserCurrency/"+$stateParams.accid;
                    return $http.get(url).then(function (response) {
                        return response.data;
                    });
                }]
            }
        })
        .state('dashboard.account.campaign.adgroup.placement', {
            parent:'entity',
            url: '/adgroup/placements/:accid/:campid/:id',
            data: {
                // authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.dashboard.account.campaign'
            },
            title: 'Blank',
            templateUrl: 'app/dashboard/agency.html',
            controller: 'AgencyController',
            pageTitle: 'campaign',
            resolve: {
                Currency: ['$stateParams', '$http', function ($stateParams, $http) {
                    var url = "api/dashboard/getAdvertiserCurrency/"+$stateParams.accid;
                    return $http.get(url).then(function (response) {
                        return response.data;
                    });
                }]
            }
        })
        .state('dashboard.account.campaign.ads2', {
            parent:'entity',
            url: '/adsbyadgroup/:adgroupid',
            data: {
                // authorities: ['ROLE_ADMIN'],
                pageTitle: 'adelementAdminApp.dashboard.account.campaign'
            },
            title: 'Blank',
            templateUrl: 'app/dashboard/ads.html',
            controller: 'AdsController',
            pageTitle: 'ads'
        });
        //.state('dashboard.account', {
        //    url: '/dashboard/:id',
        //    title: 'Blank Template',
        //    templateUrl: 'app/dashboard/template.html',
        //    controller: 'DashboardController',
        //    pageTitle: 'template'
        //})


});
