/**
 * Created by maitray on 29/3/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .factory('AssignAdvertisers', function ($resource) {
        return $resource('api/assignadvertisers', {}, {
        });
    });
