/**
 * Created by rahul on 9/6/16.
 */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('advertisers-mgt', {
                parent: 'entity',
                url: '/advertisersmgt',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.advertisers-mgt.title'
                },
                templateUrl: 'app/admin/advertisers-mgt/advertisers-mgt.html',
                controller: 'AdvertisersMgtController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    });
