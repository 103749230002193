/**
 * Created by maitray on 27/3/16.
 */

'use strict';

angular.module('adelementAdminApp')
    .factory('UserCreate', function ($resource) {
        return $resource('api/createuser', {}, {
        });
    })
