'use strict';

/**
 * Created by snehal on 13/7/17.
 */
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('creativeForm-create-advId', {
                parent: 'entity',
                url: '/creatives/vast/{advertiserId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-vast-create.title'
                },
                templateUrl: 'app/creativeForm/creative-form.html',
                controller: 'CreativeFormController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })
            .state('creativeForm-create', {
                parent: 'entity',
                url: '/creatives/vast',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-vast-create.title'
                },
                templateUrl: 'app/creativeForm/creative-form.html',
                controller: 'CreativeFormController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: [function () {
                        return undefined;
                    }]
                }
            })
            .state('creativeForm-edit', {
                parent: 'entity',
                url: '/creatives/vast/{advertiserId}/{creativeId}',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-vast-edit.title'
                },
                templateUrl: 'app/creativeForm/creative-form.html',
                controller: 'CreativeFormController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    advertiser: ['$stateParams', '$http', function ($stateParams, $http) {
                        var url = "api/campaign-manager/getAdvertiser/"+$stateParams.advertiserId;
                        return $http.get(url).then(function (response) {
                            return response.data;
                        });
                    }]
                }
            })
            .state('creativeForm-list', {
                parent: 'entity',
                url: '/creatives/vast/list',
                data: {
                    authorities: [],
                    pageTitle: 'adelementAdminApp.pagetitles.creative-vast-edit.title'
                },
                templateUrl: 'app/creativeForm/creative-form-list.html',
                controller: 'CreativeFormController',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });

    });
