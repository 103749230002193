/**
 * Created by maitray on 30/3/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .factory('CreateAdvertisers', function ($resource) {
        return $resource('api/createadvertiser', {}, {
        });
    })
    .factory('AddAgency', function ($resource) {
        return $resource('api/agency/create', {}, {
        });
    })
    .factory('AssignAgency', function ($resource) {
        return $resource('api/agency/assign', {}, {
        });
    });

