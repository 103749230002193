/**
 * Created by rahul on 16/3/16.
 */
'use strict';
angular.module('adelementAdminApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('userCreate', {
                parent: 'entity',
                url: '/usercreate',
                data: {
                    authorities: ['ROLE_ADMIN','SUPER_EXPERT_WRITE','SUPER_EXPERT_ADMIN'],
                    pageTitle: 'adelementAdminApp.pagetitles.user-create.title'
                },

                templateUrl: 'app/admin/user-create/user-create-form.html',
                controller: 'NewUserCreateController',
                resolve: {
                    aeAdvertisers: ['AeAdvertisersIdName', function(AeAdvertisersIdName) {
                        return AeAdvertisersIdName.query();
                    }],
                    userExist:function () {
                        return null;
                    },
                    assignedAdvertiserAndRoles:function () {
                        return null
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]

                }



            })
            .state('userCreate.prospective', {
                parent: 'entity',
                url: '/usercreate/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','SUPER_EXPERT_WRITE','SUPER_EXPERT_ADMIN'],
                    pageTitle: 'adelementAdminApp.pagetitles.user-create.title'
                },
                        templateUrl: 'app/admin/user-create/user-create-form.html',
                        controller: 'UserCreateController'
                  ,
                resolve: {
                    prospectiveUser: ['ProspectiveUsers','$stateParams', function(ProspectiveUsers,$stateParams) {
                        return ProspectiveUsers.get({id : $stateParams.id});
                    }],
                    aeAdvertisers: ['AeAdvertisersIdName', function(AeAdvertisersIdName) {
                        return AeAdvertisersIdName.query();
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]

                }

            })
            .state('userCreate.useredit', {
                parent: 'entity',
                url: '/usercreate/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','SUPER_EXPERT_WRITE','SUPER_EXPERT_ADMIN'],
                    pageTitle: 'adelementAdminApp.pagetitles.user-edit.title'
                },
                templateUrl: 'app/admin/user-create/user-create-form.html',
                controller: 'NewUserCreateController'
                ,
                resolve: {
                    aeAdvertisers: ['AeAdvertisersIdName', function(AeAdvertisersIdName) {
                        return AeAdvertisersIdName.query();
                    }],
                    userExist:['UserById','$stateParams', function(UserById,$stateParams) {
                        return UserById.get({id: $stateParams.id});

                    }],
                    assignedAdvertiserAndRoles:['GetAssignedAdvertisers','$stateParams',function(GetAssignedAdvertisers,$stateParams){
                        return GetAssignedAdvertisers.get({id: $stateParams.id});
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pagetitles');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]

                }

            });
    });
