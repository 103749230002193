'use strict';

angular.module('adelementAdminApp')
    .controller('UserAdvertiserMappingDetailController', function ($scope, $rootScope, $stateParams, entity, UserAdvertiserMapping) {
        $scope.userAdvertiserMapping = entity;
        $scope.load = function (id) {
            UserAdvertiserMapping.get({id: id}, function(result) {
                $scope.userAdvertiserMapping = result;
            });
        };
        var unsubscribe = $rootScope.$on('adelementAdminApp:userAdvertiserMappingUpdate', function(event, result) {
            $scope.userAdvertiserMapping = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
