(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .directive('paneltool', paneltool);

    paneltool.$inject = ['$compile', '$timeout'];
    function paneltool ($compile, $timeout) {
        var directive = {
            link: link,
            restrict: 'E',
            scope: false
        };
        return directive;

        function link(scope, element, attrs) {

            var templates = {
                /* jshint multistr: true */
                collapse:'<a href="#" panel-collapse="" uib-tooltip="Collapse Panel" ng-click="panelId = !panelId"> \
                        <em ng-show="{{panelId}}" class="fa fa-plus ng-no-animation"></em> \
                        <em ng-show="!{{panelId}}" class="fa fa-minus ng-no-animation"></em> \
                      </a>',
                dismiss: '<a href="#" panel-dismiss="" uib-tooltip="Close Panel">\
                       <em class="fa fa-times"></em>\
                     </a>',
                refresh: '<a href="#" panel-refresh="" data-spinner="{{spinner}}" uib-tooltip="Refresh Panel">\
                       <em class="fa fa-refresh"></em>\
                     </a>'
            };

            var tools = scope.panelTools || attrs;

            $timeout(function() {
                element.html(getTemplate(element, tools )).show();
                $compile(element.contents())(scope);

                element.addClass('pull-right');
            });

            function getTemplate( elem, attrs ){
                var temp = '';
                attrs = attrs || {};
                if(attrs.toolCollapse)
                    temp += templates.collapse.replace(/{{panelId}}/g, (elem.parent().parent().attr('id')) );
                if(attrs.toolDismiss)
                    temp += templates.dismiss;
                if(attrs.toolRefresh)
                    temp += templates.refresh.replace(/{{spinner}}/g, attrs.toolRefresh);
                return temp;
            }
        }// link
    }

})();
/**=========================================================
 * Refresh panels
 * [panel-refresh] * [data-spinner="standard"]
 =========================================================*/

(function() {
    'use strict';

    angular
        .module('adelementAdminApp')
        .directive('panelRefresh', panelRefresh);

    function panelRefresh () {
        var directive = {
            controller: Controller,
            restrict: 'A',
            scope: false
        };
        return directive;

    }

    Controller.$inject = ['$scope', '$element'];
    function Controller ($scope, $element) {
        var refreshEvent   = 'panel-refresh',
            whirlClass     = 'whirl',
            defaultSpinner = 'traditional';

        // catch clicks to toggle panel refresh
        $element.on('click', function (e) {
            e.preventDefault();

            var $this   = $(this),
                panel   = $this.parents('.panel').eq(0),
                spinner = $this.data('spinner') || defaultSpinner
                ;

            // start showing the spinner
            //panel.addClass(whirlClass + ' ' + spinner);

            // Emit event when refresh clicked
            //$scope.$emit(refreshEvent, panel.attr('id'));

        });

        // listen to remove spinner
        $scope.$on('removeSpinner', removeSpinner);

        // method to clear the spinner when done
        function removeSpinner (ev, id) {
            if (!id) return;
            for(var i=0;i<id.length;i++){
                var newid = id[i].charAt(0) === '#' ? id[i] : ('#'+id[i]);
                angular
                    .element(newid)
                    .removeClass(whirlClass);
            }

        }
        $scope.$on('panel-refresh', function(event, id) {
            //var secs = 3;
            //
            //console.log('Refreshing during ' + secs +'s #'+id);
            //
            //$timeout(function(){
            //    // directive listen for to remove the spinner
            //    // after we end up to perform own operations
            //    $scope.$broadcast('removeSpinner', id);
            //
            //    console.log('Refreshed #' + id);
            //
            //}, 3000);
            if (!id)
                return;
            for(var i=0;i<id.length;i++) {
                var newid = id[i].charAt(0) === '#' ? id[i] : ('#' + id[i]);
                var panel = angular.element(newid);
                //var panel = elm.parents('.panel').eq(0);
                var spinner = panel.data('spinner') || defaultSpinner;

                // start showing the spinner
                panel.addClass(whirlClass + ' ' + spinner);
            }
        });
    }
})();
