/**
 * Created by snehal on 13/7/17.
 */

angular.module('adelementAdminApp')
    .directive('insertText', ['$rootScope', function($rootScope) {
      return {
        scope: {
            source:'=source',
            index:'=index',
        },
        link: function(scope, element, attrs) {
          $rootScope.$on('add', function(e, val) {
            var domElement = element[0];

            if (document.selection) {
              domElement.focus();
              var sel = document.selection.createRange();
              sel.text = val;
              domElement.focus();
            } else if (domElement.selectionStart || domElement.selectionStart === 0) {
              var startPos = domElement.selectionStart;
              var endPos = domElement.selectionEnd;
              var scrollTop = domElement.scrollTop;
              domElement.value = domElement.value.substring(0, startPos) + val + domElement.value.substring(endPos, domElement.value.length);
              domElement.focus();
              domElement.selectionStart = startPos + val.length;
              domElement.selectionEnd = startPos + val.length;
              domElement.scrollTop = scrollTop;
            } else {
              domElement.value += val;
              domElement.focus();
            }

          });
        }
      }
    }])
    .controller('CreativeFormController', function ($scope,$filter,$state,$location,$stateParams,$http,$timeout,CreativeFormVastService,GetAdvertiserIDName,$rootScope,advertiser){
        $scope.stateDecide = false;
        $scope.isAdvertiserId = false;
        var creativeId;
        $scope.formData = {};
        $scope.parseData={};
        $scope.creativedata = {};
        $scope.creativeAllDetails={};
        var advertiserId;
        $scope.advertiserId="";
        $scope.creativeId="";
        var formData={};
        $scope.advertiser = advertiser;
        $scope.creativeVastData = {};
        var creativeData={};
        $scope.currentPath = $location.path();
        $scope.creativeTagName="";
        $scope.creativeVastTag="";
//        $scope.videoMinDuration="";
//        $scope.videoMaxDuration="";
        $scope.videoDuration = "";
        $scope.vastTagType="";
        $scope.playerSize="";
        $scope.allAdvertisersIdName = [];
        var filterStartDate="";
        var filterEndDate="";
        $scope.playerSizes=['Small','Medium','Large','Extra Large'];
        $scope.seletedPlayerSize = {
            playerSizes: []
        };
        $scope.labels="";
        $scope.platformSelection = false;
        $scope.submitButtonDisable = false;
        $scope.macros = false;
        var advertiserId = $stateParams.advertiserId;
        var todays = new Date();
        $scope.infiniteDate = new Date("9999-09-09 00:00:00");
        $scope.opened = {};
        $scope.options = {
            minDate: new Date()
        };

        $scope.dt = new Date();
        $scope.minDate = new Date();
        $scope.startDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            minDate:moment()
        };
        $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };
        $scope.startDateOpened = false;
        $scope.endDateOpened = false;

        $scope.initDate = new Date('2019-10-20');

        var currentUserTime;
        var offset = 0;
        if($scope.advertiser != undefined) {
            currentUserTime = $scope.advertiser.currentTime;
            offset = new Date().getTimezoneOffset();
        }

        $scope.formats = ['yyyy-MM-dd HH:mm:ss','yyyy-MM-dd','dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        $scope.creative = {};
        var fcapDurationMinutes = {HOUR:60,DAY:1440,WEEK:10080,MONTH:43200};
        var m = moment();
        var today = m.set({hour:0,minute:0,second:0,millisecond:0}).toDate();

        $scope.openStartEndDate = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
            if(dateType) {
                if(index == -1){
                    $scope.startDateOpened = true;
                }
            }
            else{
                if(index == -1){
                    $scope.endDateOpened = true;

                }
            }
        }

        $scope.startLessThanToday = function(which,index) {
            if(which == 0) {
                var startD = new Date($scope.creative.startDate);
                return today > startD;
            }
        };
        $scope.checkDateLimits = function(selectedDate){
            var currentDate = new Date(selectedDate);
            var upperBounds = new Date();
            var lowerBounds = new Date('01/01/1900');

            return currentDate < upperBounds && currentDate > lowerBounds;

        };

        $scope.checkStartMoreThanEndDate = function (startValue,endValue) {
            if(startValue && endValue){
                var startDate = new Date(startValue);
                var endDate = new Date(endValue);
                return startDate < endDate;
            }
            else if(!endValue) {
                return true;
            }
            else {
                return false;
            }
        };


        $scope.checkOverLappingDatesForCreative = function (viewValue,which) {
            if(which == 1 && viewValue == null) {
                return true;
            }
            var currentDate = new Date(viewValue);
            var creativeStartDate = new Date($scope.creative.startDate);
            var creativeEndDate = new Date($scope.creative.endDate);
            if($scope.creative.endDate == null) {
                return currentDate > creativeStartDate;
            }
            return currentDate > creativeStartDate && currentDate < creativeEndDate;
        };

        $scope.platform = {
           desktopWeb: false,
           mobileWeb: false,
           app: false
        };

        $scope.macrotype = {
            adserverMacros: false,
            rtbMacros: false
        };


        $scope.$watchCollection('platform', function () {
          $scope.selectedPlatform = [];
          $scope.selectedMacrosTypeList = [];
          angular.forEach($scope.platform, function (value, key) {
            if (value) {
              $scope.selectedPlatform.push(key);
              $scope.platformSelection = true
            }
          });
            if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
                angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                  angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                        if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                          }
                        }
                        else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                    else if($scope.selectedPlatform.length>1){
                        if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                           if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                              $scope.selectedMacrosTypeList.push(val);
                           }
                        }
                    }
                  });
                });
             } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
               angular.forEach($scope.selectedPlatform, function (data) {
    //                console.log(data)
                 angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                    if($scope.selectedPlatform.length==1){
                       if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                         if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                         }
                       }
                       else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                    else if($scope.selectedPlatform.length>1){
                       if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                          if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                             $scope.selectedMacrosTypeList.push(val);
                          }
                       }
                    }
                 });
               });
             }
             else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
                  angular.forEach($scope.selectedPlatform, function (data) {
     //                console.log(data)
                    angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                       if($scope.selectedPlatform.length==1){
                          if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                            if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                            }
                          }
                          else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                       else if($scope.selectedPlatform.length>1){
                          if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                             if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                                $scope.selectedMacrosTypeList.push(val);
                             }
                          }
                       }
                    });
                 });
              }
        });

        $scope.isStartDateOpen = false;
        $scope.isEndDateOpen = false;

        $scope.openCalendar = function($event,dateType,index) {
            $event.preventDefault();
            $event.stopPropagation();
//            $scope.isOpen = true;
            if(dateType) {
                if(index == -1){
                    $scope.isStartDateOpen = true;
                }
            }
            else{
                if(index == -1){
                    $scope.isEndDateOpen = true;
                }
            }
        };


        $scope.getMacros = function(){
            $scope.selectedMacrosTypeList=[];
            $scope.macros = true;
          if($scope.selectedPlatform && $scope.macrotype.adserverMacros && !$scope.macrotype.rtbMacros){
              angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
            angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
              if(val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
              else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                 if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                    $scope.selectedMacrosTypeList.push(val);
                 }
              }
            });
          });
       } else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && !$scope.macrotype.adserverMacros){
         angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
           angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
             if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
               if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
               }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
             else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb"){
                if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                   $scope.selectedMacrosTypeList.push(val);
                }
             }
           });
         });
       }
       else if ($scope.selectedPlatform && $scope.macrotype.rtbMacros && $scope.macrotype.adserverMacros){
            angular.forEach($scope.selectedPlatform, function (data) {
//                console.log(data)
              angular.forEach($scope.thirdPartyMacrosList, function (val, index) {
                if(val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                  if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                  }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
                else if(val.platform=="Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="rtb" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="rtb" || val.platform=="App" && data=="app" && val.macroType=="rtb"|| val.platform == "App/Web" && data=="app" && val.macroType=="rtb" || val.platform=="Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="desktopWeb" && val.macroType=="adserver" || val.platform=="Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform == "App/Web" && data=="mobileWeb" && val.macroType=="adserver" || val.platform=="App" && data=="app" && val.macroType=="adserver"|| val.platform == "App/Web" && data=="app" && val.macroType=="adserver"){
                   if($scope.selectedMacrosTypeList.indexOf(val) == -1){
                      $scope.selectedMacrosTypeList.push(val);
                   }
                }
              });
            });
          }
          else{
            $scope.macros = false;
          }
        }


        var advertiserInfoPromise =  GetAdvertiserIDName.query();
        advertiserInfoPromise.$promise.then(function(response){
             $scope.allAdvertisersIdName = response;
             if($scope.stateDecide){
                $scope.selectedAdvertiser = parseInt($stateParams.advertiserId);
             }
        });


        // initialize the services
        function initServices() {
            CreativeFormVastService.getVastData().then(function(creativeVastData){
                $scope.thirdPartyMacrosList = angular.copy(creativeVastData.thirdPartyMacros);
            });
        }


        function init(){
           var startDate = new Date($scope.startDate);
           var endDate = new Date($scope.endDate);
           var urlPath = $location.path();

            $scope.creative.startDate = moment(currentUserTime).add(10,'MINUTES').toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.creative.startDate = moment(currentUserTime).toDate(), 'yyyy-MM-dd HH:mm:ss';
//            $scope.creative.endDate = moment(currentUserTime).add(364, 'DAYS').toDate(), 'yyyy-MM-dd HH:mm:ss';
           if($stateParams.hasOwnProperty("advertiserId") && $stateParams.hasOwnProperty("creativeId") && $stateParams.creativeId != undefined){
               $scope.isAdvertiserId = true;
               $scope.isEditCreative = true;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
               creativeId = $stateParams.creativeId;
               $scope.creativeId = creativeId;
           }
           else if($stateParams.hasOwnProperty("advertiserId") && !$stateParams.hasOwnProperty("creativeId") && $stateParams.advertiserId != undefined){
               $scope.isAdvertiserId = true;
               advertiserId = $stateParams.advertiserId;
               $scope.advertiserId = advertiserId;
           }
           else{
               $scope.isEditCreative = false;
               $scope.isAdvertiserId = false;
               $scope.creativeId = creativeId;
           }

           // if the creativeId id present then get the data and set to the form for update operation
           if($scope.isEditCreative){
               CreativeFormVastService.getCreative(advertiserId,creativeId).then(function(creativedata){
                $scope.creativeVastData = angular.copy(creativedata);
                $scope.creativeTagName=$scope.creativeVastData.creativeTagName;
                $scope.creativeVastTag=$scope.creativeVastData.creativeVastTag;
                $scope.videoDuration=$scope.creativeVastData.videoDuration;
                $scope.vastTagType=$scope.creativeVastData.vastTagType;
                $scope.labels=$scope.creativeVastData.labels;

                $scope.creative.startDate = moment($scope.creativeVastData.startDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                if($scope.creativeVastData.endDate.indexOf("9999") == -1) {
                    $scope.creative.endDate = moment($scope.creativeVastData.endDate).toDate(), 'yyyy-MM-dd HH:mm:ss';
                }
                else {
                    $scope.creative.endDate = null;
                }

                var array = $scope.creativeVastData.playerSize.split(',');
                $scope.seletedPlayerSize.playerSizes = array;
                if($scope.creativeVastData.platform){
                    var platformArray = $scope.creativeVastData.platform.split(',');
                    $scope.selectedPlatform.push(platformArray);
                    angular.forEach($scope.platform, function (value, key) {
                        angular.forEach(platformArray, function (data) {
                           if (key == data) {
                              $scope.platform[key] = true;
                           }
                        })
                    });
                }
                $scope.advertiserId=advertiserId;
                $scope.advertiserDomains = $scope.creativeVastData.advertiserDomains;
                $scope.destinationUrl = $scope.creativeVastData.destinationUrl;
//               console.log(creativedata);
               });
            }
            else{

            }
        };
        initServices();
        init();

        $scope.saveCreativesForm = function(){
            if($stateParams.hasOwnProperty("advertiserId")){
               CreativeFormVastService.saveCreativeFormAdvId(advertiserId,formData).then(function(data){
                   $scope.submitButtonDisable = true;
                   $timeout(function () {
                       $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
                   }, 2000);
//                   console.log(data);
                   $state.reload();
               });
             }
             else{
               CreativeFormVastService.saveCreativeForm(formData).then(function(data){
                  $scope.submitButtonDisable = true;
                  $timeout(function () {
                      $state.go('creative-manager-adv-summaryFilter', {advertiserId: advertiserId, summaryFilter:"concept"})
//                      $state.go('creativeForm-edit', {advertiserId: advertiserId, creativeId: data})
                  }, 2000);
//                  console.log(data);
                  $state.reload();
              });
             }
        };

        // submitCreativesForm function will be called whenever submit button is pressed
        $scope.submitCreativesForm= function(){
//            console.log('Submitting');
            if($stateParams.hasOwnProperty("advertiserId")){
                advertiserId = $stateParams.advertiserId;
            }
            else{
                advertiserId = $scope.advertiserId;
            }

            $scope.formData.advertiserId=advertiserId;

            // if isEditCreative then assign the creativeId to the formData
           if($scope.isEditCreative){
              $scope.formData.creativeId=creativeId;
           }
           else{
             $scope.submitButtonDisable = true;
           }
           $scope.formData.platform = $scope.selectedPlatform.toString();
           $scope.formData.creativeTagName = $scope.creativeTagName;
           var insertedText = document.getElementById("vastTag").value;
           $scope.creativeVastTag = angular.copy(insertedText);
           $scope.formData.creativeVastTag = $scope.creativeVastTag;
           $scope.formData.videoDuration = $scope.videoDuration;
           $scope.formData.vastTagType = $scope.vastTagType;
           $scope.formData.labels = $scope.labels;
           $scope.formData.startDate  = moment($scope.creative.startDate).format('YYYY-MM-DD HH:mm:ss');
           if($scope.creative.endDate){
              $scope.formData.endDate  = moment($scope.creative.endDate).format('YYYY-MM-DD HH:mm:ss');
           }
           else{
              $scope.formData.endDate  = "9999-09-09 09:09:09";
           }

           var playerSizes = $scope.seletedPlayerSize.playerSizes.toString()
           if (playerSizes.substr(0,1) == ",") {
               playerSizes = playerSizes.substring(1);
           }

           $scope.formData.playerSize = playerSizes;
           $scope.formData.advertiserDomains = $scope.advertiserDomains;
           $scope.formData.destinationUrl = $scope.destinationUrl;
           formData = angular.copy($scope.formData);

           // if creativeId is not present, saveCreativesForm() will be called, else updateCreative()
            if (creativeId === undefined || creativeId === null) {
                console.log('Saving New Creative');
                $scope.saveCreativesForm();
            }
            else {
                CreativeFormVastService.updateCreative(advertiserId,creativeId, formData).then(function (data) {
                   $scope.submitButtonDisable = true;
//                   console.log(data);
                   $state.reload();
                });
            };
        };


        $scope.getAllCreatives=function(){
            return CreativeFormVastService.getAllCreatives();
        }


        $scope.add = function(macro) {
            $rootScope.$broadcast('add', macro);
        }

    });
