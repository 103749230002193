/**
 * Created by adelement on 25/4/16.
 */
angular.module('adelementAdminApp')
.factory('UserById', function ($resource) {
    return $resource('api/userbyid/:id', {}, {
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                return data;
            }
        }
    });
})
.factory('AssignedUsers', function ($resource) {
    return $resource('api/assignedusers/:id', {}, {
        'get': { method: 'GET'}
    });
})
.factory('GetAllUsers', function ($resource) {
    return $resource('api/getallusers', {}, {
        'get': { method: 'GET'}
    });
})
.factory('AssignUsersToAdvertiser', function ($resource) {
    return $resource('api/assignusers', {}, {
    });
});
