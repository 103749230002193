/**
 * Created by mjhuria on 11/4/17.
 */

angular.module('adelementAdminApp')
    .factory('RecoService', function ($http) {
        var url = "api/reco";
        return {
            // added by Snehal
            getAllAdvertisersData: function () {
                 return $http.post(url).then(function (response) {
                     return response.data;
                 });
             },
            getAllFeedProps: function (advId) {
                return $http.get(url+"/"+advId).then(function (response) {
                    return response.data;
                });
            },
            getFeedProps: function (advId,feedId) {
                return $http.get(url+"/"+advId+"/"+feedId).then(function (response) {
                    return response.data;
                });
            },
            saveRecoProps: function (advId, data) {
                return $http.post(url+"/"+advId, data).then(function (response) {
                    return response.data;
                });
            }
        }
    })
    .factory('GetAdvertiserIDName', function ($resource) {
        return $resource('api/reco', {}, {
            'query': { method: 'GET', isArray: true},
        })
    });
