/**
 * Created by maitray on 2/4/16.
 */
'use strict';

angular.module('adelementAdminApp')
    .factory('GetSelectionInfo', function ($http) {
        return {
            getSelectionInfo: function () {
                return $http.get('api/getselectioninfo').then(function (response) {
                    return response.data;
                });
            }
        };
    })
    .factory('GetAssignedAdvertisers', function ($resource) {
        return $resource('api/getassignedadvertisers/:id', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    })
    .factory('GetDemoRequests', function ($resource) {
        return $resource('api/getdemorequests', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    });

